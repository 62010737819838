import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, Typography } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import LogoOnlyIcon from 'icons/logoOnly'
import Spacer from 'component/layoutUtils/spacer'
import { SignInButtons } from 'component/signInButtons'

const useLiveLinkPageContentStyles = makeStyles((theme: Theme) => {
  return {
    liveLinkPageContentRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      margin: '60px auto',
      maxWidth: 500,
      [theme.breakpoints.down('sm')]: {
        margin: '20px auto',
      },
    },
    logo: {
      marginBottom: 30,
    },
    threadTitle: {
      color: theme.threadPalette.darkBlueGrey,
      fontSize: '25px',
      fontWeight: 900,
      textAlign: 'center',
    },
    lockIcon: {
      color: theme.threadPalette.lightGray,
      height: 100,
      marginTop: 70,
      width: 100,
      [theme.breakpoints.down('sm')]: {
        marginTop: 30,
      },
    },
    text: {
      color: theme.threadPalette.lightGray,
      fontSize: '20px',
      fontWeight: 500,
      textAlign: 'center',
    },
  }
})

interface ILiveLinkPageContentProps {
  threadTitle: string
}

export const LiveLinkPageContent = ({ threadTitle }: ILiveLinkPageContentProps) => {
  const classes = useLiveLinkPageContentStyles()

  return (
    <>
      <div className={classes.liveLinkPageContentRoot}>
        <LogoOnlyIcon className={classes.logo} height={50} width={50} />
        <Typography className={classes.threadTitle}>{threadTitle}</Typography>
        <LockOutlinedIcon className={classes.lockIcon} />
        <Spacer height={10} />
        <Typography className={classes.text}>Verify your identity</Typography>
        <Spacer height={20} />
      </div>
      <div>
        <SignInButtons />
      </div>
    </>
  )
}
