import React, { useContext } from 'react'
import { Dialog, makeStyles } from '@material-ui/core'
import { NewThreadModalContent } from './newThreadModalContent'
import { useFullScreen } from 'hooks/useFullScreen'
import { IProject, IProjectThread } from 'model/project'

interface INewThreadModalContext {
  openModal: (props?: { shouldNavigateToThread?: boolean }) => void
  closeModal: () => void
  isOpen: boolean
  project?: IProject | IProjectThread | null
  setProject: (project: IProject) => void
  removeProject: () => void
  navigateToThreadOnCreation: boolean
}

export const NewThreadModalContext = React.createContext<INewThreadModalContext | undefined>(undefined)

export const NewThreadModalProvider: React.FC<{}> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [project, setProject] = React.useState<IProject | null>()
  const [navigateToThreadOnCreation, setNavigateToThreadOnCreation] = React.useState(false)

  const openModal = ({ shouldNavigateToThread = true }: { shouldNavigateToThread?: boolean } = {}) => {
    setNavigateToThreadOnCreation(shouldNavigateToThread)
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    setProject(null)
    setNavigateToThreadOnCreation(false)
  }

  const removeProject = () => setProject(null)

  return (
    <NewThreadModalContext.Provider
      value={{
        isOpen,
        openModal,
        closeModal,
        project,
        setProject,
        removeProject,
        navigateToThreadOnCreation,
      }}
    >
      {children}
    </NewThreadModalContext.Provider>
  )
}

const useNewThreadModalStyles = makeStyles(() => ({
  root: {
    borderRadius: 24,
    minWidth: 'min(640px, 95vw)',
  },
  backdrop: {
    backgroundColor: 'transparent',
  },
}))

export const useNewThreadModalContext = () => {
  const context = useContext(NewThreadModalContext)

  if (context === undefined) throw new Error('useNewThreadModalContext should be used within a NewThreadModalProvider ')

  return context
}

export const NewThreadModal = () => {
  const classes = useNewThreadModalStyles()
  const { isOpen, closeModal } = useNewThreadModalContext()
  const { isFullScreen } = useFullScreen()

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: classes.root }}
      onClose={closeModal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
      maxWidth="md"
      fullScreen={isFullScreen}
    >
      <NewThreadModalContent />
    </Dialog>
  )
}
