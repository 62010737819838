import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      borderTop: '1px solid #e5e5e5',
      padding: '18px 18px 12px 0',
    },
    value: {
      color: theme.threadPalette.menuItem.current,
      display: 'inline-block',
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: '20px',
      position: 'relative',
      textTransform: 'uppercase',
    },
    unread: {
      color: theme.palette.error.main,
      fontSize: 14,
      marginLeft: 3,
      lineHeight: '20px',
      fontWeight: 'bold',
    },
  }
})

interface IUnreadDividerProps {
  unreadCounter: number
  className?: string
}

const UnreadDivider = ({ unreadCounter, className }: IUnreadDividerProps) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.container, className)}>
      <span className={classes.value}>Unread&nbsp;</span>
      <span className={classes.unread}>({unreadCounter})</span>
    </div>
  )
}

export default UnreadDivider
