import * as React from 'react'
import RightSidebarHeader from '../rightSidebarHeader'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectRightSidebar } from '../../selectors/rightSidebar'
import ActivityHistory from './threadActivity/activityHistory'
import ThreadFollowersList from './threadFollowersList'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      height: '100%',
      border: `solid 1px ${theme.threadPalette.lighterGray}`,
      overflow: 'hidden',
    },
    sidebarContainer: {
      alignSelf: 'flex-end',
      width: theme.dimensions.rightSidebar.maxWidth,
    },
    sidebarContent: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
    },
  }
})

const sidebarContent = (option: TRightSidebarOption) => {
  switch (option) {
    case 'none': {
      return <React.Fragment />
    }
    case 'activity': {
      return <ActivityHistory />
    }
    case 'emailViews': {
      return <></>
    }
    case 'files': {
      return <></>
    }
    case 'search': {
      return <div></div>
    }
    case 'followers': {
      return <ThreadFollowersList />
    }
    case 'teamSpace': {
      return <div></div>
    }
  }
}

interface IRightSidebarProps {}

const RightSidebar = (props: IRightSidebarProps) => {
  const classes = useStyles()

  const rightSidebarOption = useSelector(selectRightSidebar).optionSelected
  const slideChildren = sidebarContent(rightSidebarOption)

  return (
    <div className={classes.root}>
      <RightSidebarHeader title={rightSidebarOption} />
      <div className={classes.sidebarContent}>{slideChildren}</div>
    </div>
  )
}

export default RightSidebar
