import * as React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core'
import DragDropImg from 'assets/drag_and_drop.png'
import classNames from 'classnames'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootOverlay: {
      alignItems: 'center',
      backgroundColor: 'black',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      opacity: 0.6,
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: theme.zIndex.drawer,
    },

    image: {
      alignSelf: 'flex-end',
      maxWidth: 'inherit',
      minWidth: '100px',
      width: '50%',
      zIndex: theme.zIndex.drawer,
    },
  })
)

interface IDragDropOverlay {
  className?: string
}

const DragDropOverlay = ({ className }: IDragDropOverlay) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.rootOverlay, className)}>
      <img src={DragDropImg} alt="drag&drop overlay" className={classes.image} />
    </div>
  )
}

export default DragDropOverlay
