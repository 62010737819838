import React from 'react'
import { SharingGroupScreenHeader } from '../../common/sharingGroupScreenHeader'
import { Theme, makeStyles } from '@material-ui/core'
import { People as GroupIcon } from '@material-ui/icons'
import InlineEditingTextField from 'shared/inputs/inlineEditingTextField'
import { PeopleSelect } from 'component/peopleSelect/peopleSelect'
import { useBoolean } from 'hooks/useBoolean'
import { SharingGroupMemberChip } from 'component/sharingGroup/common/sharingGroupMemberChip'
import SubmitButtonWithLoading from 'component/submitButtonWithLoading'
import { ISharingGroup } from 'model/sharingGroup'
import { useCreateSharingGroupUseCase } from 'usecases/sharingGroup/createSharingGroup'
import { useCreateOrEditSharingGroup } from 'hooks/useSharingGroup/useCreateOrEditSharingGroup'
import { ExistingSharingGroupWithSameNameError } from 'component/sharingGroup/common/existingSharingGroupWithSameNameError'

const useCreateSharingGroupScreenStyles = makeStyles((theme: Theme) => ({
  createSharingGroupScreenRoot: {
    maxWidth: 600,
    paddingInline: 20,
    paddingBottom: 20,
  },
  sharingGroupNameContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
  },
  groupIcon: {
    fill: theme.threadPalette.menuItem.current,
    fontSize: 28,
  },
  groupNameInput: {
    fontSize: 18,
    height: 36,
  },
  editGroupNameIcon: {
    color: theme.threadPalette.lightGray,
    fontSize: 16,
  },
  sharingGroupNameInput: {
    fontSize: 22,
    fontWeight: 500,
    height: '100%',
  },
  groupNameLabel: {
    fontSize: 22,
    fontWeight: 700,
  },
  selectMembersInputContainer: {
    border: `solid 1.5px ${theme.palette.primary.main}`,
    borderRadius: 4,
    marginTop: 20,
    width: '100%',
  },
  selectedMembersContainer: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    columnGap: 8,
    marginTop: 16,
    rowGap: 6,
  },
  submitButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  createGroupButton: {
    alignSelf: 'end',
    fontSize: 16,
    minHeight: 48,
    minWidth: 148,
    paddingBlock: 10,
    paddingInline: 28,
  },
}))

interface ICreateSharingGroupScreen {
  initialName?: string
  onReturn?(): void
  onSuccess?(sharingGroup: ISharingGroup): void
}
export const CreateSharingGroupScreen = ({ initialName = '', onReturn, onSuccess }: ICreateSharingGroupScreen) => {
  const classes = useCreateSharingGroupScreenStyles()

  const {
    sharingGroupName,
    updateSharingGroupName,
    sharingGroupMembers,
    updateSharingGroupMembers,
    userHasOtherSharingGroupsWithSameName,
    isValidSharingGroup,
    filterCurrentUserFromMembersOptions,
  } = useCreateOrEditSharingGroup({ initialName })

  const { state: isPeopleSelectorInputVisible, setTrue: displayPeopleSelectorInput } = useBoolean(false)
  const { createSharingGroupUseCase, isLoading: isCreatingSharingGroup } = useCreateSharingGroupUseCase({
    onSuccess,
  })

  const onSubmit = () => {
    const sharingGroupMembersEmails = sharingGroupMembers.map(member => member.email)
    createSharingGroupUseCase({ name: sharingGroupName, membersEmails: sharingGroupMembersEmails })
  }

  const onConfirmGroupName = (newName?: string) => {
    updateSharingGroupName(newName ?? '')
    if (!isPeopleSelectorInputVisible) {
      displayPeopleSelectorInput()
    }
  }

  const onSharingGroupMemberDeleted = (person: TPerson) => {
    const updatedSharingGroupMembers = sharingGroupMembers.filter(member => member.email !== person.email)
    updateSharingGroupMembers(updatedSharingGroupMembers)
  }

  const hasSelectedSharingGroupMembers = sharingGroupMembers.length > 0

  return (
    <div className={classes.createSharingGroupScreenRoot}>
      <SharingGroupScreenHeader text="Create a group" onReturnToPreviousScreenClick={onReturn} />
      <div className={classes.sharingGroupNameContainer}>
        <GroupIcon className={classes.groupIcon} />
        <InlineEditingTextField
          autoFocus
          hideCancelEditingButton={!isPeopleSelectorInputVisible}
          onConfirmEdit={onConfirmGroupName}
          inputProps={{ defaultValue: sharingGroupName, placeholder: 'Name your group' }}
          inputContainerClassName={classes.sharingGroupNameInput}
          inputClassName={classes.groupNameInput}
          editIconClassName={classes.editGroupNameIcon}
          labelClassName={classes.groupNameLabel}
          hasError={userHasOtherSharingGroupsWithSameName}
        >
          {sharingGroupName}
        </InlineEditingTextField>
      </div>
      {userHasOtherSharingGroupsWithSameName && (
        <ExistingSharingGroupWithSameNameError sharingGroupName={sharingGroupName} />
      )}
      {isPeopleSelectorInputVisible && (
        <div>
          <PeopleSelect
            placeholder="Add people to this group"
            rootClassName={classes.selectMembersInputContainer}
            selectedPeople={sharingGroupMembers}
            onPeopleChange={updateSharingGroupMembers}
            peopleOptionsFilter={filterCurrentUserFromMembersOptions}
            isFocusedOnRendering
          />
          {hasSelectedSharingGroupMembers && (
            <div className={classes.selectedMembersContainer}>
              {sharingGroupMembers.map(person => (
                <SharingGroupMemberChip
                  person={person}
                  key={person.email}
                  onDelete={() => onSharingGroupMemberDeleted(person)}
                />
              ))}
            </div>
          )}
        </div>
      )}
      <div className={classes.submitButtonContainer}>
        <SubmitButtonWithLoading
          className={classes.createGroupButton}
          variant="contained"
          color="primary"
          disabled={!isValidSharingGroup || !isPeopleSelectorInputVisible}
          onClick={onSubmit}
          isLoading={isCreatingSharingGroup}
        >
          Create group
        </SubmitButtonWithLoading>
      </div>
    </div>
  )
}
