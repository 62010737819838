import React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'

const useBaseHeaderWithActionsStyles = makeStyles((theme: Theme) => ({
  baseHeaderRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  baseHeaderContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  actionsContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: 2,
  },
  footerRoot: {
    alignSelf: 'center',
  },
}))

interface BaseHeaderWithActionsProps {
  actions?: React.ReactNode[]
  footer?: React.ReactNode
}
export const BaseHeaderWithActions: React.FC<BaseHeaderWithActionsProps> = ({ children, actions, footer }) => {
  const classes = useBaseHeaderWithActionsStyles()

  return (
    <div className={classes.baseHeaderRoot}>
      <div className={classes.baseHeaderContent}>
        {children}
        <div className={classes.actionsContainer}>
          {actions?.map((action, index) => <React.Fragment key={index}>{action}</React.Fragment>)}
        </div>
      </div>
      {footer && <div className={classes.footerRoot}>{footer}</div>}
    </div>
  )
}
