import React from 'react'
import { Dialog, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFullScreen } from 'hooks/useFullScreen'
import { useSharingGroupContext } from 'contexts/sharingGroupContext'
import { useUser } from 'hooks'
import { ConnectSharingGroupScreen } from './screens/connectSharingGroupScreen/connectSharingGroupScreen'
import { CreateOrEditThreadSharingSettingsScreen } from './screens/createOrEditThreadSharingSettingsScreen/createOrEditThreadSharingSettingsScreen'
import { ThreadSharingSettingsFormFieldsContextProvider } from './screens/createOrEditThreadSharingSettingsScreen/useThreadSharingSettingsFormFieldsContext'
import { ISharingGroup, IThreadSharingSettings } from 'model/sharingGroup'
import classNames from 'classnames'
import { CreateSharingGroupScreen } from './screens/createSharingGroupScreen/createSharingGroupScreen'
import { EditSharingGroupScreen } from './screens/editSharingGroupScreen/editSharingGroupScreen'
import { ReviewHeldMessagesScreen } from './screens/reviewHeldMessagesScreen/reviewHeldMessagesScreen'
import { IndividualMessageSharingScreen } from './screens/individualMessageSharingScreen/individualMessageSharingScreen'

const useSharingGroupBaseModalStyles = makeStyles(theme => ({
  sharingGroupModalPaper: {
    borderRadius: 18,
    maxHeight: 'unset',
    maxWidth: '100%',
    minWidth: 500,
    overflowY: 'unset',
    position: 'relative',
  },
  closeIconWrapper: {
    color: theme.threadPalette.white,
    cursor: 'pointer',
    position: 'absolute',
    right: -20,
    top: -20,
    '&:hover': {
      color: theme.threadPalette.lightGray,
    },
  },
  closeIconWrapperFullScreen: {
    aspectRatio: '1/1',
    color: theme.threadPalette.menuItem.current,
    borderRadius: 100,
    display: 'flex',
    paddingInline: 2,
    placeItems: 'center',
    right: 2,
    top: 2,
    '&:hover': {
      backgroundColor: theme.threadPalette.brownishGray,
      color: theme.threadPalette.white,
    },
  },
  closeIcon: {
    height: 26,
    width: 26,
  },
}))

export const SharingGroupModal = () => {
  const classes = useSharingGroupBaseModalStyles()

  const currentUser = useUser()
  const { isFullScreen } = useFullScreen()
  const {
    isSharingGroupOpen,
    closeSharingGroup,
    ActiveScreen,
    setActiveScreen,
    sharingGroupScreenToOpen,
    thread,
    messageToShare,
    focusedHeldMessage,
  } = useSharingGroupContext()

  const goToConnectSharingGroupScreen = ({ autoFocusInput }: { autoFocusInput?: boolean } = {}) => {
    const connectSharingGroupScreen = (
      <ConnectSharingGroupScreen
        onShareThreadToSharingGroup={(sharingGroup: ISharingGroup) =>
          goToCreateOrEditThreadSharingSettingsScreen({ sharingGroup })
        }
        onEditExistingSharingGroup={(sharingGroup: ISharingGroup) => goToEditSharingGroupScreen({ sharingGroup })}
        onEditThreadSharingSettings={(threadSharingSettings: IThreadSharingSettings) =>
          goToCreateOrEditThreadSharingSettingsScreen({
            sharingGroup: threadSharingSettings.sharingGroup,
            threadSharingSettings,
          })
        }
        onCreateNewSharingGroupByName={(name: string) => goToCreateSharingGroupScreen({ initialName: name })}
        onReviewHeldMessages={() => goToReviewHeldMessagesScreen(thread)}
        isInputAutoFocused={autoFocusInput}
      />
    )
    setActiveScreen(connectSharingGroupScreen)
  }

  const goToCreateSharingGroupScreen = ({
    initialName,
    initialSharedMessagesIds,
    onReturn,
  }: {
    initialName: string
    initialSharedMessagesIds?: string[]
    onReturn?(): void
  }) => {
    const goToCreateThreadSharingSettingsScreen = (sharingGroup: ISharingGroup) =>
      goToCreateOrEditThreadSharingSettingsScreen({
        sharingGroup,
        initialSharedMessagesIds,
      })
    const createSharingGroupScreen = (
      <CreateSharingGroupScreen
        initialName={initialName}
        onReturn={onReturn ?? goToConnectSharingGroupScreen}
        onSuccess={goToCreateThreadSharingSettingsScreen}
      />
    )
    setActiveScreen(createSharingGroupScreen)
  }

  const goToEditSharingGroupScreen = ({
    sharingGroup,
    onReturn,
    onSuccess,
  }: {
    sharingGroup: ISharingGroup
    onReturn?(): void
    onSuccess?(): void
  }) => {
    const editSharingGroupScreen = (
      <EditSharingGroupScreen
        sharingGroup={sharingGroup}
        onReturn={onReturn ?? goToConnectSharingGroupScreen}
        onSuccess={onSuccess ?? goToConnectSharingGroupScreen}
      />
    )
    setActiveScreen(editSharingGroupScreen)
  }

  const goToCreateOrEditThreadSharingSettingsScreen = ({
    sharingGroup,
    threadSharingSettings,
    initialSharedMessagesIds,
    onReturn,
  }: {
    sharingGroup: ISharingGroup
    threadSharingSettings?: IThreadSharingSettings
    initialSharedMessagesIds?: string[]
    onReturn?(): void
  }) => {
    const sharedMessagesIds = initialSharedMessagesIds ?? threadSharingSettings?.sharedMessagesIds

    const createOrEditThreadSharingSettingsScreen = (
      <ThreadSharingSettingsFormFieldsContextProvider
        selectedMessagesIdsInitialValue={sharedMessagesIds}
        autoShareConditionsInitialValue={threadSharingSettings?.autoShareConditions}
      >
        <CreateOrEditThreadSharingSettingsScreen
          sharingGroup={sharingGroup}
          threadSharingSettings={threadSharingSettings}
          onUpdateThreadSharingSettings={goToConnectSharingGroupScreen}
          onReturn={onReturn ?? goToConnectSharingGroupScreen}
          onStopSharing={goToConnectSharingGroupScreen}
        />
      </ThreadSharingSettingsFormFieldsContextProvider>
    )
    setActiveScreen(createOrEditThreadSharingSettingsScreen)
  }

  const goToReviewHeldMessagesScreen = (thread: IThread, message?: IMessage) => {
    if (!currentUser) return
    const reviewHeldMessagesScreen = (
      <ReviewHeldMessagesScreen
        thread={thread}
        returnToPreviousScreenAction={goToConnectSharingGroupScreen}
        onClose={closeSharingGroup}
        user={currentUser}
        focusedHeldMessage={message}
      />
    )
    setActiveScreen(reviewHeldMessagesScreen)
  }

  const goToIndividualMessageSharingScreen = (message: IMessage) => {
    const returnToMessageSharingScreen = () => goToIndividualMessageSharingScreen(message)

    const shareMessageScreen = (
      <IndividualMessageSharingScreen
        message={message}
        thread={thread}
        onEditExistingSharingGroup={(sharingGroup: ISharingGroup) =>
          goToEditSharingGroupScreen({
            sharingGroup,
            onReturn: returnToMessageSharingScreen,
            onSuccess: returnToMessageSharingScreen,
          })
        }
        onShareMessageToSharingGroup={({ sharingGroup, threadSharingSettings, sharedMessagesIds }) =>
          goToCreateOrEditThreadSharingSettingsScreen({
            sharingGroup,
            threadSharingSettings,
            initialSharedMessagesIds: sharedMessagesIds,
            onReturn: returnToMessageSharingScreen,
          })
        }
        onCreateNewSharingGroupByName={({ name, initialSharedMessagesIds }) => {
          goToCreateSharingGroupScreen({
            initialName: name,
            initialSharedMessagesIds,
            onReturn: returnToMessageSharingScreen,
          })
        }}
      />
    )

    setActiveScreen(shareMessageScreen)
  }

  if (!currentUser) return null

  if (!ActiveScreen && isSharingGroupOpen) {
    switch (sharingGroupScreenToOpen) {
      case 'createOrEditThreadSharingSettingsScreen': {
        goToConnectSharingGroupScreen({ autoFocusInput: true })
        break
      }
      case 'reviewHeldMessagesScreen': {
        goToReviewHeldMessagesScreen(thread, focusedHeldMessage)
        break
      }
      case 'individualMessageSharingScreen': {
        if (!messageToShare) break
        goToIndividualMessageSharingScreen(messageToShare)
        break
      }
    }
  }

  return (
    <Dialog
      open={isSharingGroupOpen}
      classes={{ paper: classes.sharingGroupModalPaper }}
      fullScreen={isFullScreen}
      transitionDuration={{ exit: 0 }}
    >
      <span
        className={classNames(classes.closeIconWrapper, { [classes.closeIconWrapperFullScreen]: isFullScreen })}
        onClick={closeSharingGroup}
      >
        <CloseIcon className={classes.closeIcon} />
      </span>
      {ActiveScreen}
    </Dialog>
  )
}
