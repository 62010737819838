import { useQuery } from '@tanstack/react-query'
import { getDraftMessageQueryKey } from './useDraftCacheActions'
import { getDraft } from 'api/message'
import { ServerError } from 'api/errors/serverError'
import { useUser } from 'hooks/auth'

export const useDraft = (draftId: string) => {
  const currentUser = useUser()

  const { data: draftMessage, isLoading } = useQuery({
    enabled: !!currentUser?.id && !!draftId,
    queryKey: getDraftMessageQueryKey({ userId: currentUser?.id ?? '', draftId }),
    queryFn: () => getDraft(draftId),
    retry: (failureCount, error: ServerError) => {
      const isNotFoundError = error?.response?.data?.code === 404
      return isNotFoundError ? false : failureCount < 3
    },
  })

  return { draftMessage, isLoading }
}
