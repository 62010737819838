import React from 'react'
import PlusIcon from '@material-ui/icons/Add'
import ReadStateIcon from 'icons/unreadIcon'
import StarIcon from '@material-ui/icons/Star'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import UnfollowIcon from '@material-ui/icons/VisibilityOff'
import { useStarThreadUseCase } from 'usecases/starThread'
import { useFollowThreadUseCase } from 'usecases/followThread'
import { useUnreadThreadUseCase } from 'usecases/unreadThread'
import { useUnfollowThreadUseCase } from 'usecases/unfollowThread'
import { useReadThreadUseCase } from 'usecases/readThread'

export type TThreadMenuItemType = 'addProject' | 'unread' | 'read' | 'star' | 'unstar' | 'follow' | 'unfollow'

interface IThreadMenuItem {
  type: TThreadMenuItemType
  label: string
  icon: React.FC<any>
}

interface IUseThreadMenuItems {
  threadId: string
  isStarred: boolean
  unreadCount: number
  stateForUser: TThreadStateForUser
  onConnectThreadToProject: () => void
}

export const useThreadMenuItems = ({
  threadId,
  isStarred,
  unreadCount,
  stateForUser,
  onConnectThreadToProject,
}: IUseThreadMenuItems) => {
  const { starThreadUseCase } = useStarThreadUseCase()
  const { followThreadUseCase } = useFollowThreadUseCase()
  const { unreadThreadUseCase } = useUnreadThreadUseCase()
  const { unfollowThreadUseCase } = useUnfollowThreadUseCase()
  const { readThreadUseCase } = useReadThreadUseCase()

  const menuItemsRecord: Record<TThreadMenuItemType, Pick<IThreadMenuItem, 'label' | 'icon'>> = React.useMemo(() => {
    return {
      addProject: {
        label: 'Add to a project',
        icon: PlusIcon,
      },
      unread: {
        label: 'Mark as unread',
        icon: ReadStateIcon,
      },
      read: {
        label: 'Mark as read',
        icon: ReadStateIcon,
      },
      star: {
        label: 'Star',
        icon: StarIcon,
      },
      unstar: {
        label: 'Remove star',
        icon: StarIcon,
      },
      follow: {
        label: 'Follow',
        icon: VisibilityOutlinedIcon,
      },
      unfollow: {
        label: 'Unfollow',
        icon: UnfollowIcon,
      },
    }
  }, [])

  const isUnread = React.useMemo(() => {
    return unreadCount !== 0
  }, [unreadCount])

  const menuItemsTypeForCurrentThread = React.useMemo(() => {
    const menuItemsType: TThreadMenuItemType[] = ['addProject']

    menuItemsType.push(isUnread ? 'read' : 'unread')
    menuItemsType.push(isStarred ? 'unstar' : 'star')
    menuItemsType.push(stateForUser === 'following' ? 'unfollow' : 'follow')

    return menuItemsType
  }, [isStarred, isUnread, stateForUser])

  const menuItemsListForCurrentThread: IThreadMenuItem[] = React.useMemo(() => {
    return menuItemsTypeForCurrentThread.map(menuItemType => {
      const menuItem = menuItemsRecord[menuItemType]
      return { ...menuItem, type: menuItemType }
    })
  }, [menuItemsRecord, menuItemsTypeForCurrentThread])

  const onMenuItemClick = React.useCallback(
    (menuItemType: TThreadMenuItemType) => {
      const menuItemTypeToUseCaseMapping: Record<TThreadMenuItemType, () => void> = {
        addProject: () => onConnectThreadToProject(),
        follow: () => followThreadUseCase(threadId),
        unfollow: () => unfollowThreadUseCase({ threadId }),
        star: () => starThreadUseCase(threadId, true),
        unstar: () => starThreadUseCase(threadId, false),
        read: () => readThreadUseCase(threadId),
        unread: () => unreadThreadUseCase(threadId),
      }
      const menuItemUseCase = menuItemTypeToUseCaseMapping[menuItemType]
      menuItemUseCase?.()
    },
    [
      threadId,
      followThreadUseCase,
      unfollowThreadUseCase,
      starThreadUseCase,
      readThreadUseCase,
      unreadThreadUseCase,
      onConnectThreadToProject,
    ]
  )

  return {
    onMenuItemClick,
    menuItemsList: menuItemsListForCurrentThread,
  }
}
