import * as React from 'react'
import { Button, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { FilterButton } from './filterButton'
import StarIcon from '@material-ui/icons/Star'
import UnreadIcon from 'icons/unreadIcon'
import classnames from 'classnames'
import { useThreadsViewHeaderSettings } from './useThreadsViewHeadersSettings'
import { OnboardingTourTargetComponent } from 'domain/onboardingTourStep'

const useThreadsViewHeaderStyles = makeStyles((theme: Theme) => {
  return {
    threadsViewHeaderRoot: {
      display: 'flex',
      flexDirection: 'column',
      paddingInline: '15px',
      gap: '4px',
    },
    threadsHeaderFirstListRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      height: '35px',
      justifyContent: 'space-around',
    },
    newThreadButton: {
      color: theme.threadPalette.orange,
      fontSize: '12px',
      width: '100px',
    },
    smallFilterButtonsWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '50%',
      gap: '10px',
    },
    stateForUserFiltersRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    newThreadButtonWrapper: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      width: '50%',
    },
    filterIconInctive: {
      opacity: '0.2',
    },
    starIcon: {
      color: theme.threadPalette.star,
      height: '14px',
      width: '14px',
    },
    filterButtonText: {
      fontSize: '10px',
    },
    filterButtonWithIcon: {
      height: '22px',
      minWidth: '22px',
      padding: '0px',
      width: '22px',
    },
    filterButtonWithText: {
      height: '24px',
      minWidth: '65px',
      width: '65px',
    },
    follwingIcon: {
      height: '10px',
      width: '10px',
    },
    unreadIcon: {
      height: '12px',
      width: '12px',
    },
    starIconFiltersList: {
      fill: theme.threadPalette.star,
      height: '14px',
      marginLeft: '-2px',
      marginRight: '3px',
      width: '14px',
    },
    starIconFilterBadge: {
      fill: theme.threadPalette.star,
      height: '12px',
      width: '12px',
    },
    viewAccessIconFiltersList: {
      fill: theme.threadPalette.lightBlue,
      height: '12px',
      marginLeft: '-1px',
      marginRight: '4px',
      width: '12px',
    },
    viewAccessFilterBadge: {
      fill: theme.threadPalette.lightBlue,
      height: '12px',
      width: '12px',
    },
    unfollowFilterBadge: {
      fill: theme.threadPalette.lightGray,
      height: '12px',
      width: '12px',
    },
    unfollowIconFiltersList: {
      fill: theme.threadPalette.brownishGray,
      height: '12px',
      marginRight: '4px',
      width: '12px',
    },
  }
})

interface IThreadsViewHeaderProps {
  onNewThread(): void
  changeThreadsFilterBy: (newFilterBy: TThreadsFilterBy[]) => void
}

export const ThreadsViewHeader = ({ onNewThread, changeThreadsFilterBy }: IThreadsViewHeaderProps) => {
  const classes = useThreadsViewHeaderStyles()

  const {
    filterByStarred,
    toggleFilterByStarred,
    filterByUnread,
    toggleFilterByUnread,
    filterByFollowed,
    toggleFilterByFollowed,
    filterByUnfollowed,
    toggleFilterByUnfollowed,
    filterByViewed,
    toggleFilterByViewed,
  } = useThreadsViewHeaderSettings()

  React.useEffect(() => {
    const newFilterBy: TThreadsFilterBy[] = []

    if (filterByFollowed) newFilterBy.push('following')
    if (filterByUnfollowed) newFilterBy.push('unfollowed')
    if (filterByViewed) newFilterBy.push('viewed')
    if (filterByStarred) newFilterBy.push('starred')
    if (filterByUnread) newFilterBy.push('unread')

    changeThreadsFilterBy(newFilterBy)
  }, [filterByFollowed, filterByUnfollowed, filterByViewed, filterByStarred, filterByUnread])

  return (
    <div className={classes.threadsViewHeaderRoot}>
      <div className={classes.threadsHeaderFirstListRoot}>
        <div className={classes.smallFilterButtonsWrapper}>
          <FilterButton
            children={StarIcon}
            isActive={filterByStarred}
            onClick={toggleFilterByStarred}
            onActiveClassName={classes.starIcon}
            onInactiveClassName={classnames(classes.starIcon, classes.filterIconInctive)}
            rootClassName={classes.filterButtonWithIcon}
          />
          <FilterButton
            children={UnreadIcon}
            isActive={filterByUnread}
            onClick={toggleFilterByUnread}
            onActiveClassName={classes.unreadIcon}
            onInactiveClassName={classnames(classes.unreadIcon, classes.filterIconInctive)}
            rootClassName={classes.filterButtonWithIcon}
          />
        </div>
        <div className={classes.newThreadButtonWrapper}>
          <Button
            id={OnboardingTourTargetComponent.createNewThreadButton}
            classes={{ root: classes.newThreadButton }}
            onClick={onNewThread}
          >
            + New Thread
          </Button>
        </div>
      </div>
      <div className={classes.stateForUserFiltersRoot}>
        <FilterButton
          children={() => <Typography className={classes.filterButtonText}>Followed</Typography>}
          isActive={filterByFollowed}
          onClick={toggleFilterByFollowed}
          rootClassName={classes.filterButtonWithText}
        />
        <FilterButton
          children={() => <Typography className={classes.filterButtonText}>Unfollowed</Typography>}
          isActive={filterByUnfollowed}
          onClick={toggleFilterByUnfollowed}
          rootClassName={classes.filterButtonWithText}
        />
        <FilterButton
          children={() => <Typography className={classes.filterButtonText}>Viewed</Typography>}
          isActive={filterByViewed}
          onClick={toggleFilterByViewed}
          rootClassName={classes.filterButtonWithText}
        />
      </div>
    </div>
  )
}
