import React from 'react'
import { Theme, Typography, makeStyles } from '@material-ui/core'
import { OnboardingStepTextLines } from './onboardingStepTextLines'

const useOnboardingStepWithImageStyles = makeStyles<Theme, { padding?: number }>((theme: Theme) => ({
  onboardingStepWithImageRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    paddingInline: ({ padding }) => padding ?? 20,
    paddingTop: ({ padding }) => padding ?? 20,
  },
  headerText: {
    fontSize: 24,
    fontWeight: 500,
    maxWidth: 450,
    textAlign: 'center',
  },
  stepNumber: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
  },
}))

interface IOnboardingStepWithImageProps {
  image: JSX.Element
  headerText: string | JSX.Element
  stepNumber?: number
  topTextLines?: JSX.Element[]
  bottomTextLines?: JSX.Element[]
  textLinesMaxWidth?: number
  lineHeight?: number
  padding?: number
}

export const OnboardingStepWithImage = ({
  image,
  stepNumber,
  headerText,
  topTextLines,
  bottomTextLines,
  textLinesMaxWidth,
  lineHeight,
  padding,
}: IOnboardingStepWithImageProps) => {
  const classes = useOnboardingStepWithImageStyles({ padding })

  return (
    <div className={classes.onboardingStepWithImageRoot}>
      <Typography className={classes.headerText}>{headerText}</Typography>
      {stepNumber && <Typography className={classes.stepNumber}>Step {stepNumber}</Typography>}
      {topTextLines && (
        <OnboardingStepTextLines textLines={topTextLines} maxWidth={textLinesMaxWidth} lineHeight={lineHeight} />
      )}
      {image}
      {bottomTextLines && (
        <OnboardingStepTextLines textLines={bottomTextLines} maxWidth={textLinesMaxWidth} lineHeight={lineHeight} />
      )}
    </div>
  )
}
