import * as React from 'react'
import classnames from 'classnames'
import { useStyles } from './threadHeaderBar.style'
import ThreadFollowersBar from 'component/threadFollowersBar'
import ThreadTitle from '../threadTitle/threadTitle'
import { useDispatch } from 'react-redux'
import AddIntegration from './addIntegration/addIntegration'
import ThreadProjects from './threadProjects/threadProjects'
import { useStarThreadUseCase } from 'usecases/starThread'
import { useUpdateThreadAliasUseCase } from 'usecases/updateThreadAlias'
import { useThreadProjects } from 'hooks/useThreadProjects/useThreadProjects'
import { ThreadFollowBadge } from './threadFollowBadge/threadFollowBadge'
import Spacer from 'component/layoutUtils/spacer'
import { useUnfollowThreadUseCase } from 'usecases/unfollowThread'
import { useFollowThreadUseCase } from 'usecases/followThread'
import { useDisplayAttachmentContext } from 'contexts/displayAttachmentContext'
import { StarButton } from 'component/starButton'
import { OnboardingTourTargetComponent } from 'domain/onboardingTourStep'

interface IThreadHeaderBarProps {
  className?: string
  currentThread: IThread
}

const ThreadHeaderBar = ({ className, currentThread }: IThreadHeaderBarProps) => {
  const classes = useStyles()

  const { isAttachmentSidebarOpen } = useDisplayAttachmentContext()

  const dispatch = useDispatch()
  const { starThreadUseCase } = useStarThreadUseCase()
  const { updateThreadAliasUseCase } = useUpdateThreadAliasUseCase()
  const { unfollowThreadUseCase, isLoading: isProcessingUnfollowing } = useUnfollowThreadUseCase()
  const { followThreadUseCase, isLoading: isProcessingFollowing } = useFollowThreadUseCase()

  const { projects: currentThreadProjects } = useThreadProjects({ threadId: currentThread.id })

  const onStarClick = React.useCallback(() => {
    starThreadUseCase(currentThread.id, !currentThread.isStarred)
  }, [dispatch, currentThread])

  const onThreadAliasChange = async (newThreadAlias?: string) => {
    updateThreadAliasUseCase({ threadId: currentThread.id, titleAlias: newThreadAlias })
  }

  const onFollowBadgeClick = React.useCallback(() => {
    if (isProcessingUnfollowing || isProcessingFollowing) return

    if (currentThread.stateForUser === 'following') {
      unfollowThreadUseCase({
        threadId: currentThread.id,
        isUserAThreadDirectRecipient: currentThread.isUserAThreadDirectRecipient,
      })
    } else {
      followThreadUseCase(currentThread.id)
    }
  }, [unfollowThreadUseCase, currentThread?.stateForUser, isProcessingUnfollowing, isProcessingFollowing])

  return (
    <div className={classnames(classes.root, classes.headerStyle, className)}>
      <div className={classes.titleWrapper}>
        <ThreadTitle
          id={OnboardingTourTargetComponent.threadAlias}
          onThreadAliasChange={onThreadAliasChange}
          title={currentThread.title}
          alias={currentThread.titleAlias}
        />
        <Spacer width={4} />
        <StarButton onClick={onStarClick} isStarred={currentThread.isStarred} />
        <Spacer width={8} />
        <ThreadFollowBadge
          stateForUser={currentThread.stateForUser}
          onClick={onFollowBadgeClick}
          id={OnboardingTourTargetComponent.threadFollowBadge}
        />
        <Spacer width={8} />
        <AddIntegration id={OnboardingTourTargetComponent.addIntegration} className={classes.iconWrapper} />
        <Spacer width={8} />
        <ThreadProjects threadId={currentThread.id} projects={currentThreadProjects} />
      </div>
      {!isAttachmentSidebarOpen && <ThreadFollowersBar />}
    </div>
  )
}

export default ThreadHeaderBar
