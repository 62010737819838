import * as React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import Toolbar from './toolbar'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  })
)

interface IMessageEditorFooterProps {
  sendButton: React.ReactNode
  onEmojiSelected?(emoji: string): void
  onUploadAttachments?(files: File[]): Promise<void>
  onMentionsClick?(): void
  onUndo?(): void
  onRedo?(): void
  toolbarId?: string
}

const MessageEditorFooter = ({
  onEmojiSelected,
  sendButton,
  onUploadAttachments,
  onMentionsClick,
  onUndo,
  onRedo,
  toolbarId = 'first-message',
}: IMessageEditorFooterProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Toolbar
        id={toolbarId}
        onEmojiSelected={onEmojiSelected}
        onUploadAttachments={onUploadAttachments}
        onMentionsClick={onMentionsClick}
        onUndo={onUndo}
        onRedo={onRedo}
      />
      {sendButton}
    </div>
  )
}

export default MessageEditorFooter
