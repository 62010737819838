import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface IStrikeIconProps extends JSX.IntrinsicAttributes {
  className?: string
}

const StrikeIcon = ({ className }: IStrikeIconProps) => {
  return (
    <SvgIcon className={className} viewBox="-1 -2.5 18 18">
      <path
        fill="#606060"
        fillRule="evenodd"
        d="M13.4 8h-1.556c.344.588.556 1.266.556 1.998 0 2.21-1.792 4.002-4 4.002H6.23c-.718 0-1.38-.208-1.962-.536-.166.314-.49.536-.868.536h-.5c-.276 0-.5-.226-.5-.5v-3.004c0-.273.224-.5.5-.5h.5c.438 0 .798.284.936.678l.024-.006c.278.774 1 1.328 1.87 1.328H8.4c1.106 0 2-.89 2-2 0-1.1-.894-1.996-2-1.996H1.398C.846 8 .4 7.552.4 7c0-.554.446-1 .998-1h1.558C2.612 5.41 2.4 4.73 2.4 4c0-2.208 1.792-4 4-4h2.174c.714 0 1.376.208 1.958.534C10.7.22 11.022 0 11.4 0h.5c.278 0 .5.224.5.5v3c0 .278-.222.5-.5.5h-.5c-.436 0-.798-.284-.938-.678l-.022.008C10.162 2.556 9.44 2 8.574 2H6.4c-1.106 0-2 .894-2 2 0 1.106.894 2 2 2h7c.552 0 1 .446 1 1 0 .552-.448 1-1 1z"
      />
    </SvgIcon>
  )
}

export default StrikeIcon
