import { apiClient } from '@jetkit/react'
import { IUserOnboarding } from 'model/onboarding'

const BASE_URI = '/v2/onboarding'

export const getUserOnboardings = async (): Promise<IUserOnboarding[]> => {
  const result = await apiClient.get<IUserOnboarding[]>(BASE_URI)
  return result.data
}

export const completeOnboarding = async (onboardingId: string): Promise<void> => {
  const result = await apiClient.post(`${BASE_URI}/${onboardingId}/complete`)
  return result.data
}
