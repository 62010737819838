import { useDispatch } from 'react-redux'
import { reimportMessage as reimportMessageApiCall } from 'api/message'
import { showSnackbar } from 'actions/snackbar'
import { useThreadMessagesCacheActions } from 'hooks/useThreadMessages/useThreadMessagesCacheActions'
import { useMutation } from '@tanstack/react-query'

type IReimportMessageUseCaseProps = Normalize<Parameters<typeof reimportMessageApiCall>[0] & { threadId: string }>

export const useReimportMessageUseCase = () => {
  const dispatch = useDispatch()

  const threadMessagesCacheActions = useThreadMessagesCacheActions()

  const { mutateAsync } = useMutation(
    ({ messageId }: IReimportMessageUseCaseProps) => reimportMessageApiCall({ messageId }),
    {
      onError: () => {
        dispatch(showSnackbar({ message: 'Failed to re-import message' }))
      },
      onSettled: (_, __, { threadId }) => {
        threadMessagesCacheActions.refreshData(threadId)
      },
    }
  )

  const reimportMessageUseCase: (props: IReimportMessageUseCaseProps) => Promise<void> = mutateAsync

  return {
    reimportMessageUseCase,
  }
}
