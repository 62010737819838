import * as React from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import { PrepareUploadResponse, apiClient, UploadFileToS3Args } from '@jetkit/react'

export default function useFileUpload<T extends PrepareUploadResponse>(url: string) {
  const prepareUploadRequest = React.useCallback(
    async ({ type, name, size }: File): Promise<T> => {
      const response = await apiClient.post(url, {
        mime_type: type,
        filename: name,
        size: size,
      })
      return response.data
    },
    [url]
  )

  const uploadFileToS3 = React.useCallback(async ({ file }: UploadFileToS3Args<string>, uploadResponse: T) => {
    const options: AxiosRequestConfig = {
      headers: {
        'Content-Type': file.type,
        ...uploadResponse.headers,
      },
    }

    return axios.put(uploadResponse.url, file, options)
  }, [])

  const uploadFile = React.useCallback(
    async (file: File) => {
      const preUploadData = await prepareUploadRequest(file)
      await uploadFileToS3({ file }, preUploadData)
      return preUploadData
    },
    [prepareUploadRequest, uploadFileToS3]
  )
  return { uploadFile, prepareUploadRequest, uploadFileToS3 }
}
