import { PagedResponseDataProcessor } from 'dataProcessors/pagedResponse'
import { IAPIActivityItem } from './../model/messageActivity'
import { apiClient } from '@jetkit/react'
import { IOpportunity } from 'model/opportunity'
import snakecaseKeys from 'snakecase-keys'
import { IProjectThread } from 'model/project'
import { PaginatedResponse } from './pagedResponse'
import { MessagesDataProcessor } from 'dataProcessors/messages'
import { IPaginationMeta } from './model/paginationMeta'
import { IThreadTitle } from 'model/thread'

export const loadThreads = async ({
  filterBy,
  search = '',
  pageNumber,
  pageSize,
  signal,
}: {
  filterBy: TThreadsFilterBy[]
  search?: string
  pageNumber: number
  pageSize: number
  signal?: AbortSignal
}): Promise<PaginatedResponse<IThread[]>> => {
  const response = await apiClient.get('/v2/thread', {
    params: {
      filterBy,
      search,
      page: pageNumber,
      page_size: pageSize,
    },
    signal,
  })

  return PagedResponseDataProcessor(response)
}

export const loadThread = async (threadId: string): Promise<IThread> => {
  const result = await apiClient.get(`/v2/thread/${threadId}`)
  return result.data
}

export const loadThreadMessages = async ({
  threadId,
  pageNumber,
  pageSize,
  filteredRecipientIds = [],
  signal,
}: {
  threadId: string
  pageNumber: number
  pageSize: number
  filteredRecipientIds?: string[]
  signal?: AbortSignal
}): Promise<PaginatedResponse<IMessage[]>> => {
  const response = await apiClient.get(`/v2/thread/${threadId}/messages`, {
    params: { page: pageNumber, page_size: pageSize, filter_audience_ids: filteredRecipientIds },
    signal,
  })

  return PagedResponseDataProcessor(response, MessagesDataProcessor)
}

export const getThreadShareableMessages = async (
  threadId: string,
  page: number,
  pageSize: number
): Promise<PaginatedResponse<Omit<IMessage, 'drafts'>[]>> => {
  const response = await apiClient.get(`/v2/thread/${threadId}/messages/shareable`, {
    params: { page, page_size: pageSize },
  })

  return PagedResponseDataProcessor(response, MessagesDataProcessor)
}

export const getThreadHeldMessages = async (threadId: string): Promise<IHeldMessage[]> => {
  const result = await apiClient.get(`/v2/thread/${threadId}/messages/held`)
  return result.data
}

export const unfollowThread = async (threadId: string): Promise<void> => {
  const result = await apiClient.patch(`/v2/thread/${threadId}/unfollow`)
  return result.data
}

export const reimportThread = async (threadId: string): Promise<void> => {
  const result = await apiClient.patch(`/v2/thread/${threadId}/reimport`)
  return result.data
}

export const unreadThread = async (threadId: string): Promise<void> => {
  const result = await apiClient.patch(`/thread/${threadId}/unread`)
  return result.data
}

export const createThread = async (
  title: string,
  follower_ids: string[],
  projectId?: string | null,
  projectName?: string | null,
  connectedThreadId?: string
): Promise<IThread> => {
  const result = await apiClient.post(`/thread`, {
    title: title,
    followers_q: follower_ids,
    project_name: projectName || null,
    project_id: projectId || null,
    connected_thread_id: connectedThreadId,
  })
  return result.data
}

export interface IActivitiesResponse {
  activities: IAPIActivityItem[]
  paginationData: IPaginationMeta
}

export const getThreadActivities = async (threadId: string, page: number): Promise<IActivitiesResponse> => {
  const response_raw = await apiClient.get(`/thread/${threadId}/activities`, { params: { pageSize: 30, page } })
  const paginationData = response_raw.headers?.['x-pagination'] && JSON.parse(response_raw.headers['x-pagination'])

  if (Array.isArray(response_raw.data)) {
    response_raw.data.reverse()
  }

  return {
    activities: await response_raw.data,
    paginationData: paginationData,
  }
}

export const changeThreadTitle = async (threadId: string, newTitle: string): Promise<IThread> => {
  const result = await apiClient.patch(`/thread/${threadId}`, { title: newTitle })
  return result.data
}

export const setThreadFollowers = async (
  threadId: string,
  followers: string[],
  userContent?: string
): Promise<IThread> => {
  const result = await apiClient.patch(`/thread/${threadId}/followers`, {
    followers: followers.map(followerId => ({ id: followerId })),
    user_email_content: userContent,
  })
  return result.data
}

export const getThreadFollowers = async (threadId: string): Promise<IUser[]> => {
  const result = await apiClient.get(`/v2/thread/${threadId}/followers`)
  return result.data
}

export const markThreadAsRead = async (threadId: string) => {
  const result = await apiClient.patch(`/thread/${threadId}/read`)
  return result.data
}

export const assignProject = async (threadId: ExtId, projectId: ExtId | null): Promise<IThread> => {
  const result = await apiClient.patch(`/thread/${threadId}`, { project_id: projectId })
  return result.data
}

export const getThreadsWithSharedAttachments = async (attachmentId: ExtId) =>
  (await apiClient.get(`/thread/attachment/${attachmentId}/shared-threads`)).data

export const getThreadOpportunities = async (threadId: ExtId): Promise<IOpportunity[]> =>
  (await apiClient.get('/opportunities', { params: { thread_id: threadId } })).data

export const changeThreadOwner = async (threadId: ExtId, newOwnerId: ExtId): Promise<void> =>
  (await apiClient.patch(`/thread/${threadId}/change-owner`, snakecaseKeys({ ownerId: newOwnerId }))).data

export const getThreadsForSharingAttachment = async (threadTitle?: string): Promise<IProjectThread[]> => {
  const threads = (await apiClient.get('/thread/preview', { params: snakecaseKeys({ search: threadTitle || '' }) }))
    .data

  return (Array.isArray(threads) && threads.map((item: IProjectThread) => item)) || []
}

export const getThreadTitle = async (threadId: string): Promise<IThreadTitle> => {
  const response = await apiClient.get(`/v2/thread/${threadId}/title`)

  return response.data
}
