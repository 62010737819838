import { IInlineAttachment } from '../model/attachment'
import { parse } from 'node-html-parser'
import { createHash } from 'crypto'

type TInsertInlineAttachmentsResult = {
  content: string
  inlineAttachments: IInlineAttachment[]
}

export const setInlineAttachmentsMaxWidth = (messageContent: string): string => {
  const root = parse(messageContent)

  const imageTags = root.getElementsByTagName('img')

  imageTags.forEach(image => {
    image.setAttribute('style', 'max-width: 484px;')
  })

  return root.toString()
}

export const insertInlineAttachments = ({
  messageContent,
  inlineAttachments,
}: {
  messageContent: string
  inlineAttachments: IInlineAttachment[]
}): TInsertInlineAttachmentsResult => {
  const root = parse(messageContent)

  // need to replace all src of inline images with their cid
  const imageTags = root.getElementsByTagName('img')

  // in case some attachments were pasted and removed later we will create a separate list for all attachments
  // we would be able to find in message content. only these attachments will be sent
  const inlineAttachmentsToSend: IInlineAttachment[] = []

  imageTags.forEach(image => {
    const src = image?.attributes?.src

    if (!src || !src.length) return image.setAttribute('src', '')

    // if `data-custom` attribute is present, it means this attachment was already created on the backend
    // we just need to grab the related inlineAttachment based on the contentId
    const dataCustom = image?.attributes?.['data-custom']
    if (dataCustom) {
      image.setAttribute('src', `cid:${dataCustom}`)
      const inlineImage = inlineAttachments.find(el => el.contentId === dataCustom)
      if (inlineImage) inlineAttachmentsToSend.push(inlineImage)
      return
    }

    // we use image src hash to match image tag in message content and IInlineAttachment object
    const hash = createHash('sha256').update(src).digest('hex')

    const inlineImage = inlineAttachments.find(el => el.contentHash === hash)
    if (inlineImage) {
      image.setAttribute('src', `cid:${inlineImage.contentId}`)
      inlineAttachmentsToSend.push(inlineImage)
    } else {
      image.setAttribute('src', '')
    }
  })

  return { content: root.toString(), inlineAttachments: inlineAttachmentsToSend }
}
