import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: 40,
    width: '100%',
    overflowY: 'auto',
  },
  header: {
    fontSize: 27,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    marginBottom: 20,
    letterSpacing: 'normal',
  },
  divider: {
    color: theme.threadPalette.lighterGray,
    marginBottom: 16,
  },

  tabsRoot: {
    width: 'fit-content',
  },
}))
