import React from 'react'
import { FormControlLabel, makeStyles, RadioGroup, Theme, Typography, Radio } from '@material-ui/core'
import BigCloseIcon from 'icons/bigCloseIcon'
import { useNewThreadModalContext } from './newThreadModal'
import { StartFromThreadliveContent } from './startFromThreadliveContent'
import ConnectExistingEmailContent from './connectExistingEmailThread'
import ProjectChip from 'features/project/projectChip'
import { useProjectActions } from 'hooks/useProjects/useProjectActions'

type TThreadCreationFlow = 'startFromThreadlive' | 'connectExistingEmail'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 20,
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  title: {
    color: theme.threadPalette.menuItem.normal,
    fontSize: 30,
    fontWeight: 'bold',
    lineHeight: 1,
  },
  closeButton: {
    alignItems: 'end',
    display: 'flex',
    height: 20,
    justifyContent: 'center',
    width: 20,
    '& svg': {
      fill: theme.threadPalette.lightGray,
    },
    '&:hover': {
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
  },
  projectContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: 10,
  },
  projectLabel: {
    color: theme.threadPalette.menuItem.normal,
    fontWeight: 500,
    paddingLeft: 4,
  },
  content: {
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
  },
  options: {
    marginBottom: 20,
  },
  optionLabel: {
    fontSize: 13,
  },
  optionRadio: {
    color: theme.threadPalette.lightGray,
    padding: '0 9px',
  },
}))

export const NewThreadModalContent = () => {
  const classes = useStyles()

  const [threadCreationFlow, setThreadCreationFlow] = React.useState<TThreadCreationFlow>('startFromThreadlive')

  const { closeModal, project, removeProject, navigateToThreadOnCreation } = useNewThreadModalContext()
  const { refreshProjectsList, refreshProject } = useProjectActions()

  const onThreadCreated = () => {
    closeModal()
    if (project) {
      refreshProjectsList()
      refreshProject(project.id)
    }
  }

  const newThreadContents = new Map<TThreadCreationFlow, JSX.Element>([
    [
      'connectExistingEmail',
      <ConnectExistingEmailContent
        onSuccess={onThreadCreated}
        projectId={project?.id}
        navigateToThreadOnSuccess={navigateToThreadOnCreation}
      />,
    ],
    [
      'startFromThreadlive',
      <StartFromThreadliveContent
        onSuccess={onThreadCreated}
        projectId={project?.id}
        navigateToThreadOnSuccess={navigateToThreadOnCreation}
      />,
    ],
  ])

  const handleThreadFlowChange = React.useCallback((event: any) => {
    const value = event.target.value
    setThreadCreationFlow(value)
  }, [])

  const radioButton = <Radio color="secondary" classes={{ root: classes.optionRadio }} />

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.title}>Create a thread</Typography>
        <div className={classes.closeButton} onClick={closeModal}>
          <BigCloseIcon />
        </div>
      </div>
      {project && (
        <div className={classes.projectContainer}>
          <Typography classes={{ root: classes.projectLabel }}>Project:</Typography>
          <ProjectChip name={'name' in project ? project?.name : ''} onRemove={removeProject} />
        </div>
      )}
      <div className={classes.content}>
        <RadioGroup className={classes.options} value={threadCreationFlow} onChange={handleThreadFlowChange} row>
          <FormControlLabel
            classes={{ label: classes.optionLabel }}
            control={radioButton}
            value="connectExistingEmail"
            label="Connect existing email"
          />
          <FormControlLabel
            classes={{ label: classes.optionLabel }}
            control={radioButton}
            value="startFromThreadlive"
            label="Start from ThreadLive"
          />
        </RadioGroup>
        {newThreadContents.get(threadCreationFlow)}
      </div>
    </div>
  )
}
