import * as React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import { getUserDisplayName } from 'dataProcessors/userDisplayName'
import { isEmail } from 'dataProcessors/utils'
import PersonIcon from '@material-ui/icons/Person'

import { ANONYMOUS_USER } from '../../constants/auth'

const DEFAULT_SIZE = 40
const DEFAULT_FONT_SIZE = 15

interface IUserAvatarBaseProps {
  size?: number
  fontSize?: number
}

interface IUserAvatarStyleProps extends IUserAvatarBaseProps {}

interface IUserAvatarProps extends IUserAvatarBaseProps {
  className?: string
  user: Pick<IUser, 'email'> & Partial<Pick<IUser, 'name' | 'avatarUrl'>>
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    userAvatar: (props: IUserAvatarStyleProps) => {
      return {
        background: theme.threadPalette.darkBlue,
        borderRadius: '4px',
        display: 'inline-flex',
        flexShrink: 0,
        overflow: 'hidden',
        height: `${props.size}px`,
        width: `${props.size}px`,
      }
    },
    avatar: (props: IUserAvatarStyleProps) => {
      return {
        objectFit: 'cover',
        width: `${props.size}px`,
      }
    },
    initials: (props: IUserAvatarStyleProps) => {
      return {
        alignItems: 'center',
        color: theme.threadPalette.lightGray,
        display: 'flex',
        fontSize: `${props.fontSize}px`,
        fontWeight: 500,
        justifyContent: 'center',
        lineHeight: `${props.size}px`,
        textAlign: 'center',
        userSelect: 'none',
        width: `${props.size}px`,
      }
    },
    personIcon: {
      color: theme.threadPalette.lightishGray,
      fontSize: 30,
    },
  }
})

const UserAvatar = ({
  size = DEFAULT_SIZE,
  fontSize = DEFAULT_FONT_SIZE,
  user = ANONYMOUS_USER,
  className,
}: IUserAvatarProps) => {
  const classes = useStyles({ size, fontSize })

  const userDisplayName = getUserDisplayName(user)
  const userDisplayNameUppercaseWordsList = userDisplayName.toLocaleUpperCase().split(' ')
  const userDisplayNameInitialsList = userDisplayNameUppercaseWordsList.map(word => word.slice(0, 1))
  if (userDisplayNameInitialsList.length === 1) userDisplayNameInitialsList[1] = isEmail(userDisplayName) ? '@' : ''

  const userDisplayFirstAndLastInitials = userDisplayNameInitialsList
    .filter((_, index) => [0, userDisplayNameInitialsList.length - 1].includes(index))
    .join('')

  return (
    <span data-cy="left-sidebar-user-avatar" className={classnames(classes.userAvatar, className)}>
      {user?.avatarUrl && <img alt="" className={classes.avatar} src={user.avatarUrl} title="" />}
      {!user?.avatarUrl && (
        <span className={classes.initials}>
          {userDisplayFirstAndLastInitials === '?' ? (
            <PersonIcon classes={{ root: classes.personIcon }} />
          ) : (
            userDisplayFirstAndLastInitials
          )}
        </span>
      )}
    </span>
  )
}

export default UserAvatar
