import React from 'react'
import { InputBase, Theme, makeStyles } from '@material-ui/core'
import WrapIntoSkeletonLoading from 'component/wrapIntoSkeletonLoading'

const useNotesInputStyles = makeStyles((theme: Theme) => ({
  notesInput: {
    color: theme.threadPalette.menuItem.current,
    fontSize: 15,
    lineHeight: '22px',
    minHeight: 120,
    '&::placeholder': {
      color: theme.threadPalette.lightGray,
      fontSize: 18,
    },
  },
}))

interface INotesInputProps {
  isLoading?: boolean
  defaultValue?: string
  value?: string
  onChange?(updatedValue: string): void
  onBlur?(): void
}

export const NotesInput = ({ isLoading, value = '', defaultValue = '', onChange, onBlur }: INotesInputProps) => {
  const classes = useNotesInputStyles()
  return (
    <WrapIntoSkeletonLoading isLoading={isLoading} width={'100%'} animation={'wave'}>
      <InputBase
        multiline
        fullWidth
        value={value}
        defaultValue={defaultValue}
        placeholder="Type notes here"
        onChange={event => onChange?.(event.target.value)}
        classes={{ inputMultiline: classes.notesInput }}
        onBlur={onBlur}
      />
    </WrapIntoSkeletonLoading>
  )
}
