import React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles, createStyles } from '@material-ui/styles'
import { MessageRecipientsBlock } from '../messageComponents/messageRecipientsBlock'
import { useMessageActions } from '../hooks/useMessageActions'
import { ICreateDraftUseCaseProps } from 'usecases/draft/createDraft'
import { useMessageCollapse } from '../hooks/useMessageCollapse'
import { useMessageRecipientsFilter } from '../hooks/useMessageRecipientsFilter'
import { CollapsibleMessageContent } from '../messageContent/collapsibleMessageContent'
import { useMessageContent } from '../hooks/useMessageContent'
import MessageAttachmentList from 'component/messageAttachmentList'
import MessageActions from 'component/message/messageComponents/messageActions'
import { MessageReimportAction } from 'component/messageList/messageReimportAction'
import { MessageDeliveryStatusBar } from 'component/message/messageComponents/messageDeliveryStatusBar'
import { BaseMessage } from '../baseMessage'
import IAttachment from 'model/attachment'
import { useDisplayAttachmentContext } from 'contexts/displayAttachmentContext'

const useThreadMessageStyles = makeStyles((theme: Theme) =>
  createStyles({
    threadMessageRoot: {
      maxWidth: '100%',
      minWidth: 420,
    },
    messageActionsWrapper: {
      cursor: 'pointer',
      display: 'flex',
    },
    messageAction: {
      color: theme.threadPalette.lightGray,
      display: 'flex',
      fontSize: 12,
      marginRight: 15,
    },
  })
)

export interface IThreadMessageProps {
  threadId: string
  message: IMessage
  currentUser: IUser
  onNewDraft: (newDraftProps: ICreateDraftUseCaseProps) => void
  isLoading?: boolean
  isViewAccess?: boolean
  onMessageReimport?: () => Promise<void>
  contentRootClassName?: string
  messageStatusComponent?: JSX.Element
}

export const ThreadMessage = React.forwardRef<HTMLDivElement, IThreadMessageProps>(
  (
    {
      threadId,
      message,
      currentUser,
      onNewDraft,
      isLoading,
      onMessageReimport,
      contentRootClassName,
      isViewAccess,
      messageStatusComponent,
    },
    ref
  ) => {
    const classes = useThreadMessageStyles()

    const {
      id: messageId,
      sender,
      recipients = message.recipients ?? [],
      bccRecipients = message.bccRecipients ?? [],
      attachments,
      content,
      previousMessageContent,
      deliveryStatus,
      createdAt,
      isCollapsed: initialMessageCollapsedState,
    } = message

    const { displayAttachmentOnRightSideBar } = useDisplayAttachmentContext()
    const onOpenAttachment = (attachment: IAttachment) => {
      displayAttachmentOnRightSideBar({ attachment, threadId })
    }

    const isMessageWithError = deliveryStatus === 'error'
    const isMessageActionsAllowed = deliveryStatus === 'sent' && !isViewAccess
    const isMessageReimportAllowed = onMessageReimport !== undefined
    const isCurrentUserMessageSender = sender.id === currentUser.id

    const { isFilterActive: isRecipientsFilterActive, onFilterByMessageRecipients } = useMessageRecipientsFilter({
      sender: sender,
      recipients: recipients,
      bccRecipients: bccRecipients,
      currentUser: currentUser,
    })

    const { isCollapsed, toggleMessageCollapsed } = useMessageCollapse({
      initialMessageCollapsedState: initialMessageCollapsedState ?? false,
      messageId,
    })

    const { onReply, onReplyAll, onForward } = useMessageActions({
      attachments,
      bccRecipients,
      recipients,
      sender,
      currentUser,
      messageId,
      threadId,
      onNewDraft,
    })

    const { isTablePresentOnMessageContentOrPreviousMessage: isMessageWithTable } = useMessageContent({
      content,
      previousMessageContent,
    })

    const recipientsBlock = (
      <MessageRecipientsBlock
        currentUser={currentUser}
        message={message}
        recipientsFilter={{
          isActive: isRecipientsFilterActive,
          onFilter: onFilterByMessageRecipients,
        }}
        isMessageWithError={isMessageWithError}
        isLoading={isLoading}
        isViewAccess={isViewAccess}
        messageStatusComponent={messageStatusComponent}
      />
    )

    const messageContentBlock = (
      <CollapsibleMessageContent
        isCollapsed={isCollapsed}
        toggleMessageCollapsed={toggleMessageCollapsed}
        message={message}
        isMessageWithTable={isMessageWithTable}
        onOpenAttachment={onOpenAttachment}
      />
    )

    const attachmentsBlock = <MessageAttachmentList attachments={attachments} onOpenAttachment={onOpenAttachment} />

    const footerBlock = (
      <React.Fragment>
        {isMessageActionsAllowed && (
          <MessageActions
            className={classes.messageActionsWrapper}
            messageActionClassName={classes.messageAction}
            onReply={onReply}
            onReplyAll={onReplyAll}
            onForward={onForward}
            hideActions={isCollapsed}
          />
        )}
        {isMessageWithError && isMessageReimportAllowed && (
          <MessageReimportAction onMessageReimport={onMessageReimport} />
        )}
        <MessageDeliveryStatusBar
          deliveryStatus={deliveryStatus}
          createdAt={createdAt}
          isCurrentUserMessageSender={isCurrentUserMessageSender}
        />
      </React.Fragment>
    )

    return (
      <div ref={ref} className={classes.threadMessageRoot}>
        <BaseMessage
          recipientsBlock={recipientsBlock}
          messageContentBlock={messageContentBlock}
          attachmentsBlock={attachmentsBlock}
          footerBlock={footerBlock}
          isLoading={isLoading}
          isViewAccess={isViewAccess}
          isMessageWithError={isMessageWithError}
          contentRootClassName={contentRootClassName}
        />
      </div>
    )
  }
)
