import React, { useState } from 'react'

import { useCreateNewProjectUseCase } from 'usecases/createProject'
import { useOpenProjectDetailsUseCase } from 'usecases/openProjectDetails'
import NewItemButton from 'component/newItemButton'

const NewProjectButton = ({ onClick, isDisabled }: { onClick?(): void; isDisabled?: boolean }) => {
  const [isCreatingProject, setIsCreatingProject] = useState(false)
  const { createNewProjectUseCase } = useCreateNewProjectUseCase()
  const { openProjectDetailsUseCase } = useOpenProjectDetailsUseCase()

  const onCreateNewProject = async () => {
    setIsCreatingProject(true)
    const newProject = await createNewProjectUseCase()
    setIsCreatingProject(false)
    if (newProject) openProjectDetailsUseCase(newProject.id)
  }

  return (
    <NewItemButton
      label="New Project"
      onClick={onClick ?? onCreateNewProject}
      isLoading={isCreatingProject}
      isDisabled={isDisabled}
    />
  )
}

export default NewProjectButton
