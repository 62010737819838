import { xor, findIndex, isEqual, slice } from 'lodash'

export const areArraysEqual = (array1: any[], array2: any[]): boolean => {
  const symmetricDifference = xor(array1, array2)
  return symmetricDifference.length === 0
}

export const locateSubArrayStartIndex = (array: any[], subArray: any[]) => {
  if (array.length < subArray.length) return -1
  return findIndex(array, (_, index) => isEqual(slice(array, index, index + subArray.length), subArray))
}
