import * as React from 'react'

interface IDragDropAreaProps {
  overlay: React.ReactElement
  children: React.ReactElement
  onFilesDrop: (files: File[]) => void
  className?: string
}
const DragDropArea = React.forwardRef<HTMLDivElement, IDragDropAreaProps>(
  ({ overlay, children, onFilesDrop, className = '' }, ref) => {
    const [dragActive, setDragActive] = React.useState(0)

    const onDragEnter = React.useCallback((event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setDragActive(prev => prev + 1)
    }, [])

    const onDragOver = React.useCallback((event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
    }, [])

    const onDragLeave = React.useCallback((event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setDragActive(prev => prev - 1)
    }, [])

    const onDrop = React.useCallback(
      (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        setDragActive(0)

        if (event.nativeEvent.dataTransfer) {
          const files = Array.from(event.nativeEvent.dataTransfer.files)
          onFilesDrop(files)
        }
      },
      [onFilesDrop]
    )

    return (
      <div
        ref={ref}
        className={className}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {children}
        {dragActive > 0 && overlay}
      </div>
    )
  }
)

export default DragDropArea
