import { useThreadActions } from 'hooks/useThreads/useThreadActions'

interface IUpdateThreadUseCaseProps {
  threadId: string
  isStarred?: boolean
  unreadCount?: number
  unreadCountModifier?: (unreadCount: number) => number
  titleAlias?: string
  isInitiated?: boolean
  stateForUser?: TThreadStateForUser
  clearFirstDraftId?: boolean
  isImporting?: boolean
}

interface IUpdateThreadUseCaseReturn {
  rollbackOptimisticUpdates?: () => void
}

export const useUpdateThreadUseCase = () => {
  const { getThreadData, setThreadData, getThreadsListActiveData, updateThreadInThreadsList, setThreadsListData } =
    useThreadActions()

  const updateThreadUseCase = (updateProps: IUpdateThreadUseCaseProps): IUpdateThreadUseCaseReturn => {
    const restoreThread = updateThread(updateProps)
    const restoreThreadsList = updateThreadList(updateProps)

    return {
      rollbackOptimisticUpdates: () => {
        restoreThread?.()
        restoreThreadsList?.()
      },
    }
  }

  const updateThread = ({
    threadId,
    isStarred,
    unreadCount,
    unreadCountModifier,
    titleAlias,
    isInitiated,
    stateForUser,
    clearFirstDraftId,
    isImporting,
  }: IUpdateThreadUseCaseProps) => {
    const thread = getThreadData(threadId)

    if (!thread) return

    const updatedThread = { ...thread }

    if (isStarred !== undefined) updatedThread.isStarred = isStarred
    if (unreadCount !== undefined) updatedThread.unreadCount = unreadCount
    if (unreadCountModifier !== undefined) {
      updatedThread.unreadCount = unreadCountModifier(thread.unreadCount)
    }
    if (stateForUser !== undefined) updatedThread.stateForUser = stateForUser
    if (titleAlias !== undefined) updatedThread.titleAlias = titleAlias
    if (isInitiated !== undefined) updatedThread.isInitiated = isInitiated
    if (clearFirstDraftId) updatedThread.firstDraftId = undefined
    if (isImporting !== undefined) updatedThread.isImporting = isImporting

    setThreadData(threadId, updatedThread)

    return () => {
      setThreadData(thread.id, thread)
    }
  }

  const updateThreadList = ({
    threadId,
    isStarred,
    unreadCount,
    unreadCountModifier,
    titleAlias,
    isInitiated,
    stateForUser,
  }: IUpdateThreadUseCaseProps) => {
    const threadsListActiveData = getThreadsListActiveData()

    if (!threadsListActiveData) return

    const { data: activeThreadsList } = threadsListActiveData

    if (isStarred !== undefined) {
      const threadIsStarredModifier = (thread: IThread) => {
        return { ...thread, isStarred: isStarred }
      }
      updateThreadInThreadsList(threadId, activeThreadsList, threadIsStarredModifier)
    }
    if (unreadCount !== undefined) {
      const threadUnreadModifier = (thread: IThread) => {
        return { ...thread, unreadCount: unreadCount }
      }
      updateThreadInThreadsList(threadId, activeThreadsList, threadUnreadModifier)
    }
    if (unreadCountModifier !== undefined) {
      const threadUnreadModifier = (thread: IThread) => {
        return { ...thread, unreadCount: unreadCountModifier(thread.unreadCount) }
      }
      updateThreadInThreadsList(threadId, activeThreadsList, threadUnreadModifier)
    }
    if (stateForUser !== undefined) {
      const threadStateForUserModifier = (thread: IThread) => {
        return { ...thread, stateForUser: stateForUser }
      }
      updateThreadInThreadsList(threadId, activeThreadsList, threadStateForUserModifier)
    }
    if (titleAlias !== undefined) {
      const threadAliasModifier = (thread: IThread) => {
        return { ...thread, titleAlias: titleAlias }
      }
      updateThreadInThreadsList(threadId, activeThreadsList, threadAliasModifier)
    }
    if (isInitiated !== undefined) {
      const threadIsInitiatedModifier = (thread: IThread) => {
        return { ...thread, isInitiated: isInitiated }
      }
      updateThreadInThreadsList(threadId, activeThreadsList, threadIsInitiatedModifier)
    }

    return () => {
      setThreadsListData(threadsListActiveData.data)
    }
  }

  return {
    updateThreadUseCase,
  }
}
