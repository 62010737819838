import React from 'react'
import { Button, Tooltip, Typography } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import Spacer from 'component/layoutUtils/spacer'
import GroupIcon from '@material-ui/icons/Group'

const useShareButtonStyles = makeStyles((theme: Theme) => {
  return {
    shareButtonRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBlock: 4,
      paddingInline: 8,
    },
    shareButtonText: {
      color: theme.palette.primary.main,
      fontSize: '14px',
    },
    shareIcon: {
      fill: theme.palette.primary.main,
      height: '20px',
      width: '20px',
    },
    shareButtonDisabledRoot: {
      backgroundColor: theme.threadPalette.lighterGray,
    },
    shareIconDisabled: {
      fill: theme.threadPalette.lightGray,
    },
    shareTextDisabled: {
      color: theme.threadPalette.lightGray,
    },
    tooltip: {
      fontSize: 12,
    },
  }
})

interface IShareButtonProps {
  id?: string
  onClick?(): void
  disabled?: boolean
  disabledTooltip?: string
}

export const ShareButton = ({ id, onClick, disabled = false, disabledTooltip = '' }: IShareButtonProps) => {
  const classes = useShareButtonStyles()

  const groupIconDisabledClassName = classnames(classes.shareIcon, {
    [classes.shareIconDisabled]: disabled,
  })
  const shareButtonTextDisabledClassName = classnames(classes.shareButtonText, {
    [classes.shareTextDisabled]: disabled,
  })

  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} title={disabled ? disabledTooltip : ''}>
      <div id={id}>
        <Button
          classes={{ root: classes.shareButtonRoot, disabled: classes.shareButtonDisabledRoot }}
          onClick={onClick}
          disabled={disabled}
        >
          <GroupIcon className={groupIconDisabledClassName} />
          <Spacer width={4} />
          <Typography className={shareButtonTextDisabledClassName}>Share</Typography>
        </Button>
      </div>
    </Tooltip>
  )
}
