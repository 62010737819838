import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { unreadThread } from 'api/threads'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'
import { useDispatch } from 'react-redux'
import { useMoveThreadUpUseCase } from './moveThreadUp'
import { useUpdateThreadUseCase } from './updateThread'

export const useUnreadThreadUseCase = () => {
  const dispatch = useDispatch()
  const { moveThreadUpUseCase } = useMoveThreadUpUseCase()
  const { updateThreadUseCase } = useUpdateThreadUseCase()
  const { refreshThread, refreshThreadsList } = useThreadActions()

  const unreadThreadMutation = useMutation(unreadThread, {
    onMutate: variables => {
      const threadId = variables
      const { rollbackOptimisticUpdates } = updateThreadUseCase({ threadId: threadId, unreadCount: -1 })
      moveThreadUpUseCase(threadId)
      return rollbackOptimisticUpdates
    },
    onError: (error, variables, rollbackOptimisticUpdates) => {
      rollbackOptimisticUpdates?.()
      dispatch(showSnackbar({ message: 'Error on unreading thread' }))
      unreadThreadMutation.reset()
    },
    onSettled: (data, error, variables) => {
      const threadId = variables
      refreshThread(threadId)
      refreshThreadsList()
    },
  })

  const unreadThreadUseCase = async (threadId: string) => {
    await unreadThreadMutation.mutateAsync(threadId)
  }

  return {
    unreadThreadUseCase,
  }
}
