import React, { useContext } from 'react'
import { useTheme } from '@material-ui/core'
import Joyride, { Step, CallBackProps, EVENTS, STATUS, TooltipRenderProps, Placement } from 'react-joyride'
import { OnboardingTooltip } from 'component/onboardingTooltip/onboardingTooltip'
import { ISupportedOnboardingName } from 'hooks/onboardingTour/useOnboardingTour'

export type IOnboardingTourStep = Step & {
  showProgress?: boolean
  hideSkipButton?: boolean
  onNextStepButtonClick?: () => void
}
export type TOnboardingTourStepPlacement = Placement | 'center' | 'auto'

export type IOnboardingTooltipProps = Omit<TooltipRenderProps, 'step'> & { step: IOnboardingTourStep }

interface IPostponedOnboardingTour {
  name: ISupportedOnboardingName
  props: any
}

interface IStartOnboardingTourParams {
  steps: IOnboardingTourStep[]
  onTourClosed?: () => void
}

interface IOnboardingTourContext {
  startOnboardingTour({ steps, onTourClosed }: IStartOnboardingTourParams): void
  isRunning: boolean
  postponedOnboardingTours: IPostponedOnboardingTour[]
  setPostponedOnboardingTours: SetStateCallback<IPostponedOnboardingTour[]>
}

export const OnboardingTourContext = React.createContext<IOnboardingTourContext | undefined>(undefined)

export const OnboardingTourContextProvider: React.FC<{}> = ({ children }) => {
  const theme = useTheme()

  const defaultStyles = {
    overlay: { zIndex: theme.zIndex.tooltip },
    options: { zIndex: theme.zIndex.tooltip + 1 },
    beacon: { display: 'none' },
  }

  const [tourSteps, setTourSteps] = React.useState<IOnboardingTourStep[]>([])
  const [onTourClosed, setOnTourClosed] = React.useState<() => void>()

  const [postponedOnboardingTours, setPostponedOnboardingTours] = React.useState<IPostponedOnboardingTour[]>([])

  const isRunning = tourSteps.length > 0

  const startOnboardingTour = ({ steps, onTourClosed }: IStartOnboardingTourParams) => {
    setTourSteps(steps)
    setOnTourClosed(() => onTourClosed)
  }

  const handleJoyrideCallback = ({ type, status }: CallBackProps) => {
    const isTourClosed = type === EVENTS.TOUR_END
    const isTourCompleted = type === EVENTS.TOUR_END && status === STATUS.FINISHED

    if (isTourClosed || isTourCompleted) {
      setTourSteps([])
      onTourClosed?.()
    }
  }

  return (
    <OnboardingTourContext.Provider
      value={{
        startOnboardingTour,
        isRunning,
        postponedOnboardingTours,
        setPostponedOnboardingTours,
      }}
    >
      <Joyride
        steps={tourSteps}
        styles={defaultStyles}
        run={isRunning}
        callback={handleJoyrideCallback}
        disableOverlayClose
        disableCloseOnEsc
        showSkipButton
        continuous={tourSteps.length > 1}
        tooltipComponent={OnboardingTooltip}
      />
      {children}
    </OnboardingTourContext.Provider>
  )
}

export const useOnboardingTourContext = () => {
  const context = useContext(OnboardingTourContext)

  if (context === undefined)
    throw new Error('useOnboardingTourContext should be used within a OnboardingTourContextProvider ')

  return context
}
