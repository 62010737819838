import React from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getThreadShareableMessages } from 'api/threads'
import { useUser } from './auth'
import { getNextPageNumber } from 'lib/api'

export const getThreadShareableMessagesQueryKey = ({ userId, threadId }: { userId?: string; threadId: string }) => [
  userId,
  'threads',
  threadId,
  'messages',
  'shareable',
]

interface IThreadShareableMessagesQueryFunctionProps {
  threadId: string
  pageSize: number
  pageNumber: number
}

const getThreadShareableMessagesQueryFunction = async ({
  threadId,
  pageNumber,
  pageSize,
}: IThreadShareableMessagesQueryFunctionProps) => {
  return await getThreadShareableMessages(threadId, pageNumber, pageSize)
}

export const useThreadShareableMessagesList = (threadId: string) => {
  const pageSize = 10
  const currentUser = useUser()

  const {
    data: messagePages,
    fetchNextPage,
    isInitialLoading,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    enabled: !!currentUser?.id,
    queryKey: getThreadShareableMessagesQueryKey({ userId: currentUser?.id ?? '', threadId }),
    queryFn: ({ pageParam }) =>
      getThreadShareableMessagesQueryFunction({
        threadId,
        pageNumber: pageParam ?? 1,
        pageSize,
      }),
    getNextPageParam: lastPage => getNextPageNumber(lastPage),
  })

  const fetchNextMessagePage = React.useCallback(() => {
    if (isFetchingNextPage || isInitialLoading) return
    fetchNextPage()
  }, [fetchNextPage, isFetchingNextPage, isInitialLoading])

  const shareableMessagesList: IMessage[] = React.useMemo(() => {
    return messagePages?.pages?.flatMap(page => page.data) ?? []
  }, [messagePages?.pages])

  return {
    shareableMessagesList,
    isLoading: isInitialLoading,
    isFetchingNextPage,
    fetchNextPage: fetchNextMessagePage,
    hasNextPage: hasNextPage !== undefined ? hasNextPage : false,
  }
}
