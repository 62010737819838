import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import SharingGroupCard from 'component/sharingGroup/common/sharingGroupCard/sharingGroupCard'
import { SelectThreadMessagesBlock } from 'component/sharingGroup/common/selectThreadMessagesBlock'
import SubmitButtonWithLoading from 'component/submitButtonWithLoading/submitButtonWithLoading'
import { ISharingGroup } from 'model/sharingGroup'
import { StopSharingThreadButton } from 'component/sharingGroup/common/stopSharingThreadButton'
import { NotifyPeopleByEmailCheckbox } from './notifyPeopleByEmailCheckbox'
import { EmailNotificationInput } from './emailNotificationInput'
import { useThreadSharingSettingsFormFieldsContext } from '../useThreadSharingSettingsFormFieldsContext'

const useEditableThreadSharingSettingsStyles = makeStyles((theme: Theme) => ({
  threadSharingSettingsContainer: {
    marginTop: 24,
  },
  notificationMessageContainer: {
    marginTop: 20,
  },
  notificationMessageInputRoot: {
    lineHeight: 1.4,
    padding: 10,
  },
  inputMultiline: {
    maxHeight: '30vh',
  },
  actionsContainer: {
    alignItems: 'end',
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: 24,
    width: '100%',
  },
  updateSettingsButton: {
    alignSelf: 'end',
    fontSize: 16,
    height: 40,
    paddingInline: 24,
  },
  stopSharingButton: {
    color: theme.palette.error.main,
    fontSize: 16,
    height: 40,
    paddingInline: 16,
  },
}))

interface IEditableThreadSharingSettingsProps {
  sharingGroup: ISharingGroup
  onEditSharingGroup?(): void
  isCreatingThreadSharingSettings: boolean
  onSubmit?(): void
  isSubmitting?: boolean
  onStopSharing?(): void
  onClickEditSelectedMessages?(): void
  openSharingFutureMessagesInfo?(): void
}

export const EditableThreadSharingSettings = ({
  sharingGroup,
  onEditSharingGroup,
  isCreatingThreadSharingSettings,
  onSubmit,
  onStopSharing,
  onClickEditSelectedMessages,
  openSharingFutureMessagesInfo,
  isSubmitting = false,
}: IEditableThreadSharingSettingsProps) => {
  const classes = useEditableThreadSharingSettingsStyles()

  const buttonLabel = isCreatingThreadSharingSettings ? 'Create share' : 'Update share'
  const stopSharingConfirmationPopupAnchorRef = React.useRef<HTMLDivElement>(null)

  const {
    selectedMessagesIds,
    activeMessagesSelectorOption,
    setActiveMessagesSelectorOption,
    autoShareConditions,
    setAutoShareConditions,
    notifyMembersByEmail,
    toggleNotifyMembersByEmail,
    notificationEmailMessage,
    setNotificationEmailMessage,
  } = useThreadSharingSettingsFormFieldsContext()

  const canSaveSettings = activeMessagesSelectorOption !== null || !!autoShareConditions

  return (
    <>
      <SharingGroupCard sharingGroup={sharingGroup} onEditGroup={onEditSharingGroup} />

      <section className={classes.threadSharingSettingsContainer} ref={stopSharingConfirmationPopupAnchorRef}>
        <SelectThreadMessagesBlock
          selectedMessagesCount={selectedMessagesIds.length}
          onClickEditSelectedMessages={onClickEditSelectedMessages}
          activeMessagesSelectorOption={activeMessagesSelectorOption}
          onChangeActiveMessagesSelectorOption={setActiveMessagesSelectorOption}
          selectedAutoShareConditions={autoShareConditions}
          setAutoShareConditions={setAutoShareConditions}
          openSharingFutureMessagesInfo={openSharingFutureMessagesInfo}
        />
        {notifyMembersByEmail && (
          <EmailNotificationInput value={notificationEmailMessage} onChange={setNotificationEmailMessage} />
        )}

        <div className={classes.actionsContainer}>
          <SubmitButtonWithLoading
            className={classes.updateSettingsButton}
            variant="contained"
            color="primary"
            disabled={!canSaveSettings}
            onClick={onSubmit}
            isLoading={isSubmitting}
          >
            {buttonLabel}
          </SubmitButtonWithLoading>
          {isCreatingThreadSharingSettings && (
            <NotifyPeopleByEmailCheckbox
              isChecked={notifyMembersByEmail}
              toggleIsChecked={toggleNotifyMembersByEmail}
            />
          )}
          {!isCreatingThreadSharingSettings && onStopSharing && (
            <StopSharingThreadButton
              sharingGroupName={sharingGroup.name}
              anchorElementRefForConfirmationPopup={stopSharingConfirmationPopupAnchorRef}
              className={classes.stopSharingButton}
              onClick={onStopSharing}
            />
          )}
        </div>
      </section>
    </>
  )
}
