import React from 'react'
import { Theme, Typography, makeStyles } from '@material-ui/core'
import { SharingGroupScreenHeader } from 'component/sharingGroup/common/sharingGroupScreenHeader'
import { HeldMessagesReviewCompleteScreen } from './heldMessagesReviewCompleteScreen'
import { ThreadTitleWithLogo } from 'component/threadTitleWithLogo/threadTitleWithLogo'
import { useThreadHeldMessages } from 'hooks/useThreadHeldMessages/useThreadHeldMessages'
import { useViewMessageEntireContent } from 'component/sharingGroup/common/useViewMessageEntireContent'
import { useThreadSharingSettings } from 'hooks/useSharingGroup/useThreadSharingSettings'
import classNames from 'classnames'
import {
  getHeldMessageCountForThreadSharingSettings,
  getThreadSharingSettingsThatHoldMessage,
} from 'domain/sharingGroup'
import { MessagesFragmentList } from 'component/sharingGroup/common/messagesFragmentList'
import { HeldMessageWithSharingPanel } from './heldMessageWithSharingPanel'
import { MessageFragmentSkeleton } from 'component/message/messageFragment/messageFragmentSkeleton'
import { scrollElementIntoView } from 'dataProcessors/utils'

const useReviewHeldMessagesScreenStyles = makeStyles((theme: Theme) => ({
  reviewHeldMessagesScreenRoot: {
    paddingBottom: 20,
  },
  screenHeaderPadding: {
    padding: 20,
  },
  reviewHeldMessagesHeader: {
    color: theme.threadPalette.orange,
    flex: 1,
    fontSize: 24,
  },
  heldMessagesReviewCompleteHeader: {
    color: theme.threadPalette.brownishGray,
  },
  threadTitle: {
    paddingInline: 30,
    marginBottom: 20,
  },
  screenHeaderSubtitle: {
    fontSize: 12,
    color: theme.threadPalette.brownishGray,
  },
  reviewHeldMessagesHeaderRoot: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  heldMessageWithSharingPanelRoot: {
    paddingInline: 30,
  },
  heldMessagesList: {
    gap: 70,
  },
}))

interface IReviewHeldMessagesScreenProps {
  thread: IThread
  user: IUser
  focusedHeldMessage?: IMessage
  returnToPreviousScreenAction(): void
  onClose(): void
}

export const ReviewHeldMessagesScreen = ({
  thread,
  user,
  focusedHeldMessage,
  returnToPreviousScreenAction,
  onClose,
}: IReviewHeldMessagesScreenProps) => {
  const { heldMessages, isLoading: isLoadingHeldMessages } = useThreadHeldMessages(thread.id)
  const { threadSharingSettingsList, isLoading: isLoadingThreadSharingSettings } = useThreadSharingSettings(thread.id)
  const isLoading = isLoadingHeldMessages || isLoadingThreadSharingSettings

  const heldMessagesWithSharingPanelsRefs = React.useRef<{ [key: string]: HTMLDivElement | null }>({})

  React.useEffect(() => {
    if (!focusedHeldMessage || isLoading) return

    const focusedMessageRef = heldMessagesWithSharingPanelsRefs.current[focusedHeldMessage.id]
    scrollElementIntoView(focusedMessageRef, { behavior: 'auto', scrollTo: 'start' })
    focusedMessageRef?.scrollIntoView({ behavior: 'auto' })
  }, [isLoading, focusedHeldMessage])

  const { onClickToViewEntireMessage, handleBackNavigation, messageSelectedForEntireContentView } =
    useViewMessageEntireContent({ returnToPreviousScreenAction })

  const heldMessageCount = getHeldMessageCountForThreadSharingSettings({ threadSharingSettingsList, user })
  const isHeldMessagesReviewed = heldMessageCount === 0 && !isLoading
  const showHeldMessagesList = !isHeldMessagesReviewed || isLoading

  const classes = useReviewHeldMessagesScreenStyles({ isHeldMessagesReviewed })

  const headerTextClassName = classNames(classes.reviewHeldMessagesHeader, {
    [classes.heldMessagesReviewCompleteHeader]: isHeldMessagesReviewed,
  })

  const getHeldMessagesSkeleton = () => (
    <div className={classes.heldMessageWithSharingPanelRoot}>
      <MessageFragmentSkeleton currentUser={user} />
    </div>
  )

  const headerTextComponent = (
    <div className={classes.reviewHeldMessagesHeaderRoot}>
      <Typography className={headerTextClassName}>
        {isHeldMessagesReviewed ? '✅ Message review complete' : '⚠️ Review held messages'}
      </Typography>
      {isHeldMessagesReviewed ? null : (
        <Typography className={classes.screenHeaderSubtitle}>
          These messages were withheld from your share group due to potential privacy concerns. Please review and decide
          whether to <b>approve</b> or <b>reject</b> it for your share group.
        </Typography>
      )}
    </div>
  )

  const threadTitleWithLogo = (
    <div className={classes.threadTitle}>
      <ThreadTitleWithLogo thread={thread} fontSize={26} />
    </div>
  )

  return (
    <div className={classes.reviewHeldMessagesScreenRoot}>
      <SharingGroupScreenHeader
        text={headerTextComponent}
        onReturnToPreviousScreenClick={handleBackNavigation}
        className={classes.screenHeaderPadding}
      />
      {showHeldMessagesList && (
        <MessagesFragmentList
          messagesCount={heldMessages.length}
          currentUser={user}
          getMessageFragmentSkeleton={getHeldMessagesSkeleton}
          isLoading={isLoading}
          messageSelectedForEntireContentView={messageSelectedForEntireContentView}
          header={threadTitleWithLogo}
          className={classes.heldMessagesList}
        >
          {heldMessages.map(message => {
            const messageSharingSettings = getThreadSharingSettingsThatHoldMessage({
              message,
              threadSharingSettingsList,
              user,
            })
            const sharingGroups = messageSharingSettings.map(sharingSettings => sharingSettings.sharingGroup)

            if (sharingGroups.length === 0) return null

            const isShowFocusAnimation = message.id === focusedHeldMessage?.id && !isLoading

            return (
              <div
                key={message.id}
                className={classes.heldMessageWithSharingPanelRoot}
                ref={element => (heldMessagesWithSharingPanelsRefs.current[message.id] = element)}
              >
                <HeldMessageWithSharingPanel
                  message={message}
                  sharingGroups={sharingGroups}
                  onClickToViewEntireMessage={() => onClickToViewEntireMessage(message)}
                  user={user}
                  isShowFocusAnimation={isShowFocusAnimation}
                />
              </div>
            )
          })}
        </MessagesFragmentList>
      )}
      {isHeldMessagesReviewed && <HeldMessagesReviewCompleteScreen onClose={onClose} />}
    </div>
  )
}
