import React from 'react'
import { makeStyles } from '@material-ui/core'
import PlaceholderImage from 'assets/placeholder-image.webp'
import { Skeleton } from '@material-ui/lab'

export interface IImagePlaceholderProps {
  contentId: string
}

const useImagePlaceholderStyles = makeStyles({
  imagePlaceholder: {
    width: 410,
    transform: 'unset',
  },
})

export const ImagePlaceholder = ({ contentId }: IImagePlaceholderProps) => {
  const classes = useImagePlaceholderStyles()

  return (
    <Skeleton
      component="img"
      src={PlaceholderImage}
      data-custom={contentId}
      alt="inline-attachment"
      className={classes.imagePlaceholder}
    />
  )
}
