import { v4 as uuid4 } from 'uuid'
import { createDraftRecipient } from 'domain/messageRecipient'
import IAttachment from 'model/attachment'
import { ICreateDraftUseCaseProps } from 'usecases/draft/createDraft'

interface IUseMessageActionsProps {
  threadId: string
  messageId: string
  sender: IUser
  currentUser: IUser
  recipients: TPerson[]
  bccRecipients: TPerson[]
  onNewDraft: (newDraftProps: ICreateDraftUseCaseProps) => void
  attachments: IAttachment[]
}

export const useMessageActions = ({
  threadId,
  messageId,
  sender,
  recipients,
  bccRecipients,
  currentUser,
  onNewDraft,
  attachments,
}: IUseMessageActionsProps) => {
  const onReply = () => {
    const repliedMessageSenderEmail = sender.email
    const isSenderTheSameUserReplying = repliedMessageSenderEmail === currentUser.email

    const firstRecipientEmail = recipients?.[0].email || ''
    const inReplyToEmail = isSenderTheSameUserReplying ? firstRecipientEmail : repliedMessageSenderEmail

    const draftRecipients = [createDraftRecipient(inReplyToEmail)]

    onNewDraft?.({
      draftId: uuid4(),
      draftType: 'reply',
      repliedOrForwardedMessageId: messageId,
      threadId: threadId,
      recipients: draftRecipients,
    })
  }

  const onReplyAll = () => {
    const senderEmail = sender.email
    const draftRecipientEmails = recipients?.map(recipient => recipient.email) ?? []
    const finalRecipientsEmails = [senderEmail, ...draftRecipientEmails].filter(
      recipientEmail => recipientEmail !== currentUser.email
    )

    const finalRecipients = finalRecipientsEmails.map(recipientEmail => createDraftRecipient(recipientEmail))

    const draftBccRecipients = bccRecipients?.map(({ email, name }) =>
      // This check is only to solve the situation where our IUser has name with string | null, while the TPerson can have it as undefined
      // We'll need to refactor the IUser to reflect correctly the logic on how we're working right now
      createDraftRecipient(email, name ?? undefined)
    )

    onNewDraft?.({
      draftId: uuid4(),
      draftType: 'reply_all',
      repliedOrForwardedMessageId: messageId,
      threadId: threadId,
      recipients: finalRecipients,
      bccRecipients: draftBccRecipients,
    })
  }

  const onForward = () => {
    onNewDraft?.({
      draftId: uuid4(),
      draftType: 'forward',
      threadId: threadId,
      repliedOrForwardedMessageId: messageId,
      attachments,
    })
  }

  return {
    onReply,
    onReplyAll,
    onForward,
  }
}
