import * as React from 'react'

interface ICloseIconProps {
  width?: string
  height?: string
  fill?: string
}

const CloseIcon = ({ width = '14', height = '13', fill = '#2196F3' }: ICloseIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 13" width={width} height={height}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M16.086 14.7L21 19.614 19.614 21 14.7 16.086 9.786 20.976 8.4 19.59 13.314 14.7 8.424 9.786 9.81 8.4 14.7 13.314 19.614 8.4 21 9.786z"
        transform="translate(-7.5 -8)"
      />
    </svg>
  )
}

export default CloseIcon
