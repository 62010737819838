import * as React from 'react'
import { Typography, Button, TextField } from '@material-ui/core'
import { IOrganization } from 'model/organization'
import SearchIcon from '@material-ui/icons/Search'
import { useStyles } from './orgMembers.style'
import OrgMemberListItem from './orgMemberListItem'
import { useUser } from 'hooks'
import { useOrgMembers } from './useOrgMembers'
import NewMemberDialog from '../newMemberDialog'
import AppLoading from 'component/appLoading'

interface IOrgMembersProps {
  organization: IOrganization
}

const OrgMembers = ({ organization }: IOrgMembersProps) => {
  const classes = useStyles()
  const user = useUser()
  const {
    filteredMembers,
    handleSearchQueryChange,
    handleMenuItemSelect,
    dialogOpen,
    toggleDialogOpen,
    onAddNewMember,
  } = useOrgMembers(user, organization)

  if (!user) {
    return null
  }

  if (!filteredMembers || filteredMembers.length === 0) {
    return (
      <div className={classes.loadingContainer}>
        <AppLoading visible />
      </div>
    )
  }

  return (
    <div>
      <NewMemberDialog onAddNewMember={onAddNewMember} open={dialogOpen} onClose={toggleDialogOpen} />
      <div className={classes.membersHeader}>
        <Typography className={classes.orgName}>
          Organization <b>{organization.name}</b>
        </Typography>

        <Button variant="contained" color="primary" onClick={toggleDialogOpen}>
          + New org member
        </Button>

        <div className={classes.searchBar}>
          <SearchIcon className={classes.searchIcon} />
          <TextField
            className={classes.searchInput}
            placeholder="Search members"
            onChange={handleSearchQueryChange}
            InputProps={{ disableUnderline: true }}
          />
        </div>
      </div>

      {filteredMembers?.map(member => (
        <OrgMemberListItem
          key={member.id}
          member={member}
          ownerIds={organization.ownerIds}
          onMenuItemSelect={handleMenuItemSelect}
          organizationName={(member.approved && organization.name) || ''}
        />
      ))}
    </div>
  )
}

export default OrgMembers
