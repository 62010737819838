import React from 'react'

interface ISpacerProps {
  height?: number | string
  width?: number | string
}

const Spacer = ({ height, width }: ISpacerProps) => {
  return <div style={{ minHeight: height, minWidth: width }}></div>
}

export default Spacer
