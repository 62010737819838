import { ErrorWithRenderedComponent } from 'lib/errors'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { handleError } from '../actions/error'
import ErrorReporter from '../component/errorReporter'
import { selectError } from '../selectors/error'

interface IErrorReporterPageActions {
  handleError: (error: Error) => void
}

interface IErrorReporterPageProps {
  actions: IErrorReporterPageActions
  error: TErrorState
}

interface IErrorReporterState {}

export class ErrorReporterPage extends React.Component<IErrorReporterPageProps, IErrorReporterState> {
  componentDidCatch(error: Error) {
    this.props.actions.handleError(error)
  }
  render() {
    const error = this.props.error

    if (!error) return this.props.children

    if (this.props.error instanceof ErrorWithRenderedComponent) {
      return this.props.error.component()
    }

    return <ErrorReporter />
  }
}

const mapStateToProps = (state: IState, ownProps: Partial<IErrorReporterPageProps>) => {
  return {
    error: selectError(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Partial<IErrorReporterPageProps>) => {
  const actions = bindActionCreators(
    {
      handleError: handleError,
    },
    dispatch
  )

  return {
    actions: actions,
  }
}

const reduxContainer = connect(mapStateToProps, mapDispatchToProps)(ErrorReporterPage)

export default reduxContainer
