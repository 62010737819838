import * as React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { getThreadProjectsQueryKey } from './useThreadProjects'
import { IProject } from 'model/project'
import { useUser } from 'hooks/auth'

export const useThreadProjectActions = () => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const refreshThreadProjects = React.useCallback(
    (threadId: string) => {
      if (!currentUser?.id) return

      queryClient.invalidateQueries(getThreadProjectsQueryKey({ userId: currentUser.id, threadId }))
    },
    [queryClient, currentUser?.id]
  )

  const getThreadProjects = React.useCallback(
    (threadId: string) => {
      if (!currentUser?.id) return []

      const projects = queryClient.getQueryData<IProject[]>(
        getThreadProjectsQueryKey({ userId: currentUser?.id, threadId })
      )
      return projects ?? []
    },
    [queryClient, currentUser?.id]
  )

  return { refreshThreadProjects, getThreadProjects }
}
