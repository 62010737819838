import * as React from 'react'
import { MOCK_MESSAGES } from 'tests/stubs/messageApi'
import { DraftMessageBlockWithAvatar } from './draftMessageBlockWithAvatar'
import { ANONYMOUS_USER } from 'constants/auth'

export const DraftMessageBlockWithAvatarSkeleton = ({
  currentUser,
  draftContainerRef,
}: {
  currentUser: IUser
  draftContainerRef: React.MutableRefObject<HTMLDivElement | null>
}) => {
  const fakeDraft: IDraftMessage = {
    ...MOCK_MESSAGES[0],
    draftType: 'reply',
    sender: { ...ANONYMOUS_USER, id: currentUser.id },
  }

  fakeDraft.content = Array(2).fill('random big line of text to simulate long content on message\n').join('')

  return (
    <DraftMessageBlockWithAvatar
      draft={fakeDraft}
      currentUser={currentUser}
      isLoading={true}
      draftContainerRef={draftContainerRef}
    />
  )
}
