import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectRightSidebar } from 'selectors/rightSidebar'
import classNames from 'classnames'
import RightSidebar from 'component/rightSidebar'
import MessageList from 'component/messageList'
import { useReimportThreadUseCase } from 'usecases/reimportThread'
import { useReimportMessageUseCase } from 'usecases/message/reimportMessage'
import { useThreadPageContext } from 'page/threadPageContextProvider'
import { useThreadSharingSettings } from 'hooks/useSharingGroup/useThreadSharingSettings'
import { useThreadOnboardingTour } from 'hooks/onboardingTour/useThreadOnboardingTour'
import { useThreadLoadedListener } from 'hooks/useThreadLoadedListener'

const useThreadContentStyles = makeStyles((theme: Theme) => ({
  sidebarContainerHidden: {
    width: '0%',
    backgroundColor: theme.threadPalette.white,
    transition: 'width 0.2s',
  },
  sidebarContainerVisible: {
    transition: 'width 0.2s',
    width: '100%',
    right: 0,
    height: '100%',
    backgroundColor: theme.threadPalette.white,
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.dimensions.rightSidebar.maxWidth,
      marginTop: 0,
      position: 'relative',
    },
  },
  threadContent: {
    flexGrow: 1,
    maxWidth: '100%',
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
}))

interface IThreadContentProps {
  thread: IThread
  currentUser: IUser
}

const ThreadContent = ({ thread, currentUser }: IThreadContentProps) => {
  const classes = useThreadContentStyles()

  const rightSidebarOption = useSelector(selectRightSidebar).optionSelected
  const isAnyOptionSelectedForRightSidebar = rightSidebarOption !== 'none'

  const {
    messagesList,
    isLoadingMessages,
    fetchNextMessagesPage,
    isRefreshingMessages,
    hasMoreMessagePages,
    messagesScrollPosition,
    setMessagesScrollPosition,
    createDraft,
    deleteDraft,
  } = useThreadPageContext()

  const { threadSharingSettingsList, isLoading: isLoadingThreadSharingSettings } = useThreadSharingSettings(thread.id)

  const { reimportThreadUseCase } = useReimportThreadUseCase()
  const { reimportMessageUseCase } = useReimportMessageUseCase()

  const { startThreadOnboardingTour } = useThreadOnboardingTour()

  useThreadLoadedListener({ callback: startThreadOnboardingTour })

  const onDeleteDraft = React.useCallback(
    (repliedOrForwardedMessageId: string, draftToRemoveId: string) => {
      deleteDraft({
        draftId: draftToRemoveId,
        repliedOrForwardedMessageId,
        threadId: thread.id,
      })
    },
    [deleteDraft, thread]
  )

  const onThreadReimport = () => reimportThreadUseCase(thread.id)

  const onMessageReimport = async (messageId: string) => {
    reimportMessageUseCase({ messageId, threadId: thread.id })
  }

  return (
    <>
      <div className={classes.threadContent}>
        <MessageList
          currentThread={thread}
          messagesList={messagesList}
          onMessageReimport={onMessageReimport}
          fetchNextMessagesPage={fetchNextMessagesPage}
          user={currentUser}
          createDraft={createDraft}
          deleteDraft={onDeleteDraft}
          isLoading={isLoadingMessages}
          onThreadReimport={onThreadReimport}
          threadSharingSettingsList={threadSharingSettingsList}
          isLoadingThreadSharingSettings={isLoadingThreadSharingSettings}
          isRefreshingMessages={isRefreshingMessages}
          hasMoreMessagePages={hasMoreMessagePages}
          scrollPosition={messagesScrollPosition}
          setScrollPosition={setMessagesScrollPosition}
        />
      </div>
      <div
        className={classNames({
          [classes.sidebarContainerVisible]: isAnyOptionSelectedForRightSidebar,
          [classes.sidebarContainerHidden]: !isAnyOptionSelectedForRightSidebar,
        })}
      >
        <RightSidebar />
      </div>
    </>
  )
}

export default ThreadContent
