import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface IItalicIconProps extends JSX.IntrinsicAttributes {
  className?: string
}

const ItalicIcon = ({ className }: IItalicIconProps) => {
  return (
    <SvgIcon className={className} viewBox="-1 -3 18 18">
      <path
        fill="#606060"
        fillRule="evenodd"
        d="M5.72 0L5.5.875h1.532c.36 0 .58.286.481.636L4.581 12.489c-.099.35-.47.636-.831.636H2.219L2 14h6.343l.22-.875h-1.75c-.362 0-.581-.285-.483-.636L9.263 1.511c.099-.35.47-.636.83-.636h1.532L11.844 0H5.719z"
      />
    </SvgIcon>
  )
}

export default ItalicIcon
