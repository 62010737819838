import React from 'react'
import { OnboardingStepTextLines } from '../onboardingStepTextLines'
import { InlineEmoji } from 'shared/components/inlineEmoji'

export const FollowingBadgeStep = () => {
  const textLines = [
    <>
      You are <strong>following</strong> this thread
    </>,
    <>
      <InlineEmoji emoji="👈" ariaLabel="point-left" withPadding />
      Find your followed threads in the lefthand menu.
    </>,
    <>Keep your workspace clean by unfollowing a thread once you're done working with it.</>,
    <>
      Don't worry about losing it; we'll store it for you, and you can easily find it later
      <InlineEmoji emoji="✅." ariaLabel="check-mark" withPadding />
    </>,
  ]

  return <OnboardingStepTextLines textLines={textLines} maxWidth={400} />
}
