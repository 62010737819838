import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface IMentionsIconProps {
  className?: string
  onClick?: (event: React.SyntheticEvent<unknown, MouseEvent>) => void
}

const MentionsIcon = (props: IMentionsIconProps) => {
  return (
    <SvgIcon {...props} viewBox="-2 -1 25 25">
      <path
        fill="#606060"
        fillRule="evenodd"
        d="M10 13.333c-1.841 0-3.333-1.492-3.333-3.333 0-1.841 1.492-3.333 3.333-3.333 1.841 0 3.333 1.492 3.333 3.333 0 1.841-1.492 3.333-3.333 3.333M10.002 0h-.001C9.019 0 7.45.145 5.74.949c-.744.35-2.176 1.136-3.439 2.665C.05 6.342 0 9.416 0 10.001c.003 4.194 2.663 6.817 3.1 7.233.118.113 2.945 2.735 6.9 2.766h.073c1.569 0 2.829-.403 3.05-.476 1.17-.385 2.047-.925 2.618-1.34.382-.261.479-.792.215-1.173-.162-.237-.427-.364-.698-.364-.164 0-.33.048-.476.148-.528.382-1.395.916-2.576 1.237-.165.044-1.045.278-2.126.278H10c-3.419-.035-5.8-2.357-5.8-2.357-2.461-2.396-2.509-5.435-2.509-5.952 0-.367.047-3.303 2.24-5.67.872-.94 1.8-1.486 2.373-1.77 1.528-.762 2.931-.869 3.697-.87h.013c1.775 0 3.092.566 3.644.835.346.169 1.834.928 3.107 2.644.708.953 1.545 2.84 1.545 4.83 0 .934-.767 1.701-1.7 1.701-.932 0-1.7-.767-1.7-1.7 0-1.033-.125-1.602-.753-2.624-.184-.302-.37-.512-.482-.636-.718-.803-1.508-1.162-1.78-1.275-.285-.12-.975-.374-1.894-.376h-.004c-.854 0-1.491.22-1.742.314-.234.09-.763.312-1.328.767-.962.779-1.367 1.707-1.488 2.01-.13.326-.348.974-.349 1.819 0 .91.25 1.6.387 1.92.156.373.558 1.187 1.415 1.885 1.31 1.065 2.753 1.106 3.105 1.106H10c.44-.001 1.431-.06 2.477-.672 1.027-.6 1.564-1.413 1.783-1.797.197.19.528.462 1.004.67.202.086.697.277 1.335.277h.01c1.388-.004 2.307-.91 2.413-1.018.94-.96.976-2.129.977-2.371.026-3.171-1.78-5.767-2.234-6.317-.521-.632-2.054-2.335-4.647-3.186C12.521.3 11.415 0 10.002 0"
      />
    </SvgIcon>
  )
}

export default MentionsIcon
