import React from 'react'
import classNames from 'classnames'
import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core'
import AppLoading from '../appLoading'

interface ISubmitButtonWithLoadingProps extends ButtonProps {
  isLoading?: boolean
}
const useStyles = makeStyles((theme: Theme) => {
  return {
    submitButtonLoadingStyle: {
      border: `solid 1px ${theme.palette.primary.main}`,
      '.Mui-disabled': {
        backgroundColor: `${theme.palette.action.disabledBackground}`,
      },
      '&:disabled': {
        backgroundColor: `${theme.palette.action.disabledBackground}`,
      },
    },
  }
})

const SubmitButtonWithLoading = React.forwardRef<HTMLButtonElement, ISubmitButtonWithLoadingProps>(
  ({ children, isLoading, disabled, className, startIcon, ...buttonProps }, ref) => {
    const classes = useStyles()
    const buttonContent = isLoading ? <AppLoading visible small /> : children
    const isButtonDisabled = isLoading || disabled
    const startIconToRender = isLoading ? null : startIcon

    const buttonClass = isLoading ? classNames(classes.submitButtonLoadingStyle, className) : className
    return (
      <Button
        ref={ref}
        disabled={isButtonDisabled}
        variant="contained"
        className={buttonClass}
        startIcon={startIconToRender}
        {...buttonProps}
      >
        {buttonContent}
      </Button>
    )
  }
)

export default SubmitButtonWithLoading
