import * as React from 'react'
import { Typography, Button, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useProjectsViewHeaderStyles = makeStyles((theme: Theme) => {
  return {
    projectsViewHeaderRoot: {
      display: 'flex',
      flexDirection: 'column',
    },
    projectOptionsWrapper: {
      alignItems: 'center',
      display: 'inline-flex',
      justifyContent: 'space-around',
    },
    newProjectButton: {
      color: theme.threadPalette.orange,
      fontSize: 14,
      width: '50%',
    },
    projectDisplayLabel: {
      color: theme.threadPalette.white,
      fontSize: 12,
      fontWeight: 500,
    },
  }
})

interface IProjectsViewHeaderProps {
  onNewProjectClick?: () => void
}

export const ProjectsViewHeader = ({ onNewProjectClick }: IProjectsViewHeaderProps) => {
  const classes = useProjectsViewHeaderStyles()

  return (
    <div className={classes.projectsViewHeaderRoot}>
      <div className={classes.projectOptionsWrapper}>
        <Typography className={classes.projectDisplayLabel}>Active projects</Typography>
        <Button classes={{ root: classes.newProjectButton }} onClick={onNewProjectClick}>
          + New Project
        </Button>
      </div>
    </div>
  )
}
