import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  loadingContainer: {
    width: '100%',
    height: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  membersHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 26,
    marginBottom: 32,
  },

  orgName: {
    flex: 1,
  },

  searchBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
    paddingLeft: 8,
    marginLeft: 24,
    marginRight: 24,
    borderRadius: 4,
    border: `solid 1px ${theme.threadPalette.lightishGray}`,
  },
  searchInput: {
    fontSize: 13,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  searchIcon: {
    fill: theme.threadPalette.lightGray,
  },

  divider: {
    color: theme.threadPalette.lighterGray,
    width: '100%',
    marginBottom: 13,
  },

  listItemContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  listItemDetails: {
    marginLeft: 12,
    flex: 1,
  },

  memberNameRow: {
    display: 'flex',
  },

  listItemRoot: {
    height: 66,
  },

  listItemName: {
    fontSize: 15,
    display: 'flex',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  listItemOrgName: {
    color: theme.threadPalette.lightGray,
    fontSize: 13,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  listItemAdmin: {
    color: theme.threadPalette.lightGray,
    fontSize: 15,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  pendingInvite: {
    color: theme.palette.error.main,
    fontSize: 15,
    marginRight: 27,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'right',
  },

  inviteTimestamp: {
    fontSize: 15,
    marginRight: 27,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'right',
  },

  moreIcon: {
    marginRight: 15,
    fill: theme.palette.primary.main,
  },

  menuItem: {
    color: theme.threadPalette.brownishGray,
    margin: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.threadPalette.lighterGray,
    },
  },
}))
