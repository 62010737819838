import React from 'react'

export const LeiaIcon = () => {
  return (
    <svg width="280" height="419" viewBox="0 0 280 419" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 1)" fill="none" fillRule="evenodd">
        <ellipse fill="#E5E5E5" fillRule="nonzero" cx="140" cy="396" rx="140" ry="22" />
        <path
          d="M107.646 247.267c-13.026-4.265-26.522-10.8-37.219-19.586 8.976-28.68 11.861-47.68 8.282-52.243a353.268 353.268 0 0 0 28.157 41.528c-.737-16.888.801-30.653 3.964-50.42 5.097-3.53 10.996-6.397 18.497-9.521a8.851 8.851 0 0 0 0-3.561l11.466-5.331c.277.908.688 1.77 1.219 2.559 7.937.58 15.902.669 23.85.266 12.727 15.46 24.578 30.984 38.138 44.28-11.06 22.698-18.305 38.382-25.838 71.956 2.671-23.52-4.756-41.773-12.823-39.95l22.354-29.704c-8.153-6.397-15.686-12.432-23.776-22.443-.715 18.445 2.49 42.487 2.971 70.102 2.383 38.947-6.411 67.49 24.94 151.707-27.836 8.263-66.946 9.404-96.3 2.74-2.629-56.945 1.635-90.316 14.084-148.978 6.059-24.98 10.846-43.948 10.216-53.618-12.535-8.53-1.635-18.883-1.635-28.638"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="m167.519 227.276 7.854-3.721 5.642 18.477-2.853 23.072s-2.266 11.504-6.508 10.545c-3.783-.853-3.462-12.219-4.67-23.595-1.24-11.653-2.842-21.228-.566-24.767M71.57 229.238s.695-36.421 8.282-52.243l.93 2.281c2.18 6.557-1.486 24.32-9.211 49.962z"
          fill="#95B1C9"
          fillRule="nonzero"
        />
        <path
          d="M70.427 227.692s.684-36.421 8.282-52.243"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M167.401 279.764v2.73M97.462 243.503a108.859 108.859 0 0 1-27.035-15.811c8.976-28.68 11.861-47.68 8.282-52.243a353.268 353.268 0 0 0 28.157 41.528c-.737-16.889.801-30.653 3.964-50.42 5.097-3.53 10.996-6.397 18.497-9.521a8.851 8.851 0 0 0 0-3.561l11.466-5.331c.277.908.688 1.77 1.219 2.558 7.937.58 15.902.67 23.85.267 12.727 15.46 24.578 30.983 38.138 44.279-11.06 22.699-18.305 38.382-25.838 71.957 2.671-23.52-4.756-41.774-12.823-39.95l22.354-29.704c-8.153-6.397-15.686-12.432-23.776-22.444-.512 13.083.962 28.99 2.02 46.987M107.646 247.267a142.655 142.655 0 0 1-6.668-2.367"
          stroke="#231F20"
          fill="#FFF"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M178.162 267.226s-2.266 11.504-6.508 10.555c-3.783-.853-3.462-12.218-4.67-23.595-1.24-11.664-3.921-23.37-1.656-26.91M167.508 286.982c.63 26.516 4.22 55.911 24.31 109.956-27.836 8.263-66.947 9.404-96.3 2.74-2.63-56.945 1.634-90.316 14.083-148.978 6.06-24.98 10.847-43.948 10.216-53.618-12.534-8.53-1.635-18.883-1.635-28.638"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M116.301 228.523s6.668 1.386 23.178 1.696c18.166.351 26.939-1.696 26.939-1.696v6.845s-9.211 1.312-27.548 1.066c-17.236-.255-23.936-2.047-23.936-2.047l1.367-5.864z"
          fill="#4B5A69"
          fillRule="nonzero"
        />
        <path
          d="M115.211 227.276s6.668 1.386 23.189 1.706c18.166.341 26.928-1.706 26.928-1.706v6.813s-9.211 1.322-27.537 1.066c-17.247-.256-23.947-2.047-23.947-2.047l1.367-5.832z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M78.228 198.905c-4.734-14.191-12.92-22.891-12.823-29.235.117-5.16 3.141-11.174 5.706-15.78-1.4-3.06-9.842-13.188-6.208-15.054 3.099-1.589 6.25 7.111 11.241 13.7 2.853 3.753 7.64 9.713 7.555 13.253-.128 4.926-4.071 11.888-4.071 11.888l.32 2.442s.77 3.038-.513 11.504c-.684 4.51-1.207 7.282-1.207 7.282zM166.974 227.18c-4.275.778-10.29 1.76-12.823 4.265-1.4 1.343-.214 3.593.074 6.045l2.982-3.497c-1.806 2.132-4.146 3.838-2.853 6.61.972 2.069 5.695 6.824 10.418 7.773 3.056.618 5.28-3.007 7.31-7.336a57.848 57.848 0 0 0 2.49-6.333c-2.117-4.5-4.777-7.218-7.598-7.527z"
          stroke="#231F20"
          fill="#F47823"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m94.638 129.557 10.442 24.522a2 2 0 0 1-1.06 2.625l-1.097.465a2 2 0 0 1-2.62-1.058L89.86 131.59a2 2 0 0 1 1.06-2.624l1.097-.466a2 2 0 0 1 2.62 1.058zM110.285 185.887 63.642 76.347a4.142 4.142 0 0 0-2.266-2.217 4.155 4.155 0 0 0-3.173.031l-7.919 3.358a4.133 4.133 0 0 0-2.137 5.427l6.764 15.897a6.774 6.774 0 0 0 2.715 3.199 6.882 6.882 0 0 1 2.66 3.038l13.635 31.986a2.525 2.525 0 0 1-1.335 3.305l4.766 11.195-11.595 22.39-2.842.608a1.683 1.683 0 0 0-.598 3.039l7.362 5.064 12.823-18.85 12.235 28.69a4.15 4.15 0 0 0 5.44 2.133l7.918-3.348a4.144 4.144 0 0 0 2.19-5.405z"
          fill="#4B5A69"
          fillRule="nonzero"
        />
        <path
          d="M70.15 153.922a7.075 7.075 0 0 1-5.713-6.201 7.067 7.067 0 0 1 4.291-7.254l2.052-.874"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.659 148.698a5.116 5.116 0 0 1-5.087 1.066M92.826 128.691l10.442 24.522a2 2 0 0 1-1.06 2.625l-1.096.465a2 2 0 0 1-2.62-1.058l-10.443-24.521a2 2 0 0 1 1.06-2.625l1.096-.465a2 2 0 0 1 2.62 1.057z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="55.681"
          cy="81.251"
          rx="3.655"
          ry="3.646"
        />
        <ellipse
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="55.681"
          cy="81.251"
          rx="1.229"
          ry="1.226"
        />
        <path
          d="M108.49 185.044 61.836 75.472a4.15 4.15 0 0 0-5.44-2.132l-7.917 3.348a4.144 4.144 0 0 0-2.138 5.437l6.764 15.897a6.881 6.881 0 0 0 2.683 3.092 6.807 6.807 0 0 1 2.714 3.135l13.635 31.985a2.525 2.525 0 0 1-1.336 3.306l4.766 11.194-11.594 22.39-2.842.619a1.683 1.683 0 0 0-.598 3.039l7.362 5.064 12.823-18.861 12.203 28.67a4.142 4.142 0 0 0 2.263 2.223 4.155 4.155 0 0 0 3.176-.027l7.918-3.358a4.152 4.152 0 0 0 2.24-2.266 4.139 4.139 0 0 0-.028-3.183z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m77.585 122.773 6.04 14.184a2.525 2.525 0 0 1-1.337 3.314l-.317.135a2.525 2.525 0 0 1-3.309-1.336l-6.04-14.184a2.525 2.525 0 0 1 1.337-3.314l.317-.135a2.525 2.525 0 0 1 3.31 1.336z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m70.983 155.044 5.39 4.942a2.22 2.22 0 0 1 .133 3.14l-.21.228c-.829.9-2.23.96-3.133.133l-5.39-4.942a2.22 2.22 0 0 1-.133-3.14l.21-.228c.829-.9 2.23-.96 3.133-.133zM69.111 159.861l4.006 3.673a2.22 2.22 0 0 1 .132 3.14l-.21.228c-.828.9-2.23.96-3.132.132l-4.006-3.672a2.22 2.22 0 0 1-.133-3.14l.21-.228c.83-.9 2.23-.96 3.133-.133zM67.87 165.27l2.717 2.49a1.925 1.925 0 0 1 .115 2.724l-.175.19c-.72.781-1.935.832-2.717.115l-2.717-2.491a1.925 1.925 0 0 1-.115-2.723l.175-.19a1.925 1.925 0 0 1 2.717-.115z"
          stroke="#231F20"
          fill="#F47823"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.628 177.709c3.205-5.48 6.892-15.822 6.101-18.125-.384-1.173-8.281-4.851-8.687-2.133-.374 2.517 4.274 4.478 4.274 4.478s-8.068 6.685-7.48 14.426M140.793 148.165a39.195 39.195 0 0 1-1.068-7.943c-.866-12.688 3.067-20.556-.727-29.406-3.793-8.849-17.899-15-25.785-1.47-2.864 4.936-3.376 7.462-2.832 15.118.369 3.21-.469 6.443-2.35 9.073a4.108 4.108 0 0 0-.327 4.191 4.125 4.125 0 0 0 3.532 2.291c.26.017.52.017.78 0 .834 3.625 2.5 9.521 7.887 10.822 4.701 1.13 8.623-.746 9.392 2.634 4.873-.416 8.773-2.037 11.498-5.31z"
          stroke="#231F20"
          fill="#F47823"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M115.628 118.877c3.505-.555 5.952.906 7.106 3.508"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M119.806 125.999a4.959 4.959 0 0 1-.555 2.879c-.064.106-.171.234-.289.192a.341.341 0 0 1-.17-.214 7.215 7.215 0 0 1-.61-3.145c-.039-.418.07-.837.31-1.183a.578.578 0 0 1 .77-.086c.383.425.58.986.544 1.557z"
          stroke="#231F20"
          fill="#1E2D44"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M121.324 128.963a4.194 4.194 0 0 0-3.772 1.333"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M119.165 113.93c-2.97-1.152-11.754-4.425-6.411-12.293 7.619-11.227 29.236-10.833 40.68.597 10.857 10.843 15.516 25.407-1.57 39.331-7.128 5.822-18.038 1.44-19.651-8.86-1.54-9.98-2.522-14.692-13.048-18.775z"
          stroke="#231F20"
          fill="#1E2D44"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M114.987 142.002s5.012.128 7.277-2.686"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse
          stroke="#231F20"
          fill="#1E2D44"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="147.622"
          cy="127.054"
          rx="20.239"
          ry="20.194"
        />
        <ellipse
          stroke="#231F20"
          fill="#1E2D44"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="151.233"
          cy="127.161"
          rx="9.532"
          ry="9.51"
        />
        <path
          d="M61.81 41.228a19.755 19.755 0 0 0-5.418-11.41 13.64 13.64 0 0 1-3.815-7.842c-.385-2.97.209-5.99 1.688-8.58A19.71 19.71 0 0 0 56.625.969l-6.648.88a12.863 12.863 0 0 1-1.661 8.567 20.642 20.642 0 0 0-2.24 12.363 20.475 20.475 0 0 0 5.33 11.339 13.64 13.64 0 0 1 3.815 7.841c.385 2.971-.209 5.99-1.688 8.58A19.777 19.777 0 0 0 51.24 63l6.647-.914a12.932 12.932 0 0 1 1.695-8.567 19.982 19.982 0 0 0 2.227-12.29z"
          fill="#AAA"
          fillRule="nonzero"
        />
        <path
          d="M59.906 40.259a19.755 19.755 0 0 0-5.417-11.41 13.64 13.64 0 0 1-3.815-7.842c-.385-2.971.21-5.99 1.688-8.58A19.71 19.71 0 0 0 54.722 0l-6.647.88a12.863 12.863 0 0 1-1.662 8.567 20.642 20.642 0 0 0-2.24 12.363 20.475 20.475 0 0 0 5.33 11.339 13.64 13.64 0 0 1 3.816 7.84c.385 2.972-.21 5.99-1.688 8.581a19.777 19.777 0 0 0-2.294 12.46l6.647-.913a12.932 12.932 0 0 1 1.695-8.567 19.982 19.982 0 0 0 2.227-12.29v-.001z"
          stroke="#231F20"
        />
      </g>
    </svg>
  )
}
