import * as React from 'react'
import { SvgIcon } from '@material-ui/core'

interface ILinkIconProps {
  className?: string
}

const LinkIcon = ({ className }: ILinkIconProps) => {
  return (
    <SvgIcon className={className} viewBox="0 -4 25 25">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#606060" fillRule="nonzero">
          <path
            d="M1.41942982,11.7161522 C-0.473124047,13.6087232 -0.473147855,16.6880797 1.41938589,18.5806021 C3.31192264,20.4731488 6.39126699,20.4731245 8.28382632,18.5805778 L11.7160646,15.1482922 C13.6086242,13.2557698 13.6086562,10.1764133 11.7161204,8.2838763 C11.5330774,8.0955989 11.2628384,8.02046167 11.0088776,8.08723458 C10.7549168,8.15400748 10.5565779,8.35234606 10.4898046,8.6063067 C10.4230313,8.86026735 10.4981681,9.13050648 10.6864452,9.31354985 C12.0263651,10.6534817 12.0263403,12.7787257 10.6864158,14.1186526 L7.25415883,17.5508897 C5.91424062,18.8908409 3.7889745,18.8908652 2.44905459,17.5509383 C1.10914802,16.2110356 1.10916186,14.0857673 2.44908978,12.7458161 L5.53811237,9.65680026 C5.72647007,9.47377071 5.80167025,9.20349209 5.73491039,8.94948418 C5.66815054,8.69547628 5.46977672,8.49710381 5.21576835,8.43034569 C4.96175999,8.36358757 4.69148189,8.4387896 4.50845363,8.62714855 L1.41942982,11.7161522 L1.41942982,11.7161522 Z M8.28392388,4.85167805 C6.39136722,6.74423932 6.3913432,9.82358124 8.28387486,11.7161279 C8.46695243,11.9042522 8.73711799,11.9792711 8.99098439,11.9124764 C9.24485078,11.8456817 9.4431158,11.6474141 9.50990725,11.3935468 C9.57669869,11.1396796 9.50167627,10.869515 9.31354962,10.6864398 C7.97363772,9.34653715 7.97365034,7.22126642 9.31358263,5.88133704 L12.7458284,2.44909268 C14.0857602,1.10916088 16.2110276,1.10914874 17.5509378,2.44905628 C18.8908528,3.78897352 18.8908397,5.91424425 17.5509043,7.25416877 L14.4618749,10.34317 C14.2740126,10.5263055 14.1991902,10.7963404 14.2660121,11.0500413 C14.332834,11.3037422 14.5309676,11.5018798 14.7846672,11.5687068 C15.0383668,11.6355337 15.3084031,11.5607168 15.4915424,11.3728581 L18.5805662,8.28383504 C20.4731207,6.39127135 20.4731551,3.31192943 18.5806118,1.41938273 C16.6880804,-0.473146991 13.6087271,-0.473125148 11.7161697,1.41943369 L8.28392388,4.85167805 L8.28392388,4.85167805 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  )
}

export default LinkIcon
