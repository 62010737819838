import { base64Encrypt, saveLoginState } from './../../dataProcessors/utils'
import { setAuthTokensFromAuthResponse } from '@jetkit/react'
import {
  SubscriptionCreationForbiddenError,
  SubscriptionCreationFailedError,
  OAuthScopeError,
  ErrorWithRenderedComponent,
} from 'lib/errors'

import { handleError } from '../../actions/error'
import { push } from 'actions/navigation'
import { safelyParseURL } from 'lib/url'

export const OAuthLoginActionCreator = (actionType: string, api: IOAuthAPI): TOAuthLoginAction => {
  return ({ redirectURL }: { redirectURL?: string }) => {
    return async (dispatch, getState) => {
      let response = null

      dispatch({
        type: actionType,
      })

      try {
        response = await api.login({ redirectURL })
      } catch (error) {
        dispatch(handleError(error))
        return
      }

      window.location.assign(response.authorization_url)
    }
  }
}

export const OAuthHandleCallbackActionCreator = (
  actionType: string,
  api: IOAuthAPI,
  emailProvider: TEmailProvider
): TOAuthHandleCallbackAction => {
  return (props: IAuthCallbackProps) => {
    const { state } = props
    return async (dispatch, getState) => {
      // saving login state integrity check after redirect
      saveLoginState(state)
      let response = null

      dispatch({
        type: actionType,
      })

      try {
        response = await api.handleCallback(props)
      } catch (error) {
        if (error.response.status === 403) {
          const reason = error.response?.data?.message?.reason

          const reasonErrorMap = new Map<string, ErrorWithRenderedComponent>([
            ['subscription', new SubscriptionCreationForbiddenError(emailProvider)],
            ['scope', new OAuthScopeError(emailProvider)],
          ])

          const errorToDispatch = reasonErrorMap.get(reason)

          if (errorToDispatch) {
            dispatch(handleError(errorToDispatch))
          }
          return
        }
        dispatch(handleError(new SubscriptionCreationFailedError(emailProvider)))
        return
      }
      const { redirect_url: redirectURLFromAuthResponse, ...authCredentials } = response

      const redirectURISegment = redirectURLFromAuthResponse ?? '/'

      const redirectURL = safelyParseURL(redirectURISegment)

      if (redirectURL) {
        try {
          chrome.runtime.sendMessage('oofkafbgfbpmpcpiideplooccmmmhikm', authCredentials)
        } catch {
          const authCredentialsAsString = JSON.stringify(authCredentials)
          redirectURL.searchParams.append('credentials', base64Encrypt(authCredentialsAsString))
          window.location.replace(redirectURL)
        }
      } else {
        setAuthTokensFromAuthResponse(authCredentials)
        dispatch(push(redirectURISegment))
      }
    }
  }
}
