import * as React from 'react'
import { PopoverPosition } from '@material-ui/core'

export function useMenu() {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<Element | null>(null)
  const [menuPosition, setMenuPosition] = React.useState<PopoverPosition>()

  const dismissMenu = React.useCallback(() => {
    setMenuAnchorEl(null)
  }, [])

  const onMenuIconClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      setMenuAnchorEl(event.currentTarget)
      if (menuPosition) {
        return
      }
      setMenuPosition({
        top: event.pageY,
        left: event.pageX,
      })
    },
    [menuPosition]
  )

  const onMenuClose = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.stopPropagation()
      event.nativeEvent.stopPropagation()
      dismissMenu()
    },
    [dismissMenu]
  )

  return {
    menuAnchorEl,
    menuPosition,
    setMenuPosition,
    onMenuIconClick,
    onMenuClose,
    dismissMenu,
    isOpen: !!menuPosition,
  }
}
