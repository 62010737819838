import React from 'react'
import { Button, makeStyles, Theme } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import AppLoading from 'component/appLoading'

const useNewItemButtonStyles = makeStyles<Theme, { fontSize?: number; iconSize?: number }>((theme: Theme) => ({
  newItemButtonRoot: {
    alignItems: 'center',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: ({ fontSize }) => fontSize,
    justifyContent: 'center',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  addIcon: {
    fontSize: ({ iconSize }) => iconSize,
  },
}))

const NewItemButton = ({
  label,
  onClick,
  isLoading = false,
  isDisabled = false,
  iconSize = 16,
  fontSize = 16,
}: {
  label: string
  onClick?(): void
  isLoading?: boolean
  isDisabled?: boolean
  iconSize?: number
  fontSize?: number
}) => {
  const classes = useNewItemButtonStyles({ fontSize, iconSize })

  return (
    <Button size="small" className={classes.newItemButtonRoot} onClick={onClick} disabled={isDisabled}>
      <>
        <AppLoading small visible={isLoading} padding={{ inline: 8 }} legHeight={16} legWidth={6} />
        {!isLoading && <AddIcon className={classes.addIcon} />}
        {label}
      </>
    </Button>
  )
}

export default NewItemButton
