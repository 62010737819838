import React from 'react'
import { Button, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useHeldMessageActionButtonStyles = makeStyles<Theme, { color: string }>((theme: Theme) => ({
  actionButtonLabel: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  actionButtonRoot: {
    borderRadius: 10,
    height: 56,
    minWidth: 56,
    padding: 4,
  },
  text: {
    color: ({ color }) => color,
    fontSize: 12,
  },
  icon: {
    fill: ({ color }) => color,
    height: 26,
    width: 26,
  },
}))

interface IHeldMessageActionButtonProps {
  text: string
  icon: typeof SvgIcon
  className: string
  color: string
  onClick(): void
}

export const HeldMessageActionButton = ({
  text,
  icon: Icon,
  className,
  color,
  onClick,
}: IHeldMessageActionButtonProps) => {
  const classes = useHeldMessageActionButtonStyles({ color })

  return (
    <Button
      classes={{ root: classNames(classes.actionButtonRoot, className), label: classes.actionButtonLabel }}
      onClick={onClick}
    >
      <Icon className={classes.icon} />
      <Typography className={classes.text}>{text}</Typography>
    </Button>
  )
}
