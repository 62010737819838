import { checkAuth } from 'actions/checkAuth'
import { useDispatch } from 'react-redux'
import * as React from 'react'
import { useInputField } from 'hooks/useInputField'
import { useBoolean } from 'hooks/useBoolean'
import { IOrgSettings, TSubscriptionPlan } from 'model/organization'
import { getOrgSettings, updateOrgSettings } from 'api/organization'

export function useOrgSettings(organizationId: string) {
  const dispatch = useDispatch()
  const { value: orgName, setValue: setOrgName, handleChange: handleOrgNameChange } = useInputField()
  const { value: subPlan, setValue: setSubPlan, handleChange: handleSubPlanChange } = useInputField()
  const { setValue: setPaymentMethod } = useInputField()
  const [orgSettings, setOrgSettings] = React.useState<IOrgSettings>()
  const { state: nameEditDisabled, toggle: toggleNameEdit } = useBoolean(true)
  const { state: planEditDisabled, toggle: togglePlanEdit } = useBoolean(true)

  const subscriptionPlans = React.useMemo(() => {
    return [
      {
        label: `SMB (${orgSettings?.monthlyFeePerUser} per user / month)`,
        value: 'smb',
      },
      {
        label: `Enterprise (${orgSettings?.monthlyFeePerUser} per user / month)`,
        value: 'enterprise',
      },
    ]
  }, [orgSettings])

  const getPlanLabel = React.useCallback(
    (planValue: string) => {
      const plan = subscriptionPlans.find(item => item.value === planValue)?.label
      if (plan) return plan
      else return subscriptionPlans[0].label
    },
    [subscriptionPlans]
  )

  React.useEffect(() => {
    const fetchOrgSettings = async () => {
      const settings = await getOrgSettings(organizationId)
      setOrgSettings(settings)
      setOrgName(settings.name)
      setPaymentMethod(settings.paymentMethod)
      setSubPlan(getPlanLabel(settings.subscriptionPlan))
    }
    fetchOrgSettings()
    // eslint-disable-next-line
  }, [organizationId])

  const handleCancelNameEdit = React.useCallback(() => {
    setOrgName(orgSettings?.name || '')
    toggleNameEdit()
  }, [orgSettings, setOrgName, toggleNameEdit])

  const handleCancelSubscriptionPlanEdit = React.useCallback(() => {
    setSubPlan(orgSettings?.subscriptionPlan as string)
    togglePlanEdit()
  }, [orgSettings, setSubPlan, togglePlanEdit])

  const handleOrgSettingsUpdate = React.useCallback(
    async (options: Partial<IOrgSettings>) => {
      const newSettings = await updateOrgSettings(organizationId, options)
      setOrgSettings(newSettings)
      dispatch(checkAuth())
    },
    [dispatch, organizationId]
  )

  const handleSaveName = React.useCallback(async () => {
    handleOrgSettingsUpdate({ name: orgName })
    toggleNameEdit()
  }, [handleOrgSettingsUpdate, orgName, toggleNameEdit])

  const handleSaveSubPlan = React.useCallback(async () => {
    handleOrgSettingsUpdate({ subscriptionPlan: subPlan as TSubscriptionPlan })
    togglePlanEdit()
  }, [handleOrgSettingsUpdate, subPlan, togglePlanEdit])

  return {
    orgName,
    orgSettings,
    handleOrgNameChange,
    nameEditDisabled,
    handleSaveName,
    handleCancelNameEdit,
    subscriptionPlans,
    subPlan,
    planEditDisabled,
    handleSubPlanChange,
    getPlanLabel,
    handleSaveSubPlan,
    handleCancelSubscriptionPlanEdit,
  }
}
