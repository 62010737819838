import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleFilterThreadsByStarred,
  toggleFilterThreadsByUnread,
  toggleFilterThreadsByFollowed,
  toggleFilterThreadsByUnfollowed,
  toggleFilterThreadsByViewed,
} from 'reducer/leftSidebarThreadsViewSettings'
import {
  selectFilterThreadsByStarred,
  selectFilterThreadsByUnread,
  selectFilterThreadsByFollowed,
  selectFilterThreadsByUnfollowed,
  selectFilterThreadsByViewed,
} from 'selectors/leftSidebarThreadsViewSettings'

export const useThreadsViewHeaderSettings = () => {
  const dispatch = useDispatch()

  const filterByStarred = useSelector(selectFilterThreadsByStarred)
  const filterByUnread = useSelector(selectFilterThreadsByUnread)
  const filterByFollowed = useSelector(selectFilterThreadsByFollowed)
  const filterByUnfollowed = useSelector(selectFilterThreadsByUnfollowed)
  const filterByViewed = useSelector(selectFilterThreadsByViewed)

  const toggleFilterByStarred = React.useCallback(() => {
    dispatch(toggleFilterThreadsByStarred())
  }, [dispatch])

  const toggleFilterByUnread = React.useCallback(() => {
    dispatch(toggleFilterThreadsByUnread())
  }, [dispatch])

  const toggleFilterByFollowed = React.useCallback(() => {
    dispatch(toggleFilterThreadsByFollowed())
  }, [dispatch])

  const toggleFilterByUnfollowed = React.useCallback(() => {
    dispatch(toggleFilterThreadsByUnfollowed())
  }, [dispatch])

  const toggleFilterByViewed = React.useCallback(() => {
    dispatch(toggleFilterThreadsByViewed())
  }, [dispatch])

  return {
    filterByStarred,
    toggleFilterByStarred,
    filterByUnread,
    toggleFilterByUnread,
    filterByFollowed,
    toggleFilterByFollowed,
    filterByUnfollowed,
    toggleFilterByUnfollowed,
    filterByViewed,
    toggleFilterByViewed,
  }
}
