import React from 'react'
import { makeStyles, Theme, useTheme } from '@material-ui/core'
import DialogAnchoredOnComponent from 'component/dialogAnchoredOnComponent'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'

import SubmitButtonWithLoading from 'component/submitButtonWithLoading'
import { MembersChangedConfirmationBlock } from './membersChangedConfirmationBlock'

const useEditSharingGroupButtonStyles = makeStyles((theme: Theme) => ({
  editSharingGroupConfirmationPopupRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    maxWidth: 'min(80vw - 32px,500px)',
    width: 'fit-content',
  },
  editGroupButton: {
    alignSelf: 'end',
    fontSize: 16,
    minHeight: 48,
    minWidth: 148,
    paddingBlock: 10,
    paddingInline: 28,
  },
}))

export interface IEditSharingGroupButtonProps {
  membersAdded?: TPerson[]
  membersRemoved?: TPerson[]
  onEditSharingGroup: () => void
  confirmationPopupAnchorElement: React.RefObject<HTMLElement>
  disabled?: boolean
  isLoading?: boolean
}

export const EditSharingGroupButton = ({
  onEditSharingGroup,
  confirmationPopupAnchorElement,
  membersAdded = [],
  membersRemoved = [],
  disabled = false,
  isLoading = false,
}: IEditSharingGroupButtonProps) => {
  const theme = useTheme()
  const classes = useEditSharingGroupButtonStyles()

  const {
    isOpen: isEditGroupConfirmationPopupOpen,
    dialogAnchorElement,
    openDialog: openEditGroupConfirmationPopup,
    handleCloseDialog: closeEditGroupConfirmationPopup,
    setDialogAnchorRef: setEditGroupConfirmationPopupAnchorElement,
  } = useDialog()

  React.useEffect(() => {
    setEditGroupConfirmationPopupAnchorElement(confirmationPopupAnchorElement.current)
  }, [confirmationPopupAnchorElement])

  const editGroupConfirmationActions = [
    {
      label: 'Cancel',
      action: closeEditGroupConfirmationPopup,
    },
    {
      label: 'Confirm',
      labelColor: theme.palette.primary.main,
      action: onEditSharingGroup,
    },
  ]

  const hasAddedOrRemovedMembers = membersAdded.length > 0 || membersRemoved.length > 0

  const onSubmit = () => (hasAddedOrRemovedMembers ? openEditGroupConfirmationPopup() : onEditSharingGroup())

  const isConfirmationPopupOpen = isEditGroupConfirmationPopupOpen && !isLoading

  return (
    <DialogAnchoredOnComponent
      open={isConfirmationPopupOpen}
      anchoredElementRef={dialogAnchorElement}
      onClose={closeEditGroupConfirmationPopup}
      title="Are you sure you want to update the share group members?"
      message={
        <>
          <MembersChangedConfirmationBlock
            members={membersAdded}
            actionLabel="added to"
            color={theme.threadPalette.green}
          />
          <MembersChangedConfirmationBlock
            members={membersRemoved}
            actionLabel="removed from"
            color={theme.threadPalette.vermilion}
          />
        </>
      }
      textAlign="center"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      actions={editGroupConfirmationActions}
      rootClassName={classes.editSharingGroupConfirmationPopupRoot}
    >
      <SubmitButtonWithLoading
        className={classes.editGroupButton}
        variant="contained"
        color="primary"
        disabled={disabled}
        onClick={onSubmit}
        isLoading={isLoading}
      >
        Update group
      </SubmitButtonWithLoading>
    </DialogAnchoredOnComponent>
  )
}
