import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useReturnToPreviousPageArrowStyles = makeStyles((theme: Theme) => ({
  returnToPreviousPageRoot: {
    color: theme.threadPalette.lightGray,
    cursor: 'pointer',
    height: 30,
    width: 30,
    '&:hover': {
      color: theme.threadPalette.onFocusGray,
    },
  },
  icon: {
    height: 'inherit',
    width: 'inherit',
  },
}))

interface ReturnToPreviousPageArrowProps {
  onClick?(): void
}
export const ReturnToPreviousPageArrow = ({ onClick }: ReturnToPreviousPageArrowProps) => {
  const classes = useReturnToPreviousPageArrowStyles()
  return (
    <span onClick={onClick} className={classes.returnToPreviousPageRoot}>
      <ArrowBackIcon className={classes.icon} />
    </span>
  )
}
