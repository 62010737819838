import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import { OnboardingStepTextLines } from '../onboardingStepTextLines'
import { InlineEmoji } from 'shared/components/inlineEmoji'

const useAddToProjectStepStyles = makeStyles((theme: Theme) => ({
  urlLabel: {
    color: theme.palette.primary.main,
  },
}))

export const AddToProjectStep = () => {
  const classes = useAddToProjectStepStyles()

  const textLines = [
    <>
      Click here to add this thread to a project
      <InlineEmoji emoji="📓" ariaLabel="notebook" withPadding />
    </>,
    <>Projects group threads and allow you to take notes.</>,
    <>
      Similar to <i>threads</i>, each project has its own <span className={classes.urlLabel}>URL</span> that you can
      easily share into other apps and click to return to your workspace.
    </>,
  ]

  return <OnboardingStepTextLines textLines={textLines} maxWidth={250} />
}
