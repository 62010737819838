import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'

import { SharingGroupScreenHeader } from 'component/sharingGroup/common/sharingGroupScreenHeader'
import SubmitButtonWithLoading from 'component/submitButtonWithLoading'

import { useUser } from 'hooks/auth'

import { SelectShareableMessages } from './selectShareableMessages'
import { xor } from 'lodash'
import { useViewMessageEntireContent } from 'component/sharingGroup/common/useViewMessageEntireContent'

const useSelectMessagesToShareStyles = makeStyles((theme: Theme) => ({
  selectMessagesToShareContentContainer: {
    backgroundColor: theme.threadPalette.whiteSmoke,
    borderBottomLeftRadius: 18,
    borderBottomRightRadius: 18,
    paddingBottom: 20,
    paddingTop: 10,
  },
  horizontalPadding: {
    padding: 20,
  },
  confirmSelection: {
    fontSize: 16,
    paddingInline: 20,
  },
}))

interface ISelectMessagesToShareScreenProps {
  thread: IThread
  initialSelectedMessagesIds: string[]
  onConfirmSelectedMessages(selectedMessagesIds: string[]): void
  onReturnToPreviousScreenClick?(): void
}
export const SelectMessagesToShareScreen = ({
  thread,
  initialSelectedMessagesIds,
  onConfirmSelectedMessages,
  onReturnToPreviousScreenClick,
}: ISelectMessagesToShareScreenProps) => {
  const classes = useSelectMessagesToShareStyles()
  const currentUser = useUser()

  const [selectedMessagesIds, setSelectedMessagesIds] = React.useState(initialSelectedMessagesIds)

  const { messageSelectedForEntireContentView, onClickToViewEntireMessage, handleBackNavigation } =
    useViewMessageEntireContent({ returnToPreviousScreenAction: onReturnToPreviousScreenClick })

  if (!currentUser) return null

  const symmetricDifferenceOfSelectedMessagesIds = xor(initialSelectedMessagesIds, selectedMessagesIds)
  const hasUpdatesToSelectedMessagesIds = symmetricDifferenceOfSelectedMessagesIds.length > 0

  const onSubmitSelectedMessages = () => {
    if (hasUpdatesToSelectedMessagesIds) onConfirmSelectedMessages(selectedMessagesIds)
    handleBackNavigation?.()
  }

  return (
    <>
      <SharingGroupScreenHeader
        text="Select messages to share"
        onReturnToPreviousScreenClick={handleBackNavigation}
        className={classes.horizontalPadding}
        actionElement={
          <SubmitButtonWithLoading
            className={classes.confirmSelection}
            color="primary"
            variant="contained"
            disabled={!hasUpdatesToSelectedMessagesIds}
            onClick={onSubmitSelectedMessages}
          >
            Confirm selection
          </SubmitButtonWithLoading>
        }
      />
      <div className={classes.selectMessagesToShareContentContainer}>
        <SelectShareableMessages
          thread={thread}
          currentUser={currentUser}
          selectedMessagesIds={selectedMessagesIds}
          setSelectedMessagesIds={setSelectedMessagesIds}
          onViewEntireMessage={onClickToViewEntireMessage}
          messageSelectedForEntireContentView={messageSelectedForEntireContentView}
        />
      </div>
    </>
  )
}
