import sanitizeHtml from 'sanitize-html'

export type TDraftSyncState = 'notSynced' | 'syncing' | 'synced'

export const getMessageContentAsPreview = (repliedOrForwardedMessage?: IMessage) => {
  if (!repliedOrForwardedMessage) return ''

  const escapeNewLinesAndAddBlankSpaceToDivAndBrTags = (text: string, tagName: string) => {
    const newLinesEscapedText = text.split('\n').join(' ')
    const isDivOrBrTag = ['div', 'br'].includes(tagName)
    if (!isDivOrBrTag) return newLinesEscapedText
    return ` ${newLinesEscapedText}`
  }

  const messageContentPreview = sanitizeHtml(repliedOrForwardedMessage.content, {
    textFilter: escapeNewLinesAndAddBlankSpaceToDivAndBrTags,
    allowedTags: [],
  })
  return messageContentPreview
}

export const getMessageAudience = (message: IMessage) => {
  return [message.sender, ...(message.recipients ?? []), ...(message.bccRecipients ?? [])]
}

export const getMessageRecipientsEmails = (recipients?: TPerson[]) => {
  if (!recipients) return []
  return recipients.map(recipient => recipient.email)
}

export const getMessageAudienceEmails = (message: IMessage) => {
  return [
    message.sender.email,
    ...getMessageRecipientsEmails(message.recipients),
    ...getMessageRecipientsEmails(message.bccRecipients),
  ]
}

export const getDraftSyncState = (draft: IDraftMessage): TDraftSyncState => {
  if (draft.isSyncedWithProvider) return 'synced'
  if (draft.isSyncingWithProvider) return 'syncing'
  return 'notSynced'
}

export const getDraftFullContent = ({
  content,
  previousMessageContent,
  isPreviousMessageContentEdited,
}: Pick<IDraftMessage, 'content' | 'previousMessageContent' | 'isPreviousMessageContentEdited'>) => {
  return isPreviousMessageContentEdited ? `${content}${previousMessageContent}` : content
}
