import { getNotification } from './../../../notifications'
import { INotificationOld } from './../../../../model/notification'
import { NotificationsCallback } from './../useNotificationHandler'
import { loadThread } from 'api/threads'

type TNotificationType =
  | 'mention'
  | 'added_as_follower'
  | 'removed_as_follower'
  | 'message_on_hold'
  | 'approve_a_follower'

export default class NotificationHandler {
  callbacks = new Map<string, NotificationsCallback>()

  private getTimeout(type: TNotificationType): number {
    switch (type) {
      case 'approve_a_follower':
      case 'mention':
        return 10000
      default:
        return 3000
    }
  }

  private getNotificationAction(type: TNotificationType): string {
    switch (type) {
      case 'mention': {
        return 'mentioned you in'
      }
      case 'added_as_follower': {
        return 'added you to'
      }
      case 'removed_as_follower': {
        return 'removed you from'
      }
      case 'message_on_hold': {
        return 'added a held message in'
      }
      case 'approve_a_follower': {
        return 'request to be added as a follower to'
      }

      default: {
        return 'made an action'
      }
    }
  }

  private createNotificationBody(type: TNotificationType, notification: INotificationOld): string {
    const authorName = notification.contentData?.author.name || ''
    const threadTitle = notification.contentData?.thread.title || ''
    return `${authorName} ${this.getNotificationAction(type)} ${threadTitle}`
  }

  private createBrowserNotification = async (
    notificationId: string,
    type: TNotificationType,
    onNotificationClicked: (thread: IThread) => void
  ) => {
    const notificationObj = await getNotification(notificationId)
    const thread = await loadThread(notificationObj.contentData?.thread.id)

    if (!thread) return

    const notification = new Notification(this.createNotificationBody(type, notificationObj), {
      icon: notificationObj.contentData?.author.avatar_url || undefined,
    })

    notification.onclick = () => {
      onNotificationClicked(thread)
    }

    setTimeout(() => {
      notification.close()
    }, this.getTimeout(type))
  }

  public addCallback = (key: string, callback: NotificationsCallback) => {
    this.callbacks.set(key, callback)
  }

  public removeCallback = (key: string) => {
    this.callbacks.delete(key)
  }

  public handle = (
    currentUser: TUser,
    notificationId: string,
    unreadQuantity: number,
    type: TNotificationType,
    onNotificationClicked: (thread: IThread) => void
  ) => {
    if (!currentUser?.notificationsEnabled) return
    this.createBrowserNotification(notificationId, type, onNotificationClicked)
    this.callbacks.forEach(callback => {
      callback(unreadQuantity)
    })
  }
}
