import { apiClient } from '@jetkit/react'
import { IMessageReadInfo } from 'model/messageActivity'
import { IEditMessage, INewDraftMessage, IUpdateDraftMessage } from 'model/message'

export const createDraftMessage = async ({
  threadId,
  draftType,
  repliedOrForwardedMessageId,
  draftId,
}: INewDraftMessage): Promise<IAPIDraft> => {
  const result = await apiClient.post(`/v2/message/draft`, {
    thread_id: threadId,
    draft_type: draftType,
    in_reply_to_message_id: repliedOrForwardedMessageId,
    draft_id: draftId,
  })
  return result.data
}

export const updateDraftMessage = async ({
  draftId,
  content,
  standardAttachments,
  inlineAttachments,
  toRecipients,
  bccRecipients,
  isPreviousMessageContentEdited,
  mentions,
  scheduleSyncingWithProvider,
  draftVersion,
}: IUpdateDraftMessage): Promise<Pick<IDraftMessage, 'draftVersion' | 'isSyncedWithProvider'>> => {
  const result = await apiClient.patch(`/v2/message/draft/${draftId}`, {
    content,
    attachments: standardAttachments,
    inline_attachment_extids: inlineAttachments,
    recipients: toRecipients,
    bcc_recipients: bccRecipients,
    is_previous_message_content_edited: isPreviousMessageContentEdited,
    mentioned_users: mentions,
    schedule_syncing_with_provider: scheduleSyncingWithProvider,
    draft_version: draftVersion,
  })
  return result.data
}

export const deleteDraftMessage = async ({ draftId }: { draftId: string }): Promise<void> => {
  const result = await apiClient.delete(`/v2/message/draft/${draftId}`)
  return result.data
}

export const sendDraftMessage = async ({
  draftId,
  content,
  standardAttachments,
  inlineAttachments,
  toRecipients,
  bccRecipients,
  isPreviousMessageContentEdited,
  mentions,
}: Omit<IUpdateDraftMessage, 'draftVersion'>): Promise<IAPIMessage> => {
  const result = await apiClient.post(`/v2/message/draft/${draftId}/send`, {
    content,
    attachments: standardAttachments,
    inline_attachment_extids: inlineAttachments,
    recipients: toRecipients,
    bcc_recipients: bccRecipients,
    is_previous_message_content_edited: isPreviousMessageContentEdited,
    mentioned_users: mentions,
  })
  return result.data
}

export const editMessage = async ({
  messageId,
  content,
  attachments,
  mentions,
  inline_attachments,
}: IEditMessage): Promise<IAPIMessage> => {
  const result = await apiClient.patch(`/message/${messageId}`, {
    content: content,
    attachments_q: attachments,
    mentioned_users: mentions,
    inline_attachment_content_ids: inline_attachments,
  })

  return result.data
}

export const getMessageViewsHistory = async (messageId: string): Promise<IMessageReadInfo[]> => {
  const result = await apiClient.get(`/message/${messageId}/views`)
  return result.data
}

export const getMessage = async (messageId: string): Promise<IAPIMessage> => {
  const result = await apiClient.get(`/v2/message/${messageId}`)

  return result.data
}

export const releaseHeldMessage = async (messageId: string): Promise<IMessage> => {
  const result = await apiClient.patch(`/message/${messageId}/release`)
  return result.data
}

export const deleteHeldMessage = async (messageId: string): Promise<void> => {
  const result = await apiClient.delete(`/message/held/${messageId}`)
  return result.data
}

export const deleteMessage = async (messageId: string): Promise<void> => {
  const result = await apiClient.delete(`/message/${messageId}`)
  return result.data
}

export const branchThread = async (messageId: string): Promise<IThread> => {
  const result = await apiClient.patch(`/message/${messageId}/branch`)

  return result.data
}

interface IBranchWarningMessage {
  threadId: string
  messageId: string
}

interface IBranchConversationInfo {
  branchWarningMessages: IBranchWarningMessage[]
  thread: IThread
}

export const mergeConversations = async (messageId: string): Promise<IBranchWarningMessage[]> => {
  const result = await apiClient.post(`/message/${messageId}/merge-conversations`)

  return result.data?.branchWarningMessages ?? []
}

export const branchConversation = async (messageId: string): Promise<IBranchConversationInfo> => {
  const result = await apiClient.post(`/message/${messageId}/branch-conversation`)

  return {
    branchWarningMessages: result.data?.branchWarningMessages ?? [],
    thread: result.data?.thread ?? null,
  }
}

export const discardConversationWarning = async (messageId: string): Promise<IBranchWarningMessage[]> => {
  const result = await apiClient.post(`/message/${messageId}/discard-branch-warning`)

  return result.data?.branchWarningMessages ?? []
}

export const silenceConversationBranchWarnings = async (messageId: string): Promise<IBranchWarningMessage[]> => {
  const result = await apiClient.post(`/message/${messageId}/silence-branch-warning`)

  return result.data?.branchWarningMessages ?? []
}

export const toggleMessageCollapsedState = async (messageId: string): Promise<void> => {
  const result = await apiClient.patch(`/v2/message/${messageId}/collapse`)
  return result.data
}

export const reimportMessage = async ({ messageId }: { messageId: string }): Promise<void> => {
  const result = await apiClient.patch(`/v2/message/${messageId}/reimport`)
  return result.data
}

export const getDraft = async (draftId: string): Promise<IDraftMessage> => {
  const result = await apiClient.get(`/v2/message/drafts/${draftId}`)
  return result.data
}
