import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase, fade, Theme, Typography } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'

const useMultiProjectsBadgeStyles = makeStyles((theme: Theme) => ({
  multiProjectsBadgeContainer: {
    alignItems: 'center',
    backgroundColor: theme.threadPalette.lighterGray,
    borderRadius: 4,
    color: theme.threadPalette.brownishGray,
    cursor: 'pointer',
    display: 'inline-flex',
    paddingLeft: 2,
    paddingRight: 6,
    '&:hover': {
      color: theme.threadPalette.white,
      backgroundColor: theme.palette.primary.main,
      boxShadow: `0 2px 4px 0 ${fade(theme.threadPalette.darkestBlue, 0.5)}`,
    },
  },
  folderIcon: {
    fontSize: 24,
  },
  projectsCount: {
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: 4,
  },
}))

interface IMultiProjectsBadgeProps {
  projectsCount: number
  onClick?(event: React.MouseEvent<HTMLDivElement>): void
}
const MultiProjectsBadge = React.forwardRef<HTMLDivElement, IMultiProjectsBadgeProps>(
  ({ projectsCount, onClick }, ref) => {
    const classes = useMultiProjectsBadgeStyles()
    const projectsDisplayTextPluralized = projectsCount > 1 ? 'Projects' : 'Project'

    return (
      <ButtonBase ref={ref} component="div" className={classes.multiProjectsBadgeContainer} onClick={onClick}>
        <FolderIcon className={classes.folderIcon} />
        <Typography className={classes.projectsCount}>
          {projectsCount} {projectsDisplayTextPluralized}
        </Typography>
      </ButtonBase>
    )
  }
)

export default MultiProjectsBadge
