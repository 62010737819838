import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, Typography } from '@material-ui/core'
import GoogleIcon from 'icons/googleIcon'
import OutlookIcon from 'icons/outlookIcon'
import classNames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  text: {
    fontSize: 15,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginLeft: 4,
    color: theme.threadPalette.lightGray,
  },

  icon: {
    width: 20,
    height: 20,
  },

  provider: {
    textTransform: 'capitalize',
  },
}))

interface IBadgeIconProps {
  provider: string
  className?: string
}

const BadgeIcon = ({ provider, className }: IBadgeIconProps) => {
  switch (provider) {
    case 'gmail': {
      return <GoogleIcon className={className} />
    }

    case 'outlook': {
      return <OutlookIcon className={className} />
    }

    default:
      return null
  }
}

interface IEmailProviderBadgeProps {
  provider: string
  className?: string
}

const EmailProviderBadge = ({ provider, className }: IEmailProviderBadgeProps) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.root, className)}>
      <BadgeIcon provider={provider} className={classes.icon} />
      <Typography className={classes.text}>
        Connected via <span className={classes.provider}>{provider}</span>
      </Typography>
    </div>
  )
}

export default EmailProviderBadge
