import { useQueryClient } from '@tanstack/react-query'
import { getSharingSettingsForThread } from 'api/v2/thread'
import { useUser } from 'hooks/auth'
import { IThreadSharingSettings } from 'model/sharingGroup'
import React from 'react'

interface IThreadSettingsActionsProps {
  threadId: string
  sharingGroupId: string
  threadSharingSettingsModifier: (threadSharingSettings: IThreadSharingSettings) => IThreadSharingSettings
}

export const getThreadSharingSettingsQueryKey = ({ userId, threadId }: { userId: string; threadId: string }) => [
  userId,
  'threadSharingSettings',
  threadId,
]

export const useThreadSharingSettingsCacheActions = () => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const refreshData = React.useCallback(
    (threadId: string) => {
      if (!currentUser?.id) return

      const queryKey = getThreadSharingSettingsQueryKey({ userId: currentUser.id, threadId })
      queryClient.invalidateQueries({ queryKey })
    },
    [queryClient, currentUser?.id]
  )

  const getData = React.useCallback(
    (threadId: string) => {
      if (!currentUser?.id) return

      const queryKey = getThreadSharingSettingsQueryKey({ userId: currentUser.id, threadId })
      return queryClient.getQueryData<IThreadSharingSettings[]>(queryKey)
    },
    [queryClient, currentUser?.id]
  )

  const setData = React.useCallback(
    (threadId: string, threadSharingSettings: IThreadSharingSettings[]) => {
      if (!currentUser?.id) return

      const queryKey = getThreadSharingSettingsQueryKey({ userId: currentUser.id, threadId })
      queryClient.setQueryData(queryKey, threadSharingSettings)
    },
    [queryClient, currentUser?.id]
  )

  const updateData = React.useCallback(
    ({ threadId, sharingGroupId, threadSharingSettingsModifier }: IThreadSettingsActionsProps) => {
      const threadSharingSettingsList = getData(threadId)

      if (!threadSharingSettingsList) return

      const threadSharingSettingsListUpdated = threadSharingSettingsList.map(threadSharingSettings => {
        if (threadSharingSettings.sharingGroup.id !== sharingGroupId) return threadSharingSettings
        return threadSharingSettingsModifier(threadSharingSettings)
      })
      setData(threadId, threadSharingSettingsListUpdated)

      const rollbackUpdate = () => setData(threadId, threadSharingSettingsList)
      return rollbackUpdate
    },
    [getData, setData]
  )

  const deleteData = React.useCallback(
    ({ sharingGroupId, threadId }: Pick<IThreadSettingsActionsProps, 'sharingGroupId' | 'threadId'>) => {
      const threadSharingSettingsList = getData(threadId)

      if (!threadSharingSettingsList) return

      const threadSharingSettingsListUpdated = threadSharingSettingsList.filter(
        threadSharingSettings => threadSharingSettings.sharingGroup.id !== sharingGroupId
      )
      setData(threadId, threadSharingSettingsListUpdated)

      const rollbackUpdate = () => setData(threadId, threadSharingSettingsList)
      return rollbackUpdate
    },
    [getData, setData]
  )

  const prefetchData = React.useCallback(
    (threadId: string) => {
      if (!currentUser?.id) return

      const threadSharingGroups = getData(threadId)

      if (threadSharingGroups) return

      queryClient.prefetchQuery({
        queryKey: getThreadSharingSettingsQueryKey({ userId: currentUser.id, threadId }),
        queryFn: () => getSharingSettingsForThread(threadId),
      })
    },
    [queryClient, currentUser?.id]
  )

  return {
    refreshData,
    getData,
    setData,
    updateData,
    prefetchData,
    deleteData,
  }
}
