import React from 'react'

interface IUseViewMessageEntireContent {
  returnToPreviousScreenAction?: () => void
}

export const useViewMessageEntireContent = ({ returnToPreviousScreenAction }: IUseViewMessageEntireContent) => {
  const [messageSelectedForEntireContentView, setMessageSelectedForEntireContentView] = React.useState<IMessage | null>(
    null
  )

  const onClickToViewEntireMessage = (message: IMessage) => setMessageSelectedForEntireContentView(message)

  const handleBackNavigation = () =>
    messageSelectedForEntireContentView
      ? setMessageSelectedForEntireContentView(null)
      : returnToPreviousScreenAction?.()

  return {
    onClickToViewEntireMessage,
    isMessageEntireContentOpen: !!messageSelectedForEntireContentView,
    handleBackNavigation,
    messageSelectedForEntireContentView,
  }
}
