import { ServerError } from '../../api/errors/serverError'
import { UseEffectAsyncParams } from '../../hooks/useEffectAsync'
type ErrorHandlingWrapperParams<T> = UseEffectAsyncParams<T>

interface IErrorHandlingWrapperResult<T> {
  data?: T
  error?: string
  rawError?: ServerError
  responseError?: TResponseError
}

enum EResponseError {
  forbidden = 403,
  notFound = 404,
  conflict = 409,
}

export type TResponseError = keyof typeof EResponseError

export async function errorHandlingWrapper<T>(
  func: () => Promise<T>,
  params?: ErrorHandlingWrapperParams<T>
): Promise<IErrorHandlingWrapperResult<T>> {
  const { initialData, defaultErrorMessage } = params ?? {}
  try {
    const data = (await func()) ?? initialData
    return { data }
  } catch (error) {
    const serverError = error as ServerError
    const serverErrorMessage = serverError?.response?.data?.message
    const errorMessage = serverErrorMessage ?? defaultErrorMessage

    let responseError: TResponseError | undefined
    if (serverError?.response?.data?.code) {
      responseError = EResponseError[serverError?.response?.data?.code] as TResponseError
    }

    return { error: errorMessage, rawError: serverError, responseError: responseError }
  }
}
