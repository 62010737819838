import React from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import AppLoading from 'component/appLoading'
import { Typography } from '@material-ui/core'
import classNames from 'classnames'

const useLabelWithLoadingIndicatorStyles = makeStyles((theme: Theme) => ({
  labelWithLoadingIndicatorRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    height: 30,
    width: 'fit-content',
  },
  text: {
    fontSize: 14,
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    height: 16,
    width: 16,
  },
}))

interface ILabelWithLoadingIndicatorProps {
  text: string
  className?: string
  spinnerColor?: string
}

export const LabelWithLoadingIndicator = ({ text, className, spinnerColor }: ILabelWithLoadingIndicatorProps) => {
  const classes = useLabelWithLoadingIndicatorStyles()

  const rootClassName = classNames(classes.labelWithLoadingIndicatorRoot, className)

  return (
    <div className={rootClassName}>
      <div className={classes.spinner}>
        <AppLoading visible small padding={4} legWidth={6} legHeight={14} fill={spinnerColor} />
      </div>
      <Typography className={classes.text}>{text}</Typography>
    </div>
  )
}
