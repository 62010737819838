import { IAttachmentContentDetails } from './../model/attachment'
import { IPagedRequestParams } from './model/pagedRequestParams'
import { IProjectAttachments } from 'model/project'
import IAttachment from '../model/attachment'
import { apiClient } from '@jetkit/react'
import { PaginatedResponse } from './pagedResponse'
import { PagedResponseDataProcessor } from 'dataProcessors/pagedResponse'

interface GetThreadFilesParams extends IPagedRequestParams {
  threadId: string
}

export const getThreadFiles = async ({
  threadId,
  page,
  pageSize,
}: GetThreadFilesParams): Promise<PaginatedResponse<IAttachment[]>> => {
  const response = await apiClient.get(`/thread/${threadId}/attachments`, {
    params: { page, page_size: pageSize || 10 },
  })

  return PagedResponseDataProcessor(response)
}

export const getS3Link = async (url: string): Promise<string> => {
  const response = await apiClient.get(url)
  return response.data
}

export const getAttachment = async (attachmentId: string): Promise<IAttachment> => {
  const result = await apiClient.get(`/v2/attachment/${attachmentId}`)
  return result.data
}

interface GetProjectAttachmentsParams extends IPagedRequestParams {
  projectId: string
}

export const getProjectAttachments = async ({
  projectId,
  page,
  pageSize,
}: GetProjectAttachmentsParams): Promise<PaginatedResponse<IProjectAttachments[]>> => {
  const response = await apiClient.get(`/thread/project/${projectId}/preview`, {
    params: { page, page_size: pageSize },
  })

  return PagedResponseDataProcessor(response)
}

export const getAttachmentContentDetails = async (attachmentId: string): Promise<IAttachmentContentDetails> => {
  const response = await apiClient.get(`/v2/attachment/${attachmentId}/content-details`)

  return response.data
}

export const updateAttachmentUploadStatus = async (attachmentId: string): Promise<void> => {
  await apiClient.post(`/v2/attachment/${attachmentId}/upload-status`)
}

export const getAttachmentDownloadUrl = async (attachmentId: string): Promise<string> => {
  const response = await apiClient.get(`/v2/attachment/${attachmentId}/download-url`)
  return response.data
}
