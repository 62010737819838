import { IThreadSharingSettings } from 'model/sharingGroup'
import { getMessageAudienceEmails } from './message'

export const isMessageInThreadSharingSettings = (
  message: IMessage,
  { sharedMessagesIds }: Pick<IThreadSharingSettings, 'sharedMessagesIds'>
) => {
  const isMessagePartOfTheSharedMessagesList = sharedMessagesIds.includes(message.id)

  return isMessagePartOfTheSharedMessagesList
}

export const isMessageHeldInThreadSharingSettings = ({
  message,
  threadSharingSettings,
  user,
}: {
  message: IMessage
  threadSharingSettings: IThreadSharingSettings
  user: IUser
}) => {
  const sharingGroup = threadSharingSettings.sharingGroup
  if (sharingGroup.owner.id !== user.id) return false

  return threadSharingSettings.heldMessagesIds.includes(message.id)
}

export const getThreadSharingSettingsThatShareMessage = (
  message: IMessage,
  threadSharingSettingsList: IThreadSharingSettings[]
) =>
  threadSharingSettingsList.filter(threadSharingSettings =>
    isMessageInThreadSharingSettings(message, threadSharingSettings)
  )

export const getThreadSharingSettingsThatHoldMessage = ({
  message,
  threadSharingSettingsList,
  user,
}: {
  message: IMessage
  threadSharingSettingsList: IThreadSharingSettings[]
  user: IUser
}) =>
  threadSharingSettingsList.filter(threadSharingSettings =>
    isMessageHeldInThreadSharingSettings({ message, threadSharingSettings, user })
  )

export const getHeldMessageCountForThreadSharingSettings = ({
  threadSharingSettingsList,
  user,
}: {
  threadSharingSettingsList: IThreadSharingSettings[]
  user: IUser
}) => {
  const threadSharingSettingsTheUserIsGroupOwner = getThreadSharingSettingsTheUserIsSharingGroupOwner(
    user.id,
    threadSharingSettingsList
  )

  const heldMessagesIds = threadSharingSettingsTheUserIsGroupOwner.reduce(
    (heldMessagesIds, threadSharingSettings) => heldMessagesIds.concat(threadSharingSettings.heldMessagesIds),
    [] as string[]
  )

  const uniqueHeldMessagesIds = new Set(heldMessagesIds)

  return uniqueHeldMessagesIds.size
}

export const getThreadSharingSettingsTheUserIsSharingGroupOwner = (
  userId: string,
  threadSharingSettingsList: IThreadSharingSettings[]
) => threadSharingSettingsList.filter(threadSharingSettings => threadSharingSettings.sharingGroup.owner.id === userId)

export const getThreadSharingSettingsForUserIsSharingGroupMember = (
  userId: string,
  threadSharingSettingsList: IThreadSharingSettings[]
) =>
  threadSharingSettingsList.filter((threadSharingSettings: IThreadSharingSettings) =>
    threadSharingSettings.sharingGroup.members.some(member => member.id === userId)
  )

export const isThreadSharingSettingsShareAllFutureMessages = ({
  autoShareConditions,
}: Pick<IThreadSharingSettings, 'autoShareConditions'>) => autoShareConditions?.length === 0

export const getThreadSharingSettingsAutoShareConditionsTypes = ({
  autoShareConditions,
}: Pick<IThreadSharingSettings, 'autoShareConditions'>) => {
  if (!autoShareConditions) return []
  return autoShareConditions.map(autoShareCondition => autoShareCondition.type)
}

export const canMessageBeSharedByUser = ({ user, message }: { user: IUser; message: IMessage }) => {
  const messageAudienceEmails = getMessageAudienceEmails(message)
  const canShare = messageAudienceEmails.includes(user.email)
  return canShare
}
