import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { createSharingGroup } from 'api/v2/sharingGroup'
import { ISharingGroup } from 'model/sharingGroup'
import { useUserSharingGroupsCacheActions } from 'hooks/useSharingGroup/useUserSharingGroupsCacheActions'

interface IUseCreateSharingGroupUseCaseProps {
  onSuccess?: (sharingGroup: ISharingGroup) => void
}

export const useCreateSharingGroupUseCase = ({ onSuccess }: IUseCreateSharingGroupUseCaseProps = {}) => {
  const dispatch = useDispatch()

  const userSharingGroupsCacheActions = useUserSharingGroupsCacheActions()

  const {
    reset: resetCreateSharingGroupMutation,
    mutateAsync: runCreateSharingGroupMutation,
    isLoading,
  } = useMutation(createSharingGroup, {
    onSuccess: sharingGroup => {
      userSharingGroupsCacheActions.refreshData({ withImmediateFetching: true })
      onSuccess?.(sharingGroup)
    },
    onError: () => dispatch(showSnackbar({ message: 'Failed to create new sharing group' })),
    onSettled: () => {
      resetCreateSharingGroupMutation()
    },
  })

  const createSharingGroupUseCase: typeof createSharingGroup = runCreateSharingGroupMutation

  return {
    createSharingGroupUseCase,
    isLoading,
  }
}
