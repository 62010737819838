import Spacer from 'component/layoutUtils/spacer'
import { useNewProjectModalContext } from 'component/newProjectModal/newProjectModalContext'
import { useProjectsList } from 'hooks/useProjects/useProjectsList'
import * as React from 'react'
import { ProjectsList } from './projectsList'
import { ProjectsViewHeader } from './projectsViewHeader'

export const ProjectsView = () => {
  const { projectsList, handleScrollEvent, isInitialLoading, isLoadingNextPage } = useProjectsList({
    pageSize: 20,
    onlyActive: true,
  })

  const { openModal: openNewProjectModal } = useNewProjectModalContext()

  return (
    <>
      <ProjectsViewHeader onNewProjectClick={openNewProjectModal} />
      <Spacer height={4} />
      <ProjectsList
        projectsList={projectsList}
        isInitialLoading={isInitialLoading}
        handleScrollEvent={handleScrollEvent}
        isLoadingNextPage={isLoadingNextPage}
      />
    </>
  )
}
