import Axios, { AxiosResponse } from 'axios'
import { checkIntegrity } from 'dataProcessors/utils'

export const OAuthLoginAPICallCreator = (endpointPath: string): TOAuthLoginAPICall => {
  return async ({ redirectURL }: { redirectURL?: string }): Promise<IGoogleOAuthLoginResponse> => {
    const anonymousApiClient = Axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      params: { redirect_url: redirectURL },
    })

    const result = await anonymousApiClient.get(endpointPath)
    return result.data
  }
}

export const OAuthHandleCallbackAPICallCreator = (endpointPath: string): TOAuthHandleCallbackAPICall => {
  return async (props: IAuthCallbackProps): Promise<ITLAuthResponse> => {
    const { state, code, error, scope } = props

    const anonymousApiClient = Axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    })

    // this check will throw error if it doesn't pass
    checkIntegrity(state)

    const result = await anonymousApiClient.post<IAuthCallbackProps, AxiosResponse<ITLAuthResponse>>(endpointPath, {
      code,
      state,
      error,
      scope,
    })

    return result.data
  }
}
