import React from 'react'
import { Theme, makeStyles, Typography, TextField, Slider, Select, InputLabel } from '@material-ui/core'

import EditIcon from '@material-ui/icons/Create'
import SubmitButtonWithLoading from 'component/submitButtonWithLoading'
import { generateEmailContent } from 'api/v2/llm'
import CopyableTypography from 'shared/components/copyableTypography'

const useGenerateEmailContentPageStyles = makeStyles((theme: Theme) => ({
  generateEmailContentPageRoot: {
    backgroundColor: theme.threadPalette.whiteSmoke,
    color: theme.threadPalette.brownishGray,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 16,
    maxWidth: '100%',
    minWidth: 0,
    overflowY: 'scroll',
    padding: 16,
    [theme.breakpoints.up('lg')]: {
      paddingInline: 50,
    },
  },
  generateContentGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 20,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 20,
  },
  generatedContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  sliderTrack: {
    height: 8,
    borderRadius: 4,
  },
  sliderThumb: {
    height: 20,
    width: 20,
    backgroundColor: theme.threadPalette.whiteSmoke,
    border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  sliderMark: {
    backgroundColor: theme.threadPalette.white,
    height: 9,
    width: 2,
    marginTop: -2,
  },
  sliderLabel: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  limitBySelect: {
    alignItems: 'center',
    display: 'flex',
    gap: 16,
  },
  limitInput: {
    alignItems: 'center',
    width: 50,
  },
  submitButton: {
    marginTop: 16,
    minHeight: 40,
  },
}))

const GenerateEmailContentPage = () => {
  const classes = useGenerateEmailContentPageStyles()
  const [goalWithEmail, setGoalWithEmail] = React.useState('')
  const [contextAboutReceiver, setContextAboutReceiver] = React.useState('')
  const [formalityValue, setFormalityValue] = React.useState(2)
  const [limitBy, setLimitBy] = React.useState<LimitContentBy>('words')
  const [limit, setLimit] = React.useState(100)
  const [isGeneratingContent, setIsGeneratingContent] = React.useState(false)
  const [generatedContent, setGeneratedContent] = React.useState<string | undefined>()

  const getFormalityBasedOnSliderValue = (value: number) => {
    const formalityOptions: LLMFormality[] = [
      'informal',
      'somewhat informal',
      'neither formal nor informal',
      'somewhat formal',
      'formal',
    ]

    return formalityOptions[value] ?? formalityOptions[2]
  }

  const onChangeLimitBy = (newValue: LimitContentBy) => {
    setLimitBy(newValue)
    const defaultLimitPerType: Record<LimitContentBy, number> = {
      words: 100,
      paragraphs: 3,
    }
    const defaultLimit = defaultLimitPerType[newValue]
    setLimit(defaultLimit)
  }

  const onGenerateContentClick = async () => {
    setIsGeneratingContent(true)
    const generatedContent = await generateEmailContent({
      goalWithEmail,
      contextAboutReceiver,
      formality: getFormalityBasedOnSliderValue(formalityValue),
      limitBy,
      limit,
    })
    setIsGeneratingContent(false)
    setGeneratedContent(generatedContent)
  }

  return (
    <div className={classes.generateEmailContentPageRoot}>
      <Typography variant="h6">Generate Email</Typography>

      <div className={classes.generateContentGrid}>
        <div className={classes.formContainer}>
          <TextField
            multiline
            value={goalWithEmail}
            onChange={event => setGoalWithEmail(event.target.value)}
            label="Goal with email"
            helperText="What you want to accomplish as a result of this email"
            variant="outlined"
          />
          <TextField
            multiline
            value={contextAboutReceiver}
            onChange={event => setContextAboutReceiver(event.target.value)}
            label="Context about receiver"
            helperText="Company, what it does, what it can gain from what you offer, etc."
            variant="outlined"
          />
          <div>
            <Slider
              value={formalityValue}
              aria-labelledby="email-formality-slider"
              onChange={(_, newValue) => setFormalityValue(newValue as number)}
              getAriaValueText={getFormalityBasedOnSliderValue}
              valueLabelDisplay="off"
              min={0}
              max={4}
              marks
              scale={x => x ** 10}
              classes={{
                track: classes.sliderTrack,
                rail: classes.sliderTrack,
                thumb: classes.sliderThumb,
                mark: classes.sliderMark,
              }}
            />
            <Typography className={classes.sliderLabel}>{getFormalityBasedOnSliderValue(formalityValue)}</Typography>
          </div>
          <InputLabel className={classes.limitBySelect}>
            Limit content to around
            <TextField
              className={classes.limitInput}
              type="number"
              size="small"
              value={limit}
              onChange={event => setLimit(parseInt(event.target.value))}
            />
            <Select
              native
              value={limitBy}
              variant="outlined"
              onChange={event => onChangeLimitBy(event.target.value as LimitContentBy)}
            >
              <option value={'words'}>Words</option>
              <option value={'paragraphs'}>Paragraphs</option>
            </Select>
          </InputLabel>
          <SubmitButtonWithLoading
            className={classes.submitButton}
            color="primary"
            variant="contained"
            disabled={isGeneratingContent}
            isLoading={isGeneratingContent}
            startIcon={<EditIcon />}
            onClick={onGenerateContentClick}
          >
            Generate
          </SubmitButtonWithLoading>
        </div>
        <div className={classes.generatedContentContainer}>
          <CopyableTypography>{generatedContent}</CopyableTypography>
        </div>
      </div>
    </div>
  )
}

export default GenerateEmailContentPage
