import * as React from 'react'
import { IOrganizationMember } from 'model/organization'
import { useStyles } from './orgMembers.style'
import UserAvatar from 'component/userAvatar'
import { Divider, Typography } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ThreadMenu from 'component/menu'
import { useMenu } from 'hooks/useMenu'
import { useUser } from 'hooks'
import { formatDateString } from 'dataProcessors/dateTime'

const ADMIN_MENU_ITEMS: IOption<string>[] = [
  {
    value: 'make_admin',
    label: 'Make an admin',
  },
  {
    value: 'remove',
    label: 'Remove from org',
  },
]

const ADMIN_ON_ADMIN_MENU_ITEMS: IOption<string>[] = [
  {
    value: 'make_admin',
    label: 'Remove admin',
  },
  {
    value: 'remove',
    label: 'Remove from org',
  },
]

const PENDING_ACCEPT_MENU_ITEMS: IOption<string>[] = [
  {
    value: 'approve',
    label: 'Approve & send invitation',
  },
  {
    value: 'cancel',
    label: 'Cancel invitation',
  },
]

const YOUR_MENU_ITEMS: IOption<string>[] = [
  {
    value: 'leave',
    label: 'Leave org',
  },
]

const MENU_ITEMS: IOption<string>[] = [
  {
    value: 'open',
    label: 'Open',
  },
]

const PENDING_CONFIRMATION__MENU_ITEMS: IOption<string>[] = [
  {
    value: 'approve',
    label: 'Resend invitation',
  },
  {
    value: 'cancel',
    label: 'Cancel invitation',
  },
]

interface IOrgMemberListItemProps {
  member: IOrganizationMember
  organizationName?: string
  ownerIds: string[]
  onMenuItemSelect(memberId: string, item: string): void
}

const OrgMemberListItem = ({ member, ownerIds, onMenuItemSelect, organizationName }: IOrgMemberListItemProps) => {
  const classes = useStyles()
  const user = useUser()

  const { menuAnchorEl, onMenuIconClick, onMenuClose, dismissMenu } = useMenu()
  const name = user?.id === member.user.id ? 'You' : member.user.name
  const isMemberAdmin = ownerIds.includes(member.user.id)
  const isCurrentUserAdmin = user?.id && ownerIds.includes(user.id)

  const getMenuItems = React.useCallback(() => {
    if (member.user.id === user?.id) {
      return YOUR_MENU_ITEMS
    }

    if (isCurrentUserAdmin && member.approved && !member.confirmed) {
      return PENDING_CONFIRMATION__MENU_ITEMS
    }

    if (isCurrentUserAdmin && ownerIds.includes(member.user.id)) {
      return ADMIN_ON_ADMIN_MENU_ITEMS
    }

    if (isCurrentUserAdmin && member.approved) {
      return ADMIN_MENU_ITEMS
    }

    if (isCurrentUserAdmin && !member.approved) {
      return PENDING_ACCEPT_MENU_ITEMS
    }

    return MENU_ITEMS
  }, [isCurrentUserAdmin, member.approved, member.confirmed, member.user.id, ownerIds, user])

  const handleMenuSelect = React.useCallback(
    (value: any) => {
      dismissMenu()
      onMenuItemSelect(member.id, value)
    },
    [dismissMenu, member.id, onMenuItemSelect]
  )

  return (
    <div className={classes.listItemRoot}>
      <Divider className={classes.divider} />
      <div className={classes.listItemContent}>
        <UserAvatar user={member.user} />
        <div className={classes.listItemDetails}>
          <div className={classes.memberNameRow}>
            <Typography className={classes.listItemName}>{name}</Typography>&nbsp;
            {isMemberAdmin && <span className={classes.listItemAdmin}>(Admin)</span>}
          </div>
          <Typography className={classes.listItemOrgName}>{organizationName}</Typography>
        </div>
        {!member.approved &&
          (isCurrentUserAdmin && !member.confirmed ? (
            <Typography className={classes.inviteTimestamp}>
              Invited on {formatDateString(member.invitationDate, 'MM/dd/yy')}
            </Typography>
          ) : (
            <Typography className={classes.pendingInvite}>Invitation pending admin approval</Typography>
          ))}
        {member.approved && !member.confirmed && (
          <Typography className={classes.inviteTimestamp}>Invitation sent</Typography>
        )}
        <MoreHorizIcon className={classes.moreIcon} onClick={onMenuIconClick} />
        <ThreadMenu
          anchorEl={menuAnchorEl}
          items={getMenuItems()}
          open={menuAnchorEl !== null}
          onClose={onMenuClose}
          onSelect={handleMenuSelect}
          customClasses={{
            item: classes.menuItem,
          }}
        />
      </div>
    </div>
  )
}

export default OrgMemberListItem
