import React from 'react'
import { IOnboardingTourStep } from 'contexts/useOnboardingTourContext'
import {
  OnboardingTourTargetComponent,
  createTargetedOnboardingStep,
  createUntargetedOnboardingStep,
} from 'domain/onboardingTourStep'
import { ThreadContentAsRecipientStep } from 'component/onboarding/threadOnboarding/threadContentAsRecipientStep'
import { ThreadAliasStep } from 'component/onboarding/threadOnboarding/threadAliasStep'
import { FollowingBadgeStep } from 'component/onboarding/threadOnboarding/followingBadgeStep'
import { AddToProjectStep } from 'component/onboarding/threadOnboarding/addToProjectStep'
import { ProjectsDashboardStep } from 'component/onboarding/threadOnboarding/projectsDashboardStep'
import { ShareThreadStep } from 'component/onboarding/threadOnboarding/shareThreadStep'
import { CopyThreadLinkStep } from 'component/onboarding/threadOnboarding/copyThreadLinkStep'
import { useFullScreen } from 'hooks/useFullScreen'

export const useThreadOnboardingSteps = () => {
  const { isFullScreen } = useFullScreen('md')

  const createThreadContentStep = ({ isFullScreen }: { isFullScreen: boolean }) => {
    const commonStepProps = {
      content: <ThreadContentAsRecipientStep isFullScreen={isFullScreen} />,
      nextStepButtonLabel: 'See more',
    }

    if (isFullScreen) return createUntargetedOnboardingStep(commonStepProps)

    return createTargetedOnboardingStep({
      ...commonStepProps,
      target: OnboardingTourTargetComponent.threadPage,
      placement: 'left',
      spotlightPadding: -10,
    })
  }

  const getSteps = React.useCallback(
    (props = {}): IOnboardingTourStep[] => {
      return [
        createThreadContentStep({ isFullScreen }),
        createTargetedOnboardingStep({
          content: <ThreadAliasStep />,
          target: OnboardingTourTargetComponent.threadAlias,
          placement: 'bottom',
          showProgress: true,
        }),
        createTargetedOnboardingStep({
          content: <FollowingBadgeStep />,
          target: OnboardingTourTargetComponent.threadFollowBadge,
          placement: 'bottom',
          showProgress: true,
        }),
        createTargetedOnboardingStep({
          content: <AddToProjectStep />,
          target: OnboardingTourTargetComponent.addIntegration,
          placement: 'bottom',
          showProgress: true,
          spotlightPadding: 6,
        }),
        createTargetedOnboardingStep({
          content: <ProjectsDashboardStep />,
          target: OnboardingTourTargetComponent.projectsDashboardLeftSidebarMenu,
          placement: 'right',
          showProgress: true,
          spotlightPadding: -4,
        }),
        createTargetedOnboardingStep({
          content: <ShareThreadStep />,
          target: OnboardingTourTargetComponent.shareThreadButton,
          placement: 'bottom',
          showProgress: true,
          spotlightPadding: 4,
        }),
        createTargetedOnboardingStep({
          content: <CopyThreadLinkStep />,
          target: OnboardingTourTargetComponent.copyThreadLinkButton,
          placement: 'bottom',
          spotlightPadding: 4,
        }),
      ]
    },
    [isFullScreen]
  )

  return { getSteps }
}
