import { USER_ACTIONS } from './../constants/actions/user'
import { AnyAction } from 'redux'

const DEFAULT_STATE: TUsersState = {
  usersOnlineStatus: {},
  loading: false,
  loadError: null,
}

const updateUserStatus = (state: TUsersState, status: IOnlineStatus, userId: string): TUsersOnlineStatus => {
  return {
    ...state.usersOnlineStatus,
    [userId]: status,
  }
}

const updateUsersStatus = (state: TUsersState, users: IAPIUser[]): TUsersOnlineStatus => {
  let statuses: Record<number, IOnlineStatus> = {}
  users.forEach((user: IAPIUser) => {
    statuses = {
      ...statuses,
      [user.id]: {
        status: user.onlineStatus as TStatus,
        location: user.lastOpenedThreadId,
        timestamp: user.timestamp,
      },
    }
  })

  return {
    ...state.usersOnlineStatus,
    ...statuses,
  }
}

export const userReducer = (state = DEFAULT_STATE, action: AnyAction) => {
  switch (action.type) {
    case USER_ACTIONS.CHANGE_ONLINE_STATUS: {
      return {
        ...state,
        usersOnlineStatus: updateUserStatus(state, action.status, action.userId),
      }
    }

    case USER_ACTIONS.LOAD_USERS_START: {
      return {
        ...state,
        loading: true,
        loadError: null,
      }
    }

    case USER_ACTIONS.LOAD_USERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loadError: action.error,
      }
    }

    case USER_ACTIONS.LOAD_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadError: null,
        users: action.users,
        usersOnlineStatus: updateUsersStatus(state, action.users),
      }
    }

    default:
      return state
  }
}
