import React from 'react'
import { areArraysEqual, locateSubArrayStartIndex } from 'lib/array'
import { useThreadPageContext } from 'page/threadPageContextProvider'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'

export const useNewThreadMessagesListener = ({ callback }: { callback(): void }) => {
  const [oldThreadMessagesIds, setOldThreadMessagesIds] = React.useState<string[]>([])

  const { currentThread } = useCurrentThreadContext()
  const { messagesList } = useThreadPageContext()

  React.useEffect(() => {
    const newThreadMessagesIds = messagesList.map(message => message.id)

    if (areArraysEqual(oldThreadMessagesIds, newThreadMessagesIds)) return

    // Once we receive new thread messages, they are shifting existing messages in the list.
    // Using this as a sign that new thread messages appeared.
    const hasNewMessages = locateSubArrayStartIndex(newThreadMessagesIds, oldThreadMessagesIds) > 0
    if (hasNewMessages) callback()

    setOldThreadMessagesIds(newThreadMessagesIds)
  }, [messagesList.length, currentThread?.id, oldThreadMessagesIds, callback])
}
