import * as React from 'react'

interface IDownloadButtonIconProps {
  fill?: string
}

const DownloadButtonIcon = ({ fill }: IDownloadButtonIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={fill ? fill : '#33373A'}>
      <defs>
        <filter id="prefix__a" width="550%" height="550%" x="-225%" y="-200%" filterUnits="objectBoundingBox">
          <feOffset dy="10" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="12" />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fillRule="evenodd" filter="url(#prefix__a)" transform="translate(-8 -8)">
        <path d="M27 17h-4v-6h-6v6h-4l7 7 7-7zm-14 9v2h14v-2H13z" />
      </g>
    </svg>
  )
}

export default DownloadButtonIcon
