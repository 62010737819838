import { useLocation } from 'react-router'

const useCurrentUrl = (): { currentUrl: string; currentHost: string } => {
  const currentHost = process.env.PUBLIC_URL
  const { pathname } = useLocation()
  const currentUrl = `${currentHost}${pathname}`

  return {
    currentUrl,
    currentHost,
  }
}

export default useCurrentUrl
