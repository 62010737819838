import * as React from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useUser } from '../../../hooks/auth'
import OpenUserMenuOptionsIcon from '@material-ui/icons/MoreHoriz'
import { useSelector } from 'react-redux'
import { selectUsersOnlineStatus } from 'selectors/users'
import { ThreadSettingsMenuItem } from './threadFollowersList'
import { UserAccessCheckWrapper } from 'lib/accessCheck'
import UserDisplayCard from 'features/user/card/userDisplayCard'
import ThreadMenu from 'component/menu'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'

const SHOW_FOLLOWER_OPTIONS_MENU = false

const useThreadSettingsFollowerItemStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    color: theme.threadPalette.brownishGray,
    margin: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.threadPalette.lighterGray,
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    fill: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: 20,
  },
}))

const myMenuItems = [
  {
    label: 'Leave thread',
    value: 'leave_thread',
  },
]

const menuItems = [
  {
    label: 'View contact',
    value: 'view',
  },
  {
    label: 'Remove from thread',
    value: 'leave_thread',
  },
]

const threadOwnerMenuItems = [
  ...menuItems,
  {
    label: 'Make owner',
    value: 'make_owner',
  },
]

interface IThreadSettingsFollowerItemProps {
  user: IUser
  onMenuItemSelected?: (value: ThreadSettingsMenuItem, userId: string) => void
  addDivider?: boolean
}

const ThreadSettingsFollowerItem = ({
  user,
  onMenuItemSelected,
  addDivider = true,
}: IThreadSettingsFollowerItemProps) => {
  const classes = useThreadSettingsFollowerItemStyles()
  const currentUser = useUser()
  const { currentThread } = useCurrentThreadContext()
  const isMe = currentUser?.id === user.id
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)
  const usersOnlineStatus: TUsersOnlineStatus = useSelector(selectUsersOnlineStatus)

  const onMenuIconClick = (event: React.SyntheticEvent<any, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()

    setMenuAnchorEl(event.currentTarget)
  }

  const onMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const onMenuSelect = (value: ThreadSettingsMenuItem) => {
    setMenuAnchorEl(null)
    onMenuItemSelected?.(value, user.id)
  }

  const getMenuItems = () => {
    /* 
      Select menu items based on who's the currently logged in user and what are his privileges
    */
    if (isMe) {
      return myMenuItems
    } else {
      if (currentUser?.id !== currentThread?.ownerId) {
        return menuItems
      }
      return threadOwnerMenuItems
    }
  }
  const isShowUserConnectivityBadge = !!parseInt(process.env.USER_CONNECTIVITY_STATUS_ENABLED ?? '0')
  const userConnectivityStatus = isShowUserConnectivityBadge ? usersOnlineStatus[user.id] : undefined

  const displayCardActions = (
    <>
      {SHOW_FOLLOWER_OPTIONS_MENU && (
        <UserAccessCheckWrapper requiredPermissions={['user_registered']}>
          <div className={classes.section}>
            <OpenUserMenuOptionsIcon className={classes.icon} onClick={onMenuIconClick} />
          </div>
        </UserAccessCheckWrapper>
      )}
    </>
  )
  return (
    <>
      <ThreadMenu
        anchorEl={menuAnchorEl}
        items={getMenuItems()}
        open={menuAnchorEl !== null}
        onClose={onMenuClose}
        onSelect={onMenuSelect}
        customClasses={{
          item: classes.menuItem,
        }}
      />
      <UserDisplayCard
        user={user}
        onlineStatus={userConnectivityStatus}
        addDivider={addDivider}
        cardAction={displayCardActions}
      />
    </>
  )
}

export default ThreadSettingsFollowerItem
