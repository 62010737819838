import * as React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { getThreadFollowersQueryKey } from './useThreadFollowers'
import { useUser } from 'hooks/auth'

export const useThreadFollowersActions = () => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const refreshThreadFollowers = React.useCallback(
    (threadId: string) => {
      if (!currentUser?.id) return

      queryClient.invalidateQueries({ queryKey: getThreadFollowersQueryKey({ userId: currentUser.id, threadId }) })
    },
    [queryClient, currentUser?.id]
  )

  return { refreshThreadFollowers }
}
