import React from 'react'
import { useSelector } from 'react-redux'

import { handleCallback } from '../actions/outlook365OAuth'
import { OAuthCallbackPage } from '../lib/oauth/pages'
import { selectOutlook365OAuthHandlingCallback } from '../selectors/auth'

interface IOutlook365OAuthCallbackPageProps {}

const Outlook365OAuthCallbackPage = (props: IOutlook365OAuthCallbackPageProps) => {
  const handlingCallback = useSelector(selectOutlook365OAuthHandlingCallback)

  return <OAuthCallbackPage handleCallback={handleCallback} working={handlingCallback} />
}

export default Outlook365OAuthCallbackPage
