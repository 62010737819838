import React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles, createStyles } from '@material-ui/styles'
import Spacer from 'component/layoutUtils/spacer'
import classnames from 'classnames'
import WrapIntoSkeletonLoading from 'component/wrapIntoSkeletonLoading'

const useBaseMessageStyles = makeStyles((theme: Theme) =>
  createStyles({
    baseMessageRoot: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
    },
    messageContentRoot: {
      background: theme.threadPalette.white,
      border: `solid 2px ${theme.threadPalette.lightishGray}`,
      borderRadius: 10,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      display: 'flex',
      flexDirection: 'column',
      minHeight: '40px',
      minWidth: '90%',
      padding: '3px 10px 5px 4px',
      position: 'relative',
    },
    messageWithErrorContentRoot: {
      border: `solid 0.5px ${theme.threadPalette.vermilion}`,
    },
    attachmentsListRoot: {
      paddingInline: 6,
    },
    threadMessageFooterRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      gap: 12,
      paddingInline: 12,
    },
    viewAccessMessageContentRoot: {
      background: theme.threadPalette.lighterGray,
    },
  })
)

interface IBaseMessageProps {
  recipientsBlock: JSX.Element
  messageContentBlock: JSX.Element
  attachmentsBlock?: JSX.Element
  footerBlock?: JSX.Element
  isMessageWithError?: boolean
  isLoading?: boolean
  isViewAccess?: boolean
  contentRootClassName?: string
}

export const BaseMessage = ({
  recipientsBlock,
  messageContentBlock,
  attachmentsBlock,
  footerBlock,
  isMessageWithError,
  isLoading,
  isViewAccess,
  contentRootClassName,
}: IBaseMessageProps) => {
  const classes = useBaseMessageStyles()

  const messageContentRootClassName = classnames(classes.messageContentRoot, contentRootClassName, {
    [classes.messageWithErrorContentRoot]: isMessageWithError,
    [classes.viewAccessMessageContentRoot]: isViewAccess,
  })

  return (
    <div className={classes.baseMessageRoot}>
      {recipientsBlock}
      <WrapIntoSkeletonLoading
        isLoading={isLoading}
        variant="rect"
        className={classes.messageContentRoot}
        width={'100%'}
        animation="wave"
      >
        <div className={messageContentRootClassName}>
          {messageContentBlock}
          {attachmentsBlock && <div className={classes.attachmentsListRoot}>{attachmentsBlock}</div>}
        </div>
      </WrapIntoSkeletonLoading>
      {footerBlock && (
        <WrapIntoSkeletonLoading isLoading={isLoading} variant="text" height={24} animation="wave" width={'100%'}>
          <Spacer height={4} />
          <div className={classes.threadMessageFooterRoot}>{footerBlock}</div>
        </WrapIntoSkeletonLoading>
      )}
    </div>
  )
}
