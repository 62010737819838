import { useBoolean } from 'hooks/useBoolean'
import React from 'react'

interface IUseDialogProps {
  message?: string
}

const useDialog = <T extends HTMLElement>({ message: initialMessage }: IUseDialogProps = {}) => {
  const { state: isOpen, setValue: setIsOpen } = useBoolean(false)
  const [dialogAnchorElement, setDialogAnchorElement] = React.useState<T | null>(null)
  const [message, setMessage] = React.useState(initialMessage)

  const handleClickToOpenDialog = (event: React.MouseEvent<T>) => {
    setDialogAnchorRef(event.currentTarget)
    setIsOpen(true)
  }

  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  const setDialogAnchorRef = (ref: T | null) => {
    setDialogAnchorElement(ref)
  }

  const openDialog = () => setIsOpen(true)

  return {
    isOpen,
    dialogAnchorElement,
    message,
    setMessage,
    handleClickToOpenDialog,
    handleCloseDialog,
    setDialogAnchorRef,
    openDialog,
  }
}

export default useDialog
