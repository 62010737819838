import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { makeStyles, Theme, MuiThemeProvider, useTheme } from '@material-ui/core/styles'
import { Quill } from 'react-quill'
import { DeltaStatic } from 'quill'
import ColorIcon from '../toolbar/buttons/colorIcon'
import BackgroundIcon from '../toolbar/buttons/background'
import { MentionElement } from 'component/richTextEditor/mentionElement'
import { useRichTextActionsContext } from '../useRichTextActionsContext'
import { createDraftRecipient } from 'domain/messageRecipient'
import { parse } from 'node-html-parser'
import juice from 'juice'

const Delta = Quill.import('delta')

const setupDivBlockForEditorRows = () => {
  const Block = Quill.import('blots/block')
  Block.tagName = 'DIV'
  Quill.register(Block, true)
}

const setupDivBlockForPreviousMessageContentSeparation = () => {
  const BlockEmbed = Quill.import('blots/block/embed')

  class EmailPreviousMessageContentDivider extends BlockEmbed {
    static create(value: any) {
      const node = super.create(undefined, value)
      node.setAttribute('contenteditable', 'false')
      node.innerHTML = value
      return node
    }

    static value(node: any) {
      return node.innerHTML
    }

    static formats(domNode: HTMLElement): any {
      return true
    }
  }
  EmailPreviousMessageContentDivider.blotName = 'emailPreviousMessageContentDivider'
  EmailPreviousMessageContentDivider.className = 'email-previous-message-content-divider'
  EmailPreviousMessageContentDivider.tagName = 'DIV'

  Quill.register(EmailPreviousMessageContentDivider)
}

const setupExcelTableBlock = () => {
  const BlockEmbed = Quill.import('blots/block/embed')

  class ExcelTable extends BlockEmbed {
    static create(value: any) {
      const node = super.create(undefined, value)
      node.setAttribute('contenteditable', 'false')
      node.innerHTML = value
      return node
    }

    static value(node: any) {
      return node.innerHTML
    }

    static formats(domNode: HTMLElement): any {
      return true
    }
  }
  ExcelTable.blotName = 'excelTable'
  ExcelTable.className = 'excel-table-wrapper'
  ExcelTable.tagName = 'DIV'

  Quill.register(ExcelTable)
}

const setupCustomImageBlot = () => {
  const ImageBlot = Quill.import('formats/image')
  class CustomImageBlot extends ImageBlot {
    static blotName = 'image'
    static tagName = 'img'

    static create(value: any) {
      const node = super.create()

      node.setAttribute('src', value.src)
      if (value.dataCustom) node.setAttribute('data-custom', value.dataCustom)

      return node
    }

    static value(node: any) {
      return {
        src: node.getAttribute('src'),
        dataCustom: node.getAttribute('data-custom'),
      }
    }
  }
  Quill.register(CustomImageBlot)
}

const setupCustomMentionBlot = () => {
  const MentionBlot = Quill.import('formats/mention')

  class CustomMentionBlot extends MentionBlot {
    static blotName = 'custom-mention'

    static create(data: any) {
      const node = super.create(data)
      node.style.fontWeight = 'bold'
      node.style.textDecoration = 'underline'
      node.style.color = '#2196f3'
      return node
    }
  }

  Quill.register(CustomMentionBlot)
}

const setupToolbarDefaultIcons = () => {
  const icons = Quill.import('ui/icons')
  icons['list'].ordered = null
  icons['list'].bullet = null
  icons['bold'] = null
  icons['italic'] = null
  icons['underline'] = null
  icons['strike'] = null
  icons['clean'] = null
  icons['link'] = null
  icons['color'] = ReactDOMServer.renderToString(<ColorIcon />)
  icons['background'] = ReactDOMServer.renderToString(<BackgroundIcon />)
}

const setupLinkSanitizer = () => {
  const Link = Quill.import('formats/link')
  Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms']
  class CustomLinkSanitizer extends Link {
    static sanitize(url: string) {
      const trimmedUrl = url.trim()

      // Run default sanitize method from Quill
      const sanitizedUrl = super.sanitize(trimmedUrl)
      if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl

      // Verify if the URL already have a whitelisted protocol
      const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function (protocol: string) {
        return sanitizedUrl.startsWith(protocol)
      })

      if (hasWhitelistedProtocol) return sanitizedUrl

      // if not, then append only 'http' to not to be a relative URL
      return `http://${sanitizedUrl}`
    }
  }

  Quill.register(CustomLinkSanitizer, true)
}

setupDivBlockForEditorRows()
setupToolbarDefaultIcons()
setupDivBlockForPreviousMessageContentSeparation()
setupCustomImageBlot()
setupCustomMentionBlot()
setupLinkSanitizer()
setupExcelTableBlock()

const useQuillSetupStyles = makeStyles((theme: Theme) => ({
  suggestionsContainer: {
    backgroundColor: theme.threadPalette.white,
    borderRadius: 2,
    boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.2)',
    zIndex: 2,
  },
  suggestionsList: {
    padding: 0,
    margin: 0,
  },
  suggestionsItem: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    minWidth: 240,
    padding: 0,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.threadPalette.lighterGray,
    },
    '&.selected': {
      backgroundColor: theme.threadPalette.lighterGray,
    },
  },
}))

interface IUseQuillSetupProps {
  toolbarContainerId: string
  getMentionOptionsFromSearchTerm: (searchTerm?: string) => IUser[]
  onMention: (mentionedRecipient: TDraftRecipient) => void
}

interface IUserQuillSetupReturn {
  customModules: {
    [key: string]: any
  }
  allowedFormats: string[]
}

const useQuillSetup = ({
  toolbarContainerId,
  getMentionOptionsFromSearchTerm,
  onMention,
}: IUseQuillSetupProps): IUserQuillSetupReturn => {
  const classes = useQuillSetupStyles()
  const theme = useTheme()
  const { richTextEditorRef: quillRef, insertExcelTable } = useRichTextActionsContext()
  const onPasteRef = React.useRef<(e: ClipboardEvent) => void>()

  const getMentionsList = React.useCallback((searchTerm: string, renderList: any) => {
    const filteredFollowers = getMentionOptionsFromSearchTerm(searchTerm)
    renderList(
      filteredFollowers.map(follower => ({
        id: follower.id,
        value: follower.name ?? follower.email,
        name: follower.name ?? '',
        email: follower.email,
      }))
    )
  }, [])

  const renderMentionItem = React.useCallback(
    (mentionItem: any) => {
      const mentionOptions = getMentionOptionsFromSearchTerm()
      const mentionedUser = mentionOptions.find(user => user.id === mentionItem.id)
      if (!mentionedUser) return null

      return ReactDOMServer.renderToString(
        <MuiThemeProvider theme={theme}>
          <MentionElement user={mentionedUser} />
        </MuiThemeProvider>
      )
    },
    [theme]
  )

  const onSelectMention = React.useCallback(
    (item: any, insertItem: Function) => {
      insertItem(item)
      if (item?.email) {
        const mentionedRecipient = createDraftRecipient(item.email, item.name)
        onMention(mentionedRecipient)
      }
    },
    [onMention]
  )

  const customModules = React.useMemo(() => {
    const history = {
      delay: 1000,
      maxStack: 500,
      userOnly: false,
    }
    const toolbar = {
      container: toolbarContainerId,
    }
    const mention = {
      allowedChars: /^[A-Za-z\sÅÄÖåäö.@]*$/,
      spaceAfterInsert: true,
      isolateCharacter: true,
      defaultMenuOrientation: 'top',
      blotName: 'custom-mention',
      dataAttributes: ['id', 'value', 'name', 'email'],
      mentionContainerClass: classes.suggestionsContainer,
      mentionListClass: classes.suggestionsList,
      listItemClass: classes.suggestionsItem,
      source: getMentionsList,
      renderItem: renderMentionItem,
      onSelect: onSelectMention,
    }

    return {
      history,
      toolbar,
      mention,
    }
  }, [toolbarContainerId])

  const allowedFormats = React.useMemo(
    () => [
      'link',
      'header',
      'list',
      'bullet',
      'ordered',
      'bold',
      'italic',
      'underline',
      'strike',
      'color',
      'background',
      'clean',
      'font',
      'size',
      'blockquote',
      'indent',
      'image',
      'mention',
      'emoji',
      'emailPreviousMessageContentDivider',
      'custom-mention',
      'excelTable',
    ],
    []
  )

  React.useEffect(() => {
    // want to prevent default clicking on toolbar to not loose selected text
    const editor = quillRef?.getEditor()
    if (!editor) return

    editor.getModule('toolbar')?.container?.addEventListener('mousedown', (e: MouseEvent) => {
      e.preventDefault()
    })

    // want to only parse images on Quill if they have a custom reference so we can match them afterwards, otherwise it should not render them on the editor
    editor.clipboard.addMatcher('img', (node: any, delta: DeltaStatic) => {
      const hasCustomId = node.getAttribute('data-custom')

      if (!hasCustomId) {
        return new Delta()
      }
      return delta
    })
  }, [quillRef])

  const onPaste = React.useCallback(
    (e: ClipboardEvent) => {
      const clipboardData = e.clipboardData

      if (!clipboardData) return

      const pastedData: string = clipboardData.getData('text/html')

      if (!pastedData || pastedData.length === 0) return

      const htmlStylesInlined = juice(pastedData)

      const root = parse(htmlStylesInlined)
      const htmlTables = root.getElementsByTagName('table')

      if (htmlTables.length === 0) return

      e.preventDefault()

      htmlTables.forEach(htmlTable => {
        const htmlTableAsString = htmlTable.toString()
        insertExcelTable(htmlTableAsString)
      })
    },
    [insertExcelTable]
  )

  React.useEffect(() => {
    onPasteRef.current = onPaste
  }, [onPaste])

  React.useEffect(() => {
    const editor = quillRef?.getEditor()
    if (!editor?.root) return

    const onPaste = (e: ClipboardEvent) => onPasteRef.current?.(e)

    editor.root.addEventListener('paste', onPaste)

    return () => {
      editor.root.removeEventListener('paste', onPaste)
    }
  }, [quillRef])

  return {
    customModules,
    allowedFormats,
  }
}

export default useQuillSetup
