import * as React from 'react'
import { IProjectThread } from 'model/project'
import { Button, Theme, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LogoOnlyIcon from 'icons/logoOnly'
import { useOpenThreadUseCase } from 'usecases/openThread'
import classNames from 'classnames'
import { useTextInEllipsis } from 'hooks/useTextInEllipsis'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { getThreadDisplayName } from 'domain/thread'

export const useProjectThreadItemStyles = makeStyles((theme: Theme) => {
  return {
    projectThreadItemRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      height: '26px',
      justifyContent: 'flex-start',
      width: '170px',
    },
    threadButton: {
      alignItems: 'center',
      borderRadius: '3px',
      display: 'flex',
      flexDirection: 'row',
      height: '18px',
      justifyContent: 'flex-start',
      width: '100%',
      '&:hover': {
        backgroundColor: theme.threadPalette.onFocusGray,
      },
    },
    threadButtonActive: {
      backgroundColor: theme.threadPalette.white,
      '&:hover': {
        backgroundColor: theme.threadPalette.white,
      },
    },
    threadTitle: {
      color: theme.threadPalette.white,
      fontSize: '13px',
      height: '18px',
      marginLeft: '6px',
      maxWidth: '132px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    threadTitleActive: {
      color: theme.threadPalette.darkBlueGrey,
    },
    threadItemFocus: {
      backgroundColor: theme.threadPalette.onFocusGray,
      color: theme.threadPalette.white,
    },
  }
})

interface IProjectThreadProps {
  projectThread: IProjectThread
  onHover?: () => void
}

export const ProjectThreadItem = ({ projectThread, onHover }: IProjectThreadProps) => {
  const classes = useProjectThreadItemStyles()

  const { openThreadByIdUseCase } = useOpenThreadUseCase()

  const { currentThread } = useCurrentThreadContext()
  const { textRef: threadNameRef, hasEllipsis: showTooltip } = useTextInEllipsis()

  const threadDisplayName = getThreadDisplayName(projectThread)

  const isProjectThreadActive = currentThread && currentThread.id === projectThread.id

  const threadButtonClassName = classNames(classes.threadButton, {
    [classes.threadButtonActive]: isProjectThreadActive,
  })
  const threadTitleClassName = classNames(classes.threadTitle, {
    [classes.threadTitleActive]: isProjectThreadActive,
  })

  const onProjectThreadClick = () => {
    openThreadByIdUseCase({ threadId: projectThread.id })
  }

  return (
    <div className={classes.projectThreadItemRoot}>
      <Button
        classes={{ root: threadButtonClassName }}
        onClick={onProjectThreadClick}
        focusVisibleClassName={classes.threadItemFocus}
        disableRipple
        onMouseEnter={onHover}
      >
        <LogoOnlyIcon height={14} width={14} fill={isProjectThreadActive ? '#1d2d45' : '#ffffff'} />
        <Tooltip
          title={showTooltip && threadDisplayName ? threadDisplayName : ''}
          arrow={true}
          aria-label="thread name"
        >
          <Typography className={threadTitleClassName} ref={threadNameRef}>
            {threadDisplayName}
          </Typography>
        </Tooltip>
      </Button>
    </div>
  )
}
