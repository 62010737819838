import { useInsertThreadUseCase } from './insertThread'
import { useRemoveThreadUseCase } from './removeThread'

interface IMoveThreadUpUseCaseReturn {
  rollbackOptimisticUpdates?: () => void
}

export const useMoveThreadUpUseCase = () => {
  const { removeThreadUseCase } = useRemoveThreadUseCase()
  const { insertThreadUseCase } = useInsertThreadUseCase()

  const moveThreadUpUseCase = (threadId: string): IMoveThreadUpUseCaseReturn => {
    const { removedThread: threadToInsert, rollbackOptimisticUpdates } = removeThreadUseCase(threadId)

    if (!threadToInsert) return { rollbackOptimisticUpdates: undefined }

    insertThreadUseCase(threadToInsert)

    return {
      rollbackOptimisticUpdates,
    }
  }

  return {
    moveThreadUpUseCase,
  }
}
