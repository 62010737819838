import React from 'react'
import { Helmet } from 'react-helmet'
import useCurrentUrl from '../../hooks/useCurrentUrl'
interface ITwitterOpenGraphProps {
  site?: string
  label1?: string
  data1?: string
  label2?: string
  data2?: string
}

interface IHeadProps {
  pageTitle?: string
  ogTitle: string
  ogDescription?: string
  ogUrl?: string
  ogType?: string
  ogSiteName?: string
  ogTwitter?: ITwitterOpenGraphProps
}

const HeadProvider: React.FC<IHeadProps> = ({
  pageTitle,
  children,
  ogTitle,
  ogDescription,
  ogUrl,
  ogTwitter,
  ogSiteName = 'Threadlive',
  ogType = 'website',
}) => {
  const { currentUrl } = useCurrentUrl()

  return (
    <Helmet>
      {pageTitle && <title>{pageTitle}</title>}
      {children}
      <meta property="og:type" content={ogType} />
      <meta property="og:site_name" content={ogSiteName} />
      {ogTitle && <meta property="og:title" content={ogTitle} />}
      {ogDescription && <meta property="og:description" content={ogDescription} />}
      <meta property="og:url" content={ogUrl || currentUrl} />
      <meta name="twitter:card" content="summary" />
      {ogTwitter?.label1 && <meta name="twitter:label1" content={ogTwitter.label1} />}
      {ogTwitter?.data1 && <meta name="twitter:data1" content={ogTwitter.data1} />}
      {ogTwitter?.label2 && <meta name="twitter:label2" content={ogTwitter.label2} />}
      {ogTwitter?.data2 && <meta name="twitter:data2" content={ogTwitter.data2} />}
      {ogTwitter?.site && <meta name="twitter:site" content={ogTwitter.site} />}
    </Helmet>
  )
}

export default HeadProvider
