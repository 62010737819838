import React from 'react'
import { useSelector } from 'react-redux'

import { login } from '../actions/googleOAuth'
import { OAuthLoginPage } from '../lib/oauth/pages'
import { selectGoogleOAuthLoggingIn } from '../selectors/auth'

interface IGoogleOAuthLoginPageProps {}

const GoogleOAuthLoginPage = (props: IGoogleOAuthLoginPageProps) => {
  const loggingIn = useSelector(selectGoogleOAuthLoggingIn)
  return <OAuthLoginPage login={login} working={loggingIn} />
}

export default GoogleOAuthLoginPage
