import React from 'react'
import { Grid, Theme, fade, makeStyles, useTheme } from '@material-ui/core'
import { ISharingGroup } from 'model/sharingGroup'
import { MessageFragment } from 'component/message/messageFragment/messageFragment'
import SharingGroupCard from 'component/sharingGroup/common/sharingGroupCard/sharingGroupCard'
import { HeldMessageActionButton } from './heldMessageActionButton'
import GroupIcon from '@material-ui/icons/Group'
import GroupOffIcon from 'icons/groupOff'
import { useShareMessageWithSharingGroupUseCase } from 'usecases/sharingGroup/shareMessageWithSharingGroup'
import { useRejectHeldMessageForSharingGroupUseCase } from 'usecases/sharingGroup/rejectHeldMessageForSharingGroup'
import classNames from 'classnames'

const useHeldMessageWithSharingPanelStyles = makeStyles((theme: Theme) => ({
  heldMessageWithSharingPanelRoot: {
    margin: 0,
  },
  animate: {
    animation: 'blink 1s',
  },
  sharingGroupCardWithActions: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  sharingGroupsWithActionsRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 14,
  },
  sharingGroupCard: {
    border: 'none',
    color: theme.threadPalette.menuItem.current,
    overflow: 'hidden',
  },
  sharingGroupName: {
    fontWeight: 'normal',
  },
  approveButton: {
    backgroundColor: fade(theme.threadPalette.lightGreen, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.threadPalette.lightGreen, 0.3),
    },
  },
  rejectButton: {
    backgroundColor: fade(theme.threadPalette.vividRed, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.threadPalette.vividRed, 0.3),
    },
  },
}))

interface IHeldMessageWithSharingPanelProps {
  message: IHeldMessage
  sharingGroups: ISharingGroup[]
  user: IUser
  onClickToViewEntireMessage(): void
  isShowFocusAnimation?: boolean
}

export const HeldMessageWithSharingPanel = ({
  message,
  sharingGroups,
  user,
  onClickToViewEntireMessage,
  isShowFocusAnimation = false,
}: IHeldMessageWithSharingPanelProps) => {
  const theme = useTheme()
  const classes = useHeldMessageWithSharingPanelStyles()

  const { shareMessageWithSharingGroupUseCase } = useShareMessageWithSharingGroupUseCase()
  const { rejectHeldMessageForSharingGroupUseCase } = useRejectHeldMessageForSharingGroupUseCase()

  const onApproveMessage = (sharingGroup: ISharingGroup) => {
    shareMessageWithSharingGroupUseCase({
      messageId: message.id,
      threadId: message.threadId,
      sharingGroupId: sharingGroup.id,
    })
  }

  const onRejectMessage = (sharingGroup: ISharingGroup) => {
    rejectHeldMessageForSharingGroupUseCase({
      messageId: message.id,
      threadId: message.threadId,
      sharingGroupId: sharingGroup.id,
    })
  }

  if (sharingGroups.length === 0) return null

  return (
    <Grid
      container
      spacing={2}
      className={classNames(classes.heldMessageWithSharingPanelRoot, { [classes.animate]: isShowFocusAnimation })}
    >
      <Grid item xs={5} className={classes.sharingGroupsWithActionsRoot}>
        {sharingGroups.map(group => (
          <div className={classes.sharingGroupCardWithActions} key={group.id}>
            <HeldMessageActionButton
              icon={GroupIcon}
              text="Approve"
              className={classes.approveButton}
              color={theme.threadPalette.lightGreen}
              onClick={() => onApproveMessage(group)}
            />
            <HeldMessageActionButton
              icon={GroupOffIcon}
              text="Reject"
              className={classes.rejectButton}
              color={theme.threadPalette.vividRed}
              onClick={() => onRejectMessage(group)}
            />
            <SharingGroupCard
              sharingGroup={group}
              className={classes.sharingGroupCard}
              sharingGroupNameClassName={classes.sharingGroupName}
              showMembersInPreview
            />
          </div>
        ))}
      </Grid>
      <Grid item xs={7}>
        <MessageFragment
          message={message}
          currentUser={user}
          onViewEntireMessage={onClickToViewEntireMessage}
          heldReason={message.reason}
          key={message.id}
        />
      </Grid>
    </Grid>
  )
}
