import * as React from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface ITabContentScrolledContextArgs {
  isBottom: boolean
}

export const TabContentScrolledContext = React.createContext<ITabContentScrolledContextArgs>({ isBottom: false })

export default function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  const [isBottom, setIsBottom] = React.useState(false)

  const handleScrollEvent = React.useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget
    setIsBottom(target.scrollHeight - target.scrollTop === target.clientHeight)
  }, [])

  return (
    <TabContentScrolledContext.Provider value={{ isBottom }}>
      <div
        onScroll={handleScrollEvent}
        role="tabpanel"
        style={{ height: '100%', overflow: 'auto' }}
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    </TabContentScrolledContext.Provider>
  )
}
