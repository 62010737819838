import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import { OnboardingStepWithImage } from '../onboardingStepWithImage'
import { SharedMessageIcon } from 'icons/onboarding/sharedMessageIcon'

const useSharedMessageStepStyles = makeStyles((theme: Theme) => ({
  sharedMessageIcon: {
    height: 240,
  },
  groupIcon: {
    color: theme.palette.primary.main,
    height: 20,
    verticalAlign: 'text-bottom',
  },
}))

export const SharedMessageStep = () => {
  const classes = useSharedMessageStepStyles()

  const textLines = [
    <>
      Your shared messages will have a blue icon <GroupIcon className={classes.groupIcon} /> next to them, making it
      easy to see who has access.
    </>,
    <>
      You can <strong>edit the sharing group</strong> or <strong>revoke access to individual messages</strong> at any
      time.
    </>,
  ]

  return (
    <OnboardingStepWithImage
      headerText="Your thread is shared!"
      image={<SharedMessageIcon className={classes.sharedMessageIcon} />}
      topTextLines={textLines}
      lineHeight={1.8}
      textLinesMaxWidth={440}
    />
  )
}
