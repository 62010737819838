import { ISharingGroup } from 'model/sharingGroup'
import React from 'react'
import GroupIcon from '@material-ui/icons/Group'
import { Theme, Typography, makeStyles } from '@material-ui/core'
import { EditButton } from '../editButton'
import classNames from 'classnames'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import { SharingGroupMembersPopup } from './sharingGroupMembersPopup'

const useSharingGroupCardStyles = makeStyles<Theme, { showMembersInPreview: boolean }>((theme: Theme) => ({
  sharingGroupCardRoot: {
    border: 'solid',
    borderColor: theme.threadPalette.lightSoftGray,
    borderWidth: 2,
    borderRadius: 12,
    paddingBlock: 8,
    paddingInline: 10,
    color: theme.threadPalette.brownishGray,
    display: 'flex',
    gap: 12,
  },
  sharingGroupInfoContainer: {
    color: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 4,
    lineHeight: 1,
    overflow: 'hidden',
    width: '100%',
  },
  icon: {
    flex: '0 0 auto',
    fontSize: 30,
  },
  sharingGroupName: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 22,
    fontWeight: 700,
    gap: 6,
    lineHeight: 'inherit',
    paddingTop: 2,
  },
  sharingGroupMembers: {
    fontSize: 13,
    lineHeight: 'inherit',
    overflow: 'hidden',
    paddingLeft: 2,
    textOverflow: 'ellipsis',
    whiteSpace: ({ showMembersInPreview }) => (showMembersInPreview ? 'nowrap' : undefined),
  },
  membersListRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  dropdownIcon: {
    height: 20,
    width: 20,
  },
}))

interface ISharingGroupCardProps {
  sharingGroup: ISharingGroup
  onEditGroup?(): void
  className?: string
  sharingGroupNameClassName?: string
  customEditButton?: React.ReactNode
  showMembersInPreview?: boolean
}

export const SharingGroupCard: React.FC<ISharingGroupCardProps> = ({
  sharingGroup,
  onEditGroup,
  className,
  sharingGroupNameClassName,
  customEditButton,
  showMembersInPreview = false,
  children,
}) => {
  const classes = useSharingGroupCardStyles({ showMembersInPreview })
  const numberOfMembers = sharingGroup.members.length

  const {
    isOpen: isSharingGroupMembersPopupOpen,
    dialogAnchorElement,
    openDialog: openSharingGroupMembersPopup,
    handleCloseDialog: closeSharingGroupMembersPopup,
    setDialogAnchorRef: setSharingGroupMembersPopupAnchorElement,
  } = useDialog()

  const membersNames = sharingGroup.members
    .map(member => (!!member.name?.trim() ? member.name : member.email))
    .join(', ')

  const defaultEditButton = onEditGroup ? <EditButton label="Edit group" onClick={onEditGroup} /> : null
  const editButton = customEditButton ?? defaultEditButton
  return (
    <div className={classNames(classes.sharingGroupCardRoot, className)}>
      <GroupIcon className={classes.icon} />
      <div className={classes.sharingGroupInfoContainer}>
        <Typography className={classNames(classes.sharingGroupName, sharingGroupNameClassName)}>
          {sharingGroup.name} ({numberOfMembers}){editButton}
        </Typography>
        <div className={classes.membersListRoot} ref={setSharingGroupMembersPopupAnchorElement}>
          <Typography className={classes.sharingGroupMembers}>{membersNames}</Typography>
          {showMembersInPreview && (
            <ArrowDropDownIcon className={classes.dropdownIcon} onClick={openSharingGroupMembersPopup} />
          )}
        </div>
        {children}
      </div>
      <SharingGroupMembersPopup
        isOpen={isSharingGroupMembersPopupOpen}
        anchorEl={dialogAnchorElement}
        onClose={closeSharingGroupMembersPopup}
        members={sharingGroup.members}
      />
    </div>
  )
}

export default SharingGroupCard
