import React from 'react'
import { makeStyles, Theme, Checkbox, Tooltip } from '@material-ui/core'
import { useThreadShareableMessagesList } from 'hooks/useThreadShareableMessages'
import classnames from 'classnames'
import { MessageFragment } from 'component/message/messageFragment/messageFragment'
import { MessageFragmentSkeleton } from 'component/message/messageFragment/messageFragmentSkeleton'
import { Skeleton } from '@material-ui/lab'
import { isMessageInThreadSharingSettings } from 'domain/sharingGroup'
import { MessagesFragmentList } from 'component/sharingGroup/common/messagesFragmentList'
import { ThreadTitleWithLogo } from 'component/threadTitleWithLogo/threadTitleWithLogo'

const useSelectShareableMessagesStyles = makeStyles((theme: Theme) => ({
  threadInfoContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginBottom: 20,
    paddingInline: 12,
  },
  threadTitle: {
    fontSize: 24,
    fontWeight: 500,
  },
  checkbox: {
    color: theme.threadPalette.lightGray,
    padding: 10,
  },
  messageWithCheckbox: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    paddingLeft: 12,
    paddingRight: 16,
  },
  messageCheckbox: {
    marginTop: 10,
  },
  checkboxSkeleton: {
    margin: 10,
  },
  shareableMessagesList: {
    gap: 18,
  },
}))

interface ISelectShareableMessagesProps {
  thread: IThread
  currentUser: IUser
  selectedMessagesIds: string[]
  setSelectedMessagesIds(newSelectedMessagesIds: string[]): void
  onViewEntireMessage?(message: IMessage): void
  messageSelectedForEntireContentView: IMessage | null
}

export const SelectShareableMessages = ({
  thread,
  currentUser,
  selectedMessagesIds,
  setSelectedMessagesIds,
  onViewEntireMessage,
  messageSelectedForEntireContentView,
}: ISelectShareableMessagesProps) => {
  const classes = useSelectShareableMessagesStyles()

  const {
    shareableMessagesList,
    isLoading: isLoadingMessagesList,
    fetchNextPage,
    hasNextPage,
  } = useThreadShareableMessagesList(thread.id)

  const getIsMessageSelected = (message: IMessage) =>
    isMessageInThreadSharingSettings(message, { sharedMessagesIds: selectedMessagesIds })

  const onToggleMessageSelected = (message: IMessage) => {
    const isMessageSelected = getIsMessageSelected(message)

    const newSelectedMessagesIds = isMessageSelected
      ? selectedMessagesIds.filter(selectedMessageId => selectedMessageId !== message.id)
      : [...selectedMessagesIds, message.id]
    setSelectedMessagesIds(newSelectedMessagesIds)
  }

  const getShareableMessagesSkeleton = () => (
    <div className={classes.messageWithCheckbox}>
      <Skeleton
        variant="rect"
        width={20}
        height={20}
        className={classnames(messageCheckboxClassName, classes.checkboxSkeleton)}
      />
      <MessageFragmentSkeleton currentUser={currentUser} />
    </div>
  )

  const messageCheckboxClassName = classnames(classes.checkbox, classes.messageCheckbox)

  const header = (
    <div className={classes.threadInfoContainer}>
      <Tooltip title="Coming soon! This feature is currently under development">
        <Checkbox checked={false} classes={{ root: classes.checkbox }} />
      </Tooltip>
      <ThreadTitleWithLogo thread={thread} fontSize={26} />
    </div>
  )

  return (
    <MessagesFragmentList
      messagesCount={shareableMessagesList.length}
      currentUser={currentUser}
      getMessageFragmentSkeleton={getShareableMessagesSkeleton}
      header={header}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isLoading={isLoadingMessagesList}
      messageSelectedForEntireContentView={messageSelectedForEntireContentView}
      className={classes.shareableMessagesList}
      isInverseList
    >
      {shareableMessagesList.map(message => {
        return (
          <div className={classes.messageWithCheckbox} key={message.id}>
            <Checkbox
              checked={getIsMessageSelected(message)}
              onChange={() => onToggleMessageSelected(message)}
              classes={{ root: messageCheckboxClassName }}
            />
            <MessageFragment
              message={message}
              currentUser={currentUser}
              onViewEntireMessage={() => onViewEntireMessage?.(message)}
            />
          </div>
        )
      })}
    </MessagesFragmentList>
  )
}
