import * as React from 'react'
import { useDispatch } from 'react-redux'
import useWebSocketClient from '../api/websocket/useWebSockets'
import { WSEvent } from './websocket/WSClient'
import TypingHandler from './websocket/actionHandlers/classes/typingHandler'
import { WebSocketGateContext } from './websocket/webSocketGateContext'
import { WS_ACTIONS } from '../constants/actions/webSocket'
import { changeOnlineStatus } from '../actions/user'
import NotificationHandler from './websocket/actionHandlers/classes/notificationHandler'
import ThreadActivityHandler from './websocket/actionHandlers/classes/threadActivityHandler'
import { useUser } from 'hooks'
import MessageHandler from './websocket/actionHandlers/classes/messageHandler'
import CloudConvertHandler from './websocket/actionHandlers/classes/cloudConvertHandler'
import { useOpenThreadUseCase } from 'usecases/openThread'
import { useUpdateCurrentUserUseCase } from 'usecases/updateUser'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { useNewThreadMessageUseCase } from 'usecases/newThreadMessage'
import { useRemoveThreadUseCase } from 'usecases/removeThread'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'
import { useThreadFollowersActions } from 'hooks/useThreadFollowers/useThreadFollowersActions'
import { useAttachmentContentDetailsCacheActions } from 'hooks/useAttachmentContentDetails/useAttachmentContentDetailsCacheActions'
import { useDraftCacheActions } from 'hooks/useDraft/useDraftCacheActions'
import { useThreadMessagesCacheActions } from 'hooks/useThreadMessages/useThreadMessagesCacheActions'
import { useReadThreadUseCase } from 'usecases/readThread'
import { useRefreshThreadData } from 'hooks/useThreads/useRefreshThreadData'
import { useUpdateThreadUseCase } from 'usecases/updateThread'

interface IWebSocketGateProps {
  children: React.ReactNode
}

const WebSocketGate = ({ children }: IWebSocketGateProps) => {
  const dispatch = useDispatch()

  const currentUser = useUser()
  const handlers = React.useMemo(() => {
    return {
      typingHandler: new TypingHandler(),
      messageHandler: new MessageHandler(),
      notificationHandler: new NotificationHandler(),
      threadActivityHandler: new ThreadActivityHandler(),
      cloudConvertHandler: new CloudConvertHandler(),
    }
  }, [])
  const { openThreadByIdUseCase } = useOpenThreadUseCase()
  const { updateCurrentUserUseCase } = useUpdateCurrentUserUseCase()
  const { currentThread } = useCurrentThreadContext()
  const { refreshThread, refreshThreadsList } = useThreadActions()
  const { newThreadMessageUseCase } = useNewThreadMessageUseCase()
  const { removeThreadUseCase } = useRemoveThreadUseCase()
  const { refreshThreadFollowers } = useThreadFollowersActions()
  const attachmentContentDetailsCacheActions = useAttachmentContentDetailsCacheActions()
  const draftCacheActions = useDraftCacheActions()
  const threadMessagesCacheActions = useThreadMessagesCacheActions()
  const { readThreadUseCase } = useReadThreadUseCase()
  const { refreshThreadData } = useRefreshThreadData()
  const { updateThreadUseCase } = useUpdateThreadUseCase()

  const onNotificationClicked = React.useCallback(
    (thread: IThread) => {
      openThreadByIdUseCase({ threadId: thread.id, initialData: thread })
    },
    [dispatch]
  )

  const eventHandler = React.useCallback(
    (evt: WSEvent) => {
      console.debug(evt)
      switch (evt.message.event) {
        case WS_ACTIONS.TYPING: {
          handlers.typingHandler.handle(evt.message.thread_id, evt.message.user_id)
          break
        }

        case WS_ACTIONS.NEW_NOTIFICATION: {
          handlers.notificationHandler.handle(
            currentUser,
            evt.message.notification_id,
            evt.message.unread_quantity,
            evt.message.type,
            onNotificationClicked
          )
          break
        }

        case WS_ACTIONS.ACTIVITY_CREATED: {
          handlers.threadActivityHandler.handle(evt.message.thread_id)
          break
        }

        case WS_ACTIONS.NEW_MESSAGE: {
          handlers.messageHandler.handle(evt.message.thread_id, evt.message.message_id, 'newMsg')
          newThreadMessageUseCase({ threadId: evt.message.thread_id })
          break
        }

        case WS_ACTIONS.ONLINE_STATUS_CHANGE: {
          dispatch(
            changeOnlineStatus(evt.message.user_id, {
              status: evt.message.status,
              timestamp: evt.message.timestamp,
              location: evt.message.location,
            })
          )
          break
        }

        case WS_ACTIONS.THREAD_FOLLOWERS_UPDATED: {
          refreshThreadFollowers(evt.message.thread_id)
          break
        }

        case WS_ACTIONS.NEW_THREAD: {
          refreshThreadsList()
          break
        }

        case WS_ACTIONS.MESSAGE_EDITED: {
          handlers.messageHandler.handle(evt.message.thread_id, evt.message.message_id, 'edit')
          break
        }

        case WS_ACTIONS.SEEN_BY_CHANGE: {
          handlers.messageHandler.handle(evt.message.thread_id, evt.message.message_id, 'edit')
          break
        }

        case WS_ACTIONS.DELETE_MESSAGE: {
          handlers.messageHandler.handle(evt.message.thread_id, evt.message.message_id, 'delete')
          break
        }

        case WS_ACTIONS.CLOUD_CONVERT_EVENT: {
          handlers.cloudConvertHandler.handle(evt.message.attachment_id)
          break
        }

        case WS_ACTIONS.MAILBOX_SYNC: {
          updateCurrentUserUseCase()
          break
        }

        case WS_ACTIONS.THREAD_MERGED: {
          const mergedThreadId = evt.message.merged_thread_id
          const mergedToThreadId = evt.message.merged_to_thread_id

          removeThreadUseCase(mergedThreadId)

          if (currentThread && mergedThreadId === currentThread.id) {
            openThreadByIdUseCase({ threadId: mergedToThreadId })
          }

          break
        }

        case WS_ACTIONS.THREAD_HAS_IMPORT_ERRORS: {
          const threadId = evt.message.thread_id

          if (threadId) refreshThread(threadId)

          break
        }

        case WS_ACTIONS.THREAD_FOLLOWED: {
          const threadId = evt.message.thread_id
          refreshThreadsList()
          refreshThread(threadId)
          break
        }

        case WS_ACTIONS.THREAD_UNFOLLOWED: {
          const threadId = evt.message.thread_id
          refreshThreadsList()
          refreshThread(threadId)
          break
        }

        case WS_ACTIONS.ATTACHMENT_UPLOADED_TO_FILE_STORAGE: {
          const attachmentId = evt.message.attachment_id

          attachmentContentDetailsCacheActions.refreshData({ id: attachmentId })

          break
        }

        case WS_ACTIONS.DRAFT_SYNCING_STATUS_UPDATED: {
          const draftId = evt.message.draft_id
          const draftVersionUpdatedOnProvider = evt.message.draft_version_updated_on_provider
          const isSyncingWithProvider = evt.message.is_syncing_with_provider
          const isSyncedWithProvider = evt.message.is_synced_with_provider

          const draft = draftCacheActions.getData(draftId)
          if (!draft) break

          const draftCurrentVersion = draft.draftVersion
          const isCurrentDraftVersionSyncedWithProvider =
            isSyncedWithProvider && draftCurrentVersion <= draftVersionUpdatedOnProvider

          const updateDraftSyncingStatus = (draft: IDraftMessage) => {
            return {
              ...draft,
              draftVersionUpdatedOnProvider,
              isSyncingWithProvider,
              isSyncedWithProvider: isCurrentDraftVersionSyncedWithProvider,
            }
          }
          draftCacheActions.updateData({ draftId, draftModifier: updateDraftSyncingStatus })

          break
        }

        case WS_ACTIONS.THREAD_IMPORTED: {
          const threadId = evt.message.thread_id

          refreshThreadData(threadId)
          readThreadUseCase(threadId)

          break
        }

        case WS_ACTIONS.MESSAGES_BATCH_IMPORTED: {
          const threadId = evt.message.thread_id
          threadMessagesCacheActions.refreshData(threadId)

          break
        }

        case WS_ACTIONS.IMPORTING_THREAD: {
          const threadId = evt.message.thread_id
          updateThreadUseCase({ threadId, isImporting: true })

          break
        }
      }
    },
    [handlers, currentUser, onNotificationClicked, dispatch, currentThread]
  )

  useWebSocketClient({ onEvent: eventHandler })

  return <WebSocketGateContext.Provider value={{ ...handlers }}>{children}</WebSocketGateContext.Provider>
}

export default WebSocketGate
