import React from 'react'
import { Popover, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import { IProject } from 'model/project'
import ProjectBadge from '../badges/projectBadge'
import ProjectDetails from './projectDetails'
import MultiProjectsSelect from './multiProjectsSelect'
import { useDisconnectThreadFromProjectUseCase } from 'usecases/disconnectThreadFromProject'

const useThreadProjectsStyles = makeStyles((theme: Theme) => ({
  projectDetailsModal: {
    backgroundColor: theme.threadPalette.darkerBlue,
    borderRadius: 10,
    color: theme.threadPalette.white,
    paddingBottom: 6,
    paddingInline: 10,
    paddingTop: 4,
    width: 345,
  },
}))

interface IThreadProjectsProps {
  threadId: string
  projects: IProject[]
}
function ThreadProjects({ threadId, projects }: IThreadProjectsProps) {
  const classes = useThreadProjectsStyles()
  const [selectedProjectId, setSelectedProjectId] = React.useState<string | null>(null)

  const selectedProject = React.useMemo(() => {
    return projects.find(project => project.id === selectedProjectId)
  }, [projects, selectedProjectId])

  const {
    isOpen: isProjectDetailsDialogOpen,
    dialogAnchorElement: projectAnchorButton,
    openDialog: openProjectDetailsDialog,
    handleCloseDialog: closeProjectDetailsDialog,
    setDialogAnchorRef: projectButtonRef,
  } = useDialog()
  const { disconnectThreadFromProjectUseCase } = useDisconnectThreadFromProjectUseCase()

  const disconnectProjectFromCurrentThread = React.useCallback(
    (project: IProject) => {
      disconnectThreadFromProjectUseCase({ projectId: project.id, threadId: threadId })
      closeProjectDetailsDialog()
    },
    [threadId]
  )

  const disconnectProjectFromThread = React.useCallback(
    (threadToRemoveId: string, projectId: string) => {
      disconnectThreadFromProjectUseCase({ projectId, threadId: threadToRemoveId })
      if (threadId === threadToRemoveId) {
        closeProjectDetailsDialog()
      }
    },
    [threadId]
  )

  if (!projects.length) return null

  const openDetailsForProject = (project: IProject) => {
    setSelectedProjectId(project.id)
    openProjectDetailsDialog()
  }

  const firstThreadProject = projects[0]

  const hasMultipleProjects = projects?.length > 1

  const projectBadge = hasMultipleProjects ? (
    <MultiProjectsSelect
      ref={projectButtonRef}
      projects={projects}
      onSelect={openDetailsForProject}
      onDisconnect={disconnectProjectFromCurrentThread}
    />
  ) : (
    <ProjectBadge
      ref={projectButtonRef}
      name={firstThreadProject.name}
      emoji={firstThreadProject.emoji}
      threadCount={firstThreadProject.threadCount}
      onClick={() => openDetailsForProject(firstThreadProject)}
      onDisconnect={() => disconnectProjectFromCurrentThread(firstThreadProject)}
    />
  )

  return (
    <>
      {projectBadge}
      <Popover
        classes={{ paper: classes.projectDetailsModal }}
        open={isProjectDetailsDialogOpen}
        elevation={8}
        anchorEl={projectAnchorButton}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={closeProjectDetailsDialog}
      >
        {selectedProject && (
          <ProjectDetails
            project={selectedProject}
            currentThreadId={threadId}
            onDisconnectThread={(threadId: string) => disconnectProjectFromThread(threadId, selectedProject.id)}
            onClose={closeProjectDetailsDialog}
          />
        )}
      </Popover>
    </>
  )
}

export default ThreadProjects
