import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Theme, Typography } from '@material-ui/core'
import { LogoWithThreadLiveName } from 'icons/logoWithThreadLiveName'
import { OneMinutePerEmailIcon } from 'icons/oneMinutePerEmail'

const useWelcomeToThreadLiveStepStyles = makeStyles((theme: Theme) => ({
  welcomeToThreadLiveRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 34,
    justifyContent: 'center',
    padding: 10,
  },
  helloHeaderRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  helloHeaderText: {
    fontSize: 34,
    fontWeight: 500,
  },
  threadLiveLogo: {
    height: 44,
    marginLeft: 10,
  },
  welcomeToThreadLiveContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  textLinesRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  subtitleText: {
    color: theme.threadPalette.menuItem.normal,
    fontSize: 26,
    textAlign: 'center',
  },
  infoText: {
    color: theme.threadPalette.menuItem.normal,
    fontSize: 18,
    textAlign: 'center',
  },
  buttonRoot: {
    paddingBlock: 6,
    paddingInline: 26,
  },
  buttonLabel: {
    fontSize: 16,
  },
  oneMinutePerEmailIcon: {
    height: 240,
  },
  thumbsUpIcon: {
    marginLeft: 6,
  },
}))

interface IWelcomeToThreadLiveStepProps {
  onSuccess(): void
}

export const WelcomeToThreadLiveStep = ({ onSuccess }: IWelcomeToThreadLiveStepProps) => {
  const classes = useWelcomeToThreadLiveStepStyles()

  const wavingHandIcon = (
    <span role="img" aria-label="Waving Hand">
      👋
    </span>
  )

  const thumbsUpIcon = (
    <span role="img" aria-label="Thumbs Up" className={classes.thumbsUpIcon}>
      👍
    </span>
  )

  return (
    <div className={classes.welcomeToThreadLiveRoot}>
      <div className={classes.helloHeaderRoot}>
        <Typography className={classes.helloHeaderText}>Hi {wavingHandIcon}, welcome to</Typography>
        <LogoWithThreadLiveName className={classes.threadLiveLogo} />
      </div>
      <section className={classes.welcomeToThreadLiveContent}>
        <div className={classes.textLinesRoot}>
          <Typography className={classes.subtitleText}>
            <strong>Organize email-driven work</strong>
          </Typography>
          <Typography className={classes.infoText}>
            A workspace for capturing, consolidating, & transmitting information.
          </Typography>
        </div>
        <OneMinutePerEmailIcon className={classes.oneMinutePerEmailIcon} />
        <div className={classes.textLinesRoot}>
          <Typography className={classes.infoText}>
            Information is <strong>Easy to Find</strong> & <strong>Easy to Share</strong>
          </Typography>
          <Typography className={classes.infoText}>
            Making your work is <strong>Easy to Manage</strong>
          </Typography>
        </div>
      </section>
      <Button
        variant="contained"
        color="primary"
        classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
        onClick={onSuccess}
      >
        What would be nice{thumbsUpIcon}
      </Button>
    </div>
  )
}
