import { debounce } from 'lodash'
import { IEmailThread } from 'model/emailThread'
import { getEmailThreads } from 'api/emailThread'
import { handleError } from 'actions/error'
import React from 'react'
import { errorHandlingWrapper } from 'api/errors/errorHandlingWrapper'

export interface ISelectedEmailThread {
  emailThreadId: string
  subject: string
}

export function useEmailThreadSearch(
  selectedEmailThreadId: string,
  onEmailThreadSelected: (emailThread?: ISelectedEmailThread) => void
) {
  const [searchQuery, setSearchQuery] = React.useState('')
  const [isLoading, setLoading] = React.useState(true)
  const [emailThreadsList, setEmailThreadsList] = React.useState<IEmailThread[]>([])
  const [nextPageToken, setNextPageToken] = React.useState('')

  const fetchEmailThreads = React.useCallback(async (searchQuery: string, nextPageToken: string) => {
    setLoading(true)
    const { data: { emailThreads = [], nextPageToken: updatedNextPageToken = '' } = {}, rawError } =
      await errorHandlingWrapper<{
        emailThreads: IEmailThread[]
        nextPageToken?: string
      }>(() => getEmailThreads({ searchQuery, nextPageToken }))
    setLoading(false)
    if (rawError) return handleError(rawError)

    const isSelectedEmailThreadInList = emailThreads.find(item => item.conversationId === selectedEmailThreadId)
    if (!isSelectedEmailThreadInList) {
      onEmailThreadSelected()
    }

    const isNewSearch = nextPageToken.length === 0
    setEmailThreadsList(prev => (isNewSearch ? emailThreads : [...prev, ...emailThreads]))
    setNextPageToken(updatedNextPageToken)
  }, [])

  const debouncedFetchEmailThreads = React.useCallback(debounce(fetchEmailThreads, 300), [fetchEmailThreads])

  React.useEffect(() => {
    debouncedFetchEmailThreads(searchQuery, nextPageToken)
  }, [searchQuery])

  const handleQueryChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNextPageToken('')
    const searchQuery = event.target.value
    setSearchQuery(searchQuery)
  }, [])

  const handleOnListItemClick = React.useCallback(
    (emailThreadId: string) => {
      const emailThread = emailThreadsList.find(el => el.conversationId === emailThreadId)
      if (!emailThread) return
      onEmailThreadSelected({ emailThreadId: emailThreadId, subject: emailThread.subject })
    },
    [onEmailThreadSelected, emailThreadsList]
  )

  const handleScrollEvent = React.useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const target = event.currentTarget
      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        debouncedFetchEmailThreads(searchQuery, nextPageToken)
      }
    },
    [debouncedFetchEmailThreads, nextPageToken, searchQuery]
  )

  const resetSearch = React.useCallback(() => {
    setNextPageToken('')
    setSearchQuery('')
  }, [])

  return {
    searchQuery,
    isLoading,
    emailThreadsList,
    handleQueryChange,
    handleOnListItemClick,
    handleScrollEvent,
    resetSearch,
  }
}
