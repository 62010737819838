import * as React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import classNames from 'classnames/dedupe'
import { makeStyles } from '@material-ui/styles'
import Skeleton, { SkeletonProps } from '@material-ui/lab/Skeleton'

const useWrapIntoSkeletonLoadingStyles = makeStyles<Theme, { backgroundColor: string }>((theme: Theme) => {
  return {
    defaultSkeleton: {
      borderColor: 'transparent !important',
      backgroundColor: ({ backgroundColor }) => `${backgroundColor} !important`,
      '&:before': {
        backgroundImage: 'unset !important',
      },
    },
  }
})

interface WrapIntoSkeletonLoadingProps extends SkeletonProps {
  children: React.ReactNode
  isLoading?: boolean
  className?: string
  backgroundColor?: string
}

const WrapIntoSkeletonLoading = ({
  children,
  isLoading = false,
  className = '',
  backgroundColor = 'rgb(0,0,0, 0.11)',
  ...skeletonProps
}: WrapIntoSkeletonLoadingProps) => {
  const classes = useWrapIntoSkeletonLoadingStyles({ backgroundColor })
  if (isLoading)
    return (
      <Skeleton className={classNames(classes.defaultSkeleton, className)} {...skeletonProps}>
        {children}
      </Skeleton>
    )

  return <>{children}</>
}

export default WrapIntoSkeletonLoading
