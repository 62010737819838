import React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles, createStyles } from '@material-ui/styles'
import { MessageRecipientsBlock } from '../messageComponents/messageRecipientsBlock'
import { MessageDeliveryStatusBar } from '../messageComponents/messageDeliveryStatusBar'
import { MessageContentPreview } from '../messageContent/messageContentPreview'
import { AttachmentCardSmall } from 'component/messageAttachment/attachmentCardSmall'
import { BaseMessage } from '../baseMessage'
import IAttachment from 'model/attachment'
import { useDisplayAttachmentContext } from 'contexts/displayAttachmentContext'
import { useTheme } from '@material-ui/core'
import { THeldMessageReason } from 'model/sharingGroup'
import { HeldReasonLabel } from './heldReasonLabel'

const useMessageFragmentStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageFragmentRoot: {
      width: '100%',
    },
    attachmentsListRoot: {
      display: 'flex',
      flexDirection: 'row',
      gap: 18,
    },
  })
)

interface IMessageFragmentProps {
  message: IMessage
  isLoading?: boolean
  currentUser: IUser
  onViewEntireMessage?(): void
  heldReason?: THeldMessageReason
}

export const MessageFragment = React.forwardRef<HTMLDivElement, IMessageFragmentProps>(
  ({ message, isLoading, currentUser, onViewEntireMessage, heldReason }, ref) => {
    const theme = useTheme()
    const classes = useMessageFragmentStyles()

    const { sender, attachments, deliveryStatus, createdAt } = message
    const isHeldMessage = !!heldReason

    const { displayAttachmentOnModal } = useDisplayAttachmentContext()

    const onOpenAttachment = (attachment: IAttachment) => {
      displayAttachmentOnModal({ attachment, threadId: message.threadId })
    }

    const isMessageWithError = deliveryStatus === 'error'
    const isCurrentUserMessageSender = sender.id === currentUser.id

    const heldReasonLabel = <HeldReasonLabel heldReason={heldReason} />
    const fromRecipientBlockColor = isHeldMessage ? theme.threadPalette.orange : undefined
    const recipientsBlock = (
      <MessageRecipientsBlock
        message={message}
        currentUser={currentUser}
        isMessageWithError={isMessageWithError}
        isLoading={isLoading}
        fromRecipientBlockColor={fromRecipientBlockColor}
        messageStatusComponent={heldReasonLabel}
      />
    )

    const messageContentBlock = <MessageContentPreview message={message} onViewEntireMessage={onViewEntireMessage} />

    const attachmentsBlock = (
      <div className={classes.attachmentsListRoot}>
        {attachments.map(attachment => (
          <AttachmentCardSmall
            attachment={attachment}
            key={attachment.id}
            onOpenAttachment={() => onOpenAttachment(attachment)}
          />
        ))}
      </div>
    )

    const footerBlock = (
      <MessageDeliveryStatusBar
        deliveryStatus={deliveryStatus}
        createdAt={createdAt}
        isCurrentUserMessageSender={isCurrentUserMessageSender}
      />
    )

    return (
      <div ref={ref} className={classes.messageFragmentRoot}>
        <BaseMessage
          recipientsBlock={recipientsBlock}
          messageContentBlock={messageContentBlock}
          attachmentsBlock={attachmentsBlock}
          footerBlock={footerBlock}
          isMessageWithError={isMessageWithError}
          isLoading={isLoading}
        />
      </div>
    )
  }
)
