import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useTabStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  divider: {
    marginTop: 35,
    marginBottom: 20,
    color: theme.threadPalette.lightGray,
  },

  indicatorColor: {
    backgroundColor: theme.threadPalette.darkerBlue,
    width: '100%',
  },

  tabPaper: {
    backgroundColor: theme.threadPalette.lighterGray,
    borderRadius: 0,
    height: 48,
  },

  tabPaperCompact: {
    borderRadius: 0,
    height: 48,
    width: 'fit-content',
  },

  tabsRoot: {
    borderBottom: `solid 1px ${theme.threadPalette.lightishGray}`,
  },

  tabItem: {
    fontWeight: 'bold',
    color: theme.threadPalette.lightGray,
    backgroundColor: theme.threadPalette.white,
    minWidth: 100,
    '& svg': {
      fill: theme.threadPalette.lightGray,
    },
  },

  tabItemSelected: {
    fontWeight: 'bold',
    color: theme.threadPalette.menuItem.current,
    '& svg': {
      fill: theme.threadPalette.menuItem.current,
    },
  },
  tabsColorsInherit: {
    opacity: 1,
  },

  tabContent: {
    display: 'flex',
    fontSize: 15,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
