import React from 'react'
import { Dialog } from '@material-ui/core'
import UserInfoConfirmationStep from './steps/userInfoConfirmationStep'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { UserOnboardingStepper } from './userOnboardingStepper/userOnboardingStepper'
import ConnectAnEmailStep from './steps/connectAnEmailStep'
import { activateCurrentUser } from 'api/users'
import { setUser } from 'actions/auth'
import { useDispatch } from 'react-redux'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { WelcomeToThreadLiveStep } from './steps/welcomeToThreadLiveStep'
import { ThreadDescriptionStep } from './steps/threadDescriptionStep'
import { UseThreadLiveStep } from './steps/useThreadLiveStep'
import { useUserAuthOnboardingContext } from 'contexts/userAuthOnboardingContext'
import Spacer from 'component/layoutUtils/spacer'
import { useThreadOnboardingTour } from 'hooks/onboardingTour/useThreadOnboardingTour'

export const useUserAuthOnboardingStyles = makeStyles((theme: Theme) => ({
  onboardingDialogRoot: {
    borderRadius: 8,
    maxWidth: 'unset',
  },
  userOnboardingRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    padding: 20,
  },
  userOnboardingStepContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '-webkit-fill-available',
  },
}))

interface IOnboardingStep {
  label?: string
  component: JSX.Element
  isStepperHidden?: boolean
}

export const UserAuthOnboarding = () => {
  const classes = useUserAuthOnboardingStyles()
  const dispatch = useDispatch()

  const {
    isAuthOnboardingOpen,
    currentOnboardingStep,
    openNextAuthOnboardingStep,
    pauseAuthOnboarding,
    closeAuthOnboarding,
  } = useUserAuthOnboardingContext()

  const { startThreadOnboardingTour } = useThreadOnboardingTour()

  const { currentThread, responseError } = useCurrentThreadContext()

  const finishOnboardingProcess = React.useCallback(async () => {
    const updatedUser = await activateCurrentUser()
    if (!updatedUser) return

    dispatch(setUser(updatedUser))
    closeAuthOnboarding()

    if (currentThread) startThreadOnboardingTour({ thread: currentThread, skipActivationCheck: true })
  }, [dispatch, activateCurrentUser, currentThread, startThreadOnboardingTour])

  const completeOrAdvanceThreadImporting = () => {
    if (currentThread) {
      finishOnboardingProcess()
      return
    }

    const hasResponseError = !!responseError
    hasResponseError ? pauseAuthOnboarding() : openNextAuthOnboardingStep()
  }

  const steps: Record<TUserAuthOnboardingStep, IOnboardingStep> = {
    confirmName: {
      label: 'Confirm your name',
      component: <UserInfoConfirmationStep onSuccess={openNextAuthOnboardingStep} />,
      isStepperHidden: true,
    },
    welcome: {
      component: <WelcomeToThreadLiveStep onSuccess={openNextAuthOnboardingStep} />,
      isStepperHidden: true,
    },
    threadDescription: {
      component: <ThreadDescriptionStep onSuccess={completeOrAdvanceThreadImporting} />,
      isStepperHidden: true,
    },
    threadCreation: {
      label: 'Follow an email',
      component: <ConnectAnEmailStep onSuccess={openNextAuthOnboardingStep} />,
    },
    threadCreationSuccess: {
      label: 'Use ThreadLive',
      component: <UseThreadLiveStep onSuccess={finishOnboardingProcess} />,
    },
  }
  const stepsList = Object.values(steps) as IOnboardingStep[]

  const stepsWithVisibleLabels = stepsList.filter(step => !!step.label)
  const labelsToDisplay = stepsWithVisibleLabels.map(step => step.label) as string[]
  const activeLabelIndex = stepsWithVisibleLabels.findIndex(step => step === steps[currentOnboardingStep])

  const currentStep = steps[currentOnboardingStep]
  const currentStepComponent = currentStep.component

  return (
    <Dialog open={isAuthOnboardingOpen} scroll="body" classes={{ paper: classes.onboardingDialogRoot }}>
      <div className={classes.userOnboardingRoot}>
        {!currentStep.isStepperHidden && (
          <>
            <Spacer height={20} />
            <UserOnboardingStepper activeStep={activeLabelIndex} steps={labelsToDisplay} />
          </>
        )}
        <div className={classes.userOnboardingStepContainer}>{currentStepComponent}</div>
      </div>
    </Dialog>
  )
}
