import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { IconButton, Theme } from '@material-ui/core'
import FilterIcon from '@material-ui/icons/FilterList'
import CloseIcon from '@material-ui/icons/Close'

const useFilterButtonStyles = makeStyles((theme: Theme) => ({
  button: {
    border: 'solid 2px transparent',
    borderRadius: '50%',
    padding: 4,
    '&:hover': {
      backgroundColor: theme.threadPalette.backgroundColor,
      borderColor: theme.palette.primary.main,
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  buttonSelected: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& svg': {
        color: theme.threadPalette.backgroundColor,
      },
    },
  },
  disableCursor: {
    cursor: 'unset',
  },
  icon: {
    color: theme.threadPalette.lightBlue,
    fontSize: 12,
  },
  iconSelected: {
    color: theme.threadPalette.backgroundColor,
  },
}))

interface IFilterButtonProps {
  isSelected: boolean
  onClick?(event: React.MouseEvent): void
  classes?: {
    button?: string
    buttonSelected?: string
    icon?: string
    iconSelected?: string
  }
  keepIconWhenSelected?: boolean
}

const FilterButton = ({ isSelected, onClick, classes = {}, keepIconWhenSelected = false }: IFilterButtonProps) => {
  const defaultClasses = useFilterButtonStyles()

  const {
    button: buttonClass = '',
    buttonSelected: buttonSelectedClass = '',
    icon: iconClass = '',
    iconSelected: iconSelectedClass = '',
  } = classes

  const Icon = isSelected && !keepIconWhenSelected ? CloseIcon : FilterIcon

  return (
    <IconButton
      size="small"
      classes={{
        root: classNames(defaultClasses.button, buttonClass, {
          [defaultClasses.buttonSelected]: isSelected,
          [buttonSelectedClass]: isSelected,
          [defaultClasses.disableCursor]: !onClick,
        }),
      }}
      disableRipple
      disableFocusRipple
      onClick={onClick}
    >
      <Icon
        classes={{
          root: classNames(defaultClasses.icon, iconClass, {
            [defaultClasses.iconSelected]: isSelected,
            [iconSelectedClass]: isSelected,
          }),
        }}
      />
    </IconButton>
  )
}

export default FilterButton
