import { useMutation } from '@tanstack/react-query'
import { createOrOverwriteThreadSharingSettings } from 'api/v2/sharingGroup'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'actions/snackbar'
import { useThreadSharingSettingsCacheActions } from 'hooks/useSharingGroup/useThreadSharingSettingsCacheActions'
import { IThreadSharingSettings } from 'model/sharingGroup'

interface IUseCreateOrEditThreadSharingSettingsUseCaseProps {
  onSuccess?(): void
}

export const useCreateOrEditThreadSharingSettingsUseCase = ({
  onSuccess,
}: IUseCreateOrEditThreadSharingSettingsUseCaseProps = {}) => {
  const dispatch = useDispatch()

  const threadSharingSettingsCacheActions = useThreadSharingSettingsCacheActions()

  const {
    reset: resetCreateOrOverwriteThreadSharingSettingsMutation,
    mutateAsync,
    isLoading,
  } = useMutation(createOrOverwriteThreadSharingSettings, {
    onMutate: ({ sharingGroupId, threadId, ...sharingProps }) => {
      const updateThreadSharingSettings = (threadSharingSettings: IThreadSharingSettings) => {
        return { ...threadSharingSettings, ...sharingProps }
      }
      const rollbackOptimisticUpdates = threadSharingSettingsCacheActions.updateData({
        sharingGroupId,
        threadId,
        threadSharingSettingsModifier: updateThreadSharingSettings,
      })
      return rollbackOptimisticUpdates
    },
    onError: (_, __, rollbackOptimisticUpdates) => {
      rollbackOptimisticUpdates?.()
      dispatch(showSnackbar({ message: 'Error saving thread sharing settings' }))
    },
    onSuccess: onSuccess,
    onSettled: (_, __, { threadId }) => {
      threadSharingSettingsCacheActions.refreshData(threadId)
      resetCreateOrOverwriteThreadSharingSettingsMutation()
    },
  })

  const createOrEditThreadSharingSettingsUseCase: typeof createOrOverwriteThreadSharingSettings = mutateAsync

  return { createOrEditThreadSharingSettingsUseCase, isLoading }
}
