import * as React from 'react'
import SyncIcon from '@material-ui/icons/Sync'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles, createStyles } from '@material-ui/styles'
import AppLoading from 'component/appLoading'
import { Typography } from '@material-ui/core'

const useMessageReimportActionStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageReimportActionRoot: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      userSelect: 'none',
    },
    syncIcon: {
      fill: theme.palette.primary.main,
      height: '16px',
      width: '16px',
    },
    messageActionText: {
      color: theme.palette.primary.main,
      fontSize: '12px',
      marginLeft: '4px',
      '&:hover': {
        fontWeight: 'bold',
      },
    },
    iconWrapper: {
      minWidth: '18px',
    },
  })
)

interface IMessageReimportActionProps {
  onMessageReimport(): Promise<void>
}

export const MessageReimportAction = ({ onMessageReimport }: IMessageReimportActionProps) => {
  const classes = useMessageReimportActionStyles()

  const [isLoading, setIsLoading] = React.useState(false)

  const onActionClick = React.useCallback(async () => {
    if (isLoading) return

    setIsLoading(true)
    await onMessageReimport()
    setIsLoading(false)
  }, [onMessageReimport, isLoading])

  return (
    <div className={classes.messageReimportActionRoot} onClick={onActionClick}>
      <div className={classes.iconWrapper}>
        {!isLoading && <SyncIcon className={classes.syncIcon} />}
        {<AppLoading visible={isLoading} small legWidth={6} legHeight={16} />}
      </div>
      <Typography className={classes.messageActionText}>Re-import message</Typography>
    </div>
  )
}
