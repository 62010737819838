import { AnyAction } from 'redux'

import { LEFT_SIDEBAR_ACTIONS } from '../constants/actions/leftSidebar'

const DEFAULT_STATE: ILeftSidebarState = {
  optionSelected: 'threads',
}

export const leftSidebarReducer = (state = DEFAULT_STATE, action: AnyAction) => {
  switch (action.type) {
    case LEFT_SIDEBAR_ACTIONS.SET_OPTION: {
      return {
        ...state,
        optionSelected: action.optionSelected,
      }
    }

    default:
      return state
  }
}
