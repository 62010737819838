import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { EmojiClickData } from 'emoji-picker-react'
import AttachmentIcon from 'icons/attachment'
import React from 'react'
import AttachmentPicker from 'component/attachmentPicker'
import BoldIcon from './buttons/bold'
import BulletListIcon from './buttons/bulletList'
import CleanIcon from './buttons/clean'
import ToolbarEmojiPicker from './buttons/toolbarEmojiPicker'
import ItalicIcon from './buttons/italic'
import LinkIcon from './buttons/link'
import MentionsIcon from './buttons/mentions'
import NumberedListIcon from './buttons/numberedList'
import RedoIcon from './buttons/redo'
import StrikeIcon from './buttons/strike'
import UnderlineIcon from './buttons/underline'
import UndoIcon from './buttons/undo'

const useToolbarStyles = makeStyles((theme: Theme) => ({
  toolbarWrapper: {
    alignItems: 'flex-end',
    display: 'inline-flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexWrap: 'wrap',
    marginLeft: 4,

    '&.ql-toolbar.ql-snow': {
      border: 'none',
      padding: 0,

      '& .ql-color-picker': {
        padding: 0,
        marginLeft: '10px !important',
      },
    },
    '& .ql-color-picker .ql-picker-label': {
      padding: 0,
    },
    '& .ql-picker.ql-expanded .ql-picker-options': {
      top: 'auto',
      bottom: '100%',
    },
  },
  toolbarActionButton: {
    cursor: 'pointer',
    height: 20,
    padding: '0!important',
    width: 20,
  },
  svgIcon: {
    height: 'inherit',
    width: 'inherit',
  },
  leftSpaced: {
    marginLeft: 24,
  },
  smallLeftSpaced: {
    marginLeft: 10,
  },
}))
interface IToolbarProps {
  id: string
  onEmojiSelected?(emoji: string): void
  onUploadAttachments?(files: File[]): Promise<void>
  onMentionsClick?(): void
  onUndo?(): void
  onRedo?(): void
}

const Toolbar = ({ id, onEmojiSelected, onUploadAttachments, onMentionsClick, onUndo, onRedo }: IToolbarProps) => {
  const classes = useToolbarStyles()

  const onEmojiClick = (emojiObject: EmojiClickData) => {
    onEmojiSelected?.(emojiObject.emoji)
  }

  return (
    <div id={id} className={classes.toolbarWrapper}>
      {onMentionsClick && (
        <div className={classNames('ql-mention', classes.toolbarActionButton)}>
          <MentionsIcon onClick={onMentionsClick} />
        </div>
      )}

      {onEmojiClick && (
        <ToolbarEmojiPicker
          className={classes.svgIcon}
          onEmojiClick={onEmojiClick}
          wrapperClassName={classNames(classes.toolbarActionButton, classes.leftSpaced)}
        />
      )}

      {onUploadAttachments && (
        <AttachmentPicker
          uploadAttachments={onUploadAttachments}
          className={classNames('ql-attachment-picker', classes.toolbarActionButton, classes.leftSpaced)}
        >
          <AttachmentIcon className={classes.svgIcon} />
        </AttachmentPicker>
      )}

      <button className={classNames('ql-link', classes.toolbarActionButton, classes.leftSpaced)}>
        <LinkIcon className={classes.svgIcon} />
      </button>

      <span className="ql-formats">
        {onUndo && (
          <button onClick={onUndo} className={classNames(classes.toolbarActionButton, classes.leftSpaced)}>
            <UndoIcon className={classes.svgIcon} />
          </button>
        )}

        {onRedo && (
          <button onClick={onRedo} className={classNames(classes.toolbarActionButton)}>
            <RedoIcon className={classes.svgIcon} />
          </button>
        )}
      </span>

      <select className={classNames('ql-header', classes.toolbarActionButton)} />

      <div className={'ql-formats'}>
        <button className={classNames('ql-list', classes.toolbarActionButton, classes.leftSpaced)} value="bullet">
          <BulletListIcon className={classes.svgIcon} />
        </button>

        <button className={classNames('ql-list', classes.toolbarActionButton, classes.smallLeftSpaced)} value="ordered">
          <NumberedListIcon className={classes.svgIcon} />
        </button>

        <button className={classNames('ql-bold', classes.toolbarActionButton, classes.smallLeftSpaced)}>
          <BoldIcon className={classes.svgIcon} />
        </button>

        <button className={classNames('ql-italic', classes.toolbarActionButton, classes.smallLeftSpaced)}>
          <ItalicIcon className={classes.svgIcon} />
        </button>

        <button className={classNames('ql-underline', classes.toolbarActionButton, classes.smallLeftSpaced)}>
          <UnderlineIcon className={classes.svgIcon} />
        </button>

        <button className={classNames('ql-strike', classes.toolbarActionButton, classes.smallLeftSpaced)}>
          <StrikeIcon className={classes.svgIcon} />
        </button>

        <select className={classNames('ql-color', classes.toolbarActionButton)}></select>

        <select className={classNames('ql-background', classes.toolbarActionButton)}></select>

        <button className={classNames('ql-clean', classes.toolbarActionButton, classes.leftSpaced)}>
          <CleanIcon />
        </button>
      </div>
    </div>
  )
}

export default Toolbar
