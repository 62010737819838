import React from 'react'
import { Button, Theme, makeStyles } from '@material-ui/core'
import { IOnboardingTooltipProps } from 'contexts/useOnboardingTourContext'

const useOnboardingTooltipStyles = makeStyles<Theme, { showSkipButton: boolean; showBackButton: boolean }>(
  (theme: Theme) => ({
    onboardingTooltipRoot: {
      alignItems: 'center',
      backgroundColor: theme.threadPalette.white,
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      gap: 26,
      width: 'fit-content',
    },
    controlButtonsRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      width: '100%',
    },
    footerButtonsRoot: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: ({ showBackButton, showSkipButton }) => {
        if (showBackButton && showSkipButton) return 'space-between'
        if (showBackButton) return 'flex-start'
        if (showSkipButton) return 'flex-end'
        return 'center'
      },
      paddingBottom: 10,
      paddingInline: 10,
      width: '100%',
    },
    nextStepButtonLabel: {
      paddingInline: 10,
    },
    footerButtonLabel: {
      color: theme.threadPalette.lightGray,
      fontSize: 12,
      fontWeight: 'normal',
    },
    contentRoot: {
      paddingInline: 20,
      paddingTop: 20,
    },
  })
)

export const OnboardingTooltip = ({
  tooltipProps: { ref },
  backProps: previousStepButtonProps,
  primaryProps: nextStepButtonProps,
  skipProps: skipButtonProps,
  step: { content, showProgress, hideSkipButton, onNextStepButtonClick: customOnNextStepButtonClick },
  size: stepCount,
  index: stepIndex,
}: IOnboardingTooltipProps) => {
  const showBackButton = stepIndex !== 0
  const showSkipButton = !hideSkipButton && stepIndex !== stepCount - 1

  const classes = useOnboardingTooltipStyles({ showSkipButton, showBackButton })

  const { title: nextStepButtonLabel, onClick: defaultOnNextStepButtonClick } = nextStepButtonProps
  const { title: previousStepButtonLabel, onClick: onPreviousStepButtonClick } = previousStepButtonProps
  const { title: skipButtonLabel, onClick: onSkipButtonClick } = skipButtonProps

  const progressLabel = showProgress ? `(${stepIndex + 1}/${stepCount})` : ''
  const nextStepButtonLabelWithProgress = `${nextStepButtonLabel} ${progressLabel}`

  const onNextStepButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    defaultOnNextStepButtonClick(event)
    customOnNextStepButtonClick?.()
  }

  return (
    <div className={classes.onboardingTooltipRoot} ref={ref}>
      <div className={classes.contentRoot}>{content}</div>
      <div className={classes.controlButtonsRoot}>
        <Button
          classes={{ label: classes.nextStepButtonLabel }}
          onClick={onNextStepButtonClick}
          variant="contained"
          color="primary"
        >
          {nextStepButtonLabelWithProgress}
        </Button>
        <div className={classes.footerButtonsRoot}>
          {showBackButton && (
            <Button classes={{ label: classes.footerButtonLabel }} onClick={onPreviousStepButtonClick}>
              {previousStepButtonLabel}
            </Button>
          )}
          {showSkipButton && (
            <Button classes={{ label: classes.footerButtonLabel }} onClick={onSkipButtonClick}>
              {skipButtonLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
