import React from 'react'
import { fade, InputBase, Theme, makeStyles, Paper } from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { useUserSharingGroups } from 'hooks/useSharingGroup/useUserSharingGroups'
import { ISharingGroup } from 'model/sharingGroup'
import SharingGroupOptionTile from './sharingGroupOptionTile'

const useSelectOrCreateSharingGroupInputStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    border: `1px solid ${fade(theme.palette.primary.light, 0.7)}`,
    borderRadius: 4,
    fontSize: 16,
    width: '100%',
  },
  input: {
    paddingBlock: 10,
    paddingInline: 8,
    '&::placeholder': {
      color: theme.threadPalette.menuItem.current,
      fontSize: 16,
      opacity: 0.7,
      fontStyle: 'italic',
    },
  },
  focusedInput: {
    borderColor: theme.palette.primary.main,
  },
  optionsBlock: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    marginBlock: 2,
  },
  optionsList: {
    paddingBlock: 0,
  },
  optionTile: {
    paddingBlock: 4,
    '&[data-focus=true]': {
      backgroundColor: theme.threadPalette.lighterGray,
    },
  },
  newGroupButtonContainer: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 16,
    paddingBlock: 8,
    paddingInline: 12,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.threadPalette.lighterGray,
    },
  },
  newGroupPreviewName: {
    color: theme.threadPalette.brownishGray,
    fontWeight: 500,
    marginLeft: 8,
  },
}))

interface ISelectOrCreateSharingGroupInputProps {
  sharingGroupsToRemoveFromOptions?: ISharingGroup[]
  onSelectSharingGroup?(sharingGroup: ISharingGroup): void
  onCreateNewSharingGroup?(sharingGroupName: string): void
  onEditSharingGroup?(sharingGroup: ISharingGroup): void
  autoFocus?: boolean
}

export const SelectOrCreateSharingGroupInput = ({
  onSelectSharingGroup,
  onCreateNewSharingGroup,
  onEditSharingGroup,
  sharingGroupsToRemoveFromOptions = [],
  autoFocus = false,
}: ISelectOrCreateSharingGroupInputProps) => {
  const classes = useSelectOrCreateSharingGroupInputStyles()
  const { userSharingGroups } = useUserSharingGroups()

  const [sharingGroupInputValue, setSharingGroupNameInputValue] = React.useState<string>('')
  const [highlightedSharingGroupId, setHighlightedSharingGroupId] = React.useState<string | null>(null)

  const onChangeSharingGroupName = (_: unknown, value: string) => setSharingGroupNameInputValue(value)

  const onSharingGroupHighlighted = (_: unknown, sharingGroup: ISharingGroup | null) => {
    const newHighlightedSharingGroupId = sharingGroup?.id ?? null

    if (highlightedSharingGroupId !== newHighlightedSharingGroupId)
      setHighlightedSharingGroupId(newHighlightedSharingGroupId)
  }

  const onSharingGroupSelected = (_: unknown, value: ISharingGroup | null) => {
    if (!value) return
    onSelectSharingGroup?.(value)
  }

  const onClickNewGroup = (event: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    onCreateNewSharingGroup?.(sharingGroupInputValue)
  }

  const newGroupButton = (
    <div className={classes.newGroupButtonContainer} onMouseDown={onClickNewGroup} onTouchEnd={onClickNewGroup}>
      + New Group <span className={classes.newGroupPreviewName}>{sharingGroupInputValue}</span>
    </div>
  )
  const sharingGroupsIdsToRemoveFromOptions = sharingGroupsToRemoveFromOptions.map(sharingGroup => sharingGroup.id)
  const sharingGroupsOptions = userSharingGroups.filter(
    sharingGroup => !sharingGroupsIdsToRemoveFromOptions.includes(sharingGroup.id)
  )

  const isThereASharingGroupWithSameInputName = userSharingGroups.some(
    sharingGroup => sharingGroup.name === sharingGroupInputValue.trim()
  )
  const shouldShowCreateSharingGroup = !isThereASharingGroupWithSameInputName

  const PaperComponentWithNewGroupButton = (options: React.HTMLAttributes<HTMLElement>) => {
    const { children, ...containerProps } = options

    return (
      <Paper {...containerProps}>
        {shouldShowCreateSharingGroup && newGroupButton}
        {children}
      </Paper>
    )
  }

  const filterSharingGroupsMatchingInputValue = createFilterOptions({
    stringify: (option: ISharingGroup) => option.name,
    trim: true,
  })

  return (
    <Autocomplete
      id="select-or-create-sharing-group-input"
      size="small"
      classes={{ paper: classes.optionsBlock, listbox: classes.optionsList, option: classes.optionTile }}
      defaultValue={null}
      options={sharingGroupsOptions}
      autoComplete
      autoHighlight={false}
      autoSelect={false}
      disablePortal
      fullWidth
      openOnFocus
      clearOnBlur={false}
      PaperComponent={PaperComponentWithNewGroupButton}
      onChange={onSharingGroupSelected}
      onInputChange={onChangeSharingGroupName}
      getOptionLabel={sharingGroup => sharingGroup.name}
      filterOptions={filterSharingGroupsMatchingInputValue}
      renderOption={sharingGroup => (
        <SharingGroupOptionTile
          sharingGroup={sharingGroup}
          isHighlighted={sharingGroup.id === highlightedSharingGroupId}
          onClickEdit={() => onEditSharingGroup?.(sharingGroup)}
        />
      )}
      noOptionsText={'No Sharing Groups found with this term'}
      onHighlightChange={onSharingGroupHighlighted}
      renderInput={params => (
        <InputBase
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          classes={{ root: classes.inputRoot, input: classes.input, focused: classes.focusedInput }}
          placeholder="Add a group"
          autoFocus={autoFocus}
          fullWidth
        />
      )}
    />
  )
}
