import * as React from 'react'
import type { QueryTypeFilter } from '@tanstack/query-core/src/utils'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { getProjectByIdQueryKey, getProjectsListQueryKey, getProjectsQueryKey } from './queryKeys'
import { IProject } from 'model/project'
import { useUser } from 'hooks/auth'
import { PaginatedResponse } from 'api/pagedResponse'

export const useProjectActions = () => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const refreshProjectsList = React.useCallback(() => {
    if (!currentUser?.id) return

    const queryKey = getProjectsListQueryKey({ userId: currentUser.id })
    queryClient.invalidateQueries({ queryKey: queryKey })
  }, [queryClient, currentUser?.id])

  const refreshProject = React.useCallback(
    (projectId: string) => {
      if (!currentUser?.id) return
      queryClient.invalidateQueries({ queryKey: getProjectByIdQueryKey({ userId: currentUser.id, projectId }) })
    },
    [queryClient, currentUser?.id]
  )

  const getProjectsList = React.useCallback(
    (queryState: QueryTypeFilter = 'active') => {
      if (!currentUser?.id) return []

      const projectsListCache = queryClient.getQueriesData<InfiniteData<PaginatedResponse<IProject[]>>>({
        queryKey: getProjectsListQueryKey({ userId: currentUser.id }),
        type: queryState,
      })

      const projectsData = projectsListCache.map(data => data[1]).filter(projectData => projectData !== undefined)

      const projects = projectsData.flatMap(projectData => projectData.pages).flatMap(projectPage => projectPage.data)

      return projects
    },
    [queryClient, currentUser?.id]
  )

  const getProjectsDetails = React.useCallback(
    (queryState: QueryTypeFilter = 'active') => {
      if (!currentUser?.id) return []

      const projectsData = queryClient.getQueriesData<IProject>({
        queryKey: getProjectsQueryKey(currentUser.id),
        type: queryState,
      })

      const projects = projectsData.map(data => data[1]).filter(project => project !== undefined) as IProject[]

      return projects
    },
    [queryClient, currentUser?.id]
  )

  const refreshThreadProjectsList = (threadId: string, queryState?: QueryTypeFilter) => {
    const projects = getProjectsList(queryState)
    projects.forEach(project => {
      const projectThreadIds = project?.threads?.map(thread => thread.id) ?? []
      if (projectThreadIds.includes(threadId)) refreshProject(project.id)
    })
  }

  const refreshThreadProjectsDetails = (threadId: string, queryState?: QueryTypeFilter) => {
    const projects = getProjectsDetails(queryState)
    projects.forEach(project => {
      const projectThreadIds = project?.threads?.map(thread => thread.id) ?? []
      if (projectThreadIds.includes(threadId)) refreshProject(project.id)
    })
  }

  return {
    refreshProjectsList,
    refreshProject,
    getProjectsList,
    refreshThreadProjectsList,
    refreshThreadProjectsDetails,
  }
}
