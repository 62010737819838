import { apiClient } from '@jetkit/react'
import { TAttachmentAnalyticsAction } from 'model/analytics'

const BASE_URI = '/v2/analytics'

export const sendAttachmentAnalyticsEvent = async ({
  attachmentId,
  action,
  threadId,
}: {
  attachmentId: string
  action: TAttachmentAnalyticsAction
  threadId?: string
}): Promise<void> => await apiClient.post(`${BASE_URI}/attachments/${attachmentId}`, { action, threadId })
