import * as React from 'react'
import classnames from 'classnames'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles, createStyles } from '@material-ui/styles'
import { PreviousMessageContent } from './previousMessageContent/previousMessageContent'
import EmailMessageContent from 'component/emailMessageContent'
import IAttachment from 'model/attachment'

const useMessageContentWithPreviousMessageStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageContent: {
      color: theme.threadPalette.menuItem.current,
      fontSize: 15,
      lineHeight: '20px',
      marginLeft: 19,
      marginRight: 8,
      textOverflow: 'ellipsis',
    },
    messageContentWithTable: {
      overflow: 'hidden',
      overflowX: 'scroll',
      overscrollBehaviorX: 'contain',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-scrollbar:horizontal': {
        height: 11,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        border: '2px solid white',
        backgroundColor: 'rgba(0, 0, 0, .5)',
      },
    },
  })
)

export interface IMessageContentWithPreviousMessageProps {
  message: IMessage
  isMessageWithTable?: boolean
  isPreviousMessageContentExpanded?: boolean
  isInlineAttachmentPreviewEnabled?: boolean
  onOpenAttachment?(attachment: IAttachment): void
}

const MessageContentWithPreviousMessage = ({
  message,
  isMessageWithTable = false,
  isPreviousMessageContentExpanded = false,
  isInlineAttachmentPreviewEnabled = true,
  onOpenAttachment,
}: IMessageContentWithPreviousMessageProps) => {
  const classes = useMessageContentWithPreviousMessageStyles()

  const messageContentClassName = classnames(classes.messageContent, {
    [classes.messageContentWithTable]: isMessageWithTable,
  })

  return (
    <div className={messageContentClassName}>
      <EmailMessageContent
        content={message.content}
        inlineAttachments={message.inlineAttachments}
        contentInlineAttachments={message.contentInlineAttachments}
        mentionedUsers={message.mentionedUsers}
        isInlineAttachmentPreviewEnabled={isInlineAttachmentPreviewEnabled}
        onOpenAttachment={onOpenAttachment}
      />
      <PreviousMessageContent
        message={message}
        hasTableInside={isMessageWithTable}
        isPreviousMessageContentExpanded={isPreviousMessageContentExpanded}
        isInlineAttachmentPreviewEnabled={isInlineAttachmentPreviewEnabled}
        onOpenAttachment={onOpenAttachment}
      />
    </div>
  )
}

export default MessageContentWithPreviousMessage
