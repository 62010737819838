import * as React from 'react'
import { SvgIcon } from '@material-ui/core'

interface IBoldIconProps extends JSX.IntrinsicAttributes {
  className?: string
}

const BoldIcon = ({ className }: IBoldIconProps) => {
  return (
    <SvgIcon className={className} viewBox="-2 -3 18 18">
      <path
        fill={'#606060'}
        fillRule="evenodd"
        d="M9.901 6.388c.973-.613 1.592-1.663 1.592-2.888C11.493 1.575 9.9 0 7.957 0H0v.875h.884c.53 0 .884.35.884.875v10.5c0 .525-.354.875-.884.875H0V14h8.399c2.21 0 3.978-1.75 3.978-3.938C12.377 8.4 11.316 7 9.9 6.389zM6.188.875c1.503 0 2.653 1.138 2.653 2.625S7.69 6.125 6.188 6.125H4.42V.875h1.768zm.442 12.25H4.42V7h2.21c1.68 0 3.095 1.4 3.095 3.063 0 1.662-1.415 3.062-3.095 3.062z"
      />
    </SvgIcon>
  )
}

export default BoldIcon
