import * as React from 'react'
import WSClient, { WSEvent } from './WSClient'

// singleton
let client: WSClient

interface IUseWebSocketClientArgs {
  onEvent?: (evt: WSEvent) => void
}

const useWebSocketClient = ({ onEvent }: IUseWebSocketClientArgs) => {
  React.useEffect(() => {
    if (!process.env.REACT_APP_WEBSOCKETS_ENABLED) return
    if (!client) client = new WSClient()

    // listen for events
    if (onEvent) client.addCallback(onEvent)

    // ensure client is connected
    client.open()

    // cleanup
    return () => {
      if (onEvent) client.removeCallback(onEvent)
    }
  })
  return { client }
}

export default useWebSocketClient
