import { IMention } from 'model/mention'
import { getShortenedName } from 'dataProcessors/utils'
import { useUser } from 'hooks'
import React from 'react'
import { useStyles } from './emailMessageContent.style'
import parse from 'node-html-parser'
import IAttachment, { IInlineAttachment } from 'model/attachment'

const ACTION_TYPE_PARAM_NAME = 'action-type'

interface IUseEmailMessageContentWrappersProps {
  mentionedUsers: IMention[]
  inlineAttachments?: IInlineAttachment[]
  onOpenAttachment?(attachment: IAttachment): void
}

export function useEmailMessageContentWrappers({
  mentionedUsers,
  inlineAttachments,
  onOpenAttachment,
}: IUseEmailMessageContentWrappersProps) {
  const classes = useStyles()
  const currentUser = useUser()

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const openContactPage = async (mention: IMention) => {}

  const onActionClicked = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const attributes = (event.target as HTMLInputElement).attributes
      const actionType = attributes.getNamedItem(ACTION_TYPE_PARAM_NAME)?.value

      if (actionType === 'mention') {
        const mentionId = attributes.getNamedItem('data-string')?.value

        if (!mentionId || mentionId === currentUser?.id) return

        const mention = mentionedUsers.find(item => item.id === mentionId)

        if (!mention) return

        openContactPage(mention)
      } else if (actionType === 'inline-attachment') {
        const inlineAttachmentContentId = attributes.getNamedItem('data-custom')?.value

        const inlineAttachmentClicked = inlineAttachments?.find(
          messageInlineAttachment => messageInlineAttachment.contentId === inlineAttachmentContentId
        )

        if (!inlineAttachmentClicked) return
        onOpenAttachment?.(inlineAttachmentClicked)
      }
    },
    [mentionedUsers, openContactPage, inlineAttachments, onOpenAttachment]
  )

  const wrapMentions = React.useCallback(
    (msgContent: string) => {
      let result = msgContent
      mentionedUsers.forEach(mention => {
        result = result.replace(
          `</span>${mention.name}</span>`,
          `</span><b action-type='mention' data-string=${mention.id} class=${classes.mention}>${getShortenedName(
            mention.name
          )}</b></span>`
        )
      })
      return result
    },
    [classes.mention, mentionedUsers]
  )

  const wrapInlineAttachments = React.useCallback(
    (messageContent: string) => {
      if (!inlineAttachments?.length) return messageContent

      const root = parse(messageContent)

      const imageTags = root.getElementsByTagName('img')

      imageTags.forEach(image => {
        const inlineAttachmentContentId = image?.attributes?.['data-custom']
        if (!inlineAttachmentContentId) return

        image.classList.add(classes.inlineAttachment)
        image.setAttribute(ACTION_TYPE_PARAM_NAME, 'inline-attachment')
      })
      return root.toString()
    },
    [classes.inlineAttachment, inlineAttachments]
  )

  return {
    onActionClicked,
    wrapMentions,
    wrapInlineAttachments,
  }
}
