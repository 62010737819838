import React from 'react'
import { makeStyles, Popover, Theme } from '@material-ui/core'
import { PopoverProps } from 'material-ui'
import { PeopleListBlock } from 'component/peopleListBlock/peopleListBlock'
import { ISharingGroupMember } from 'model/sharingGroup'

interface ISharingGroupMembersPopupProps extends Omit<PopoverProps, 'anchorEl'> {
  isOpen: boolean
  anchorEl: HTMLElement | (() => HTMLElement) | null
  onClose?(): void
  members: ISharingGroupMember[]
}

const useSharingGroupMembersPopupStyles = makeStyles((theme: Theme) => {
  return {
    membersListModal: {
      borderRadius: 11,
      width: 380,
      maxHeight: 400,
    },
    blockHeader: {
      backgroundColor: theme.threadPalette.darkBlue,
      color: theme.threadPalette.white,
    },
  }
})

export const SharingGroupMembersPopup = ({ members, isOpen, ...props }: ISharingGroupMembersPopupProps) => {
  const classes = useSharingGroupMembersPopupStyles()

  return (
    <Popover
      open={isOpen}
      classes={{ paper: classes.membersListModal }}
      {...props}
      elevation={8}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <PeopleListBlock
        headerClassName={classes.blockHeader}
        people={members}
        headerTitle="Sharing group members"
        addDivider
      />
    </Popover>
  )
}
