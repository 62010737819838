import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Theme, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import AppLoading from './appLoading'

const useAddButtonStyles = makeStyles<Theme, { size: number }>((theme: Theme) => ({
  addButtonRoot: {
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
  },
  addIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: ({ size }) => size,
    transition: 'fontSize 0.2s',
    '&:hover': {
      fontSize: ({ size }) => 1.1 * size,
    },
  },
}))

interface AddButtonProps {
  onClick?(): void
  className?: string
  size?: number
  isLoading?: boolean
}
export const AddButton = React.forwardRef<HTMLDivElement, AddButtonProps>(
  ({ onClick, className = '', size = 20, isLoading = false }, ref) => {
    const classes = useAddButtonStyles({ size })

    return (
      <div ref={ref} onClick={onClick} className={classNames(classes.addButtonRoot, className)}>
        {isLoading && (
          <div>
            <AppLoading visible small padding={{ inline: 32 }} />
          </div>
        )}
        {!isLoading && <AddIcon className={classes.addIcon} />}
      </div>
    )
  }
)
