import * as React from 'react'
import { IOrganizationMember } from 'model/organization'

export function useFilteredMembers(members: IOrganizationMember[]) {
  const [searchQuery, setSearchQuery] = React.useState('')
  const [filteredMembers, setFilteredMembers] = React.useState<IOrganizationMember[]>(members)

  React.useEffect(() => {
    setFilteredMembers(
      members.filter(item => item.user.name?.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()))
    )
  }, [searchQuery, members])

  const handleSearchQueryChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const val = event.target.value
      setSearchQuery(val)
    },
    []
  )

  return {
    handleSearchQueryChange,
    filteredMembers,
  }
}
