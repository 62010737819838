import { apiClient } from '@jetkit/react'
import { AxiosResponse } from 'axios'

const PREFIX = '/v2/llm'

export const generateEmailContent = async ({
  goalWithEmail,
  contextAboutReceiver,
  formality,
  limitBy,
  limit,
}: {
  goalWithEmail: string
  contextAboutReceiver: string
  formality: LLMFormality
  limitBy: LimitContentBy
  limit: number
}): Promise<string> => {
  const formalityParsed = formality.replaceAll(' ', '_')
  const result = await apiClient.post<unknown, AxiosResponse<string>>(`${PREFIX}/email`, {
    goal_with_email: goalWithEmail,
    context_about_receiver: contextAboutReceiver,
    formality: formalityParsed,
    limit_content_by: limitBy,
    limit_maximum_count: limit,
  })
  return result.data
}
