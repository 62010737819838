import storage from 'redux-persist/lib/storage'
import { AnyAction } from 'redux'
import { USER_SETTINGS_ACTIONS } from 'constants/actions/userSettings'

const DEFAULT_STATE: IUserSettings = {
  isBrowserSecurityWarningSilenced: false,
}

export const userSettingsPersistConfig = {
  key: 'userSettings',
  storage: storage,
}

export const silenceBrowserSecurityWarning = (): TThunk => {
  return (dispatch, _) => {
    dispatch({
      type: USER_SETTINGS_ACTIONS.SILENCE_BROWSER_SECURITY_WARNING,
    })
  }
}

export const userSettingsReducer = (state = DEFAULT_STATE, action: AnyAction) => {
  switch (action.type) {
    case USER_SETTINGS_ACTIONS.SILENCE_BROWSER_SECURITY_WARNING: {
      return {
        ...state,
        isBrowserSecurityWarningSilenced: true,
      }
    }
    default:
      return state
  }
}
