import React from 'react'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { ConnectThreadToProjectModal } from 'component/connectThreadToProjectModal.tsx/connectThreadToProjectModal'
import { AddButton } from 'component/addButton'

interface IAddIntegrationProps {
  id?: string
  className?: string
}

function AddIntegration({ id, className }: IAddIntegrationProps) {
  const { currentThread } = useCurrentThreadContext()

  const {
    isOpen: isConnectProjectDialogOpen,
    dialogAnchorElement: addIntegrationAnchorButton,
    openDialog: openConnectProjectDialog,
    handleCloseDialog: closeConnectProjectDialog,
    setDialogAnchorRef: addIntegrationButtonRef,
  } = useDialog()

  if (!currentThread) return null

  return (
    <>
      <span id={id}>
        <AddButton ref={addIntegrationButtonRef} onClick={openConnectProjectDialog} className={className} />
      </span>
      <ConnectThreadToProjectModal
        thread={currentThread}
        anchorEl={addIntegrationAnchorButton}
        isOpen={isConnectProjectDialogOpen}
        onDialogClose={closeConnectProjectDialog}
        prefetchProjects={true}
      />
    </>
  )
}

export default AddIntegration
