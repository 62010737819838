import { AnyAction } from 'redux'
import { SNACKBAR_ACTIONS } from 'constants/actions/snackbar'

const WAIT_FOR_USER_INTERACTION_TO_CLOSE = undefined

const DEFAULT_STATE: TSnackbarState = {
  message: '',
  show: false,
  duration: WAIT_FOR_USER_INTERACTION_TO_CLOSE,
  closeOnClickaway: true,
}

export const threadSnackbarReducer = (state = DEFAULT_STATE, action: AnyAction) => {
  switch (action.type) {
    case SNACKBAR_ACTIONS.SHOW_WITH_MESSAGE: {
      return {
        ...state,
        show: true,
        message: action.message,
        duration: action.duration,
        severity: action.severity,
        closeOnClickaway: action.closeOnClickaway,
      }
    }

    case SNACKBAR_ACTIONS.DISMISS: {
      return {
        ...state,
        show: false,
        message: '',
        duration: WAIT_FOR_USER_INTERACTION_TO_CLOSE,
      }
    }

    default:
      return state
  }
}
