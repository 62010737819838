import * as React from 'react'
import { Dialog, Typography, Button } from '@material-ui/core'
import BigCloseIcon from 'icons/bigCloseIcon'
import FormikTextField from 'component/formikTextField'
import { useStyles } from './newMemberDialog.style'
import { useNewMemberDialog } from './useNewMemberDialog'

interface INewMemberDialogProps {
  open: boolean
  onClose(): void
  onAddNewMember(email: string, firstName?: string, lastName?: string): void
}

export type TEmailStatus = 'non-exists' | 'org' | 'exists'

const NewMemberDialog = ({ open, onClose, onAddNewMember }: INewMemberDialogProps) => {
  const classes = useStyles()

  const {
    firstName,
    lastName,
    email,
    emailStatus,
    handleEmailChange,
    handleFirstNameChange,
    handleLastNameChange,
    handleAddNewMember,
  } = useNewMemberDialog(onClose, onAddNewMember)

  const emailStatusClass = React.useMemo(() => {
    switch (emailStatus) {
      case 'non-exists':
        return undefined

      case 'exists':
        return classes.emailStatusAvailable

      case 'org':
        return classes.emailStatusInOrg

      default:
        return undefined
    }
  }, [classes.emailStatusAvailable, classes.emailStatusInOrg, emailStatus])

  const emailStatusLabel = React.useMemo(() => {
    switch (emailStatus) {
      case 'non-exists':
        return ''

      case 'exists':
        return 'Existing user is found'

      case 'org':
        return 'User is already a member of another org'
    }
  }, [emailStatus])

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.dialogRoot}>
        <div className={classes.dialogHeader}>
          <Typography className={classes.dialogTitle}>Invite a new org member</Typography>
          <div className={classes.closeButton} onClick={onClose}>
            <BigCloseIcon className={classes.closeIcon} />
          </div>
        </div>

        <Typography className={classes.dialogSubtitle}>
          Search for existing or input the details of a new user
        </Typography>

        <div className={classes.form}>
          <FormikTextField
            value={email}
            title="Email"
            labelClasses={classes.fieldLabel}
            placeholder="eg. johnsmith@gmail.com"
            onChange={handleEmailChange}
          />
          <Typography className={emailStatusClass}>{emailStatusLabel}</Typography>
          <FormikTextField
            labelClasses={classes.fieldLabel}
            value={firstName}
            title="First Name"
            placeholder="eg. John"
            onChange={handleFirstNameChange}
            disabled={emailStatus !== 'non-exists'}
          />
          <FormikTextField
            value={lastName}
            labelClasses={classes.fieldLabel}
            title="Last Name"
            disabled={emailStatus !== 'non-exists'}
            placeholder="eg. Smith"
            onChange={handleLastNameChange}
          />
        </div>

        <div className={classes.buttonsStyle}>
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            classes={{
              root: classes.cancelButton,
            }}
          >
            Cancel
          </Button>
          <div className={classes.betweenButtons} />
          <Button
            color="primary"
            variant="contained"
            onClick={handleAddNewMember}
            disabled={!!!emailStatus || emailStatus === 'org'}
            classes={{
              root: classes.addButton,
            }}
          >
            Create
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default NewMemberDialog
