import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import LogoOnlyIcon from 'icons/logoOnly'
import { Theme, Typography } from '@material-ui/core'
import SubmitButtonWithLoading from 'component/submitButtonWithLoading'
import Spacer from 'component/layoutUtils/spacer'
import ErrorBearsIcon from 'icons/errorBearsIcon'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTrackComponentDimensions } from 'hooks/useTrackComponentDimensions'

interface IErrorScreenStylesProps {
  graphicsAndActionContainerMarginBottom?: number
}

const useErrorScreenStyles = makeStyles<Theme, IErrorScreenStylesProps>((theme: Theme) => ({
  errorScreenRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 500,
  },
  logo: {
    marginBottom: 20,
    marginTop: 32,
  },
  title: {
    fontSize: 25,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 300,
    marginTop: 12,
    maxWidth: 300,
  },
  graphicsAndActionContainer: {
    marginBottom: ({ graphicsAndActionContainerMarginBottom = 0 }) => graphicsAndActionContainerMarginBottom,
    marginTop: 26,
    position: 'relative',
  },
  bearIconAndActionButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  overlayBearIconAndActionButtonContainer: {
    position: 'absolute',
    top: '70%',
  },
  button: {
    fontSize: 20,
    fontWeight: 700,
    height: 50,
    lineHeight: 0.7,
    width: 252,
  },
}))

interface IErrorScreenProps {
  title: string | JSX.Element
  subtitle?: string | JSX.Element
  iconMessage: string
  isIconMessageHighlighted?: boolean
  overlaidBackgroundComponent?: JSX.Element
  onActionClick?(): void
  buttonTitle?: string
  includeLogo?: boolean
}

const ErrorScreen = ({
  title,
  subtitle,
  iconMessage,
  isIconMessageHighlighted,
  onActionClick,
  buttonTitle,
  overlaidBackgroundComponent,
  includeLogo = true,
}: IErrorScreenProps) => {
  const { ref: bearIconAndActionButtonContainerRef, componentHeight: bearIconAndActionButtonContainerHeight } =
    useTrackComponentDimensions()

  // When the overlaid component is present, the bearIconAndActionButtonContainer will be absolute positioned,
  // so this aims to compensate its dimentions to keep the content centered on the screen
  const marginBottomForGraphicsAndActionContainer = overlaidBackgroundComponent
    ? bearIconAndActionButtonContainerHeight
    : 0

  const classes = useErrorScreenStyles({
    graphicsAndActionContainerMarginBottom: marginBottomForGraphicsAndActionContainer,
  })
  const dispatch = useDispatch()
  const defaultOnClick = () => dispatch(push('/'))

  const onClick = onActionClick ?? defaultOnClick

  return (
    <div className={classes.errorScreenRoot}>
      {includeLogo && <LogoOnlyIcon className={classes.logo} height={44} width={50} />}
      <Typography className={classes.title}>{title}</Typography>
      {subtitle && (
        <Typography className={classes.subtitle} align="center">
          {subtitle}
        </Typography>
      )}
      <div className={classes.graphicsAndActionContainer}>
        {overlaidBackgroundComponent}
        <div
          className={classNames(classes.bearIconAndActionButtonContainer, {
            [classes.overlayBearIconAndActionButtonContainer]: !!overlaidBackgroundComponent,
          })}
          ref={bearIconAndActionButtonContainerRef}
        >
          <ErrorBearsIcon text={iconMessage} isTextHighlighted={isIconMessageHighlighted} />
          <Spacer height={45} />
          <SubmitButtonWithLoading className={classes.button} color="primary" onClick={onClick}>
            {buttonTitle ?? 'Home'}
          </SubmitButtonWithLoading>
        </div>
      </div>
    </div>
  )
}

export default ErrorScreen
