import { useQuery } from '@tanstack/react-query'
import { getThreadFollowers } from 'api/threads'
import { getTimeInMilliSeconds } from 'dataProcessors/utils'
import { useUser } from 'hooks/auth'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'

export const getThreadFollowersQueryKey = ({ userId, threadId }: { userId: string; threadId?: string }) => [
  userId,
  'threads',
  threadId,
  'followers',
]

const getThreadFollowersQueryFunction = async (threadId?: string) => {
  if (!threadId) return undefined

  const followers = getThreadFollowers(threadId)

  return followers
}

export const useThreadFollowers = (threadId?: string) => {
  const { getThreadsListActiveData } = useThreadActions()
  const currentUser = useUser()

  const getThreadFollowersInitialData = (threadId?: string) => {
    if (!threadId) return

    const threadsListActiveData = getThreadsListActiveData()

    if (!threadsListActiveData) return undefined

    const { threadsList = [] } = threadsListActiveData

    const thread = threadsList.find(thread => thread.id === threadId)
    if (!thread) return undefined

    return thread?.followers
  }

  const { data: followers, isLoading } = useQuery({
    enabled: !!threadId && !!currentUser?.id,
    queryKey: getThreadFollowersQueryKey({ userId: currentUser?.id ?? '', threadId }),
    queryFn: () => getThreadFollowersQueryFunction(threadId),
    initialData: () => getThreadFollowersInitialData(threadId),
    staleTime: getTimeInMilliSeconds({ minutes: 1 }),
    initialDataUpdatedAt: 0,
  })

  return {
    followers: followers ?? [],
    isLoading,
  }
}
