import * as React from 'react'

interface IEmailIconProps {
  className?: string
}

const EmailIcon = (props: IEmailIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.32" height="20" viewBox="0 0 13 12.5" {...props}>
      <path
        fillRule="evenodd"
        d="M1.43 2.4c-.11 0-.214.022-.31.06l4.59 4.245c.197.183.374.183.571 0l4.6-4.244c-.097-.04-.201-.061-.312-.061H1.431zm-.821.718C.604 3.155.6 3.193.6 3.231v5.815c0 .46.37.83.83.83h9.14c.46 0 .83-.37.83-.83V3.231c0-.038-.004-.076-.009-.113L6.848 7.315c-.476.44-1.228.44-1.705 0L.61 3.118z"
      />
    </svg>
  )
}

export default EmailIcon
