import React from 'react'
import { Theme, makeStyles, IconButton } from '@material-ui/core'
import { StarButton } from 'component/starButton'
import { IProjectThread } from 'model/project'
import ArrowRight from '@material-ui/icons/ArrowRight'
import ArrowDown from '@material-ui/icons/ArrowDropDown'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { useOpenThreadUseCase } from 'usecases/openThread'
import { useBoolean } from 'hooks/useBoolean'
import classNames from 'classnames'
import { NotesInput } from './notesInput'
import { useUpdateThreadNotesUseCase } from 'usecases/updateThreadNotes'
import debounce from 'lodash/debounce'
import ThreadTitle from 'component/threadTitle/threadTitle'

const useThreadBlockStyles = makeStyles((theme: Theme) => ({
  threadBlockRoot: {
    alignItems: 'center',
    color: theme.threadPalette.lightSoftGray,
    display: 'flex',
    gap: 4,
    '&:hover': {
      color: theme.threadPalette.darkGray,
      cursor: 'pointer',
    },
  },
  threadTitle: {
    color: theme.threadPalette.brownishGray,
    fontSize: 18,
    fontWeight: 500,
    marginRight: 4,
  },
  collapsableIcon: { color: 'inherit' },
  collapsableIconHovering: { color: theme.threadPalette.brownishGray },
  openThreadIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 20,
  },
  hideIcon: {
    visibility: 'hidden',
  },
  threadDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 54,
    marginTop: 8,
  },
}))

interface IThreadBlockProps {
  thread: IProjectThread
}

export const ThreadBlock = ({ thread }: IThreadBlockProps) => {
  const classes = useThreadBlockStyles()
  const { openThreadByIdUseCase } = useOpenThreadUseCase()
  const { state: isHovering, setTrue: startHovering, setFalse: stopHovering } = useBoolean(false)
  const { state: isThreadDetailsOpen, toggle: toggleThreadDetails } = useBoolean(false)
  const [threadNotes, setThreadNotes] = React.useState(thread.notes ?? '')

  const { updateThreadNotesUseCase } = useUpdateThreadNotesUseCase()

  const updateThreadNotes = async (updatedNotes: string) =>
    await updateThreadNotesUseCase({ threadId: thread.id, notes: updatedNotes })
  const debouncedUpdateThreadNotes = React.useCallback(debounce(updateThreadNotes, 500), [])

  const onChangeThreadNotes = (updatedNotes: string) => {
    setThreadNotes(updatedNotes)
    debouncedUpdateThreadNotes(updatedNotes)
  }

  const openThread = () => openThreadByIdUseCase({ threadId: thread.id, forceOpen: true })
  const CollapsableIcon = isThreadDetailsOpen ? ArrowDown : ArrowRight

  return (
    <div>
      <div
        className={classes.threadBlockRoot}
        onMouseOver={startHovering}
        onMouseOut={stopHovering}
        onClick={toggleThreadDetails}
      >
        <CollapsableIcon
          className={classNames(classes.collapsableIcon, { [classes.collapsableIconHovering]: isHovering })}
        />
        <StarButton />
        <ThreadTitle
          id={thread.id}
          title={thread.title}
          alias={thread.titleAlias}
          titleClassName={classes.threadTitle}
        />
        <IconButton size="small" onClick={openThread} className={classNames({ [classes.hideIcon]: !isHovering })}>
          <OpenInNewIcon className={classes.openThreadIcon} />
        </IconButton>
      </div>
      {isThreadDetailsOpen && (
        <div className={classes.threadDetailsContainer}>
          <NotesInput value={threadNotes} onChange={onChangeThreadNotes} />
        </div>
      )}
    </div>
  )
}
