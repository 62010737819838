import React from 'react'
import { Theme, makeStyles, Button, Tooltip, Typography } from '@material-ui/core'
import ConfirmIcon from '@material-ui/icons/Check'
import SyncIcon from '@material-ui/icons/Sync'
import AppLoading from 'component/appLoading'
import classNames from 'classnames'
import { TDraftSyncState } from 'domain/message'

const useSyncDraftButtonStyles = makeStyles((theme: Theme) => ({
  syncDraftContentRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    justifyContent: 'center',
    paddingInline: 8,
  },
  syncDraftButtonRoot: {
    paddingBlock: 4,
    paddingInline: 0,
  },
  text: {
    color: theme.threadPalette.lightBlue,
    fontSize: 12,
  },
  textDisabled: {
    color: theme.threadPalette.lightGray,
  },
  icon: {
    fill: theme.palette.primary.main,
    height: 15,
    width: 15,
  },
  iconDisabled: {
    fill: theme.threadPalette.lightGray,
  },
  tooltip: {
    backgroundColor: theme.threadPalette.menuItem.current,
    borderRadius: 8,
    color: theme.threadPalette.white,
    fontSize: '13px',
    marginBottom: 8,
    padding: 6,
  },
}))

interface ISyncDraftButtonProps {
  draftSyncState: TDraftSyncState
  providerName: TEmailProvider
  onForcedUpdate(): void
  hasUploadsInProgress: boolean
}

interface ISyncDraftButtonOptions {
  icon: JSX.Element
  text: string
  tooltip: string
}

export const SyncDraftButton = ({
  draftSyncState,
  providerName,
  onForcedUpdate,
  hasUploadsInProgress,
}: ISyncDraftButtonProps) => {
  const classes = useSyncDraftButtonStyles()

  const providerDisplayName = providerName === 'gmail' ? 'Gmail' : 'Office 365'

  const isDisabled = hasUploadsInProgress

  const textClassName = classNames(classes.text, { [classes.textDisabled]: isDisabled })
  const iconClassName = classNames(classes.icon, { [classes.iconDisabled]: isDisabled })

  const draftSyncStateToButtonOptionsMapping: Record<TDraftSyncState, ISyncDraftButtonOptions> = {
    notSynced: {
      icon: <SyncIcon className={iconClassName} />,
      text: `Sync with ${providerDisplayName}`,
      tooltip: hasUploadsInProgress ? "Can't sync. Uploading is in progress." : 'Auto synced every 5 min',
    },
    syncing: {
      icon: <AppLoading visible small legHeight={4} legWidth={12} padding={6} />,
      text: `Syncing with ${providerDisplayName}`,
      tooltip: '',
    },
    synced: {
      icon: <ConfirmIcon className={iconClassName} />,
      text: 'Draft saved',
      tooltip: '',
    },
  }

  const buttonOptions = draftSyncStateToButtonOptionsMapping[draftSyncState]

  return (
    <Tooltip title={buttonOptions.tooltip} placement="top-start" classes={{ tooltip: classes.tooltip }}>
      <span>
        {draftSyncState === 'notSynced' ? (
          <Button
            classes={{ root: classes.syncDraftButtonRoot, label: classes.syncDraftContentRoot }}
            onClick={onForcedUpdate}
            disabled={isDisabled}
          >
            {buttonOptions.icon}
            <Typography className={textClassName}>{buttonOptions.text}</Typography>
          </Button>
        ) : (
          <div className={classes.syncDraftContentRoot}>
            {buttonOptions.icon}
            <Typography className={textClassName}>{buttonOptions.text}</Typography>
          </div>
        )}
      </span>
    </Tooltip>
  )
}
