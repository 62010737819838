import * as React from 'react'
import GoogleBtn from 'assets/signin_button_google.png'

interface IGoogleIconProps {
  className?: string
}

const GoogleIcon = (props: IGoogleIconProps) => {
  return <img {...props} src={GoogleBtn} alt="" />
}

export default GoogleIcon
