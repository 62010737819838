import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { stopSharingThreadWithSharingGroup as stopSharingThreadWithSharingGroupApiCall } from 'api/v2/sharingGroup'
import { showSnackbar } from 'actions/snackbar'
import { useThreadSharingSettingsCacheActions } from 'hooks/useSharingGroup/useThreadSharingSettingsCacheActions'

interface IUseStopSharingThreadWithSharingGroupUseCaseProps {
  onStopSharing?: () => void
}

export const useStopSharingThreadWithSharingGroupUseCase = ({
  onStopSharing,
}: IUseStopSharingThreadWithSharingGroupUseCaseProps = {}) => {
  const dispatch = useDispatch()

  const threadSharingSettingsCacheActions = useThreadSharingSettingsCacheActions()

  const {
    reset: resetStopSharingThreadWithSharingGroupMutation,
    mutateAsync,
    isLoading,
  } = useMutation(stopSharingThreadWithSharingGroupApiCall, {
    onMutate: stopSharingThreadParams => {
      const { sharingGroupId, threadId } = stopSharingThreadParams
      const rollbackOptimisticUpdates = threadSharingSettingsCacheActions.deleteData({ sharingGroupId, threadId })

      onStopSharing?.()
      return rollbackOptimisticUpdates
    },
    onError: (_, __, rollbackOptimisticUpdates) => {
      dispatch(showSnackbar({ message: 'Failed to stop sharing thread' }))
      rollbackOptimisticUpdates?.()
    },
    onSettled: (_, __, stopSharingThreadParams) => {
      const { threadId } = stopSharingThreadParams

      threadSharingSettingsCacheActions.refreshData(threadId)
      resetStopSharingThreadWithSharingGroupMutation()
    },
  })

  const stopSharingThreadWithSharingGroupUseCase: typeof stopSharingThreadWithSharingGroupApiCall = mutateAsync

  return { stopSharingThreadWithSharingGroupUseCase, isLoading }
}
