import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { starThread } from 'api/v2/thread'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'
import { useDispatch } from 'react-redux'
import { useUpdateThreadUseCase } from './updateThread'

interface IStarThreadUseCaseProps {
  threadId: string
  isStarred: boolean
}

export const useStarThreadUseCase = () => {
  const dispatch = useDispatch()
  const { updateThreadUseCase } = useUpdateThreadUseCase()
  const { refreshThread, refreshThreadsList } = useThreadActions()

  const starThreadHandler = async ({ threadId }: IStarThreadUseCaseProps) => {
    await starThread(threadId)
  }

  const starThreadMutation = useMutation(starThreadHandler, {
    onMutate: (variables: IStarThreadUseCaseProps) => {
      const { threadId, isStarred } = variables
      const { rollbackOptimisticUpdates } = updateThreadUseCase({ threadId: threadId, isStarred: isStarred })
      return rollbackOptimisticUpdates
    },
    onError: (error, variables, rollbackOptimisticUpdates) => {
      rollbackOptimisticUpdates?.()
      const { isStarred } = variables
      dispatch(showSnackbar({ message: isStarred ? 'Error on starring thread' : 'Error on unstarring thread' }))
      starThreadMutation.reset()
    },
    onSettled: (data, error, variables) => {
      const { threadId } = variables
      refreshThread(threadId)
      refreshThreadsList()
    },
  })

  const starThreadUseCase = async (threadId: string, isStarred: boolean) => {
    await starThreadMutation.mutateAsync({ threadId, isStarred })
  }

  return {
    starThreadUseCase,
  }
}
