import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import * as React from 'react'

import { DateTimeDataProcessor } from '../../dataProcessors/dateTime'

const useStyles = makeStyles((theme: Theme) => {
  return {
    dateDivider: {
      margin: '0px 10px',
      position: 'relative',
      textAlign: 'center',
      '&:before': {
        background: theme.threadPalette.lightishGray,
        content: '""',
        display: 'block',
        height: '2px',
        left: '0px',
        position: 'absolute',
        right: '0px',
        top: '9px',
      },
    },
    value: {
      background: theme.threadPalette.white,
      color: theme.threadPalette.menuItem.normal,
      display: 'inline-block',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '20px',
      padding: '0px 25px',
      position: 'relative',
    },
  }
})

interface IDateDividerProps {
  className?: string
  date: string
}

export const DateDivider = (props: IDateDividerProps) => {
  const classes = useStyles()

  const dateTime = DateTimeDataProcessor(props.date)
  const valueParts = [dateTime.toFormat('MMM')]

  const dayMod = dateTime.day % 10
  let daySuffix = 'th'
  if (dayMod === 1) {
    daySuffix = 'st'
  } else if (dayMod === 2) {
    daySuffix = 'nd'
  } else if (dayMod === 3) {
    daySuffix = 'rd'
  }

  valueParts.push(`${dateTime.day}${daySuffix}`)

  const year = dateTime.toFormat('yyyy')
  valueParts.push(year)

  const className = classnames(classes.dateDivider, props.className)
  return (
    <div className={className}>
      <span className={classes.value}>{valueParts.join(' ')}</span>
    </div>
  )
}
