import * as React from 'react'
import { getProjectByName } from 'api/v2/project'
import { IProject } from 'model/project'
import { useQuery } from '@tanstack/react-query'
import { errorHandlingWrapper } from 'api/errors/errorHandlingWrapper'
import { getProjectByNameQueryKey } from './queryKeys'
import { useUser } from 'hooks/auth'

export function useFetchProjectByName() {
  const [projectName, setProjectName] = React.useState<string>('')

  const currentUser = useUser()

  const { data: queryResult, isLoading } = useQuery({
    enabled: !!projectName && !!currentUser?.id,
    queryKey: getProjectByNameQueryKey({ userId: currentUser?.id ?? '', projectName }),
    queryFn: async () => {
      const { data: project } = await errorHandlingWrapper<IProject | undefined>(() => getProjectByName(projectName))
      return project ?? null
    },
  })

  const onProjectNameChange = (newSearchTerm: string) => setProjectName(newSearchTerm)

  return {
    project: queryResult,
    isLoading,
    onProjectNameChange,
  }
}
