import { useDispatch } from 'react-redux'
import { errorHandlingWrapper } from 'api/errors/errorHandlingWrapper'
import { handleError } from 'actions/error'
import { getCurrentUser } from 'api/auth'
import { setUser } from 'actions/auth'

export const useUpdateCurrentUserUseCase = () => {
  const dispatch = useDispatch()

  const updateCurrentUserUseCase = async (): Promise<TCurrentUser> => {
    const { data: user, rawError } = await errorHandlingWrapper(() => getCurrentUser())

    if (rawError) handleError(rawError)

    if (!user) return null

    dispatch(setUser(user))

    return user
  }

  return {
    updateCurrentUserUseCase,
  }
}
