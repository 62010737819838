import React, { Component } from 'react'
import './pdfViewer.css'
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

type PdfViewerState = {
  numPages?: number
  pageNumber: number
}

interface IPdfViewerProps {
  file: any
  zoom: number
  onPdfLoadFailed(error: Error): void
}

class PdfViewer extends Component<IPdfViewerProps, PdfViewerState> {
  state = {
    numPages: 0,
    pageNumber: 1,
  }

  onDocumentLoadSuccess = (document: pdfjs.PDFDocumentProxy) => {
    const pages = document.numPages
    this.setState({ numPages: pages })
  }

  render() {
    const { numPages } = this.state
    return (
      <div>
        <Document
          file={this.props.file}
          onLoadSuccess={this.onDocumentLoadSuccess}
          onLoadError={this.props.onPdfLoadFailed}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} className="page" scale={this.props.zoom} />
          ))}
        </Document>
      </div>
    )
  }
}
export default PdfViewer
