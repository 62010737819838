import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useUser } from 'hooks/auth'

export const getDraftMessageQueryKey = ({ userId, draftId }: { userId: string; draftId: string }) => [
  userId,
  'drafts',
  draftId,
]

export const useDraftCacheActions = () => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const getData = useCallback(
    (draftId: string) => {
      if (!currentUser?.id) return

      const queryKey = getDraftMessageQueryKey({ userId: currentUser?.id, draftId })
      return queryClient.getQueryData<IDraftMessage>(queryKey)
    },
    [queryClient, currentUser?.id]
  )

  const setData = useCallback(
    (draftMessage: IDraftMessage) => {
      if (!currentUser?.id) return

      const queryKey = getDraftMessageQueryKey({ userId: currentUser?.id, draftId: draftMessage.id })
      queryClient.setQueryData(queryKey, draftMessage)

      const rollbackUpdate = () => deleteData(draft => draft.id === draftMessage.id)
      return rollbackUpdate
    },
    [queryClient, currentUser?.id]
  )

  const updateData = useCallback(
    ({ draftId, draftModifier }: { draftId: string; draftModifier: (draft: IDraftMessage) => IDraftMessage }) => {
      if (!currentUser?.id) return

      const draftMessage = getData(draftId)
      if (!draftMessage) return

      const updatedDraftMessage = draftModifier(draftMessage)
      setData(updatedDraftMessage)

      const rollbackUpdate = () => setData(draftMessage)
      return rollbackUpdate
    },
    [queryClient, currentUser?.id]
  )

  const deleteData = useCallback(
    (deleteCondition: (draft: IDraftMessage) => boolean) => {
      if (!currentUser?.id) return

      const draftQueriesData = queryClient.getQueriesData<IDraftMessage>({ queryKey: ['drafts'], exact: false })
      const drafts = draftQueriesData.map(queryData => queryData[1])

      const draftsToRemove = drafts.filter(draft => draft && deleteCondition(draft)) as IDraftMessage[]

      draftsToRemove.forEach(draft => {
        const queryKey = getDraftMessageQueryKey({ userId: currentUser.id, draftId: draft.id })
        queryClient.removeQueries({ queryKey })
      })

      const rollbackUpdate = () => draftsToRemove.forEach(draft => setData(draft))
      return rollbackUpdate
    },
    [queryClient, currentUser?.id]
  )

  const refreshData = useCallback(
    (draftId: string) => {
      if (!currentUser?.id) return

      const queryKey = getDraftMessageQueryKey({ userId: currentUser?.id, draftId })
      queryClient.invalidateQueries({ queryKey })
    },
    [queryClient, currentUser?.id]
  )

  return {
    getData,
    setData,
    updateData,
    deleteData,
    refreshData,
  }
}
