import * as React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme, Radio, RadioProps } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioButton: {
      color: `${theme.threadPalette.green} !important`,
    },
  })
)

interface IRadioButtonProps extends RadioProps {}

const RadioButton = (props: IRadioButtonProps) => {
  const classes = useStyles()

  return (
    <Radio
      {...props}
      classes={{
        checked: classes.radioButton,
      }}
    />
  )
}

export default RadioButton
