import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { updateThreadNotes } from 'api/v2/thread'
import { useDispatch } from 'react-redux'
import { useProjectActions } from 'hooks/useProjects/useProjectActions'

export const useUpdateThreadNotesUseCase = () => {
  const dispatch = useDispatch()
  const { refreshThreadProjectsDetails } = useProjectActions()

  const { mutateAsync: runUpdateProjectNotesMutation, reset: resetUpdateThreadNotesMutation } = useMutation(
    updateThreadNotes,
    {
      onError: () => {
        dispatch(showSnackbar({ message: 'Error on updating thread notes' }))
        resetUpdateThreadNotesMutation()
      },
      onSettled: (_, __, { threadId }) => refreshThreadProjectsDetails(threadId, 'all'),
    }
  )

  const updateThreadNotesUseCase: typeof updateThreadNotes = runUpdateProjectNotesMutation

  return {
    updateThreadNotesUseCase,
  }
}
