import { USER_AUTH_ONBOARDING_ACTIONS } from '../constants/actions/userAuthOnboarding'

export const setCurrentAuthOnboardingStep = (onboardingStep: TUserAuthOnboardingStep) => ({
  type: USER_AUTH_ONBOARDING_ACTIONS.SET_CURRENT_ONBOARDING_STEP,
  step: onboardingStep,
})

export const resetUserAuthOnboarding = () => ({
  type: USER_AUTH_ONBOARDING_ACTIONS.RESET,
})
