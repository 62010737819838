import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppLoading from '../../component/appLoading'
import { selectLocation } from '../../selectors/router'
import { useAuthContext } from 'contexts/authContext'

interface IOAuthCallbackPageProps {
  handleCallback: TOAuthHandleCallbackAction
  working: boolean
}

export const OAuthCallbackPage = (props: IOAuthCallbackPageProps) => {
  const dispatch = useDispatch()
  const location = useSelector(selectLocation)

  React.useEffect(() => {
    if (!props.working) {
      dispatch(
        props.handleCallback({
          state: location.query.state,
          code: location.query.code,
          error: location.query?.error,
          scope: location.query?.scope,
        })
      )
    }
  }, [dispatch, location, props])

  return <AppLoading visible={true} />
}

interface IOAuthLoginPageProps {
  login: TOAuthLoginAction
  working: boolean
}

export const OAuthLoginPage = (props: IOAuthLoginPageProps) => {
  const dispatch = useDispatch()

  const { redirectURL } = useAuthContext()

  React.useEffect(() => {
    if (!props.working) {
      dispatch(props.login({ redirectURL }))
    }
  }, [dispatch, props])

  return <AppLoading visible={true} />
}
