import React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Button, Typography } from '@material-ui/core'
import { getMessageContentAsPreview } from 'domain/message'

const useMessageContentPreviewStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageContentRoot: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      marginBlock: '12px 4px',
      marginInline: 16,
    },
    messageContent: {
      display: '-webkit-box',
      fontSize: 14,
      lineHeight: 1.4,
      maxHeight: 40,
      overflow: 'hidden',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      width: '100%',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    viewEntireMessageButtonRoot: {
      paddingBlock: 2,
      paddingInline: 6,
    },
    viewEntireMessageButtonText: {
      color: theme.palette.primary.main,
      fontSize: 12,
    },
  })
)

interface IMessageContentPreviewProps {
  message: IMessage
  onViewEntireMessage?(): void
}

export const MessageContentPreview = ({ message, onViewEntireMessage }: IMessageContentPreviewProps) => {
  const classes = useMessageContentPreviewStyles()

  return (
    <div className={classes.messageContentRoot}>
      <Typography className={classes.messageContent}>{getMessageContentAsPreview(message)}</Typography>
      {onViewEntireMessage && (
        <Button classes={{ root: classes.viewEntireMessageButtonRoot }} onClick={onViewEntireMessage}>
          <Typography className={classes.viewEntireMessageButtonText}>View entire message</Typography>
        </Button>
      )}
    </div>
  )
}
