import React from 'react'
import { Button, CircularProgress, Theme, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import IAttachment from 'model/attachment'
import PdfViewer from './pdfViewer'
import ImageViewer from './imageViewer'
import PreviewUnsupported from './shared/unsupported'
import { useCloudConvertHandler } from 'api/websocket/actionHandlers/useCloudConvertHandler'
import { useAttachmentContentDetailsCacheActions } from 'hooks/useAttachmentContentDetails/useAttachmentContentDetailsCacheActions'
import Spacer from 'component/layoutUtils/spacer'
import { TAttachmentPreviewType } from './attachmentDisplay'

const useAttachmentContentRendererStyles = makeStyles((theme: Theme) =>
  createStyles({
    refreshPreviewHeaderText: {
      maxWidth: 500,
      fontSize: 19,
      color: theme.threadPalette.white,
      textAlign: 'center',
    },
    circularProgress: {
      margin: 'auto',
    },
    refreshContentRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    refreshContentButton: {
      height: 50,
      marginTop: 64,
      textTransform: 'none',
      width: 250,
    },
  })
)

interface IAttachmentContentRendererProps {
  attachment: IAttachment
  attachmentURL?: string
  previewType: TAttachmentPreviewType
  zoom: number
  isConverting: boolean
  setIsConverting: (isConverting: boolean) => void
  isLoadingContentDetails: boolean
  isFetchingContentDetails: boolean
  onDownload(): void
  isDownloading: boolean
}

type TContentState = 'importing' | 'converting' | 'ready'

export const AttachmentContentRenderer = ({
  attachment,
  attachmentURL,
  previewType,
  zoom,
  isConverting,
  setIsConverting,
  isLoadingContentDetails,
  isFetchingContentDetails,
  onDownload,
  isDownloading,
}: IAttachmentContentRendererProps) => {
  const classes = useAttachmentContentRendererStyles()

  const attachmentContentDetailsCacheActions = useAttachmentContentDetailsCacheActions()

  const onCloudConvertEventCallback = (attachmentId: string) => {
    if (attachmentId !== attachment?.id) return
    setIsConverting(false) // triggers the preview reload
  }
  useCloudConvertHandler({ callback: onCloudConvertEventCallback, attachmentId: attachment?.id })

  const contentRendererComponent = React.useMemo(() => {
    switch (previewType) {
      case 'pdf':
        return <PdfViewer file={attachmentURL} zoom={zoom} onPdfLoadFailed={() => setIsConverting(true)} />

      case 'img': {
        return <ImageViewer fileUrl={attachmentURL} zoom={zoom} />
      }

      default:
        return <PreviewUnsupported onDownload={onDownload} isDownloading={isDownloading} />
    }
  }, [previewType, attachmentURL, zoom, attachment, onDownload])

  if (isLoadingContentDetails) {
    return <CircularProgress className={classes.circularProgress} />
  }

  let contentState: TContentState = 'ready'
  if (isConverting) contentState = 'converting'
  if (!isLoadingContentDetails && !attachmentURL) contentState = 'importing'

  if (contentState === 'ready') {
    return contentRendererComponent
  }

  const contentPreparationStateToContentRefreshPropsMapping: Partial<
    Record<TContentState, { actionName: string; isRefreshEnabled: boolean; onRefresh(): void }>
  > = {
    importing: {
      actionName: 'Importing',
      onRefresh: () => attachmentContentDetailsCacheActions.refreshData({ id: attachment.id }),
      isRefreshEnabled: !isLoadingContentDetails && !isFetchingContentDetails,
    },
    converting: {
      actionName: 'Conversion',
      onRefresh: () => setIsConverting(false),
      isRefreshEnabled: true,
    },
  }

  const contentRefreshProps = contentPreparationStateToContentRefreshPropsMapping[contentState]

  return (
    <div className={classes.refreshContentRoot}>
      <Typography className={classes.refreshPreviewHeaderText}>
        {contentRefreshProps?.actionName} of <strong>{attachment.filename}</strong> is in progress...
      </Typography>
      <Spacer height={32} />
      <CircularProgress />
      <Button
        color="primary"
        variant="contained"
        className={classes.refreshContentButton}
        onClick={contentRefreshProps?.onRefresh}
        disabled={!contentRefreshProps?.isRefreshEnabled}
      >
        Refresh Preview
      </Button>
    </div>
  )
}
