import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  mention: {
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  invite: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  inlineAttachment: {
    cursor: 'zoom-in',
    '&:hover': {
      boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.2)',
    },
  },
}))
