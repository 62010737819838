import * as React from 'react'
import Management from 'component/management'
import PageLayout from './pageLayout'

const ManagementPage = () => {
  return (
    <PageLayout leftSidebarOption="management">
      <Management />
    </PageLayout>
  )
}

export default ManagementPage
