import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, Typography, Button } from '@material-ui/core'
import InfiniteIcon from 'icons/infinite'
import { switchOrganization } from 'api/organization'
import Sent from 'icons/sent'
import { useDispatch } from 'react-redux'
import { checkAuth } from 'actions/checkAuth'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 21,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: 550,
    alignItems: 'center',
    marginTop: 55,
    marginLeft: 10,
    height: 500,
    boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.2)',
  },

  circle: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -35,
    left: '40%',
    width: 100,
    height: 100,
    borderRadius: '50%',
    backgroundColor: theme.threadPalette.white,
    boxShadow: `0 3px 4px 0 ${theme.threadPalette.lightishGray}`,
    border: `solid 1px ${theme.threadPalette.lighterGray}`,
  },

  paperTitle: {
    fontSize: 32,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: theme.threadPalette.darkBlue,
  },

  paperSubtitle: {
    fontSize: 32,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: theme.threadPalette.lightBlue,
  },

  bottomButton: {
    borderRadius: 5,
    height: 75,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: theme.threadPalette.green,
    color: theme.threadPalette.white,
    margin: 12,
    width: '95%',
    fontSize: 32,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.44,
    letterSpacing: 'normal',
    '&:hover': {
      backgroundColor: theme.threadPalette.green,
    },
  },

  featuresContainer: {
    marginTop: 36,
    marginBottom: 36,
  },

  orgFeatureItem: {
    display: 'flex',
    alignItems: 'center',
  },

  orgFeatureTickIcon: {
    fill: theme.threadPalette.green,
    width: 20,
    height: 15,
    marginRight: 10,
  },

  orgFeatureText: {
    fontSize: 24,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: theme.threadPalette.lightGray,
  },
}))

interface IOrgFeatureProps {
  text: string
}

const OrgFeature: React.FC<IOrgFeatureProps> = ({ text }) => {
  const classes = useStyles()

  return (
    <div className={classes.orgFeatureItem}>
      <Sent className={classes.orgFeatureTickIcon} />
      <Typography className={classes.orgFeatureText}>{text}</Typography>
    </div>
  )
}

interface IJoinOrgProps {}

const JoinOrg = (props: IJoinOrgProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleGetStarted = React.useCallback(async () => {
    await switchOrganization()
    dispatch(checkAuth())
  }, [dispatch])

  return (
    <>
      <Typography className={classes.title}>
        To unlock this and many other features please upgrade to a paid account.
      </Typography>
      <div className={classes.paper}>
        <div className={classes.circle}>
          <InfiniteIcon />
        </div>

        <Typography className={classes.paperTitle}>PREMIUM ACCOUNT</Typography>
        <Typography className={classes.paperSubtitle}>$9 per month/user</Typography>

        <div className={classes.featuresContainer}>
          <OrgFeature text={'Manage opportunities'} />
          <OrgFeature text={'Add tasks and notes'} />
          <OrgFeature text={'Manage organization and teams'} />
          <OrgFeature text={'Receive email read receipts'} />
          <OrgFeature text={'Access data through dashboards'} />
        </div>
        <Button className={classes.bottomButton} onClick={handleGetStarted}>
          Get Started
        </Button>
      </div>
    </>
  )
}

export default JoinOrg
