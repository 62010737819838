import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { Theme, Typography } from '@material-ui/core'
import UncollapseIcon from '@material-ui/icons/ChevronRight'
import CollapseIcon from '@material-ui/icons/ExpandMore'
import Spacer from 'component/layoutUtils/spacer'
import WrapIntoSkeletonLoading from 'component/wrapIntoSkeletonLoading'

const useDraftAutoSaveStatusLabelStyles = makeStyles((theme: Theme) => ({
  draftLabelRoot: {
    alignItems: 'center',
    display: 'inline-flex',
  },
  statusContainer: {
    alignItems: 'center',
    backgroundColor: theme.threadPalette.white,
    border: `solid 2px ${theme.threadPalette.lightBlue}`,
    borderBottomStyle: `hidden`,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: 'inline-flex',
    paddingBottom: 2,
    marginBottom: -2,
    minHeight: 18,
    minWidth: 82,
    zIndex: 1,
  },
  statusContainerWithoutCollapseButton: {
    display: 'unset',
    paddingTop: 5,
    paddingRight: 0,
  },
  labelText: {
    color: theme.threadPalette.lightBlue,
    fontSize: 10,
  },
  labelIcon: {
    color: theme.threadPalette.lightSoftGray,
    fontSize: 24,
  },
  draftError: {
    backgroundColor: theme.threadPalette.white,
    borderTopRightRadius: 5,
    color: theme.palette.error.contrastText,
    fontSize: 10,
    fontWeight: 300,
    paddingBottom: 1,
    paddingLeft: 5,
    paddingRight: 5,
  },
}))

interface IDraftAutoSaveStatusLabelProps {
  displayId?: string
  errorMessage?: string
  isCollapsed?: boolean
  showCollapseButton?: boolean
  onToggleCollapse(): void
  isLoading?: boolean
}

const DraftAutoSaveStatusLabel = ({
  displayId,
  errorMessage,
  isCollapsed = true,
  showCollapseButton = true,
  onToggleCollapse,
  isLoading,
}: IDraftAutoSaveStatusLabelProps) => {
  const classes = useDraftAutoSaveStatusLabelStyles()

  const IconComponent = isCollapsed ? UncollapseIcon : CollapseIcon
  const draftWithDisplayId = displayId ? `Draft ${displayId}` : 'Draft'

  return (
    <WrapIntoSkeletonLoading isLoading={isLoading} variant="rect" className={classes.draftLabelRoot} animation="wave">
      <div className={classes.draftLabelRoot}>
        <div
          className={classNames(classes.statusContainer, {
            [classes.statusContainerWithoutCollapseButton]: !showCollapseButton,
          })}
        >
          {showCollapseButton && (
            <>
              <IconComponent className={classes.labelIcon} onClick={onToggleCollapse} />
              <Spacer width={4} />
            </>
          )}
          <Spacer width={4} />
          <Typography align="center" className={classes.labelText}>
            {draftWithDisplayId}
          </Typography>
          {showCollapseButton && <Spacer width={8} />}
        </div>
        {errorMessage && (
          <Typography align="center" className={classes.draftError}>
            {errorMessage}
          </Typography>
        )}
      </div>
    </WrapIntoSkeletonLoading>
  )
}

export default DraftAutoSaveStatusLabel
