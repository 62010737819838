import React from 'react'
import { Button, Dialog, makeStyles, Typography } from '@material-ui/core'
import { useFullScreen } from 'hooks/useFullScreen'
import { useBrowserSecurityWarningContext } from './browserSecurityWarningContext'
import ErrorBearsIcon from 'icons/errorBearsIcon'
import Spacer from 'component/layoutUtils/spacer'

const useBrowserSecurityWarningModalStyles = makeStyles(theme => ({
  modalRoot: {
    border: `2px solid ${theme.threadPalette.warningRed}`,
    borderRadius: 24,
    boxShadow: 'inset 0 0 10px rgba(244, 67, 54, 0.4)',
    width: '440px',
  },
  contentRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '24px 20px',
  },
  dismissWarningButton: {
    backgroundColor: theme.threadPalette.warningRed,
    border: 'none',
    borderRadius: '4px',
    color: theme.threadPalette.white,
    cursor: 'pointer',
    fontSize: '16px',
    paddingInline: '38px',
    '&:hover': {
      backgroundColor: '#d32f2f',
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    maxWidth: '400px',
    textAlign: 'center',
    lineHeight: 'normal',
    color: theme.threadPalette.menuItem.normal,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 300,
    maxWidth: 300,
    textAlign: 'center',
    lineHeight: 'normal',
    color: theme.threadPalette.menuItem.normal,
  },
  browserSuggestions: {
    fontSize: 14,
    fontWeight: 300,
    maxWidth: 380,
    textAlign: 'center',
    lineHeight: 'normal',
    color: theme.threadPalette.menuItem.normal,
  },
}))

export const BrowserSecurityWarningModal = () => {
  const classes = useBrowserSecurityWarningModalStyles()
  const { showBrowserSecurityWarning, silenceSecurityWarning, browserUpdateRequired, secureBrowsersSuggestion } =
    useBrowserSecurityWarningContext()
  const { isFullScreen } = useFullScreen()

  const warningSubtitle = React.useMemo(() => {
    if (browserUpdateRequired)
      return `Please, consider updating you browser version to access your email content safely`

    return `Please, consider changing it to access your email content safely`
  }, [browserUpdateRequired])

  return (
    <Dialog
      open={showBrowserSecurityWarning}
      classes={{ paper: classes.modalRoot }}
      maxWidth="md"
      fullScreen={isFullScreen}
    >
      <div className={classes.contentRoot}>
        <Typography className={classes.title}>Your browser lacks modern security protection</Typography>
        <Spacer height={26} />
        <ErrorBearsIcon text="not secure" />
        <Spacer height={26} />
        <Typography className={classes.subtitle}>{warningSubtitle}</Typography>
        {secureBrowsersSuggestion && secureBrowsersSuggestion.length > 0 && (
          <>
            <Spacer height={12} />
            <Typography className={classes.browserSuggestions}>
              Browser suggestions: {secureBrowsersSuggestion?.join(', ')}
            </Typography>
          </>
        )}
        <Spacer height={26} />
        <Button variant="contained" className={classes.dismissWarningButton} onClick={silenceSecurityWarning}>
          Dismiss
        </Button>
      </div>
    </Dialog>
  )
}
