import React from 'react'
import DraftMessage, { IDraftMessageProps } from './draftMessage'
import { MessageBlockWithAvatar } from '../shared/messageBlockWithAvatar'

export interface IDraftMessageBlockWithAvatarProps extends IDraftMessageProps {
  currentUser: IUser
}

export const DraftMessageBlockWithAvatar = ({
  currentUser,
  isLoading,
  ...draftMessageProps
}: IDraftMessageBlockWithAvatarProps) => {
  return (
    <MessageBlockWithAvatar isCurrentUserMessageSender={true} messageSender={currentUser} isLoading={isLoading}>
      <DraftMessage isLoading={isLoading} {...draftMessageProps} />
    </MessageBlockWithAvatar>
  )
}
