import * as React from 'react'
import Picker, { EmojiClickData, SkinTones } from 'emoji-picker-react'
import { ClickAwayListener, Popper, useTheme } from '@material-ui/core'

export interface IEmojiPickerProps {
  onEmojiClick: (data: EmojiClickData) => void
  wrapperClassName?: string
  wrapperProperties?: React.HTMLAttributes<HTMLDivElement>
  disabled?: boolean
  onClosePicker?(): void
}

const EmojiPicker: React.FC<IEmojiPickerProps> = ({
  onEmojiClick,
  wrapperProperties,
  wrapperClassName,
  disabled,
  onClosePicker,
  children,
}) => {
  const theme = useTheme()
  const [showEmojiPopup, setShowEmojiPopup] = React.useState(false)
  const popoverAnchor = React.useRef<HTMLDivElement>(null)

  const onEmojiButtonClick = (event: React.MouseEvent) => {
    if (disabled) return
    event.preventDefault()
    event.stopPropagation()

    setShowEmojiPopup(true)
  }

  const onEmojiSelected = (data: EmojiClickData) => {
    onEmojiClick(data)
    setShowEmojiPopup(false)
  }

  const onClosePopper = () => {
    setShowEmojiPopup(false)
    onClosePicker?.()
  }

  return (
    <ClickAwayListener onClickAway={onClosePopper}>
      <div ref={popoverAnchor} className={wrapperClassName} {...wrapperProperties} onClick={onEmojiButtonClick}>
        <Popper
          style={{ zIndex: theme.zIndex.tooltip + 1 }}
          open={showEmojiPopup}
          anchorEl={popoverAnchor.current}
          placement="bottom-start"
        >
          <Picker onEmojiClick={onEmojiSelected} defaultSkinTone={SkinTones.LIGHT} skinTonesDisabled />
        </Popper>
        {children}
      </div>
    </ClickAwayListener>
  )
}

export default EmojiPicker
