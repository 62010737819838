import * as React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { Typography } from '@material-ui/core'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 20,
      height: 20,
      borderRadius: '50%',
      backgroundColor: theme.palette.error.main,
    },
    counter: {
      fontSize: 12,
      color: theme.threadPalette.white,
      fontWeight: 'bold',
    },
  })
)

interface IUnreadCounterProps {
  counter?: number
  className?: string
}

const UnreadCounter = ({ counter, className }: IUnreadCounterProps) => {
  const classes = useStyles()

  if (!counter || counter < 1) {
    return null
  }

  return (
    <div className={classnames(classes.background, className)}>
      <Typography className={classes.counter}>{counter}</Typography>
    </div>
  )
}

export default UnreadCounter
