import * as React from 'react'
import { getProjectsList } from 'api/v2/project'
import { IProject } from 'model/project'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getProjectsListQueryKey } from './queryKeys'
import { useUser } from 'hooks/auth'
import { getNextPageNumber } from 'lib/api'

interface UseProjectsFetchProps {
  pageSize: number
  isFetchingEnabled?: boolean
  onlyActive?: boolean
  onlyArchived?: boolean
  parentProject?: IProject | null
}

export function useProjectsList({
  pageSize,
  parentProject,
  onlyActive = false,
  onlyArchived = false,
  isFetchingEnabled = true,
}: UseProjectsFetchProps) {
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const currentUser = useUser()

  const isArchived = React.useMemo(() => {
    if (onlyActive === onlyArchived) return undefined
    if (onlyArchived) return true
    if (onlyActive) return false
  }, [onlyActive, onlyArchived])

  const {
    data: queryResult,
    fetchNextPage,
    isFetchingNextPage,
    isInitialLoading,
  } = useInfiniteQuery({
    enabled: isFetchingEnabled && !!currentUser?.id,
    queryKey: getProjectsListQueryKey({
      userId: currentUser?.id ?? '',
      parentProjectId: parentProject === null ? null : parentProject?.id,
      filters: { pageSize, searchTerm, isArchived },
    }),
    queryFn: ({ pageParam }) =>
      getProjectsList({
        page: pageParam ?? 1,
        pageSize,
        searchString: searchTerm,
        isArchived,
        parentProjectId: parentProject === null ? null : parentProject?.id,
      }),
    getNextPageParam: lastPage => getNextPageNumber(lastPage),
    keepPreviousData: true,
  })

  const projectsList: IProject[] = React.useMemo(() => {
    const projectsListFromQuery = queryResult?.pages?.flatMap(page => page.data) ?? []

    if (!parentProject) return projectsListFromQuery

    const placeholderData = parentProject?.nestedProjectsList ?? []
    const usePlaceholderData = !isFetchingEnabled || isInitialLoading

    return usePlaceholderData ? placeholderData : projectsListFromQuery
  }, [parentProject, queryResult?.pages, isFetchingEnabled, isInitialLoading])

  const changeSearchTerm = (newSearchTerm: string) => setSearchTerm(newSearchTerm)

  const handleScrollEvent = React.useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      if (isFetchingNextPage) return
      const target = event.currentTarget
      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        fetchNextPage()
      }
    },
    [fetchNextPage, isFetchingNextPage]
  )

  return {
    projectsList,
    changeSearchTerm,
    handleScrollEvent,
    isLoadingNextPage: isFetchingNextPage,
    isInitialLoading,
  }
}
