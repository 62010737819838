import React from 'react'
import { makeStyles } from '@material-ui/styles'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import { IProject } from 'model/project'
import { fade, Popover, Theme } from '@material-ui/core'
import ProjectBadge from '../badges/projectBadge'
import MultiProjectsBadge from '../badges/multiProjectsBadge'

const useMultiProjectsSelectStyles = makeStyles((theme: Theme) => ({
  projectsSelectListModal: {
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
  },
  projectSelectItem: {
    boxShadow: `0 2px 5px 0 ${fade(theme.threadPalette.darkestBlue, 0.5)}`,
    marginBottom: 8,
  },
}))

interface IMultiProjectsSelectProps {
  projects: IProject[]
  onSelect?(project: IProject): void
  onDisconnect?(project: IProject): void
}

const MultiProjectsSelect = React.forwardRef<HTMLDivElement, IMultiProjectsSelectProps>(
  ({ projects, onSelect, onDisconnect }, ref) => {
    const classes = useMultiProjectsSelectStyles()
    const {
      isOpen: isProjectsBadgesListDialogOpen,
      handleClickToOpenDialog: openProjectsBadgesListDialog,
      handleCloseDialog: closeProjectDetailsDialog,
      dialogAnchorElement: projectsBadgesListDialogAnchorElement,
    } = useDialog()

    const onSelectProject = (project: IProject) => {
      onSelect?.(project)
      closeProjectDetailsDialog()
    }
    return (
      <>
        <MultiProjectsBadge ref={ref} projectsCount={projects.length} onClick={openProjectsBadgesListDialog} />
        <Popover
          classes={{ paper: classes.projectsSelectListModal }}
          open={isProjectsBadgesListDialogOpen}
          elevation={0}
          anchorEl={projectsBadgesListDialogAnchorElement}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={closeProjectDetailsDialog}
        >
          {projects.map(project => (
            <React.Fragment key={project.id}>
              <ProjectBadge
                className={classes.projectSelectItem}
                name={project.name}
                threadCount={project.threadCount}
                emoji={project.emoji}
                onClick={() => onSelectProject(project)}
                onDisconnect={() => onDisconnect?.(project)}
              />
            </React.Fragment>
          ))}
        </Popover>
      </>
    )
  }
)

export default MultiProjectsSelect
