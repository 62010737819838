import * as React from 'react'
import { Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ThreadItem from 'component/leftSidebar/threadItem'
import { useUser } from 'hooks'
import ThreadSkeletonItem from 'component/leftSidebar/threadSkeletonItem'
import BeatProgress from 'component/beatProgress'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { useOpenThreadUseCase } from 'usecases/openThread'
import { useThreadSharingSettingsCacheActions } from 'hooks/useSharingGroup/useThreadSharingSettingsCacheActions'

const useThreadsListStyles = makeStyles((theme: Theme) => {
  return {
    threadsListRoot: {
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    emptyThreadsListLabelRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      justifyContent: 'center',
    },
    emptyThreadsListLabel: {
      color: theme.threadPalette.white,
      fontSize: '14px',
    },
  }
})

interface IThreadsListProps {
  threadsList: IThread[]
  handleScrollEvent(event: React.UIEvent<HTMLDivElement>): void
  isFirstPageLoading: boolean
  isLoadingNextPage: boolean
}

export const ThreadsList = ({
  threadsList,
  handleScrollEvent,
  isFirstPageLoading,
  isLoadingNextPage,
}: IThreadsListProps) => {
  const classes = useThreadsListStyles()

  const user = useUser()
  const { currentThread } = useCurrentThreadContext()
  const { openThreadByIdUseCase } = useOpenThreadUseCase()

  const threadSharingSettingsCacheActions = useThreadSharingSettingsCacheActions()

  const onThreadItemSelect = (thread: IThread) => {
    openThreadByIdUseCase({ threadId: thread.id, initialData: thread })
  }

  const getThreadsList = (threads: IThread[]) => {
    if (threads.length === 0) {
      return (
        <div className={classes.emptyThreadsListLabelRoot}>
          <Typography className={classes.emptyThreadsListLabel}>No threads to show</Typography>
          <Typography className={classes.emptyThreadsListLabel}>Adjust your filter</Typography>
        </div>
      )
    }

    return threads.map(thread => (
      <ThreadItem
        dataCy="left-sidebar-thread-item"
        key={thread.id}
        isActive={!!currentThread && currentThread.id === thread.id}
        thread={thread}
        user={user}
        onSelect={() => onThreadItemSelect(thread)}
        onHover={() => threadSharingSettingsCacheActions.prefetchData(thread.id)}
        stateForUser={thread.stateForUser}
      />
    ))
  }

  const getThreadsSeletonList = (numberOfThreads: number) => {
    return [...Array(numberOfThreads)].map((_, index) => <ThreadSkeletonItem key={index} />)
  }

  return (
    <div className={classes.threadsListRoot} onScroll={handleScrollEvent}>
      {isFirstPageLoading ? getThreadsSeletonList(30) : getThreadsList(threadsList)}
      {isLoadingNextPage && <BeatProgress />}
    </div>
  )
}
