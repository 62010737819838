import React from 'react'
import { IOnboardingTourStep } from 'contexts/useOnboardingTourContext'
import { createUntargetedOnboardingStep } from 'domain/onboardingTourStep'
import { ThreadSharingExplanationStep } from 'component/onboarding/shareThreadOnboarding/threadSharingExplanationStep'
import { CreateNewGroupStep } from 'component/onboarding/shareThreadOnboarding/createNewGroupStep'
import { CreateThreadSharingStep } from 'component/onboarding/shareThreadOnboarding/createThreadSharingStep'
import { SharedMessageStep } from 'component/onboarding/shareThreadOnboarding/sharedMessageStep'
import { EmailDigestStep } from 'component/onboarding/shareThreadOnboarding/emailDigestStep'

interface IGetShareThreadOnboardingStepsProps {
  openSharingGroup?(): void
}

export const useShareThreadOnboardingSteps = () => {
  const getSteps = React.useCallback(
    ({ openSharingGroup }: IGetShareThreadOnboardingStepsProps): IOnboardingTourStep[] => {
      return [
        createUntargetedOnboardingStep({
          content: <ThreadSharingExplanationStep />,
          hideSkipButton: true,
        }),
        createUntargetedOnboardingStep({
          content: <CreateNewGroupStep />,
          hideSkipButton: true,
        }),
        createUntargetedOnboardingStep({
          content: <CreateThreadSharingStep />,
          hideSkipButton: true,
        }),
        createUntargetedOnboardingStep({
          content: <SharedMessageStep />,
          hideSkipButton: true,
        }),
        createUntargetedOnboardingStep({
          content: <EmailDigestStep />,
          lastStepButtonLabel: 'Share a thread',
          onNextStepButtonClick: openSharingGroup,
        }),
      ]
    },
    []
  )

  return { getSteps }
}
