import * as React from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useProject } from 'hooks/useProjects/useProject'
import { IProject } from 'model/project'
import BeatProgress from 'component/beatProgress'
import { ProjectThreadItem } from './projectThreadItem'
import Divider from '@material-ui/core/Divider'
import { useThreadSharingSettingsCacheActions } from 'hooks/useSharingGroup/useThreadSharingSettingsCacheActions'

export const useProjectThreadsDropdownStyles = makeStyles((theme: Theme) => {
  return {
    projectThreadsDropdownRoot: {
      display: 'flex',
      marginLeft: '11px',
      paddingInline: '10px',
    },
    projectThreadsDropdown: {
      display: 'flex',
    },
    projectThreadsList: {
      display: 'flex',
      marginLeft: '10px',
      flexDirection: 'column',
    },
    divider: {
      backgroundColor: theme.threadPalette.lightBlueGrey,
      height: 'calc(100% - 4px)',
      marginTop: '-3px',
      width: '2px',
    },
  }
})

interface IProjectThreadsDropdownProps {
  project: IProject
}

export const ProjectThreadsDropdown = ({ project }: IProjectThreadsDropdownProps) => {
  const classes = useProjectThreadsDropdownStyles()

  const threadSharingSettingsCacheActions = useThreadSharingSettingsCacheActions()

  const { project: projectWithThreads, isLoading } = useProject({ projectId: project.id })

  if (isLoading) return <BeatProgress />

  if (!projectWithThreads || !projectWithThreads.threads) return null

  return (
    <div className={classes.projectThreadsDropdownRoot}>
      <div className={classes.projectThreadsDropdown}>
        <Divider orientation="vertical" className={classes.divider} />
        <div className={classes.projectThreadsList}>
          {projectWithThreads.threads.map(projectThread => (
            <ProjectThreadItem
              projectThread={projectThread}
              key={projectThread.id}
              onHover={() => {
                threadSharingSettingsCacheActions.prefetchData(projectThread.id)
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
