import React from 'react'
import { Button, ButtonProps, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import AppLoading from 'component/appLoading'

const useButtonWithIconStyles = makeStyles((theme: Theme) => ({
  buttonRoot: {
    cursor: 'pointer',
    display: 'inline-flex',
    placeItems: 'center',

    '&:disabled': {
      color: 'revert',
    },
  },
  icon: {
    color: 'inherit',
    fontSize: 30,
  },
  loadingContainer: {
    marginLeft: 4,
  },
}))

type IButtonWithIconStylesProps = Pick<ButtonProps, 'size' | 'onClick'> & {
  text: string
  icon: React.ElementType
  iconClassName?: string
  buttonClassName?: string
  isLoading?: boolean
}

const ButtonWithIcon = ({
  onClick,
  size,
  text,
  buttonClassName,
  icon: Icon,
  iconClassName,
  isLoading = false,
}: IButtonWithIconStylesProps) => {
  const classes = useButtonWithIconStyles()

  const buttonRootClassName = classnames(buttonClassName, classes.buttonRoot)
  const iconRootClassName = classnames(iconClassName, classes.icon)
  const startIcon = isLoading ? (
    <div className={classes.loadingContainer}>
      <AppLoading visible small padding={8} legHeight={6} legWidth={16} />
    </div>
  ) : (
    <Icon classes={{ root: iconRootClassName }} />
  )
  return (
    <Button size={size} className={buttonRootClassName} startIcon={startIcon} onClick={onClick} disabled={isLoading}>
      {text}
    </Button>
  )
}

export default ButtonWithIcon
