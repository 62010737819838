import * as React from 'react'
import { useIconStyles } from '../styles'
import classnames from 'classnames'

interface IAudioIconProps extends JSX.IntrinsicAttributes {
  width?: number
  height?: number
  className?: string
  fill?: string
}

const AudioIcon = ({ className, width, height, fill }: IAudioIconProps) => {
  const classes = useIconStyles()
  return (
    <div className={classnames(className, classes.iconWrapper)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : '20'}
        height={height ? height : '20'}
        viewBox="0 0 20 20"
      >
        <path
          fill={fill ? fill : '#4A4A4A'}
          d="M17.268 19.714c.283 0 .523-.099.72-.297.199-.198.298-.438.298-.72V6.481c0-.283-.071-.594-.212-.933-.142-.34-.311-.608-.51-.806l-3.307-3.308c-.198-.198-.467-.367-.806-.509-.34-.141-.65-.212-.933-.212h-9.5c-.283 0-.523.1-.721.297C2.099 1.21 2 1.45 2 1.732v16.964c0 .283.099.523.297.721.198.198.438.297.72.297h14.25zm-.34-1.357H3.359V2.071H11.5v4.411c0 .283.099.523.297.721.198.198.438.297.72.297h4.412v10.857zm-.084-12.214h-3.987V2.156c.205.07.35.149.435.234l3.318 3.318c.085.085.163.23.234.435zm-3.849 10.889c.22 0 .396-.085.53-.255.912-1.124 1.368-2.406 1.368-3.848 0-1.442-.456-2.725-1.368-3.85-.113-.148-.265-.232-.456-.254-.19-.02-.357.029-.498.149-.149.12-.232.274-.25.461-.017.187.034.355.154.504.707.869 1.06 1.866 1.06 2.99 0 1.123-.353 2.12-1.06 2.99-.12.148-.171.316-.153.503.017.187.1.338.249.45.127.107.268.16.424.16zm-4.549-.88c.029 0 .071-.007.128-.021.141-.057.212-.163.212-.319v-5.767c0-.156-.071-.262-.212-.318-.135-.057-.258-.032-.371.074l-1.76 1.77h-1.39c-.098 0-.18.032-.243.096-.064.063-.096.145-.096.244v2.035c0 .1.032.18.096.244.063.064.145.096.244.096h1.389l1.76 1.77c.077.064.159.096.243.096zm2.312-.69c.19 0 .357-.07.498-.211.615-.658.923-1.432.923-2.322 0-.891-.308-1.665-.923-2.322-.127-.135-.286-.205-.477-.212-.19-.008-.353.053-.488.18-.134.127-.205.284-.212.472-.007.187.057.351.191.493.368.403.551.866.551 1.389s-.183.986-.55 1.389c-.135.14-.199.305-.192.493.007.187.078.344.212.471.142.12.297.18.467.18z"
        />
      </svg>
    </div>
  )
}

export default AudioIcon
