import { routerMiddleware } from 'connected-react-router'
import { History } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { makeRootReducer } from './reducer'

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['auth', 'currentThread', 'rightSidebar', 'error', 'router', 'leftSidebar', 'threads', 'snackbar'],
}

export const configureStore = (history: History) => {
  const rootReducer = makeRootReducer(history)
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const middlewares = applyMiddleware(routerMiddleware(history), thunk)
  const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const reduxMiddleware = composeEnhancer(middlewares)

  const store = createStore(persistedReducer, undefined, reduxMiddleware)
  const persistor = persistStore(store)

  return {
    persistor: persistor,
    store: store,
  }
}
