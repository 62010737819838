import * as React from 'react'

interface IAccountIconProps {
  className?: string
}

const AccountIcon = (props: IAccountIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" {...props}>
      <g fillRule="evenodd" transform="translate(-8 -8)">
        <path d="M32.206 19.111H21.89V8.794c0-.21-.083-.413-.232-.562-.15-.149-.351-.232-.562-.232H9.985c-.44 0-.795.355-.795.794v22.222c0 .21.084.413.233.561.148.15.35.233.561.233h22.222c.21 0 .413-.084.562-.233.149-.148.232-.35.232-.561V19.905c0-.21-.083-.413-.232-.562-.149-.149-.351-.232-.562-.232zM10.778 9.587h9.524v20.635H17.92v-3.174c0-.21-.084-.413-.233-.562-.148-.149-.35-.232-.561-.232h-3.175c-.438 0-.793.355-.793.794v3.174h-2.381V9.587zm3.968 20.635v-2.38h1.587v2.38h-1.587zm16.667 0h-9.524v-9.524h9.524v9.524zm-17.46-17.46h-1.588v-1.587h1.587v1.587zm2.38 0h-1.587v-1.587h1.587v1.587zm2.381 0h-1.587v-1.587h1.587v1.587zm-4.762 2.38h-1.587v-1.586h1.587v1.587zm2.381 0h-1.587v-1.586h1.587v1.587zm2.381 0h-1.587v-1.586h1.587v1.587zm-4.762 2.382h-1.587v-1.587h1.587v1.587zm2.381 0h-1.587v-1.587h1.587v1.587zm2.381 0h-1.587v-1.587h1.587v1.587zm-4.762 2.38h-1.587v-1.587h1.587v1.588zm2.381 0h-1.587v-1.587h1.587v1.588zm2.381 0h-1.587v-1.587h1.587v1.588zm-4.762 2.382h-1.587v-1.588h1.587v1.588zm2.381 0h-1.587v-1.588h1.587v1.588zm2.381 0h-1.587v-1.588h1.587v1.588zm-4.762 2.38h-1.587V23.08h1.587v1.588zm2.381 0h-1.587V23.08h1.587v1.588zm2.381 0h-1.587V23.08h1.587v1.588zm6.35-.793h-1.588v-1.587h1.587v1.587zm2.38 0h-1.587v-1.587h1.587v1.587zm2.381 0h-1.587v-1.587h1.587v1.587zm-4.762 2.381h-1.587v-1.587h1.587v1.587zm2.381 0h-1.587v-1.587h1.587v1.587zm2.381 0h-1.587v-1.587h1.587v1.587zm-4.762 2.38h-1.587v-1.586h1.587v1.587zm2.381 0h-1.587v-1.586h1.587v1.587zm2.381 0h-1.587v-1.586h1.587v1.587z" />
      </g>
    </svg>
  )
}

export default AccountIcon
