import React from 'react'
import { makeStyles, Theme, createStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { ReturnToPreviousPageArrow } from 'component/returnToPreviousPageArrow'

const useSharingGroupScreenHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    sharingGroupScreenHeaderRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
      paddingBlock: 20,
    },
    title: {
      flex: 1,
      fontSize: 24,
      fontWeight: 500,
    },
    backArrow: {
      color: theme.threadPalette.lightGray,
      cursor: 'pointer',
      height: 30,
      width: 30,
      '&:hover': {
        color: theme.threadPalette.onFocusGray,
      },
    },
  })
)

interface ISharingGroupScreenHeaderProps {
  text: string | JSX.Element
  icon?: JSX.Element
  onReturnToPreviousScreenClick?: () => void
  className?: string
  actionElement?: JSX.Element
}

export const SharingGroupScreenHeader = ({
  text,
  icon,
  onReturnToPreviousScreenClick,
  className,
  actionElement,
}: ISharingGroupScreenHeaderProps) => {
  const classes = useSharingGroupScreenHeaderStyles()

  return (
    <div className={classNames(classes.sharingGroupScreenHeaderRoot, className)}>
      {onReturnToPreviousScreenClick && <ReturnToPreviousPageArrow onClick={onReturnToPreviousScreenClick} />}
      {icon}
      {typeof text === 'string' && <Typography className={classes.title}>{text}</Typography>}
      {typeof text === 'object' && text}
      {!!actionElement && actionElement}
    </div>
  )
}
