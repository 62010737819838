import React from 'react'
import classNames from 'classnames'

import StarredIcon from '@material-ui/icons/Star'
import UnstarredIcon from '@material-ui/icons/StarBorder'
import { IconButton, Theme, makeStyles } from '@material-ui/core'

export const useStarButtonStyles = makeStyles((theme: Theme) => ({
  startButtonRoot: {
    cursor: 'pointer',
    display: 'inline-flex',
  },
  starIcon: {
    color: theme.threadPalette.lightishGray,
    fontSize: 16,
  },
  starred: {
    color: theme.threadPalette.star,
  },
}))

interface StarButtonProps {
  onClick?(): void
  isStarred?: boolean
}

export function StarButton({ onClick, isStarred = false }: StarButtonProps) {
  const classes = useStarButtonStyles()
  const StarIcon = isStarred ? StarredIcon : UnstarredIcon

  const isDisabled = !!!onClick
  return (
    <IconButton size="small" onClick={onClick} className={classes.startButtonRoot} disabled={isDisabled}>
      <StarIcon className={classNames(classes.starIcon, { [classes.starred]: isStarred })} />
    </IconButton>
  )
}
