import { apiClient } from '@jetkit/react'
import { IUserActivate, IUserPatch } from 'model/userPatch'

export const loadUsers = async (): Promise<IAPIUser[]> => {
  const result = await apiClient.get('/user')
  return result.data
}

export const getActivatedUserByEmail = async (email: string): Promise<TUser> => {
  return (await apiClient.get(`/user/activation`, { params: { email } })).data
}

export const patchCurrentUser = async (userDetails: IUserPatch): Promise<void> => {
  return (await apiClient.patch(`/user/me`, userDetails)).data
}

export const activateCurrentUser = async (): Promise<IUser> => {
  return (await apiClient.post(`/v2/user/me/activation`)).data
}

export const updateUserInfoOnActivation = async (userDetails: IUserActivate): Promise<IUser> => {
  return (await apiClient.post(`/v2/user/me/activation/info`, userDetails)).data
}

export const updateCurrentThread = async (threadId: string) => {
  const result = await apiClient.patch(`/v2/user/current-thread/${threadId}`)
  return result.data
}
