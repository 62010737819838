import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Slide, Snackbar, Theme, Typography } from '@material-ui/core'
import SyncIcon from '@material-ui/icons/Sync'
import ArrowUp from '@material-ui/icons/ArrowDropUp'
import { TransitionProps } from '@material-ui/core/transitions'
import { useBoolean } from 'hooks/useBoolean'
import classNames from 'classnames'
const FIVE_SECONDS_IN_MILLISECONDS = 5000
const useSyncingInboxSnackbarStyles = makeStyles((theme: Theme) => ({
  snackbarRoot: {
    cursor: 'pointer',
    right: 0,
    top: 0,
    '&:hover': {
      transform: 'scale(1.03)',
    },
  },
  snackbarText: {
    display: 'inline-flex',
    fontSize: 16,
    gap: 4,
    placeItems: 'center',
  },
  snackbarMessageContentWrapper: {
    alignItems: 'center',
    backgroundColor: theme.threadPalette.green,
    borderRadius: 0,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    boxShadow: '0 1px 3px 0 rgba(200, 200, 200, 0.5);',
    color: theme.threadPalette.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: 8,
    marginRight: 40,
    paddingBlock: 4,
    paddingLeft: 24,
    paddingRight: 16,
  },
  collapsedContent: {
    borderBottomRightRadius: 0,
    marginRight: 0,
    paddingBottom: 6,
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 2,
  },
  syncIconContainer: {
    animation: '$spin 1.3s linear infinite',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  syncIcon: {
    height: 20,
    transform: 'rotateY(180deg)',
    width: 20,
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}))

interface ISyncingInboxSnackbar {
  isSyncingInbox: boolean
}

const TransitionDown = (props: TransitionProps) => {
  return <Slide {...props} direction="down" />
}

export const SyncingInboxSnackbar = ({ isSyncingInbox }: ISyncingInboxSnackbar) => {
  const classes = useSyncingInboxSnackbarStyles()
  const { state: isCollapsed, toggle: toggleIsCollapsed, setValue: setIsCollapsed } = useBoolean(false)

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isSyncingInbox}
      onClose={() => setIsCollapsed(true)}
      autoHideDuration={FIVE_SECONDS_IN_MILLISECONDS}
      TransitionComponent={TransitionDown}
      classes={{ anchorOriginTopRight: classes.snackbarRoot }}
      onClick={toggleIsCollapsed}
    >
      <div className={classNames(classes.snackbarMessageContentWrapper, { [classes.collapsedContent]: isCollapsed })}>
        <div className={classes.syncIconContainer}>
          <SyncIcon className={classes.syncIcon} />
        </div>
        {!isCollapsed && (
          <Typography className={classes.snackbarText}>
            Syncing your inbox
            <ArrowUp className={classes.syncIcon} />
          </Typography>
        )}
      </div>
    </Snackbar>
  )
}
