import * as React from 'react'

interface ICrownIconProps {
  className?: string
}

const CrownIcon = (props: ICrownIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        d="M15.85 15.6H4.15c-.248 0-.45.203-.45.45v.9c0 .248.202.45.45.45h11.7c.248 0 .45-.202.45-.45v-.9c0-.248-.203-.45-.45-.45zm1.8-9c-.745 0-1.35.605-1.35 1.35 0 .2.045.385.124.557l-2.037 1.22c-.433.26-.992.113-1.243-.326l-2.292-4.01c.301-.248.498-.62.498-1.041C11.35 3.605 10.745 3 10 3c-.745 0-1.35.605-1.35 1.35 0 .422.197.793.498 1.04L6.856 9.402c-.25.439-.813.585-1.244.326L3.58 8.507c.076-.169.124-.357.124-.557 0-.745-.605-1.35-1.35-1.35C1.607 6.6 1 7.205 1 7.95c0 .745.605 1.35 1.35 1.35.073 0 .146-.011.217-.023L4.6 14.7h10.8l2.033-5.422c.07.01.144.022.217.022.745 0 1.35-.605 1.35-1.35 0-.745-.605-1.35-1.35-1.35z"
      />
    </svg>
  )
}

export default CrownIcon
