import React from 'react'
import { OnboardingStepTextLines } from '../onboardingStepTextLines'

export const ViewAccessBadgeStep = () => {
  const textLines = [
    <>
      Click here to "<strong>follow</strong>" this thread and receive an email digest when new messages arrive.
    </>,
  ]

  return <OnboardingStepTextLines textLines={textLines} maxWidth={320} />
}
