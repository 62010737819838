import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

import SubmitButtonWithLoading from 'component/submitButtonWithLoading'
import ErrorDialogAnchoredOnComponent from 'component/dialogAnchoredOnComponent'
import EmailIcon from 'icons/email'
import {
  IDialogAction,
  IDialogAnchoredOnComponentProps,
} from 'component/dialogAnchoredOnComponent/dialogAnchoredOnComponent'

const useMessageSendButtonStyles = makeStyles((theme: Theme) => ({
  sendButton: {
    borderRadius: 5,
    fontSize: 18,
    height: 40,
    marginLeft: 10,
    textTransform: 'capitalize',
    width: 130,
  },
  btnIcon: {
    fill: theme.threadPalette.white,
    height: 18,
    width: 18,
  },
}))

interface IMessageSendButtonProps {
  onSend(): void
  isSending: boolean
  isDisabled: boolean
  isErrorDialogOpen: boolean
  errorMessage?: IDialogAnchoredOnComponentProps['message']
  errorDialogAnchorElement: HTMLElement | null
  onCloseErrorDialog(): void
  errorDialogActions?: IDialogAction[]
}

const MessageSendButton = React.forwardRef<HTMLButtonElement, IMessageSendButtonProps>(
  (
    {
      onSend,
      isSending,
      isDisabled,
      isErrorDialogOpen,
      errorMessage,
      errorDialogAnchorElement,
      onCloseErrorDialog,
      errorDialogActions,
    },
    ref
  ) => {
    const classes = useMessageSendButtonStyles()
    return (
      <ErrorDialogAnchoredOnComponent
        open={isErrorDialogOpen}
        anchoredElementRef={errorDialogAnchorElement}
        title="Error"
        message={errorMessage}
        onClose={onCloseErrorDialog}
        actions={errorDialogActions}
      >
        <SubmitButtonWithLoading
          ref={ref}
          className={classes.sendButton}
          color="primary"
          variant="contained"
          onClick={onSend}
          disabled={isDisabled}
          isLoading={isSending}
          startIcon={<EmailIcon className={classes.btnIcon} />}
        >
          Send
        </SubmitButtonWithLoading>
      </ErrorDialogAnchoredOnComponent>
    )
  }
)

export default MessageSendButton
