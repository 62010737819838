import { Button } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

import { theme } from '../../theme'

interface ISigninButtonProps {
  className?: string
  icon?: string
  text: string
  onClick?: (event: React.SyntheticEvent) => void
  dataCy?: string
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      borderRadius: '5px',
      boxShadow: 'none !important',
      overflow: 'hidden',
      height: '60px',
      position: 'relative',
      '@media screen and (max-width: 425px)': {
        padding: '15px 0px',
      },
    },
    label: {
      fontSize: '23px',
      lineHeight: '38px',
      '@media screen and (max-width: 425px)': {
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
    startIcon: {
      background: theme.threadPalette.white,
      borderBottomLeftRadius: '5px',
      borderTopLeftRadius: '5px',
      display: 'block',
      height: 'calc(100% - 2px)',
      left: '1px',
      margin: '0px',
      position: 'absolute',
      top: '1px',
      width: '70px',
      '@media screen and (max-width: 425px)': {
        width: '48px',
      },
    },
    startIconImg: {
      marginTop: '13px',
      '@media screen and (max-width: 425px)': {
        marginTop: '4px',
      },
    },
  }
})

export const SigninButton = (props: ISigninButtonProps) => {
  const classes = useStyles(theme)

  // start icon will be added to the beginning of the button
  let startIcon = null
  if (props.icon) {
    startIcon = <img className={classes.startIconImg} src={props.icon} alt="" />
  }

  return (
    <Button
      data-cy={props.dataCy}
      className={props.className}
      classes={{
        label: classes.label,
        root: classes.root,
        startIcon: classes.startIcon,
      }}
      color="primary"
      fullWidth
      startIcon={startIcon}
      variant="contained"
      onClick={props.onClick}
    >
      {props.text}
    </Button>
  )
}
