import React from 'react'
import { makeStyles, Theme, createStyles, Paper, Typography, Tooltip } from '@material-ui/core'
import IAttachment from 'model/attachment'
import { useTextInEllipsis } from 'hooks/useTextInEllipsis'
import { AttachmentContentTypeIcon } from 'component/attachmentContentTypeIcon/attachmentContentTypeIcon'

const useAttachmentCardSmallStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachmentCardSmallRoot: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      gap: 8,
      height: 38,
      justifyContent: 'flex-start',
      paddingInline: 8,
      width: 200,
      '&:hover': {
        boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.2)',
        '& .MuiTypography-root': {
          color: theme.palette.primary.main,
        },
        '& .MuiSvgIcon-root': {
          display: 'block',
        },
      },
    },
    fileExtentionIcon: {
      width: 20,
    },
    fileNameLabel: {
      fontSize: 15,
      fontWeight: 500,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  })
)

interface IAttachmentCardSmallProps {
  attachment: IAttachment
  onOpenAttachment(): void
}

export const AttachmentCardSmall = ({ attachment, onOpenAttachment }: IAttachmentCardSmallProps) => {
  const classes = useAttachmentCardSmallStyles()

  const { textRef: filenameRef, hasEllipsis: showTooltip } = useTextInEllipsis()

  return (
    <Paper className={classes.attachmentCardSmallRoot} onClick={onOpenAttachment}>
      <div className={classes.fileExtentionIcon}>
        <AttachmentContentTypeIcon attachment={attachment} />
      </div>
      <Tooltip title={showTooltip ? attachment.filename : ''} arrow={true} aria-label="attachment filename">
        <Typography ref={filenameRef} className={classes.fileNameLabel}>
          {attachment.filename}
        </Typography>
      </Tooltip>
    </Paper>
  )
}
