import * as React from 'react'
import { useIconStyles } from '../styles'
import classnames from 'classnames'

interface IExcelIconProps extends JSX.IntrinsicAttributes {
  width?: number
  height?: number
  className?: string
  fill?: string
}

const ExcelIcon = ({ className, width, height, fill }: IExcelIconProps) => {
  const classes = useIconStyles()
  return (
    <div className={classnames(className, classes.iconWrapper)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : '20'}
        height={height ? height : '20'}
        viewBox="0 0 20 20"
      >
        <path
          fill={fill ? fill : '#007339'}
          d="M17.268 19.714c.283 0 .523-.099.72-.297.199-.198.298-.438.298-.72V6.481c0-.283-.071-.594-.212-.933-.142-.34-.311-.608-.51-.806l-3.307-3.308c-.198-.198-.467-.367-.806-.509-.34-.141-.65-.212-.933-.212h-9.5c-.283 0-.523.1-.721.297C2.099 1.21 2 1.45 2 1.732v16.964c0 .283.099.523.297.721.198.198.438.297.72.297h14.25zm-.34-1.357H3.359V2.071H11.5v4.411c0 .283.099.523.297.721.198.198.438.297.72.297h4.412v10.857zm-.084-12.214h-3.987V2.156c.205.07.35.149.435.234l3.318 3.318c.085.085.163.23.234.435zM9.528 17v-1.124h-.795l1.092-1.707c.035-.05.07-.108.106-.175.035-.067.062-.115.08-.143.017-.028.03-.042.036-.042h.022c.007.028.024.063.053.106.014.028.03.054.047.08l.064.084.069.09 1.134 1.707h-.805V17h3.085v-1.124h-.721l-2.036-2.894 2.068-2.99h.71V8.857H10.78v1.135h.785l-1.092 1.685c-.029.05-.064.108-.106.175-.043.068-.075.115-.096.144l-.021.031h-.021c-.007-.028-.025-.063-.053-.106-.043-.077-.103-.159-.18-.244L8.87 9.992h.805V8.857H6.602v1.135h.72l2.004 2.884-2.056 3h-.721V17h2.979z"
        />
      </svg>
    </div>
  )
}

export default ExcelIcon
