import React from 'react'
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import CheckAuthProvider from '../CheckAuthProvider'
import { CheckUserIsOnboarded } from '../checkUserIsOnboarded'

export interface IRouteMiddlewareProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps>
}

export const AuthenticatedRoute: React.FC<IRouteMiddlewareProps> = route => {
  const { component, ...routeProps } = route

  return (
    <Route
      {...routeProps}
      render={props => {
        return (
          <CheckAuthProvider>
            <CheckUserIsOnboarded>
              <route.component {...props} />
            </CheckUserIsOnboarded>
          </CheckAuthProvider>
        )
      }}
    />
  )
}

export const UnauthenticatedRouteWithUserAuthOnboarding: React.FC<IRouteMiddlewareProps> = route => {
  const { component, ...routeProps } = route

  return (
    <Route
      {...routeProps}
      render={props => {
        return (
          <CheckUserIsOnboarded>
            <route.component {...props} />
          </CheckUserIsOnboarded>
        )
      }}
    />
  )
}
