import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { connectThreadToProject } from 'api/v2/project'
import { useProjectActions } from 'hooks/useProjects/useProjectActions'
import { useThreadProjectActions } from 'hooks/useThreadProjects/useThreadProjectActions'
import { IProject } from 'model/project'
import { useDispatch } from 'react-redux'

interface IUseConnectThreadToProjectUseCaseProps {
  onSuccess?: (project: IProject) => void
}

interface IConnectThreadToProjectUseCaseProps {
  threadId: string
  projectId: string
}

export const useConnectThreadToProjectUseCase = ({ onSuccess }: IUseConnectThreadToProjectUseCaseProps) => {
  const dispatch = useDispatch()
  const { refreshThreadProjects } = useThreadProjectActions()
  const { refreshProjectsList, refreshProject } = useProjectActions()

  const connectThreadToProjectHandler = async ({ threadId, projectId }: IConnectThreadToProjectUseCaseProps) => {
    return await connectThreadToProject({ projectId: projectId, threadId: threadId })
  }

  const connectThreadToProjectMutation = useMutation(connectThreadToProjectHandler, {
    onSuccess: (data, variables) => {
      const project = data
      onSuccess?.(project)
    },
    onError: () => {
      dispatch(showSnackbar({ message: 'Error trying to connect project' }))
    },
    onSettled: (data, error, variables) => {
      const { threadId, projectId } = variables

      connectThreadToProjectMutation.reset()

      refreshThreadProjects(threadId)
      refreshProject(projectId)
      refreshProjectsList()
    },
  })

  const connectThreadToProjectUseCase = async (useCaseProps: IConnectThreadToProjectUseCaseProps) => {
    const project = await connectThreadToProjectMutation.mutateAsync(useCaseProps)
    return project
  }

  return {
    connectThreadToProjectUseCase,
    isLoading: connectThreadToProjectMutation.isLoading,
  }
}
