import * as React from 'react'
import { createStyles, makeStyles } from '@material-ui/styles'
import { Theme, Menu, MenuItem, useTheme } from '@material-ui/core'
import IAttachment from 'model/attachment'
import classnames from 'classnames'
import { MoreHoriz } from '@material-ui/icons'
import { AttachmentInfoCard } from './attachmentInfoCard'
import { useMenu } from 'hooks/useMenu'
import { useDownloadAttachmentUseCase } from 'usecases/attachment/downloadAttachment'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'

const useMessageAttachmentStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainWrapper: {
      alignItems: 'center',
      backgroundColor: theme.threadPalette.white,
      borderRadius: 4,
      boxShadow: `0 1px 3px 0 rgba(0, 0, 0, 0.2)`,
      cursor: 'pointer',
      display: 'flex',
      height: 60,
      justifyContent: 'space-between',
      marginBottom: 5,
      marginTop: 5,
      paddingLeft: 15,
      paddingRight: 15,
      width: 420,
      '&:hover': {
        boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.2)',
        '& .MuiTypography-root': {
          color: theme.palette.primary.main,
        },
        '& .MuiSvgIcon-root': {
          display: 'block',
        },
      },
    },
    fileWrapper: {
      alignItems: 'center',
      display: 'flex',
    },
    nameTextWrapper: {
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
    },
    dateTextWrapper: {
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
    iconStyle: {
      marginRight: 8,
    },
    textStyle: {
      color: theme.threadPalette.menuItem.current,
      fontSize: 12,
      fontWeight: 300,
      marginRight: 2,
    },
    fileNameStyle: {
      color: theme.threadPalette.menuItem.current,
      display: 'inline-block',
      fontSize: 16,
      fontWeight: 500,
      height: 21,
      marginBottom: 2,
      maxWidth: 250,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    menuIcon: {
      display: 'none',
      fill: theme.palette.primary.main,
    },
    menuItem: {
      color: theme.threadPalette.brownishGray,
      margin: 0,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.threadPalette.lighterGray,
      },
    },
  })
)

interface IMessageAttachmentProps {
  attachment: IAttachment
  className?: string
  onOpenAttachment?(): void
}

const MessageAttachment = ({ attachment, className, onOpenAttachment }: IMessageAttachmentProps) => {
  const classes = useMessageAttachmentStyles()
  const theme = useTheme()

  const { currentThread } = useCurrentThreadContext()
  const { isOpen: isMenuOpen, menuPosition: menuAnchorPosition, setMenuPosition, onMenuIconClick } = useMenu()
  const { downloadAttachmentUseCase } = useDownloadAttachmentUseCase()

  const openMenu = (event: React.MouseEvent) => onMenuIconClick(event)

  const closeMenu = (event: React.MouseEvent) => {
    event.stopPropagation()
    setMenuPosition(undefined)
  }

  const onDownload = (event: React.MouseEvent) => {
    if (!currentThread) return
    closeMenu(event)
    downloadAttachmentUseCase({
      attachmentId: attachment.id,
      fileName: attachment.filename,
      threadId: currentThread.id,
    })
  }

  return (
    <div className={classnames(classes.mainWrapper, className)} onClick={onOpenAttachment}>
      <AttachmentInfoCard attachment={attachment} fillColor={theme.threadPalette.menuItem.current} />
      <div>
        <MoreHoriz onClick={openMenu} className={classes.menuIcon} />
      </div>
      <Menu open={isMenuOpen} onClose={closeMenu} anchorReference="anchorPosition" anchorPosition={menuAnchorPosition}>
        <MenuItem onClick={onDownload}>Download</MenuItem>
      </Menu>
    </div>
  )
}

export default MessageAttachment
