import * as GoogleOAuthAPI from '../api/googleOAuth'
import { GOOGLE_OAUTH_ACTIONS } from '../constants/actions/googleOAuth'

import { OAuthLoginActionCreator, OAuthHandleCallbackActionCreator } from '../lib/oauth/actions'

export const login = OAuthLoginActionCreator(GOOGLE_OAUTH_ACTIONS.LOGIN, GoogleOAuthAPI)

export const handleCallback = OAuthHandleCallbackActionCreator(
  GOOGLE_OAUTH_ACTIONS.HANDLE_CALLBACK,
  GoogleOAuthAPI,
  'gmail'
)
