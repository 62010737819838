import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import SettingsMenu from 'component/settingsMenu'
import { IMenuItem } from 'component/menu/menu'
import AccountPageSelect from './accountPageSelect'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
}))

const menuItems: IMenuItem<TAccountPage>[] = [
  {
    value: 'profile',
    label: 'Profile',
  },
  {
    value: 'team',
    label: 'Team',
  },
  {
    value: 'organization',
    label: 'Organization',
    requiredPermissions: ['organization_view'],
  },
]

export type TAccountPage = 'profile' | 'team' | 'organization'

const Account = () => {
  const classes = useStyles()
  const [selectedPage, setPage] = React.useState<TAccountPage>('profile')

  const handleOnItemSelected = React.useCallback((item: TAccountPage) => {
    setPage(item)
  }, [])

  return (
    <div className={classes.root}>
      <SettingsMenu items={menuItems} onItemSelected={handleOnItemSelected} />
      <AccountPageSelect selectedPage={selectedPage} />
    </div>
  )
}

export default Account
