import * as React from 'react'
import { useIconStyles } from '../styles'
import classnames from 'classnames'

interface IPowerpointIconProps extends JSX.IntrinsicAttributes {
  width?: number
  height?: number
  className?: string
  fill?: string
}

const PowerpointIcon = ({ className, width, height, fill }: IPowerpointIconProps) => {
  const classes = useIconStyles()
  return (
    <div className={classnames(className, classes.iconWrapper)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : '20'}
        height={height ? height : '20'}
        viewBox="0 0 20 20"
      >
        <path
          fill={fill ? fill : '#D4461A'}
          d="M17.268 19.714c.283 0 .523-.099.72-.297.199-.198.298-.438.298-.72V6.481c0-.283-.071-.594-.212-.933-.142-.34-.311-.608-.51-.806l-3.307-3.308c-.198-.198-.467-.367-.806-.509-.34-.141-.65-.212-.933-.212h-9.5c-.283 0-.523.1-.721.297C2.099 1.21 2 1.45 2 1.732v16.964c0 .283.099.523.297.721.198.198.438.297.72.297h14.25zm-.34-1.357H3.359V2.071H11.5v4.411c0 .283.099.523.297.721.198.198.438.297.72.297h4.412v10.857zm-.084-12.214h-3.987V2.156c.205.07.35.149.435.234l3.318 3.318c.085.085.163.23.234.435zM9.878 17v-1.124h-.986v-1.77h1.452c.538 0 .955-.054 1.251-.16.474-.162.85-.47 1.13-.922.279-.452.418-.968.418-1.548 0-.573-.13-1.07-.392-1.495-.261-.424-.615-.732-1.06-.922-.34-.135-.799-.202-1.379-.202H6.412v1.135h.975v5.884h-.975V17h3.467zm.275-4.093H8.892v-2.841h1.272c.368 0 .661.063.88.19.396.234.594.64.594 1.22 0 .63-.22 1.053-.658 1.272-.219.106-.494.16-.827.16z"
        />
      </svg>
    </div>
  )
}

export default PowerpointIcon
