import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    padding: '26px 36px 36px 36px',
    backgroundColor: theme.threadPalette.backgroundColor,
    borderRadius: 4,
    boxShadow:
      '0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  footer: {
    marginTop: 70,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  title: {
    fontSize: 23,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    alignSelf: 'flex-end',
  },

  contentText: {
    marginTop: 20,
    marginRight: 160,
    fontSize: 17,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.threadPalette.lightGray,
  },

  closeButton: {
    marginBottom: 5,
    width: 30,
    height: 30,
    fill: theme.threadPalette.lightGray,
    '&:hover': {
      fill: theme.palette.primary.main,
    },
  },

  button: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  cancelButton: {
    width: 120,
  },

  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.threadPalette.white,
    marginLeft: 15,
    width: 150,
  },
}))
