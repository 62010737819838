import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { stopSharingMessageWithSharingGroup as stopSharingMessageWithSharingGroupApiCall } from 'api/v2/sharingGroup'
import { showSnackbar } from 'actions/snackbar'
import { useThreadSharingSettingsCacheActions } from 'hooks/useSharingGroup/useThreadSharingSettingsCacheActions'

interface IUseStopSharingMessageWithSharingGroupUseCaseProps {
  onSuccess?(): void
}

type IUseStopSharingMessageWithSharingGroupUseCaseMutationParams = Normalize<
  Parameters<typeof stopSharingMessageWithSharingGroupApiCall>[0] & { threadId: string }
>

export const useStopSharingMessageWithSharingGroupUseCase = ({
  onSuccess,
}: IUseStopSharingMessageWithSharingGroupUseCaseProps = {}) => {
  const dispatch = useDispatch()

  const threadSharingSettingsCacheActions = useThreadSharingSettingsCacheActions()

  const {
    reset: resetStopSharingMessageWithSharingGroupMutation,
    mutateAsync,
    isLoading,
  } = useMutation({
    mutationFn: ({ threadId, ...props }: IUseStopSharingMessageWithSharingGroupUseCaseMutationParams) =>
      stopSharingMessageWithSharingGroupApiCall(props),
    onError: () => dispatch(showSnackbar({ message: 'Failed to stop sharing message' })),
    onSuccess: onSuccess,
    onSettled: (_, __, stopSharingMessageParams) => {
      const { threadId } = stopSharingMessageParams

      threadSharingSettingsCacheActions.refreshData(threadId)
      resetStopSharingMessageWithSharingGroupMutation()
    },
  })

  const stopSharingMessageWithSharingGroupUseCase: (
    props: IUseStopSharingMessageWithSharingGroupUseCaseMutationParams
  ) => Promise<void> = mutateAsync

  return { stopSharingMessageWithSharingGroupUseCase, isLoading }
}
