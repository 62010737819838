import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import { CustomCheckbox } from 'shared/inputs/customCheckbox'

const useInlineCheckboxStyles = makeStyles((theme: Theme) => ({
  inlineCheckboxRoot: {
    cursor: 'pointer',
  },
  inlineCheckbox: {
    padding: 2,
    paddingLeft: 4,
    paddingTop: 0,
  },
}))

interface IInlineCheckboxProps {
  label: string
  isChecked: boolean
  onClick(): void
}

export const InlineCheckbox = ({ label, isChecked, onClick }: IInlineCheckboxProps) => {
  const classes = useInlineCheckboxStyles()
  return (
    <span className={classes.inlineCheckboxRoot} onClick={onClick}>
      <CustomCheckbox
        disableRipple
        size="small"
        checked={isChecked}
        name="active"
        color="secondary"
        className={classes.inlineCheckbox}
      />
      <strong>{label}</strong>
    </span>
  )
}
