import * as React from 'react'
import { useStyles } from './organization.style'
import { Typography, Divider } from '@material-ui/core'
import TabWrapper, { ITabElement } from 'component/tabs/tabWrapper'
import { useUser } from 'hooks'
import OrgMembers from './members/orgMembers'
import OrgSettings from './settings/orgSettings'
import JoinOrg from './joinOrg'

const Organization = () => {
  const classes = useStyles()
  const user = useUser()

  const getOrganizationTabs = (user: TUser): ITabElement[] => {
    if (!user) return []

    return [
      {
        label: <>Members</>,
        page: <OrgMembers organization={user.organization} />,
      },
      {
        label: <>Settings</>,
        page: <OrgSettings organizationId={user.organization?.id} />,
      },
    ]
  }

  return (
    <div className={classes.root}>
      <Typography align="left" className={classes.header}>
        Organization
      </Typography>
      <Divider className={classes.divider} />
      {!user?.organization ? (
        <JoinOrg />
      ) : user.organization.owner_ids?.includes(user.id) ? (
        <TabWrapper isCompact tabs={getOrganizationTabs(user)} />
      ) : (
        <OrgMembers organization={user.organization} />
      )}
    </div>
  )
}

export default Organization
