import * as React from 'react'
import { createStyles, makeStyles, Theme, Tooltip, withStyles } from '@material-ui/core'
import { useUser } from 'hooks'
import ConnectEmailAccountDialog, {
  ConnectEmailAccountDialogContext,
} from 'component/connectEmailAccount/connectEmailAccountDialog'
import { AuthenticatedRoute, IRouteMiddlewareProps } from 'route/middlewares'
import { ConnectAccountPage } from 'page/connectAccountPage'

export const userHasAccess = (user: TUser, requiredPermissions?: TUserPermissions[]) => {
  if (!user) return false
  if (!requiredPermissions || requiredPermissions.length === 0) return true

  const requiredPermissionsMatchWithUser = requiredPermissions.filter(
    permission => user.permissions?.includes(permission)
  )

  return requiredPermissionsMatchWithUser.length > 0
}

interface IUserAccessCheckWrapper {
  requiredPermissions: TUserPermissions[]
  notAllowedComponent?: JSX.Element | null
  children: React.ReactNode
  placeholderText?: string
}

interface IUserHasNoAccessWrapper {
  children: React.ReactNode
  placeholderText?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabled: {
      pointerEvents: 'none',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
  })
)

// tooltip component to show when user has no access to the component
const UserHasNoAccessTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#fa8072',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #fa8072',
    marginTop: '0 px',
    paddingTop: '0 px',
  },
  // overriding distance to the parent component
  tooltipPlacementBottom: {
    margin: '4px 0',
  },
  tooltipPlacementTop: {
    margin: '4px 0',
  },
}))(Tooltip)

// wrapper around the component when user don't have access to it. will replace component default behavior with
// account connect dialog opening, add a tooltip on component hover.
const UserHasNoAccessWrapper: React.FC<IUserHasNoAccessWrapper> = props => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = React.useState(false)

  const { placeholderText = 'Click to connect your email account and get access.', children } = props

  return (
    <ConnectEmailAccountDialogContext.Provider value={{ isOpen: isOpen, setIsOpen: setIsOpen }}>
      <UserHasNoAccessTooltip title={placeholderText} placement="bottom-start">
        <div onClick={() => setIsOpen(true)} className={classes.cursorPointer}>
          <div className={classes.disabled}>{children}</div>
        </div>
      </UserHasNoAccessTooltip>

      <ConnectEmailAccountDialog />
    </ConnectEmailAccountDialogContext.Provider>
  )
}

// wrapper around the component to check user permission to access it.
// if notAllowedComponent is passed and user don't have access: notAllowedComponent component will be rendered.
// if notAllowedComponent is not passed and user don't have access: the component will be placed inside of the UserHasNoAccessWrapper wrapper to restrict access.
// if user has permission to the component, it will be rendered as usual.
export const UserAccessCheckWrapper = (props: IUserAccessCheckWrapper) => {
  const user = useUser()

  const { requiredPermissions, notAllowedComponent, children, placeholderText } = props
  const currentUserHasAccess = userHasAccess(user, requiredPermissions)

  if (currentUserHasAccess) return <>{children}</>

  if (notAllowedComponent === null) return notAllowedComponent

  if (notAllowedComponent === undefined)
    return <UserHasNoAccessWrapper placeholderText={placeholderText}> {children} </UserHasNoAccessWrapper>

  return <UserHasNoAccessWrapper placeholderText={placeholderText}>{notAllowedComponent}</UserHasNoAccessWrapper>
}

interface IAccessCheckRoute extends IRouteMiddlewareProps {
  requiredPermissions: TUserPermissions[]
}

// the access the route on top of AuthenticatedRoute
export const AccessCheckRoute = ({ requiredPermissions, ...props }: IAccessCheckRoute) => {
  const user = useUser()

  const currentUserHasAccess = userHasAccess(user, requiredPermissions)

  if (!currentUserHasAccess) props.component = ConnectAccountPage

  return <AuthenticatedRoute {...props} />
}
