import * as React from 'react'
import ImgLogoThreadLive from '../assets/logo_threadlive.svg'
import ImgProjects from '../assets/signin_projects.png'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import { push } from 'actions/navigation'
import { useDispatch } from 'react-redux'
import { SignInButtons } from 'component/signInButtons'
import { fade, Grow, Typography } from '@material-ui/core'
import Spacer from 'component/layoutUtils/spacer'
import classNames from 'classnames'

const useStyles = makeStyles((theme: Theme) => {
  return {
    pageGridContainer: {
      height: '100vh',
      width: '100vw',
      [theme.breakpoints.up('md')]: {
        backgroundColor: fade(theme.threadPalette.lightBlue, 0.08),
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      },
    },
    gridColumn: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
    },
    signinContainer: {
      backgroundColor: theme.threadPalette.white,
      border: `solid 2px ${theme.threadPalette.lightishGray}`,
      borderRadius: 10,
      boxShadow: '0 4px 40px 1px rgba(0, 0, 0, 0.1)',
      maxWidth: 400,
    },
    logoContainer: {
      display: 'grid',
      marginBlock: 30,
      placeContent: 'center',
    },
    logo: {
      cursor: 'pointer',
      height: 30,
      width: 'auto',
    },
    title: {
      color: theme.threadPalette.darkerBlue,
      fontSize: 28,
      fontWeight: 500,
      marginBlock: 10,
      paddingInline: 28,
      textAlign: 'center',
    },
    titleHighlight: {
      color: theme.palette.primary.main,
    },
    disclaimer: {
      backgroundColor: fade(theme.threadPalette.lightGray, 0.18),
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      fontSize: 12,
      margin: 0,
      marginTop: 30,
      paddingBlock: 16,
      paddingInline: 8,
      textAlign: 'center',
      width: '100%',
      '& a': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textDecoration: 'none',
      },
    },
    heroContainer: {
      background: 'linear-gradient(270deg, #0069ff, #47b4ff)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }
})

const SigninPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onLogoClick = () => {
    dispatch(push('/'))
  }

  return (
    <div className={classes.pageGridContainer}>
      <div className={classes.gridColumn}>
        <Grow in={true} timeout={800}>
          <div className={classes.signinContainer}>
            <div className={classes.logoContainer}>
              <img className={classes.logo} src={ImgLogoThreadLive} alt="ThreadLive" onClick={onLogoClick} />
            </div>
            <Grow in={true} timeout={500} style={{ transitionDelay: '500ms' }}>
              <Typography component="h1" className={classes.title}>
                <span className={classes.titleHighlight}>Stop searching your inbox</span> Keep important email here
              </Typography>
            </Grow>
            <Spacer height={20} />
            <SignInButtons />
            <Spacer height={10} />
            <p className={classes.disclaimer}>
              <strong>No credit card required.</strong> By signing up, you confirm to have read ThreadLive's{' '}
              <a target="_blank" rel="noreferrer" href="https://www.threadlive.com/privacy-policy">
                privacy policy
              </a>{' '}
              and agree to the{' '}
              <a target="_blank" rel="noreferrer" href="https://www.threadlive.com/tc">
                terms of service.
              </a>
            </p>
          </div>
        </Grow>
      </div>
      <div className={classNames(classes.gridColumn, classes.heroContainer)}>
        <img src={ImgProjects} alt="Projects and Threads" />
      </div>
    </div>
  )
}

export default SigninPage
