import { useDraft } from 'hooks/useDraft/useDraft'
import React from 'react'
import { DraftMessageBlockWithAvatar, IDraftMessageBlockWithAvatarProps } from './draftMessageBlockWithAvatar'
import { DraftMessageBlockWithAvatarSkeleton } from './draftMessageBlockWithAvatarSkeleton'

interface IDraftMessageWithSkeletonLoadingProps
  extends Omit<IDraftMessageBlockWithAvatarProps, 'draft' | 'initialToRecipients' | 'initialBccRecipients'> {
  draftMessageId: string
}

export const DraftMessageWithSkeletonLoading = ({
  draftMessageId,
  ...draftMessageBlockWithAvatarProps
}: IDraftMessageWithSkeletonLoadingProps) => {
  const { currentUser, draftContainerRef } = draftMessageBlockWithAvatarProps

  const { draftMessage, isLoading } = useDraft(draftMessageId)

  if (isLoading || !draftMessage) {
    return <DraftMessageBlockWithAvatarSkeleton currentUser={currentUser} draftContainerRef={draftContainerRef} />
  }

  const initialToRecipients = draftMessage.recipients ?? []
  const initialBccRecipients = draftMessage.bccRecipients ?? []

  return (
    <DraftMessageBlockWithAvatar
      draft={draftMessage}
      initialToRecipients={initialToRecipients}
      initialBccRecipients={initialBccRecipients}
      {...draftMessageBlockWithAvatarProps}
    />
  )
}
