import React from 'react'
import debounce from 'lodash/debounce'
import { makeStyles, Theme, TextField, Link, Typography } from '@material-ui/core'
import { fade } from '@material-ui/core/styles'
import { getThreadByTitle } from 'api/v2/thread'
import SubmitButtonWithLoading from 'component/submitButtonWithLoading'
import useCurrentUrl from '../../hooks/useCurrentUrl'
import { useBoolean } from 'hooks/useBoolean'
import { errorHandlingWrapper } from 'api/errors/errorHandlingWrapper'
import AppLoading from 'component/appLoading'
import { useOpenThreadUseCase } from 'usecases/openThread'
import { useCreateThreadFromThreadliveUseCase } from 'usecases/createThreadFromThreadlive'

const useStyles = makeStyles((theme: Theme) => ({
  threadTitleInput: {
    marginTop: 6,
  },
  threadTitleInputLabel: {
    color: theme.palette.secondary.contrastText,
    fontWeight: 400,
  },
  threadTitleInputPlaceholder: {
    '&::placeholder': {
      color: theme.palette.secondary.contrastText,
      fontStyle: 'italic',
      fontWeigth: 'normal',
    },
  },
  createButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  createButton: {
    fontSize: 16,
    height: 40,
    width: 130,
  },
  sameThreadTitleWarning: {
    backgroundColor: fade(theme.threadPalette.warning, 0.33),
    border: `solid 1px ${fade(theme.threadPalette.lightishGray, 0.33)}`,
    borderRadius: 14,
    color: theme.threadPalette.brownishGray,
    marginRight: 124,
    marginTop: 20,
    padding: 15,
  },
  threadLink: {
    color: theme.palette.primary.main,
  },
}))

interface IStartFromThreadliveContentProps {
  onSuccess?(): void
  projectId?: string
  navigateToThreadOnSuccess?: boolean
}

export const StartFromThreadliveContent = ({
  onSuccess,
  projectId,
  navigateToThreadOnSuccess = true,
}: IStartFromThreadliveContentProps) => {
  const classes = useStyles()

  const { openThreadByIdUseCase } = useOpenThreadUseCase()

  const [threadTitle, setThreadTitle] = React.useState<string>('')
  const [existingThreadIdWithSameName, setExistingThreadIdWithSameName] = React.useState<string>('')
  const { state: isLoadingThreadData, setValue: setIsLoadingThreadData } = useBoolean(false)

  const onThreadCreated = (newThread: IThread) => {
    onSuccess?.()
    navigateToThreadOnSuccess && openThreadByIdUseCase({ threadId: newThread.id, initialData: newThread })
  }

  const { isLoading, createThreadFromThreadliveUseCase } = useCreateThreadFromThreadliveUseCase({
    onThreadCreated,
  })

  const checkExistingThreadByTitle = async (title: string) => {
    setIsLoadingThreadData(true)
    const encodedTitle = encodeURIComponent(title)
    const { data: thread } = await errorHandlingWrapper<any>(() => getThreadByTitle(encodedTitle))
    setIsLoadingThreadData(false)

    setExistingThreadIdWithSameName(thread?.id)
    return thread
  }
  const debouncedCheckExistingThreadByTitle = React.useCallback(debounce(checkExistingThreadByTitle, 500), [])

  const onChangeThreadTitle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setExistingThreadIdWithSameName('')
    const title = event.target.value
    setThreadTitle(title)
    if (!title) return

    debouncedCheckExistingThreadByTitle(title)
  }

  return (
    <>
      <TextField
        label="Thread name"
        className={classes.threadTitleInput}
        placeholder="Becomes the subject of any email"
        variant="outlined"
        value={threadTitle}
        onChange={onChangeThreadTitle}
        InputLabelProps={{ shrink: true, classes: { root: classes.threadTitleInputLabel } }}
        InputProps={{
          endAdornment: <AppLoading visible={isLoadingThreadData} small padding={5} />,
          classes: {
            input: classes.threadTitleInputPlaceholder,
          },
        }}
      />
      {existingThreadIdWithSameName && <SameTitleThreadWarning threadId={existingThreadIdWithSameName} />}
      <div className={classes.createButtonWrapper}>
        <SubmitButtonWithLoading
          className={classes.createButton}
          color="primary"
          variant="contained"
          onClick={() => createThreadFromThreadliveUseCase(threadTitle, projectId)}
          disabled={threadTitle.length === 0 || isLoadingThreadData}
          isLoading={isLoading}
          disableElevation
        >
          {existingThreadIdWithSameName ? 'Proceed' : 'Create'}
        </SubmitButtonWithLoading>
      </div>
    </>
  )
}

interface ISameTitleThreadWarningProps {
  threadId: string
}

const SameTitleThreadWarning = (props: ISameTitleThreadWarningProps) => {
  const classes = useStyles()
  const { currentHost } = useCurrentUrl()

  return (
    <div className={classes.sameThreadTitleWarning}>
      <div>
        <Typography paragraph>
          You already have a thread with this name,
          <Link
            underline="none"
            href={`${currentHost}/threads/${props.threadId}`}
            target="_blank"
            rel="noopener"
            className={classes.threadLink}
          >
            {' '}
            view existing Thread.
          </Link>
        </Typography>
        <Typography paragraph>Having two threads with the same name could be confusing.</Typography>
        <Typography>Click proceed if you are ok with this.</Typography>
      </div>
    </div>
  )
}
