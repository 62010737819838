import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { IAttachmentContentDetails } from 'model/attachment'
import { useUser } from 'hooks/auth'

export interface IAttachmentContentURLDetails {
  attachmentId: string
  contentURL?: string
}

export const getAttachmentContentDetailsQueryKey = ({
  userId,
  attachmentId,
}: {
  userId: string
  attachmentId: string
}) => [userId, 'attachment', attachmentId, 'content-details']

export const useAttachmentContentDetailsCacheActions = () => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const refreshData = React.useCallback(
    ({ id }: Pick<IAttachmentContentDetails, 'id'>) => {
      if (!currentUser?.id) return

      const queryKey = getAttachmentContentDetailsQueryKey({ userId: currentUser.id, attachmentId: id })
      queryClient.invalidateQueries({ queryKey })
    },
    [queryClient, currentUser?.id]
  )

  return {
    refreshData,
  }
}
