import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import { IThreadSharingSettings } from 'model/sharingGroup'
import SharingGroupCard from './sharingGroupCard/sharingGroupCard'
import { ORDINAL_SUFFIX_FORMAT_CODE, formatDateWithOrdinal } from 'dataProcessors/dateTime'
import { DateTime } from 'luxon'
import { EditButton } from './editButton'
import { StopSharingThreadButton } from './stopSharingThreadButton'
import {
  getThreadSharingSettingsAutoShareConditionsTypes,
  isThreadSharingSettingsShareAllFutureMessages,
} from 'domain/sharingGroup'

const useConnectedThreadSharingSettingsCardStyles = makeStyles((theme: Theme) => ({
  connectedThreadSharingSettingsCardRoot: {
    display: 'inline-grid',
    gridTemplateColumns: '1fr auto',
  },
  threadSharingSettingsInfo: {
    border: 'unset',
    padding: 0,
  },
  threadSharingSettingsSummaryText: {
    fontSize: 12,
    lineHeight: 1.3,
    margin: 0,
    marginTop: 2,
    maxWidth: 420,
    paddingInlineStart: 16,
    '&> li': {
      marginBottom: 4,
    },
  },
  sharingSettingsActionsContainer: {
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'column',
    paddingBlock: 4,
  },
  editButton: {
    color: theme.threadPalette.lightBlue,
    fontWeight: 'bold',
  },
  stopSharingButton: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },
}))

interface IConnectedThreadSharingSettingsCardProps {
  threadSharingSettings: IThreadSharingSettings
  onEditGroup?(): void
  onEditSharingSettings?(): void
  onStopSharing?(): void
  manuallySharedMessageCount: number
}
export const ConnectedThreadSharingSettingsCard = ({
  threadSharingSettings,
  onEditGroup,
  onEditSharingSettings,
  onStopSharing,
  manuallySharedMessageCount,
}: IConnectedThreadSharingSettingsCardProps) => {
  const classes = useConnectedThreadSharingSettingsCardStyles()
  const cardRootRef = React.useRef<HTMLDivElement>(null)

  const sharingSettingsLastUpdatedAt = formatDateWithOrdinal(
    DateTime.fromISO(threadSharingSettings.updatedAt ?? threadSharingSettings.createdAt),
    `MMM d${ORDINAL_SUFFIX_FORMAT_CODE} yyyy @ hh:mm a`
  )

  const pluralizedMessage = manuallySharedMessageCount === 1 ? 'message' : 'messages'
  const sharedMessagesSummary = `${manuallySharedMessageCount} ${pluralizedMessage} before ${sharingSettingsLastUpdatedAt}`

  const getShareFutureMessagesQuantifiedText = (threadSharingSettings: IThreadSharingSettings) => {
    const isSharingAllFutureMessages = isThreadSharingSettingsShareAllFutureMessages(threadSharingSettings)
    if (isSharingAllFutureMessages) return 'All messages I receive'

    const autoShareConditionsTypes = getThreadSharingSettingsAutoShareConditionsTypes(threadSharingSettings)
    if (autoShareConditionsTypes.includes('reply_to_shared_message_with_same_audience'))
      return 'Only messages that include the same list of people from the previous shared message and any new recipients'

    return 'No messages'
  }

  const shareFutureMessagesQuantifiedText = getShareFutureMessagesQuantifiedText(threadSharingSettings)
  const shareFutureMessagesSummary = `${shareFutureMessagesQuantifiedText} after ${sharingSettingsLastUpdatedAt}`

  const editSharingGroupButton = onEditGroup ? (
    <EditButton label="Edit group" className={classes.editButton} onClick={onEditGroup} />
  ) : null

  return (
    <div className={classes.connectedThreadSharingSettingsCardRoot} ref={cardRootRef}>
      <SharingGroupCard
        sharingGroup={threadSharingSettings.sharingGroup}
        className={classes.threadSharingSettingsInfo}
        customEditButton={editSharingGroupButton}
      >
        <ul className={classes.threadSharingSettingsSummaryText}>
          <li>{sharedMessagesSummary}</li>
          <li>{shareFutureMessagesSummary}</li>
        </ul>
      </SharingGroupCard>
      <div className={classes.sharingSettingsActionsContainer}>
        {onEditSharingSettings && (
          <EditButton
            label="Edit share settings"
            className={classes.editButton}
            onClick={onEditSharingSettings}
          ></EditButton>
        )}
        {onStopSharing && (
          <StopSharingThreadButton
            sharingGroupName={threadSharingSettings.sharingGroup.name}
            onClick={onStopSharing}
            anchorElementRefForConfirmationPopup={cardRootRef}
          />
        )}
      </div>
    </div>
  )
}
