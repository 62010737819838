import * as React from 'react'
import IAttachment from '../../model/attachment'
import MessageAttachment from 'component/messageAttachment'
import { Paper } from '@material-ui/core'
import { Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'

const useMessageAttachmentsListStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageAttachmentsListRoot: {
      width: 'fit-content',
    },
  })
)

interface IMessageFileListProps {
  className?: string
  attachments: IAttachment[]
  onOpenAttachment?(attachment: IAttachment): void
}

const MessageAttachmentsList = ({ attachments, onOpenAttachment }: IMessageFileListProps) => {
  const classes = useMessageAttachmentsListStyles()

  return (
    <div className={classes.messageAttachmentsListRoot}>
      {attachments.map(attachment => (
        <Paper key={attachment.id}>
          <MessageAttachment
            attachment={attachment}
            key={attachment.id}
            onOpenAttachment={() => onOpenAttachment?.(attachment)}
          />
        </Paper>
      ))}
    </div>
  )
}

export default MessageAttachmentsList
