import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import * as React from 'react'
import UserAvatar from '../userAvatar'

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: '10px',
      paddingRight: '10px',
      height: '60px',
    },
    userInfoContent: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '5px',
      justifyContent: 'flex-start',
      alignItems: 'left',
    },
    userName: {
      color: theme.threadPalette.menuItem.current,
      fontSize: '17px',
    },
    userEmail: {
      marginTop: '5px',
      color: theme.threadPalette.menuItem.current,
      fontSize: '13px',
    },
    divider: {
      width: '100%',
      height: '1px',
      color: theme.threadPalette.lightGray,
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  })
)

interface IMentionElementProps {
  user: IUser
}

export const MentionElement = (props: IMentionElementProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.contentWrapper}>
        <UserAvatar user={props.user} />
        <div className={classes.userInfoContent}>
          <div className={classes.userName}>{props.user.name}</div>
          <div className={classes.userEmail}>{props.user.email}</div>
        </div>
      </div>
      <div className={classes.divider} />
    </div>
  )
}
