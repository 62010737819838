import { IAutoShareCondition } from 'model/sharingGroup'
import React, { useContext } from 'react'

export type TMessagesSelectorOption = 'allMessages' | 'selectedMessages'

type IThreadSharingSettingsFormFieldsContext = {
  activeMessagesSelectorOption: TMessagesSelectorOption | null
  setActiveMessagesSelectorOption(newOption: TMessagesSelectorOption | null): void
  selectedMessagesIds: string[]
  setSelectedMessagesIds(newSelectedMessagesIds: string[]): void
  notifyMembersByEmail: boolean
  toggleNotifyMembersByEmail(): void
  notificationEmailMessage: string
  setNotificationEmailMessage(newMessage: string): void
  clearSelectedMessagesIds(): void
  autoShareConditions?: IAutoShareCondition[]
  setAutoShareConditions(autoShareCondition?: IAutoShareCondition[]): void
}

interface IThreadSharingSettingsFormFieldsContextProps {
  selectedMessagesIdsInitialValue?: string[]
  autoShareConditionsInitialValue?: IAutoShareCondition[]
}

export const ThreadSharingSettingsFormFieldsContext = React.createContext<
  IThreadSharingSettingsFormFieldsContext | undefined
>(undefined)

export const ThreadSharingSettingsFormFieldsContextProvider: React.FC<IThreadSharingSettingsFormFieldsContextProps> = ({
  selectedMessagesIdsInitialValue = [],
  autoShareConditionsInitialValue,
  children,
} = {}) => {
  const initialMessagesSelectOption = selectedMessagesIdsInitialValue?.length > 0 ? 'selectedMessages' : null
  const [activeMessagesSelectorOption, setActiveMessagesSelectorOption] =
    React.useState<TMessagesSelectorOption | null>(initialMessagesSelectOption)

  const [selectedMessagesIds, setSelectedMessagesIds] = React.useState<string[]>(selectedMessagesIdsInitialValue)

  const [autoShareConditions, setAutoShareConditions] = React.useState(autoShareConditionsInitialValue)

  const [notifyMembersByEmail, setNotifyMembersByEmail] = React.useState(false)
  const [notificationEmailMessage, setNotificationEmailMessage] = React.useState('')

  const clearSelectedMessagesIds = () => setSelectedMessagesIds([])

  const changeExistingThreadMessagesSharingOption = (newOption: TMessagesSelectorOption | null) => {
    if (newOption === activeMessagesSelectorOption) return

    if (!newOption) {
      clearSelectedMessagesIds()
    }
    setActiveMessagesSelectorOption(newOption)
  }

  const changeSelectedMessagesIds = (newSelectedMessagesIds: string[]) => {
    setSelectedMessagesIds(newSelectedMessagesIds)
    if (activeMessagesSelectorOption === 'selectedMessages' && newSelectedMessagesIds.length === 0)
      setActiveMessagesSelectorOption(null)
  }

  return (
    <ThreadSharingSettingsFormFieldsContext.Provider
      value={{
        activeMessagesSelectorOption,
        setActiveMessagesSelectorOption: changeExistingThreadMessagesSharingOption,
        selectedMessagesIds,
        notifyMembersByEmail,
        toggleNotifyMembersByEmail: () => setNotifyMembersByEmail(previousNotifyByEmail => !previousNotifyByEmail),
        notificationEmailMessage,
        setNotificationEmailMessage,
        setSelectedMessagesIds: changeSelectedMessagesIds,
        clearSelectedMessagesIds,
        autoShareConditions,
        setAutoShareConditions,
      }}
    >
      {children}
    </ThreadSharingSettingsFormFieldsContext.Provider>
  )
}

export const useThreadSharingSettingsFormFieldsContext = () => {
  const context = useContext(ThreadSharingSettingsFormFieldsContext)

  if (context === undefined)
    throw new Error('useSharingGroupContext should be used within a SharingGroupContextProvider ')

  return context
}
