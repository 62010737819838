import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, Typography } from '@material-ui/core'
import { Select, SelectProps } from '@material-ui/core'
import DropdownIcon from '../../icons/dropdownIcon'
import classNames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: 17,
    marginTop: 24,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    marginBottom: 10,
    letterSpacing: 'normal',
    color: theme.threadPalette.menuItem.normal,
    textTransform: 'capitalize',
  },

  value: {
    fontSize: 17,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    paddingLeft: 0,
    lineHeight: 1.18,
    textTransform: 'capitalize',
    letterSpacing: 'normal',
  },

  listItem: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    padding: '15px 12px 15px 12px',
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.threadPalette.lighterGray,
    },
  },

  dropdownRoot: {
    backgroundColor: theme.threadPalette.white,
    width: '100%',
    maxHeight: '400px !important',
    '& .Mui-disabled': {
      padding: 10,
      backgroundColor: theme.threadPalette.lighterGray,
    },
  },

  dropdownOutline: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}))

interface IDropdownSelectProps extends SelectProps {
  renderValue?: (item: any) => React.ReactElement
  icon?: React.FC<any>
  label?: string
  className?: string
  dataCy?: string
}
const DropdownSelect = ({ renderValue, icon, className, dataCy, label, ...props }: IDropdownSelectProps) => {
  const classes = useStyles()
  const dropdownClass = classNames(classes.dropdownRoot, { [classes.dropdownOutline]: props.disabled })

  return (
    <div>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <Select
        {...props}
        variant="outlined"
        className={classNames(dropdownClass, className)}
        IconComponent={icon || DropdownIcon}
        renderValue={renderValue}
        data-cy={dataCy}
      >
        {props.children}
      </Select>
    </div>
  )
}

export default DropdownSelect
