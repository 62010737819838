import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { useThreadFollowers } from 'hooks/useThreadFollowers/useThreadFollowers'
import * as React from 'react'
import ThreadSettingsFollowerItem from './followerItem'
import { useStyles } from './threadSettings.style'

export type ThreadSettingsMenuItem = 'make_owner' | 'view' | 'leave_thread'

const ThreadFollowersList = () => {
  const classes = useStyles()
  const { currentThread } = useCurrentThreadContext()

  const { followers } = useThreadFollowers(currentThread?.id)

  return (
    <div className={classes.threadSettingsRoot}>
      {followers.map((follower: IUser, index: number) => (
        <ThreadSettingsFollowerItem key={follower.id} user={follower} addDivider={index !== 0} />
      ))}
    </div>
  )
}

export default ThreadFollowersList
