import { useMutation } from '@tanstack/react-query'
import { editSharingGroup } from 'api/v2/sharingGroup'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'actions/snackbar'
import { useUserSharingGroupsCacheActions } from 'hooks/useSharingGroup/useUserSharingGroupsCacheActions'
import { useThreadSharingSettingsCacheActions } from 'hooks/useSharingGroup/useThreadSharingSettingsCacheActions'

interface IUseEditSharingGroupUseCaseProps {
  threadId?: string
  onSuccess?: () => void
}

export const useEditSharingGroupUseCase = ({ threadId, onSuccess }: IUseEditSharingGroupUseCaseProps) => {
  const dispatch = useDispatch()

  const userSharingGroupsCacheActions = useUserSharingGroupsCacheActions()
  const threadSharingSettingsCacheActions = useThreadSharingSettingsCacheActions()

  const {
    reset: resetEditSharingGroupMutation,
    mutateAsync: runEditSharingGroupMutation,
    isLoading,
  } = useMutation(editSharingGroup, {
    onMutate: editSharingGroupParams => {
      const { sharingGroupId, name, membersEmails } = editSharingGroupParams

      const rollbackOptimisticUpdate = userSharingGroupsCacheActions.updateData({
        id: sharingGroupId,
        name,
        membersEmails,
      })
      return rollbackOptimisticUpdate
    },
    onSuccess: onSuccess,
    onError: (_, __, rollbackOptimisticUpdate) => {
      rollbackOptimisticUpdate?.()
      dispatch(showSnackbar({ message: 'Error trying to update sharing group' }))
    },
    onSettled: () => {
      userSharingGroupsCacheActions.refreshData({ withImmediateFetching: true })
      if (threadId) threadSharingSettingsCacheActions.refreshData(threadId)

      resetEditSharingGroupMutation()
    },
  })

  const editSharingGroupUseCase: typeof editSharingGroup = runEditSharingGroupMutation

  return { editSharingGroupUseCase, isLoading }
}
