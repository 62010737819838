import * as React from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import AttachmentPreviewHeader from './shared/previewHeader'
import IAttachment from 'model/attachment'
import { AttachmentDisplayControlOverlay } from './shared/attachmentDisplayControlOverlay'
import classnames from 'classnames'
import { useAttachmentContentDetails } from 'hooks/useAttachmentContentDetails/useAttachmentContentDetails'
import { useZoom } from './shared/useZoom'
import { useBoolean } from 'hooks/useBoolean'
import { AttachmentContentRenderer } from './attachmentContentRenderer'
import { useDownloadAttachmentUseCase } from 'usecases/attachment/downloadAttachment'
import { getAttachmentContentType, isAttachmentConvertible } from 'domain/attachment'

const useAttachmentDisplayStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachmentPreviewroot: {
      backgroundColor: theme.threadPalette.menuItem.normal,
    },
    contentRoot: {
      backgroundColor: theme.threadPalette.menuItem.normal,
      display: 'flex',
      height: 'calc(100vh - 70px)',
      justifyContent: 'center',
      overflowY: 'auto',
    },
  })
)

interface IAttachmentDisplayProps {
  attachment: IAttachment
  onClose: () => void
  currentThread: IThread
  contentClassName?: string
}

export type TAttachmentPreviewType = 'pdf' | 'img' | 'unsupported'

const AttachmentDisplay = ({ attachment, onClose, currentThread, contentClassName }: IAttachmentDisplayProps) => {
  const classes = useAttachmentDisplayStyles()

  const [isAttachmentConverting, setIsAttachmentConverting] = React.useState(false)

  const { state: isShowingControls, setTrue: showControls, setFalse: hideControls } = useBoolean(false)

  const { zoom, onFullScreenClick, onZoomInClick, onZoomOutClick } = useZoom()

  const {
    attachmentContentDetails,
    isLoading: isLoadingContentDetails,
    isFetching: isFetchingContentDetails,
  } = useAttachmentContentDetails(attachment.id)
  const attachmentURL = attachmentContentDetails?.url

  const { downloadAttachmentUseCase, isLoading: isDownloading } = useDownloadAttachmentUseCase()

  const previewType = React.useMemo(() => {
    const fileType = attachment.fileType

    const contentType = getAttachmentContentType({ fileType })

    if (contentType === 'pdf' || isAttachmentConvertible({ fileType })) return 'pdf'
    if (contentType === 'image') return 'img'
    return 'unsupported'
  }, [attachment.fileType])

  React.useEffect(() => {
    setIsAttachmentConverting(false)
  }, [attachment, currentThread])

  const onMouseEnter = () => showControls()
  const onMouseLeave = () => hideControls()

  const onDownload = () =>
    downloadAttachmentUseCase({
      attachmentId: attachment.id,
      fileName: attachment.filename,
      threadId: currentThread.id,
    })

  const isPreviewSupported = previewType !== 'unsupported'
  const isShowingPreviewControlsAllowed = !isAttachmentConverting && attachmentURL && isPreviewSupported

  return (
    <div className={classes.attachmentPreviewroot} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <AttachmentPreviewHeader attachment={attachment} onClose={onClose} />
      <div className={classnames(classes.contentRoot, contentClassName)}>
        <AttachmentContentRenderer
          attachment={attachment}
          attachmentURL={attachmentURL}
          zoom={zoom}
          previewType={previewType}
          isConverting={isAttachmentConverting}
          setIsConverting={setIsAttachmentConverting}
          isLoadingContentDetails={isLoadingContentDetails}
          isFetchingContentDetails={isFetchingContentDetails}
          onDownload={onDownload}
          isDownloading={isDownloading}
        />
      </div>
      {isShowingPreviewControlsAllowed && (
        <AttachmentDisplayControlOverlay
          show={isShowingControls}
          onDownload={onDownload}
          onFullScreenClick={onFullScreenClick}
          onZoomInClick={onZoomInClick}
          onZoomOutClick={onZoomOutClick}
          isDownloading={isDownloading}
        />
      )}
    </div>
  )
}

export default AttachmentDisplay
