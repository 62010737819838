import * as React from 'react'
import RedoIcon from './redo'

interface IUndoIconProps {
  className?: string
}

const UndoIcon = (props: IUndoIconProps) => {
  return <RedoIcon {...props} transform="scale(-1, 1)" />
}

export default UndoIcon
