import React from 'react'
import { errorHandlingWrapper } from '../api/errors/errorHandlingWrapper'

export interface UseEffectAsyncParams<T> {
  initialData?: T
  defaultErrorMessage?: string
}

export function useEffectAsync<T>(func: () => Promise<T>, params?: UseEffectAsyncParams<T>) {
  const { initialData } = params ?? {}
  const [data, setData] = React.useState<T | undefined>(initialData)
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<string | undefined>()

  React.useEffect(() => {
    const fetchData = async () => {
      setError(undefined)
      setIsLoading(true)

      const { data: resultData, error: errorMessage } = await errorHandlingWrapper(func, params)

      setData(resultData)
      setError(errorMessage)
      setIsLoading(false)
    }

    fetchData()
  }, [func])

  return { data, isLoading, error }
}
