import { showSnackbar } from 'actions/snackbar'
import { useDispatch } from 'react-redux'

const ONE_SECOND_IN_MILLISECONDS = 1000

interface IUseCopyContentToClipboard {
  messageOnCopy?: string
}

export const useCopyContentToClipboard = ({
  messageOnCopy = 'Content copied to clipboard',
}: IUseCopyContentToClipboard = {}) => {
  const dispatch = useDispatch()

  const copyContentToClipboard = (content: string) => {
    navigator.clipboard.writeText(content)
    dispatch(showSnackbar({ message: messageOnCopy, durationInMilliseconds: ONE_SECOND_IN_MILLISECONDS }))
  }

  return {
    copyContentToClipboard,
  }
}
