import React from 'react'
import {
  browserName as browserNameAsString,
  browserVersion as browserVersionAsString,
  isIOS,
  isMacOs,
  isWindows,
} from 'react-device-detect'

export type TBrowserName = 'Chrome' | 'Firefox' | 'Edge' | 'Safari' | 'Opera' | 'Brave' | 'Vivaldi'
export const browserNamesList: TBrowserName[] = ['Chrome', 'Firefox', 'Edge', 'Safari', 'Opera', 'Brave', 'Vivaldi']

export const useBrowserData = () => {
  const browserName = React.useMemo(() => {
    return browserNamesList.find(name => name === browserNameAsString)
  }, [browserNameAsString, browserNamesList])

  const browserVersion = React.useMemo(() => {
    return parseFloat(browserVersionAsString)
  }, [browserVersionAsString])

  return {
    browserName,
    browserVersion,
    isAppleDevice: isIOS || isMacOs,
    isWindows,
  }
}
