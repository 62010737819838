import * as React from 'react'
import { Theme, ListItemIcon, ListItem, fade, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'

const useLeftSidebarMenuItemStyles = makeStyles((theme: Theme) => {
  return {
    leftSidebarMenuItemRoot: {
      backgroundColor: 'transparent',
      borderRadius: 8,
      height: 50,
      padding: 4,
      width: '100%',
      minWidth: 0,

      '&:hover .MuiListItemIcon-root:not($listItemIconRootSelected)': {
        backgroundColor: fade(theme.palette.primary.main, 0.5),
      },
    },
    listItemIconRoot: {
      alignItems: 'center',
      borderRadius: 8,
      color: theme.threadPalette.white,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      minWidth: 0,
      width: '100%',
    },
    listItemIconRootSelected: {
      backgroundColor: theme.palette.primary.main,
    },
    tooltip: {
      backgroundColor: theme.palette.primary.main,
    },
    tooltipPopper: {
      marginInline: 4,
    },
  }
})

interface LeftSidebarMenuItemProps {
  id?: string
  icon: React.ReactNode
  onClick(): void
  isSelected?: boolean
  tooltip?: string
}
export const LeftSidebarMenuItem = ({ id, icon, onClick, isSelected, tooltip = '' }: LeftSidebarMenuItemProps) => {
  const classes = useLeftSidebarMenuItemStyles()
  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        tooltipPlacementRight: classes.tooltipPopper,
      }}
      title={tooltip}
      placement="right"
    >
      <ListItem
        id={id}
        button
        disableGutters
        onClick={onClick}
        selected={isSelected}
        classes={{
          root: classes.leftSidebarMenuItemRoot,
        }}
      >
        <ListItemIcon
          classes={{
            root: classNames(classes.listItemIconRoot, {
              [classes.listItemIconRootSelected]: isSelected,
            }),
          }}
        >
          {icon}
        </ListItemIcon>
      </ListItem>
    </Tooltip>
  )
}
