import React from 'react'
import ErrorScreen from 'component/errorScreen'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useSubscriptionCreationErrorPageStyles = makeStyles((theme: Theme) => ({
  subscriptionCreationErrorPageContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
  },
  subtitleRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
}))

interface ISubscriptionCreationErrorPageProps {
  subtitle: JSX.Element
  buttonTitle?: string
  onButtonClick?(): void
}

export const SubscriptionCreationErrorPage = (props: ISubscriptionCreationErrorPageProps) => {
  const classes = useSubscriptionCreationErrorPageStyles()

  const onButtonClick = () => {
    window.history.pushState(null, '', '/signin')
    window.location.reload()
  }

  return (
    <div className={classes.subscriptionCreationErrorPageContainer}>
      <ErrorScreen
        title="Failed to subscribe to your mailbox updates"
        subtitle={props.subtitle}
        iconMessage="Ooops"
        onActionClick={props.onButtonClick ?? onButtonClick}
        buttonTitle={props.buttonTitle}
      />
    </div>
  )
}
