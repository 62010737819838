import { ISharingGroup } from 'model/sharingGroup'
import React from 'react'
import GroupIcon from '@material-ui/icons/Group'
import { Theme, makeStyles } from '@material-ui/core'
import { EditButton } from 'component/sharingGroup/common/editButton'

interface ISharingGroupOptionTileProps {
  sharingGroup: ISharingGroup
  isHighlighted?: boolean
  onClickEdit?(): void
}

const useSharingGroupOptionTileStyles = makeStyles<Theme, Pick<ISharingGroupOptionTileProps, 'isHighlighted'>>(
  (theme: Theme) => ({
    sharingGroupOptionTileRoot: {
      color: theme.threadPalette.brownishGray,
      display: 'inline-flex',
      justifyContent: 'center',
      gap: 12,
      width: '100%',
    },
    sharingGroupInfoContainer: {
      flexGrow: 1,
    },
    editGroupButton: {
      color: theme.threadPalette.lightBlue,
      opacity: ({ isHighlighted }) => (isHighlighted ? 1 : 0.5),
    },
    icon: {
      fontSize: 20,
    },
    sharingGroupName: {
      fontSize: 16,
    },
    membersCount: {
      fontSize: 12,
    },
  })
)

export const SharingGroupOptionTile = ({
  sharingGroup,
  onClickEdit,
  isHighlighted = false,
}: ISharingGroupOptionTileProps) => {
  const classes = useSharingGroupOptionTileStyles({ isHighlighted })
  const numberOfMembers = sharingGroup.members.length

  const pluralizedMembersLabel = numberOfMembers === 1 ? 'member' : 'members'
  const numberOfMembersLabel = !!numberOfMembers ? numberOfMembers : 'No'
  const membersLabel = `${numberOfMembersLabel} ${pluralizedMembersLabel}`
  return (
    <div className={classes.sharingGroupOptionTileRoot}>
      <GroupIcon className={classes.icon} />
      <div className={classes.sharingGroupInfoContainer}>
        {sharingGroup.name} <span className={classes.membersCount}>({membersLabel})</span>
      </div>
      <EditButton label="Edit group" className={classes.editGroupButton} onClick={onClickEdit} />
    </div>
  )
}

export default SharingGroupOptionTile
