import * as React from 'react'
import classNames from 'classnames'
import { Theme, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import UserAvatar from '../../../component/userAvatar'
import { useUser } from '../../../hooks/auth'
import UserConnectionStatus from 'component/userConnectionStatus'
import { getUserDisplayName } from 'dataProcessors/userDisplayName'
import CopyableTypography from 'shared/components/copyableTypography'

const useUserDisplayCardStyles = makeStyles((theme: Theme) => ({
  userDisplayCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  divider: {
    width: '100%',
    color: theme.threadPalette.lighterGray,
  },

  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  section: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  avatarSection: {
    display: 'inline-block',
    position: 'relative',
  },

  onlineStatus: {
    bottom: 0,
    right: 0,
    marginRight: -2,
    marginBottom: 2,
    position: 'absolute',
  },

  userInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12,
  },

  userName: {
    color: theme.threadPalette.menuItem.current,
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 'normal',
  },
  userEmail: {
    color: theme.threadPalette.menuItem.current,
    fontSize: 12,
    lineHeight: 'normal',
    marginTop: 1,
  },
}))

interface IUserDisplayCardProps {
  user: Pick<IUser, 'email'> & Partial<Pick<IUser, 'id' | 'name' | 'avatarUrl'>>
  onlineStatus?: IOnlineStatus
  addDivider?: boolean
  className?: string
  cardAction?: JSX.Element
}

const UserDisplayCard = ({
  user,
  onlineStatus,
  addDivider = false,
  className = '',
  cardAction,
}: IUserDisplayCardProps) => {
  const classes = useUserDisplayCardStyles()
  const currentUser = useUser()

  const isMe = currentUser?.id === user.id

  const userDisplayName = isMe ? 'You' : getUserDisplayName(user)
  return (
    <div className={classes.userDisplayCardContainer}>
      {addDivider && <Divider className={classes.divider} />}
      <div className={classNames(classes.content, className)}>
        <div className={classes.section}>
          <div className={classes.avatarSection}>
            <UserAvatar user={user} />
            {onlineStatus && (
              <UserConnectionStatus isCurrentUser={isMe} onlineStatus={onlineStatus} className={classes.onlineStatus} />
            )}
          </div>
          <div className={classes.userInfoWrapper}>
            <CopyableTypography className={classes.userName}>{userDisplayName}</CopyableTypography>
            {userDisplayName !== user.email && (
              <CopyableTypography className={classes.userEmail}>{user.email}</CopyableTypography>
            )}
          </div>
        </div>
        {cardAction}
      </div>
    </div>
  )
}

export default UserDisplayCard
