import * as React from 'react'
import { SvgIcon } from '@material-ui/core'

interface IEmojiIconProps {
  onClick?: (event: React.SyntheticEvent<unknown, MouseEvent>) => void
  className?: string
}

const EmojiIcon = ({ onClick, className = '' }: IEmojiIconProps) => {
  return (
    <SvgIcon className={className} viewBox="0 0 30 30">
      <svg xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <g fill="#606060" fillRule="evenodd">
          <path d="M14.5 26c4.688 0 8.5-3.627 8.5-8.086a.824.824 0 00-.247-.592.757.757 0 00-.611-.322H6.858c-.25 0-.47.127-.611.322a.826.826 0 00-.247.592C6 22.374 9.812 26 14.5 26zm6.914-7.374c-.382 3.229-3.336 5.75-6.914 5.75s-6.531-2.521-6.913-5.75h13.827zM8.764 12c.422 0 .763-.418.763-.935 0-.658.437-1.193.973-1.193s.973.535.974 1.193c0 .517.341.935.763.935s.763-.418.763-.935C13 9.375 11.878 8 10.5 8S8 9.375 8 11.065c.001.517.342.935.764.935zM17.764 12c.422 0 .763-.418.763-.935 0-.658.437-1.193.973-1.193s.973.535.974 1.193c0 .517.341.935.763.935s.763-.418.763-.935C22 9.375 20.878 8 19.5 8S17 9.375 17 11.065c0 .517.342.935.764.935z" />
          <path d="M15 30c8.27 0 15-6.73 15-15S23.27 0 15 0 0 6.73 0 15s6.73 15 15 15zm0-28.42c7.4 0 13.42 6.02 13.42 13.42S22.4 28.42 15 28.42 1.58 22.4 1.58 15 7.6 1.58 15 1.58z" />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default EmojiIcon
