import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'
import BeatProgress from 'component/beatProgress'
import { MessageFullContent } from 'component/message/messageFullContent/messageFullContent'
import classNames from 'classnames'
import { useFullScreen } from 'hooks/useFullScreen'

const useMessagesFragmentListStyles = makeStyles((theme: Theme) => ({
  messagesFragmentListRoot: {
    width: 'min(1200px, 90vw)',
  },
  messagesFragmentListFullScreen: {
    width: '100vw',
  },
  scrollableListRoot: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '70vh',
  },
  scrollableInverseListRoot: {
    flexDirection: 'column-reverse',
  },
  messageEntireContentContainer: {
    maxHeight: '80vh',
    overflowY: 'auto',
    paddingInline: 30,
  },
}))

interface IMessagesFragmentListProps {
  messagesCount: number
  currentUser: IUser
  getMessageFragmentSkeleton(): JSX.Element
  isLoading: boolean
  header?: JSX.Element
  messageSelectedForEntireContentView: IMessage | null
  fetchNextPage?(): void
  hasNextPage?: boolean
  isInverseList?: boolean
  className?: string
}

export const MessagesFragmentList: React.FC<IMessagesFragmentListProps> = ({
  messagesCount,
  currentUser,
  getMessageFragmentSkeleton,
  isLoading,
  header,
  messageSelectedForEntireContentView,
  fetchNextPage,
  hasNextPage = false,
  isInverseList,
  className,
  children,
}) => {
  const classes = useMessagesFragmentListStyles()

  const { isFullScreen } = useFullScreen()

  const hasMorePages = !fetchNextPage ? false : hasNextPage && !isLoading

  // used for lists without pagination
  const fetchEmptyNextPage = () => {
    return []
  }

  const getMessagesFragmentSkeletonsList = (numberOfMessages = 4) => {
    const skeletonsList = [...Array(numberOfMessages)].map((_, index) => (
      <div key={index}>{getMessageFragmentSkeleton()}</div>
    ))
    return skeletonsList
  }

  const messagesFragmentListRoot = classNames(classes.messagesFragmentListRoot, {
    [classes.messagesFragmentListFullScreen]: isFullScreen,
  })
  const scrollableListRootClassName = classNames(classes.scrollableListRoot, className, {
    [classes.scrollableInverseListRoot]: isInverseList,
  })

  if (messageSelectedForEntireContentView) {
    return (
      <div className={classes.messageEntireContentContainer}>
        <MessageFullContent message={messageSelectedForEntireContentView} currentUser={currentUser} />
      </div>
    )
  }

  return (
    <div className={messagesFragmentListRoot}>
      {header}
      <InfiniteScroll
        dataLength={messagesCount}
        className={scrollableListRootClassName}
        next={fetchNextPage ?? fetchEmptyNextPage}
        hasMore={hasMorePages}
        loader={<BeatProgress />}
        inverse={isInverseList}
        height={'100%'}
        scrollThreshold="250px"
      >
        {isLoading && getMessagesFragmentSkeletonsList()}
        {!isLoading && children}
      </InfiniteScroll>
    </div>
  )
}
