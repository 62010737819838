import { useQueryClient } from '@tanstack/react-query'
import { getUserSharingGroups } from 'api/v2/sharingGroup'
import { useUser } from 'hooks/auth'
import { ISharingGroup } from 'model/sharingGroup'
import React from 'react'

export const getUserSharingGroupsQueryKey = (userId: string) => [userId, 'sharingGroups']

export const useUserSharingGroupsCacheActions = () => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const refreshData = React.useCallback(
    ({ withImmediateFetching = false }: { withImmediateFetching?: boolean } = {}) => {
      if (!currentUser?.id) return

      const queryKey = getUserSharingGroupsQueryKey(currentUser.id)
      queryClient.invalidateQueries({ queryKey })
      if (withImmediateFetching) prefetchData()
    },
    [queryClient, currentUser?.id]
  )

  const getData = React.useCallback(() => {
    if (!currentUser?.id) return

    const queryKey = getUserSharingGroupsQueryKey(currentUser.id)
    return queryClient.getQueryData<ISharingGroup[]>(queryKey)
  }, [queryClient, currentUser?.id])

  const setData = React.useCallback(
    (userSharingGroups: ISharingGroup[]) => {
      if (!currentUser?.id) return

      const queryKey = getUserSharingGroupsQueryKey(currentUser.id)
      queryClient.setQueryData(queryKey, userSharingGroups)
    },
    [queryClient, currentUser?.id]
  )

  const updateData = React.useCallback(
    ({
      id: sharingGroupId,
      name,
      membersEmails,
    }: Normalize<Pick<ISharingGroup, 'id'> & Partial<Pick<ISharingGroup, 'name'>> & { membersEmails?: string[] }>) => {
      const userSharingGroups = getData()

      if (!userSharingGroups) return

      const userSharingGroupsUpdated = userSharingGroups.map(sharingGroup => {
        if (sharingGroup.id !== sharingGroupId) return sharingGroup

        const updatedSharingGroup = { ...sharingGroup }
        if (name) updatedSharingGroup.name = name
        if (membersEmails) {
          const membersWithoutRemoved = sharingGroup.members.filter(member => !membersEmails.includes(member.email))
          updatedSharingGroup.members = membersWithoutRemoved
        }
        return updatedSharingGroup
      })

      setData(userSharingGroupsUpdated)

      const rollbackUpdate = () => setData(userSharingGroups)
      return rollbackUpdate
    },
    [getData, setData]
  )

  const deleteData = React.useCallback(
    ({ id: sharingGroupId }: Pick<ISharingGroup, 'id'>) => {
      const userSharingGroups = getData()

      if (!userSharingGroups) return

      const userSharingGroupsUpdated = userSharingGroups.filter(sharingGroup => sharingGroup.id !== sharingGroupId)
      setData(userSharingGroupsUpdated)

      const rollbackUpdate = () => setData(userSharingGroups)
      return rollbackUpdate
    },
    [getData, setData]
  )

  const prefetchData = React.useCallback(() => {
    if (!currentUser?.id) return

    const userSharingGroups = getData()

    if (userSharingGroups) return

    queryClient.prefetchQuery({
      queryKey: getUserSharingGroupsQueryKey(currentUser.id),
      queryFn: getUserSharingGroups,
    })
  }, [queryClient, currentUser?.id])

  return {
    refreshData,
    getData,
    setData,
    updateData,
    prefetchData,
    deleteData,
  }
}
