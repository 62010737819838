import * as React from 'react'

interface IBulletListIconIconProps extends JSX.IntrinsicAttributes {
  className?: string
}

const BulletListIcon = ({ className }: IBulletListIconIconProps) => {
  return (
    <svg className={className} viewBox="0 -3 18 18">
      <g fillRule="evenodd" fill="#606060">
        <path d="M13.076 5.536H6.757c-.65 0-1.175.525-1.175 1.175s.525 1.175 1.175 1.175h6.32c.65 0 1.175-.524 1.175-1.175 0-.65-.526-1.175-1.176-1.175zM6.757 3.134h6.32c.65 0 1.175-.525 1.175-1.175S13.727.784 13.076.784H6.757c-.65 0-1.175.524-1.175 1.175 0 .65.525 1.175 1.175 1.175zM13.076 10.236H6.757c-.65 0-1.175.525-1.175 1.175s.525 1.176 1.175 1.176h6.32c.65 0 1.175-.525 1.175-1.176 0-.65-.526-1.175-1.176-1.175zM3.917 1.959c0 1.081-.877 1.958-1.958 1.958C.877 3.917 0 3.04 0 1.959 0 .877.877 0 1.959 0 3.04 0 3.917.877 3.917 1.959M3.917 6.711c0 1.082-.877 1.959-1.958 1.959C.877 8.67 0 7.793 0 6.71s.877-1.958 1.959-1.958c1.081 0 1.958.876 1.958 1.958M3.917 11.411c0 1.082-.877 1.96-1.958 1.96C.877 13.37 0 12.492 0 11.41c0-1.081.877-1.958 1.959-1.958 1.081 0 1.958.877 1.958 1.958" />
      </g>
    </svg>
  )
}

export default BulletListIcon
