import { toggleMessageCollapsedState } from 'api/message'
import React from 'react'

interface IUserMessageCollapseProps {
  initialMessageCollapsedState: boolean
  messageId: string
}

export const useMessageCollapse = ({ initialMessageCollapsedState, messageId }: IUserMessageCollapseProps) => {
  const [isCollapsed, setIsCollapsed] = React.useState(initialMessageCollapsedState)

  const toggleMessageCollapsed = React.useCallback(() => {
    setIsCollapsed(previousIsCollapsed => !previousIsCollapsed)
    toggleMessageCollapsedState(messageId)
  }, [toggleMessageCollapsedState, messageId])

  return {
    isCollapsed,
    toggleMessageCollapsed,
  }
}
