import * as React from 'react'

interface IBigCloseIconProps {
  className?: string
}

const BigCloseIcon = (props: IBigCloseIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#AAA" width="30px" height="30px" viewBox="0 0 30 30" {...props}>
      <path
        d="M1388.125 50L1375.001 63.125 1361.875 50 1360 51.875 1373.126 65 1360 78.123 1361.875 80 1375.001 66.875 1388.125 80 1390 78.123 1376.876 65 1390 51.875z"
        transform="translate(-1360 -50)"
      />
    </svg>
  )
}

export default BigCloseIcon
