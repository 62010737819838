import * as React from 'react'

interface ISentProps {
  className?: string
}

const Sent = (props: ISentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} width="14" height="10" viewBox="0 0 14 10">
      <path fillRule="evenodd" d="M4.107 7L1.615 5 0 6.5 4.107 10l9.154-8.5L11.646 0z" />
    </svg>
  )
}

export default Sent
