import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import { OnboardingStepWithImage } from '../onboardingStepWithImage'
import { ThreadSharingModalIcon } from 'icons/onboarding/threadSharingModalIcon'

const useCreateThreadSharingStepStyles = makeStyles((theme: Theme) => ({
  shareModalIcon: {
    height: 350,
  },
}))

export const CreateThreadSharingStep = () => {
  const classes = useCreateThreadSharingStepStyles()

  const textLines = [<>Choose which messages people can see, and decide if you want to notify them by email.</>]

  return (
    <OnboardingStepWithImage
      headerText="How to share a thread"
      image={<ThreadSharingModalIcon className={classes.shareModalIcon} />}
      stepNumber={2}
      topTextLines={textLines}
      lineHeight={1.8}
      textLinesMaxWidth={440}
    />
  )
}
