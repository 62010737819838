import * as React from 'react'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { theme } from './theme'
import Routes from './route'
import useGlobalCSS from './theme/globalCSS'
import { createBrowserHistory } from 'history'
import { configureStore } from './store'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import AppLoadingPage from './page/appLoading'
import ErrorReporterPage from './page/errorReporter'
import WebSocketGate from './api/webSocketGate'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import AppMainHead from 'component/head/appMainHead'
import HotKeysHandler from 'hotKeys'
import { QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { CurrentThreadContextProvider } from 'contexts/currentThreadContext'
import { BrowserSecurityWarningContextProvider } from 'component/browserSecurityWarning/browserSecurityWarningContext'
import { BrowserSecurityWarningModal } from 'component/browserSecurityWarning/browserSecurityWarningModal'
import { DisplayAttachmentContextProvider } from 'contexts/displayAttachmentContext'
import { AuthContextProvider } from 'contexts/authContext'
import { UserAuthOnboardingContextProvider } from 'contexts/userAuthOnboardingContext'
import { OnboardingTourContextProvider } from 'contexts/useOnboardingTourContext'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { getTimeInMilliSeconds } from 'dataProcessors/utils'
import { ProjectsTreeContextProvider } from 'component/projectsTree/projectsTreeContext'

const history = createBrowserHistory()

const { persistor, store } = configureStore(history)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: getTimeInMilliSeconds({ days: 7 }),
    },
  },
})

const reactQueryPersistOptions = createSyncStoragePersister({ storage: window.localStorage })

const App: React.FC = () => {
  useGlobalCSS()

  return (
    <>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: reactQueryPersistOptions }}>
            <ErrorReporterPage>
              <PersistGate loading={<AppLoadingPage />} persistor={persistor}>
                <BrowserSecurityWarningContextProvider>
                  <OnboardingTourContextProvider>
                    <CurrentThreadContextProvider>
                      <DisplayAttachmentContextProvider>
                        <AuthContextProvider>
                          <UserAuthOnboardingContextProvider>
                            <WebSocketGate>
                              <DndProvider backend={HTML5Backend}>
                                <ConnectedRouter history={history}>
                                  <AppMainHead />
                                  <ProjectsTreeContextProvider>
                                    <Routes />
                                  </ProjectsTreeContextProvider>
                                  <HotKeysHandler />
                                </ConnectedRouter>
                              </DndProvider>
                            </WebSocketGate>
                          </UserAuthOnboardingContextProvider>
                        </AuthContextProvider>
                      </DisplayAttachmentContextProvider>
                    </CurrentThreadContextProvider>
                  </OnboardingTourContextProvider>
                  <BrowserSecurityWarningModal />
                </BrowserSecurityWarningContextProvider>
              </PersistGate>
            </ErrorReporterPage>
            <ReactQueryDevtools initialIsOpen={false} />
          </PersistQueryClientProvider>
        </MuiThemeProvider>
      </Provider>
    </>
  )
}

export default App
