import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { useProjectItemStyles } from './projectItem'
import classNames from 'classnames'

const ProjectSkeletonItem = () => {
  const classes = useProjectItemStyles()

  return (
    <div className={classes.projectItemButton}>
      <Skeleton
        variant="rect"
        width={28}
        height={22}
        className={classNames(classes.folderIcon, classes.loadingItem, classes.folderLoadingIcon)}
      />
      <Skeleton
        variant="text"
        className={classNames(classes.projectName, classes.loadingItem, classes.threadNameLoading)}
      />
      <Skeleton variant="text" className={classNames(classes.projectActionsWrapper, classes.loadingItem)} />
    </div>
  )
}

export default ProjectSkeletonItem
