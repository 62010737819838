import { useThreadPageContext } from 'page/threadPageContextProvider'

interface IUseMessageRecipientsFilterProps {
  sender: IUser
  recipients: TPerson[]
  bccRecipients: TPerson[]
  currentUser: IUser
}

export const useMessageRecipientsFilter = ({
  sender,
  recipients,
  bccRecipients,
  currentUser,
}: IUseMessageRecipientsFilterProps) => {
  const { recipientsFilter, updateRecipientsFilter } = useThreadPageContext()

  const isFilterActive = recipientsFilter.length > 0

  const onFilterByMessageRecipients = () => {
    const recipientsIds = recipients.map(recipient => recipient.id)
    const bccRecipientsIds = bccRecipients.map(recipient => recipient.id)
    const messageAudienceIds = [sender.id, ...recipientsIds, ...bccRecipientsIds].filter(recipientId => recipientId)

    const filteredFollowers = isFilterActive ? [] : messageAudienceIds
    const filteredFollowersWithoutCurrentUser = filteredFollowers.filter(
      (recipientId): recipientId is string => recipientId !== currentUser?.id
    )

    updateRecipientsFilter(filteredFollowersWithoutCurrentUser)
  }

  return {
    isFilterActive,
    onFilterByMessageRecipients,
  }
}
