import * as React from 'react'

interface ICheckboxIconProps {
  className?: string
}

const CheckboxIcon = (props: ICheckboxIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" {...props}>
      <g fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-806 -33) translate(76 16) translate(680) translate(50.404 16)">
                <g>
                  <path
                    d="M13.5 15h-12C.675 15 0 14.325 0 13.5v-12C0 .675.675 0 1.5 0h12c.825 0 1.5.675 1.5 1.5v12c0 .825-.675 1.5-1.5 1.5zM12.113 4.369c-.356-.319-.919-.319-1.256 0l-4.819 4.5-1.894-1.762c-.356-.319-.918-.319-1.256 0-.356.318-.356.843 0 1.181L5.4 10.632c.356.318.918.318 1.256 0l5.457-5.1c.356-.32.356-.844 0-1.163z"
                    transform="translate(0 1)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CheckboxIcon
