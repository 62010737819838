import { useQuery } from '@tanstack/react-query'
import { errorHandlingWrapper } from 'api/errors/errorHandlingWrapper'
import { loadThread } from 'api/threads'
import { useUser } from 'hooks/auth'

interface IUseThreadProps {
  threadId?: string
  initialData?: IThread
  onError?: (error: Error) => void
}

export const getThreadsQueryKey = ({ userId, threadId }: { userId: string; threadId: string }) => [
  userId,
  'threads',
  threadId,
]

const getThreadQueryFunction = async (threadId?: string) => {
  if (!threadId) return undefined
  const { data: thread, responseError, rawError } = await errorHandlingWrapper(() => loadThread(threadId))

  if (responseError) throw new Error(responseError)
  if (rawError) throw rawError

  return thread
}

export function useThread({ threadId, initialData, onError }: IUseThreadProps) {
  const currentUser = useUser()

  const {
    data: thread,
    isLoading,
    dataUpdatedAt,
  } = useQuery<IThread | undefined, Error>({
    enabled: !!threadId && !!currentUser?.id,
    queryKey: getThreadsQueryKey({ userId: currentUser?.id ?? '', threadId: threadId ?? '' }),
    queryFn: () => getThreadQueryFunction(threadId),
    keepPreviousData: !!!initialData,
    initialData: initialData,
    // reload initial data immediately
    initialDataUpdatedAt: 0,
    retry: false,
    onError: onError,
  })

  return { thread: thread ?? null, isLoading, lastRefreshedAt: dataUpdatedAt }
}
