import * as React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import CloseIcon from '../../../icons/closeIcon'
import { Theme, Chip, useTheme } from '@material-ui/core'
import { isEmail } from 'dataProcessors/utils'
import { getUserDisplayName } from 'dataProcessors/userDisplayName'
import { IPersonChipProps } from 'component/peopleSelect/peopleSelect'

interface IUseRecipientsChipStylesProps {
  isUserEmailValid: boolean
}

const useRecipientsChipStyles = makeStyles<Theme, IUseRecipientsChipStylesProps>((theme: Theme) =>
  createStyles({
    chipRoot: {
      backgroundColor: ({ isUserEmailValid }) =>
        isUserEmailValid ? theme.threadPalette.darkBlue : theme.palette.error.contrastText,
      borderRadius: 4,
      marginInline: 2,
      paddingRight: 3,
    },
    primaryChipColor: {},
    chipLabel: {
      color: theme.threadPalette.white,
      fontSize: 15,
    },
    deleteIconContainer: {
      alignItems: 'center',
      backgroundColor: theme.threadPalette.white,
      borderRadius: '50%',
      cursor: 'pointer',
      display: 'flex',
      height: 16,
      justifyContent: 'center',
      marginLeft: 10,
      width: 16,
    },
  })
)

function RecipientChip({ person: recipient, ...props }: IPersonChipProps) {
  const isUserEmailValid = isEmail(recipient.email)
  const classes = useRecipientsChipStyles({ isUserEmailValid })

  const recipientDisplayName = getUserDisplayName(recipient)
  const theme = useTheme()

  const closeIconFillColor = isUserEmailValid ? undefined : theme.palette.error.contrastText
  return (
    <Chip
      {...props}
      label={recipientDisplayName}
      deleteIcon={
        <div className={classes.deleteIconContainer}>
          <CloseIcon width="10" height="10" fill={closeIconFillColor} />
        </div>
      }
      classes={{
        root: classes.chipRoot,
        label: classes.chipLabel,
      }}
    />
  )
}

export default RecipientChip
