import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import { PeopleSharingWorkItemsIcon } from 'icons/peopleSharingWorkItemsIcon'
import { OnboardingStepWithImage } from '../onboardingStepWithImage'

const useThreadSharingExplanationStepStyles = makeStyles((theme: Theme) => ({
  peopleSharingWorkItemsIcon: {
    width: 400,
  },
}))

export const ThreadSharingExplanationStep = () => {
  const classes = useThreadSharingExplanationStepStyles()

  const textLines = [
    <>
      Collaborating via email can be frustrating. CCs, forwards, and side emails make conversations hard to follow, and
      important details inevitably get lost in the process.
    </>,
    <>
      When you share a thread with group of people, they will{' '}
      <strong>only see the messages you choose to share with them</strong>, and you can choose to include all future
      messages too.
    </>,
    <>A shared thread has the same unique URL as the regular thread.</>,
  ]

  return (
    <OnboardingStepWithImage
      headerText="Keep everyone in the loop by securely sharing access to thread messages"
      image={<PeopleSharingWorkItemsIcon className={classes.peopleSharingWorkItemsIcon} />}
      bottomTextLines={textLines}
      textLinesMaxWidth={640}
      lineHeight={1.8}
    />
  )
}
