import { SubscriptionCreationForbiddenPage } from 'component/signInErrors/subscriptionCreationForbidden'
import { SubscriptionCreationFailedPage } from 'component/signInErrors/subscriptionCreationFailed'
import React from 'react'
import { OAuthScopeErrorPage } from 'component/signInErrors/oAuthScopeError'

export class ErrorWithRenderedComponent extends Error {
  readonly component: () => JSX.Element | null = () => null
}

export class SubscriptionCreationFailedError extends ErrorWithRenderedComponent {
  readonly component = () => <SubscriptionCreationFailedPage emailProvider={this.emailProvider} />

  emailProvider: TEmailProvider

  constructor(emailProvider: TEmailProvider) {
    super()
    this.emailProvider = emailProvider
  }
}
export class SubscriptionCreationForbiddenError extends SubscriptionCreationFailedError {
  readonly component = () => <SubscriptionCreationForbiddenPage />
}

export class OAuthScopeError extends SubscriptionCreationFailedError {
  readonly component = () => <OAuthScopeErrorPage emailProvider={this.emailProvider} />
}
