import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  fieldButtonsContainer: {
    marginBottom: 13,
  },
  fieldButton: {
    fontSize: 17,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
    marginBottom: 13,
    marginLeft: 12,
    marginRight: 12,
  },
}))

interface IFieldControlsProps {
  handleSave(): void
  handleToggle(): void
  editDisabled: boolean
}

const FieldControls = ({ editDisabled, handleSave, handleToggle }: IFieldControlsProps) => {
  const classes = useStyles()

  return (
    <>
      {editDisabled ? (
        <span onClick={handleToggle} className={classes.fieldButton}>
          Change
        </span>
      ) : (
        <div className={classes.fieldButtonsContainer}>
          <span onClick={handleToggle} className={classes.fieldButton}>
            Cancel
          </span>
          <span onClick={handleSave} className={classes.fieldButton}>
            Save
          </span>
        </div>
      )}
    </>
  )
}

export default FieldControls
