import { useCallback } from 'react'
import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import { getAttachmentContentDetails } from 'api/attachment'
import { getTimeInMilliSeconds } from 'dataProcessors/utils'
import { useUser } from 'hooks/auth'
import { getAttachmentContentDetailsQueryKey } from 'hooks/useAttachmentContentDetails/useAttachmentContentDetailsCacheActions'
import { IAttachmentContentDetails } from 'model/attachment'

const ONE_HOUR_IN_MILLISECONDS = getTimeInMilliSeconds({ hours: 1 })

export const useAttachmentContentDetails = (attachmentId: string) => {
  const currentUser = useUser()
  const {
    data: attachmentContentDetails,
    isLoading,
    isFetching,
  } = useQuery({
    enabled: !!currentUser?.id && !!attachmentId,
    queryKey: getAttachmentContentDetailsQueryKey({ userId: currentUser?.id ?? '', attachmentId }),
    queryFn: () => getAttachmentContentDetails(attachmentId),
    staleTime: ONE_HOUR_IN_MILLISECONDS,
    refetchOnMount: false,
  })

  return {
    attachmentContentDetails,
    isLoading,
    isFetching,
  }
}

export function useAttachmentsContentDetails(attachmentIds: string[]) {
  const currentUser = useUser()

  const attachmentsContentDetailsQueries = useQueries({
    queries: attachmentIds.map(attachmentId => ({
      enabled: !!currentUser?.id && !!attachmentId,
      queryKey: getAttachmentContentDetailsQueryKey({
        userId: currentUser?.id ?? '',
        attachmentId,
      }),
      queryFn: () => getAttachmentContentDetails(attachmentId),
      staleTime: ONE_HOUR_IN_MILLISECONDS,
      refetchOnMount: false,
    })),
  })

  return {
    attachmentsContentDetails: attachmentsContentDetailsQueries.map(
      attachmentContentDetailssQuery => attachmentContentDetailssQuery.data
    ),
  }
}

export const useAttachmentContentDetailsCacheActions = () => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const fetchData = useCallback(
    async (attachmentId: string) => {
      if (!currentUser) return

      const queryKey = getAttachmentContentDetailsQueryKey({ userId: currentUser.id, attachmentId })
      const attachmentContentDetails = await queryClient.fetchQuery({
        queryKey,
        queryFn: () => getAttachmentContentDetails(attachmentId),
      })

      return attachmentContentDetails
    },
    [queryClient, currentUser]
  )

  const getData = useCallback(
    (attachmentId: string) => {
      if (!currentUser) return

      const queryKey = getAttachmentContentDetailsQueryKey({ userId: currentUser.id, attachmentId })
      return queryClient.getQueryData<IAttachmentContentDetails>(queryKey)
    },
    [queryClient, currentUser]
  )

  return { fetchData, getData }
}
