import * as React from 'react'
import { useIconStyles } from '../styles'
import classnames from 'classnames'

interface IImageIconProps extends JSX.IntrinsicAttributes {
  width?: number
  height?: number
  className?: string
  fill?: string
}

const ImageIcon = ({ className, width, height, fill }: IImageIconProps) => {
  const classes = useIconStyles()
  return (
    <div className={classnames(className, classes.iconWrapper)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : '20'}
        height={height ? height : '20'}
        viewBox="0 0 20 20"
      >
        <path
          fill={fill ? fill : '#4A4A4A'}
          d="M17.268 19.714c.283 0 .523-.099.72-.297.199-.198.298-.438.298-.72V6.481c0-.283-.071-.594-.212-.933-.142-.34-.311-.608-.51-.806l-3.307-3.308c-.198-.198-.467-.367-.806-.509-.34-.141-.65-.212-.933-.212h-9.5c-.283 0-.523.1-.721.297C2.099 1.21 2 1.45 2 1.732v16.964c0 .283.099.523.297.721.198.198.438.297.72.297h14.25zm-.34-1.357H3.359V2.071H11.5v4.411c0 .283.099.523.297.721.198.198.438.297.72.297h4.412v10.857zm-.084-12.214h-3.987V2.156c.205.07.35.149.435.234l3.318 3.318c.085.085.163.23.234.435zM6.75 11.57c.565 0 1.046-.197 1.442-.593.396-.396.594-.877.594-1.442 0-.566-.198-1.046-.594-1.442-.396-.396-.877-.594-1.442-.594s-1.046.198-1.442.594c-.396.396-.594.876-.594 1.442 0 .565.198 1.046.594 1.442.396.396.877.593 1.442.593zM15.571 17v-3.393l-3.392-3.393-4.072 4.072-1.357-1.357-2.036 2.035V17h10.857z"
        />
      </svg>
    </div>
  )
}

export default ImageIcon
