import React from 'react'
import { markThreadAsRead } from 'api/threads'
import { useMutation } from '@tanstack/react-query'
import { useUpdateThreadUseCase } from './updateThread'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'actions/snackbar'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'

export const useReadThreadUseCase = () => {
  const dispatch = useDispatch()
  const { updateThreadUseCase } = useUpdateThreadUseCase()
  const { refreshThread, refreshThreadsList } = useThreadActions()

  const readThreadApiCall = async (threadId: string) => {
    await markThreadAsRead(threadId)
  }

  const readThreadMutation = useMutation(readThreadApiCall, {
    onMutate: threadId => {
      const { rollbackOptimisticUpdates } = updateThreadUseCase({ threadId: threadId, unreadCount: 0 })
      return rollbackOptimisticUpdates
    },
    onError: (error, variables, rollbackOptimisticUpdates) => {
      rollbackOptimisticUpdates?.()
      dispatch(showSnackbar({ message: 'Error marking thread as read' }))
      readThreadMutation.reset()
    },
    onSettled: (data, error, threadId) => {
      refreshThread(threadId)
      refreshThreadsList()
    },
  })

  const readThreadUseCase = React.useCallback(
    async (threadId: string) => {
      await readThreadMutation.mutateAsync(threadId)
    },
    [readThreadMutation]
  )

  return { readThreadUseCase }
}
