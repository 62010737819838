import * as React from 'react'
import 'react-quill/dist/quill.snow.css'
import 'quill-mention'
import ReactQuillEditor from 'react-quill'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useRichTextActionsContext } from '../useRichTextActionsContext'
import useQuillSetup from './useQuillSetup'
import { IShortcut } from 'model/shortcut'

const useRichTextEditorStyles = makeStyles((theme: Theme) => ({
  editor: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginBottom: 4,
    minHeight: 160,
    '& .quill': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: 'inherit',
    },

    '& .ql-container.ql-snow': {
      alignItems: 'center',
      border: `none`,
      borderRadius: 4,
      boxSizing: 'border-box',
      cursor: 'text',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '1rem',
      height: '100%',
      minHeight: 'inherit',
      paddingTop: 5,
    },

    // Style for placeholder
    '& .ql-editor::before': {
      color: theme.threadPalette.lightGray,
      fontStyle: 'normal',
      left: 0,
      paddingLeft: 8,
    },

    '& .ql-editor': {
      border: 'none',
      height: '100%',
      minHeight: 'inherit',
      overflowY: 'scroll',
      padding: 0,
      paddingLeft: 8,
      width: '100%',
      '& img': {
        height: 'auto',
        maxWidth: '484px',
      },
      '& div': {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
      },
    },
  },
}))

export interface IRichTextEditorProps {
  textContent: string
  onChange: (content: string) => void
  onFilePaste: (event: React.ClipboardEvent<HTMLElement>) => void
  getMentionOptionsFromSearchTerm: (searchTerm?: string) => IUser[]
  onMention: (mentionedRecipient: TDraftRecipient) => void
  placeholder: string
  toolbarId: string
  shortcuts?: IShortcut[]
}

const RichTextEditor = ({
  textContent,
  onChange,
  onFilePaste,
  getMentionOptionsFromSearchTerm,
  onMention,
  placeholder,
  toolbarId,
  shortcuts,
}: IRichTextEditorProps) => {
  const classes = useRichTextEditorStyles()

  const toolbarContainerId = `#${toolbarId}`
  const { customModules, allowedFormats } = useQuillSetup({
    toolbarContainerId,
    getMentionOptionsFromSearchTerm,
    onMention,
  })
  const { richTextEditorRef: quillRef, setRichTextEditorRef, saveSelectionRange } = useRichTextActionsContext()

  const onContentChange = (content: string) => {
    if (!quillRef) return
    onChange(content)
  }

  const handleShortcuts = (event: React.KeyboardEvent) => {
    if (!shortcuts) return
    shortcuts.forEach(({ isShortcutKeys, runAction }) => {
      if (!isShortcutKeys(event)) return

      runAction()
      event.preventDefault()
    })
  }

  return (
    <div className={classes.editor} onPaste={onFilePaste}>
      <ReactQuillEditor
        ref={el => {
          if (el) setRichTextEditorRef(el)
        }}
        placeholder={placeholder}
        theme="snow"
        bounds={toolbarContainerId}
        defaultValue={textContent}
        modules={customModules}
        onChange={onContentChange}
        onKeyDown={handleShortcuts}
        onBlur={saveSelectionRange}
        formats={allowedFormats}
      />
    </div>
  )
}
export default RichTextEditor
