import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  baseItem: {
    fontSize: 20,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.8,
    letterSpacing: 0.8,
    fontWeight: 500,
    cursor: 'pointer',
    height: 55,
    width: 200,
    color: theme.threadPalette.lightGray,
    paddingLeft: 20,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.threadPalette.lighterGray,
    },
    backgroundColor: theme.threadPalette.white,
  },

  selectedItem: {
    fontWeight: 'bold',
    backgroundColor: theme.threadPalette.lighterGray,
    position: 'relative',
    color: theme.threadPalette.menuItem.current,
    '&:before': {
      content: '""',
      backgroundSize: '55px 3px',
      bottom: 0,
      background: theme.palette.error.main,
      display: 'block',
      left: 0,
      height: '55px',
      position: 'absolute',
      width: '3px',
    },
  },

  divider: {
    color: theme.threadPalette.lighterGray,
  },
  verticalDivider: {
    color: theme.threadPalette.lighterGray,
    height: '90%',
  },
}))
