import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import StarIcon from '@material-ui/icons/Star'
import classnames from 'classnames'
import * as React from 'react'
import UserAvatar from '../userAvatar'
import OptionsMenu, { IMenuItem } from '../menu/menu'
import { useMenu } from 'hooks/useMenu'
import { useThreadAvatar } from 'hooks/useThreadAvatar'
import { useStyles } from './threadItem.styles'
import UnreadOrangeCircle from 'component/unreadCircle'
import { getUserDisplayName } from 'dataProcessors/userDisplayName'
import { TThreadMenuItemType, useThreadMenuItems } from './useThreadMenuItems'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import { ConnectThreadToProjectModal } from 'component/connectThreadToProjectModal.tsx/connectThreadToProjectModal'
import { usePrefetchThreadProjects } from 'hooks/useThreadProjects/useThreadProjects'
import { getThreadDisplayName } from 'domain/thread'

interface IThreadItemProps {
  dataCy?: string
  isActive?: boolean
  thread: IThread
  user: TUser
  onSelect: (thread: IThread) => void
  stateForUser: TThreadStateForUser
  onHover?: () => void
}

const ThreadItem = ({
  thread,
  onSelect,
  user: currentUser,
  isActive,
  dataCy,
  stateForUser,
  onHover,
}: IThreadItemProps) => {
  const classes = useStyles()
  const [hovered, setHovered] = React.useState(false)
  // state to represent unread status of the thread
  const [unreadCount, setUnreadCount] = React.useState(0)
  const groupIcon = useThreadAvatar(thread.id)

  const { menuAnchorEl, onMenuIconClick, onMenuClose, dismissMenu } = useMenu()

  const { prefetchThreadProjects } = usePrefetchThreadProjects()

  const onThreadMenuOpen = React.useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      // Prefetching thread projects so we can show which of them are connected to the thread as quickly as possible
      // when the user opens the "connect thread to project modal"
      prefetchThreadProjects({ threadId: thread.id })
      onMenuIconClick(event)
    },
    [thread.id, onMenuIconClick]
  )

  const {
    isOpen: isConnectProjectDialogOpen,
    dialogAnchorElement: threadAnchorItem,
    openDialog: openConnectProjectDialog,
    handleCloseDialog: closeConnectProjectDialog,
    setDialogAnchorRef: addThreadItemRef,
  } = useDialog()

  const { menuItemsList, onMenuItemClick } = useThreadMenuItems({
    threadId: thread.id,
    isStarred: thread.isStarred,
    unreadCount: thread.unreadCount,
    stateForUser,
    onConnectThreadToProject: openConnectProjectDialog,
  })

  const onThreadClick = (event: React.SyntheticEvent<unknown, MouseEvent>) => {
    onSelect(thread)
  }

  React.useEffect(() => {
    if (thread) {
      setUnreadCount(thread.unreadCount)
    }
  }, [thread?.unreadCount])

  const handleMenuClose = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      onMenuClose(event)
      setHovered(false)
    },
    [onMenuClose]
  )

  const handleMenuItemSelect = React.useCallback(
    (value: TThreadMenuItemType) => {
      dismissMenu()
      onMenuItemClick(value)
      setHovered(false)
    },
    [dismissMenu]
  )

  const menuItems: IMenuItem<TThreadMenuItemType>[] = React.useMemo(() => {
    return menuItemsList.map(menuItem => {
      return {
        value: menuItem.type,
        label: menuItem.label,
        IconComponent: menuItem.icon,
      }
    })
  }, [menuItemsList])

  if (!currentUser) return null

  thread.followers = thread.followers ?? []

  const isGroupThread = thread.followers.length > 2
  const isOneOnOneThread = thread.followers.length === 2

  let userForThreadIconCreation = currentUser
  if (isOneOnOneThread) {
    userForThreadIconCreation = thread.followers.filter(follower => follower.id !== currentUser.id)[0]
  }

  const threadIcon = isGroupThread ? (
    <img src={groupIcon} alt="" />
  ) : (
    <UserAvatar size={40} user={userForThreadIconCreation} />
  )
  const threadDisplayName = getThreadDisplayName(thread)

  const lastMessageSenderDisplayName = thread?.lastMessageSender ? getUserDisplayName(thread.lastMessageSender) : ''

  const displayUnreadCountIndicator = unreadCount !== 0
  const displayStarIndicator = !displayUnreadCountIndicator && thread.isStarred

  const onMouseEnter = () => {
    setHovered(true)
    onHover?.()
  }

  return (
    <>
      <div
        data-cy={dataCy}
        className={classnames(classes.threadItem, { active: isActive })}
        onClick={onThreadClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setHovered(false)}
        ref={addThreadItemRef}
      >
        <div className={classes.flexContainer}>
          <div className={classes.backdrop}></div>
          {threadIcon}
          <div className={classes.threadInfoWrapper}>
            <p className={classnames(classes.threadInfoItem, classes.threadName, { active: isActive })}>
              {threadDisplayName}
            </p>
            <p className={classnames(classes.threadInfoItem, classes.lastSenderName, { active: isActive })}>
              {lastMessageSenderDisplayName}
            </p>
          </div>
        </div>
        <div>
          {hovered ? (
            <MoreHorizIcon className={classes.menuIcon} onClick={onThreadMenuOpen} />
          ) : (
            <>
              {displayUnreadCountIndicator && <UnreadOrangeCircle unreadCount={unreadCount} />}
              {displayStarIndicator && <StarIcon className={classes.starIcon} />}
            </>
          )}
        </div>
        <OptionsMenu
          anchorEl={menuAnchorEl}
          items={menuItems}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          onSelect={handleMenuItemSelect}
        />
      </div>
      <ConnectThreadToProjectModal
        thread={thread}
        anchorEl={threadAnchorItem}
        isOpen={isConnectProjectDialogOpen}
        onDialogClose={closeConnectProjectDialog}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  )
}

export default ThreadItem
