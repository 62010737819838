import * as React from 'react'
import { Dialog, Typography, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'
import { push } from 'actions/navigation'
import { useStyles } from './deleteAccount.styles'

interface IDeleteAccountProps {
  open: boolean
  onClose: () => void
}

const DeleteAccountDialog = ({ open, onClose }: IDeleteAccountProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleDeleteClick = React.useCallback(() => {
    dispatch(push('/signin'))
  }, [dispatch])

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.dialogRoot}>
        <div className={classes.header}>
          <Typography className={classes.title}>Permanently delete your account?</Typography>
          <IconButton onClick={onClose} className={classes.button} aria-label="Delete" disableRipple>
            <CloseIcon className={classes.closeButton} />
          </IconButton>
        </div>

        <Typography className={classes.contentText}>
          When you delete your account, you will lose access to all of the existing data and your email account will be
          disconnected.
        </Typography>

        <div className={classes.footer}>
          <Button variant="outlined" color="primary" className={classes.cancelButton} onClick={onClose}>
            No, cancel
          </Button>
          <Button variant="contained" color="primary" className={classes.deleteButton} onClick={handleDeleteClick}>
            Yes, delete
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default DeleteAccountDialog
