import React from 'react'
import { setCurrentAuthOnboardingStep } from 'actions/userAuthOnboarding'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentAuthOnboardingStep } from 'selectors/userAuthOnboarding'

interface IUserAuthOnboardingContext {
  isAuthOnboardingOpen: boolean
  isAuthOnboardingPaused: boolean
  currentOnboardingStep: TUserAuthOnboardingStep
  openAuthOnboarding(step?: TUserAuthOnboardingStep): void
  openNextAuthOnboardingStep(): void
  closeAuthOnboarding(): void
  pauseAuthOnboarding(): void
}

export const UserAuthOnboardingContext = React.createContext<IUserAuthOnboardingContext | undefined>(undefined)

export const UserAuthOnboardingContextProvider: React.FC<{}> = ({ children }) => {
  const dispatch = useDispatch()

  const currentOnboardingStep = useSelector(selectCurrentAuthOnboardingStep)

  const [isAuthOnboardingOpen, setIsAuthOnboardingOpen] = React.useState(false)
  const [isAuthOnboardingPaused, setIsAuthOnboardingPaused] = React.useState(false)

  const orderedAuthOnboardingSteps: TUserAuthOnboardingStep[] = [
    'confirmName',
    'welcome',
    'threadDescription',
    'threadCreation',
    'threadCreationSuccess',
  ]

  const openAuthOnboarding = (step?: TUserAuthOnboardingStep) => {
    if (step) dispatch(setCurrentAuthOnboardingStep(step))
    setIsAuthOnboardingOpen(true)
    setIsAuthOnboardingPaused(false)
  }

  const closeAuthOnboarding = () => setIsAuthOnboardingOpen(false)

  const pauseAuthOnboarding = () => {
    setIsAuthOnboardingPaused(true)
    closeAuthOnboarding()
  }

  const openNextAuthOnboardingStep = () => {
    const currentStepIndex = orderedAuthOnboardingSteps.indexOf(currentOnboardingStep)
    const nextStep = orderedAuthOnboardingSteps[currentStepIndex + 1]
    dispatch(setCurrentAuthOnboardingStep(nextStep))
  }

  return (
    <UserAuthOnboardingContext.Provider
      value={{
        isAuthOnboardingOpen,
        openAuthOnboarding,
        closeAuthOnboarding,
        currentOnboardingStep,
        openNextAuthOnboardingStep,
        isAuthOnboardingPaused,
        pauseAuthOnboarding,
      }}
    >
      {children}
    </UserAuthOnboardingContext.Provider>
  )
}

export const useUserAuthOnboardingContext = () => {
  const context = React.useContext(UserAuthOnboardingContext)

  if (context === undefined)
    throw new Error('useUserAuthOnboardingContext should be used within a UserAuthOnboardingContextProvider ')

  return context
}
