import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { createThread } from 'api/v2/thread'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'
import { useDispatch } from 'react-redux'
import { useInsertThreadUseCase } from './insertThread'

interface ICreateThreadProps {
  title: string
  projectId?: string
}

interface IUseCreateThreadFromThreadliveUseCaseProps {
  onThreadCreated?: (newThread: IThread) => void
}

export const useCreateThreadFromThreadliveUseCase = ({
  onThreadCreated,
}: IUseCreateThreadFromThreadliveUseCaseProps) => {
  const dispatch = useDispatch()

  const { refreshThreadsList } = useThreadActions()
  const { insertThreadUseCase } = useInsertThreadUseCase()

  const createThreadHandler = async ({ title, projectId }: ICreateThreadProps) => {
    const newThread = await createThread(title, projectId)
    return newThread
  }

  const createThreadMutation = useMutation(createThreadHandler, {
    onError: (error, variables: ICreateThreadProps) => {
      dispatch(showSnackbar({ message: `Error on creating thread ${variables.title}` }))
      createThreadMutation.reset()
    },
    onSuccess: (data: IThread) => {
      onThreadCreated?.(data)
      insertThreadUseCase(data)
      refreshThreadsList()
    },
  })

  const createThreadFromThreadliveUseCase = async (title: string, projectId?: string) => {
    const thread = await createThreadMutation.mutateAsync({ title, projectId })
    return thread
  }

  return {
    createThreadFromThreadliveUseCase,
    isLoading: createThreadMutation.isLoading,
  }
}
