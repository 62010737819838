import React from 'react'
import { makeStyles } from '@material-ui/core'

export interface IImageWithContentProps {
  contentId: string
  url: string
}

const useImageWithContentStyles = makeStyles({
  imageWithContentRoot: {
    maxWidth: 484,
  },
})

export const ImageWithContent = ({ contentId, url }: IImageWithContentProps) => {
  const classes = useImageWithContentStyles()

  return <img src={url} className={classes.imageWithContentRoot} data-custom={contentId} alt="inline-attachment" />
}
