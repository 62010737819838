import { Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    threadSettingsRoot: {
      marginBottom: 50,
      overflow: 'auto',
    },
    label: {
      color: theme.threadPalette.menuItem.current,
      fontSize: 16,
      fontWeight: 500,
      textTransform: 'uppercase',
      marginBottom: 15,
    },
    textField: {
      width: '100%',
      fontSize: 17,
      fontWeight: 300,
      marginBottom: 24,
    },
    textFieldInputProps: {
      height: 40,
    },
    followersContainer: {
      marginTop: 10,
      marginBottom: 50,
    },
    followersSelect: {
      '& .MuiInputBase-root': {
        height: 40,
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 13px) scale(1)',
      },
      '& .MuiOutlinedInput-root': {
        padding: '0 0 0 8px',
      },
    },
  })
)
