import * as React from 'react'
import { useEmailMessageContentWrappers } from './useEmailMessageContentWrappers'
import { makeStyles } from '@material-ui/core'
import { useAttachmentsContentDetails } from 'hooks/useAttachmentContentDetails/useAttachmentContentDetails'
import IAttachment, { IAttachmentContentDetails, IInlineAttachment } from 'model/attachment'
import { IMention } from 'model/mention'
import { useInlineAttachmentContentActions } from './useInlineAttachmentContentActions'
import { ImagePlaceholder } from './inlineAttachmentImage/imagePlaceholder'

interface IEmailMessageContentProps {
  content: string
  mentionedUsers?: IMention[]
  contentInlineAttachments?: Pick<IInlineAttachment, 'id' | 'contentId'>[]
  inlineAttachments?: IInlineAttachment[]
  isInlineAttachmentPreviewEnabled?: boolean
  onOpenAttachment?(attachment: IAttachment): void
}

const useEmailMessageContentStyles = makeStyles({
  message: {
    wordBreak: 'break-word',
  },
})

type IMessageWrapper = (messageContent: string) => string

const EmailMessageContent: React.FC<IEmailMessageContentProps> = ({
  content,
  inlineAttachments = [],
  contentInlineAttachments = [],
  mentionedUsers = [],
  isInlineAttachmentPreviewEnabled = true,
  onOpenAttachment,
}) => {
  const classes = useEmailMessageContentStyles()

  const { attachmentsContentDetails } = useAttachmentsContentDetails(
    contentInlineAttachments.map(attachment => attachment.id)
  )

  const { wrapMentions, wrapInlineAttachments, onActionClicked } = useEmailMessageContentWrappers({
    mentionedUsers,
    inlineAttachments,
    onOpenAttachment,
  })

  const { setInlineAttachmentPlaceholder, setInlineAttachmentContent } = useInlineAttachmentContentActions()

  const messageContentWrapped = React.useMemo(() => {
    const wrappers: IMessageWrapper[] = [wrapMentions]

    const messageContentWrapped = wrappers.reduce<string>((content, wrapper) => wrapper(content), content)

    return messageContentWrapped
  }, [content])

  const updateMessageContentFromInlineAttachmentContentDetails = ({
    messageContent,
    contentId,
    contentDetails,
  }: {
    messageContent: string
    contentId: string
    contentDetails?: IAttachmentContentDetails
  }) => {
    if (!contentDetails) {
      return setInlineAttachmentPlaceholder({
        content: messageContent,
        contentId,
        placeholder: <ImagePlaceholder contentId={contentId} />,
      })
    }

    const { url } = contentDetails

    if (url) {
      return setInlineAttachmentContent({
        content: messageContent,
        contentId,
        url,
      })
    }

    return setInlineAttachmentPlaceholder({
      content: messageContent,
      contentId,
      placeholder: <ImagePlaceholder contentId={contentId} />,
    })
  }

  const messageContentWrappedWithInlineAttachments = React.useMemo(() => {
    if (!messageContentWrapped) return

    let contentWithInjectedInlineAttachments = messageContentWrapped

    contentInlineAttachments.forEach(inlineAttachment => {
      const contentDetails = attachmentsContentDetails.find(
        contentDetails => contentDetails?.id === inlineAttachment.id
      )
      contentWithInjectedInlineAttachments = updateMessageContentFromInlineAttachmentContentDetails({
        messageContent: contentWithInjectedInlineAttachments,
        contentId: inlineAttachment.contentId,
        contentDetails,
      })
    })

    if (isInlineAttachmentPreviewEnabled)
      contentWithInjectedInlineAttachments = wrapInlineAttachments(contentWithInjectedInlineAttachments)

    return contentWithInjectedInlineAttachments
  }, [messageContentWrapped, attachmentsContentDetails, contentInlineAttachments, isInlineAttachmentPreviewEnabled])

  return (
    <div
      className={classes.message}
      onClick={onActionClicked}
      dangerouslySetInnerHTML={{ __html: messageContentWrappedWithInlineAttachments ?? content }}
    />
  )
}

export default EmailMessageContent
