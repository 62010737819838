import React from 'react'
import { Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Spacer from 'component/layoutUtils/spacer'
import { useDraftMessageContext } from 'component/message/draftMessage/useDraftMessageContext'
import { PeopleSelect } from 'component/peopleSelect/peopleSelect'
import RecipientChip from './recipientChip'

const useRecipientsBlockStyles = makeStyles((theme: Theme) => ({
  recipientsWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  bccShortText: {
    color: theme.threadPalette.brownishGray,
    cursor: 'pointer',
    fontSize: 16,
    marginLeft: 18,
  },
}))

interface IRecipientsBlockProps {
  toRecipients: TDraftRecipient[]
  setToRecipients(recipients: TDraftRecipient[]): void
  bccRecipients?: TDraftRecipient[]
  setBccRecipients(recipients: TDraftRecipient[]): void
  isEditable: boolean
  shouldAutoFocus?: boolean
}

const RecipientsBlock = ({
  toRecipients,
  setToRecipients,
  bccRecipients = [],
  setBccRecipients,
  isEditable,
  shouldAutoFocus = false,
}: IRecipientsBlockProps) => {
  const classes = useRecipientsBlockStyles()
  const [isShowBccField, setIsShowBccField] = React.useState(false)
  const { setToRecipientsInputRef, setBccRecipientsInputRef } = useDraftMessageContext()

  //  This useEffect covers the situation where the input box is reset, meaning the bcc recipients become
  //   empty without the user removing them and unblur the field. Without it, when the bcc field has some emails,
  //   it will be kept open on reseting the input box, until being focused and unblurred again
  React.useEffect(() => {
    if (bccRecipients.length > 0) setIsShowBccField(true)
  }, [bccRecipients])

  return (
    <>
      <div className={classes.recipientsWrapper}>
        <PeopleSelect
          label="To:"
          // Fixing type inconsistency: TDraftRecipient `name` and `avatarUrl` fields are string | null | undefined,
          //  while the TPerson can have them as null only
          selectedPeople={toRecipients.map(recipient => {
            return { ...recipient, name: recipient?.name ?? null, avatarUrl: recipient?.avatarUrl ?? null }
          })}
          onPeopleChange={setToRecipients}
          isEditable={isEditable}
          isFocusedOnRendering={shouldAutoFocus}
          setRef={setToRecipientsInputRef}
          personChip={RecipientChip}
        />
        {!isShowBccField && isEditable && (
          <div
            className={classes.bccShortText}
            onClick={() => setIsShowBccField(true)}
            onFocus={() => setIsShowBccField(true)}
            tabIndex={0}
          >
            <Typography>Bcc</Typography>
          </div>
        )}
      </div>
      {isShowBccField && (
        <>
          <Spacer height={12} />
          <div className={classes.recipientsWrapper}>
            <PeopleSelect
              label="Bcc:"
              // Fixing type inconsistency: TDraftRecipient `name` and `avatarUrl` fields are string | null | undefined,
              //  while the TPerson can have them as null only
              selectedPeople={bccRecipients.map(recipient => {
                return { ...recipient, name: recipient?.name ?? null, avatarUrl: recipient?.avatarUrl ?? null }
              })}
              onPeopleChange={setBccRecipients}
              hideField={() => setIsShowBccField(false)}
              isFocusedOnRendering={true}
              isEditable={isEditable}
              setRef={setBccRecipientsInputRef}
              personChip={RecipientChip}
            />
          </div>
        </>
      )}
    </>
  )
}

export default RecipientsBlock
