import * as React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import MailIcon from '@material-ui/icons/Mail'
import { makeStyles, createStyles } from '@material-ui/styles'
import CheckIcon from '@material-ui/icons/Done'
import DoubleCheckIcon from '@material-ui/icons/DoneAll'
import DateTimeFormatter from 'component/formatters/dateTime'
import { Typography } from '@material-ui/core'
import { ORDINAL_SUFFIX_FORMAT_CODE } from 'dataProcessors/dateTime'
import { DateTime } from 'luxon'

const useMessageDeliveryStatusBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.threadPalette.lightGray,
      fontSize: '12px',
      textAlign: 'right',
      marginLeft: 'auto',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    icon: {
      fill: theme.threadPalette.lightGray,
      display: 'inline-block',
      height: '15px',
      position: 'relative',
      top: '2px',
      verticalAlign: 'top',
    },
    messageDeliveryBarTextWrapper: {
      display: 'inline-block',
      lineHeight: '18px',
    },
    senderName: {
      fontSize: 12,
      display: 'inline-block',
      color: theme.threadPalette.lightGray,
      lineHeight: '18px',
    },
  })
)

interface IMessageDeliveryBarProps {
  deliveryStatus: TMessageDeliveryStatus
  createdAt: DateTime
  isCurrentUserMessageSender: boolean
}

export const MessageDeliveryStatusBar = ({
  deliveryStatus,
  createdAt,
  isCurrentUserMessageSender,
}: IMessageDeliveryBarProps) => {
  const classes = useMessageDeliveryStatusBarStyles()

  const isSent = deliveryStatus === 'sent'
  const isError = deliveryStatus === 'error'
  const isPending = deliveryStatus === 'pending'

  return (
    <div className={classes.root}>
      {isSent && <DoubleCheckIcon className={classes.icon} />}
      {isCurrentUserMessageSender && isPending && <CheckIcon className={classes.icon} />}
      <MailIcon className={classes.icon} />
      <div className={classes.messageDeliveryBarTextWrapper}>
        <Typography className={classes.senderName}>
          {(isSent || isError) && (
            <DateTimeFormatter value={createdAt} format={`MMM d${ORDINAL_SUFFIX_FORMAT_CODE} @ hh:mm a`} />
          )}
          {isPending && (
            <>
              {' ('}
              <i>pending</i>
              {')'}
            </>
          )}
        </Typography>
      </div>
    </div>
  )
}
