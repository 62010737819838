import ProjectMenu from 'component/menu'
import { IMenuItem } from 'component/menu/menu'
import * as React from 'react'
import { TProjectMenuOption } from './projectItem'
import AddIcon from '@material-ui/icons/Add'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useProjectMenuPopoverStyles = makeStyles((theme: Theme) => {
  return {
    menuItem: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '24px',
      padding: '0px 10px',
      '&:hover': {
        background: theme.threadPalette.white,
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
    },
  }
})

interface IProjectMenuPopoverProps {
  isOpen: boolean
  anchorEl: HTMLElement | (() => HTMLElement) | null
  onClose(event: React.MouseEvent): void
  onMenuItemSelect(menuOption: TProjectMenuOption): void
}

export const ProjectMenuPopover = ({ isOpen, onMenuItemSelect, onClose, anchorEl }: IProjectMenuPopoverProps) => {
  const classes = useProjectMenuPopoverStyles()

  const menuOptions: IMenuItem<TProjectMenuOption>[] = [
    {
      value: 'newThread',
      label: 'New thread in this project',
      IconComponent: AddIcon,
    },
  ]

  return (
    <ProjectMenu
      anchorEl={anchorEl}
      items={menuOptions}
      open={isOpen}
      onClose={onClose}
      onSelect={onMenuItemSelect}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      getContentAnchorEl={null}
      customClasses={{
        item: classes.menuItem,
      }}
    />
  )
}
