import { PaginatedResponse } from './pagedResponse'
import { UnreadCount, INotificationOld, INotification } from './../model/notification'
import { apiClient } from '@jetkit/react'
import camelcaseKeys from 'camelcase-keys'

export const getNotifications = async (page: number): Promise<PaginatedResponse<INotification[]>> => {
  const response = await apiClient.get('/v2/notification-center-messages', {
    params: { page },
  })
  const paginationHeader = camelcaseKeys(JSON.parse(response.headers['x-pagination']))

  return {
    ...paginationHeader,
    data: response.data.map((notification: INotification) => notification),
  }
}

export const getUnreadNotificationsCount = async (): Promise<UnreadCount> => {
  const response = await apiClient.get('/v2/notification-center-messages/unread/count')
  return response.data
}

export const markNotificationsRead = async (): Promise<void> => {
  const response = await apiClient.patch('/v2/notification-center-messages/unread')
  return response.data
}

export const getNotification = async (notificationId: string): Promise<INotificationOld> => {
  const response = await apiClient.get(`/notification/${notificationId}`)
  return response.data
}
