import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import TabWrapper, { ITabElement } from 'component/tabs/tabWrapper'
import AccountIcon from 'icons/account'
import FolderIcon from 'icons/folder'
import CrownIcon from 'icons/crown'
import CheckboxIcon from 'icons/checkbox'
import ManagementTabLabel from './managementTabLabel'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0px 16px 16px 16px',
    backgroundColor: theme.threadPalette.lighterGray,
    width: '100%',
  },
}))

interface IManagementProps {}

const Management: React.FC<IManagementProps> = () => {
  const classes = useStyles()
  const managementTabs: ITabElement[] = [
    {
      label: <ManagementTabLabel icon={AccountIcon} label={<span>Accounts</span>} />,
      page: <div />,
      requiredPermissions: ['crm_full_access'],
    },
    {
      label: <ManagementTabLabel icon={FolderIcon} label={<span>Projects</span>} />,
      page: <div />,
      requiredPermissions: ['projects_view'],
    },
    {
      label: <ManagementTabLabel icon={CrownIcon} label={<span>Opportunities</span>} />,
      page: <div />,
      requiredPermissions: ['crm_full_access'],
    },
    {
      label: <ManagementTabLabel icon={CheckboxIcon} label={<span>Tasks</span>} />,
      page: <div />,
      requiredPermissions: ['task_full_access'],
    },
  ]
  return (
    <div className={classes.root}>
      <TabWrapper tabs={managementTabs} />
    </div>
  )
}

export default Management
