import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { createFromEmailThread } from 'api/emailThread'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'
import { useDispatch } from 'react-redux'
import { useInsertThreadUseCase } from './insertThread'

interface ICreateThreadFromEmailProps {
  emailThreadId: string
  subject: string
  projectId?: string
}

interface IUseCreateThreadFromEmailUseCaseProps {
  onThreadCreated?: (newThread: IThread) => void
}

export const useCreateThreadFromEmailUseCase = ({ onThreadCreated }: IUseCreateThreadFromEmailUseCaseProps) => {
  const dispatch = useDispatch()

  const { refreshThreadsList } = useThreadActions()
  const { insertThreadUseCase } = useInsertThreadUseCase()

  const createThreadHandler = async ({ emailThreadId, subject, projectId }: ICreateThreadFromEmailProps) => {
    const newThread = await createFromEmailThread(emailThreadId, subject, projectId)
    return newThread
  }

  const createThreadMutation = useMutation(createThreadHandler, {
    onError: (error, variables: ICreateThreadFromEmailProps) => {
      dispatch(showSnackbar({ message: `Error on connecting ${variables.subject}` }))
      createThreadMutation.reset()
    },
    onSuccess: (data: IThread) => {
      onThreadCreated?.(data)
      insertThreadUseCase(data)
      refreshThreadsList()
    },
  })

  const createThreadFromEmailUseCase = async (emailThreadId: string, subject: string, projectId?: string) => {
    const thread = await createThreadMutation.mutateAsync({ emailThreadId, subject, projectId })
    return thread
  }

  return {
    createThreadFromEmailUseCase,
    isLoading: createThreadMutation.isLoading,
  }
}
