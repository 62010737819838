import { useDispatch } from 'react-redux'
import { push } from 'actions/navigation'
import { getProjectURL } from 'domain/project'
import { getRelativeURL } from 'lib/url'

export const useOpenProjectDetailsUseCase = () => {
  const dispatch = useDispatch()

  const openProjectDetailsUseCase = async (projectId: string) => {
    const projectUrl = getProjectURL(projectId)
    const relativeProjectUrl = getRelativeURL(projectUrl)
    dispatch(push(relativeProjectUrl))
  }

  return {
    openProjectDetailsUseCase,
  }
}
