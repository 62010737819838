import * as React from 'react'
import TypingHandler from './actionHandlers/classes/typingHandler'
import NotificationsHandler from './actionHandlers/classes/notificationHandler'
import ThreadActivityHandler from './actionHandlers/classes/threadActivityHandler'
import MessageHandler from './actionHandlers/classes/messageHandler'
import CloudConvertHandler from './actionHandlers/classes/cloudConvertHandler'

export interface IWebSocketGateContextArgs {
  typingHandler?: TypingHandler
  threadActivityHandler?: ThreadActivityHandler
  notificationHandler?: NotificationsHandler
  messageHandler?: MessageHandler
  cloudConvertHandler?: CloudConvertHandler
}

export const WebSocketGateContext = React.createContext<IWebSocketGateContextArgs>({})
