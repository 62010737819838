import * as React from 'react'

interface IInfiniteIconProps {
  className?: string
}

const InfiniteIcon = (props: IInfiniteIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="57" viewBox="0 0 58 57" {...props}>
      <path
        fill="#4B5A69"
        fillRule="evenodd"
        d="M42.325 14.239c-4.93.303-9.525 2.194-12.385 5.528.741 1.143 1.32 2.401 1.717 3.77 1.523-4.75 5.903-9.234 11.132-8.48 6.467.935 10.948 6.614 11.082 12.713-.127 5.715-4.928 13.134-11.709 12.156-6.374-.922-10.814-6.448-11.075-12.443h-.002l.007-.124c-.181-8.24-7.416-13.509-15.608-13.34-8.129.165-15.3 5.396-15.48 13.602-.183 8.25 7.69 13.504 15.607 13.34 4.995-.103 9.628-2.12 12.5-5.562-.813-1.214-1.444-2.565-1.86-4.04-1.538 4.615-5.569 8.85-10.843 8.526C8.842 39.481 4.3 33.274 4.168 27.36c-.127-5.747 5.014-12.664 11.52-12.264 6.52.403 11.043 6.529 11.232 12.406h.034c-.005.09-.01.178-.012.267-.089 3.987 2.008 7.72 5.164 10.2 2.982 2.345 6.764 3.003 10.513 2.773C50.66 40.244 57.816 35.537 58 27.21c-.184-8.298-7.45-13.479-15.675-12.972z"
      />
    </svg>
  )
}

export default InfiniteIcon
