import * as React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'actions/navigation'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'
import { showSnackbar } from 'actions/snackbar'
import { useDraftCacheActions } from 'hooks/useDraft/useDraftCacheActions'
import { useReadThreadUseCase } from './readThread'

export const useOpenThreadUseCase = () => {
  const dispatch = useDispatch()
  const { currentThread, setCurrentThread, hasActiveUpload: currentThreadHasActiveUpload } = useCurrentThreadContext()
  const { refreshThread } = useThreadActions()
  const { readThreadUseCase } = useReadThreadUseCase()

  const draftCacheActions = useDraftCacheActions()

  const openThreadByIdUseCase = React.useCallback(
    async ({
      threadId,
      initialData,
      forceOpen = false,
    }: {
      threadId: string
      initialData?: IThread
      forceOpen?: boolean
    }) => {
      if (!forceOpen && currentThread && threadId === currentThread.id) return

      if (currentThreadHasActiveUpload) {
        dispatch(showSnackbar({ message: "Can't leave the thread. Uploading is in progress." }))
        return
      }

      if (currentThread) {
        draftCacheActions.deleteData(draft => draft.threadId === currentThread.id)
      }

      refreshThread(threadId)
      setCurrentThread(threadId, initialData)
      dispatch(push(`/threads/${threadId}`))

      readThreadUseCase(threadId)
    },
    [currentThread, currentThreadHasActiveUpload, readThreadUseCase]
  )

  return {
    openThreadByIdUseCase,
  }
}
