import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getProjectsFiltersQueryKey } from './queryKeys'
import { useUser } from 'hooks/auth'

interface UseProjectsFiltersProps {
  initialOnlyActive?: boolean
  initialOnlyArchived?: boolean
}

interface ProjectsFilters {
  onlyActive: boolean
  onlyArchived: boolean
}

export function useProjectsFilters({
  initialOnlyActive = true,
  initialOnlyArchived = true,
}: UseProjectsFiltersProps = {}) {
  const queryClient = useQueryClient()
  const currentUser = useUser()
  const projectsFiltersQueryKey = getProjectsFiltersQueryKey(currentUser?.id ?? '')

  const initialData: ProjectsFilters = { onlyActive: initialOnlyActive, onlyArchived: initialOnlyArchived }

  const { data: projectsFilters, isLoading } = useQuery({
    enabled: !!currentUser?.id,
    queryKey: projectsFiltersQueryKey,
    queryFn: (): ProjectsFilters => queryClient.getQueryData(projectsFiltersQueryKey) ?? initialData,
    initialData,
  })

  const setFilters = ({ onlyActive, onlyArchived }: ProjectsFilters) => {
    if (!currentUser?.id) return

    queryClient.setQueryData(projectsFiltersQueryKey, { onlyActive, onlyArchived })
  }

  const toggleActiveFilter = () =>
    setFilters({ onlyActive: !projectsFilters.onlyActive, onlyArchived: projectsFilters.onlyArchived })

  const toggleArchivedFilter = () =>
    setFilters({ onlyActive: projectsFilters.onlyActive, onlyArchived: !projectsFilters.onlyArchived })

  return {
    projectsFilters,
    setFilters,
    isLoading,
    toggleActiveFilter,
    toggleArchivedFilter,
  }
}
