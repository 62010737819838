import React from 'react'
import { getAttachmentContentType } from 'domain/attachment'
import AudioIcon from 'icons/file/audioIcon'
import DocIcon from 'icons/file/docIcon'
import ExcelIcon from 'icons/file/excelIcon'
import ImageIcon from 'icons/file/imageIcon'
import PdfIcon from 'icons/file/pdfIcon'
import VideoIcon from 'icons/file/videoIcon'
import IAttachment, { TAttachmentContentType } from 'model/attachment'
import PowerpointIcon from 'icons/file/powerpointIcon'
import UnknownFileIcon from 'icons/file/unknownFileIcon'

interface IAttachmentContentTypeIconProps {
  attachment: Pick<IAttachment, 'fileType'>
  fillColor?: string
  size?: number
}

type TFileTypeIcon = React.FC<{ fill?: string; width: number; height: number }>

export const AttachmentContentTypeIcon = ({ attachment, fillColor, size = 38 }: IAttachmentContentTypeIconProps) => {
  const contentType = getAttachmentContentType(attachment)

  const commonProps = { fill: fillColor, width: size, height: size }

  const contentTypeToIconMapping: Record<TAttachmentContentType, TFileTypeIcon> = {
    text: DocIcon,
    image: ImageIcon,
    spreadsheet: ExcelIcon,
    video: VideoIcon,
    audio: AudioIcon,
    pdf: PdfIcon,
    presentation: PowerpointIcon,
    unknown: UnknownFileIcon,
  }

  const Icon = contentTypeToIconMapping[contentType]

  return <Icon {...commonProps} />
}
