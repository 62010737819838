import React from 'react'

export const LogoWithThreadLiveName = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 184 33">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <path
                fill="#2196F3"
                d="M.201 4.367l2.676 17.67c.08.544.523.967 1.066 1.027l5.09.523V32l6.016-7.668V13.927H9.054V7.909H27.12v6.018h-6.016v10.948l10.864.987c.644.06 1.228-.403 1.308-1.067L35.973 1.53C36.053.765 35.43.12 34.686.18L1.308 2.98C.604 3.039.1 3.683.2 4.367"
                transform="translate(0 1)"
              />
              <g fill="#33373A">
                <path
                  d="M9.416 18.475L5.432 18.475 5.432 3.663.101 3.663.101.181 14.747.181 14.747 3.663 9.416 3.663zM20.522.181v6.702c.945-1.409 2.454-2.153 4.184-2.153 2.817 0 5.09 1.952 5.09 5.534v8.211h-3.782v-7.003c0-2.275-1.147-3.26-2.635-3.26-1.55 0-2.857.965-2.857 3.22v7.064h-3.783V.2l3.783-.02zM36.194 4.991l.202 2.617c.905-1.852 2.676-2.878 5.01-2.878l-.061 3.864h-.785c-2.474 0-3.963 1.288-3.963 4.307v5.574h-3.783V4.991h3.38zM46.355 10.143h6.458c-.241-1.489-1.57-2.636-3.139-2.636-1.69 0-2.997 1.107-3.32 2.636m3.622 5.334c2.374 0 3.561-1.63 3.561-1.63l2.254 2.173s-1.811 2.717-6.056 2.717c-4.185 0-7.243-2.999-7.243-6.963 0-3.945 3.159-7.065 7.283-7.065 3.762 0 6.72 2.959 6.72 6.48 0 .766-.201 1.249-.201 1.249h-10.02c.262 1.811 1.69 3.039 3.702 3.039M62.068 11.753c0 1.993 1.489 3.522 3.5 3.522 2.012 0 3.521-1.55 3.521-3.522 0-1.992-1.509-3.522-3.52-3.522-2.012-.02-3.501 1.53-3.501 3.522zm7.524 6.743l-.322-2.013c-1.046 1.409-2.615 2.274-4.486 2.274-3.561 0-6.499-2.999-6.499-6.963 0-3.945 3.038-7.064 6.64-7.064 1.85 0 3.38.805 4.366 2.153l.322-1.892h3.158v13.484l-3.179.02zM78.425 11.733c0 1.993 1.489 3.522 3.5 3.522 2.012 0 3.521-1.55 3.521-3.522 0-1.992-1.509-3.522-3.52-3.522-2.012 0-3.501 1.53-3.501 3.522zm7.524 6.742l-.322-2.012c-1.046 1.409-2.615 2.274-4.486 2.274-3.561 0-6.499-2.999-6.499-6.963 0-3.945 3.038-7.065 6.64-7.065 1.67 0 3.078.665 4.044 1.812V.16h3.802v18.294H85.95v.02z"
                  transform="translate(0 1) translate(45.67 6.642)"
                />
              </g>
            </g>
            <g fill="#33373A">
              <path
                d="M12.074 18.555L0 18.555 0 .283 2.029.283 2.029 16.756 12.074 16.756zM14.263.445c.262-.263.583-.384.985-.384s.723.12 1.004.384c.261.262.402.586.402.99s-.14.728-.402 1.01c-.26.264-.602.405-1.004.405s-.723-.141-.985-.404c-.26-.263-.381-.607-.381-1.01 0-.405.12-.749.381-.991zm0 18.11v-13.3h1.99v13.3h-1.99zM23.746 18.555L18.121 5.255 20.27 5.255 24.73 16.13 29.19 5.255 31.259 5.255 25.634 18.555zM44.92 12.027H33.529c.02 1.455.502 2.647 1.467 3.618.964.97 2.17 1.455 3.616 1.455.582 0 1.145-.08 1.687-.243.543-.161.964-.363 1.306-.606.341-.242.623-.485.884-.728.24-.242.422-.444.522-.606l.16-.263 1.246 1.01c-.06.082-.12.203-.22.345-.101.141-.322.384-.664.747-.341.364-.723.688-1.145.97-.421.284-.964.526-1.667.748-.703.223-1.446.344-2.21.344-1.969 0-3.616-.647-4.942-1.98-1.326-1.315-1.989-2.952-1.989-4.892 0-1.94.683-3.598 2.03-4.932 1.345-1.334 2.993-2.001 4.961-2.001 1.768 0 3.275.647 4.52 1.92 1.246 1.273 1.889 2.81 1.889 4.608l-.06.486zM38.53 6.549c-1.245 0-2.33.384-3.234 1.152-.904.768-1.467 1.759-1.668 2.951h9.422c-.18-1.172-.683-2.142-1.567-2.93-.863-.789-1.848-1.173-2.953-1.173z"
                transform="translate(0 1) translate(139 7)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
