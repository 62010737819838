import * as React from 'react'
import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { getThreadsQueryKey } from './useThread'
import { getThreadsListQueryKey, TThreadsListData } from './useThreadsList'
import { useUser } from 'hooks/auth'

const getFiltersFromThreadsListQueryKey = (threadsListQueryKey: QueryKey): TThreadsFilterBy[] =>
  threadsListQueryKey[2]?.filterBy ?? []

export const useThreadActions = () => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const getThreadData = React.useCallback(
    (threadId: string) => {
      if (!currentUser?.id) return

      const queryData = queryClient.getQueryData<IThread | undefined>(
        getThreadsQueryKey({ userId: currentUser.id, threadId })
      )

      return queryData
    },
    [queryClient, currentUser?.id]
  )

  const setThreadData = React.useCallback(
    (threadId: string, thread: IThread) => {
      if (!currentUser?.id) return

      queryClient.setQueryData(getThreadsQueryKey({ userId: currentUser.id, threadId }), thread)
    },
    [queryClient, currentUser?.id]
  )

  const refreshThread = React.useCallback(
    (threadId: string, threadInitialData?: IThread) => {
      if (!currentUser?.id) return

      if (threadInitialData) {
        setThreadData(threadId, threadInitialData)
      }
      queryClient.invalidateQueries(getThreadsQueryKey({ userId: currentUser.id, threadId }), { exact: true })
    },
    [queryClient, currentUser?.id]
  )

  const refreshThreadsList = React.useCallback(() => {
    if (!currentUser?.id) return

    queryClient.invalidateQueries({ queryKey: getThreadsListQueryKey(currentUser.id) })
  }, [queryClient, currentUser?.id])

  const getThreadsListActiveData = React.useCallback(() => {
    if (!currentUser?.id) return

    const queriesData = queryClient.getQueriesData<TThreadsListData>({
      queryKey: getThreadsListQueryKey(currentUser.id),
      type: 'active',
    })
    if (!queriesData || queriesData.length === 0) return

    const activeQueryData = queriesData[0]

    const threadsListQueryKey = activeQueryData[0]
    const activeFilters = getFiltersFromThreadsListQueryKey(threadsListQueryKey)

    return {
      data: activeQueryData,
      threadsList: activeQueryData[1]?.pages?.flatMap(page => page.data) ?? [],
      activeFilters,
    }
  }, [queryClient, currentUser?.id])

  const setThreadsListData = React.useCallback(
    (data: [QueryKey, TThreadsListData | undefined]) => {
      const [queryKey, threadsList] = data
      queryClient.setQueryData(queryKey, threadsList)
    },
    [queryClient]
  )

  const insertThreadToThreadsList = React.useCallback(
    (thread: IThread, threadsListData: [QueryKey, TThreadsListData | undefined]) => {
      const [queryKey, listData] = threadsListData

      if (!listData) return

      const pageParams = listData.pageParams
      const pages = listData.pages

      const firstPage = pages.shift()
      if (!firstPage) return
      const firstPageWithNewThread = { ...firstPage, data: [thread, ...firstPage.data] }

      setThreadsListData([queryKey, { pageParams: pageParams, pages: [firstPageWithNewThread, ...pages] }])
    },
    [setThreadsListData]
  )

  const removeThreadFromThreadsList = React.useCallback(
    (threadId: string, threadsListData: [QueryKey, TThreadsListData | undefined]) => {
      const queryKey = threadsListData[0]
      const listData = threadsListData[1]

      if (!listData) return

      const pageParams = listData.pageParams
      const pages = listData.pages

      let removedThread: IThread | undefined

      // removed thread from the data pages
      const newPagesWithRemovedThread = pages.map(page => {
        if (removedThread) return page

        const pageData = page.data
        removedThread = pageData.find(thread => thread.id === threadId)
        const pageDataWithoutThreadToRemove = pageData.filter(thread => thread.id !== threadId)

        return { ...page, data: pageDataWithoutThreadToRemove }
      })

      // thread is not present in the list, no need to update cache
      if (!removedThread) return

      setThreadsListData([queryKey, { pageParams: pageParams, pages: newPagesWithRemovedThread }])

      return removedThread
    },
    [setThreadsListData]
  )

  const updateThreadInThreadsList = React.useCallback(
    (
      threadId: string,
      threadsListData: [QueryKey, TThreadsListData | undefined],
      threadModifier: (thread: IThread) => IThread
    ) => {
      const queryKey = threadsListData[0]
      const listData = threadsListData[1]

      if (!listData) return

      const pageParams = listData.pageParams
      const pages = listData.pages

      const newPagesWithUpdatedThread = pages.map(page => {
        const pageDataWithUpdatedThread = page.data.map(thread => {
          if (thread.id !== threadId) return thread
          return threadModifier(thread)
        })

        return { ...page, data: pageDataWithUpdatedThread }
      })

      setThreadsListData([queryKey, { pageParams: pageParams, pages: newPagesWithUpdatedThread }])
    },
    [setThreadsListData]
  )

  return {
    getThreadData,
    setThreadData,
    refreshThread,
    refreshThreadsList,
    getThreadsListActiveData,
    setThreadsListData,
    insertThreadToThreadsList,
    removeThreadFromThreadsList,
    updateThreadInThreadsList,
  }
}
