import { DateTime } from 'luxon'
import { ANONYMOUS_USER } from '../../constants/auth'

export const MOCK_API_MESSAGES: Array<IAPIMessage> = [
  {
    id: '1',
    content: 'message_content',
    sender: ANONYMOUS_USER,
    threadId: '1',
    messageSubtype: 'user',
    createdAt: '2020-01-15T13:39:12.440+01:00',
    updatedAt: '2020-01-15T13:39:12.440+01:00',
    isEditable: false,
    attachments: [],
    mentionedUsers: [],
    type: 'live_chat',
    deliveryStatus: 'sent',
  },
]

export const MOCK_MESSAGES: Array<IMessage> = [
  {
    attachments: [],
    content: '<div>unique content</div>',
    previousMessageContent: '<div>previous message content</div>',
    isEditable: false,
    isEdited: false,
    type: 'email',
    messageSubtype: 'user',
    threadId: 'e0083e91-98ed-47cb-9775-94e5e70c1ec4',
    deliveryStatus: 'sent',
    id: '1',
    sender: ANONYMOUS_USER,
    createdAt: DateTime.fromISO('2020-01-15T12:39:12.440655+00:00'),
    updatedAt: DateTime.fromISO('2020-01-15T12:39:12.440655+00:00'),
    mentionedUsers: [],
  },
]
