import { TAccountPage } from './../account/account'
import * as React from 'react'

export function useSettingsMenu(onItemSelected: (item: TAccountPage) => void) {
  const [selectedItem, setSelectedItem] = React.useState('profile')

  const handleOnItemClick = React.useCallback(
    (item: TAccountPage) => {
      setSelectedItem(item)
      onItemSelected(item)
    },
    [onItemSelected]
  )

  return { selectedItem, handleOnItemClick }
}
