import * as React from 'react'
import { TAccountPage } from './account'
import Profile from 'component/profile'
import Organization from 'component/organization'

interface IAccountPageSelectProps {
  selectedPage: TAccountPage
}

const AccountPageSelect = ({ selectedPage }: IAccountPageSelectProps) => {
  switch (selectedPage) {
    case 'profile': {
      return <Profile />
    }

    case 'team': {
      return null
    }

    case 'organization': {
      return <Organization />
    }
  }
}

export default AccountPageSelect
