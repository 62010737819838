import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'

import { authReducer } from './auth'
import { errorReducer } from './error'
import { leftSidebarReducer } from './leftSidebar'
import { rightSidebarReducer } from './rightSidebar'
import { userReducer } from './users'
import { threadSnackbarReducer } from './threadSnackbar'
import { persistReducer } from 'redux-persist'
import { userAuthOnboardingReducer } from './userAuthOnboarding'
import { userSettingsReducer, userSettingsPersistConfig } from './userSettings'
import {
  leftSidebarThreadsViewSettingsReducer,
  leftSidebarThreadsViewSettingsPersistConfig,
} from './leftSidebarThreadsViewSettings'

export const makeRootReducer = (history: History) => {
  return combineReducers({
    auth: authReducer,
    error: errorReducer,
    leftSidebar: leftSidebarReducer,
    rightSidebar: rightSidebarReducer,
    router: connectRouter(history),
    users: userReducer,
    snackbar: threadSnackbarReducer,
    userAuthOnboarding: userAuthOnboardingReducer,
    userSettings: persistReducer(userSettingsPersistConfig, userSettingsReducer),
    leftSidebarThreadsViewSettings: persistReducer(
      leftSidebarThreadsViewSettingsPersistConfig,
      leftSidebarThreadsViewSettingsReducer
    ),
  })
}
