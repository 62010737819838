import { WebSocketGateContext } from './../webSocketGateContext'

import * as React from 'react'

interface IUseCloudConvertHandlerArgs {
  callback?: (attachmentId: string) => void
  attachmentId?: string
}

export function useCloudConvertHandler({ callback, attachmentId }: IUseCloudConvertHandlerArgs) {
  const { cloudConvertHandler } = React.useContext(WebSocketGateContext)

  React.useEffect(() => {
    if (callback && attachmentId) {
      cloudConvertHandler?.addCallback(attachmentId, callback)
    }

    return () => {
      if (attachmentId) {
        cloudConvertHandler?.removeCallback(attachmentId)
      }
    }
  }, [attachmentId, callback])
  return { cloudConvertHandler }
}
