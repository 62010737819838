import React from 'react'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import { SharingGroupMemberChip } from 'component/sharingGroup/common/sharingGroupMemberChip'

const useMembersChangedConfirmationBlockStyles = makeStyles((theme: Theme) => ({
  membersChangedConfirmationBlockRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    marginBottom: 8,
  },
  summary: {
    fontWeight: 500,
    textDecoration: 'underline',
  },
  membersContainer: {
    alignItems: 'center',
    columnGap: 4,
    display: 'inline-flex',
    flexWrap: 'wrap',
    rowGap: 4,
  },
}))

export const MembersChangedConfirmationBlock = ({
  members,
  color,
  actionLabel,
}: {
  members: TPerson[]
  color: string
  actionLabel: string
}) => {
  const classes = useMembersChangedConfirmationBlockStyles()
  const countOfMembers = members.length
  const pluralizedPersonLabel = countOfMembers > 1 ? 'people' : 'person'
  if (!countOfMembers) return null

  return (
    <div className={classes.membersChangedConfirmationBlockRoot}>
      <Typography component="span" className={classes.summary}>
        {countOfMembers} {pluralizedPersonLabel} would be {actionLabel} this group
      </Typography>
      <div className={classes.membersContainer}>
        {members.map(person => (
          <SharingGroupMemberChip person={person} key={person.email} accentColor={color} />
        ))}
      </div>
    </div>
  )
}
