import * as React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import { fade, InputBase, Typography } from '@material-ui/core'
import NewProjectItem from 'component/connectThreadToProjectModal.tsx/newProjectItem'
import Spacer from 'component/layoutUtils/spacer'
import debounce from 'lodash/debounce'
import { useFetchProjectByName } from 'hooks/useProjects/useFetchProjectByName'
import BeatProgress from 'component/beatProgress'
import { useNewProjectModalContext } from './newProjectModalContext'
import { useCreateNewProjectUseCase } from 'usecases/createProject'
import ProjectEmoji from 'features/project/projectEmoji'

export const useNewProjectModalContentStyles = makeStyles((theme: Theme) => {
  return {
    newProjectModalContentRoot: {
      backgroundColor: theme.threadPalette.darkerBlue,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: 12,
    },
    headerRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      height: '30px',
      justifyContent: 'flex-start',
    },
    headerText: {
      color: theme.threadPalette.white,
      fontSize: '18px',
      marginLeft: '12px',
    },
    folderIcon: {
      fill: theme.threadPalette.white,
    },
    inputRoot: {
      backgroundColor: theme.threadPalette.white,
      borderRadius: '4px',
      fontSize: 16,
      width: '100%',
    },
    input: {
      paddingInline: '8px',
      '&::placeholder': {
        fontSize: 16,
        opacity: 0.3,
      },
    },
    newProjectItemRoot: {
      color: theme.threadPalette.white,
      paddingBottom: '4px',
      paddingLeft: '4px',
      paddingTop: '4px',
      '&:hover': {
        backgroundColor: fade(theme.threadPalette.white, 0.1),
        borderRadius: '4px',
      },
    },
    projectNameExistsText: {
      color: theme.threadPalette.orange,
      fontSize: '12px',
      fontWeight: 'bold',
      paddingTop: '12px',
    },
  }
})

export const NewProjectModalContent = () => {
  const classes = useNewProjectModalContentStyles()

  const [projectName, setProjectName] = React.useState<string>('')
  const [projectEmoji, setProjectEmoji] = React.useState<string>('')
  const [isCreatingProject, setIsCreatingProject] = React.useState<boolean>(false)

  const {
    isLoading: isProjectWithSameNameLoading,
    onProjectNameChange,
    project: projectWithSameName,
  } = useFetchProjectByName()
  const { closeModal: closeNewProjectModal } = useNewProjectModalContext()
  const { createNewProjectUseCase } = useCreateNewProjectUseCase()

  const debouncedCheckExistingProjectByTitle = React.useCallback(debounce(onProjectNameChange, 300), [])

  React.useEffect(() => {
    debouncedCheckExistingProjectByTitle(projectName)
  }, [projectName])

  const onChangeProjectName = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const projectName = event.target.value
    setProjectName(projectName)
  }

  const onCreateNewProject = React.useCallback(async () => {
    setIsCreatingProject(true)

    const newProject = await createNewProjectUseCase({ projectName, emoji: projectEmoji })

    if (newProject) {
      closeNewProjectModal()
    }

    setIsCreatingProject(false)
  }, [projectName, closeNewProjectModal, createNewProjectUseCase])

  const loadingProjectByName = isProjectWithSameNameLoading && projectName
  const projectWithSameNameExists = !isProjectWithSameNameLoading && projectName && projectWithSameName
  const canCreateProject = !isProjectWithSameNameLoading && projectName && !projectWithSameName

  return (
    <div className={classes.newProjectModalContentRoot}>
      <div className={classes.headerRoot}>
        <ProjectEmoji emoji={projectEmoji} className={classes.folderIcon} onUpdateEmoji={setProjectEmoji} />
        <Typography className={classes.headerText}>Create a new project</Typography>
      </div>
      <Spacer height={8} />
      <InputBase
        classes={{ root: classes.inputRoot, input: classes.input }}
        placeholder="New project name"
        value={projectName}
        onChange={onChangeProjectName}
      />
      {loadingProjectByName && <BeatProgress />}
      {projectWithSameNameExists && (
        <Typography className={classes.projectNameExistsText}>This project name already exists</Typography>
      )}
      {canCreateProject && (
        <NewProjectItem
          isLoading={isCreatingProject}
          onSelect={onCreateNewProject}
          name={projectName}
          rootClassName={classes.newProjectItemRoot}
        />
      )}
    </div>
  )
}
