import React from 'react'

interface IAuthContext {
  redirectURL?: string
  setRedirectURL(redirectURL?: string): void
}

export const AuthContext = React.createContext<IAuthContext | undefined>(undefined)

export const AuthContextProvider: React.FC<{}> = ({ children }) => {
  const [redirectURL, setRedirectURL] = React.useState<string>()

  return (
    <AuthContext.Provider
      value={{
        redirectURL,
        setRedirectURL,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const context = React.useContext(AuthContext)

  if (context === undefined) throw new Error('useAuthContext should be used within a AuthContextProvider ')

  return context
}
