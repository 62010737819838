import React from 'react'
import ErrorScreen from 'component/errorScreen'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useErrorReporterPageStyles = makeStyles((theme: Theme) => ({
  errorReportPageContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
  },
}))

const ErrorReporter = () => {
  const classes = useErrorReporterPageStyles()
  const onButtonClick = () => {
    window.location.reload()
  }

  return (
    <div className={classes.errorReportPageContainer}>
      <ErrorScreen
        title="Oops, that's our bad"
        subtitle="We apologize and are fixing the problem. Please try again later."
        iconMessage="500 Error"
        onActionClick={onButtonClick}
      />
    </div>
  )
}

export default ErrorReporter
