import { apiClient } from '@jetkit/react'
import { AxiosResponse } from 'axios'
import { IProject } from 'model/project'
import { IThreadSharingSettings } from 'model/sharingGroup'

const PREFIX = '/v2/thread'

export const createThread = async (title: string, projectId?: string): Promise<IThread> => {
  const result = await apiClient.post(`${PREFIX}`, {
    title: title,
    project_id: projectId,
  })
  return result.data
}

export const getThreadByTitle = async (title: string): Promise<IThread> => {
  const result = await apiClient.get(`${PREFIX}/title/${title}`)
  return result.data
}

export const starThread = async (threadId: string): Promise<void> => {
  const result = await apiClient.patch(`${PREFIX}/${threadId}/star`)
  return result.data
}

export const updateThreadTitleAlias = async ({
  threadId,
  titleAlias,
}: {
  threadId: string
  titleAlias?: string
}): Promise<void> => {
  const result = await apiClient.patch(`${PREFIX}/${threadId}/alias`, { title_alias: titleAlias })
  return result.data
}

export const getThreadProjects = async (threadId: string): Promise<IProject[]> => {
  const result = await apiClient.get(`${PREFIX}/${threadId}/projects`)
  return result.data
}

export const followThread = async (threadId: string): Promise<void> => {
  const result = await apiClient.patch(`${PREFIX}/${threadId}/follow`)
  return result
}

export const getSharingSettingsForThread = async (threadId: string): Promise<IThreadSharingSettings[]> => {
  const result = await apiClient.get<IThreadSharingSettings[]>(`${PREFIX}/${threadId}/sharing-settings`)
  return result.data
}

type IUpdateThreadNotesAPIProps = {
  threadId: string
  notes: string
}
export const updateThreadNotes = async ({ threadId, notes }: IUpdateThreadNotesAPIProps): Promise<void> => {
  const result = await apiClient.patch<Omit<IUpdateThreadNotesAPIProps, 'threadId'>, AxiosResponse<void>>(
    `${PREFIX}/${threadId}/notes`,
    { notes }
  )
  return result.data
}
