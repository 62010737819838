import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { shareMessageWithSharingGroup as shareMessageWithSharingGroupApiCall } from 'api/v2/sharingGroup'
import { useThreadSharingSettingsCacheActions } from 'hooks/useSharingGroup/useThreadSharingSettingsCacheActions'
import { IThreadSharingSettings } from 'model/sharingGroup'
import { useDispatch } from 'react-redux'

export type IShareMessageWithSharingGroupUseCaseProps = Normalize<
  Parameters<typeof shareMessageWithSharingGroupApiCall>[0] & { threadId: string }
>

export const useShareMessageWithSharingGroupUseCase = () => {
  const dispatch = useDispatch()

  const threadSharingSettingsCacheActions = useThreadSharingSettingsCacheActions()

  const { reset: resetShareMessageWithSharingGroupMutation, mutateAsync: runShareMessageWithSharingGroupMutation } =
    useMutation(
      ({ sharingGroupId, messageId }: IShareMessageWithSharingGroupUseCaseProps) =>
        shareMessageWithSharingGroupApiCall({ sharingGroupId, messageId }),
      {
        onMutate: ({ sharingGroupId, threadId, messageId }) => {
          const shareMessageWithSharingGroup = (threadSharingSettings: IThreadSharingSettings) => {
            const heldMessagesIds = threadSharingSettings.heldMessagesIds.filter(id => id !== messageId)
            const sharedMessagesIds = [...threadSharingSettings.sharedMessagesIds, messageId]
            return { ...threadSharingSettings, heldMessagesIds, sharedMessagesIds }
          }
          const rollbackOptimisticUpdates = threadSharingSettingsCacheActions.updateData({
            sharingGroupId,
            threadId,
            threadSharingSettingsModifier: shareMessageWithSharingGroup,
          })
          return rollbackOptimisticUpdates
        },
        onError: (_, __, rollbackOptimisticUpdates) => {
          rollbackOptimisticUpdates?.()
          dispatch(showSnackbar({ message: 'Error on sharing message with sharing group' }))
        },
        onSettled: (_, __, { threadId }) => {
          threadSharingSettingsCacheActions.refreshData(threadId)
          resetShareMessageWithSharingGroupMutation()
        },
      }
    )

  const shareMessageWithSharingGroupUseCase: (props: IShareMessageWithSharingGroupUseCaseProps) => Promise<void> =
    runShareMessageWithSharingGroupMutation

  return { shareMessageWithSharingGroupUseCase }
}
