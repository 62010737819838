import { IOnboardingTourStep, TOnboardingTourStepPlacement } from 'contexts/useOnboardingTourContext'

export enum OnboardingTourTargetComponent {
  threadPage = 'threadPage',
  threadAlias = 'threadAlias',
  threadFollowBadge = 'threadFollowBadge',
  addIntegration = 'addIntegration',
  projectsDashboardLeftSidebarMenu = 'projectsDashboardLeftSidebarMenu',
  shareThreadButton = 'shareThreadButton',
  copyThreadLinkButton = 'copyThreadLinkButton',
  createNewThreadButton = 'createNewThreadButton',
}

interface IOnboardingTourStepProps {
  content: JSX.Element
  spotlightPadding?: number
  showProgress?: boolean
  nextStepButtonLabel?: string
  lastStepButtonLabel?: string
  previousStepButtonLabel?: string
  skipStepButtonLabel?: string
  hideSkipButton?: boolean
  onNextStepButtonClick?: () => void
}

interface IOnboardingTourStepPlacementProps {
  target: string
  placement: TOnboardingTourStepPlacement
}

export const createUntargetedOnboardingStep = (stepProps: IOnboardingTourStepProps) =>
  createOnboardingStep({
    target: 'body',
    placement: 'center',
    ...stepProps,
  })

export const createTargetedOnboardingStep = ({
  target,
  ...stepProps
}: IOnboardingTourStepProps & IOnboardingTourStepPlacementProps) =>
  createOnboardingStep({
    target: `#${target}`,
    ...stepProps,
  })

const createOnboardingStep = ({
  content,
  target,
  placement,
  spotlightPadding = 10,
  showProgress = false,
  hideSkipButton = false,
  nextStepButtonLabel = 'Next',
  lastStepButtonLabel = 'Done',
  previousStepButtonLabel = 'BACK',
  skipStepButtonLabel = 'SKIP',
  onNextStepButtonClick,
}: IOnboardingTourStepProps & IOnboardingTourStepPlacementProps): IOnboardingTourStep => ({
  content,
  target,
  placement,
  spotlightPadding,
  showProgress,
  hideSkipButton,
  onNextStepButtonClick,
  locale: {
    back: previousStepButtonLabel,
    skip: skipStepButtonLabel,
    next: nextStepButtonLabel,
    last: lastStepButtonLabel,
  },
  disableBeacon: true,
})
