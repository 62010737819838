import React from 'react'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { useUser } from './auth'
import { useThreadPageContext } from 'page/threadPageContextProvider'

interface IUseThreadLoadedListenerProps {
  callback({ thread }: { thread: IThread }): void
}

export const useThreadLoadedListener = ({ callback }: IUseThreadLoadedListenerProps) => {
  const currentUser = useUser()

  const [lastProcessedThreadId, setLastProcessedThreadId] = React.useState('')

  const { currentThread, isLoading: isLoadingThread, responseError: threadLoadingError } = useCurrentThreadContext()
  const { isLoadingMessages, messagesListLoadingError } = useThreadPageContext()

  React.useEffect(() => {
    const isLoading = isLoadingThread || isLoadingMessages || !currentUser || currentThread?.isImporting
    const hasError = !!threadLoadingError || !!messagesListLoadingError
    const isThreadProcessed = lastProcessedThreadId === currentThread?.id

    if (!currentThread || isThreadProcessed || isLoading || hasError) return

    setLastProcessedThreadId(currentThread.id)
    callback({ thread: currentThread })
  }, [
    callback,
    currentUser,
    currentThread,
    isLoadingThread,
    isLoadingMessages,
    lastProcessedThreadId,
    threadLoadingError,
    messagesListLoadingError,
  ])
}
