import * as React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orangeCircle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 12,
      height: 12,
      borderRadius: 6,
      backgroundColor: 'orange',
    },
    whiteCircle: {
      width: 2,
      height: 2,
      borderRadius: 1,
      backgroundColor: theme.threadPalette.white,
    },
  })
)

const UnreadIcon = ({ className }: { className?: string }) => {
  const classes = useStyles()

  const rootClassName = classnames(className, classes.orangeCircle)

  return (
    <div className={rootClassName}>
      <div className={classes.whiteCircle}></div>
    </div>
  )
}

export default UnreadIcon
