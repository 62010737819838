import { checkAuth } from 'actions/checkAuth'
import { showSnackbar } from 'actions/snackbar'
import { useDispatch } from 'react-redux'
import { useBoolean } from 'hooks/useBoolean'
import * as React from 'react'
import { IOrganization, IOrganizationMember } from 'model/organization'
import {
  getOrgMembers,
  addNewOrgMember,
  acceptUserInvite,
  denyUserInvite,
  removeMember,
  addAdmin,
} from 'api/organization'
import { useFilteredMembers } from './useFilteredMembers'

export function useOrgMembers(user: TUser, organization: IOrganization) {
  const dispatch = useDispatch()
  const [members, setMembers] = React.useState<IOrganizationMember[]>([])
  const { state: dialogOpen, toggle: toggleDialogOpen } = useBoolean(false)

  const reloadMembers = React.useCallback(async () => {
    const orgMembers = await getOrgMembers(organization.id)
    setMembers(orgMembers)
  }, [organization.id])

  React.useEffect(() => {
    reloadMembers()
  }, [organization, reloadMembers])

  const { handleSearchQueryChange, filteredMembers } = useFilteredMembers(members)

  const handleLeaveOrg = React.useCallback(
    async (memberId: string) => {
      if (user && organization.ownerIds.includes(user.id) && organization.ownerIds.length === 1) {
        dispatch(showSnackbar({ message: 'You cannot leave organization if you are the only admin' }))
      } else {
        await removeMember(organization.id, memberId)
        dispatch(checkAuth())
      }
    },
    [dispatch, organization.id, organization.ownerIds, user]
  )

  const handleMenuItemSelect = React.useCallback(
    async (memberId: string, item: string) => {
      switch (item) {
        case 'open': {
          break
        }
        case 'leave': {
          handleLeaveOrg(memberId)
          break
        }
        case 'cancel': {
          await denyUserInvite(organization.id, memberId)
          break
        }
        case 'approve': {
          await acceptUserInvite(organization.id, memberId)
          break
        }
        case 'remove': {
          await removeMember(organization.id, memberId)
          break
        }
        case 'make_admin': {
          await addAdmin(organization.id, memberId)
          dispatch(checkAuth())
          break
        }
      }
      reloadMembers()
    },
    [dispatch, handleLeaveOrg, organization.id, reloadMembers]
  )

  const onAddNewMember = React.useCallback(
    async (email: string, firstName?: string, lastName?: string) => {
      const newMember = await addNewOrgMember(organization.id, email, firstName, lastName)
      setMembers(prev => [...prev, newMember])

      const msg =
        user?.id && organization.ownerIds.includes(user.id)
          ? `The invite to join this organization was sent to ${firstName + ' ' + lastName}`
          : `You suggested to invite ${
              firstName + ' ' + lastName
            } to join this organization. The invite is now awaiting approval from the organization admins.`
      dispatch(showSnackbar({ message: msg }))
    },
    [dispatch, organization.id, organization.ownerIds, user]
  )

  return {
    filteredMembers,
    handleSearchQueryChange,
    handleMenuItemSelect,
    dialogOpen,
    toggleDialogOpen,
    onAddNewMember,
  }
}
