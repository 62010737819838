import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Divider, Theme, Typography } from '@material-ui/core'
import LogoOnlyIcon from 'icons/logoOnly'
import { PeopleSharingWorkItemsIcon } from 'icons/peopleSharingWorkItemsIcon'

const useThreadDescriptionStepStyles = makeStyles((theme: Theme) => ({
  threadDescriptionStepRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 34,
    justifyContent: 'center',
    padding: 10,
  },
  threadDescriptionHeader: {
    color: theme.threadPalette.menuItem.current,
    fontSize: 30,
    textAlign: 'center',
  },
  whatIsAThreadQuestion: {
    color: theme.palette.primary.main,
    marginLeft: 6,
  },
  threadDescriptionStepContent: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonRoot: {
    paddingBlock: 6,
    paddingInline: 22,
  },
  buttonLabel: {
    fontSize: 16,
  },
  threadTermText: {
    color: theme.threadPalette.menuItem.normal,
    fontSize: 20,
  },
  definitionText: {
    color: theme.threadPalette.menuItem.normal,
    fontSize: 16,
  },
  infoText: {
    color: theme.threadPalette.menuItem.normal,
    fontSize: 14,
  },
  divider: {
    backgroundColor: theme.threadPalette.menuItem.normal,
    width: 200,
  },
  definitionList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginBottom: 0,
    marginTop: 14,
    paddingInlineStart: 20,
  },
  definitionListItem: {
    paddingLeft: 10,
  },
  graphicDefinitionContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    marginTop: 14,
  },
  graphicDefinitionLogo: {
    height: 18,
    width: 18,
  },
  peopleSharingWorkItemsIcon: {
    height: 200,
  },
  syncIcon: {
    marginInline: 6,
  },
}))

interface IThreadDescriptionStepProps {
  onSuccess(): void
}

export const ThreadDescriptionStep = ({ onSuccess }: IThreadDescriptionStepProps) => {
  const classes = useThreadDescriptionStepStyles()

  const syncIcon = (
    <span role="img" aria-label="Sync" className={classes.syncIcon}>
      🔄
    </span>
  )

  return (
    <div className={classes.threadDescriptionStepRoot}>
      <div>
        <Typography className={classes.threadDescriptionHeader}>
          To use <strong>Thread</strong>Live effectively
        </Typography>
        <Typography className={classes.threadDescriptionHeader}>
          you need to understand:
          <span className={classes.whatIsAThreadQuestion}>
            What is a{' '}
            <strong>
              <i>“thread”</i>
            </strong>{' '}
            ?
          </span>
        </Typography>
      </div>
      <section className={classes.threadDescriptionStepContent}>
        <Typography className={classes.threadTermText}>
          <strong>Thread</strong> [thred]
        </Typography>
        <Divider className={classes.divider} />
        <Typography className={classes.infoText}>
          <i>(noun)</i>
        </Typography>
        <ol className={classes.definitionList}>
          <li className={classes.definitionListItem}>
            <Typography className={classes.definitionText}>
              A <strong>digital container</strong> that captures all messages from its connected email
            </Typography>
          </li>
          <li className={classes.definitionListItem}>
            <Typography className={classes.definitionText}>
              An <strong>object</strong> with a <strong>unique URL</strong> that can be shared
            </Typography>
          </li>
          <li className={classes.definitionListItem}>
            <Typography className={classes.definitionText}>Object-Oriented Email™</Typography>
          </li>
        </ol>
        <div className={classes.graphicDefinitionContainer}>
          <Typography className={classes.infoText}>
            <i>Graphic definition:</i>
          </Typography>
          <LogoOnlyIcon className={classes.graphicDefinitionLogo} />
        </div>
      </section>
      <PeopleSharingWorkItemsIcon className={classes.peopleSharingWorkItemsIcon} />
      <Button
        variant="contained"
        color="primary"
        classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
        onClick={onSuccess}
      >
        Threads sync{syncIcon}with connected emails
      </Button>
    </div>
  )
}
