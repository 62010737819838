import * as React from 'react'
import { useIconStyles } from '../styles'
import classnames from 'classnames'

interface IVideoIconProps extends JSX.IntrinsicAttributes {
  width?: number
  height?: number
  className?: string
  fill?: string
}

const VideoIcon = ({ className, width, height, fill }: IVideoIconProps) => {
  const classes = useIconStyles()
  return (
    <div className={classnames(className, classes.iconWrapper)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : '20'}
        height={height ? height : '20'}
        viewBox="0 0 20 20"
      >
        <path
          fill={fill ? fill : '#4A4A4A'}
          d="M17.268 19.714c.283 0 .523-.099.72-.297.199-.198.298-.438.298-.72V6.481c0-.283-.071-.594-.212-.933-.142-.34-.311-.608-.51-.806l-3.307-3.308c-.198-.198-.467-.367-.806-.509-.34-.141-.65-.212-.933-.212h-9.5c-.283 0-.523.1-.721.297C2.099 1.21 2 1.45 2 1.732v16.964c0 .283.099.523.297.721.198.198.438.297.72.297h14.25zm-.34-1.357H3.359V2.071H11.5v4.411c0 .283.099.523.297.721.198.198.438.297.72.297h4.412v10.857zm-.084-12.214h-3.987V2.156c.205.07.35.149.435.234l3.318 3.318c.085.085.163.23.234.435zm-6.701 9.5c.367 0 .685-.134.954-.403.269-.269.403-.587.403-.954v-4.072c0-.367-.134-.685-.403-.954-.269-.269-.587-.403-.954-.403H6.07c-.367 0-.685.134-.954.403-.268.269-.403.587-.403.954v4.072c0 .367.135.685.403.954.269.269.587.403.954.403h4.072zm5.09 0c.027 0 .07-.007.126-.021.142-.057.212-.163.212-.318V9.196c0-.155-.07-.261-.212-.318-.056-.014-.099-.02-.127-.02-.099 0-.18.031-.244.095l-2.81 2.82v.954l2.81 2.82c.064.064.145.096.244.096z"
        />
      </svg>
    </div>
  )
}

export default VideoIcon
