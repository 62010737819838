import { SvgIcon } from '@material-ui/core'
import * as React from 'react'
interface IBackgroundIconProps extends JSX.IntrinsicAttributes {
  className?: string
}

const BackgroundIcon = (props: IBackgroundIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 21 16"
      style={{ height: 'inherit', width: 'inherit', transform: 'translateY(2px)' }}
      {...props}
    >
      <g id="5.-Message-area" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Messaging-Modes---Email" transform="translate(-577.000000, -664.000000)">
          <g id="MessageInput/Component/Email" transform="translate(265.000000, 638.000000)">
            <g id="Group-4" transform="translate(37.000000, 22.000000)">
              <g id="RichTextBlock">
                <g id="Group-2">
                  <g id="Group-5" transform="translate(78.000000, 4.000000)">
                    <g id="Group-7-Copy" transform="translate(197.000000, 0.000000)">
                      <g id="Icon/RichText/BackColor">
                        <g>
                          <path
                            d="M0.7,14 L13.3,14 C13.6865993,14 14,14.3134007 14,14.7 C14,15.0865993 13.6865993,15.4 13.3,15.4 L0.7,15.4 C0.313400675,15.4 4.73447626e-17,15.0865993 0,14.7 C-4.73447626e-17,14.3134007 0.313400675,14 0.7,14 Z"
                            id="Path"
                            fill="#606060"
                          ></path>
                          <g id="Group-5" transform="translate(2.800000, 0.000000)" fill="#606060">
                            <path
                              d="M0.79457,9.63599 L3.31737,11.14967 C3.704554,11.385374 4.238304,11.2770924 4.48714,10.86365 C4.79941,10.35013 5.27464,9.88638 5.91066,9.47513 L1.05434,6.57881 C1.0313716,7.29029 0.847616,7.91756 0.51184,8.44851 C0.254268,8.853404 0.3816834,9.380588 0.79457,9.63599 Z"
                              id="Fill-1"
                            ></path>
                            <path
                              d="M9.0902,1.75168 L6.36622,0.11536 C6.00747,-0.10066 5.50599,0.0043442 5.26372,0.34286 L1.20652,5.9899 L6.33948,9.04918 L9.4052,2.83122 C9.59497,2.44622 9.4577,1.97372 9.0902,1.751694 L9.0902,1.75168 Z"
                              id="Fill-2"
                            ></path>
                            <path
                              d="M0.50036,12.7417053 C0.6387192,12.7356645 1.313564,12.7077735 1.27036,12.7094143 C1.42786,12.7033987 1.57059,12.6191801 1.65536,12.4819142 L2.076998,11.7780922 L2.32582,11.9279342 C2.477846,12.0132474 2.64684,11.956371 2.72559,11.82895 L2.87707,11.5752 L0.61355,10.20747 L0.45878,10.46724 C0.3772958,10.60724 0.4210458,10.78497 0.5610458,10.86701 L0.7322238,10.9698232 L0.0972958,12.0274812 C-0.0924742,12.3446652 0.1399524,12.7417053 0.5003418,12.7417053 L0.50036,12.7417053 Z"
                              id="Fill-3"
                            ></path>
                          </g>
                        </g>
                      </g>
                      <g id="Icon/Triangle">
                        <g transform="translate(13.600000, 5.600000)">
                          <polygon
                            id="Mask"
                            fill="#606060"
                            transform="translate(3.500000, 3.500000) rotate(90.000000) translate(-3.500000, -3.500000) "
                            points="1.75 7 5.25 3.5 1.75 4.6629367e-15"
                          ></polygon>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export default BackgroundIcon
