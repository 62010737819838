import { DependencyList, useCallback, useEffect } from 'react'
import sanitizeHtml from 'sanitize-html'
import { validate } from 'email-validator'

const ALLOWED_ATTRIBUTES = {
  a: ['href', 'name', 'target'],
  img: ['src'],
  span: ['style'],
  div: ['style'],
  blockquote: ['style', 'itemtype'],
  sup: ['style'],
  sub: ['style'],
  strike: ['style'],
}

export const sanitizeContent = (content: string) => sanitizeHtml(content, { allowedAttributes: ALLOWED_ATTRIBUTES })

export const useDebouncedEffect = (effect: () => void, delay: number, deps: DependencyList) => {
  const callback = useCallback(effect, deps)

  useEffect(() => {
    const handler = setTimeout(() => {
      callback()
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [callback, delay, deps])
}

export const getShortenedName = (name: string | null): string => {
  if (!name) return ''

  const spacesSafeName = name.replace(/\s\s+/g, ' ')
  const parts = spacesSafeName.split(' ')
  if (parts.length > 1) {
    const trimmedName = parts
      .map((item, index) => {
        if (index > 0) {
          return item[0]?.concat('.') ?? ''
        } else return item
      })
      .join(' ')
    return trimmedName
  } else return parts[0]
}

export const isEmail = (str: string): boolean => {
  return validate(str)
}

export const filterHtmlTags = (text: string): string => {
  return text.replace(/<\/?[^>]+(>|$)/g, '')
}

export const getNameParts = (name: string | null): { firstName: string; lastName: string } => {
  let firstName, lastName

  if (!name || isEmail(name)) {
    firstName = ''
    lastName = ''
  } else {
    const parts = name.split(' ')
    firstName = parts[0]
    lastName = parts.splice(-1, 1).join(' ')
  }
  return {
    firstName,
    lastName,
  }
}

export function insertIf<T>(condition: boolean, elements: T) {
  return condition ? elements : []
}

export const flattenObject = (obj: any) => {
  const flattened: any = {}

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}

export function removeDuplicates<T>(data: T[], filterFunc: (o1: T, o2: T) => boolean) {
  const unique: T[] = []
  data.forEach((item: T) => {
    if (!unique.find(uniqueItem => filterFunc(item, uniqueItem))) {
      unique.push(item)
    }
  })
  return unique
}

export const saveLoginState = (state: string) => {
  localStorage.setItem('loginState', state)
}

export const checkIntegrity = (state: string) => {
  // check if state we receive in redirect link overlaps
  // with the state that was sent in the original auth request
  const loginState = localStorage.getItem('loginState')

  if (loginState !== state) {
    throw Error("Login state doesn't match")
  } else {
    localStorage.removeItem('loginState')
    return true
  }
}

export const scrollElementIntoView = (
  ref: HTMLElement | null,
  { behavior = 'smooth', scrollTo = 'start' }: { behavior?: ScrollBehavior; scrollTo?: 'start' | 'end' } = {}
) => {
  ref?.scrollIntoView({ behavior, block: scrollTo })
}

export const scrollContainerIntoPosition = ({
  ref,
  scrollPosition,
  behavior = 'auto',
}: {
  ref: HTMLElement | null
  scrollPosition: number
  behavior?: ScrollBehavior
}) => ref?.scrollTo({ top: scrollPosition, behavior })

export const base64Encrypt = (stringToEncrypt: string) => btoa(stringToEncrypt)

export const getTimeInMilliSeconds = ({
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
}: {
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
}) => {
  const oneSecondInMilliSeconds = 1000
  const oneMinuteInMilliSeconds = oneSecondInMilliSeconds * 60
  const oneHourInMilliSeconds = oneMinuteInMilliSeconds * 60
  const oneDayInMilliSeconds = oneHourInMilliSeconds * 24

  return (
    days * oneDayInMilliSeconds +
    hours * oneHourInMilliSeconds +
    minutes * oneMinuteInMilliSeconds +
    seconds * oneSecondInMilliSeconds
  )
}
