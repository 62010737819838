import * as React from 'react'
import { useDispatch } from 'react-redux'
import { getThreadTitle } from 'api/threads'
import { RouteComponentProps } from 'react-router-dom'
import { LiveLinkPageContent } from './liveLinkPageContent'
import AppLoading from 'component/appLoading'
import HeadProvider from 'component/head/headProvider'
import { push } from 'actions/navigation'
import { useAuthContext } from 'contexts/authContext'
import QueryString from 'query-string'
import { getThreadURL } from 'domain/thread'
import { getRelativeURL } from 'lib/url'

interface ILiveLinkPageProps extends RouteComponentProps<{ id: string }> {}

export const LiveLinkPage = (props: ILiveLinkPageProps) => {
  const dispatch = useDispatch()

  const queryParams = QueryString.parse(props.location.search)
  const { setRedirectURL } = useAuthContext()

  const threadId = props.match.params.id

  const [threadTitle, setThreadTitle] = React.useState<string>('')

  React.useEffect(() => {
    const loadThreadTitle = async () => {
      const { title = '', mergedToThreadId } = await getThreadTitle(threadId)

      if (mergedToThreadId) {
        dispatch(push(`/threads/${mergedToThreadId}`))
      }

      setThreadTitle(title)
    }
    loadThreadTitle()
    saveRedirectURL(threadId)
  }, [threadId])

  const saveRedirectURL = (threadId: string) => {
    const attachmentId = queryParams.file as string | undefined

    const redirectURL = getThreadURL({ id: threadId, attachmentId })
    const relativeURL = getRelativeURL(redirectURL)

    setRedirectURL(relativeURL)
  }

  if (!threadTitle) return <AppLoading visible />

  return (
    <>
      <HeadProvider
        pageTitle={`Access to ${threadTitle}`}
        ogTitle={`${threadTitle}`}
        ogDescription="Sign in to Threadlive to access Thread messages and followers"
      />
      <LiveLinkPageContent threadTitle={threadTitle} />
    </>
  )
}
