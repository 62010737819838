import React from 'react'

interface IThreadSharingModalIconProps {
  className?: string
}

export const ThreadSharingModalIcon = ({ className }: IThreadSharingModalIconProps) => {
  return (
    <svg className={className} viewBox="0 0 608 749" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Share modul</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="600" height="741" rx="17"></rect>
        <filter x="-1.2%" y="-0.7%" width="102.3%" height="101.9%" filterUnits="objectBoundingBox" id="filter-2">
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
        <rect id="path-3" x="360" y="658" width="200" height="50" rx="5"></rect>
        <filter x="-3.5%" y="-10.0%" width="107.0%" height="128.0%" filterUnits="objectBoundingBox" id="filter-4">
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g id="1-Onboarding" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="SVG-images" transform="translate(-116.000000, -1988.000000)">
          <g id="Share-modul" transform="translate(120.000000, 1990.000000)">
            <g id="Rectangle">
              <use fill="black" fillOpacity="1" filter="url(#filter-2)" href="#path-1"></use>
              <use fill="#FFFFFF" fillRule="evenodd" href="#path-1"></use>
            </g>
            <text id="Future-messages" fontFamily="Roboto-Bold, Roboto" fontSize="16" fontWeight="bold" fill="#33373A">
              <tspan x="39.1111111" y="318">
                Future messages
              </tspan>
            </text>
            <rect
              id="Select/Check-Small/NotSelected"
              fill="#9BC53E"
              x="55"
              y="339"
              width="12"
              height="12"
              rx="2"
            ></rect>
            <polygon
              id="Page-1"
              fill="#FFFFFF"
              points="59.9076923 346.2 57.9692308 344.4 57 345.3 59.9076923 348 65.4 342.9 64.4307692 342"
            ></polygon>
            <text
              id="Share-messages-that"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="18"
              fontWeight="normal"
              line-spacing="25"
            >
              <tspan x="77" y="349" fill="#33373A">
                Share messages that include the same list of people from the{' '}
              </tspan>
              <tspan x="77" y="374" fill="#33373A">
                previous shared message, and any new recipients (
              </tspan>
              <tspan x="483.230469" y="374" fill="#2196F3">
                more info
              </tspan>
              <tspan x="560.00293" y="374" fill="#33373A">
                )
              </tspan>
            </text>
            <rect
              id="Select/Check-Small/NotSelected"
              stroke="#AAAAAA"
              fill="#FFFFFF"
              x="55"
              y="400"
              width="12"
              height="12"
              rx="2"
            ></rect>
            <text
              id="All-future-messages"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="18"
              fontWeight="normal"
              fill="#33373A"
            >
              <tspan x="77" y="412">
                All future messages I recieve
              </tspan>
            </text>
            <text
              id="All-existing-message"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="22"
              fontWeight="normal"
              fill="#33373A"
            >
              <tspan x="58" y="268">
                All existing messages
              </tspan>
            </text>
            <g id="Group" transform="translate(30.000000, 252.000000)">
              <circle id="Oval" stroke="#9BC53E" cx="9" cy="9" r="8"></circle>
              <circle id="Oval-Copy" fill="#9BC53E" cx="9" cy="9" r="4"></circle>
            </g>
            <text
              id="Select-messages"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="22"
              fontWeight="normal"
              fill="#33373A"
            >
              <tspan x="330" y="268">
                Select messages
              </tspan>
            </text>
            <g id="Oval" transform="translate(302.000000, 252.000000)" fill="#FFFFFF" stroke="#AAAAAA">
              <circle cx="9" cy="9" r="8"></circle>
            </g>
            <text
              id="Thread-Name-Copy-2"
              fontFamily="Roboto-Bold, Roboto"
              fontSize="22"
              fontWeight="bold"
              fill="#33373A"
            >
              <tspan x="30" y="228">
                This group can see
              </tspan>
            </text>
            <g id="Rectangle-5">
              <use fill="black" fillOpacity="1" filter="url(#filter-4)" href="#path-3"></use>
              <use fill="#2196F3" fillRule="evenodd" href="#path-3"></use>
            </g>
            <text id="Send-invite" fontFamily="Roboto-Medium, Roboto" fontSize="20" fontWeight="400" fill="#FFFFFF">
              <tspan x="402.993164" y="688">
                Create share
              </tspan>
            </text>
            <rect
              id="Select/Check-Small/NotSelected"
              fill="#9BC53E"
              x="40"
              y="677"
              width="12"
              height="12"
              rx="2"
            ></rect>
            <polygon
              id="Page-1"
              fill="#FFFFFF"
              points="44.9076923 684.2 42.9692308 682.4 42 683.3 44.9076923 686 50.4 680.9 49.4307692 680"
            ></polygon>
            <text
              id="Notify-people-by-ema"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="18"
              fontWeight="normal"
              fill="#606060"
            >
              <tspan x="64" y="689">
                Notify people by email
              </tspan>
            </text>
            <rect
              id="Rectangle"
              stroke="#979797"
              fill="#FFFFFF"
              x="20.5"
              y="451.5"
              width="559"
              height="153"
              rx="4"
            ></rect>
            <text
              id="Add-a-custom-message"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="22"
              fontWeight="normal"
              line-spacing="33"
              fill="#AAAAAA"
            >
              <tspan x="34" y="481">
                Add a custom message to explain why you are sharing{' '}
              </tspan>
              <tspan x="34" y="514">
                this thread and what you hope to achieve.
              </tspan>
            </text>
            <text id="Thread-Name" fontFamily="Roboto-Medium, Roboto" fontSize="28" fontWeight="400" fill="#33373A">
              <tspan x="30" y="61">
                Share “Your Thread Name”
              </tspan>
            </text>
            <rect
              id="Rectangle"
              stroke="#C4C4C4"
              strokeWidth="1.5"
              fill="#FFFFFF"
              x="20.75"
              y="93.75"
              width="558.5"
              height="88.5"
              rx="12"
            ></rect>
            <text
              id="Michael-Strayhan,-St"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="12"
              fontWeight="normal"
              line-spacing="18"
              fill="#606060"
            >
              <tspan x="73" y="148">
                Michael Strayhan, Steve Urkle, Sammy David Jr., Penope Wunderlust
              </tspan>
            </text>
            <text id="people---material" fontFamily="material" fontSize="30" fontWeight="normal" fill="#6B6B6B">
              <tspan x="30" y="129">
                
              </tspan>
            </text>
            <text
              id="ATL-Deployment-and-a"
              fontFamily="Roboto-Bold, Roboto"
              fontSize="22"
              fontWeight="bold"
              fill="#6B6B6B"
            >
              <tspan x="73" y="126">
                Team Get'R Done
              </tspan>
            </text>
            <text id="Edit-group" fontFamily="Roboto-Regular, Roboto" fontSize="12" fontWeight="normal" fill="#95B1C9">
              <tspan x="471" y="123">
                Edit group
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  )
}
