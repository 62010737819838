import { showSnackbar } from 'actions/snackbar'
import { useProjectsFilters } from 'hooks/useProjects/useProjectsFilters'
import { IProject } from 'model/project'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useOpenProjectDetailsUseCase } from 'usecases/openProjectDetails'
import { useUpdateParentProjectUseCase } from 'usecases/project/updateParentProject'

interface IProjectsTreeContext {
  onlyActive: boolean
  onlyArchived: boolean
  isFetchingEnabled: boolean
  openProjectDetails(projectId: string): void
  movingProject?: IProject
  setMovingProject(project?: IProject): void
  updateParentForSelectedProject(parentProject?: IProject): void
  isMovingProject: boolean
}

export const ProjectsTreeContext = React.createContext<IProjectsTreeContext | undefined>(undefined)

export const ProjectsTreeContextProvider: React.FC<{}> = ({ children }) => {
  const dispatch = useDispatch()

  const [movingProject, setMovingProject] = React.useState<IProject>()

  const { projectsFilters, isLoading: isLoadingProjectsFilters } = useProjectsFilters()
  const { openProjectDetailsUseCase } = useOpenProjectDetailsUseCase()
  const { updateParentProjectUseCase } = useUpdateParentProjectUseCase()

  const updateProjectParent = ({ project, parentProject }: { project: IProject; parentProject?: IProject }) => {
    const isMovingToRoot = !parentProject
    const isRootProject = !project.parentProjectId

    if (isRootProject && isMovingToRoot) {
      dispatch(showSnackbar({ message: `Project ${project.name} is already in the root.` }))
      return
    }

    if (isMovingToRoot) {
      updateParentProjectUseCase({ projectId: project.id, parentProjectId: undefined })
      setMovingProject(undefined)
      return
    }

    if (project.id === parentProject?.id) {
      dispatch(showSnackbar({ message: `Project ${project.name} cannot be parent of itself.` }))
      return
    }

    if (project.parentProjectId === parentProject?.id) {
      dispatch(showSnackbar({ message: `Project ${project.name} is already nested to ${parentProject.name}.` }))
      return
    }

    updateParentProjectUseCase({ projectId: project.id, parentProjectId: parentProject.id })
    setMovingProject(undefined)
  }

  const updateParentForSelectedProject = (parentProject?: IProject) => {
    if (!movingProject) {
      console.log(`Project is not selected, can't update parent.`)
      return
    }
    updateProjectParent({ project: movingProject, parentProject })
  }

  const openProjectDetails = (projectId: string) => openProjectDetailsUseCase(projectId)

  return (
    <ProjectsTreeContext.Provider
      value={{
        onlyActive: projectsFilters.onlyActive,
        onlyArchived: projectsFilters.onlyArchived,
        isFetchingEnabled: !isLoadingProjectsFilters,
        openProjectDetails,
        setMovingProject,
        movingProject,
        updateParentForSelectedProject,
        isMovingProject: !!movingProject,
      }}
    >
      {children}
    </ProjectsTreeContext.Provider>
  )
}

export const useProjectsTreeContext = () => {
  const context = React.useContext(ProjectsTreeContext)

  if (context === undefined)
    throw new Error('useProjectsTreeContext should be used within a ProjectsTreeContextProvider ')

  return context
}
