import * as React from 'react'
import { Theme, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import {
  GetApp as DownloadIcon,
  Add as ZoomInIcon,
  Remove as ZoomOutIcon,
  Fullscreen as FullscreenIcon,
} from '@material-ui/icons'
import AppLoading from 'component/appLoading'

const useAttachmentDisplayControlOverlayStyles = makeStyles((theme: Theme) => ({
  attachmentDisplayControlOverlayRoot: {
    alignItems: 'flex-end',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: 50,
    marginRight: 50,
    position: 'absolute',
    right: 0,
  },
  downloadIcon: {
    marginBottom: '40px !important',
  },
  icon: {
    alignItems: 'center',
    backgroundColor: theme.threadPalette.white,
    borderRadius: 20,
    boxShadow: '0 10px 24px 0 rgba(0, 0, 0, 0.2), 0 10px 24px 0 rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    marginBottom: 10,
    width: 40,
    '&:hover': {
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
  },
}))

interface IAttachmentDisplayControlOverlayProps {
  show: boolean
  onDownload(): void
  onFullScreenClick(): void
  onZoomInClick(): void
  onZoomOutClick(): void
  isDownloading: boolean
}

export const AttachmentDisplayControlOverlay = ({
  show,
  onDownload,
  onFullScreenClick,
  onZoomInClick,
  onZoomOutClick,
  isDownloading,
}: IAttachmentDisplayControlOverlayProps) => {
  const theme = useTheme()
  const classes = useAttachmentDisplayControlOverlayStyles()

  if (!show) return null

  const onDownloadClick = () => {
    if (isDownloading) return
    onDownload()
  }

  return (
    <div className={classes.attachmentDisplayControlOverlayRoot}>
      <div className={classnames(classes.downloadIcon, classes.icon)} onClick={onDownloadClick}>
        {isDownloading ? <AppLoading visible small fill={theme.threadPalette.onFocusGray} /> : <DownloadIcon />}
      </div>
      <div className={classes.icon} onClick={onFullScreenClick}>
        <FullscreenIcon />
      </div>
      <div className={classes.icon} onClick={onZoomInClick}>
        <ZoomInIcon />
      </div>
      <div className={classes.icon} onClick={onZoomOutClick}>
        <ZoomOutIcon />
      </div>
    </div>
  )
}
