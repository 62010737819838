import React from 'react'
import classNames from 'classnames'

import { Button, Menu, Theme, makeStyles, MenuItem, Tooltip, useTheme } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import { getProjectURL } from 'domain/project'
import LinkIcon from '@material-ui/icons/Link'
import GroupIcon from '@material-ui/icons/Group'
import DeleteIcon from '@material-ui/icons/Delete'
import { useCopyContentToClipboard } from 'hooks/useCopyContentToClipboard'
import { SubdirectoryArrowRight as UpdateParentProjectIcon, Add as PlusIcon } from '@material-ui/icons/'
import { useProjectsTreeContext } from 'component/projectsTree/projectsTreeContext'
import { IProject } from 'model/project'
import DialogAnchoredOnComponent from 'component/dialogAnchoredOnComponent'
import { useDeleteProjectUseCase } from 'usecases/project/deleteProject'

const useProjectActionsButtonWithModalStyles = makeStyles((theme: Theme) => ({
  projectHeaderRoot: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    gap: 20,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 20,
    },
  },
  projectBaseInfo: {
    flexGrow: 1,
  },
  ownerAvatarContainer: {
    alignItems: 'center',
    color: theme.threadPalette.darkGray,
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    fontWeight: 500,
    gap: 2,
    paddingTop: 12,
  },
  actionIcon: {
    color: 'inherit',
    cursor: 'pointer',
    opacity: 0.7,
  },
  projectAction: {
    color: theme.threadPalette.darkGray,
    fontSize: 14,
    fontWeight: 500,
    gap: 12,
    '&:hover': {
      color: theme.threadPalette.skyBlue,
      backgroundColor: theme.threadPalette.backgroundColor,
    },
  },
  moveAction: {
    transform: 'rotateX(180deg)',
  },
  copyAction: {
    cursor: 'copy',
  },
  tooltip: {
    fontSize: 12,
  },
  warningModalContainer: {
    maxWidth: 520,
    width: '100%',
  },
  warningTextContainer: {
    fontSize: 18,
  },
  dangerText: {
    color: theme.threadPalette.vermilion,
    fontSize: 'inherit',
  },
}))

export type ProjectActions = 'moveProject' | 'copyLink' | 'share' | 'createNestedProject' | 'delete'

type ProjectActionWithModalProps = {
  project?: IProject
  actionsToHide?: ProjectActions[]
  iconClassName?: string
  onNewNestedProject?(): void
  onProjectDeleted?(): void
}

export const ProjectActionsButtonWithModal = ({
  project,
  actionsToHide,
  iconClassName,
  onNewNestedProject,
  onProjectDeleted,
}: ProjectActionWithModalProps) => {
  const classes = useProjectActionsButtonWithModalStyles()
  const { setMovingProject } = useProjectsTreeContext()
  const {
    isOpen: isProjectActionsOpen,
    handleClickToOpenDialog: openProjectActions,
    handleCloseDialog: closeProjectActionsModal,
    dialogAnchorElement: openProjectActionsButtonRef,
  } = useDialog()

  const projectUrl = project?.id ? getProjectURL(project.id) : null
  const { copyContentToClipboard } = useCopyContentToClipboard({ messageOnCopy: 'Project link copied to clipboard' })

  const shouldHideAction = (action: ProjectActions) => actionsToHide?.includes(action)
  const { deleteProjectUseCase } = useDeleteProjectUseCase({ onSuccess: onProjectDeleted })
  return (
    <>
      <Button size="small" onClick={openProjectActions}>
        <MoreHoriz className={classNames(classes.actionIcon, iconClassName)} />
      </Button>
      <Menu
        open={isProjectActionsOpen}
        anchorEl={openProjectActionsButtonRef}
        keepMounted
        onClose={closeProjectActionsModal}
      >
        {!shouldHideAction('createNestedProject') && (
          <MenuItem
            onClick={() => {
              closeProjectActionsModal()
              onNewNestedProject?.()
            }}
            dense
            classes={{ dense: classes.projectAction }}
            disabled={!project?.id}
          >
            <PlusIcon fontSize="small" className={classes.actionIcon} />
            Nested Project
          </MenuItem>
        )}
        {!shouldHideAction('moveProject') && (
          <MenuItem
            onClick={() => {
              setMovingProject(project)
              closeProjectActionsModal()
            }}
            dense
            classes={{ dense: classes.projectAction }}
            disabled={!project?.id}
          >
            <UpdateParentProjectIcon fontSize="small" className={classNames(classes.actionIcon, classes.moveAction)} />
            Move project
          </MenuItem>
        )}
        {!shouldHideAction('copyLink') && (
          <MenuItem
            dense
            classes={{ dense: classNames(classes.projectAction, classes.copyAction) }}
            onClick={() => {
              if (!projectUrl) return
              copyContentToClipboard(projectUrl.toString())
              closeProjectActionsModal()
            }}
            disabled={!projectUrl}
          >
            <LinkIcon fontSize="small" className={classes.actionIcon} />
            Copy project link
          </MenuItem>
        )}
        {!shouldHideAction('share') && (
          <Tooltip title="Coming soon" classes={{ tooltip: classes.tooltip }}>
            <div>
              <MenuItem disabled dense classes={{ dense: classes.projectAction }}>
                <GroupIcon fontSize="small" className={classes.actionIcon} />
                Share
              </MenuItem>
            </div>
          </Tooltip>
        )}
        {!shouldHideAction('delete') && (
          <DeleteProjectMenuItemWithConfirmationModal
            onClick={async () => {
              project?.id && (await deleteProjectUseCase(project.id))
              closeProjectActionsModal()
            }}
            isDisabled={!project?.id}
            actionClassName={classes.projectAction}
            actionIconClassName={classes.actionIcon}
          />
        )}
      </Menu>
    </>
  )
}

const DeleteProjectMenuItemWithConfirmationModal = ({
  onClick,
  actionClassName,
  actionIconClassName,
  isDisabled = false,
}: {
  onClick?(): void
  actionClassName?: string
  actionIconClassName?: string
  isDisabled?: boolean
}) => {
  const classes = useProjectActionsButtonWithModalStyles()
  const theme = useTheme()
  const {
    isOpen: isDeleteProjectConfirmationPopupOpen,
    dialogAnchorElement,
    openDialog: openDeleteProjectConfirmationPopup,
    handleCloseDialog: closeDeleteProjectConfirmationPopup,
    setDialogAnchorRef: setDeleteProjectConfirmationPopupAnchorElement,
  } = useDialog()

  return (
    <DialogAnchoredOnComponent
      rootClassName={classes.warningModalContainer}
      open={isDeleteProjectConfirmationPopupOpen}
      anchoredElementRef={dialogAnchorElement}
      onClose={closeDeleteProjectConfirmationPopup}
      actionsAlign="right"
      message={
        <div className={classes.warningTextContainer}>
          <strong className={classes.dangerText}>WARNING</strong>: Deleting this Project{' '}
          <span className={classes.dangerText}>
            will also delete <strong>all notes</strong> and <strong>all Nested Projects</strong> of this Project
          </span>
          , this action is irreversible.
          <br />
          be careful.
        </div>
      }
      actions={[
        {
          label: 'Cancel',
          labelColor: theme.palette.primary.main,
          action: closeDeleteProjectConfirmationPopup,
        },
        {
          label: 'Delete',
          labelColor: 'white',
          backgroundColor: 'red',
          paddingInline: 32,
          action: () => {
            onClick?.()
            closeDeleteProjectConfirmationPopup()
          },
        },
      ]}
    >
      <MenuItem
        ref={setDeleteProjectConfirmationPopupAnchorElement}
        onClick={openDeleteProjectConfirmationPopup}
        dense
        classes={{ dense: actionClassName }}
        disabled={isDisabled}
      >
        <DeleteIcon fontSize="small" className={actionIconClassName} />
        Delete project
      </MenuItem>
    </DialogAnchoredOnComponent>
  )
}
