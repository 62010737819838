export const selectAuth = (state: IState) => state.auth
export const selectChecked = (state: IState) => selectAuth(state).checked
export const selectGoogleOAuthLoggingIn = (state: IState) => {
  return selectAuth(state).googleOAuthLoggingIn
}
export const selectGoogleOAuthHandlingCallback = (state: IState) => {
  return selectAuth(state).googleOAuthHandlingCallback
}
export const selectOutlook365OAuthLoggingIn = (state: IState) => {
  return selectAuth(state).outlook365OAuthLoggingIn
}
export const selectOutlook365OAuthHandlingCallback = (state: IState) => {
  return selectAuth(state).outlook365OAuthHandlingCallback
}
export const selectUser = (state: IState) => selectAuth(state).user
