import React from 'react'

function ProjectIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={22} height={24} viewBox="0 0 22 24" {...props}>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-19.000000, -13.000000)" fill="#E5E5E5" fillRule="nonzero">
          <g transform="translate(19.000000, 13.000000)">
            <path d="M0.416559595,19.7004491 L9.49367194,23.869085 L9.56086498,23.8018919 L9.56086498,10.892221 L9.56086498,10.825028 L0.0130875544,6.45456649 L0.0130875544,18.8935723 C-0.0543240126,19.2969547 0.147473642,19.565951 0.416469945,19.7005388 L0.416559595,19.7004491 Z"></path>
            <path d="M10.9729051,8.40448711 L20.1844931,4.23585129 L20.1844931,4.16865824 L20.1844931,4.1014652 L11.3090944,0.067193044 C11.1747083,0 11.1072912,0 10.9729051,0 C10.838519,0 10.7711018,0 10.6367157,0.067193044 L1.76131698,4.1014652 L1.76131698,4.16865824 L1.76131698,4.23585129 L10.9729051,8.40448711 Z"></path>
            <path d="M12.3849003,10.8922883 L12.3849003,23.8019592 L12.5192864,24.0037624 L12.4520934,23.8019592 L21.5292057,19.6333233 C21.798202,19.4989373 21.9997811,19.2299409 21.9997811,18.9609446 L22,6.52193884 L21.932807,6.4547458 L12.3849003,10.8922883 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ProjectIcon
