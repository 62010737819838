import { selectRightSidebar } from '../selectors/rightSidebar'
import { RIGHT_SIDEBAR_ACTIONS } from '../constants/actions/rightSidebar'

export const setOption = (option: TRightSidebarOption): TThunk => {
  return (dispatch, getState) => {
    dispatch({
      type: RIGHT_SIDEBAR_ACTIONS.SET_OPTION,
      optionSelected: option,
    })
  }
}

export const setBackOption = (backOption: TRightSidebarOption): TThunk => {
  return (dispatch, getState) => {
    dispatch({
      type: RIGHT_SIDEBAR_ACTIONS.SET_BACK_OPTION,
      backOption: backOption,
    })
  }
}

export const setSearch = (search: string): TThunk => {
  return (dispatch, getState) => {
    const rightSidebarState = selectRightSidebar(getState())

    if (search !== rightSidebarState.search) {
      dispatch({
        type: RIGHT_SIDEBAR_ACTIONS.SET_SEARCH,
        search: search,
      })
      // todo search trough threads
    }
  }
}
