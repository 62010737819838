import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { rejectHeldMessageForSharingGroup as rejectHeldMessageForSharingGroupApiCall } from 'api/v2/sharingGroup'
import { useThreadSharingSettingsCacheActions } from 'hooks/useSharingGroup/useThreadSharingSettingsCacheActions'
import { IThreadSharingSettings } from 'model/sharingGroup'
import { useDispatch } from 'react-redux'

export type IRejectHeldMessageForSharingGroupUseCaseProps = Normalize<
  Parameters<typeof rejectHeldMessageForSharingGroupApiCall>[0] & { threadId: string }
>

export const useRejectHeldMessageForSharingGroupUseCase = () => {
  const dispatch = useDispatch()

  const threadSharingSettingsCacheActions = useThreadSharingSettingsCacheActions()

  const {
    reset: resetRejectHeldMessageForSharingGroupMutation,
    mutateAsync: runRejectHeldMessageForSharingGroupMutation,
  } = useMutation(
    ({ sharingGroupId, messageId }: IRejectHeldMessageForSharingGroupUseCaseProps) =>
      rejectHeldMessageForSharingGroupApiCall({ sharingGroupId, messageId }),
    {
      onMutate: ({ sharingGroupId, threadId, messageId }) => {
        const rejectHeldMessageForSharingGroup = (threadSharingSettings: IThreadSharingSettings) => {
          const heldMessagesIds = threadSharingSettings.heldMessagesIds.filter(id => id !== messageId)
          return { ...threadSharingSettings, heldMessagesIds }
        }
        const rollbackOptimisticUpdates = threadSharingSettingsCacheActions.updateData({
          sharingGroupId,
          threadId,
          threadSharingSettingsModifier: rejectHeldMessageForSharingGroup,
        })
        return rollbackOptimisticUpdates
      },
      onError: (_, __, rollbackOptimisticUpdates) => {
        rollbackOptimisticUpdates?.()
        dispatch(showSnackbar({ message: 'Error on rejecting held message for sharing group' }))
      },
      onSettled: (_, __, { threadId }) => {
        threadSharingSettingsCacheActions.refreshData(threadId)
        resetRejectHeldMessageForSharingGroupMutation()
      },
    }
  )

  const rejectHeldMessageForSharingGroupUseCase: (
    props: IRejectHeldMessageForSharingGroupUseCaseProps
  ) => Promise<void> = runRejectHeldMessageForSharingGroupMutation

  return { rejectHeldMessageForSharingGroupUseCase }
}
