import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import UserBadge from 'component/threadHeaderUserBadge'
import { useUser } from 'hooks'
import { IconButton, Theme, Typography } from '@material-ui/core'
import { useThreadPageContext } from 'page/threadPageContextProvider'
import { getUserDisplayName } from 'dataProcessors/userDisplayName'
import FilterButton from 'component/buttons/filterButton'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'
import Spacer from 'component/layoutUtils/spacer'
import WrapIntoSkeletonLoading from 'component/wrapIntoSkeletonLoading'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { useThreadFollowers } from 'hooks/useThreadFollowers/useThreadFollowers'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignContent: 'center',
    border: 'solid 2px transparent',
    borderRadius: 6,
    display: 'flex',
    maxWidth: '100%',
    paddingRight: 8,
    width: 'fit-content',
  },
  rootWithActiveFilters: {
    border: `solid 2px ${theme.palette.primary.main}`,
  },
  followersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterButton: {
    borderRadius: '6px 0 0 6px',
    margin: -2,
    marginRight: 6,
    padding: '6px 8px',
    width: 24,
  },
  filterMessage: {
    alignSelf: 'center',
    color: theme.palette.primary.main,
    fontSize: 12,
    marginRight: 10,
  },
  closeButton: {
    alignSelf: 'center',
    height: 'fit-content',
    padding: 4,
  },
  closeButtonIcon: {
    fontSize: 16,
  },
}))

const ThreadFollowersBar: React.FC = () => {
  const classes = useStyles()
  const currentUser = useUser()
  const { recipientsFilter: filteredRecipientsIds, updateRecipientsFilter } = useThreadPageContext()
  const { currentThread } = useCurrentThreadContext()

  const { followers, isLoading: isLoadingFollowers } = useThreadFollowers(currentThread?.id)

  const hasActiveFilter = filteredRecipientsIds.length > 0
  const followersWithoutCurrentUser = followers.filter(follower => follower.id !== currentUser?.id) ?? []

  const getFilteredFollowers = () => {
    if (!hasActiveFilter) return followersWithoutCurrentUser

    const filteredFollowers = followersWithoutCurrentUser.filter(follower =>
      filteredRecipientsIds.includes(follower.id)
    )
    return filteredFollowers
  }

  const resetFilteredFollowers = () => {
    updateRecipientsFilter([])
  }

  const getFollowersToDisplay = () =>
    getFilteredFollowers().map(follower => (
      <UserBadge key={follower.id} name={getUserDisplayName(follower)} userId={follower.id} />
    ))

  const getSkeletonLoadingFollowers = () =>
    [...Array(4)].map((_, index) => (
      <React.Fragment key={index}>
        <WrapIntoSkeletonLoading isLoading={true} animation="wave">
          <UserBadge name="random@email.com" userId="randomId" />
        </WrapIntoSkeletonLoading>
        <Spacer width={4} />
      </React.Fragment>
    ))

  return (
    <div className={classNames(classes.root, { [classes.rootWithActiveFilters]: hasActiveFilter })}>
      {hasActiveFilter && (
        <>
          <FilterButton
            classes={{ button: classes.filterButton }}
            isSelected={hasActiveFilter}
            keepIconWhenSelected={true}
          />
          <Typography align="center" className={classes.filterMessage}>
            Messages that include
          </Typography>
        </>
      )}
      <div className={classes.followersContainer}>
        {isLoadingFollowers && !followers ? getSkeletonLoadingFollowers() : getFollowersToDisplay()}
      </div>
      {hasActiveFilter && (
        <IconButton className={classes.closeButton} size="small" onClick={resetFilteredFollowers}>
          <CloseIcon classes={{ root: classes.closeButtonIcon }} color="primary" />
        </IconButton>
      )}
    </div>
  )
}

export default ThreadFollowersBar
