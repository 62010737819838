import * as React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import EmailThreadSearch from 'component/newThreadModal/connectExistingEmailThread/emailThreadSearch'
import SubmitButtonWithLoading from 'component/submitButtonWithLoading'
import { ISelectedEmailThread } from './useEmailThreadSearch'
import { useOpenThreadUseCase } from 'usecases/openThread'
import { useCreateThreadFromEmailUseCase } from 'usecases/createThreadFromEmail'

const useConnectExistingEmailThreadStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    searchWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflowY: 'auto',
      width: '100%',
    },
    buttonStyle: {
      fontFamily: 'Roboto',
      fontSize: 18,
      height: 40,
      marginTop: 40,
      width: 300,
      [theme.breakpoints.down('md')]: {
        marginTop: 32,
      },
    },
  })
)

interface IConnectExistingEmailThreadProps {
  onSuccess?(): void
  hideSearch?: boolean
  buttonText?: string
  projectId?: string
  navigateToThreadOnSuccess?: boolean
}

const ConnectExistingEmailThread = ({
  onSuccess,
  hideSearch = false,
  buttonText,
  projectId,
  navigateToThreadOnSuccess = true,
}: IConnectExistingEmailThreadProps) => {
  const classes = useConnectExistingEmailThreadStyles()

  const { openThreadByIdUseCase } = useOpenThreadUseCase()

  const [selectedEmailThread, setSelectedEmailThread] = React.useState<ISelectedEmailThread>()

  const onThreadCreated = (newThread: IThread) => {
    onSuccess?.()
    navigateToThreadOnSuccess && openThreadByIdUseCase({ threadId: newThread.id, initialData: newThread })
  }

  const { isLoading, createThreadFromEmailUseCase } = useCreateThreadFromEmailUseCase({ onThreadCreated })

  const onEmailThreadSelected = React.useCallback((emailThread?: ISelectedEmailThread) => {
    setSelectedEmailThread(emailThread)
  }, [])

  const handleOnCreateThread = React.useCallback(async () => {
    if (!selectedEmailThread) return
    createThreadFromEmailUseCase(selectedEmailThread.emailThreadId, selectedEmailThread.subject, projectId)
  }, [selectedEmailThread, projectId])

  const defaultButtonText = 'Connect & create a thread'

  return (
    <div className={classes.root}>
      <div className={classes.searchWrapper}>
        <EmailThreadSearch
          selectedEmailThreadId={selectedEmailThread?.emailThreadId || ''}
          onEmailThreadSelected={onEmailThreadSelected}
          hideSearch={hideSearch}
        />
      </div>
      <SubmitButtonWithLoading
        id="create_button"
        variant="contained"
        color="primary"
        className={classes.buttonStyle}
        disabled={!!!selectedEmailThread}
        isLoading={isLoading}
        onClick={handleOnCreateThread}
      >
        {buttonText ?? defaultButtonText}
      </SubmitButtonWithLoading>
    </div>
  )
}

export default ConnectExistingEmailThread
