import * as React from 'react'
import { Theme, useTheme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Button, Typography } from '@material-ui/core'
import DownloadButtonIcon from 'icons/download'
import AppLoading from 'component/appLoading'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    text: {
      maxWidth: 300,
      fontSize: 19,
      color: theme.threadPalette.white,
    },

    button: {
      width: 300,
      height: 50,
      marginTop: 32,
      textTransform: 'none',
    },
  })
)

interface IPreviewUnsupportedProps {
  onDownload(): void
  isDownloading: boolean
}

const downloadIcon = <DownloadButtonIcon fill="white" />

const PreviewUnsupported = ({ onDownload, isDownloading }: IPreviewUnsupportedProps) => {
  const theme = useTheme()
  const classes = useStyles()

  const onDownloadClick = () => {
    if (isDownloading) return
    onDownload()
  }

  const buttonIcon = isDownloading ? (
    <AppLoading visible small fill={theme.threadPalette.white} padding={10} />
  ) : (
    downloadIcon
  )
  const buttonLabel = isDownloading ? 'Downloading' : 'Download File'

  return (
    <div className={classes.root}>
      <Typography className={classes.text} align="center">
        Thread can’t display this type of file. Please download to view it.
      </Typography>

      <Button
        color="primary"
        variant="contained"
        startIcon={buttonIcon}
        className={classes.button}
        onClick={onDownloadClick}
      >
        {buttonLabel}
      </Button>
    </div>
  )
}

export default PreviewUnsupported
