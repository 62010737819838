import React from 'react'
import { Theme, Typography, makeStyles } from '@material-ui/core'
import { LogoWithThreadLiveName } from 'icons/logoWithThreadLiveName'
import { PeopleSharingWorkItemsIcon } from 'icons/peopleSharingWorkItemsIcon'

const useWelcomeToSharedThreadStepStyles = makeStyles((theme: Theme) => ({
  welcomeToSharedThreadStepRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    maxWidth: 530,
  },
  logoWithThreadLiveNameIcon: {
    height: 30,
  },
  title: {
    fontSize: 34,
    textAlign: 'center',
  },
  peopleSharingWorkItemsIcon: {
    height: 200,
  },
  contentText: {
    fontSize: 18,
    lineHeight: 2,
    textAlign: 'center',
  },
}))

export const WelcomeToSharedThreadStep = () => {
  const classes = useWelcomeToSharedThreadStepStyles()

  return (
    <div className={classes.welcomeToSharedThreadStepRoot}>
      <LogoWithThreadLiveName className={classes.logoWithThreadLiveNameIcon} />
      <Typography className={classes.title}>
        Welcome to a <strong>shared thread</strong>
      </Typography>
      <PeopleSharingWorkItemsIcon className={classes.peopleSharingWorkItemsIcon} />
      <Typography className={classes.contentText}>
        You were invited to a shared thread so you can track the conversation and never miss a detail.
      </Typography>
    </div>
  )
}
