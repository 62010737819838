import * as React from 'react'
import classnames from 'classnames'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indicatorStyle: {
      position: 'relative',
      marginRight: 3,
      width: 12,
      height: 12,
      border: 'solid, 0.5 white',
      borderRadius: '50%',
    },
    innerCircle: {
      position: 'absolute',
      right: 3,
      left: 3,
      top: 3,
      bottom: 3,
      backgroundColor: theme.threadPalette.white,
      borderRadius: '50%',
    },
    offline: {
      backgroundColor: theme.threadPalette.lightishGray,
    },
    online: {
      backgroundColor: theme.threadPalette.green,
    },
    away: {
      backgroundColor: theme.threadPalette.star,
    },
  })
)

interface IUserConnectionStatusProps {
  onlineStatus?: IOnlineStatus
  isActiveInThread?: boolean
  isCurrentUser?: boolean
  className?: string
}

const UserConnectionStatus = ({
  onlineStatus,
  isCurrentUser,
  isActiveInThread,
  className,
}: IUserConnectionStatusProps) => {
  const classes = useStyles()

  const determineStatusColorStyle = React.useCallback(() => {
    const offlineStatusClass = classes.offline
    if (!process.env.USER_CONNECTIVITY_STATUS_ENABLED) return offlineStatusClass

    const statusClass = onlineStatus ? classes[onlineStatus.status] : offlineStatusClass
    return statusClass
  }, [classes, onlineStatus])

  const statusColor = isCurrentUser ? classes.online : determineStatusColorStyle()

  return (
    <div className={classnames(classes.indicatorStyle, statusColor, className)}>
      {!isActiveInThread && <div className={classes.innerCircle} />}
    </div>
  )
}

export default UserConnectionStatus
