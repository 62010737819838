import * as React from 'react'
import AppLoading from '../component/appLoading'

interface IAppLoadingPageProps {}

const AppLoadingPage = (props: IAppLoadingPageProps) => {
  return <AppLoading visible={true} />
}

export default AppLoadingPage
