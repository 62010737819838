import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  tabLabel: {
    width: 170,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  icon: {
    marginRight: 8,
    width: 15,
    height: 15,
  },
}))

interface IManagementTabLabelProps {
  icon: React.ElementType
  label: React.ReactNode | string
}

const ManagementTabLabel: React.FC<IManagementTabLabelProps> = ({ icon: Icon, label }) => {
  const classes = useStyles()

  return (
    <div className={classes.tabLabel}>
      {<Icon className={classes.icon} />}
      {label}
    </div>
  )
}

export default ManagementTabLabel
