import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, Chip, ChipProps } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'

const useChipStyles = makeStyles((theme: Theme) => ({
  chipRoot: {
    padding: 2,
  },
  chipLabel: {
    color: theme.threadPalette.white,
    fontSize: 15,
  },
  deleteIcon: {
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    fill: theme.threadPalette.white,
    justifyContent: 'center',
    '& svg': {
      color: 'white',
      height: 16,
      width: 16,
    },
    '&:hover': {
      backgroundColor: theme.threadPalette.white,
      '& svg': {
        color: theme.threadPalette.lightGray,
      },
    },
  },
}))

interface ICustomChipProps extends ChipProps {
  className?: string
  label: string
}

const CustomChip = ({ label, deleteIcon, className, ...props }: ICustomChipProps) => {
  const classes = useChipStyles()
  const defaultDeleteIcon = <CloseIcon />
  return (
    <Chip
      {...props}
      label={label}
      deleteIcon={<div className={classes.deleteIcon}>{deleteIcon ?? defaultDeleteIcon}</div>}
      classes={{
        root: classNames(classes.chipRoot, className),
        label: classes.chipLabel,
      }}
    />
  )
}

export default CustomChip
