import { useUser } from '../auth'
import * as React from 'react'
import IAttachment, { IAttachmentUploadState, IInlineAttachment } from '../../model/attachment'
import { handleError, PrepareUploadResponse } from '@jetkit/react'
import useFileUpload from 'hooks/useFileUpload'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { useUpdateAttachmentUploadStatusUseCase } from 'usecases/attachment/updateAttachmentUploadStatus'
import {
  createInlineAttachmentFromFile,
  createStandardAttachmentFromFile,
  getAttachmentContent,
} from 'domain/attachment'

export interface AttachmentsValues {
  deleteAttachment: (attachmentId: string) => void
  addAttachment: (attachmentsList: IAttachment[]) => void
  addUploadStatuses: (uploadStatuses: IAttachmentUploadState[]) => void
  attachments: IAttachmentsList
  setAttachments: React.Dispatch<React.SetStateAction<IAttachmentsList>>
  uploadStatuses: IAttachmentUploadState[]
  uploadAttachments: (files: File[]) => Promise<void>
  clearAttachments: () => void
  uploadsInProgress: number
  addUploadsInProgress: (uploads: number) => void
  onFilePaste: (
    event: React.ClipboardEvent<HTMLElement>,
    onSuccess?: (imageSource: string, customData?: string) => void
  ) => void
}

export interface IAttachmentsList {
  standard: IAttachment[]
  inline: IInlineAttachment[]
}

type OnAttachmentSetChangeCallback = (attachments: IAttachment[]) => void
type OnInlineAttachmentSetChangeCallback = (attachments: IInlineAttachment[]) => void

export interface PreUploadResponse extends IAttachment, PrepareUploadResponse {
  contentId?: string
}

interface useMessageAttachmentsProps {
  attachmentCallback?: OnAttachmentSetChangeCallback
  inlineAttachmentCallback?: OnInlineAttachmentSetChangeCallback
  initialAttachments?: IAttachment[]
  onAttachmentUploadError?(attachment?: IAttachment): void
}

export default function useMessageAttachments({
  attachmentCallback,
  inlineAttachmentCallback,
  onAttachmentUploadError,
  initialAttachments = [],
}: useMessageAttachmentsProps = {}) {
  const [attachments, setAttachments] = React.useState<IAttachmentsList>({
    standard: initialAttachments,
    inline: [],
  })
  const [uploadStatuses, setUploadStatuses] = React.useState<IAttachmentUploadState[]>([])
  const [uploadsInProgress, setUploadsInProgress] = React.useState(0)
  const currentUser = useUser()
  const { prepareUploadRequest, uploadFileToS3 } = useFileUpload<PreUploadResponse>('/v2/attachment/standard')
  const { prepareUploadRequest: prepareInlineAttachmentUploadRequest, uploadFileToS3: uploadInlineAttachmentToS3 } =
    useFileUpload<PreUploadResponse>('/v2/attachment/inline')
  const { setHasActiveUpload } = useCurrentThreadContext()

  const onUploadCallbackError = (attachmentId: string) => {
    const failedAttachment = [...attachments.inline, ...attachments.standard].find(
      attachment => attachment.id === attachmentId
    )
    onAttachmentUploadError?.(failedAttachment)
    setAttachments(prev => {
      return {
        ...prev,
        standard: prev.standard.filter(attachment => attachment.id !== attachmentId),
        inline: prev.inline.filter(attachment => attachment.id !== attachmentId),
      }
    })
  }

  const { attachmentUploadedCallbackUseCase } = useUpdateAttachmentUploadStatusUseCase({
    onError: onUploadCallbackError,
  })

  const attachmentsSize = React.useMemo(() => {
    return [...attachments.inline, ...attachments.standard].reduce((sum, current) => sum + current.size, 0)
  }, [attachments])

  const onAttachmentCreated = React.useCallback(
    (id: string, file: File) => {
      if (!currentUser) return
      const newAttachment = createStandardAttachmentFromFile({ file, attachmentId: id, owner: currentUser })
      setAttachments(prev => {
        const newValue = [...prev.standard, newAttachment]
        attachmentCallback && attachmentCallback(newValue)
        return { inline: prev.inline, standard: newValue }
      })
      setUploadStatuses(prev => [...prev, { id: newAttachment.id, uploaded: false, error: false }])
    },
    [attachmentCallback, currentUser]
  )

  const onInlineAttachmentCreated = React.useCallback(
    async (preUploaded: PreUploadResponse, file: File) => {
      if (!currentUser || !preUploaded.contentId) return

      const newAttachment = await createInlineAttachmentFromFile({
        file,
        attachmentId: preUploaded.id,
        contentId: preUploaded.contentId,
        owner: currentUser,
      })

      setAttachments(prev => {
        const newValue = [...prev.inline, newAttachment]
        inlineAttachmentCallback && inlineAttachmentCallback(newValue)
        return { standard: prev.standard, inline: newValue }
      })
      setUploadStatuses(prev => [...prev, { id: newAttachment.id, uploaded: false, error: false }])
    },
    [inlineAttachmentCallback, currentUser]
  )

  const onFileUploaded = React.useCallback((uploadState: IAttachmentUploadState) => {
    attachmentUploadedCallbackUseCase(uploadState.id)
    setUploadStatuses(prev => {
      const stat = prev.find(x => x.id === uploadState.id)
      if (stat) Object.assign(stat, uploadState)
      return prev
    })
  }, [])

  const addUploadsInProgress = React.useCallback(
    (uploads: number) => {
      setUploadsInProgress(prev => prev + uploads)
    },
    [setUploadsInProgress]
  )

  const clearAttachments = React.useCallback(() => {
    setAttachments({ standard: [], inline: [] })
    setUploadStatuses([])
    setUploadsInProgress(0)
    attachmentCallback && attachmentCallback([])
    inlineAttachmentCallback && inlineAttachmentCallback([])
  }, [attachmentCallback, inlineAttachmentCallback])

  const deleteAttachment = React.useCallback(
    (attachmentId: string) => {
      setAttachments(prev => {
        const newValue = prev.standard.filter(item => item.id !== attachmentId)
        attachmentCallback && attachmentCallback(newValue)
        return { inline: prev.inline, standard: newValue }
      })
      setUploadsInProgress(prev => (prev > 0 ? prev - 1 : prev))
    },
    [attachmentCallback]
  )

  const addAttachment = React.useCallback(
    (attachmentList: IAttachment[]) => {
      setAttachments(prev => {
        const newValue = [...prev.standard, ...attachmentList]
        attachmentCallback && attachmentCallback(newValue)
        return { inline: prev.inline, standard: newValue }
      })
    },
    [attachmentCallback]
  )

  const addUploadStatuses = React.useCallback(
    (uploadStatuses: IAttachmentUploadState[]) => {
      setUploadStatuses(prev => [...prev, ...uploadStatuses])
    },
    [setUploadStatuses]
  )

  const onFilePaste = React.useCallback(
    (event: React.ClipboardEvent<HTMLElement>, onSuccess?: (imageUrl: string, customData?: string) => void) => {
      if (!event.clipboardData?.files) return

      const files = [...event.clipboardData.files].filter(f => f.name && f.lastModified)

      uploadInlineAttachments(files, onSuccess)
    },
    []
  )

  const uploadInlineAttachments = React.useCallback(
    async (files: File[], onSuccess?: (imageUrl: string, customData?: string) => void) => {
      const preparedUploads = new Map<File, PreUploadResponse>()
      const attachmentsArray: PreUploadResponse[] = []

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const prepareUploadResponse = await prepareInlineAttachmentUploadRequest(file)
        preparedUploads.set(file, prepareUploadResponse)
        onInlineAttachmentCreated(prepareUploadResponse, file)
        attachmentsArray.push(prepareUploadResponse)

        const fileContentSrcAsBase64 = await getAttachmentContent(file)
        onSuccess?.(fileContentSrcAsBase64, prepareUploadResponse.contentId)
      }

      let file
      let preparedUpload

      for (let i = 0; i < files.length; i++) {
        try {
          setUploadsInProgress(prev => prev + 1)
          file = files[i]
          preparedUpload = preparedUploads.get(file)
          if (preparedUpload) {
            await uploadInlineAttachmentToS3({ file }, preparedUpload)
            onFileUploaded({ id: preparedUpload.id, uploaded: true, error: false })
          }
        } catch (error) {
          handleError(error)
          onFileUploaded({ id: preparedUpload?.id || '', uploaded: false, error: true })
        } finally {
          setUploadsInProgress(prev => prev - 1)
        }
      }
      return attachmentsArray
    },
    []
  )

  const uploadAttachments = React.useCallback(
    async (files: File[], onAttachmentUploadedCallback?: () => void): Promise<void> => {
      const preparedUploads = new Map<File, PreUploadResponse>()

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const prepareUploadResponse = await prepareUploadRequest(file)
        preparedUploads.set(file, prepareUploadResponse)
        onAttachmentCreated(prepareUploadResponse.id, file)
      }

      let file
      let preparedUpload
      for (let i = 0; i < files.length; i++) {
        try {
          setUploadsInProgress(prev => prev + 1)
          file = files[i]
          preparedUpload = preparedUploads.get(file)
          if (preparedUpload) {
            await uploadFileToS3({ file }, preparedUpload)
            onFileUploaded({ id: preparedUpload.id, uploaded: true, error: false })
            onAttachmentUploadedCallback?.()
          }
        } catch (error) {
          handleError(error)
          onFileUploaded({ id: preparedUpload?.id || '', uploaded: false, error: true })
        } finally {
          setUploadsInProgress(prev => prev - 1)
        }
      }
    },
    [onAttachmentCreated, prepareUploadRequest, uploadFileToS3, onFileUploaded]
  )

  React.useEffect(() => {
    setHasActiveUpload(uploadsInProgress > 0)
  }, [uploadsInProgress])

  return {
    deleteAttachment,
    addAttachment,
    addUploadStatuses,
    setAttachments,
    attachments,
    uploadStatuses,
    uploadAttachments,
    clearAttachments,
    uploadsInProgress,
    addUploadsInProgress,
    onFilePaste,
    uploadInlineAttachments,
    attachmentsSize,
  }
}
