import * as React from 'react'
import { isString } from 'lodash'
import { DateTime, DateTimeFormatOptions } from 'luxon'
import { formatDateWithOrdinal } from 'dataProcessors/dateTime'

const DEFAULT_FORMAT = DateTime.DATETIME_SHORT

interface IDateTimeFormatterProps {
  className?: string
  format?: DateTimeFormatOptions | string
  prefix?: string
  value: DateTime | string
  useOrdinal?: boolean
}

const DateTimeFormatter = ({ className, format = {}, prefix, value, useOrdinal }: IDateTimeFormatterProps) => {
  if (isString(value)) {
    value = DateTime.fromISO(value as string)
  }

  const valueFormatted = isString(format)
    ? formatDateWithOrdinal(value, format)
    : value.toLocaleString(format || DEFAULT_FORMAT)

  return <span className={className}>{`${prefix ?? ''} ${valueFormatted}`}</span>
}

export default DateTimeFormatter
