import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import { useProjectsList } from 'hooks/useProjects/useProjectsList'
import { InlineCheckbox } from 'shared/inputs/inlineCheckbox'
import { useProjectsFilters } from 'hooks/useProjects/useProjectsFilters'
import NewProjectButton from 'features/project/newProjectButton'
import { ProjectsTree } from 'component/projectsTree/projectsTree'

const useProjectsHomePageContentStyles = makeStyles((theme: Theme) => ({
  projectsHomePageContentRoot: {
    backgroundColor: theme.threadPalette.whiteSmoke,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 16,
    maxWidth: '100%',
    minWidth: 0,
    overflowY: 'scroll',
    paddingBlock: 20,
    paddingInline: 12,
  },
  projectsActionsContainer: {
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'space-between',
    paddingInline: 18,
  },
  projectsFiltersContainer: {
    color: theme.threadPalette.lightGray,
    fontSize: 14,
    fontWeight: 500,
  },
}))

export const ProjectsHomePageContent = () => {
  const classes = useProjectsHomePageContentStyles()

  const {
    projectsFilters,
    isLoading: isLoadingProjectsFilters,
    toggleActiveFilter: toggleActiveProjectsFilter,
    toggleArchivedFilter: toggleArchivedProjectsFilter,
  } = useProjectsFilters()

  const { projectsList } = useProjectsList({
    pageSize: 50,
    onlyActive: projectsFilters.onlyActive,
    onlyArchived: projectsFilters.onlyArchived,
    isFetchingEnabled: !isLoadingProjectsFilters,
    parentProject: null,
  })

  return (
    <div className={classes.projectsHomePageContentRoot}>
      <div className={classes.projectsActionsContainer}>
        <div className={classes.projectsFiltersContainer}>
          Projects that are
          <InlineCheckbox
            label="Active"
            isChecked={projectsFilters.onlyActive}
            onClick={toggleActiveProjectsFilter}
          />{' '}
          and
          <InlineCheckbox
            label="Archived"
            isChecked={projectsFilters.onlyArchived}
            onClick={toggleArchivedProjectsFilter}
          />
        </div>
        <NewProjectButton />
      </div>
      <ProjectsTree projects={projectsList} />
    </div>
  )
}
