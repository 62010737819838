import React from 'react'
import { IProject } from 'model/project'
import { Theme, makeStyles, fade } from '@material-ui/core'
import { ProjectNode } from './projectNode'
import { UpdateParentProjectDialog } from './updateParentProjectDialog'

const useProjectsTreeStyles = makeStyles((theme: Theme) => ({
  projectsTreeRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  projectRootNode: {
    backgroundColor: theme.threadPalette.white,
    border: `1px solid ${theme.threadPalette.lightishGray}`,
    borderRadius: 12,
    color: theme.threadPalette.menuItem.current,
    '&:hover': {
      boxShadow: `0 1px 3px 0 ${fade(theme.threadPalette.darkGray, 0.5)}`,
    },
    paddingBlock: 4,
    paddingInline: 16,
  },
}))

interface IProjectsListProps {
  projects: IProject[]
}

export const ProjectsTree = ({ projects }: IProjectsListProps) => {
  const classes = useProjectsTreeStyles()

  return (
    <div className={classes.projectsTreeRoot}>
      {projects.map(project => (
        <div key={project.id} className={classes.projectRootNode}>
          <ProjectNode project={project} allowEditingEmoji />
        </div>
      ))}
      <UpdateParentProjectDialog projects={projects} />
    </div>
  )
}
