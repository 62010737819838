import { isEmail } from 'dataProcessors/utils'
import React from 'react'

interface IUsePeopleEmailCheckProps {
  people: TPerson[]
  errorMessageOnEmptyList?: string
  errorMessageOnInvalidEmail?: string
}

export const usePeopleEmailCheck = ({
  people,
  errorMessageOnEmptyList = 'There are no new people added. Please add at least one new person and try again',
  errorMessageOnInvalidEmail = 'Email address was not recognized. Please make sure all addresses are properly formed',
}: IUsePeopleEmailCheckProps) => {
  const errorMessage = React.useMemo(() => {
    const peopleEmail = people.map(peron => peron.email)

    if (peopleEmail.length === 0) return errorMessageOnEmptyList

    const isExistsInvalidEmail = peopleEmail.some(personEmail => !isEmail(personEmail))

    if (isExistsInvalidEmail) return errorMessageOnInvalidEmail

    return ''
  }, [people])

  return {
    errorMessage,
  }
}
