import { makeStyles, Theme } from '@material-ui/core'
import { push } from 'actions/navigation'
import { SignInButtons } from 'component/signInButtons'
import BigCloseIcon from 'icons/bigCloseIcon'
import * as React from 'react'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop: '50px',
  },
  content: {
    display: 'block',
  },
  signInButton: {
    minWidth: '500px',
  },
  text: {
    textAlign: 'center',
    marginBottom: '80px',
    fontSize: 30,
  },
  closeButtonContainer: {
    display: 'flex',
  },
  closeButton: {
    marginLeft: 'auto',
    marginRight: '20px',
    marginTop: '20px',
    cursor: 'pointer',
    '&:hover': {
      fill: theme.threadPalette.menuItem.normal,
    },
  },
}))

export const ConnectAccountPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onClose = React.useCallback(() => {
    dispatch(push('/'))
  }, [dispatch, push])

  return (
    <div>
      <div className={classes.closeButtonContainer} onClick={onClose}>
        <BigCloseIcon className={classes.closeButton} />
      </div>
      <div className={classes.root}>
        <div className={classes.text}>Connect your email account to access this page</div>
        <div className={classes.content}>
          <SignInButtons />
        </div>
      </div>
    </div>
  )
}
