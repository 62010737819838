import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'
import UserDisplayCard from 'features/user/card/userDisplayCard'

const usePeopleListBlockStyles = makeStyles((theme: Theme) => {
  return {
    blockHeader: {
      backgroundColor: theme.threadPalette.lighterGray,
      borderBottomWidth: 1,
      borderColor: theme.threadPalette.lightishGray,
      borderStyle: 'solid',
      borderWidth: 0,
      color: theme.threadPalette.brownishGray,
      fontSize: 12,
      fontWeight: 500,
      paddingBlock: 8,
      paddingInline: 16,
    },
    userDisplayCardContainer: {
      paddingBottom: 7,
      paddingInline: 24,
      paddingTop: 6,
    },
  }
})

interface IPeopleListBlockProps {
  people: TPerson[]
  headerTitle: string
  headerClassName?: string
  addDivider?: boolean
}

export const PeopleListBlock = ({
  people,
  headerTitle,
  headerClassName,
  addDivider = false,
}: IPeopleListBlockProps) => {
  const classes = usePeopleListBlockStyles()
  return (
    <>
      <div className={classNames(classes.blockHeader, headerClassName)}>{headerTitle}</div>
      {people.map(person => (
        <UserDisplayCard
          key={`${person.email}_${headerTitle.toLowerCase()}`}
          user={person}
          addDivider={addDivider}
          className={classes.userDisplayCardContainer}
        />
      ))}
    </>
  )
}
