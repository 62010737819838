import React, { useContext } from 'react'

interface IDraftMessageContext {
  setToRecipientsInputRef: React.Dispatch<React.SetStateAction<HTMLInputElement | null>>
  focusToRecipients(): void
  setBccRecipientsInputRef: React.Dispatch<React.SetStateAction<HTMLInputElement | null>>
  focusBccRecipients(): void
}

export const DraftMessageContext = React.createContext<IDraftMessageContext | undefined>(undefined)

export const DraftMessageContextProvider: React.FC<{}> = ({ children }) => {
  const [toRecipientsInputRef, setToRecipientsInputRef] = React.useState<HTMLInputElement | null>(null)
  const [bccRecipientsInputRef, setBccRecipientsInputRef] = React.useState<HTMLInputElement | null>(null)

  const focusToRecipients = () => {
    toRecipientsInputRef?.focus()
  }

  const focusBccRecipients = () => {
    bccRecipientsInputRef?.focus()
  }

  return (
    <DraftMessageContext.Provider
      value={{
        setToRecipientsInputRef,
        setBccRecipientsInputRef,
        focusToRecipients,
        focusBccRecipients,
      }}
    >
      {children}
    </DraftMessageContext.Provider>
  )
}

export const useDraftMessageContext = () => {
  const context = useContext(DraftMessageContext)
  if (context === undefined)
    throw new Error('useDraftMessageContext should be used within a DraftMessageContextProvider ')

  return context
}
