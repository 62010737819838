import React from 'react'
import PageLayout from '../../pageLayout'
import { ProjectDetailsPageHeader } from './projectDetailsPageHeader'
import { useUser } from 'hooks'
import AppLoadingPage from 'page/appLoading'
import { ProjectDetailsPageContent } from './projectDetailsPageContent'
import { useProject } from 'hooks/useProjects/useProject'

interface ProjectDetailsPageProps {
  projectId: string
}

export const ProjectDetailsPage = ({ projectId }: ProjectDetailsPageProps) => {
  const currentUser = useUser()
  const { project, isLoading: isLoadingProject } = useProject({ projectId })

  if (!currentUser) return <AppLoadingPage />

  return (
    <PageLayout
      leftSidebarOption="projects"
      headerContent={<ProjectDetailsPageHeader project={project} currentUser={currentUser} />}
    >
      <ProjectDetailsPageContent key={project?.id} project={project} isLoadingProject={isLoadingProject} />
    </PageLayout>
  )
}
