import { isLoggedIn } from '@jetkit/react'
import { useUser } from 'hooks'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { silenceBrowserSecurityWarning } from 'reducer/userSettings'
import { selectIsBrowserSecurityWarningSilenced } from 'selectors/userSettings'
import { TBrowserName, useBrowserData } from 'hooks/useBrowserData'

type IXssSafeBrowsers = {
  [key in TBrowserName]: number
}

// List of XSS safe browsers and their minimum supported versions
const xssSafeBrowsers: IXssSafeBrowsers = {
  // Introduced stricter cookie policies, support for Content Security Policy 3 (CSP3), and improved CORS handling, which can help mitigate the risk of XSS attacks.
  Chrome: 88,
  // Introduced improvements to its CSP support and the addition of the referrerPolicy attribute, which can help prevent certain types of XSS attacks.
  Firefox: 83,
  // Introduced stricter cookie policies and support for the SameSite attribute for cookies, which can help prevent CSRF (Cross-Site Request Forgery) and XSS attacks.
  Safari: 14,
  // Introduced support for the Fetch Metadata Request Headers and the same-origin and same-site attributes in the Referrer-Policy header, which can help prevent certain types of XSS attacks
  Edge: 88,
  // Introduced support for Fetch Metadata Request Headers and improvements to its CSP support, which can help prevent XSS attacks
  Opera: 79,
  // Introduced improved support for Content Security Policy, including support for the unsafe-hashed-attributes directive, which can help mitigate the risk of XSS attacks.
  Brave: 1.24,
  // Introduced support for Content Security Policy and improved handling of certain types of cross-origin requests, which can help prevent XSS attacks.
  Vivaldi: 3.6,
}

export interface IBrowserSecurityWarningContext {
  showBrowserSecurityWarning: boolean
  silenceSecurityWarning: () => void
  browserUpdateRequired: boolean
  secureBrowsersSuggestion?: TBrowserName[]
}

export const BrowserSecurityWarningContext = React.createContext<IBrowserSecurityWarningContext | undefined>(undefined)

export const BrowserSecurityWarningContextProvider: React.FC<{}> = ({ children }) => {
  const dispatch = useDispatch()

  const loggedIn = isLoggedIn()
  const currentUser = useUser()
  const isSecurityWarningSilenced = useSelector(selectIsBrowserSecurityWarningSilenced)
  const { browserName, browserVersion, isAppleDevice, isWindows } = useBrowserData()

  const isBrowserSecure = React.useMemo(() => {
    if (!browserName) return false
    return xssSafeBrowsers?.[browserName] && browserVersion >= xssSafeBrowsers?.[browserName]
  }, [xssSafeBrowsers, browserName, browserVersion])

  const showBrowserSecurityWarning = React.useMemo(() => {
    if (isBrowserSecure || isSecurityWarningSilenced) return false
    return loggedIn && !!currentUser?.activatedDateTime
  }, [isBrowserSecure, isSecurityWarningSilenced, loggedIn, currentUser?.activatedDateTime])

  const browserUpdateRequired = React.useMemo(() => {
    if (!browserName) return false

    const minimumBrowserVersion = xssSafeBrowsers?.[browserName]
    if (!minimumBrowserVersion) return false

    return minimumBrowserVersion >= browserVersion
  }, [xssSafeBrowsers, browserName, browserVersion])

  const secureBrowsersSuggestion = React.useMemo(() => {
    if (browserName && xssSafeBrowsers?.[browserName]) return

    const popularSecureBrowsers: TBrowserName[] = ['Chrome', 'Firefox']
    if (isAppleDevice) popularSecureBrowsers.push('Safari')
    if (isWindows) popularSecureBrowsers.push('Edge')

    return popularSecureBrowsers
  }, [browserName, isAppleDevice, isWindows])

  const silenceSecurityWarning = React.useCallback(() => {
    dispatch(silenceBrowserSecurityWarning())
  }, [dispatch])

  return (
    <BrowserSecurityWarningContext.Provider
      value={{
        showBrowserSecurityWarning,
        silenceSecurityWarning,
        browserUpdateRequired,
        secureBrowsersSuggestion,
      }}
    >
      {children}
    </BrowserSecurityWarningContext.Provider>
  )
}

export const useBrowserSecurityWarningContext = () => {
  const context = useContext(BrowserSecurityWarningContext)

  if (context === undefined)
    throw new Error('useBrowserSecurityWarningContext should be used within a BrowserSecurityWarningContextProvider ')

  return context
}
