import * as React from 'react'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { useMoveThreadUpUseCase } from './moveThreadUp'
import { useUpdateThreadUseCase } from './updateThread'
import { useProjectActions } from 'hooks/useProjects/useProjectActions'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'
import { useThreadFollowersActions } from 'hooks/useThreadFollowers/useThreadFollowersActions'
import { useThreadMessagesCacheActions } from 'hooks/useThreadMessages/useThreadMessagesCacheActions'
import { useThreadSharingSettingsCacheActions } from 'hooks/useSharingGroup/useThreadSharingSettingsCacheActions'
import { useReadThreadUseCase } from './readThread'

interface INewThreadMessageUseCaseProps {
  threadId: string
}

export const useNewThreadMessageUseCase = () => {
  const { currentThread } = useCurrentThreadContext()
  const { refreshThreadsList } = useThreadActions()
  const { moveThreadUpUseCase } = useMoveThreadUpUseCase()
  const { updateThreadUseCase } = useUpdateThreadUseCase()
  const { refreshThreadFollowers } = useThreadFollowersActions()
  const { refreshThreadProjectsList } = useProjectActions()
  const { readThreadUseCase } = useReadThreadUseCase()
  const threadMessagesCacheActions = useThreadMessagesCacheActions()
  const threadSharingSettingsCacheActions = useThreadSharingSettingsCacheActions()

  const newThreadMessageUseCase = React.useCallback(
    async ({ threadId }: INewThreadMessageUseCaseProps) => {
      const isMessageInCurrentThread = currentThread?.id === threadId
      if (!isMessageInCurrentThread) {
        updateThreadUseCase({
          threadId: threadId,
          unreadCountModifier: (unreadCount: number) => {
            return unreadCount + 1
          },
        })
      }
      moveThreadUpUseCase(threadId)
      refreshThreadsList()
      threadSharingSettingsCacheActions.refreshData(threadId)

      refreshThreadProjectsList(threadId)

      if (isMessageInCurrentThread) {
        readThreadUseCase(threadId)
        refreshThreadFollowers(threadId)
        threadMessagesCacheActions.refreshData(threadId)
      }
    },
    [currentThread, refreshThreadsList, readThreadUseCase]
  )

  return {
    newThreadMessageUseCase,
  }
}
