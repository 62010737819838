import React from 'react'
import { makeStyles, Theme, createStyles, TextField } from '@material-ui/core'

const useEmailNotificationInputStyles = makeStyles((theme: Theme) =>
  createStyles({
    emailNotificationInputContainer: {
      marginTop: 20,
    },
    notificationInputRoot: {
      lineHeight: 1.4,
      padding: 10,
    },
    inputMultiline: {
      maxHeight: '30vh',
    },
  })
)
export const EmailNotificationInput = ({ value, onChange }: { value: string; onChange(newValue: string): void }) => {
  const classes = useEmailNotificationInputStyles()

  return (
    <TextField
      name="email-notification-input"
      autoFocus
      classes={{
        root: classes.emailNotificationInputContainer,
      }}
      InputProps={{
        classes: {
          multiline: classes.notificationInputRoot,
          inputMultiline: classes.inputMultiline,
        },
      }}
      multiline
      fullWidth
      rows={5}
      rowsMax={10}
      placeholder="Add a custom message to explain why you are sharing this thread and what you hope to achieve."
      variant="outlined"
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value ?? '')}
      value={value}
    />
  )
}
