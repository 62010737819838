import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { ISharingGroup } from 'model/sharingGroup'
import { SharingGroupScreenHeader } from 'component/sharingGroup/common/sharingGroupScreenHeader'
import { EditableThreadSharingSettings } from './editableThreadSharingSettings'
import { SharingFutureMessagesInfoScreen } from '../../sharingFutureMessagesInfoScreen/sharingFutureMessagesInfoScreen'
import { getThreadDisplayName } from 'domain/thread'

const useEditThreadSharingSettingsScreenStyles = makeStyles((theme: Theme) => ({
  editThreadSharingSettingsRoot: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
    minWidth: 470,
    paddingBottom: 16,
    paddingInline: 20,
    paddingTop: 0,
  },
}))

export const EditThreadSharingSettingsScreen = ({
  isCreatingThreadSharingSettings,
  thread,
  sharingGroup,
  onEditSharingGroup,
  onSubmit,
  isSubmitting,
  onStopSharing,
  setIsSelectingMessages,
  onReturn,
}: {
  isCreatingThreadSharingSettings: boolean
  thread: IThread
  sharingGroup: ISharingGroup
  onEditSharingGroup?(): void
  onSubmit?(): void
  isSubmitting?: boolean
  onStopSharing?(): void
  setIsSelectingMessages?(isSelectingMessages: boolean): void
  onReturn?(): void
}) => {
  const classes = useEditThreadSharingSettingsScreenStyles()

  const [isSharingFutureMessagesInfoOpen, setIsSharingFutureMessagesInfoOpen] = React.useState(false)

  const headerStaticTitle = isCreatingThreadSharingSettings ? 'Share' : 'Edit Share'
  const headerTitle = `${headerStaticTitle} "${getThreadDisplayName(thread)}"`

  const openSharingFutureMessagesInfo = () => setIsSharingFutureMessagesInfoOpen(true)
  const closeSharingFutureMessagesInfo = () => setIsSharingFutureMessagesInfoOpen(false)

  if (isSharingFutureMessagesInfoOpen) {
    return <SharingFutureMessagesInfoScreen onReturnToPreviousScreenClick={closeSharingFutureMessagesInfo} />
  }

  return (
    <div className={classes.editThreadSharingSettingsRoot}>
      <SharingGroupScreenHeader text={headerTitle} onReturnToPreviousScreenClick={onReturn} />
      <div>
        <EditableThreadSharingSettings
          isCreatingThreadSharingSettings={isCreatingThreadSharingSettings}
          sharingGroup={sharingGroup}
          onEditSharingGroup={onEditSharingGroup}
          onClickEditSelectedMessages={() => setIsSelectingMessages?.(true)}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          onStopSharing={onStopSharing}
          openSharingFutureMessagesInfo={openSharingFutureMessagesInfo}
        />
      </div>
    </div>
  )
}
