import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'

const useEditSharingGroupScreenStyles = makeStyles((theme: Theme) => ({
  existingSharingGroupWithSameNameErrorRoot: {
    color: theme.threadPalette.vermilion,
    fontSize: 13,
  },
}))

interface IExistingSharingGroupWithSameNameErrorProps {
  sharingGroupName: string
}

export const ExistingSharingGroupWithSameNameError = ({
  sharingGroupName,
}: IExistingSharingGroupWithSameNameErrorProps) => {
  const classes = useEditSharingGroupScreenStyles()
  return (
    <div className={classes.existingSharingGroupWithSameNameErrorRoot}>
      You already have a sharing group named <strong>{sharingGroupName}</strong>
    </div>
  )
}
