import { useQuery } from '@tanstack/react-query'
import { getProject } from 'api/v2/project'
import { getProjectByIdQueryKey } from './queryKeys'
import { getTimeInMilliSeconds } from 'dataProcessors/utils'
import { useUser } from 'hooks/auth'

interface IUseProjectProps {
  projectId?: string
  onError?: (error: Error) => void
}

export function useProject({ projectId, onError }: IUseProjectProps) {
  const currentUser = useUser()

  const { data: project, isLoading } = useQuery({
    enabled: !!projectId && !!currentUser?.id,
    queryKey: getProjectByIdQueryKey({ userId: currentUser?.id ?? '', projectId: projectId ?? '' }),
    queryFn: () => getProject(projectId ?? ''),
    onError: onError,
    retry: 1,
    staleTime: getTimeInMilliSeconds({ minutes: 1 }),
  })

  return {
    project,
    isLoading,
  }
}
