import React from 'react'
import { OnboardingStepTextLines } from '../onboardingStepTextLines'

export const ShareThreadStep = () => {
  const textLines = [
    <>
      Click <strong>Share</strong> to invite others to view this thread.
    </>,
  ]

  return <OnboardingStepTextLines textLines={textLines} maxWidth={170} />
}
