import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { deleteProject } from 'api/v2/project'
import { useProjectActions } from 'hooks/useProjects/useProjectActions'
import { useDispatch } from 'react-redux'

export const useDeleteProjectUseCase = ({ onSuccess }: { onSuccess?(): void } = {}) => {
  const dispatch = useDispatch()
  const { refreshProjectsList, refreshProject } = useProjectActions()

  const { mutateAsync: runDeleteProjectMutation } = useMutation(deleteProject, {
    onError: () => {
      dispatch(showSnackbar({ message: 'Error trying to delete project' }))
    },
    onSettled: (_, __, variables) => {
      const projectId = variables
      refreshProjectsList()
      refreshProject(projectId)
      onSuccess?.()
    },
  })
  const deleteProjectUseCase: typeof deleteProject = runDeleteProjectMutation

  return { deleteProjectUseCase }
}
