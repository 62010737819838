import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import SubmitButtonWithLoading from 'component/submitButtonWithLoading'
import { useDispatch } from 'react-redux'
import { push } from 'actions/navigation'

const useBoolSupportCallStyles = makeStyles((theme: Theme) => ({
  root: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  calenly: { width: '100%', height: 'calc(100vh - 60px)' },
  button: {
    margin: 'auto',
    fontSize: 20,
    fontWeight: 700,
    height: 50,
    lineHeight: 0.7,
    width: 252,
  },
  buttonWrapper: { height: '60px', display: 'flex' },
}))

const BookSupportCallPage = () => {
  const classes = useBoolSupportCallStyles()

  const dispatch = useDispatch()

  const onClick = React.useCallback(() => {
    dispatch(push('/'))
  }, [dispatch])

  return (
    <div className={classes.root}>
      <iframe
        title="mixmax-support-calendar"
        className={classes.calenly}
        src="https://cal.mixmax.com/holman/support"
      ></iframe>
      <div className={classes.buttonWrapper}>
        <SubmitButtonWithLoading className={classes.button} color="primary" onClick={onClick}>
          Home
        </SubmitButtonWithLoading>
      </div>
    </div>
  )
}

export default BookSupportCallPage
