import { AxiosInstance, AxiosResponse } from 'axios'
import * as Sentry from '@sentry/browser'

export const applyErrorLogsMiddleware = (apiClient: AxiosInstance, ignoreErrorCodes?: number[]) => {
  apiClient.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      return response
    },
    error => {
      if (!ignoreErrorCodes?.includes(error?.response?.status)) {
        const URL = error.config.url
        const method = error.config.method
        const logOptions = { extra: { response: error?.response, error: { error } } }
        Sentry.captureMessage(`API Error on ${method} ${URL}`, logOptions)
      }
      throw error
    }
  )
}
