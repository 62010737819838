import React from 'react'
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query'
import { loadThreadMessages } from 'api/threads'
import { PaginatedResponse } from 'api/pagedResponse'
import { useUser } from 'hooks/auth'
import { getNextPageNumber } from 'lib/api'
import { ServerError } from 'api/errors/serverError'

export const getThreadMessagesQueryKey = ({
  userId,
  threadId,
  recipientsFilter = [],
}: {
  userId: string
  threadId: string
  recipientsFilter?: string[]
}) => [userId, 'threads', threadId, 'messages', { recipientsFilter }]

export type TMessageListData = InfiniteData<PaginatedResponse<IMessage[]>>

interface IUserMessagesListProps {
  threadId: string
  recipientsFilter?: string[]
  isFetchingEnabled: boolean
}

export const useThreadMessagesList = ({
  threadId,
  recipientsFilter,
  isFetchingEnabled = true,
}: IUserMessagesListProps) => {
  const currentUser = useUser()
  const pageSize = 10

  const {
    data: messagePages,
    fetchNextPage,
    isInitialLoading,
    isFetchingNextPage,
    error,
    dataUpdatedAt,
    isRefetching,
    hasNextPage,
  } = useInfiniteQuery<PaginatedResponse<IMessage[]>, ServerError>({
    enabled: !!threadId && !!currentUser?.id && isFetchingEnabled,
    queryKey: getThreadMessagesQueryKey({ userId: currentUser?.id ?? '', threadId, recipientsFilter }),
    queryFn: ({ pageParam, signal }) =>
      loadThreadMessages({
        threadId,
        filteredRecipientIds: recipientsFilter,
        pageNumber: pageParam ?? 1,
        pageSize,
        signal,
      }),
    getNextPageParam: lastPage => getNextPageNumber(lastPage),
  })

  const fetchNextMessagePage = React.useCallback(() => {
    if (isFetchingNextPage || isInitialLoading) return
    fetchNextPage()
  }, [fetchNextPage, isFetchingNextPage, isInitialLoading])

  const messagesList: IMessage[] = React.useMemo(() => {
    return messagePages?.pages?.flatMap(page => page.data) ?? []
  }, [messagePages?.pages])

  return {
    messagesList,
    isLoading: isInitialLoading,
    isFetchingNextPage,
    fetchNextPage: fetchNextMessagePage,
    error,
    lastRefreshedAt: dataUpdatedAt,
    isRefetching,
    hasNextPage: !!hasNextPage,
  }
}
