import * as React from 'react'

export function useInputField(defaultValue?: string) {
  const [value, setValue] = React.useState(defaultValue ? defaultValue : '')

  const handleChange = React.useCallback((event: any) => {
    const value = event.target.value
    setValue(value)
  }, [])

  return { value, setValue, handleChange }
}
