import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, Typography } from '@material-ui/core'
import ReplyAllIcon from '@material-ui/icons/ReplyAll'
import ReplyIcon from '@material-ui/icons/Reply'
import classNames from 'classnames'

const useMessageActionStyles = makeStyles((theme: Theme) => ({
  actionIcons: {
    fontSize: 16,
    marginRight: 5,
  },
  forwardIcon: {
    transform: 'rotateY(180deg)',
  },
}))

interface IMessageActionsProps {
  className: string
  messageActionClassName: string
  onReply(): void
  onReplyAll(): void
  onForward(): void
  hideActions?: boolean
}

const MessageActions = ({
  className,
  messageActionClassName,
  onReply,
  onReplyAll,
  onForward,
  hideActions = false,
}: IMessageActionsProps) => {
  const classes = useMessageActionStyles()
  return (
    <div className={className}>
      {!hideActions && (
        <>
          <Typography className={messageActionClassName} onClick={onReplyAll}>
            <ReplyAllIcon fontSize="small" classes={{ root: classes.actionIcons }} />
            reply all
          </Typography>
          <Typography className={messageActionClassName} onClick={onReply}>
            <ReplyIcon fontSize="small" classes={{ root: classes.actionIcons }} />
            reply
          </Typography>
          <Typography className={messageActionClassName} onClick={onForward}>
            <ReplyIcon fontSize="small" classes={{ root: classNames(classes.actionIcons, classes.forwardIcon) }} />
            forward
          </Typography>
        </>
      )}
    </div>
  )
}

export default MessageActions
