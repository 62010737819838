import { makeStyles } from '@material-ui/core'

export const useIconStyles = makeStyles(() => {
  return {
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
    },

    scaleIcon: {
      transform: 'scale(1.2)',
      height: '100%',
    },
  }
})
