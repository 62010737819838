import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 10,
      width: '100%',
    },

    headerStyle: {
      color: theme.threadPalette.menuItem.current,
      display: 'flex',
      width: '100%',
    },

    userBadge: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      marginRight: 10,
    },

    tooltip: {
      backgroundColor: '#efefef',
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.4)',
      color: 'black',
      fontSize: 11,
      fontWeight: 500,
    },
    tooltipContent: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    titleWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 4,
    },
    iconWrapper: {
      cursor: 'pointer',
      display: 'inline-flex',
    },
    starIcon: {
      color: theme.threadPalette.lightishGray,
      fontSize: 16,
    },
    starred: {
      color: theme.threadPalette.star,
    },
  })
)
