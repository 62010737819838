import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles, createStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import * as React from 'react'
import DateTimeFormatter from 'component/formatters/dateTime'
import UserAvatar from 'component/userAvatar'
import { INotification } from 'model/notification'
import UnreadOrangeCircle from 'component/unreadCircle'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationCenterMessageContainer: {
      alignSelf: 'flex-start',
      maxWidth: 500,
      padding: '10px 18px',
      width: '100%',
      '&.clickable': {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.threadPalette.lighterGray,
        },
      },
    },
    notificationCenterMessage: {
      alignItems: 'center',
      display: 'flex',
    },
    userAvatar: {
      flexShrink: 0,
      marginRight: '9px',
    },
    messageContentWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    messageContent: {
      color: theme.threadPalette.menuItem.current,
      fontSize: 15,
      lineHeight: '20px',
      maxWidth: 300,
      textOverflow: 'ellipsis',
      wordWrap: 'break-word',
    },
    extraInfoContainer: {
      alignItems: 'center',
      alignSelf: 'center',
      display: 'flex',
      fontSize: 13,
      height: 'fit-content',
    },
    unreadTime: {
      color: theme.palette.error.main,
      fontWeight: 500,
    },
  })
)

interface INotificationItemProps {
  className?: string
  notification: INotification
  isUnread?: boolean
  onClick?(): void
  isClickable?: boolean
}

export const NotificationItem = ({
  notification,
  className,
  isUnread,
  onClick,
  isClickable = false,
}: INotificationItemProps) => {
  const classes = useStyles()

  return (
    <div
      className={classNames(classes.notificationCenterMessageContainer, className, {
        clickable: isClickable && onClick,
      })}
      onClick={onClick}
    >
      <div className={classes.notificationCenterMessage}>
        <UserAvatar className={classes.userAvatar} user={notification.author} />
        <div className={classes.messageContentWrapper}>
          <div
            className={classes.messageContent}
            dangerouslySetInnerHTML={{ __html: notification.message }} // this is controlled environment, not affected by user's input directly. Html is utilized to achieve specific text formatting.
          />
          <div className={classes.extraInfoContainer}>
            {isUnread && <UnreadOrangeCircle size={12} internalCircleSize={2} />}

            <DateTimeFormatter
              className={classNames({ [classes.unreadTime]: isUnread })}
              format={DateTime.TIME_SIMPLE}
              value={notification.date}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationItem
