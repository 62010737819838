import * as React from 'react'
import { Tabs, Tab } from '@material-ui/core'
import TabPanel from './tabPanel'
import classNames from 'classnames'
import { useTabs } from './useTabs'
import { useTabStyles } from './tabs.style'
import { userHasAccess } from 'lib/accessCheck'
import { useUser } from 'hooks'

export interface ITabElement {
  label: React.ReactNode | string
  page: React.ReactNode
  requiredPermissions?: TUserPermissions[]
}
interface ITabWrapperProps {
  tabs: ITabElement[]
  className?: string
  tabClass?: string
  isCompact?: boolean
  preSelectedTab?: number
}

const TabWrapper = ({ tabs, className, tabClass, isCompact, preSelectedTab }: ITabWrapperProps) => {
  const classes = useTabStyles()
  const user = useUser()

  const { selectedTab, handleSelectTab } = useTabs(preSelectedTab)
  const tabPaperClass = isCompact ? classes.tabPaperCompact : classes.tabPaper
  const tabsUserCanAccess = tabs.filter(tabElement => {
    const requiredPermissions = tabElement.requiredPermissions ?? []
    return userHasAccess(user, requiredPermissions)
  })

  return (
    <div className={classNames(classes.root, className)}>
      <div className={tabPaperClass}>
        <Tabs
          value={selectedTab}
          onChange={handleSelectTab}
          classes={{
            indicator: classes.indicatorColor,
            root: classes.tabsRoot,
          }}
          variant={isCompact ? 'standard' : 'fullWidth'}
          centered={!isCompact}
        >
          {tabsUserCanAccess.map(
            ({ label }, index) =>
              label && (
                <Tab
                  key={index}
                  label={<div className={classNames(classes.tabContent, tabClass)}>{label}</div>}
                  className={classes.tabItem}
                  classes={{ selected: classes.tabItemSelected, textColorInherit: classes.tabsColorsInherit }}
                />
              )
          )}
        </Tabs>
      </div>

      {tabsUserCanAccess.map(({ page }, index) => (
        <TabPanel value={selectedTab} index={index} key={index}>
          {page}
        </TabPanel>
      ))}
    </div>
  )
}

export default TabWrapper
