import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { updateThreadTitleAlias } from 'api/v2/thread'
import { useProjectActions } from 'hooks/useProjects/useProjectActions'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'
import { useDispatch } from 'react-redux'
import { useUpdateThreadUseCase } from './updateThread'

export const useUpdateThreadAliasUseCase = () => {
  const dispatch = useDispatch()

  const { updateThreadUseCase } = useUpdateThreadUseCase()
  const { refreshThreadProjectsList } = useProjectActions()
  const { refreshThread, refreshThreadsList } = useThreadActions()

  const { mutateAsync: runUpdateThreadAliasMutation, reset: resetUpdateThreadAliasMutation } = useMutation(
    updateThreadTitleAlias,
    {
      onMutate: ({ threadId, titleAlias }) => {
        const { rollbackOptimisticUpdates } = updateThreadUseCase({ threadId, titleAlias })
        return rollbackOptimisticUpdates
      },
      onError: (_, __, rollbackOptimisticUpdates) => {
        rollbackOptimisticUpdates?.()
        dispatch(showSnackbar({ message: 'Error trying to update thread alias' }))
        resetUpdateThreadAliasMutation()
      },
      onSettled: (_, __, variables) => {
        const { threadId } = variables
        refreshThread(threadId)
        refreshThreadsList()
        refreshThreadProjectsList(threadId)
      },
    }
  )

  const updateThreadAliasUseCase: typeof updateThreadTitleAlias = runUpdateThreadAliasMutation

  return { updateThreadAliasUseCase }
}
