import React from 'react'
import { useBoolean } from 'hooks/useBoolean'
import { ChevronRight as ExpandNestedIcon, ExpandMore as CollapseNestedIcon } from '@material-ui/icons/'
import { makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'

const useCollapsableIconStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.threadPalette.lightGray,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  disabled: {
    color: theme.threadPalette.onFocusGray,
    cursor: 'unset',
    opacity: 0.5,
    '&:hover': {
      color: theme.threadPalette.onFocusGray,
    },
  },
}))

type UseCollapsableIconProps = {
  className?: string
  isInitialExpanded?: boolean
  isDisabled?: boolean
}

export const useCollapsableIcon = ({
  className,
  isInitialExpanded = false,
  isDisabled = false,
}: UseCollapsableIconProps) => {
  const classes = useCollapsableIconStyles()
  const { state: isExpanded, toggle: toggleIsCollapsed, setTrue: uncollapse } = useBoolean(isInitialExpanded)

  const onClickIcon = () => {
    if (isDisabled) return
    toggleIsCollapsed()
  }
  const ExpandIcon = isExpanded ? CollapseNestedIcon : ExpandNestedIcon
  const expandIconComponent = (
    <ExpandIcon
      className={classNames(classes.icon, className, { [classes.disabled]: isDisabled })}
      onClick={onClickIcon}
    />
  )

  return {
    isExpanded,
    toggleIsCollapsed,
    uncollapse,
    icon: ExpandIcon,
    component: expandIconComponent,
  }
}
