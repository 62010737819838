import React from 'react'
import { getThreadDisplayName } from 'domain/thread'
import { Theme, Typography, makeStyles } from '@material-ui/core'
import LogoOnlyIcon from 'icons/logoOnly'

const useThreadTitleWithLogoStyles = makeStyles<Theme, { fontSize: number }>((theme: Theme) => ({
  threadTitleWithLogoRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  threadDisplayName: {
    fontSize: ({ fontSize }) => fontSize,
    fontWeight: 500,
  },
}))

interface IThreadTitleWithLogoProps {
  thread: Pick<IThread, 'title' | 'titleAlias'>
  fontSize: number
  logoSize?: number
}

export const ThreadTitleWithLogo = ({ thread, fontSize, logoSize }: IThreadTitleWithLogoProps) => {
  const classes = useThreadTitleWithLogoStyles({ fontSize })

  const { title, titleAlias } = thread
  const logoDimensions = logoSize ?? fontSize

  const threadDisplayName = getThreadDisplayName({ title, titleAlias })

  return (
    <div className={classes.threadTitleWithLogoRoot}>
      <LogoOnlyIcon width={logoDimensions} height={logoDimensions} />
      <Typography className={classes.threadDisplayName}>{threadDisplayName}</Typography>
    </div>
  )
}
