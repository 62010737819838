import React from 'react'
import { Theme, Typography, makeStyles } from '@material-ui/core'
import { OnboardingStepTextLines } from '../onboardingStepTextLines'
import { BrowserToolbarIcon } from 'icons/browserToolbar'

const useThreadContentStepStyles = makeStyles((theme: Theme) => ({
  threadContentStepRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: 500,
  },
  headerText: {
    fontSize: 28,
    textAlign: 'center',
  },
}))

interface IThreadPageContentStepProps {
  textLines: JSX.Element[]
  isFullScreen: boolean
}

export const ThreadPageContentStep = ({ textLines, isFullScreen }: IThreadPageContentStepProps) => {
  const classes = useThreadContentStepStyles()

  const textLinesContainer = <OnboardingStepTextLines textLines={textLines} maxWidth={isFullScreen ? 450 : 180} />

  if (!isFullScreen) return textLinesContainer

  return (
    <div className={classes.threadContentStepRoot}>
      <Typography className={classes.headerText}>
        This page is your <strong>thread</strong>
      </Typography>
      <BrowserToolbarIcon />
      {textLinesContainer}
    </div>
  )
}
