import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    threadItem: {
      alignItems: 'center',
      justifyContent: 'space-between',
      display: 'flex',
      marginRight: '10px',
      padding: '6px 10px',
      position: 'relative',
      cursor: 'pointer',
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
      '&:hover': {
        backgroundColor: theme.threadPalette.onFocusGray,
      },
      '&.active': {
        backgroundColor: '#95b1c9',
      },
    },
    flexContainer: {
      display: 'flex',
      width: 'calc(100% - 30px)',
    },
    backdrop: {
      borderBottomRightRadius: '9px',
      borderTopRightRadius: '9px',
      bottom: '0px',
      left: '0px',
      pointerEvents: 'none',
      position: 'absolute',
      right: '0px',
      top: '0px',
      zIndex: -1,
    },
    backdropHover: {
      background: theme.threadPalette.darkBlue,
    },
    backdropActive: {
      background: theme.threadPalette.lightBlue,
    },
    threadInfoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginLeft: '10px',
      width: 'calc(100% - 50px)',
      height: '36px',
    },
    threadInfoItem: {
      margin: '0px',
      overflow: 'hidden',
      pointerEvents: 'none',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    threadName: {
      color: theme.threadPalette.white,
      fontSize: '14px',
      fontFamily: 'Roboto',
      fontWeight: 500,
      '&:hover': {
        color: theme.threadPalette.lighterGray,
      },
      '&.active': {
        color: '#33373a',
      },
    },
    nameHover: {},
    nameActive: {
      color: theme.threadPalette.menuItem.current,
    },
    lastSenderName: {
      color: theme.threadPalette.lightBlue,
      fontSize: '12px',
      height: '16px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontWeight: 'bold',
      '&:hover': {
        color: theme.threadPalette.lightGray,
      },
      '&.active': {
        color: theme.threadPalette.menuItem.current,
      },
    },
    messageActive: {
      color: theme.threadPalette.menuItem.normal,
    },
    starIcon: {
      color: theme.threadPalette.star,
      fontSize: '14px',
      marginLeft: '8px',
    },
    menuIcon: {
      color: theme.threadPalette.lighterGray,
      fontSize: '22px',
    },
    groupThreadImg: {
      height: '35px',
      borderRadius: '4px',
      width: '35px',
    },
    loadingItem: {
      '&.MuiSkeleton-root': {
        backgroundColor: '#3b4759',
      },
    },
    unfollowIcon: {
      fill: theme.threadPalette.brownishGray,
      height: '15px',
      width: '15px',
    },
  }
})
