import createMuiTheme, { ThemeOptions } from '@material-ui/core/styles/createMuiTheme'
import { SkeletonClassKey } from '@material-ui/lab/Skeleton'

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiSkeleton: SkeletonClassKey
  }
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    threadPalette: IThreadPalette
    threadMixins: IThreadThemeMixins
    dimensions: IDimensions
  }

  interface ThemeOptions {
    threadPalette?: Partial<IThreadPalette>
    threadMixins?: Partial<IThreadThemeMixins>
    dimensions?: Partial<IDimensions>
  }
}

// main google font to apply to all elements by default
export const primaryFont = process.env.REACT_APP_DEFAULT_FONT

export const primaryTextColor = '#33373a'

const createThreadTheme = (options: ThemeOptions) => {
  return createMuiTheme({
    overrides: {
      MuiTypography: {
        root: {
          fontFamily: primaryFont,
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          '&$disabled': {
            color: '#ffffff',
          },
        },
        contained: {
          '&$disabled': {
            color: '#ffffff',
            backgroundColor: '#e0dfe0',
          },
        },
        outlined: {
          '&$disabled': {
            color: '#ffffff',
            backgroundColor: '#e0dfe0',
          },
        },
      },

      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },

      MuiSkeleton: {
        root: {
          backgroundColor: 'rgb(0,0,0, 0.11)',
        },
        circle: {
          borderRadius: 4,
          width: 40,
          height: 40,
        },
      },
    },
    ...options,
    threadPalette: {
      lightGray: '#aaaaaa',
      lightSoftGray: '#b3b3b3',
      lightishGray: '#e5e5e5',
      lighterGray: '#f2f2f2',
      brownishGray: '#606060',
      darkGray: '#979797',
      darkMidnightBlue: '#2b3c57',
      backgroundColor: '#fafafa',
      white: '#ffffff',
      whiteSmoke: '#fcfcfc',
      menuItem: {
        normal: '#535759',
        current: '#33373a',
      },
      star: '#fce74c',
      lightBlue: '#95b1c9',
      darkBlue: '#4B5A69',
      darkerBlue: '#1e2d44',
      darkestBlue: '#141e2e',
      green: '#9bc53e',
      warning: '#fbe82c',
      errorDialog: '#fef9c3',
      darkBlueGrey: '#1d2d45',
      lightBlueGrey: '#566171',
      skyBlue: '#4994ec',
      slateGrey: '#566172',
      orange: '#f47823',
      onFocusGray: '#4b5a69',
      pink100: '#ffc1c1',
      salmonPink: '#f78e8e',
      vermilion: '#f42323',
      palePink: '#f9e2e2',
      warningRed: '#f44336',
      brightOrange: '#ff7000',
      lightGreen: '#008e37',
      vividRed: '#fa4545',
    },
    threadMixins: {
      toolbarHeight: {
        desktop: '66px',
        mobile: '56px',
      },
    },
    dimensions: {
      rightSidebar: {
        headerHeight: 50,
        maxWidth: 450,
        controlsBarHeight: 50,
      },
      leftSidebar: {
        threadWidth: 305,
      },
      filePreview: {
        headerMaxHeight: 70,
        contentMaxWidth: 800,
      },
    },
  })
}

// our theme
export const theme = createThreadTheme({
  palette: {
    primary: {
      main: '#2196f3',
      dark: '#1a78c3',
      light: '#63b6f7',
    },
    secondary: {
      main: '#9bc53e',
    },
    action: {
      disabled: '#e4e4e4',
      disabledBackground: '#ffffff',
    },
    error: {
      main: '#f78e8e',
      contrastText: '#f42224',
    },
  },
  typography: {
    fontFamily: primaryFont,
  },
})
