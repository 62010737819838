import { useMutation } from '@tanstack/react-query'
import { sendAttachmentAnalyticsEvent } from 'api/v2/analytics'

export const useSendAttachmentAnalyticsEventUseCase = () => {
  const { mutateAsync } = useMutation(sendAttachmentAnalyticsEvent)

  const sendAttachmentAnalyticsEventUseCase: typeof sendAttachmentAnalyticsEvent = mutateAsync

  return {
    sendAttachmentAnalyticsEventUseCase,
  }
}
