import { useMutation } from '@tanstack/react-query'
import { deleteDraftMessage as deleteDraftMessageApiCall } from 'api/message'
import { useThreadMessagesCacheActions } from 'hooks/useThreadMessages/useThreadMessagesCacheActions'
import { useDraftCacheActions } from 'hooks/useDraft/useDraftCacheActions'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'actions/snackbar'

export type IDeleteDraftUseCaseParams = Normalize<
  Parameters<typeof deleteDraftMessageApiCall>[0] & { threadId: string; repliedOrForwardedMessageId: string }
>

export const useDeleteDraftUseCase = () => {
  const dispatch = useDispatch()

  const threadMessagesCacheActions = useThreadMessagesCacheActions()
  const draftCacheActions = useDraftCacheActions()

  const removeDraftFromRepliedOrForwardedMessage = ({
    threadId,
    repliedOrForwardedMessageId,
    draftId: draftIdToRemove,
  }: IDeleteDraftUseCaseParams) => {
    if (!repliedOrForwardedMessageId) return

    const removeDraftIdFromMessage = (message: IMessage) => {
      const draftsIDs = message.draftsIds ?? []
      const filteredDraftsIDs = draftsIDs.filter(draftId => draftId !== draftIdToRemove)
      return { ...message, draftsIds: filteredDraftsIDs }
    }

    return threadMessagesCacheActions.updateMessage({
      threadId,
      messageId: repliedOrForwardedMessageId,
      messageModifier: removeDraftIdFromMessage,
    })
  }

  const deleteDraftOptimistically = (deleteDraftProps: IDeleteDraftUseCaseParams) => {
    const rollbackMessageOptimisticUpdate = removeDraftFromRepliedOrForwardedMessage(deleteDraftProps)
    const rollbackDraftOptimisticDeletion = draftCacheActions.deleteData(draft => draft.id === deleteDraftProps.draftId)

    return () => {
      rollbackMessageOptimisticUpdate?.()
      rollbackDraftOptimisticDeletion?.()
    }
  }

  const {
    mutateAsync,
    reset: resetDeleteDraftMutation,
    isLoading,
  } = useMutation({
    mutationFn: ({ draftId }: IDeleteDraftUseCaseParams) => deleteDraftMessageApiCall({ draftId }),
    onMutate: deleteDraftProps => {
      threadMessagesCacheActions.cancelDataFetching(deleteDraftProps.threadId)
      return deleteDraftOptimistically(deleteDraftProps)
    },
    onSettled: () => {
      resetDeleteDraftMutation()
    },
    onError: (_, { threadId }, rollbackOptimisticUpdates) => {
      rollbackOptimisticUpdates?.()
      threadMessagesCacheActions.refreshData(threadId)
      dispatch(showSnackbar({ message: 'Failed to delete draft message' }))
    },
    // We are double-checking that the draft is deleted. The draft can pop up if we refresh messages and receive a response
    // after we optimistically removed the draft before the draft was removed on the BE.
    onSuccess: (_, deleteDraftProps) => removeDraftFromRepliedOrForwardedMessage(deleteDraftProps),
  })

  const deleteDraftUseCase: (props: IDeleteDraftUseCaseParams) => Promise<void> = mutateAsync

  return {
    deleteDraftUseCase,
    isLoading,
  }
}
