import { EmailThreadsDataProcessor } from '../dataProcessors/emailThread'
import { apiClient } from '@jetkit/react'
import { IEmailThread } from '../model/emailThread'

export const getEmailThreads = async ({
  searchQuery,
  nextPageToken,
}: {
  searchQuery: string
  nextPageToken: string
  loadedEmailThreadIds?: string[]
}): Promise<{ emailThreads: IEmailThread[]; nextPageToken?: string }> => {
  const result = await apiClient.get('/v2/thread/email', {
    params: {
      search: searchQuery,
      nextPageToken: nextPageToken,
    },
  })

  const emailThreadsReturnData = result.data
  return {
    ...emailThreadsReturnData,
    emailThreads: EmailThreadsDataProcessor(emailThreadsReturnData.emailThreads),
  }
}

export const createFromEmailThread = async (
  emailThreadId: string,
  emailThreadSubject: string,
  projectId?: string
): Promise<IThread> => {
  const result = await apiClient.post('/v2/thread/email', {
    external_thread_conversation_id: emailThreadId,
    subject: emailThreadSubject,
    project_id: projectId,
  })
  return result.data
}
