import React from 'react'
import { useUpdateProjectUseCase } from 'usecases/updateProject'
import { IProject } from 'model/project'
import { ProjectArchivedStateBadge } from './projectArchivedStateBadge'

interface IArchiveUnarchiveProjectButton {
  project: IProject
}

export const ArchiveUnarchiveProjectButton = ({ project }: IArchiveUnarchiveProjectButton) => {
  const { updateProjectUseCase } = useUpdateProjectUseCase()

  const onUpdateProjectArchivedState = () =>
    updateProjectUseCase({ projectId: project.id, isArchived: !project.isArchived })

  return <ProjectArchivedStateBadge isArchived={project.isArchived} onClick={onUpdateProjectArchivedState} />
}
