import * as React from 'react'

function LogoOnlyIcon(props: React.SVGProps<SVGSVGElement>) {
  const { fill = '#2196F3' } = props

  return (
    <svg width={34} height={31} viewBox="0 0 34 31" {...props}>
      <path
        d="M.013 3.974L2.534 20.83a1.14 1.14 0 001.006.97l4.795.498v8.026l5.67-7.309v-9.92h-5.67V7.352h17.007v5.743h-5.669v10.442l10.237.943a1.14 1.14 0 001.23-1.011L33.685 1.28a1.142 1.142 0 00-1.22-1.277L1.04 2.657A1.146 1.146 0 00.013 3.974"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default LogoOnlyIcon
