import { LEFT_SIDEBAR_ACTIONS } from '../constants/actions/leftSidebar'

export const setOption = (option: TLeftSidebarOption): TThunk => {
  return (dispatch, getState) => {
    dispatch({
      type: LEFT_SIDEBAR_ACTIONS.SET_OPTION,
      optionSelected: option,
    })
  }
}
