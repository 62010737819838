import React from 'react'
import { Button, makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'

const useEditButtonStyles = makeStyles((theme: Theme) => ({
  editButtonRoot: {
    alignItems: 'center',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 12,
    justifyContent: 'center',
    paddingBlock: 0,
    paddingInline: 8,
    minWidth: 'unset',
  },
}))

interface IEditButtonStylesProps {
  label?: string | JSX.Element
  onClick?(): void
  className?: string
  labelClassName?: string
}

export const EditButton = ({ onClick, label = 'Edit', className, labelClassName }: IEditButtonStylesProps) => {
  const onClickButton = (event: React.MouseEvent) => {
    event.stopPropagation()
    onClick?.()
  }
  const classes = useEditButtonStyles()
  return (
    <Button
      classes={{ root: classNames(classes.editButtonRoot, className), label: labelClassName }}
      onClick={onClickButton}
    >
      {label}
    </Button>
  )
}
