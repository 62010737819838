import React from 'react'
import { useSelector } from 'react-redux'

import { login } from '../actions/outlook365OAuth'
import { OAuthLoginPage } from '../lib/oauth/pages'
import { selectOutlook365OAuthLoggingIn } from '../selectors/auth'

interface IOutlook365OAuthLoginPageProps {}

const Outlook365OAuthLoginPage = (props: IOutlook365OAuthLoginPageProps) => {
  const loggingIn = useSelector(selectOutlook365OAuthLoggingIn)

  return <OAuthLoginPage login={login} working={loggingIn} />
}

export default Outlook365OAuthLoginPage
