import * as React from 'react'
import { Theme, InputBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search'
import { SearchKeyHint } from './seachKeyHint'
import { TCurrentPlatform } from 'lib/diff'

const useSearchInputStyles = makeStyles((theme: Theme) => {
  return {
    searchInputRoot: {
      alignItems: 'center',
      backgroundColor: theme.threadPalette.lightBlueGrey,
      borderRadius: '6px',
      display: 'flex',
      flexDirection: 'row',
      height: '33px',
      justifyContent: 'space-between',
      padding: '8px',
    },
    searchIcon: {
      color: theme.threadPalette.white,
      height: '17px',
      marginRight: '5px',
      width: '17px',
    },
    inputRoot: {
      width: '100%',
    },
    input: {
      color: theme.threadPalette.white,
      '&::placeholder': {
        color: theme.threadPalette.white,
        fontStyle: 'italic',
        fontWeight: 100,
        opacity: 0.8,
        fontSize: 14,
      },
    },
    seachKeysHintWrapper: {
      columnGap: '4px',
      display: 'flex',
      flexDirection: 'row',
      marginLeft: '4px',
    },
  }
})

interface ISearchInputProps {
  currentPlatform: TCurrentPlatform
  openSearchModal?: (searchTerm: string) => void
}

export const SearchInput = ({ currentPlatform, openSearchModal }: ISearchInputProps) => {
  const classes = useSearchInputStyles()

  const [searchValue, setSearchValue] = React.useState<string>('')

  const seachKeyHints = currentPlatform === 'mac' ? ['⌘', 'K'] : ['Ctrl', 'K']
  const openSearchModalKeywords = ['person:', 'thread:', 'project:']

  const onSeachValueChage = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      let searchTerm = event.target.value

      const searchTermLowerCase = searchTerm.toLowerCase()

      openSearchModalKeywords.forEach(openSearchModalKeyword => {
        if (searchTermLowerCase.startsWith(openSearchModalKeyword)) {
          openSearchModal?.(searchTerm)
          searchTerm = ''
        }
      })

      setSearchValue(searchTerm)
    },
    [openSearchModal]
  )

  const handleShortcuts = React.useCallback(
    (event: React.KeyboardEvent) => {
      const isEnterPressed = event.key === 'Enter'

      if (!isEnterPressed || !searchValue) return

      setSearchValue('')
      openSearchModal?.(searchValue)
    },
    [searchValue, openSearchModal]
  )

  return (
    <div className={classes.searchInputRoot}>
      <SearchIcon classes={{ root: classes.searchIcon }} />
      <InputBase
        classes={{ root: classes.inputRoot, input: classes.input }}
        placeholder="Search..."
        value={searchValue}
        onChange={onSeachValueChage}
        onKeyUp={handleShortcuts}
      />
      <div className={classes.seachKeysHintWrapper}>
        {seachKeyHints.map(seachKeyHint => (
          <SearchKeyHint keyName={seachKeyHint} key={`${seachKeyHint}-key-hint`} />
        ))}
      </div>
    </div>
  )
}
