import { IAPIEmailThread, IEmailThread } from '../model/emailThread'
import { DateTime } from 'luxon'

export const EmailThreadsDataProcessor = (emails: IAPIEmailThread[]): IEmailThread[] => {
  return emails.map(email => {
    return {
      ...email,
      updatedAt: DateTime.fromISO(email.updatedAt),
    }
  })
}
