import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { fade, IconButton, Theme, Typography } from '@material-ui/core'
import MessageIcon from '@material-ui/icons/Message'
import TreeDotsIcon from '@material-ui/icons/MoreHoriz'
import UnlinkIcon from '@material-ui/icons/LinkOff'
import Spacer from 'component/layoutUtils/spacer'
import { AvatarGroup } from '@material-ui/lab'
import UserAvatar from 'component/userAvatar'
import { useBoolean } from 'hooks/useBoolean'
import ThreadSettingsFollowerItem from 'component/rightSidebar/threadFollowersList/followerItem'
import { getThreadDisplayName } from 'domain/thread'

const useThreadCardStyles = makeStyles((theme: Theme) => ({
  threadCardContainer: {
    backgroundColor: theme.threadPalette.white,
    borderRadius: 11,
    boxShadow: `0 0 20px -5px ${fade(theme.threadPalette.darkGray, 0.5)}`,
    color: theme.threadPalette.menuItem.current,
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
    paddingBottom: 8,
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
      transform: 'scale(1.03)',
    },
  },
  header: {
    alignItems: 'center',
    display: 'flex',
  },
  threadTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  headerSeparator: {
    border: `solid 0.5px ${theme.threadPalette.lighterGray}`,
    height: 1,
    marginTop: 6,
  },
  content: {
    marginTop: 10,
  },
  messageIcon: {
    color: theme.threadPalette.lightGray,
    fontSize: 18,
  },
  messagesCount: {
    alignItems: 'center',
    color: theme.threadPalette.menuItem.current,
    display: 'flex',
    fontSize: 14,
  },
  userAvatar: {
    borderRadius: 50,
    marginLeft: -14,
  },
  actions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  treeDotsIcon: {
    color: theme.threadPalette.lightGray,
    fontSize: 20,
  },
  unlinkIconContainer: {
    color: fade(theme.palette.error.main, 0.5),
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  unlinkIcon: {
    fontSize: 22,
  },
}))

interface IThreadCardProps {
  title: string
  titleAlias?: string
  messagesCount: number
  followers: IUser[]
  onClick?(): void
  onUnlink?(): void
  onHover?(): void
}

function ThreadCard({ title, titleAlias, messagesCount, followers, onClick, onUnlink, onHover }: IThreadCardProps) {
  const classes = useThreadCardStyles()
  const { state: isExpanded, toggle: toggleExpanded } = useBoolean(false)

  const messagesDisplayTextPluralized = messagesCount > 1 ? 'messages' : 'message'

  const threadDisplayName = getThreadDisplayName({ title, titleAlias })

  const onClickExpandedIcon = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    toggleExpanded()
  }

  const onClickUnlinkIcon = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    onUnlink?.()
  }

  return (
    <div className={classes.threadCardContainer} onClick={onClick} onMouseEnter={onHover}>
      <div className={classes.header}>
        <img height="20" width="20" src="/logo.png" alt="Threadlive logo" />
        <Spacer width={8} />
        <Typography className={classes.threadTitle}>{threadDisplayName}</Typography>
      </div>
      <div className={classes.headerSeparator}></div>
      <div className={classes.content}>
        <Typography component="div" className={classes.messagesCount}>
          <MessageIcon className={classes.messageIcon} /> <Spacer width={10} />
          {messagesCount} {messagesDisplayTextPluralized}
        </Typography>
        <Spacer height={18} />
        {!isExpanded && (
          <>
            <AvatarGroup classes={{ avatar: classes.userAvatar }}>
              {followers.map(follower => (
                <UserAvatar key={follower.email} user={follower} />
              ))}
            </AvatarGroup>
            <Spacer height={10} />
          </>
        )}
        {isExpanded &&
          followers.map(follower => (
            <ThreadSettingsFollowerItem key={follower.email} user={follower} addDivider={false} />
          ))}
      </div>
      <div className={classes.actions}>
        <IconButton size="small" onClick={onClickExpandedIcon}>
          <TreeDotsIcon className={classes.treeDotsIcon} />
        </IconButton>
        <IconButton onClick={onClickUnlinkIcon} size="small" className={classes.unlinkIconContainer}>
          <UnlinkIcon className={classes.unlinkIcon} />
        </IconButton>
      </div>
    </div>
  )
}

export default ThreadCard
