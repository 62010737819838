import * as React from 'react'

interface IDarkTriangleIconProps {}

const DarkTriangleIcon = (props: IDarkTriangleIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      {...props}
      style={{ marginTop: 4 }}
      fill="#33373a"
    >
      <path fillRule="evenodd" d="M3 12L9 6 3 0z" transform="rotate(90 5.644 6.356)" />
    </svg>
  )
}

export default DarkTriangleIcon
