import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import * as serviceWorker from './serviceWorker'
import applyCaseMiddleware from 'axios-case-converter'
import { apiClient } from '@jetkit/react'
import * as Sentry from '@sentry/react'
import { applyErrorLogsMiddleware } from 'lib/logs'

applyCaseMiddleware(apiClient, {
  ignoreHeaders: true,
  preservedKeys: ['access_token', 'refresh_token', 'content-type', 'x-amz-acl'],
})
applyErrorLogsMiddleware(apiClient, [403, 404])

if (process.env.REACT_APP_SENTRY_ENABLED === 'true')
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, environment: 'prod' })

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
