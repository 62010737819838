import * as React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'

import { DateTimeDataProcessor } from '../../dataProcessors/dateTime'
import { DateDivider } from './dateDivider'
import { ICreateDraftUseCaseProps } from 'usecases/draft/createDraft'
import { ThreadMessageSkeleton } from './threadMessageSkeleton'
import Skeleton from '@material-ui/lab/Skeleton'
import { ISystemMessageProps, SystemMessage } from './systemMessage/systemMessage'
import { Typography } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined'
import SyncIcon from '@material-ui/icons/Sync'
import { ThreadMessageBlockWithAvatar } from 'component/message/threadMessage/threadMessageBlockWithAvatar'
import { IThreadSharingSettings } from 'model/sharingGroup'
import { getMessageAudience, getMessageAudienceEmails } from 'domain/message'
import { getThreadSharingSettingsThatHoldMessage, getThreadSharingSettingsThatShareMessage } from 'domain/sharingGroup'
import { DraftMessageWithSkeletonLoading } from 'component/message/draftMessage/draftMessageWithSkeletonLoading'
import { MessageShareActions } from 'component/message/messageComponents/messageShareActions'
import InfiniteScroll from 'react-infinite-scroll-component'
import BeatProgress from 'component/beatProgress'
import { useNewThreadMessagesListener } from '../../hooks/useNewThreadMessagesListener'
import { scrollContainerIntoPosition } from 'dataProcessors/utils'

const VIEWPORT_MARGIN = 24

const useMessageListStyles = makeStyles((theme: Theme) => ({
  threadMessagesListRoot: {
    height: '100%',
    '& .infinite-scroll-component__outerdiv': {
      height: '100%',
    },
  },
  messageContainer: {
    maxWidth: '100vw',
  },
  messageWithDraftsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  messagesScrollableList: {
    display: 'flex',
    flexDirection: 'column-reverse',
    gap: 30,
    height: '100%',
    overflow: 'auto',
    paddingBottom: 20,
  },
  dateDivider: {
    marginBottom: `${VIEWPORT_MARGIN}px`,
  },
  systemMessageBubbleText: {
    fontSize: '15px',
    lineHeight: '28px',
  },
  infiniteScroll: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
}))

interface IMessageListProps {
  currentThread: IThread
  messagesList: IMessage[]
  user: IUser
  isLoading: boolean
  fetchNextMessagesPage: () => void
  onMessageReimport: (messageId: string) => Promise<void>
  createDraft: (props: ICreateDraftUseCaseProps) => void
  deleteDraft: (repliedOrForwardedMessageId: string, draftToRemoveId: string) => void
  onThreadReimport: () => Promise<void>
  threadSharingSettingsList: IThreadSharingSettings[]
  isLoadingThreadSharingSettings: boolean
  isRefreshingMessages: boolean
  hasMoreMessagePages: boolean
  scrollPosition: number
  setScrollPosition(scrollPosition: number): void
}

const MessageList = ({
  currentThread,
  messagesList,
  fetchNextMessagesPage,
  createDraft,
  isLoading: isLoadingEmptyThread,
  deleteDraft,
  onMessageReimport,
  onThreadReimport,
  user,
  threadSharingSettingsList,
  isLoadingThreadSharingSettings,
  isRefreshingMessages,
  hasMoreMessagePages,
  scrollPosition,
  setScrollPosition,
}: IMessageListProps) => {
  const classes = useMessageListStyles()

  const messagesListRef = React.useRef<HTMLDivElement | null>(null)
  const infiniteScrollRef = React.useRef<InfiniteScroll | null>(null)

  const isLoadingMessages = React.useMemo(() => {
    const isLoadingMessagesList = isLoadingEmptyThread && !currentThread?.firstDraftId

    const isMessagesListEmpty = messagesList.length === 0
    const isImportingThreadMessages = currentThread.isImporting && isMessagesListEmpty

    // Covers a case when the thread is imported and we have an empty messages list and re-loading it,
    // but this is not the initial loading
    const isLoadingFromEmptyPage = isRefreshingMessages && isMessagesListEmpty

    return isLoadingMessagesList || isImportingThreadMessages || isLoadingFromEmptyPage
  }, [isLoadingEmptyThread, currentThread, messagesList, isRefreshingMessages])

  const messagedListOrdered = React.useMemo(() => {
    const threadMessages = messagesList.filter(message => message.threadId === currentThread.id)
    const threadMessagesOrdered = threadMessages.sort((one, two) => (one.createdAt > two.createdAt ? -1 : 1))
    return threadMessagesOrdered
  }, [messagesList, currentThread])

  const getMessagesSkeletonList = () => {
    const dateSkeleton = (
      <Skeleton
        key="date"
        className={classes.dateDivider}
        width={'98%'}
        animation="wave"
        style={{ alignSelf: 'center' }}
      >
        <DateDivider className={classes.dateDivider} date={Date.now().toString()} />
      </Skeleton>
    )
    const messagesSkeleton = [...Array(5)].map((_, index) => {
      const isOwnMessage = index % 2 === 0
      return (
        <div key={index} className={classes.messageContainer}>
          <ThreadMessageSkeleton isOwnMessage={isOwnMessage} currentUser={user} />
        </div>
      )
    })
    return [dateSkeleton, ...messagesSkeleton]
  }

  const systemMessagesToDisplay: ISystemMessageProps[] = []

  const displayMissingMessagesSystemMessage =
    !isLoadingEmptyThread && currentThread.hasImportErrors && !currentThread.isImporting
  if (displayMissingMessagesSystemMessage) {
    systemMessagesToDisplay.push({
      children: (
        <>
          <Typography className={classes.systemMessageBubbleText}>
            ThreadLive detected an issue, some of your messages might be missing.
          </Typography>
          <Typography className={classes.systemMessageBubbleText}>
            We will attempt to re-sync with your mail provider soon, or you can trigger it manually.
          </Typography>
        </>
      ),
      header: { text: 'Missing messages', IconComponent: ErrorIcon },
      action: {
        text: 'Re-sync inbox',
        IconComponent: SyncIcon,
        onClick: onThreadReimport,
      },
    })
  }

  const messagesListAsComponent = React.useMemo(() => {
    const messageComponentsList = messagedListOrdered.map(message => {
      const threadSharingSettingsThatShareMessage = getThreadSharingSettingsThatShareMessage(
        message,
        threadSharingSettingsList
      )
      const threadSharingSettingsThatHoldMessage = getThreadSharingSettingsThatHoldMessage({
        message,
        threadSharingSettingsList,
        user,
      })

      const messageAudienceEmails = getMessageAudienceEmails(message)
      const isMessageInViewAccess =
        !!threadSharingSettingsThatShareMessage && !messageAudienceEmails.includes(user.email)

      const draftsIds = message?.draftsIds ?? []

      const messageAudience = getMessageAudience(message)
      const shareActionsComponent = (
        <MessageShareActions
          threadSharingSettingsThatShareMessage={threadSharingSettingsThatShareMessage}
          threadSharingSettingsThatHoldMessage={threadSharingSettingsThatHoldMessage}
          messageAudience={messageAudience}
          currentUser={user}
          message={message}
          isLoadingThreadSharingSettings={isLoadingThreadSharingSettings}
        />
      )

      return (
        <div key={message.id} className={classes.messageWithDraftsContainer}>
          <div className={classes.messageContainer}>
            {message.type === 'email' && (
              <ThreadMessageBlockWithAvatar
                message={message}
                threadId={currentThread.id}
                currentUser={user}
                isViewAccess={isMessageInViewAccess}
                messageStatusComponent={shareActionsComponent}
                onNewDraft={createDraft}
                onMessageReimport={() => onMessageReimport(message.id)}
              />
            )}
          </div>
          {draftsIds.map((draftId, index) => (
            <div className={classes.messageContainer} key={draftId}>
              <DraftMessageWithSkeletonLoading
                draftMessageId={draftId}
                displayId={`${index + 1}`}
                repliedOrForwardedMessage={message}
                onDelete={() => deleteDraft(message.id, draftId)}
                draftContainerRef={messagesListRef}
                currentUser={user}
              />
            </div>
          ))}
        </div>
      )
    })

    const messagesWithDateDividersList = messageComponentsList.reduce<JSX.Element[]>(
      (messagesWithDividers, messageComponent, index) => {
        const currentMessageSentDate = DateTimeDataProcessor(messagesList[index].createdAt).toISODate()
        const isLastMessage = index === messagesList.length - 1

        const nextMessageSentDate = !isLastMessage
          ? DateTimeDataProcessor(messagesList[index + 1].createdAt).toISODate()
          : null

        const shouldAddDivider =
          currentMessageSentDate !== nextMessageSentDate || index === messageComponentsList.length - 1

        const divider = shouldAddDivider ? (
          <DateDivider key={currentMessageSentDate} className={classes.dateDivider} date={currentMessageSentDate} />
        ) : null

        return divider
          ? [...messagesWithDividers, messageComponent, divider]
          : [...messagesWithDividers, messageComponent]
      },
      []
    )

    return messagesWithDateDividersList
  }, [messagedListOrdered, currentThread.id, threadSharingSettingsList])

  const scrollMessagesListToPosition = React.useCallback(
    (scrollPosition: number, behavior: ScrollBehavior) => {
      const scrollableTarget = infiniteScrollRef.current?.getScrollableTarget()
      if (!scrollableTarget) return
      scrollContainerIntoPosition({ ref: scrollableTarget, scrollPosition, behavior })
    },
    [infiniteScrollRef.current]
  )

  // scrolling to the last saved position in the thread
  React.useEffect(() => scrollMessagesListToPosition(scrollPosition, 'auto'), [currentThread.id])

  // scrolling down on new messages
  useNewThreadMessagesListener({ callback: () => scrollMessagesListToPosition(0, 'smooth') })

  // saving the scrolling position
  const onScroll = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    setScrollPosition(target.scrollTop)
  }

  const messagesListScrollableRootId = 'messagesListScrollableRoot'

  return (
    <div className={classes.threadMessagesListRoot}>
      <InfiniteScroll
        ref={infiniteScrollRef}
        style={{ height: '100%', overflow: 'hidden' }}
        className={classes.infiniteScroll}
        dataLength={messagesList.length}
        next={fetchNextMessagesPage}
        hasMore={hasMoreMessagePages && !isLoadingEmptyThread}
        scrollableTarget={messagesListScrollableRootId}
        loader={<BeatProgress />}
        hasChildren={true}
        inverse={true}
        scrollThreshold="250px"
        onScroll={onScroll}
      >
        <div id={messagesListScrollableRootId} ref={messagesListRef} className={classes.messagesScrollableList}>
          {systemMessagesToDisplay.map(systemMessageToDisplay => (
            <div className={classes.messageContainer} key={systemMessageToDisplay.header.text}>
              <SystemMessage {...systemMessageToDisplay} />
            </div>
          ))}
          {isLoadingMessages && getMessagesSkeletonList()}
          {!isLoadingMessages && messagesListAsComponent}
          {currentThread?.firstDraftId && (
            <div className={classes.messageContainer} key={`first-message-${currentThread.id}`}>
              <DraftMessageWithSkeletonLoading
                draftMessageId={currentThread.firstDraftId}
                draftContainerRef={messagesListRef}
                currentUser={user}
              />
            </div>
          )}
        </div>
      </InfiniteScroll>
    </div>
  )
}

export default MessageList
