import React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import { IThreadMessageProps, ThreadMessage } from './threadMessage'
import classnames from 'classnames'
import ImgMessageBubbleTriangle from 'assets/message_bubble_triangle.svg'
import ImgMessageBubbleSharedTriangle from 'assets/message_bubble_triangle_shared.svg'
import { useMessageContent } from '../hooks/useMessageContent'
import { MessageBlockWithAvatar } from '../shared/messageBlockWithAvatar'

interface IUseThreadMessageBlockWithAvatarStylesProps {
  isViewAccess?: boolean
}

const useThreadMessageBlockWithAvatarStyles = makeStyles<Theme, IUseThreadMessageBlockWithAvatarStylesProps>(
  (theme: Theme) => {
    return {
      messageBlockWithAvatarRoot: {
        maxWidth: 780,
      },
      threadMessageWithTableRoot: {
        maxWidth: '65vw',
      },
      bubbleTriangleCommon: {
        '&:before': {
          backgroundImage: ({ isViewAccess }: IUseThreadMessageBlockWithAvatarStylesProps) =>
            isViewAccess ? `url(${ImgMessageBubbleSharedTriangle})` : `url(${ImgMessageBubbleTriangle})`,
          backgroundSize: '10px 10px',
          bottom: 15,
          content: '""',
          display: 'block',
          height: 10,
          position: 'absolute',
          width: 10,
        },
      },
      bubbleTriangleForReceiver: {
        '&:before': {
          left: '-8px',
          transform: 'rotate(180deg)',
        },
      },
      bubbleTriangleForSender: {
        '&:before': {
          right: '-8px',
        },
      },
      userAvatar: {
        marginBottom: 22,
      },
    }
  }
)

interface IThreadMessageBlockWithAvatarProps extends IThreadMessageProps {}

export const ThreadMessageBlockWithAvatar = React.forwardRef<HTMLDivElement, IThreadMessageBlockWithAvatarProps>(
  (props, ref) => {
    const {
      message,
      currentUser,
      isLoading,
      onNewDraft,
      threadId,
      onMessageReimport,
      isViewAccess,
      messageStatusComponent,
    } = props
    const { content, previousMessageContent, sender } = message

    const classes = useThreadMessageBlockWithAvatarStyles({ isViewAccess })

    const isCurrentUserMessageSender = sender.id === currentUser.id

    const { isTablePresentOnMessageContentOrPreviousMessage: isMessageWithTable } = useMessageContent({
      content,
      previousMessageContent,
    })

    const messageBlockWithAvatarRootClassName = classnames(classes.messageBlockWithAvatarRoot, {
      [classes.threadMessageWithTableRoot]: isMessageWithTable,
    })

    const contentRootClassName = classnames(classes.bubbleTriangleCommon, {
      [classes.bubbleTriangleForSender]: isCurrentUserMessageSender,
      [classes.bubbleTriangleForReceiver]: !isCurrentUserMessageSender,
    })

    return (
      <MessageBlockWithAvatar
        isCurrentUserMessageSender={isCurrentUserMessageSender}
        messageSender={sender}
        rootClassName={messageBlockWithAvatarRootClassName}
        isLoading={isLoading}
        avatarClassName={classes.userAvatar}
      >
        <ThreadMessage
          ref={ref}
          contentRootClassName={contentRootClassName}
          threadId={threadId}
          message={message}
          currentUser={currentUser}
          onNewDraft={onNewDraft}
          isLoading={isLoading}
          isViewAccess={isViewAccess}
          onMessageReimport={onMessageReimport}
          messageStatusComponent={messageStatusComponent}
        />
      </MessageBlockWithAvatar>
    )
  }
)
