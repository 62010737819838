import { apiClient } from '@jetkit/react'
import { PaginatedResponse } from './pagedResponse'
import { PagedResponseDataProcessor } from 'dataProcessors/pagedResponse'

export const getContacts = async (page: number, searchPhrase?: string): Promise<PaginatedResponse<IUser[]>> => {
  const searchString = searchPhrase?.trim() ?? ''

  const response = await apiClient.get(`/v2/contacts`, {
    params: { page, search_string: searchString },
  })

  return PagedResponseDataProcessor(response)
}
