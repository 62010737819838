import React from 'react'
import { SharingGroupScreenHeader } from '../../common/sharingGroupScreenHeader'
import { Theme, makeStyles, useTheme } from '@material-ui/core'
import { People as GroupIcon } from '@material-ui/icons'
import InlineEditingTextField from 'shared/inputs/inlineEditingTextField'
import { ISharingGroup } from 'model/sharingGroup'
import { useEditSharingGroupUseCase } from 'usecases/sharingGroup/editSharingGroup'
import { useSharingGroupContext } from 'contexts/sharingGroupContext'
import { PeopleSelect } from 'component/peopleSelect/peopleSelect'
import { SharingGroupMemberChip } from 'component/sharingGroup/common/sharingGroupMemberChip'
import { useCreateOrEditSharingGroup } from 'hooks/useSharingGroup/useCreateOrEditSharingGroup'
import { EditSharingGroupButton } from './editSharingGroupButton'
import { DeleteSharingGroupButton } from 'component/sharingGroup/common/deleteSharingGroupButton'
import { useDeleteSharingGroupUseCase } from 'usecases/sharingGroup/deleteSharingGroup'
import { ExistingSharingGroupWithSameNameError } from 'component/sharingGroup/common/existingSharingGroupWithSameNameError'

const useEditSharingGroupScreenStyles = makeStyles((theme: Theme) => ({
  editSharingGroupScreenRoot: {
    maxWidth: 600,
    paddingInline: 20,
    paddingBottom: 20,
  },
  sharingGroupNameContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
  },
  groupIcon: {
    fill: theme.threadPalette.menuItem.current,
    fontSize: 28,
  },
  groupNameInput: {
    fontSize: 18,
    height: 36,
  },
  editGroupNameIcon: {
    color: theme.threadPalette.lightGray,
    fontSize: 16,
  },
  sharingGroupNameInput: {
    fontSize: 22,
    fontWeight: 500,
    height: '100%',
  },
  groupNameLabel: {
    fontSize: 22,
    fontWeight: 700,
  },
  selectMembersInputContainer: {
    border: `solid 1.5px ${theme.palette.primary.main}`,
    borderRadius: 4,
    marginTop: 20,
    width: '100%',
  },
  selectedMembersContainer: {
    columnGap: 8,
    display: 'inline-flex',
    flexWrap: 'wrap',
    marginTop: 16,
    rowGap: 6,
  },
  actionsContainer: {
    alignItems: 'end',
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: 16,
    width: '100%',
  },
}))

interface IEditSharingGroupScreen {
  sharingGroup: ISharingGroup
  onReturn?(): void
  onSuccess?(): void
  onDeleteSharingGroup?(): void
}
export const EditSharingGroupScreen = ({
  sharingGroup,
  onReturn,
  onSuccess,
  onDeleteSharingGroup,
}: IEditSharingGroupScreen) => {
  const theme = useTheme()
  const classes = useEditSharingGroupScreenStyles()
  const { thread } = useSharingGroupContext()

  const {
    sharingGroupMembers: updatedSharingGroupMembers,
    updateSharingGroupMembers,
    sharingGroupMembersAdded,
    sharingGroupMembersRemoved,
    filterCurrentUserFromMembersOptions,
    sharingGroupName: updatedSharingGroupName,
    updateSharingGroupName: onEditSharingGroupName,
    userHasOtherSharingGroupsWithSameName,
    isValidSharingGroup,
  } = useCreateOrEditSharingGroup({
    id: sharingGroup.id,
    initialName: sharingGroup.name,
    initialMembers: sharingGroup.members,
  })

  const sharingGroupNameContainerRef = React.useRef<HTMLDivElement | null>(null)
  const membersContainerRef = React.useRef<HTMLDivElement | null>(null)

  const { editSharingGroupUseCase, isLoading: isEditingSharingGroup } = useEditSharingGroupUseCase({
    threadId: thread.id,
    onSuccess,
  })
  const { deleteSharingGroupUseCase } = useDeleteSharingGroupUseCase({
    threadId: thread.id,
    onSuccess: onDeleteSharingGroup,
  })

  const isNameChanged = sharingGroup.name !== updatedSharingGroupName.trim()
  const areMembersChanged = sharingGroupMembersAdded.length > 0 || sharingGroupMembersRemoved.length > 0
  const canSubmit = isValidSharingGroup && (isNameChanged || areMembersChanged)

  const onSubmit = () => {
    if (!isNameChanged && !areMembersChanged) return onReturn?.()

    const sharingGroupMembersEmails = updatedSharingGroupMembers.map(member => member.email)
    editSharingGroupUseCase({
      sharingGroupId: sharingGroup.id,
      name: updatedSharingGroupName,
      membersEmails: sharingGroupMembersEmails,
    })
  }

  const onDelete = () => {
    deleteSharingGroupUseCase(sharingGroup.id)
    onReturn?.()
  }

  const onSharingGroupMemberDeleted = (person: TPerson) => {
    const isMemberRemoved = sharingGroupMembersRemoved.some(member => member.email === person.email)
    const updatedMembers = isMemberRemoved
      ? [...updatedSharingGroupMembers, person]
      : updatedSharingGroupMembers.filter(updatedMember => updatedMember.email !== person.email)
    updateSharingGroupMembers(updatedMembers)
  }

  const sharingGroupMembersWithAddedAndRemoved = React.useMemo(
    () => [...sharingGroupMembersAdded, ...sharingGroup.members],
    [sharingGroupMembersAdded, sharingGroup.members]
  )

  const getSharingGroupMemberAccentColor = React.useCallback(
    (sharingGroupMember: TPerson) => {
      const isMemberAdded = sharingGroupMembersAdded.some(member => member.email === sharingGroupMember.email)
      if (isMemberAdded) return theme.threadPalette.green

      const isMemberRemoved = sharingGroupMembersRemoved.some(member => member.email === sharingGroupMember.email)
      if (isMemberRemoved) return theme.threadPalette.vermilion
      return undefined
    },
    [sharingGroupMembersAdded, sharingGroupMembersRemoved, theme.threadPalette]
  )

  const hasSharingGroupMembers = sharingGroupMembersWithAddedAndRemoved.length > 0

  return (
    <div className={classes.editSharingGroupScreenRoot}>
      <SharingGroupScreenHeader text="Edit group" onReturnToPreviousScreenClick={onReturn} />
      <div ref={sharingGroupNameContainerRef} className={classes.sharingGroupNameContainer}>
        <GroupIcon className={classes.groupIcon} />
        <InlineEditingTextField
          onConfirmEdit={onEditSharingGroupName}
          inputProps={{ defaultValue: updatedSharingGroupName, placeholder: 'Name your group' }}
          inputContainerClassName={classes.sharingGroupNameInput}
          inputClassName={classes.groupNameInput}
          editIconClassName={classes.editGroupNameIcon}
          labelClassName={classes.groupNameLabel}
          hasError={userHasOtherSharingGroupsWithSameName}
        >
          {updatedSharingGroupName}
        </InlineEditingTextField>
      </div>
      {userHasOtherSharingGroupsWithSameName && (
        <ExistingSharingGroupWithSameNameError sharingGroupName={updatedSharingGroupName} />
      )}
      <div ref={membersContainerRef}>
        <PeopleSelect
          placeholder="Add people to this group"
          rootClassName={classes.selectMembersInputContainer}
          selectedPeople={updatedSharingGroupMembers}
          onPeopleChange={updateSharingGroupMembers}
          peopleOptionsFilter={filterCurrentUserFromMembersOptions}
          isFocusedOnRendering
        />
        {hasSharingGroupMembers && (
          <div className={classes.selectedMembersContainer}>
            {sharingGroupMembersWithAddedAndRemoved.map(person => (
              <SharingGroupMemberChip
                person={person}
                key={person.email}
                onDelete={() => onSharingGroupMemberDeleted(person)}
                accentColor={getSharingGroupMemberAccentColor(person)}
              />
            ))}
          </div>
        )}
      </div>
      <div className={classes.actionsContainer}>
        <EditSharingGroupButton
          onEditSharingGroup={onSubmit}
          confirmationPopupAnchorElement={membersContainerRef}
          membersAdded={sharingGroupMembersAdded}
          membersRemoved={sharingGroupMembersRemoved}
          disabled={!canSubmit}
          isLoading={isEditingSharingGroup}
        />
        <DeleteSharingGroupButton
          sharingGroupName={sharingGroup.name}
          anchorElementRefForConfirmationPopup={sharingGroupNameContainerRef}
          onClick={onDelete}
        />
      </div>
    </div>
  )
}
