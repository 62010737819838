import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { followThread } from 'api/v2/thread'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useUpdateThreadUseCase } from './updateThread'

export const useFollowThreadUseCase = () => {
  const dispatch = useDispatch()

  const { updateThreadUseCase } = useUpdateThreadUseCase()
  const { refreshThread, refreshThreadsList } = useThreadActions()

  const followThreadApiCall = async (threadId: string) => {
    return await followThread(threadId)
  }

  const followThreadMutation = useMutation(followThreadApiCall, {
    onMutate: followThreadParams => {
      const threadId = followThreadParams
      updateThreadUseCase({ threadId: threadId, stateForUser: 'following' })
    },
    onSettled: (data, error, followThreadParams) => {
      const threadId = followThreadParams

      followThreadMutation.reset()

      refreshThread(threadId)
      refreshThreadsList()
    },
    onError: () => {
      dispatch(showSnackbar({ message: 'Error on following the thread' }))
    },
  })

  const followThreadUseCase = useCallback(
    async (threadId: string) => {
      await followThreadMutation.mutateAsync(threadId)
    },
    [followThreadMutation]
  )

  return { followThreadUseCase, isLoading: followThreadMutation.isLoading }
}
