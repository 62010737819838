import * as React from 'react'
import { WebSocketGateContext } from './../webSocketGateContext'

export type NotificationsCallback = (unreadQuantity: number) => void

interface IUseNotificationHandlerArgs {
  key: string
  callback: NotificationsCallback
}

/**
 * Hook that connects incoming typing actions with end component for provided threadId
 * @param callback - callback on incoming 'new_notification' events
 */

export function useNotificationHandler({ key, callback }: IUseNotificationHandlerArgs) {
  const { notificationHandler: notificationsHandler } = React.useContext(WebSocketGateContext)

  React.useEffect(() => {
    if (callback) {
      notificationsHandler?.addCallback(key, callback)
    }
    return () => {
      notificationsHandler?.removeCallback(key)
    }
  }, [key, callback])
  return { notificationsHandler }
}
