import React from 'react'
import { SubscriptionCreationErrorPage } from 'component/signInErrors/subscriptionCreationError'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useSubscriptionCreationFailedPageStyles = makeStyles((theme: Theme) => ({
  subtitleRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
}))

interface ISubscriptionCreationFailedPageProps {
  emailProvider: TEmailProvider
}

export const SubscriptionCreationFailedPage = (props: ISubscriptionCreationFailedPageProps) => {
  const classes = useSubscriptionCreationFailedPageStyles()

  const providerName = props.emailProvider.charAt(0).toUpperCase() + props.emailProvider.slice(1)

  const subtitle = (
    <div className={classes.subtitleRoot}>
      We couldn't connect to your {providerName} account, which means {providerName} is either offline or passing
      through some instabilities. Please, try again in a few minutes
    </div>
  )

  return <SubscriptionCreationErrorPage subtitle={subtitle} />
}
