import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { reimportThread as reimportThreadApiCall } from 'api/threads'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'
import { useDispatch } from 'react-redux'
import { useUpdateThreadUseCase } from './updateThread'

export const useReimportThreadUseCase = () => {
  const dispatch = useDispatch()
  const { refreshThread } = useThreadActions()

  const { updateThreadUseCase } = useUpdateThreadUseCase()

  const { mutateAsync } = useMutation(reimportThreadApiCall, {
    onMutate: threadId => {
      const { rollbackOptimisticUpdates } = updateThreadUseCase({ threadId, isImporting: true })
      return rollbackOptimisticUpdates
    },
    onError: (_, __, rollbackOptimisticUpdates) => {
      rollbackOptimisticUpdates?.()
      dispatch(showSnackbar({ message: 'Error trying to re-sync with the thread.' }))
    },
    onSettled: (_, __, threadId) => refreshThread(threadId),
  })

  const reimportThreadUseCase: typeof reimportThreadApiCall = mutateAsync

  return {
    reimportThreadUseCase,
  }
}
