import { useRef, useEffect } from 'react'

/*
 *    Helper hook to check if current render is the first render in a component
 */

export const useIsMount = () => {
  const isMountRef = useRef(true)
  useEffect(() => {
    isMountRef.current = false
  }, [])
  return isMountRef.current
}
