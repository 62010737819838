import * as React from 'react'
import { useIconStyles } from '../styles'
import classnames from 'classnames'

interface IPdfIconProps extends JSX.IntrinsicAttributes {
  width?: number
  height?: number
  className?: string
  fill?: string
}

const PdfIcon = ({ className, width, height, fill }: IPdfIconProps) => {
  const classes = useIconStyles()
  return (
    <div className={classnames(className, classes.iconWrapper)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : '20'}
        height={height ? height : '20'}
        viewBox="0 0 20 20"
      >
        <path
          fill={fill ? fill : '#D0021B'}
          d="M17.268 19.714c.283 0 .523-.099.72-.297.199-.198.298-.438.298-.72V6.481c0-.283-.071-.594-.212-.933-.142-.34-.311-.608-.51-.806l-3.307-3.308c-.198-.198-.467-.367-.806-.509-.34-.141-.65-.212-.933-.212h-9.5c-.283 0-.523.1-.721.297C2.099 1.21 2 1.45 2 1.732v16.964c0 .283.099.523.297.721.198.198.438.297.72.297h14.25zm-.34-1.357H3.359V2.071H11.5v4.411c0 .283.099.523.297.721.198.198.438.297.72.297h4.412v10.857zm-.084-12.214h-3.987V2.156c.205.07.35.149.435.234l3.318 3.318c.085.085.163.23.234.435zM5.404 17c.629 0 1.484-.926 2.565-2.778 1.209-.417 2.594-.71 4.157-.88.445.233.904.42 1.378.562.473.141.88.212 1.22.212.459 0 .71-.134.752-.403v-.01l.021-.022c.007-.007.01-.014.01-.02.1-.213.093-.397-.02-.552-.212-.347-.838-.52-1.877-.52-.41 0-.824.025-1.24.075-.361-.213-.658-.41-.891-.594-.643-.523-1.16-1.364-1.548-2.524.085-.487.134-1.166.148-2.035v-.318c0-.036-.003-.064-.01-.085.014-.014.028-.043.042-.085.064-.332.032-.573-.095-.721-.085-.106-.209-.16-.371-.16H9.41c-.219 0-.367.142-.445.425-.092.353-.115.804-.069 1.352s.154 1.111.323 1.69c-.254.892-.622 1.818-1.102 2.779-.389.792-.767 1.488-1.135 2.089 0-.015-.007-.029-.02-.043-.065-.106-.146-.127-.245-.063-.6.318-1.067.659-1.4 1.023-.332.364-.53.687-.593.97-.028.184-.007.31.064.382.035.028.056.045.063.053l.255.127c.091.05.19.074.296.074zM9.57 8.073c-.092-.637-.085-1.103.022-1.4v-.021c.007-.007.01-.014.01-.021.085.099.13.226.138.381 0 .007.002.013.005.016.004.004.006.009.006.016 0 .007.003.014.01.021-.021.029-.035.057-.042.085-.05.283-.074.435-.074.456-.043.262-.068.417-.075.467zM8.277 13.68c.106-.19.265-.484.477-.88.396-.784.69-1.48.88-2.088.36.77.81 1.392 1.346 1.866.036.035.092.083.17.143.078.06.124.093.138.1-1.053.191-2.057.478-3.011.86zm6.68-.127c-.34 0-.778-.099-1.315-.297.82 0 1.314.085 1.484.255.014.014.021.024.021.032-.028.007-.092.01-.19.01zm-9.585 2.873c.092-.212.266-.473.524-.784.258-.311.568-.608.928-.89-.6.946-1.085 1.505-1.452 1.674z"
        />
      </svg>
    </div>
  )
}

export default PdfIcon
