import React from 'react'
import IAttachment, { IInlineAttachment } from 'model/attachment'
import { useUpdateDraftUseCase } from 'usecases/draft/updateDraft'
import { useIsMount } from './useIsMount'
import { TDraftProcessingErrorReason } from 'usecases/draft/errors'
import { useDraftCacheActions } from './useDraft/useDraftCacheActions'

interface IDraftUpdateProps {
  draftId: string
  threadId: string
  repliedOrForwardedMessageId: string
  toRecipients: TDraftRecipient[]
  bccRecipients: TDraftRecipient[]
  content: string
  inlineAttachments: IInlineAttachment[]
  standardAttachments: IAttachment[]
  isPreviousMessageContentEdited: boolean
  isSendingDraft: boolean
  isAttachmentsUploadInProgress: boolean
  onUpdateError?: (reason?: TDraftProcessingErrorReason) => void
}

interface IUpdateDraftCallbackProps {
  forceDraftUpdate: boolean
  scheduleSyncingWithProvider: boolean
}

type UpdateDraftCallback = (props?: IUpdateDraftCallbackProps) => void

const TIME_TO_ACCUMULATE_DRAFT_CHANGES_IN_MILLISECONDS = 3 * 1000

export const useUpdateDraft = ({
  draftId,
  threadId,
  repliedOrForwardedMessageId,
  toRecipients,
  bccRecipients,
  content,
  inlineAttachments,
  standardAttachments,
  isPreviousMessageContentEdited,
  isSendingDraft,
  isAttachmentsUploadInProgress,
  onUpdateError,
}: IDraftUpdateProps) => {
  const [draftLastModifiedAt, setDraftLastModifiedAt] = React.useState<number>(Date.now())
  const [isDraftUpdated, setIsDraftUpdated] = React.useState<boolean>(true)

  const updateDraftCallbackRef = React.useRef<UpdateDraftCallback>()
  const updateDraftOnUnmountCallbackRef = React.useRef<UpdateDraftCallback>()
  const isDraftMount = useIsMount()

  const { updateDraftUseCase, isUpdatingDraft, hasErrorOnSaving, errorReason } = useUpdateDraftUseCase({
    onError: onUpdateError,
  })
  const draftCacheActions = useDraftCacheActions()

  React.useEffect(() => {
    // skip update on draft mounting
    if (isDraftMount) {
      return
    }

    const currentTimestampInMilliseconds = Date.now()
    const currentTimestampInSeconds = Math.floor(currentTimestampInMilliseconds / 1000)

    const updateDraftOnNewChanges = (draft: IDraftMessage) => {
      return { ...draft, isSyncedWithProvider: false, draftVersion: currentTimestampInSeconds }
    }
    draftCacheActions.updateData({ draftId, draftModifier: updateDraftOnNewChanges })

    setIsDraftUpdated(false)
    setDraftLastModifiedAt(currentTimestampInMilliseconds)
  }, [
    toRecipients,
    bccRecipients,
    content,
    inlineAttachments,
    standardAttachments,
    isPreviousMessageContentEdited,
    draftId,
  ])

  const draftUpdateProcessor = React.useCallback(
    (
      { forceDraftUpdate, scheduleSyncingWithProvider }: IUpdateDraftCallbackProps | undefined = {
        forceDraftUpdate: false,
        scheduleSyncingWithProvider: true,
      }
    ) => {
      if (isSendingDraft || isAttachmentsUploadInProgress) return

      const millisecondAfterLastDraftChange = Date.now() - draftLastModifiedAt
      const isTimeToAccumulateChangesPassed =
        millisecondAfterLastDraftChange > TIME_TO_ACCUMULATE_DRAFT_CHANGES_IN_MILLISECONDS
      const isDraftEligibleForUpdate = (!isDraftUpdated && isTimeToAccumulateChangesPassed) || forceDraftUpdate

      if (!isDraftEligibleForUpdate) return

      const draft = draftCacheActions.getData(draftId)
      if (!draft) return
      const draftVersion = draft.draftVersion

      // optimistically switching to capture new changes
      setIsDraftUpdated(true)

      updateDraftUseCase({
        scheduleSyncingWithProvider,
        draftId,
        draftVersion,
        toRecipients,
        bccRecipients,
        content,
        inlineAttachments,
        standardAttachments,
        isPreviousMessageContentEdited,
        repliedOrForwardedMessageId,
        threadId,
      })
    },
    [
      draftLastModifiedAt,
      isDraftUpdated,
      updateDraftUseCase,
      draftId,
      toRecipients,
      bccRecipients,
      content,
      inlineAttachments,
      standardAttachments,
      isPreviousMessageContentEdited,
      repliedOrForwardedMessageId,
      threadId,
      isSendingDraft,
      isAttachmentsUploadInProgress,
    ]
  )

  React.useEffect(() => {
    updateDraftCallbackRef.current = draftUpdateProcessor
  }, [draftUpdateProcessor])

  const updateDraftOnUnmount = React.useCallback(() => {
    const draft = draftCacheActions.getData(draftId)

    const isDraftExists = draft !== undefined

    // The draft is unmounted in three cases: the thread was changed, the draft was deleted, or sent.
    // If the draft was deleted or sent, it already doesn't exist in the cache, and we don't want to update it.
    // If the thread was changed, the draft is still in the cache, and we want to update it.
    if (isDraftExists) draftUpdateProcessor({ forceDraftUpdate: true, scheduleSyncingWithProvider: true })
  }, [draftUpdateProcessor, draftId])

  React.useEffect(() => {
    updateDraftOnUnmountCallbackRef.current = updateDraftOnUnmount
  }, [updateDraftOnUnmount])

  // updating draft on unmount
  React.useEffect(() => {
    return () => {
      updateDraftOnUnmountCallbackRef.current?.()
    }
  }, [])

  // starting the updates checking loop
  React.useEffect(() => {
    const checkDraftChangesAndUpdate = () => {
      if (updateDraftCallbackRef.current) {
        updateDraftCallbackRef.current()
      }
    }
    const id = setInterval(checkDraftChangesAndUpdate, TIME_TO_ACCUMULATE_DRAFT_CHANGES_IN_MILLISECONDS)
    return () => clearInterval(id)
  }, [])

  const onForcedUpdate = () => {
    draftUpdateProcessor({ forceDraftUpdate: true, scheduleSyncingWithProvider: false })
  }

  return {
    isUpdatingDraft,
    hasErrorOnSaving,
    errorReason,
    onForcedUpdate,
  }
}
