import * as React from 'react'
import ActivityItem from './activityItem'
import { DateDivider } from './dateDivider'
import { useActivities } from './useActivities'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { groupBy } from 'lodash'
import { DateTimeDataProcessor } from '../../../dataProcessors/dateTime'
import { IActivityItem } from '../../../model/messageActivity'
import BeatProgress from 'component/beatProgress'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'

const VIEWPORT_MARGIN = 24

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      overflowY: 'auto',
      marginBottom: 80,
    },

    verticalLine: {
      height: 'auto',
      width: '2px',
      marginLeft: 79,
      marginTop: 45,
      marginRight: -79,
      backgroundColor: theme.threadPalette.lightishGray,
    },

    divider: {
      marginTop: 35,
      marginBottom: 20,
      color: theme.threadPalette.lightGray,
    },

    dateDivider: {
      marginBottom: `${VIEWPORT_MARGIN}px`,
    },

    loadingContainer: {
      marginTop: '50%',
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

interface IActivityHistoryProps {
  opportunityOnly?: boolean
}

const ActivityHistory = ({ opportunityOnly }: IActivityHistoryProps) => {
  const classes = useStyles()
  const { currentThread } = useCurrentThreadContext()
  const { activitiesState, elementRef } = useActivities(currentThread ?? undefined)
  const groupedActivities = activitiesState.activities
    ? groupBy(activitiesState.activities, (activity: IActivityItem) => {
        const createdAt = DateTimeDataProcessor(activity.startPeriod)
        return createdAt.toISODate()
      })
    : {}

  const activityDates = Object.keys(groupedActivities).sort((one, two) => (one > two ? -1 : 1))

  if (!activitiesState) {
    return <BeatProgress />
  }

  return (
    <div className={classes.root} ref={elementRef}>
      {activityDates?.map((date: string) => {
        const result = [
          <DateDivider key={date} className={classes.dateDivider} date={date} />,
          ...groupedActivities[date]
            .sort((one, two) => (one.startPeriod > two.startPeriod ? -1 : 1))
            .map((item: IActivityItem) => {
              return <ActivityItem key={item.id} activity={item} />
            }),
        ]
        return result
      })}
    </div>
  )
}

export default ActivityHistory
