import { useMutation } from '@tanstack/react-query'
import { completeOnboarding as completeOnboardingApiCall } from 'api/v2/onboarding'
import { useUserOnboardingsCacheActions } from 'hooks/userOnboarding/useUserOnboardingsCacheActions'

export const useCompleteOnboardingUseCase = () => {
  const userOnboardingsCacheActions = useUserOnboardingsCacheActions()

  const { mutateAsync: runCompleteOnboardingMutation, reset: resetCompleteOnboardingMutation } = useMutation(
    completeOnboardingApiCall,
    {
      onMutate: onboardingId =>
        userOnboardingsCacheActions.updateUserOnboarding({
          onboardingId,
          onboardingModifier: onboarding => ({ ...onboarding, isCompleted: true }),
        }),
      onError: (_, __, rollbackOptimisticUpdate) => rollbackOptimisticUpdate?.(),
      onSettled: () => {
        userOnboardingsCacheActions.refreshData()
        resetCompleteOnboardingMutation()
      },
    }
  )

  const completeOnboardingUseCase: typeof completeOnboardingApiCall = runCompleteOnboardingMutation

  return { completeOnboardingUseCase }
}
