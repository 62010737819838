import React from 'react'

import { Theme, makeStyles } from '@material-ui/core'
import { BaseHeaderWithActions } from 'component/baseHeaderWithActions'
import { useDispatch } from 'react-redux'
import { push } from 'actions/navigation'
import { ReturnToPreviousPageArrow } from 'component/returnToPreviousPageArrow'
import UserAvatar from 'component/userAvatar'
import { IProject } from 'model/project'
import { ArchiveUnarchiveProjectButton } from 'component/sharingGroup/common/projectArchivedStateBadge/archiveUnarchiveProjectButton'
import { useProjectsTreeContext } from 'component/projectsTree/projectsTreeContext'
import { UpdateParentProjectDialog } from 'component/projectsTree/updateParentProjectDialog'
import { useProjectsList } from 'hooks/useProjects/useProjectsList'
import { ProjectActionsButtonWithModal } from './projectActionsButtonWithModal'

const useProjectDetailsPageHeaderStyles = makeStyles((theme: Theme) => ({
  projectHeaderRoot: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    gap: 20,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 20,
    },
  },
  projectBaseInfo: {
    flexGrow: 1,
  },
  ownerAvatarContainer: {
    alignItems: 'center',
    color: theme.threadPalette.darkGray,
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    fontWeight: 500,
    gap: 2,
    paddingTop: 12,
  },
  actionIcon: {
    color: 'inherit',
    cursor: 'pointer',
    opacity: 0.7,
  },
  projectAction: {
    color: theme.threadPalette.darkGray,
    fontSize: 14,
    fontWeight: 500,
    gap: 12,
    '&:hover': {
      color: theme.threadPalette.skyBlue,
      backgroundColor: theme.threadPalette.backgroundColor,
    },
  },
  moveAction: {
    transform: 'rotateX(180deg)',
  },
  copyAction: {
    cursor: 'copy',
  },
  tooltip: {
    fontSize: 12,
  },
}))

interface ProjectDetailsPageHeaderProps {
  project?: IProject
  currentUser: IUser
}
export const ProjectDetailsPageHeader = ({ project, currentUser }: ProjectDetailsPageHeaderProps) => {
  const classes = useProjectDetailsPageHeaderStyles()
  const dispatch = useDispatch()

  const { projectsList } = useProjectsList({
    pageSize: 50,
    isFetchingEnabled: !!project?.id,
    parentProject: null,
  })

  const { openProjectDetails } = useProjectsTreeContext()

  const onReturnToPreviousPage = () =>
    project?.parentProjectId ? openProjectDetails(project.parentProjectId) : dispatch(push('/projects'))

  const projectActions = [
    <ProjectActionsButtonWithModal
      project={project}
      actionsToHide={['createNestedProject']}
      onProjectDeleted={onReturnToPreviousPage}
    />,
    <UpdateParentProjectDialog projects={projectsList} />,
  ]
  return (
    <BaseHeaderWithActions actions={projectActions}>
      <div className={classes.projectHeaderRoot}>
        <ReturnToPreviousPageArrow onClick={onReturnToPreviousPage} />
        <div className={classes.ownerAvatarContainer}>
          <UserAvatar user={currentUser} />
          Owner
        </div>
        {project && <ArchiveUnarchiveProjectButton project={project} />}
      </div>
    </BaseHeaderWithActions>
  )
}
