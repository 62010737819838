import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import Spacer from 'component/layoutUtils/spacer'
import LinkIcon from '@material-ui/icons/Link'
import { useCopyContentToClipboard } from 'hooks/useCopyContentToClipboard'

const useCopyLinkButtonStyles = makeStyles((theme: Theme) => {
  return {
    copyLinkButtonRoot: {
      alignItems: 'center',
      borderColor: theme.palette.primary.main,
      cursor: 'copy',
      display: 'flex',
      justifyContent: 'center',
      gap: 6,
      paddingBlock: 4,
      paddingInline: 8,
    },
    buttonLabel: {
      color: theme.palette.primary.main,
      fontSize: 14,
    },
    linkIcon: {
      fill: theme.palette.primary.main,
      height: 16,
      width: 16,
    },
  }
})

interface ICopyLinkButtonProps {
  id?: string
  link: string
  successSnackbarMessage?: string
  outlined?: boolean
}

export const CopyLinkButton = ({ id, link, successSnackbarMessage, outlined = false }: ICopyLinkButtonProps) => {
  const { copyContentToClipboard } = useCopyContentToClipboard({ messageOnCopy: successSnackbarMessage })
  const classes = useCopyLinkButtonStyles()

  const onClick = () => copyContentToClipboard(link)

  return (
    <Button
      id={id}
      variant={outlined ? 'outlined' : 'text'}
      classes={{ root: classes.copyLinkButtonRoot }}
      onClick={onClick}
    >
      <LinkIcon className={classes.linkIcon} />
      <Spacer width={4} />
      <Typography className={classes.buttonLabel} noWrap>
        Copy Link
      </Typography>
    </Button>
  )
}
