import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Theme,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  ListItemSecondaryAction,
  IconButton,
  fade,
} from '@material-ui/core'
import UnlinkIcon from '@material-ui/icons/LinkOff'
import { useTextInEllipsis } from 'hooks/useTextInEllipsis'
import ProjectEmoji from 'features/project/projectEmoji'

const useProjectItemStyles = makeStyles((theme: Theme) => ({
  projectListItem: {
    borderBottomRightRadius: 0,
    borderBottomWidth: 0.3,
    borderImage: `linear-gradient(to right,transparent 15%,${theme.threadPalette.brownishGray} 15%) 1`,
    color: theme.threadPalette.white,
    paddingLeft: 12,
    '&:hover': {
      backgroundColor: fade(theme.threadPalette.white, 0.1),
    },
  },
  folderIconContainer: {
    marginRight: 14,
    minWidth: 'unset',
  },
  folderIcon: {
    color: theme.threadPalette.white,
  },
  projectName: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  projectUnlinkIconContainer: {
    color: theme.threadPalette.white,
    '&:hover': {
      backgroundColor: theme.threadPalette.lighterGray,
      color: theme.palette.error.contrastText,
    },
  },
  projectUnlinkIcon: {
    fontSize: 16,
  },
}))

interface IProjectItemProps {
  name: string
  isConnected: boolean
  emoji?: string
  onSelect?(): void
  onUnlink?(): void
}

function ProjectItem({ name, emoji, onSelect, isConnected, onUnlink }: IProjectItemProps) {
  const classes = useProjectItemStyles()
  const { textRef: projectNameRef, hasEllipsis: showTooltip } = useTextInEllipsis()
  return (
    <ListItem button disableGutters divider onClick={onSelect} classes={{ root: classes.projectListItem }}>
      <ListItemIcon classes={{ root: classes.folderIconContainer }}>
        <ProjectEmoji emoji={emoji} className={classes.folderIcon} />
      </ListItemIcon>
      <Tooltip title={showTooltip ? name : ''} arrow={true} aria-label="project name">
        <ListItemText
          ref={projectNameRef}
          primary={name}
          classes={{ root: classes.projectName }}
          primaryTypographyProps={{ noWrap: true }}
          disableTypography
        />
      </Tooltip>
      {isConnected && (
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={onUnlink} classes={{ root: classes.projectUnlinkIconContainer }}>
            <UnlinkIcon className={classes.projectUnlinkIcon} />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}

export default ProjectItem
