import * as React from 'react'
import OutlookBtn from 'assets/signin_button_outlook365.png'

interface IOutlookIconProps {
  className?: string
}

const OutlookIcon = (props: IOutlookIconProps) => {
  return <img {...props} src={OutlookBtn} alt="" />
}

export default OutlookIcon
