import avatar1 from 'icons/avatar/avatar-normal-geometric-1.svg'
import avatar2 from 'icons/avatar/avatar-normal-geometric-2.svg'
import avatar3 from 'icons/avatar/avatar-normal-geometric-3.svg'
import avatar4 from 'icons/avatar/avatar-normal-geometric-4.svg'
import avatar5 from 'icons/avatar/avatar-normal-geometric-5.svg'
import avatar6 from 'icons/avatar/avatar-normal-geometric-6.svg'
import avatar7 from 'icons/avatar/avatar-normal-geometric-7.svg'
import avatar8 from 'icons/avatar/avatar-normal-geometric-8.svg'
import avatar9 from 'icons/avatar/avatar-normal-geometric-9.svg'
import avatar10 from 'icons/avatar/avatar-normal-geometric-10.svg'
import avatar11 from 'icons/avatar/avatar-normal-geometric-11.svg'
import avatar12 from 'icons/avatar/avatar-normal-geometric-12.svg'
import avatar13 from 'icons/avatar/avatar-normal-geometric-13.svg'
import avatar14 from 'icons/avatar/avatar-normal-geometric-14.svg'
import avatar15 from 'icons/avatar/avatar-normal-geometric-15.svg'
import avatar16 from 'icons/avatar/avatar-normal-geometric-16.svg'
import avatar17 from 'icons/avatar/avatar-normal-geometric-17.svg'
import avatar18 from 'icons/avatar/avatar-normal-geometric-18.svg'
import avatar19 from 'icons/avatar/avatar-normal-geometric-19.svg'
import avatar20 from 'icons/avatar/avatar-normal-geometric-20.svg'
import avatar21 from 'icons/avatar/avatar-normal-geometric-21.svg'

const AVATARS = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
  avatar16,
  avatar17,
  avatar18,
  avatar19,
  avatar20,
  avatar21,
]

export function useThreadAvatar(threadId: string) {
  const uuidAsNumber = [...threadId]
    .map(char => char.charCodeAt(0))
    .reduce(function (a, b) {
      return a + b
    })

  return getAvatarFromNumberInput(AVATARS, uuidAsNumber)
}

export const getAvatarFromNumberInput = (avatars: string[], numberInput: number) => {
  const avatarId = numberInput % avatars.length
  const avatar = avatars[avatarId]
  return avatar
}
