import { Divider, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import BigCloseIcon from 'icons/bigCloseIcon'
import * as React from 'react'
import { ConnectEmailAccountDialogContext } from './connectEmailAccountDialog'
import ImgSigninButtonGoogle from '../../assets/signin_button_google.png'
import ImgSigninButtonOutlook365 from '../../assets/signin_button_outlook365.png'
import { push } from 'actions/navigation'
import { useDispatch } from 'react-redux'
import { SigninButton } from './connectEmailAccountSigninButton'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '36px',
    minWidth: '600px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 23,
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    '& svg': {
      fill: theme.threadPalette.lightGray,
    },
    '&:hover': {
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
  },
  content: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  signinButton: {
    maxWidth: 500,
    width: '100%',
    marginLeft: 10,
    marginRight: 10,
  },
  dividerContainer: {
    marginTop: 40,
    paddingLeft: 20,
    paddingRight: 20,
    position: 'relative',
    width: '100%',
    height: 50,
  },
  divider: {
    position: 'relative',
    color: theme.threadPalette.lightGray,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 15,
    marginTop: 15,
  },
  dividerTitle: {
    backgroundColor: theme.threadPalette.white,
    color: theme.threadPalette.lightGray,
    fontSize: 21,
    position: 'relative',
    left: '43%',
    bottom: '61%',
    width: 55,
  },
}))

// the content of the ConnectEmailAccountDialog component
export const ConnectEmailAccount = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { setIsOpen } = React.useContext(ConnectEmailAccountDialogContext)

  const onGoogleSigninButtonClick = React.useCallback(
    (event: React.SyntheticEvent) => {
      dispatch(push('/oauth/google/login'))
    },
    [dispatch]
  )

  const onOutlook365SigninButtonClick = React.useCallback(
    (event: React.SyntheticEvent) => {
      dispatch(push('/oauth/outlook365/login'))
    },
    [dispatch]
  )

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.title}>Connect your email account</Typography>
        <div className={classes.closeButton} onClick={() => setIsOpen && setIsOpen(false)}>
          <BigCloseIcon />
        </div>
      </div>
      <div className={classes.content}>
        <SigninButton
          dataCy={'outlook-signin'}
          className={classes.signinButton}
          icon={ImgSigninButtonOutlook365}
          text="Connect with Outlook"
          onClick={onOutlook365SigninButtonClick}
        />

        <div className={classes.dividerContainer}>
          <Divider className={classes.divider} />
          <Typography align="center" className={classes.dividerTitle}>
            or
          </Typography>
        </div>

        <SigninButton
          dataCy={'google-signin'}
          className={classes.signinButton}
          icon={ImgSigninButtonGoogle}
          text="Connect with Google"
          onClick={onGoogleSigninButtonClick}
        />
      </div>
    </div>
  )
}
