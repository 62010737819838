import { useThreadActions } from 'hooks/useThreads/useThreadActions'

interface IRemoveThreadUseCaseReturn {
  removedThread?: IThread
  rollbackOptimisticUpdates?: () => void
}

export const useRemoveThreadUseCase = () => {
  const { getThreadsListActiveData, removeThreadFromThreadsList, setThreadsListData } = useThreadActions()

  const removeThreadUseCase = (threadId: string): IRemoveThreadUseCaseReturn => {
    const threadsListActiveData = getThreadsListActiveData()

    if (!threadsListActiveData) return { removedThread: undefined, rollbackOptimisticUpdates: undefined }

    const { data: activeThreadsList } = threadsListActiveData

    const removedThread = removeThreadFromThreadsList(threadId, activeThreadsList)

    return {
      removedThread,
      rollbackOptimisticUpdates: () => {
        setThreadsListData(threadsListActiveData.data)
      },
    }
  }

  return {
    removeThreadUseCase,
  }
}
