import * as React from 'react'
import { SvgIcon } from '@material-ui/core'

interface IUnderlineIconProps extends JSX.IntrinsicAttributes {
  className?: string
}

const UnderlineIcon = ({ className }: IUnderlineIconProps) => {
  return (
    <SvgIcon className={className} viewBox="-3 -3 17 17">
      <path
        fill="#606060"
        fillRule="evenodd"
        d="M.2 12.25h10.548V14H.2v-1.75zM7.232 0v.876c.486 0 .879.391.879.875V7c0 1.449-1.181 2.625-2.637 2.625C4.017 9.626 2.837 8.45 2.837 7V1.75c0-.483.393-.875.879-.875V0H.2v.876c.486 0 .879.391.879.875V7c0 2.415 1.968 4.374 4.395 4.374 2.427 0 4.395-1.959 4.395-4.374V1.75c0-.483.393-.875.879-.875V0H7.232z"
      />
    </SvgIcon>
  )
}

export default UnderlineIcon
