import { isLoggedIn } from '@jetkit/react'
import { push } from 'actions/navigation'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { checkAuth } from './actions/checkAuth'
import AppLoading from './component/appLoading'
import { selectChecked as selectAuthChecked } from './selectors/auth'

interface ICheckAuthProviderProps {}

const CheckAuthProvider: React.FC<ICheckAuthProviderProps> = props => {
  const dispatch = useDispatch()
  const authChecked: boolean = useSelector(selectAuthChecked)
  const loggedIn = isLoggedIn()

  React.useEffect(() => {
    if (loggedIn) {
      dispatch(checkAuth())
    } else {
      dispatch(push('/signin'))
    }
  }, [dispatch, loggedIn])

  if (!authChecked) {
    return <AppLoading visible={true} />
  }

  return <React.Fragment>{props.children}</React.Fragment>
}

export default CheckAuthProvider
