import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface INumberedListIconProps extends JSX.IntrinsicAttributes {
  className?: string
}

const NumberedListIcon = ({ className }: INumberedListIconProps) => {
  return (
    <SvgIcon className={className} viewBox="0 -3 18 18">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#606060"
          d="M12.867 5.37H5.846c-.723 0-1.306.582-1.306 1.305S5.123 7.98 5.846 7.98h7.021c.723 0 1.306-.583 1.306-1.306 0-.723-.584-1.306-1.306-1.306zM5.846 2.7h7.021c.723 0 1.306-.583 1.306-1.306 0-.722-.583-1.305-1.306-1.305H5.846c-.723 0-1.306.583-1.306 1.305 0 .723.583 1.306 1.306 1.306zM12.867 10.592H5.846c-.723 0-1.306.583-1.306 1.305 0 .723.583 1.306 1.306 1.306h7.021c.723 0 1.306-.583 1.306-1.306 0-.722-.584-1.305-1.306-1.305z"
          transform="translate(.59)"
        />
        <path
          fill="#606060"
          fillRule="nonzero"
          d="M1.611 3.121L1.611 0 .193.219.193.728.889.728.889 3.121zM2.258 8.177V7.62H1.042l-.007-.013.422-.452c.274-.298.462-.523.565-.675.102-.151.153-.325.153-.523 0-.292-.096-.523-.288-.691-.192-.169-.46-.253-.803-.253-.322 0-.582.1-.777.3-.196.201-.29.446-.283.734l.004.013h.707c0-.143.031-.26.093-.352.062-.092.148-.138.256-.138.122 0 .213.035.273.107.061.071.092.167.092.287 0 .084-.03.183-.09.298-.06.114-.154.244-.281.39L.075 7.706v.47h2.183zM1.086 13.277c.34 0 .62-.081.838-.245.219-.163.328-.388.328-.674 0-.174-.047-.323-.141-.448-.095-.125-.225-.219-.393-.28.148-.067.265-.16.353-.28.088-.119.132-.25.132-.39 0-.285-.102-.504-.305-.66-.202-.154-.473-.232-.812-.232-.29 0-.539.077-.743.232-.204.154-.302.355-.293.604l.004.013h.703c0-.086.035-.156.107-.21.071-.055.154-.082.248-.082.12 0 .212.034.274.101.063.067.095.15.095.248 0 .125-.035.222-.105.292-.07.07-.17.105-.3.105h-.34v.538h.34c.144 0 .256.034.335.103.08.07.119.18.119.331 0 .109-.038.199-.114.27-.075.072-.177.107-.304.107-.111 0-.206-.033-.284-.101-.077-.068-.116-.152-.116-.252H.005L0 12.38c-.007.291.1.514.318.667.22.154.476.23.768.23z"
          transform="translate(.59)"
        />
      </g>
    </SvgIcon>
  )
}

export default NumberedListIcon
