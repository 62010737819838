export const selectFilterThreadsByStarred = (state: IState) => state.leftSidebarThreadsViewSettings.filterByStarred

export const selectFilterThreadsByUnread = (state: IState) => state.leftSidebarThreadsViewSettings.filterByUnread

export const selectFilterThreadsByFollowed = (state: IState) => state.leftSidebarThreadsViewSettings.filterByFollowed

export const selectFilterThreadsByUnfollowed = (state: IState) =>
  state.leftSidebarThreadsViewSettings.filterByUnfollowed

export const selectFilterThreadsByViewed = (state: IState) => state.leftSidebarThreadsViewSettings.filterByViewed
