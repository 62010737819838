import React from 'react'
import { Theme, Typography, makeStyles } from '@material-ui/core'

const useBasePageHeaderStyles = makeStyles((theme: Theme) => ({
  title: {
    alignItems: 'center',
    color: theme.threadPalette.brownishGray,
    display: 'flex',
    fontSize: 32,
    fontWeight: 500,
    gap: 4,
    justifyContent: 'center',
    paddingInline: 16,
    [theme.breakpoints.up('lg')]: {
      fontSize: 50,
      paddingInline: 30,
    },
  },
}))

interface BasePageHeaderProps {
  title: string | JSX.Element
}

export const BasePageHeader = ({ title }: BasePageHeaderProps) => {
  const classes = useBasePageHeaderStyles()
  return <Typography className={classes.title}>{title}</Typography>
}
