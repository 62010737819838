import { useQuery } from '@tanstack/react-query'
import { IThreadSharingSettings } from 'model/sharingGroup'
import { getThreadSharingSettingsQueryKey } from './useThreadSharingSettingsCacheActions'
import { getSharingSettingsForThread } from 'api/v2/thread'
import { useUser } from 'hooks/auth'

const TWO_MINUTES_IN_MILISECONDS = 2 * 60 * 1000

export const useThreadSharingSettings = (threadId: string) => {
  const currentUser = useUser()

  const { data: threadSharingSettingsList = [], isLoading } = useQuery<IThreadSharingSettings[] | undefined, Error>({
    enabled: !!threadId && !!currentUser?.id,
    queryKey: getThreadSharingSettingsQueryKey({ userId: currentUser?.id ?? '', threadId }),
    queryFn: () => getSharingSettingsForThread(threadId),
    staleTime: TWO_MINUTES_IN_MILISECONDS,
  })

  return { threadSharingSettingsList, isLoading }
}
