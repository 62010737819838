import React from 'react'
import { IOnboardingName } from 'model/onboarding'
import { useThreadOnboardingSteps } from './tours/useThreadOnboardingSteps'
import { useOnboardingTourContext } from 'contexts/useOnboardingTourContext'
import { useCompleteOnboardingUseCase } from 'usecases/onboarding/completeOnboarding'
import { useUserOnboardings } from 'hooks/userOnboarding/useUserOnboardings'
import { useSharedThreadOnboardingSteps } from './tours/useSharedThreadOnboardingSteps'
import { useShareThreadOnboardingSteps } from './tours/useShareThreadOnboardingSteps'

export type ISupportedOnboardingName = Extract<IOnboardingName, 'thread' | 'shared_thread' | 'share_thread'>

export const useOnboardingTour = () => {
  const {
    isRunning: isRunningOnboardingTour,
    startOnboardingTour: startTour,
    postponedOnboardingTours,
    setPostponedOnboardingTours,
  } = useOnboardingTourContext()

  // using a ref to call the correct version of onOnboardingCompleted
  const onOnboardingClosedRef = React.useRef<(name: IOnboardingName) => void>()

  const { userOnboardings, isLoading: isLoadingUserOnboardings } = useUserOnboardings()
  const { completeOnboardingUseCase } = useCompleteOnboardingUseCase()

  const { getSteps: getThreadOnboardingSteps } = useThreadOnboardingSteps()
  const { getSteps: getSharedThreadOnboardingSteps } = useSharedThreadOnboardingSteps()
  const { getSteps: getShareThreadOnboardingSteps } = useShareThreadOnboardingSteps()

  const onboardingNameToStepsGetterMapping = {
    thread: getThreadOnboardingSteps,
    share_thread: getShareThreadOnboardingSteps,
    shared_thread: getSharedThreadOnboardingSteps,
  }

  const getOnboardingByName = (name: IOnboardingName) => userOnboardings.find(onboarding => onboarding.name === name)

  const isOnboardingCompleted = (name: IOnboardingName) => {
    const onboarding = getOnboardingByName(name)
    return !!onboarding?.isCompleted
  }

  const onOnboardingClosed = React.useCallback(
    (name: IOnboardingName) => {
      const onboarding = getOnboardingByName(name)
      if (onboarding && !onboarding.isCompleted) completeOnboardingUseCase(onboarding.id)
    },
    [userOnboardings, completeOnboardingUseCase, getOnboardingByName]
  )

  React.useEffect(() => {
    onOnboardingClosedRef.current = onOnboardingClosed
  }, [onOnboardingClosed])

  React.useEffect(() => {
    const hasPostponedOnboardingTour = postponedOnboardingTours.length > 0
    const canTriggerPostponedOnboardingTour = !isLoadingUserOnboardings && !isRunningOnboardingTour

    if (!hasPostponedOnboardingTour || !canTriggerPostponedOnboardingTour) return

    const { name, props } = postponedOnboardingTours[0]
    startOnboardingTour(name, props)
  }, [isLoadingUserOnboardings, postponedOnboardingTours, isRunningOnboardingTour])

  const postponedOnboardingTour = ({ name, props }: { name: ISupportedOnboardingName; props: any }) =>
    setPostponedOnboardingTours(prev => {
      const isAlreadyPostponed = prev.some(postponedOnboardingTour => postponedOnboardingTour.name === name)
      if (isAlreadyPostponed) return prev
      return [...prev, { name, props }]
    })

  const removePostponedOnboardingTour = (name: ISupportedOnboardingName) =>
    setPostponedOnboardingTours(prev => prev.filter(postponedOnboardingTour => postponedOnboardingTour.name !== name))

  const startOnboardingTour = <T extends ISupportedOnboardingName>(
    name: T,
    props: Parameters<(typeof onboardingNameToStepsGetterMapping)[T]>[0] = {}
  ): boolean => {
    if (isOnboardingCompleted(name)) return false

    if (isLoadingUserOnboardings || isRunningOnboardingTour) {
      postponedOnboardingTour({ name, props })
      return true
    }

    const stepsGetter = onboardingNameToStepsGetterMapping[name]
    const onTourClosed = () => onOnboardingClosedRef.current?.(name)

    const steps = stepsGetter(props)

    removePostponedOnboardingTour(name)
    startTour({ steps, onTourClosed })

    return true
  }

  return {
    startOnboardingTour,
  }
}
