import * as React from 'react'

function MessagesIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
        <filter id="prefix__b">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g filter="url(#prefix__a)" transform="translate(-19 -14)" fill="none" fillRule="evenodd">
        <g filter="url(#prefix__b)" transform="translate(19 14)">
          <path
            d="M10.986 0c6.057 0 10.986 4.321 10.986 9.631 0 2.264-.904 4.445-2.553 6.174v5.41a.785.785 0 01-1.18.681l-4.906-2.851c-.77.147-1.558.22-2.347.22C4.93 19.266 0 14.946 0 9.634 0 4.32 4.93 0 10.986 0zm1.796 11H7.085a.785.785 0 100 1.571h5.697a.784.784 0 100-1.571zm2.553-3.536h-8.25a.786.786 0 000 1.572h8.25a.786.786 0 000-1.572z"
            fill="#000"
          />
        </g>
      </g>
    </svg>
  )
}

export default MessagesIcon
