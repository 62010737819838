import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import { UserAccessCheckWrapper } from 'lib/accessCheck'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) => {
  return {
    item: {
      color: theme.threadPalette.menuItem.normal,
      fontSize: '12px',
      lineHeight: '24px',
      padding: '0px 10px',
      '&:hover': {
        background: theme.threadPalette.white,
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
    },
    currentItem: {
      color: theme.threadPalette.menuItem.current,
      fontWeight: 'bold',
    },
    icon: {
      fontSize: '15px',
      marginRight: '5px',
    },
    label: {
      fontSize: 9,
      fontWeight: 500,
      marginLeft: 12,
      color: theme.threadPalette.brownishGray,
    },
  }
})

export interface IMenuItem<TValue> {
  value: TValue
  label: string
  IconComponent?: React.FC<any>
  iconClasses?: string
  requiredPermissions?: TUserPermissions[]
  isEnabled?: boolean
}

interface IMenuProps extends MenuProps {
  activeValue?: any
  items: Array<IMenuItem<any>>
  onSelect: (value: any) => void
  customClasses?: Partial<Record<'item' | 'currentItem', string>>
  label?: string
}

const OptionsMenu = ({ activeValue, items, onSelect, customClasses, label, open, ...menuProps }: IMenuProps) => {
  const classes = useStyles()
  const { item: itemClass = classes.item, currentItem: currentItemClass = classes.currentItem } = customClasses ?? {}

  const onMenuItemClickFactory = (value: any) => {
    return (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.stopPropagation()
      e.nativeEvent.stopPropagation()
      onSelect(value)
    }
  }
  const allowedItems = items.filter(({ isEnabled = true }) => isEnabled)
  return (
    <Menu anchorEl={menuProps.anchorEl} open={open} onClose={menuProps.onClose} {...menuProps}>
      <div>
        {label && <Typography className={classes.label}>{label}</Typography>}
        {allowedItems.map(item => {
          const { value, label, IconComponent, iconClasses, requiredPermissions = [] } = item
          const className = classnames(itemClass, { [currentItemClass]: value === activeValue })

          return (
            <UserAccessCheckWrapper key={value} requiredPermissions={requiredPermissions} notAllowedComponent={null}>
              <MenuItem className={className} onClick={onMenuItemClickFactory(value)}>
                {IconComponent && <IconComponent className={classnames(classes.icon, iconClasses && iconClasses)} />}{' '}
                {label}
              </MenuItem>
            </UserAccessCheckWrapper>
          )
        })}
      </div>
    </Menu>
  )
}

export default OptionsMenu
