import * as React from 'react'
import { useIconStyles } from '../styles'
import classnames from 'classnames'

interface IDocIconProps extends JSX.IntrinsicAttributes {
  width?: number
  height?: number
  className?: string
  fill?: string
}

const DocIcon = ({ className, width, height, fill }: IDocIconProps) => {
  const classes = useIconStyles()
  return (
    <div className={classnames(className, classes.iconWrapper)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : '20'}
        height={height ? height : '20'}
        viewBox="0 0 20 20"
      >
        <path
          fill={fill ? fill : '#27549C'}
          d="M17.268 19.714c.283 0 .523-.099.72-.297.199-.198.298-.438.298-.72V6.481c0-.283-.071-.594-.212-.933-.142-.34-.311-.608-.51-.806l-3.307-3.308c-.198-.198-.467-.367-.806-.509-.34-.141-.65-.212-.933-.212h-9.5c-.283 0-.523.1-.721.297C2.099 1.21 2 1.45 2 1.732v16.964c0 .283.099.523.297.721.198.198.438.297.72.297h14.25zm-.34-1.357H3.359V2.071H11.5v4.411c0 .283.099.523.297.721.198.198.438.297.72.297h4.412v10.857zm-.084-12.214h-3.987V2.156c.205.07.35.149.435.234l3.318 3.318c.085.085.163.23.234.435zM8.637 17l1.357-5.142c.05-.142.085-.304.106-.488.015-.113.022-.198.022-.254h.042l.032.254c.007.021.02.092.037.212.018.12.037.212.058.276L11.648 17h1.686l1.74-7.008h.741V8.857h-3.18v1.135h.954l-1.05 4.644c-.035.141-.06.304-.074.487l-.021.223H12.4l-.032-.223-.042-.222c-.021-.113-.039-.202-.053-.265l-1.527-5.779H9.54l-1.527 5.779c-.014.063-.03.15-.048.26l-.037.227-.043.223h-.042l-.021-.223c-.014-.183-.039-.346-.074-.487l-1.05-4.644h.954V8.857h-3.18v1.135h.742L6.95 17h1.686z"
        />
      </svg>
    </div>
  )
}

export default DocIcon
