import * as React from 'react'

export default function useBackListener(callback: (this: Window, ev: PopStateEvent) => void) {
  React.useEffect(() => {
    window.history.pushState(null, '', window.location.pathname)
    window.addEventListener('popstate', callback)

    return () => {
      window.removeEventListener('popstate', callback)
    }
  })
}
