export const ANONYMOUS_USER: IUser = {
  id: '0',
  name: 'Anonymous0',
  email: 'anonymous0@idontexist.com',
  permissions: [],
  avatarUrl: null,
  emailProvider: 'custom',
  organization: {
    id: 'org1',
    name: 'ORGANIZATION_1',
  },
  notificationsEnabled: true,
}

export const ANONYMOUS_USER_1: IUser = {
  id: '1',
  name: 'Anonymous1',
  email: 'anonymous1@idontexist.com',
  permissions: [],
  avatarUrl: null,
  emailProvider: 'custom',
  organization: {
    id: 'org1',
    name: 'ORGANIZATION_1',
  },
}

export const ANONYMOUS_USER_2: IUser = {
  id: '2',
  name: 'Anonymous2',
  email: 'anonymous2@idontexist.com',
  permissions: [],
  avatarUrl: null,
  emailProvider: 'custom',
  organization: {
    id: 'org1',
    name: 'ORGANIZATION_1',
  },
}

export const ANONYMOUS_USER_3: IUser = {
  id: '3',
  name: 'Anonymous3',
  email: 'anonymous3@idontexist.com',
  permissions: [],
  avatarUrl: null,
  emailProvider: 'custom',
  organization: {
    id: 'org1',
    name: 'ORGANIZATION_1',
  },
}

export const ANONYMOUS_USER_DIFFERENT_ORG_ONLINE: IUser = {
  id: '4',
  name: 'Anonymous4',
  email: 'anonymous4@idontexist.com',
  permissions: [],
  avatarUrl: null,
  emailProvider: 'custom',
  organization: {
    id: 'different',
    name: 'ORGANIZATION_DIFFERENT',
  },
}

export const ANONYMOUS_USER_DIFFERENT_ORG_OFFLINE: IUser = {
  id: '5',
  name: 'Anonymous5',
  email: 'anonymous5@idontexist.com',
  permissions: [],
  avatarUrl: null,
  emailProvider: 'custom',
  organization: {
    id: 'different',
    name: 'ORGANIZATION_DIFFERENT',
  },
}
