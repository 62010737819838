import { deleteSharingGroup } from 'api/v2/sharingGroup'
import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useUserSharingGroupsCacheActions } from 'hooks/useSharingGroup/useUserSharingGroupsCacheActions'
import { useThreadSharingSettingsCacheActions } from 'hooks/useSharingGroup/useThreadSharingSettingsCacheActions'
import { showSnackbar } from 'actions/snackbar'

interface IUseDeleteSharingGroupUseCaseProps {
  threadId?: string
  onSuccess?(): void
}

export const useDeleteSharingGroupUseCase = ({ threadId, onSuccess }: IUseDeleteSharingGroupUseCaseProps = {}) => {
  const dispatch = useDispatch()

  const userSharingGroupsCacheActions = useUserSharingGroupsCacheActions()
  const threadSharingSettingsCacheActions = useThreadSharingSettingsCacheActions()

  const {
    reset: resetDeleteSharingGroupMutation,
    mutateAsync: runDeleteSharingGroupMutation,
    isLoading,
  } = useMutation(deleteSharingGroup, {
    onMutate: sharingGroupId => {
      const rollbackUserSharingGroups = userSharingGroupsCacheActions.deleteData({ id: sharingGroupId })
      const rollbackThreadsSharingSettings = threadId
        ? threadSharingSettingsCacheActions.deleteData({ threadId, sharingGroupId })
        : undefined
      const rollbackOptimisticUpdates = () => {
        rollbackUserSharingGroups?.()
        rollbackThreadsSharingSettings?.()
      }
      return rollbackOptimisticUpdates
    },
    onSuccess: onSuccess,
    onError: (_, __, rollbackOptimisticUpdates) => {
      dispatch(showSnackbar({ message: 'Failed to delete sharing group' }))
      rollbackOptimisticUpdates?.()
    },
    onSettled: () => {
      userSharingGroupsCacheActions.refreshData({ withImmediateFetching: true })
      if (threadId) threadSharingSettingsCacheActions.refreshData(threadId)

      resetDeleteSharingGroupMutation()
    },
  })

  const deleteSharingGroupUseCase: typeof deleteSharingGroup = runDeleteSharingGroupMutation

  return { deleteSharingGroupUseCase, isLoading }
}
