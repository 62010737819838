import { getNameParts } from './../../../dataProcessors/utils'
import { debounce } from 'lodash'
import * as React from 'react'
import { checkEmailStatus } from 'api/organization'
import { TEmailStatus } from './newMemberDialog'

export function useNewMemberDialog(
  onClose: () => void,
  onAddNewMember: (email: string, firstName?: string, lastName?: string) => void
) {
  const [email, setEmail] = React.useState('')
  const [emailStatus, setEmailStatus] = React.useState<TEmailStatus | undefined>()

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')

  const handleEmailChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = event.target.value
    setEmail(val)
  }, [])

  const checkEmail = React.useMemo(
    () =>
      debounce(async (email: string) => {
        if (email.length === 0) {
          setEmailStatus(undefined)
          return
        }
        const user = await checkEmailStatus(email)
        if (!user) {
          setEmailStatus('non-exists')
        } else {
          const nameParts = getNameParts(user.name)
          setFirstName(nameParts.firstName)
          setLastName(nameParts.lastName)
          if (!user.organization) {
            setEmailStatus('exists')
          } else {
            setEmailStatus('org')
          }
        }
      }, 1000),
    []
  )

  React.useEffect(() => {
    checkEmail(email)
  }, [checkEmail, email])

  const handleFirstNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const val = event.target.value
      setFirstName(val)
    },
    []
  )

  const handleLastNameChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = event.target.value
    setLastName(val)
  }, [])

  const handleAddNewMember = React.useCallback(() => {
    onAddNewMember(email, firstName, lastName)
    onClose()
    setEmail('')
    setFirstName('')
    setLastName('')
  }, [email, firstName, lastName, onAddNewMember, onClose])

  return {
    firstName,
    lastName,
    email,
    emailStatus,
    handleEmailChange,
    handleFirstNameChange,
    handleLastNameChange,
    handleAddNewMember,
  }
}
