export default class CloudConvertHandler {
  callbacks = new Map<string, (attachmentId: string) => void>()

  public addCallback = (attachmentId: string, callback: (attachmentId: string) => void) => {
    this.callbacks.set(attachmentId, callback)
  }

  public removeCallback = (attachmentId: string) => {
    this.callbacks.delete(attachmentId)
  }

  public handle = (attachmentId: string) => {
    this.callbacks.get(attachmentId)?.(attachmentId)
  }
}
