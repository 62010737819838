import React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider'
import { SearchInput } from './searchInput/searchInput'
import { getCurrentPlatform } from 'lib/diff'
import Spacer from 'component/layoutUtils/spacer'
import { ViewSwitcher } from './viewSwitcher/viewSwitcher'
import { ThreadsView } from './threadsView/threadsView'
import { ProjectsView } from './projectsView/projectsView'
import { NewThreadModal } from 'component/newThreadModal/newThreadModal'
import { NewProjectModal } from 'component/newProjectModal/newProjectModal'
import { NewProjectModalProvider } from 'component/newProjectModal/newProjectModalContext'

export const useQuickViewListStyles = makeStyles((theme: Theme) => {
  return {
    quickViewListRoot: {
      backgroundColor: theme.threadPalette.darkerBlue,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    },
    searchWrapper: {
      marginRight: 'auto',
      marginTop: '0px',
      width: 'calc(100% - 20px)',
      marginLeft: 'auto',
    },
    viewSwitcherWrapper: {
      marginRight: 'auto',
      marginTop: '0px',
      width: 'calc(100% - 30px)',
      marginLeft: 'auto',
    },
    divider: {
      backgroundColor: theme.threadPalette.lightBlueGrey,
      height: '1px',
      width: '100%',
    },
  }
})

export type TViewState = 'threads' | 'projects'

export const QuickViewList = () => {
  const classes = useQuickViewListStyles()

  const [viewState, setViewState] = React.useState<TViewState>('threads')

  const quickViewContentOptions = new Map<TViewState, JSX.Element>([
    ['threads', <ThreadsView />],
    ['projects', <ProjectsView />],
  ])

  const quickViewScreen = quickViewContentOptions.get(viewState)

  const isSearchEnabled =
    process.env.REACT_APP_LEFT_SIDEBAR_SEARCH_ENABLED && !!parseInt(process.env.REACT_APP_LEFT_SIDEBAR_SEARCH_ENABLED)

  return (
    <NewProjectModalProvider>
      <div className={classes.quickViewListRoot}>
        <Spacer height={24} />
        {isSearchEnabled && (
          <>
            <div className={classes.searchWrapper}>
              <SearchInput currentPlatform={getCurrentPlatform()} />
            </div>
            <Spacer height={24} />
          </>
        )}
        <div className={classes.viewSwitcherWrapper}>
          <Divider className={classes.divider} />
          <Spacer height={4} />
          <ViewSwitcher viewState={viewState} setViewState={setViewState} />
          <Spacer height={4} />
          <Divider className={classes.divider} />
        </div>
        <Spacer height={4} />
        {quickViewScreen}
        <NewThreadModal />
        <NewProjectModal />
      </div>
    </NewProjectModalProvider>
  )
}
