import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { fade, ListItem, Theme, Typography } from '@material-ui/core'
import AppLoading from 'component/appLoading'
import classNames from 'classnames'

const useNewProjectItemStyles = makeStyles((theme: Theme) => ({
  newProjectItem: {
    paddingLeft: 12,
    marginTop: 8,
    '&:hover': {
      backgroundColor: fade(theme.threadPalette.white, 0.1),
    },
  },
  createAction: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: 12,
    whiteSpace: 'nowrap',
  },
  projectName: {
    border: `solid 0.3px ${fade(theme.threadPalette.errorDialog, 0.5)}`,
    borderRadius: 1,
    fontSize: 14,
    marginRight: 8,
    paddingBlock: 1,
    paddingInline: 5,
  },
  listItemDivider: {
    borderBottom: 0,
  },
}))

interface INewProjectItemProps {
  name: string
  isLoading?: boolean
  onSelect?(): void
  rootClassName?: string
}

function NewProjectItem({ name, isLoading = false, onSelect, rootClassName }: INewProjectItemProps) {
  const classes = useNewProjectItemStyles()
  const buttonContent = isLoading ? (
    <AppLoading visible small padding={4} legWidth={6} legHeight={12} />
  ) : (
    '+ Create new project'
  )

  const listItemRootClassName = classNames(classes.newProjectItem, rootClassName)

  return (
    <ListItem
      button
      disableGutters
      divider
      onClick={onSelect}
      classes={{ root: listItemRootClassName, divider: classes.listItemDivider }}
    >
      <Typography className={classes.createAction}>{buttonContent}</Typography>
      <Typography noWrap className={classes.projectName}>
        {name}
      </Typography>
    </ListItem>
  )
}

export default NewProjectItem
