import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import { ThreadPageContentStep } from './threadContentStep'

const useThreadContentAsRecipientStepStyles = makeStyles((theme: Theme) => ({
  urlLabel: {
    color: theme.palette.primary.main,
  },
  highlightedText: {
    paddingInline: 4,
  },
}))

interface IThreadPageContentStepProps {
  isFullScreen: boolean
}

export const ThreadContentAsRecipientStep = ({ isFullScreen }: IThreadPageContentStepProps) => {
  const classes = useThreadContentAsRecipientStepStyles()

  const textLines = [
    <>
      A <strong>thread</strong> is a collection of messages exchanged between people in the same email chain.
    </>,
    <>
      The
      <strong className={classes.highlightedText}>
        <span className={classes.urlLabel}>URL</span> for a thread never changes.
      </strong>
      Anyone who sent or received a message can open the thread and view all messages they're involved in.
    </>,
    <>You can reply to or forward messages, the message will send out as a regular email.</>,
  ]

  return <ThreadPageContentStep textLines={textLines} isFullScreen={isFullScreen} />
}
