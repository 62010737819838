import * as React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import AttachmentBadge from 'component/attachmentBadge'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import classNames from 'classnames'
import IAttachment, { IAttachmentUploadState } from 'model/attachment'

const useAttachmentListStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachmentContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 4,
      padding: '10px 0 0',
    },
    none: {
      display: 'none',
    },
    attachmentBadge: {
      flexGrow: 1,
      marginBottom: 3,
      marginRight: 10,
    },
  })
)

interface IAttachmentListProps {
  className?: string
  attachments: IAttachment[]
  onDelete(attachmentId: string): void
  attachmentUploadStatuses: IAttachmentUploadState[]
}

const AttachmentList = ({ attachments, onDelete, attachmentUploadStatuses, className = '' }: IAttachmentListProps) => {
  const classes = useAttachmentListStyles()

  const attachmentContainerStyle = classNames({
    className,
    [classes.none]: attachments.length === 0,
    [classes.attachmentContainer]: !className,
  })
  return (
    <div className={attachmentContainerStyle}>
      {attachments.map(attachment => {
        const uploadState = attachmentUploadStatuses.find(item => item.id === attachment.id)

        return (
          <AttachmentBadge
            key={attachment.id}
            attachment={attachment}
            uploadState={uploadState}
            onDeleteClick={onDelete}
            className={classes.attachmentBadge}
          />
        )
      })}
    </div>
  )
}

export default AttachmentList
