import { push as reactPush, replace as reactReplace } from 'connected-react-router'
import * as QueryString from 'query-string'

export const push = (path: string, state?: unknown): TThunk => {
  return (dispatch, getState) => {
    dispatch(reactPush(path, state))
  }
}

export const replaceQueryParams = (
  newQueryParams: QueryString.ParsedQuery<string | boolean>,
  state?: unknown
): TThunk => {
  return (dispatch, getState) => {
    dispatch(reactReplace({ search: QueryString.stringify(newQueryParams) }))
  }
}
