import { Theme, Typography, makeStyles } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'
import classNames from 'classnames'
import { useBoolean } from 'hooks/useBoolean'
import React from 'react'
import EmojiPicker from 'shared/components/emojiPicker'

const useProjectEmojiStyles = makeStyles((theme: Theme) => ({
  emojiIconContainer: {
    alignItems: 'center',
    borderRadius: 4,
    display: 'flex',
    height: 'fit-content',
    justifyContent: 'center',
    padding: 2,
  },
  emojiIconContainerHovered: {
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
  emojiIcon: {
    alignItems: 'center',
    aspectRatio: '1/1',
    display: 'inline-flex',
    maxHeight: 'fit-content',
  },
  customEmoji: {
    paddingTop: 3,
  },
}))

type ProjectEmojiProps = {
  emoji?: string
  onUpdateEmoji?(emoji: string): void
  className?: string
  emojiClassName?: string
}

const ProjectEmoji = ({ emoji, onUpdateEmoji, className, emojiClassName }: ProjectEmojiProps) => {
  const classes = useProjectEmojiStyles()
  const hasValidEmoji = !!emoji?.length
  const canUpdateEmoji = !!onUpdateEmoji

  const { state: isHovering, setTrue: startHovering, setFalse: stopHovering } = useBoolean(false)

  const emojiBaseClassName = classNames(classes.emojiIcon, className)

  const emojiIcon = hasValidEmoji ? (
    <Typography className={classNames(emojiBaseClassName, classes.customEmoji, emojiClassName)}>{emoji}</Typography>
  ) : (
    <FolderIcon className={emojiBaseClassName} />
  )

  return (
    <EmojiPicker
      onEmojiClick={data => onUpdateEmoji?.(data.emoji)}
      wrapperClassName={classNames(classes.emojiIconContainer, {
        [classes.emojiIconContainerHovered]: canUpdateEmoji && isHovering,
      })}
      wrapperProperties={{
        onMouseOver: startHovering,
        onMouseOut: stopHovering,
      }}
      disabled={!canUpdateEmoji}
      onClosePicker={stopHovering}
    >
      {emojiIcon}
    </EmojiPicker>
  )
}

export default ProjectEmoji
