import { isString } from 'lodash'
import { DateTime } from 'luxon'

export const ORDINAL_SUFFIX_FORMAT_CODE = '[O]'

type TInputDateTime = DateTime | string

export const DateTimeDataProcessor = (value: TInputDateTime): DateTime => {
  let result: DateTime
  if (isString(value)) {
    result = DateTime.fromISO(value as string)
  } else {
    result = value as DateTime
  }

  return result
}

export const simpleDateFormat = (date: DateTime | string, format?: string): string => {
  if (typeof date === 'string') date = DateTime.fromISO(date)
  return date.toFormat(format ? format : 'MM/dd/yyyy')
}

export const formatDateString = (dateString: string, format?: string): string => {
  const date = DateTime.fromISO(dateString)
  return date.toFormat(format ? format : 'MMMM Do YYYY')
}

const ordinalSuffixesMap: Partial<Record<Intl.LDMLPluralRule, string>> = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
}
const ordinalPluralRules = new Intl.PluralRules(undefined, { type: 'ordinal' })

export const formatDateWithOrdinal = (date: DateTime, format: string) => {
  const dayOrdinal = ordinalPluralRules.select(date.day)
  const dayOrdinalSuffix = ordinalSuffixesMap[dayOrdinal] ?? ''
  const formattedDate = date.toLocal().toFormat(format).replace(ORDINAL_SUFFIX_FORMAT_CODE, dayOrdinalSuffix)

  return formattedDate
}
