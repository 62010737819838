import * as Outlook365OAuthAPI from '../api/outlook365OAuth'
import { OUTLOOK365_OAUTH_ACTIONS } from '../constants/actions/outlook365OAuth'

import { OAuthLoginActionCreator, OAuthHandleCallbackActionCreator } from '../lib/oauth/actions'

export const login = OAuthLoginActionCreator(OUTLOOK365_OAUTH_ACTIONS.LOGIN, Outlook365OAuthAPI)

export const handleCallback = OAuthHandleCallbackActionCreator(
  OUTLOOK365_OAUTH_ACTIONS.HANDLE_CALLBACK,
  Outlook365OAuthAPI,
  'outlook'
)
