import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflowY: 'auto',
    paddingBlock: 20,
    paddingInline: 40,
  },
  header: {
    fontSize: 27,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 'normal',
    lineHeight: 'normal',
    marginBottom: 11,
  },

  subheader: {
    color: theme.threadPalette.lightGray,
    fontSize: 17,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    marginBottom: 29,
  },

  profilePictureSection: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 24,
  },

  label: {
    color: theme.threadPalette.menuItem.normal,
    fontSize: 17,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 'normal',
    lineHeight: 'normal',
    marginBottom: 8,
    marginTop: 12,
  },

  buttonStyle: {
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 500,
    height: 40,
    marginLeft: 30,
    width: 153,
  },

  submitButtonStyle: {
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: 5,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    fontSize: 18,
    height: 40,
    margin: 'auto',
    padding: '10px 45px 9px 46px',
    width: 131,
  },

  buttonContainer: {
    display: 'flex',
  },

  fieldWrapper: {
    marginBottom: 24,
    width: 330,
  },

  field: {
    alignItems: 'center',
    backgroundColor: theme.threadPalette.white,
    border: `solid 1px ${theme.threadPalette.lightishGray}`,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'row',
    height: 40,
    paddingLeft: 16,
    width: '100%',
    '& .MuiInputBase-input': {
      color: theme.threadPalette.brownishGray,
      flex: 1,
      fontSize: 17,
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      lineHeight: 1.18,
      padding: 0,
      width: '100%',
    },
  },

  fieldDisabled: {
    backgroundColor: theme.threadPalette.lighterGray,
    border: `solid 1px ${theme.threadPalette.lighterGray}`,
    cursor: 'not-allowed',
    '& .MuiInputBase-input': {
      color: theme.threadPalette.lightGray,
      cursor: 'not-allowed',
    },
  },

  providerBadge: {
    marginTop: 5,
  },

  loadingContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
  },
}))
