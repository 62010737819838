import * as React from 'react'
import Typography from '@material-ui/core/Typography'
import { IconButton, useTheme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import AttachmentIcon from 'icons/attachment'
import IAttachment, { IAttachmentUploadState } from 'model/attachment'
import classnames from 'classnames'
import AppLoading from 'component/appLoading'
import { humanizeAttachmentSize } from 'domain/attachment'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      whiteSpace: 'nowrap',
    },
    mainFieldStyle: {
      alignItems: 'center',
      border: `solid 0.5px ${theme.threadPalette.lightGray}`,
      borderRadius: 5,
      boxShadow: `1px 1px 0.5px 0 ${theme.threadPalette.lightGray}`,
      display: 'flex',
      height: 50,
      justifyContent: 'space-between',
      maxWidth: 300,
      width: 'intrinsic',
    },
    attachmentIconStyle: {
      fontSize: 20,
      marginLeft: 16,
      marginRight: 10,
    },
    loadingContainer: {
      marginLeft: 27,
      marginRight: 19,
    },
    attachmentTextStyle: {
      color: theme.threadPalette.menuItem.normal,
      display: 'inline-block',
      fontSize: 16,
      fontWeight: 500,
      height: 21,
      marginLeft: 5,
      maxWidth: 120,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    closeButton: {
      fill: theme.palette.primary.main,
      marginRight: 5,
      '&:hover': {
        fill: theme.threadPalette.menuItem.normal,
      },
    },
    attachmentSizeStyle: {
      color: theme.threadPalette.menuItem.normal,
      fontSize: 16,
      fontStyle: 'italic',
      fontWeight: 500,
      height: 21,
      marginLeft: 5,
    },
    button: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
)

interface IAttachmentBadgeProps {
  className?: string
  attachment: IAttachment
  uploadState?: IAttachmentUploadState
  onDeleteClick: (attachmentId: string) => void
}

const AttachmentBadge = ({ className, attachment, uploadState, onDeleteClick }: IAttachmentBadgeProps) => {
  const classes = useStyles()
  const theme = useTheme()

  const handleDeleteAttachment = React.useCallback(
    (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      evt.stopPropagation()
      evt.preventDefault()
      onDeleteClick(attachment.id)
    },
    [onDeleteClick, attachment.id]
  )

  return (
    <div className={classnames(classes.mainFieldStyle, className)}>
      <div className={classes.titleWrapper}>
        {!uploadState || uploadState.uploaded ? (
          <AttachmentIcon fill={theme.threadPalette.menuItem.normal} className={classes.attachmentIconStyle} />
        ) : (
          <div className={classes.loadingContainer}>
            <AppLoading visible small padding={0} legHeight={28} legWidth={6} fill={theme.palette.primary.light} />
          </div>
        )}
        <Typography className={classes.attachmentTextStyle}>{attachment.filename}, </Typography>
        <Typography className={classes.attachmentSizeStyle}>({humanizeAttachmentSize(attachment)})</Typography>
      </div>
      <div>
        <IconButton onClick={handleDeleteAttachment} className={classes.button} aria-label="Delete" disableRipple>
          <CloseIcon className={classes.closeButton} />
        </IconButton>
      </div>
    </div>
  )
}

export default AttachmentBadge
