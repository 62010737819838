import { useQuery } from '@tanstack/react-query'
import { getThreadHeldMessages } from 'api/threads'
import { useUser } from 'hooks/auth'

const getThreadHeldMessagesQueryKey = ({ userId, threadId }: { userId: string; threadId: string }) => [
  userId,
  'threads',
  threadId,
  'messages',
  'held',
]

export const useThreadHeldMessages = (threadId: string) => {
  const currentUser = useUser()

  const { data: heldMessages = [], isLoading } = useQuery({
    enabled: !!threadId && !!currentUser?.id,
    queryKey: getThreadHeldMessagesQueryKey({ userId: currentUser?.id ?? '', threadId }),
    queryFn: () => getThreadHeldMessages(threadId),
  })

  return { heldMessages, isLoading }
}
