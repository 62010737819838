import React from 'react'
import * as QueryString from 'query-string'

import { useSharingGroupContext } from 'contexts/sharingGroupContext'
import { ShareButton } from 'component/shareButton'
import { Badge, Theme, makeStyles } from '@material-ui/core'
import { useThreadSharingSettings } from 'hooks/useSharingGroup/useThreadSharingSettings'
import { getHeldMessageCountForThreadSharingSettings } from 'domain/sharingGroup'
import { useOnboardingTour } from 'hooks/onboardingTour/useOnboardingTour'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { replaceQueryParams } from 'actions/navigation'

const useShareThreadButtonStyles = makeStyles((theme: Theme) => {
  return {
    heldMessageCountBadge: {
      backgroundColor: theme.threadPalette.orange,
      bottom: '30%',
      color: theme.threadPalette.white,
      fontSize: 10,
      height: 14,
      left: '10%',
      minWidth: 0,
      width: 14,
    },
  }
})

interface IShareThreadButtonProps {
  id?: string
  currentThread: IThread
  currentUser: IUser
}

export const ShareThreadButton = ({ id, currentThread, currentUser }: IShareThreadButtonProps) => {
  const classes = useShareThreadButtonStyles()
  const location = useLocation()
  const dispatch = useDispatch()

  const { openSharingGroup } = useSharingGroupContext()
  const { startOnboardingTour } = useOnboardingTour()

  const { threadSharingSettingsList } = useThreadSharingSettings(currentThread.id)

  const heldMessageCount = getHeldMessageCountForThreadSharingSettings({ threadSharingSettingsList, user: currentUser })

  const isThreadCanBeShared = currentThread.isUserAThreadDirectRecipient === true

  const onShareClick = () => {
    if (!isThreadCanBeShared) return

    const isOnboarding = startOnboardingTour('share_thread', { openSharingGroup })
    if (!isOnboarding) openSharingGroup()
  }

  React.useEffect(() => {
    if (!location.search) return

    const queryParams = QueryString.parse(location.search, { parseBooleans: true })
    if (queryParams?.share) {
      onShareClick()

      delete queryParams.share
      dispatch(replaceQueryParams(queryParams))
    }
  }, [])

  return (
    <Badge
      badgeContent={heldMessageCount}
      classes={{ badge: classes.heldMessageCountBadge }}
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      showZero={false}
    >
      <ShareButton
        id={id}
        onClick={onShareClick}
        disabled={!isThreadCanBeShared}
        disabledTooltip="You should be a recipient of at least one message in the thread to share it."
      />
    </Badge>
  )
}
