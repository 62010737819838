import React from 'react'
import { LeiaIcon } from 'icons/leia'
import { Button, Theme, makeStyles } from '@material-ui/core'

const useHeldMessagesReviewCompleteScreenStyles = makeStyles((theme: Theme) => ({
  heldMessagesReviewCompleteScreenRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    paddingInline: 30,
  },
  closeButtonRoot: {
    alignSelf: 'end',
    height: 40,
    width: 130,
  },
  closeButtonText: {
    fontSize: 16,
    fontWeight: 500,
  },
}))

interface IHeldMessagesReviewCompleteScreenScreenProps {
  onClose(): void
}

export const HeldMessagesReviewCompleteScreen = ({ onClose }: IHeldMessagesReviewCompleteScreenScreenProps) => {
  const classes = useHeldMessagesReviewCompleteScreenStyles()

  return (
    <div className={classes.heldMessagesReviewCompleteScreenRoot}>
      <LeiaIcon />
      <Button
        variant="contained"
        color="primary"
        classes={{ root: classes.closeButtonRoot, label: classes.closeButtonText }}
        onClick={onClose}
      >
        Close
      </Button>
    </div>
  )
}
