import storage from 'redux-persist/lib/storage'
import { AnyAction } from 'redux'
import { LEFT_SIDEBAR_THREADS_VIEW_SETTINGS_ACTIONS } from 'constants/actions/leftSidebarThreadsViewSettings'

const DEFAULT_STATE: IThreadsViewLeftSidebarSettings = {
  filterByStarred: false,
  filterByUnread: false,
  filterByFollowed: true,
  filterByUnfollowed: false,
  filterByViewed: false,
}

export const leftSidebarThreadsViewSettingsPersistConfig = {
  key: 'leftSidebarThreadsViewSettings',
  storage: storage,
}

export const toggleFilterThreadsByStarred = (): TThunk => {
  return (dispatch, _) => {
    dispatch({
      type: LEFT_SIDEBAR_THREADS_VIEW_SETTINGS_ACTIONS.TOGGLE_FILTER_BY_STARRED,
    })
  }
}

export const toggleFilterThreadsByUnread = (): TThunk => {
  return (dispatch, _) => {
    dispatch({
      type: LEFT_SIDEBAR_THREADS_VIEW_SETTINGS_ACTIONS.TOGGLE_FILTER_BY_UNREAD,
    })
  }
}

export const toggleFilterThreadsByFollowed = (): TThunk => {
  return (dispatch, _) => {
    dispatch({
      type: LEFT_SIDEBAR_THREADS_VIEW_SETTINGS_ACTIONS.TOGGLE_FILTER_BY_FOLLOWED,
    })
  }
}

export const toggleFilterThreadsByUnfollowed = (): TThunk => {
  return (dispatch, _) => {
    dispatch({
      type: LEFT_SIDEBAR_THREADS_VIEW_SETTINGS_ACTIONS.TOGGLE_FILTER_BY_UNFOLLOWED,
    })
  }
}

export const toggleFilterThreadsByViewed = (): TThunk => {
  return (dispatch, _) => {
    dispatch({
      type: LEFT_SIDEBAR_THREADS_VIEW_SETTINGS_ACTIONS.TOGGLE_FILTER_BY_VIEWED,
    })
  }
}

export const leftSidebarThreadsViewSettingsReducer = (state = DEFAULT_STATE, action: AnyAction) => {
  switch (action.type) {
    case LEFT_SIDEBAR_THREADS_VIEW_SETTINGS_ACTIONS.TOGGLE_FILTER_BY_STARRED: {
      return {
        ...state,
        filterByStarred: !state.filterByStarred,
      }
    }
    case LEFT_SIDEBAR_THREADS_VIEW_SETTINGS_ACTIONS.TOGGLE_FILTER_BY_UNREAD: {
      return {
        ...state,
        filterByUnread: !state.filterByUnread,
      }
    }
    case LEFT_SIDEBAR_THREADS_VIEW_SETTINGS_ACTIONS.TOGGLE_FILTER_BY_FOLLOWED: {
      return {
        ...state,
        filterByFollowed: !state.filterByFollowed,
      }
    }
    case LEFT_SIDEBAR_THREADS_VIEW_SETTINGS_ACTIONS.TOGGLE_FILTER_BY_UNFOLLOWED: {
      return {
        ...state,
        filterByUnfollowed: !state.filterByUnfollowed,
      }
    }
    case LEFT_SIDEBAR_THREADS_VIEW_SETTINGS_ACTIONS.TOGGLE_FILTER_BY_VIEWED: {
      return {
        ...state,
        filterByViewed: !state.filterByViewed,
      }
    }
    default:
      return state
  }
}
