import React from 'react'

interface IUseZoomProps {
  defaultZoom: number
  maxZoom: number
  minZoom: number
  zoomStep: number
}

export const useZoom = (
  { defaultZoom, maxZoom, minZoom, zoomStep }: IUseZoomProps = {
    defaultZoom: 1.0,
    maxZoom: 3.0,
    minZoom: 0.2,
    zoomStep: 0.2,
  }
) => {
  const [zoom, setZoom] = React.useState(defaultZoom)

  const onFullScreenClick = () => {
    setZoom(defaultZoom)
  }

  const onZoomInClick = () => {
    if (zoom < maxZoom) {
      setZoom(prev => (prev += zoomStep))
    }
  }

  const onZoomOutClick = () => {
    if (zoom > minZoom) {
      setZoom(prev => (prev -= zoomStep))
    }
  }

  return {
    zoom,
    onFullScreenClick,
    onZoomInClick,
    onZoomOutClick,
  }
}
