import React, { useContext } from 'react'
import { useCurrentThreadContext } from './currentThreadContext'

type TSharingGroupScreenName =
  | 'createOrEditThreadSharingSettingsScreen'
  | 'reviewHeldMessagesScreen'
  | 'individualMessageSharingScreen'

interface ISharingGroupContext {
  thread: IThread
  isSharingGroupOpen: boolean
  openSharingGroup: () => void
  openThreadHeldMessages(): void
  openIndividualMessageSharing(message: IMessage): void
  closeSharingGroup: () => void
  ActiveScreen: JSX.Element | null
  setActiveScreen(newScreen: JSX.Element): void
  sharingGroupScreenToOpen: TSharingGroupScreenName
  messageToShare?: IMessage
  focusedHeldMessage?: IMessage
}

export const SharingGroupContext = React.createContext<ISharingGroupContext | undefined>(undefined)

export const SharingGroupContextProvider: React.FC<{}> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [ActiveScreen, setActiveScreen] = React.useState<JSX.Element | null>(null)

  const [sharingGroupScreenToOpen, setSharingGroupScreenToOpen] = React.useState<TSharingGroupScreenName>(
    'createOrEditThreadSharingSettingsScreen'
  )
  const [messageToShare, setMessageToShare] = React.useState<IMessage>()
  const [focusedHeldMessage, setFocusedHeldMessage] = React.useState<IMessage>()

  const { currentThread } = useCurrentThreadContext()

  const openSharingGroup = () => {
    setSharingGroupScreenToOpen('createOrEditThreadSharingSettingsScreen')
    setIsOpen(true)
  }

  const openThreadHeldMessages = (focusedHeldMessage?: IMessage) => {
    setFocusedHeldMessage(focusedHeldMessage)
    setSharingGroupScreenToOpen('reviewHeldMessagesScreen')
    setIsOpen(true)
  }

  const openIndividualMessageSharing = (message: IMessage) => {
    setMessageToShare(message)
    setSharingGroupScreenToOpen('individualMessageSharingScreen')
    setIsOpen(true)
  }

  const closeSharingGroup = () => {
    setActiveScreen(null)
    setIsOpen(false)
    setMessageToShare(undefined)
    setFocusedHeldMessage(undefined)
  }

  if (!currentThread) return <>{children}</>

  return (
    <SharingGroupContext.Provider
      value={{
        thread: currentThread,
        isSharingGroupOpen: isOpen,
        openSharingGroup,
        closeSharingGroup,
        ActiveScreen,
        setActiveScreen,
        sharingGroupScreenToOpen,
        openThreadHeldMessages,
        messageToShare,
        openIndividualMessageSharing,
        focusedHeldMessage,
      }}
    >
      {children}
    </SharingGroupContext.Provider>
  )
}

export const useSharingGroupContext = () => {
  const context = useContext(SharingGroupContext)

  if (context === undefined)
    throw new Error('useSharingGroupContext should be used within a SharingGroupContextProvider ')

  return context
}
