import * as React from 'react'
import classNames from 'classnames'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles, createStyles } from '@material-ui/styles'
import VisibilityIcon from '@material-ui/icons/Visibility'
import UnfollowIcon from '@material-ui/icons/VisibilityOff'
import ButtonWithIcon from 'component/buttonWithIcon/buttonWithIcon'
import EmailMessageContent from 'component/emailMessageContent'
import IAttachment from 'model/attachment'

interface IPreviousMessageContentProps {
  message: IMessage
  hasTableInside: boolean
  isPreviousMessageContentExpanded?: boolean
  isInlineAttachmentPreviewEnabled?: boolean
  onOpenAttachment?(attachment: IAttachment): void
}

const VARIANT_TABLE_INSIDE_CLASSNAME = 'MessagePreviousContent__table'
const VARIANT_TABLE_INSIDE_SELECTOR = `&.${VARIANT_TABLE_INSIDE_CLASSNAME}`

const usePreviousMessageContentStyles = makeStyles((theme: Theme) =>
  createStyles({
    expandButton: {
      color: theme.threadPalette.lightBlue,
      fontSize: '12px',
      height: '25px',
      marginTop: '10px',
      paddingTop: '5px',
      paddingBottom: '5px',
      [VARIANT_TABLE_INSIDE_SELECTOR]: {
        marginBottom: '10px',
      },
    },
    visibilityIcon: {
      height: '12px',
      width: '12px',
    },
    content: {
      marginTop: '15px',
      wordBreak: 'break-all',
    },
  })
)

export const PreviousMessageContent = ({
  message,
  hasTableInside,
  isPreviousMessageContentExpanded = false,
  isInlineAttachmentPreviewEnabled = true,
  onOpenAttachment,
}: IPreviousMessageContentProps) => {
  const classes = usePreviousMessageContentStyles()
  const [isExpanded, setIsExpanded] = React.useState(isPreviousMessageContentExpanded)

  const previousMessageContent = message.previousMessageContent
  const isPreviousMessageContentPresent = previousMessageContent && previousMessageContent !== ''

  const expandButtonClassName = classNames(classes.expandButton, {
    [VARIANT_TABLE_INSIDE_CLASSNAME]: hasTableInside,
  })

  const getButtonText = React.useCallback(() => {
    if (isExpanded) return 'Hide previous messages'
    return 'See previous messages'
  }, [isExpanded])

  const getButtonIcon = React.useCallback(() => {
    if (isExpanded) return UnfollowIcon
    return VisibilityIcon
  }, [isExpanded])

  if (!isPreviousMessageContentPresent) return null

  return (
    <>
      <ButtonWithIcon
        onClick={() => setIsExpanded(prev => !prev)}
        text={getButtonText()}
        icon={getButtonIcon()}
        iconClassName={classes.visibilityIcon}
        buttonClassName={expandButtonClassName}
      />
      {isExpanded && (
        <div className={classes.content}>
          <EmailMessageContent
            content={previousMessageContent}
            inlineAttachments={message.inlineAttachments}
            contentInlineAttachments={message.previousMessageContentInlineAttachments}
            mentionedUsers={message.mentionedUsers}
            isInlineAttachmentPreviewEnabled={isInlineAttachmentPreviewEnabled}
            onOpenAttachment={onOpenAttachment}
          />
        </div>
      )}
    </>
  )
}
