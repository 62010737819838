import { linkRegularExpression } from 'constants/diff'

export const isURL = (text: string) => {
  return linkRegularExpression.exec(text)
}

export type TCurrentPlatform = 'mac' | 'win' | 'linux' | null

export const getCurrentPlatform = (): TCurrentPlatform => {
  const userAgent = navigator.userAgent.toLowerCase()

  if (userAgent.indexOf('mac') >= 0) return 'mac'

  if (userAgent.indexOf('linux') >= 0) return 'linux'

  if (userAgent.indexOf('win') >= 0) return 'win'

  return null
}
