import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import { EditButton } from './editButton'
import GroupIcon from '@material-ui/icons/Group'
import { useSharingGroupContext } from 'contexts/sharingGroupContext'

const useShareIndividualMessageButtonStyles = makeStyles((theme: Theme) => ({
  buttonLabel: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 13,
    fontWeight: 'normal',
    gap: 4,
  },
  groupIcon: {
    height: 18,
    width: 18,
  },
}))

interface IShareIndividualMessageButtonProps {
  message: IMessage
  onClick?(): void
}

export const ShareIndividualMessageButton = ({ message, onClick }: IShareIndividualMessageButtonProps) => {
  const classes = useShareIndividualMessageButtonStyles()

  const { openIndividualMessageSharing } = useSharingGroupContext()

  const onShareIndividualMessageButtonClick = () => {
    openIndividualMessageSharing(message)
    onClick?.()
  }

  const label = (
    <>
      <GroupIcon className={classes.groupIcon} />
      Share with new group
    </>
  )

  return <EditButton label={label} onClick={onShareIndividualMessageButtonClick} labelClassName={classes.buttonLabel} />
}
