import * as React from 'react'
import { useIconStyles } from '../styles'
import classnames from 'classnames'

interface IUnknownFileIconProps extends JSX.IntrinsicAttributes {
  width?: number
  height?: number
  className?: string
  fill?: string
}

const UnknownFileIcon = ({ className, width, height, fill }: IUnknownFileIconProps) => {
  const classes = useIconStyles()
  return (
    <div className={classnames(className, classes.iconWrapper)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : '20'}
        height={height ? height : '20'}
        viewBox="0 0 20 20"
      >
        <path
          fill={fill ? fill : '#4A4A4A'}
          d="M17.268 19.714c.283 0 .523-.099.72-.297.199-.198.298-.438.298-.72V6.481c0-.283-.071-.594-.212-.933-.142-.34-.311-.608-.51-.806l-3.307-3.308c-.198-.198-.467-.367-.806-.509-.34-.141-.65-.212-.933-.212h-9.5c-.283 0-.523.1-.721.297C2.099 1.21 2 1.45 2 1.732v16.964c0 .283.099.523.297.721.198.198.438.297.72.297h14.25zm-.34-1.357H3.359V2.071H11.5v4.411c0 .283.099.523.297.721.198.198.438.297.72.297h4.412v10.857zm-.084-12.214h-3.987V2.156c.205.07.35.149.435.234l3.318 3.318c.085.085.163.23.234.435zm-2.969 4.071c.099 0 .18-.032.244-.095.063-.064.095-.145.095-.244v-.679c0-.099-.032-.18-.095-.243-.064-.064-.145-.096-.244-.096H6.411c-.1 0-.18.032-.244.096-.064.063-.096.144-.096.243v.679c0 .099.032.18.096.244.063.063.145.095.244.095h7.464zm0 2.715c.099 0 .18-.032.244-.096.063-.063.095-.145.095-.244v-.678c0-.1-.032-.18-.095-.244-.064-.064-.145-.096-.244-.096H6.411c-.1 0-.18.032-.244.096-.064.063-.096.145-.096.244v.678c0 .1.032.18.096.244.063.064.145.096.244.096h7.464zm0 2.714c.099 0 .18-.032.244-.096.063-.063.095-.144.095-.243v-.679c0-.099-.032-.18-.095-.244-.064-.063-.145-.095-.244-.095H6.411c-.1 0-.18.032-.244.095-.064.064-.096.145-.096.244v.679c0 .099.032.18.096.243.063.064.145.096.244.096h7.464z"
        />
      </svg>
    </div>
  )
}

export default UnknownFileIcon
