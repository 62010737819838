import * as React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme, Chip, Tooltip, fade } from '@material-ui/core'
import { isEmail } from 'dataProcessors/utils'
import { getUserDisplayName } from 'dataProcessors/userDisplayName'
import { IPersonChipProps } from 'component/peopleSelect/peopleSelect'
import UserAvatar from 'component/userAvatar'
import UserDisplayCard from 'features/user/card/userDisplayCard'

interface IUseSharingGroupMemberChipStylesProps {
  isUserEmailValid: boolean
  accentColor?: string
}

const useSharingGroupMemberChipStyles = makeStyles<Theme, IUseSharingGroupMemberChipStylesProps>((theme: Theme) =>
  createStyles({
    chipRoot: {
      backgroundColor: ({ isUserEmailValid }) =>
        isUserEmailValid ? theme.threadPalette.white : theme.palette.error.contrastText,
      border: ({ isUserEmailValid, accentColor }) => {
        const defaultBorderColor = isUserEmailValid ? theme.threadPalette.lightGray : theme.palette.error.contrastText
        return `solid 1px ${accentColor ?? defaultBorderColor}`
      },
      gap: 4,
      height: 28,
      maxWidth: 160,
      paddingBlock: 2,
      paddingLeft: ({ isUserEmailValid }) => (isUserEmailValid ? 2 : 8),
      paddingRight: 8,
    },
    chipLabel: {
      color: ({ isUserEmailValid }) =>
        isUserEmailValid ? theme.threadPalette.menuItem.current : theme.threadPalette.white,
      paddingInline: 0,
    },
    avatar: {
      borderRadius: 11,
      margin: 0,
    },
    deleteIcon: {
      fill: ({ isUserEmailValid, accentColor }) => {
        const defaultFillColor = isUserEmailValid ? theme.threadPalette.lightGray : theme.threadPalette.white
        return accentColor ?? defaultFillColor
      },
      height: 16,
      margin: 0,
      width: 16,
    },
    tooltip: {
      backgroundColor: theme.threadPalette.white,
      border: `solid 1px ${fade(theme.threadPalette.lightGray, 0.4)}`,
      borderRadius: 8,
      boxShadow: '4px 4px 6px 0 rgba(0, 0, 0, 0.2)',
      margin: 0,
      minWidth: 220,
      padding: 8,
    },
  })
)

export const SharingGroupMemberChip = ({
  person,
  size,
  accentColor,
  ...props
}: IPersonChipProps & Parameters<typeof Chip>[0] & Pick<IUseSharingGroupMemberChipStylesProps, 'accentColor'>) => {
  const isUserEmailValid = isEmail(person.email)
  const classes = useSharingGroupMemberChipStyles({ isUserEmailValid, accentColor })

  const personDisplayName = getUserDisplayName(person)

  const icon = isUserEmailValid ? (
    <UserAvatar size={22} user={person} fontSize={10} className={classes.avatar} />
  ) : undefined
  const personInfoTooltip = <UserDisplayCard user={person} />

  return (
    <Tooltip title={personInfoTooltip} placement="top" classes={{ tooltip: classes.tooltip }} interactive>
      <Chip
        icon={icon}
        label={personDisplayName}
        classes={{
          root: classes.chipRoot,
          label: classes.chipLabel,
          deleteIcon: classes.deleteIcon,
        }}
        size={size}
        {...props}
      />
    </Tooltip>
  )
}
