import * as React from 'react'
import PageLayout from './pageLayout'
import Account from 'component/account'
import { Typography, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontSize: 30,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  appBar: {
    backgroundColor: theme.threadPalette.lighterGray,
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
  },
}))

const AccountPage = () => {
  const classes = useStyles()

  return (
    <PageLayout
      headerContent={<Typography className={classes.header}>My Account</Typography>}
      className={classes.appBar}
    >
      <Account />
    </PageLayout>
  )
}

export default AccountPage
