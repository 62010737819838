import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Popover, PopoverProps, Theme } from '@material-ui/core'
import NotificationsPage from 'component/rightSidebar/notificationCenter'
import { usePagination } from 'hooks/usePagination'
import { getNotifications, markNotificationsRead } from 'api/notifications'
import BeatProgress from 'component/beatProgress'

const useStyles = makeStyles((theme: Theme) => ({
  popoverRoot: {
    height: 800,
    width: theme.dimensions.rightSidebar.maxWidth,
    borderRadius: 4,
    overflow: 'auto',
    boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.2)',
    position: 'relative',
  },

  progressBar: {
    marginBottom: 32,
  },

  label: {
    fontSize: 13,
    fontWeight: 500,
    fontStretch: 'normal',
    textTransform: 'uppercase',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.threadPalette.brownishGray,
    marginBottom: 12,
  },
  arrow: {
    backgroundColor: 'black',
    width: 80,
    height: 40,
    left: '-20px',
    bottom: '20px',
    transform: 'rotate(270deg)',
    position: 'absolute',
  },
}))

interface INotificationsPopoverProps extends PopoverProps {}

const NotificationsPopover = (props: INotificationsPopoverProps) => {
  const classes = useStyles()

  const handlePopoverClose = React.useCallback(
    (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => {
      props.onClose && props.onClose(event, reason)
      markNotificationsRead()
    },
    [props]
  )

  const fetchNotificationsData = React.useCallback(async (page: number) => await getNotifications(page), [])

  const { handleScrollEvent, reloadData, data, isLoading } = usePagination(fetchNotificationsData)

  React.useEffect(() => {
    if (props.open) {
      reloadData()
    }
  }, [props.open])

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      style={{ left: 60, bottom: 35, top: 0 }}
    >
      <div className={classes.popoverRoot} onScroll={handleScrollEvent}>
        <NotificationsPage collection={data} isLoading={isLoading} />
        <div>{isLoading && <BeatProgress />}</div>
      </div>
    </Popover>
  )
}

export default NotificationsPopover
