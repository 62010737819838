import React, { useContext } from 'react'
import { useCurrentThreadContext } from '../contexts/currentThreadContext'
import { useThreadMessagesList } from 'hooks/useThreadMessages/useThreadMessagesList'
import { useRefreshThreadData } from 'hooks/useThreads/useRefreshThreadData'
import { useThreadMessagesScrollPosition } from 'hooks/useThreadMessages/useThreadMessagesScrollPosition'
import { debounce } from 'lodash'
import { ICreateDraftUseCaseProps, useCreateDraftUseCase } from 'usecases/draft/createDraft'
import { IDeleteDraftUseCaseParams, useDeleteDraftUseCase } from 'usecases/draft/deleteDraft'

const TWENTY_SECONDS_IN_MILLISECONDS = 20_000

export interface IThreadPageContext {
  recipientsFilter: string[]
  updateRecipientsFilter(followers: string[]): void
  messagesList: IMessage[]
  isLoadingMessages: boolean
  fetchNextMessagesPage(): void
  isFetchingNextMessagesPage: boolean
  isRefreshingMessages: boolean
  hasMoreMessagePages: boolean
  messagesScrollPosition: number
  setMessagesScrollPosition(scrollPosition: number): void
  createDraft(newDraftProps: ICreateDraftUseCaseProps): void
  deleteDraft(deleteDraftProps: IDeleteDraftUseCaseParams): void
  messagesListLoadingError: Error | null
}

export const ThreadPageContext = React.createContext<IThreadPageContext | undefined>(undefined)
ThreadPageContext.displayName = 'ThreadPageContext'

export const ThreadPageContextProvider: React.FC<{}> = ({ children }) => {
  const [recipientsFilter, setRecipientsFilter] = React.useState<string[]>([])

  const {
    currentThread,
    lastRefreshedAt: threadLastRefreshedAt,
    isLoading: isLoadingThread,
  } = useCurrentThreadContext()

  const { createDraftUseCase, isLoading: isCreatingDraft } = useCreateDraftUseCase()
  const { deleteDraftUseCase, isLoading: isDeletingDraft } = useDeleteDraftUseCase()

  const {
    messagesList,
    isLoading: isLoadingMessages,
    fetchNextPage: fetchNextMessagesPage,
    isFetchingNextPage: isFetchingNextMessagesPage,
    lastRefreshedAt: messagesListLastRefreshedAt,
    isRefetching: isRefreshingMessages,
    hasNextPage: hasMoreMessagePages,
    error: messagesListLoadingError,
  } = useThreadMessagesList({
    threadId: currentThread?.id ?? '',
    recipientsFilter,
    isFetchingEnabled: !isCreatingDraft && !isDeletingDraft,
  })

  const { scrollPosition, setScrollPosition } = useThreadMessagesScrollPosition(currentThread?.id ?? '')

  const debouncedSetScrollPosition = React.useCallback(debounce(setScrollPosition, 200), [])

  const setMessagesScrollPosition = (scrollPosition: number) => {
    if (!currentThread) return
    debouncedSetScrollPosition({ threadId: currentThread.id, scrollPosition })
  }

  const { refreshThreadData } = useRefreshThreadData()

  React.useEffect(() => {
    setRecipientsFilter([])
  }, [currentThread?.id])

  const updateRecipientsFilter = (recipientsIds: string[]) => {
    setRecipientsFilter(recipientsIds)
  }

  // refresh thread while importing if data is stale for too long (20 seconds)
  React.useEffect(() => {
    const isImporting = !!currentThread?.isImporting
    const isThreadFetched = !isLoadingThread && threadLastRefreshedAt !== 0

    if (!isImporting || !isThreadFetched) return

    const lastRefreshTime = Math.max(threadLastRefreshedAt, messagesListLastRefreshedAt)

    const elapsedMillisecondsSinceRefresh = Date.now() - lastRefreshTime
    const refreshDelayInMilliseconds = Math.max(0, TWENTY_SECONDS_IN_MILLISECONDS - elapsedMillisecondsSinceRefresh)

    const timeout = setTimeout(() => {
      refreshThreadData(currentThread.id)
    }, refreshDelayInMilliseconds)

    return () => clearTimeout(timeout)
  }, [
    currentThread?.id,
    currentThread?.isImporting,
    isLoadingThread,
    threadLastRefreshedAt,
    messagesListLastRefreshedAt,
  ])

  return (
    <ThreadPageContext.Provider
      value={{
        recipientsFilter,
        updateRecipientsFilter,
        messagesList,
        isLoadingMessages,
        fetchNextMessagesPage,
        isFetchingNextMessagesPage,
        isRefreshingMessages,
        hasMoreMessagePages,
        messagesScrollPosition: scrollPosition,
        setMessagesScrollPosition,
        createDraft: createDraftUseCase,
        deleteDraft: deleteDraftUseCase,
        messagesListLoadingError,
      }}
    >
      {children}
    </ThreadPageContext.Provider>
  )
}

export const useThreadPageContext = () => {
  const context = useContext(ThreadPageContext)
  if (context === undefined) throw new Error('useThreadPageContext should be used within a ThreadPageContextProvider')

  return context
}
