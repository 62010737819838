interface ProjectsListFilters {
  pageSize?: number
  searchTerm?: string
  isArchived?: boolean
}

export const getProjectsListQueryKey = ({
  userId,
  parentProjectId,
  filters = {},
}: {
  userId: string
  parentProjectId?: string | null
  filters?: ProjectsListFilters
}) => {
  const queryKeyOptions = { type: 'list', parentProjectId, ...filters }
  if (parentProjectId === undefined) delete queryKeyOptions.parentProjectId
  return [userId, 'projects', queryKeyOptions]
}

export const getProjectsQueryKey = (userId: string) => [userId, 'projects', { type: 'id' }]

export const getProjectByIdQueryKey = ({ userId, projectId }: { userId: string; projectId: string }) => [
  userId,
  'projects',
  { type: 'id', id: projectId },
]

export const getProjectByNameQueryKey = ({ userId, projectName }: { userId: string; projectName: string }) => [
  userId,
  'projects',
  { type: 'name', name: projectName },
]

export const getProjectsFiltersQueryKey = (userId: string) => [userId, 'projects-filters']
