import { useMutation } from '@tanstack/react-query'
import { updateAttachmentUploadStatus } from 'api/attachment'

export const useUpdateAttachmentUploadStatusUseCase = ({ onError }: { onError?(attachmentId: string): void } = {}) => {
  const { mutateAsync } = useMutation(updateAttachmentUploadStatus, {
    onError: (_, attachmentId) => onError?.(attachmentId),
  })

  const attachmentUploadedCallbackUseCase: typeof updateAttachmentUploadStatus = mutateAsync

  return {
    attachmentUploadedCallbackUseCase,
  }
}
