export const getThreadDisplayName = (thread: { titleAlias?: string; title: string }) => {
  if (!!thread?.titleAlias) {
    return thread.titleAlias
  }
  return thread.title
}

export const getThreadURL = ({ id, attachmentId }: { id: string; attachmentId?: string }) => {
  const url = new URL(`/threads/${id}`, window.location.href)
  if (attachmentId) addAttachmentToThreadURL({ threadURL: url, attachmentId })
  return url
}

export const getThreadLiveLinkURL = ({ id, attachmentId }: { id: string; attachmentId?: string }) => {
  const url = new URL(`/live-link/${id}`, window.location.href)
  if (attachmentId) addAttachmentToThreadURL({ threadURL: url, attachmentId })
  return url
}

const addAttachmentToThreadURL = ({ threadURL, attachmentId }: { threadURL: URL; attachmentId: string }) => {
  threadURL.searchParams.append('file', attachmentId)
}
