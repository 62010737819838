import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { push } from 'actions/navigation'
import { TResponseError } from 'api/errors/errorHandlingWrapper'
import { PaginatedResponse } from 'api/pagedResponse'
import { useUser } from 'hooks'
import { useThread } from 'hooks/useThreads/useThread'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'
import { getThreadsListQueryKey } from 'hooks/useThreads/useThreadsList'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useRemoveThreadUseCase } from 'usecases/removeThread'
// import { DateTime } from 'luxon'

// const REMOVE_CACHE_OLDER_THAN_DATE = DateTime.utc(2024, 1, 18, 0, 0, 0)

interface ICurrentThreadContext {
  currentThread: IThread | null
  setCurrentThread: (threadId?: string, threadInitialData?: IThread) => void
  responseError?: TResponseError
  refreshCurrentThread: (thread?: IThread) => void
  hasActiveUpload: boolean
  setHasActiveUpload: (hasActiveUpload: boolean) => void
  isLoading: boolean
  lastRefreshedAt: number
}

export const CurrentThreadContext = React.createContext<ICurrentThreadContext | undefined>(undefined)

interface ICurrentThreadState {
  currentThreadId?: string
  threadInitialData?: IThread
}

export const CurrentThreadContextProvider: React.FC<{}> = ({ children }) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const [currentThreadState, setCurrentThreadState] = React.useState<ICurrentThreadState>()
  const [responseError, setResponseError] = React.useState<TResponseError>()

  const [hasActiveUpload, setHasActiveUpload] = React.useState<boolean>(false)

  // queryClient.removeQueries({
  //   predicate: query => {
  //     if (!query.state.data && query.state.dataUpdatedAt === 0) return false
  //     const queryLastUpdatedAt = DateTime.fromMillis(Math.trunc(query.state.dataUpdatedAt))
  //     if (queryLastUpdatedAt <= REMOVE_CACHE_OLDER_THAN_DATE) {
  //       console.log('Removing query cache: ', query)
  //       return true
  //     }

  //     return false
  //   },
  // })

  const {
    thread: currentThread,
    isLoading,
    lastRefreshedAt,
  } = useThread({
    threadId: currentThreadState?.currentThreadId,
    initialData: currentThreadState?.threadInitialData,
    onError: (error: Error) => {
      setResponseError(error.message as TResponseError)
    },
  })
  const { refreshThread } = useThreadActions()
  const { removeThreadUseCase } = useRemoveThreadUseCase()

  const getThreadDefaultInitialData = React.useCallback(
    (threadId: string) => {
      if (!currentUser?.id) return

      const threadsListQueriesData = queryClient.getQueriesData<InfiniteData<PaginatedResponse<IThread[]>>>({
        queryKey: getThreadsListQueryKey(currentUser.id),
        type: 'active',
      })

      if (threadsListQueriesData.length === 0) return

      const threadsPages = threadsListQueriesData[0][1]
      const threadsList = threadsPages?.pages?.flatMap(page => page.data) ?? []

      return threadsList.find(thread => thread.id === threadId)
    },
    [queryClient, currentUser?.id]
  )

  const setCurrentThread = React.useCallback(
    (threadId?: string, threadInitialData?: IThread) => {
      if (!threadId || threadId === currentThreadState?.currentThreadId) return
      setResponseError(undefined)

      threadInitialData = threadInitialData ?? getThreadDefaultInitialData(threadId)

      setCurrentThreadState({ currentThreadId: threadId, threadInitialData })
    },
    [currentThreadState, getThreadDefaultInitialData]
  )

  React.useEffect(() => {
    if (!currentThread) return

    if (currentThread?.mergedToThreadId) {
      dispatch(push(`/threads/${currentThread.mergedToThreadId}`))
      removeThreadUseCase(currentThread.id)
    }
  }, [currentThread?.id])

  const refreshCurrentThread = React.useCallback(
    (threadInitialData?: IThread) => {
      if (!currentThread) return
      refreshThread(currentThread.id, threadInitialData)
    },
    [queryClient, currentThread?.id]
  )

  return (
    <CurrentThreadContext.Provider
      value={{
        currentThread,
        setCurrentThread,
        responseError,
        refreshCurrentThread,
        hasActiveUpload,
        setHasActiveUpload,
        isLoading,
        lastRefreshedAt,
      }}
    >
      {children}
    </CurrentThreadContext.Provider>
  )
}

export const useCurrentThreadContext = () => {
  const context = useContext(CurrentThreadContext)

  if (context === undefined)
    throw new Error('useCurrentThreadContext should be used within a CurrentThreadContextProvider ')

  return context
}
