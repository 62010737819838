import * as React from 'react'
import { Typography, Button, TextField } from '@material-ui/core'
import UserAvatar from 'component/userAvatar'
import AttachmentPicker from 'component/attachmentPicker'
import EmailProviderBadge from 'component/emailProviderBadge'
import AppLoading from 'component/appLoading'
import { AVATAR_FILE_TYPES } from 'component/profile/profile.model'
import { useStyles } from './userInfoConfirmation.style'
import { useUserInfoConfirmation } from './useUserInfoConfirmation'
import { useUser } from 'hooks/auth'
import classNames from 'classnames'
import SubmitButtonWithLoading from 'component/submitButtonWithLoading'
import { UserAccessCheckWrapper } from 'lib/accessCheck'

interface IUserInfoConfirmationStepProps {
  onSuccess: () => void
}

const UserInfoConfirmationStep: React.FC<IUserInfoConfirmationStepProps> = props => {
  const classes = useStyles()
  const user = useUser()

  const {
    nameParts,
    isUpdatingUserInfo,
    handleFirstNameChange,
    handleLastNameChange,
    handleAvatarUpload,
    handleFormSubmit,
  } = useUserInfoConfirmation(user, props.onSuccess)

  if (!user) {
    return (
      <div className={classes.loadingContainer}>
        <AppLoading visible />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Typography align="center" className={classes.header}>
        Confirm your name
      </Typography>

      <Typography align="center" className={classes.subheader}>
        This is how people see you in ThreadLive
      </Typography>

      <div className={classes.profilePictureSection}>
        <UserAvatar user={user} size={160} fontSize={70} />
        <AttachmentPicker
          uploadAttachments={handleAvatarUpload}
          fileTypes={AVATAR_FILE_TYPES}
          errorMsg={'Please select .jpg or .png file'}
        >
          <Button variant="outlined" color="primary" className={classes.buttonStyle}>
            Change Photo
          </Button>
        </AttachmentPicker>
      </div>

      <div className={classes.fieldWrapper}>
        <Typography className={classes.label}>First Name*</Typography>
        <TextField
          className={classes.field}
          defaultValue={nameParts.firstName}
          fullWidth
          onChange={handleFirstNameChange}
          InputProps={{ disableUnderline: true }}
        />
      </div>

      <div className={classes.fieldWrapper}>
        <Typography className={classes.label}>Last Name*</Typography>
        <TextField
          className={classes.field}
          defaultValue={nameParts.lastName}
          fullWidth
          onChange={handleLastNameChange}
          InputProps={{ disableUnderline: true }}
        />
      </div>

      <div className={classes.fieldWrapper}>
        <Typography className={classes.label}>Email</Typography>
        <TextField
          className={classNames(classes.field, classes.fieldDisabled)}
          defaultValue={user.email}
          disabled
          fullWidth
          InputProps={{ disableUnderline: true }}
        />
        <UserAccessCheckWrapper requiredPermissions={['user_registered']} notAllowedComponent={null}>
          <EmailProviderBadge provider={user?.emailProvider} className={classes.providerBadge} />
        </UserAccessCheckWrapper>
      </div>
      <div className={classes.buttonContainer}>
        <SubmitButtonWithLoading
          color="primary"
          isLoading={isUpdatingUserInfo}
          className={classes.submitButtonStyle}
          onClick={handleFormSubmit}
        >
          Save
        </SubmitButtonWithLoading>
      </div>
    </div>
  )
}

export default UserInfoConfirmationStep
