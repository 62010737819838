export const DEFAULT_PROJECT_PREFIX = 'Untitled Project'

export const getProjectURL = (id: string) => {
  const url = new URL(`/projects/${id}`, window.location.href)
  return url
}

export const isProjectNameStillTheDefaultOne = (projectName: string) => {
  const projectNameAsLowerCase = projectName.toLowerCase()
  return projectNameAsLowerCase.startsWith(DEFAULT_PROJECT_PREFIX.toLowerCase())
}

export const getDefaultProjectNameCount = (projectName: string) => {
  const projectCount = projectName.replace(DEFAULT_PROJECT_PREFIX, '').trim()
  const projectCountAsNumber = parseInt(projectCount) || 0
  return projectCountAsNumber
}

export const buildProjectNameFromCount = (count: number | null) =>
  count !== null ? `${DEFAULT_PROJECT_PREFIX} ${count}`.trim() : DEFAULT_PROJECT_PREFIX
