import { AnyAction } from 'redux'

import { ERROR_ACTIONS } from '../constants/actions/error'

const DEFAULT_STATE = null

export const errorReducer = (state = DEFAULT_STATE, action: AnyAction) => {
  switch (action.type) {
    case ERROR_ACTIONS.HANDLE_ERROR:
      return action.error

    default:
      return state
  }
}
