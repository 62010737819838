import React from 'react'
import { Theme, Typography, makeStyles } from '@material-ui/core'
import { SharingGroupScreenHeader } from 'component/sharingGroup/common/sharingGroupScreenHeader'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const useSharingFutureMessagesInfoScreenStyles = makeStyles((theme: Theme) => ({
  sharingFutureMessagesInfoScreenRoot: {
    paddingBottom: 20,
    paddingInline: 20,
    width: 'min(590px, 100vw)',
  },
  screenContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    fontSize: 16,
  },
  filterOptionWrapper: {
    alignItems: 'flex-start',
    backgroundColor: theme.threadPalette.lighterGray,
    borderRadius: 14,
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 500,
    padding: 6,
  },
  checkboxIcon: {
    color: theme.threadPalette.skyBlue,
    height: 18,
    marginRight: 4,
    marginTop: 2,
    width: 18,
  },
}))

interface ISharingFutureMessagesInfoScreenProps {
  onReturnToPreviousScreenClick(): void
}

export const SharingFutureMessagesInfoScreen = ({
  onReturnToPreviousScreenClick,
}: ISharingFutureMessagesInfoScreenProps) => {
  const classes = useSharingFutureMessagesInfoScreenStyles()

  return (
    <div className={classes.sharingFutureMessagesInfoScreenRoot}>
      <SharingGroupScreenHeader
        text="Sharing future messages"
        onReturnToPreviousScreenClick={onReturnToPreviousScreenClick}
      />
      <div className={classes.screenContentRoot}>
        <Typography>
          Email is complicated… Sometimes people want to have private conversations so they drop people when they reply.
          To prevent automatically sharing these private / sensitive messages, you can opt to:
        </Typography>
        <Typography className={classes.filterOptionWrapper}>
          <CheckBoxIcon className={classes.checkboxIcon} /> Share messages that include the same list of people from the
          previous shared message, and any new recipients
        </Typography>
        <Typography>
          <strong>For example:</strong>
        </Typography>
        <Typography>Imagine you share an email with a sharing group.</Typography>
        <Typography>
          <span role="img" aria-label="white-check-mark">
            ✅
          </span>{' '}
          If a recipient clicks <strong>"reply-all”</strong> to a shared message, the reply message is shared with your
          sharing group.
        </Typography>
        <Typography>
          <span role="img" aria-label="white-check-mark">
            ✅
          </span>{' '}
          If a recipient clicks <strong>"reply-all"</strong> to a shared message and adds new people, the reply message
          is shared with your sharing group.
        </Typography>
        <Typography>
          <span role="img" aria-label="x-mark">
            ❌
          </span>{' '}
          However, If a recipient replies to a shared message and removes people, the message will not be shared
          automatically.
        </Typography>
        <Typography>
          Instead, you'll have the choice to review the message and decide if you want to share it with the group.
        </Typography>
      </div>
    </div>
  )
}
