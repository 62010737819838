import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import ThreadHeader from '../../component/threadHeader'
import AppLoadingPage from '../appLoading'
import PageLayout from '../pageLayout'
import { Theme, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { isLoggedIn, handleError } from '@jetkit/react'
import { push } from 'actions/navigation'
import { checkAuth } from 'actions/checkAuth'
import { getAttachment } from 'api/attachment'
import { RouteComponentProps } from 'react-router-dom'
import * as QueryString from 'query-string'
import { showSnackbar } from 'actions/snackbar'
import { updateCurrentThread } from 'api/users'
import { IOpportunity, IOppAttribute, EMPTY_OPPORTUNITY } from 'model/opportunity'
import { IOrganizationMember } from 'model/organization'
import { ThreadPageContextProvider } from '../threadPageContextProvider'
import ThreadContent from './threadContent'
import NoThreadAccessErrorScreen from 'component/errorNoThreadAccess'
import { TResponseError } from 'api/errors/errorHandlingWrapper'
import NotFoundPage from 'page/notFound'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { SharingGroupContextProvider } from 'contexts/sharingGroupContext'
import { SharingGroupModal } from 'component/sharingGroup/sharingGroupModal'
import { useUser } from 'hooks'
import { AttachmentContentModal } from 'component/attachmentContentModal/attachmentContentModal'
import { useDisplayAttachmentContext } from 'contexts/displayAttachmentContext'
import { getThreadLiveLinkURL } from 'domain/thread'
import { getRelativeURL } from 'lib/url'
import { useUserAuthOnboardingContext } from 'contexts/userAuthOnboardingContext'
import { OnboardingTourTargetComponent } from 'domain/onboardingTourStep'

const useStyles = makeStyles((theme: Theme) => ({
  threadRoot: {
    display: 'flex',
    height: '100%',
    flex: 1,
    flexDirection: 'row',
    minWidth: 0,
  },
  fakeThreadRoot: {
    height: '100vh',
    left: theme.dimensions.leftSidebar.threadWidth,
    overflow: 'hidden',
    position: 'absolute',
    width: `calc(100vw - ${theme.dimensions.leftSidebar.threadWidth}px)`,
    zIndex: -1000,
  },
}))

interface IOpportunityContextArgs {
  opportunity?: IOpportunity
  selectedTaskId?: string
  handleOpportunitySelect(opportunity: IOpportunity): void
  onOpportunityCreated(): void
  onOpportunityDisconnected?(opportunity: IOpportunity): void
  onCurrentOpportunityUpdated?(opportunity: IOpportunity): void
  orgMembers?: IOrganizationMember[]
  dynamicOpportunityAttributes?: IOppAttribute[]
  isLoading: boolean
  openConnectDialog: boolean
  toggleConnectDialog(): void
  opportunities?: IOpportunity[]
  open: number
  purchased: number
  cancelled: number
  handleOnCreateOpportunityClick(): void
}

export interface ITypingUser {
  id: string
  name: string
  clearTimeoutCallback: () => void
}

export const OpportunityContext = React.createContext<IOpportunityContextArgs>({
  opportunity: EMPTY_OPPORTUNITY,
  onOpportunityCreated: () => undefined,
  handleOpportunitySelect: () => undefined,
  toggleConnectDialog: () => undefined,
  handleOnCreateOpportunityClick: () => undefined,
  orgMembers: [],
  dynamicOpportunityAttributes: [],
  isLoading: true,
  openConnectDialog: false,
  opportunities: [],
  open: 0,
  purchased: 0,
  cancelled: 0,
})

interface IThreadPageRouteParams {
  id?: string
}

interface IThreadPageRouteComponentProps {
  file?: string
  opportunityId?: string
  taskId?: string
}

interface IThreadPageProps extends RouteComponentProps<IThreadPageRouteComponentProps> {}

const ThreadPage = (props: IThreadPageProps) => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const params: IThreadPageRouteParams = useParams()
  const queryParams = QueryString.parse(props.location.search)
  const { setCurrentThread, responseError: userThreadAccessError, currentThread: thread } = useCurrentThreadContext()
  const loggedIn = isLoggedIn()

  const currentUser = useUser()

  const { displayAttachmentOnRightSideBar } = useDisplayAttachmentContext()
  const { openAuthOnboarding } = useUserAuthOnboardingContext()

  React.useEffect(() => {
    if (!loggedIn) {
      redirectToLiveLinkPage(params.id as string)
      return
    }
    dispatch(checkAuth())
  }, [dispatch, loggedIn, params.id])

  const redirectToLiveLinkPage = (threadId: string) => {
    const attachmentId = queryParams.file as string | undefined

    const liveLinkURL = getThreadLiveLinkURL({ id: threadId, attachmentId })
    const relativeURL = getRelativeURL(liveLinkURL)

    dispatch(push(relativeURL))
  }

  React.useEffect(() => {
    if (!loggedIn) return
    if (thread) return

    setCurrentThread(params.id)
  }, [params, loggedIn, thread?.id])

  React.useEffect(() => {
    const informThreadEnter = async (threadId: string) => {
      try {
        await updateCurrentThread(threadId)
      } catch (error) {
        handleError(error)
      }
    }

    if (thread?.id) {
      informThreadEnter(thread.id)
    }
    // eslint-disable-next-line
  }, [thread?.id])

  // Opening the attachment if its ID is present in query params or it was saved in the live-link flow
  React.useEffect(() => {
    const fetchAndOpenAttachment = async (attachmentId: string, threadId: string) => {
      const attachment = await getAttachment(attachmentId)

      if (!attachment) {
        dispatch(showSnackbar({ message: 'Attachment not found' }))
        return
      }

      displayAttachmentOnRightSideBar({ attachment, threadId })
    }

    const attachmentIdFromQueryParams = queryParams.file as string
    if (attachmentIdFromQueryParams && thread) {
      fetchAndOpenAttachment(attachmentIdFromQueryParams, thread.id)
    }
  }, [queryParams.file, thread])

  if (userThreadAccessError) {
    const threadToOpenExists = currentUser && currentUser.lastOpenedThreadId
    const onActionClick = threadToOpenExists ? undefined : () => openAuthOnboarding('threadCreation')

    const getErrorScreenMapping: Partial<Record<TResponseError, () => JSX.Element>> = {
      forbidden: () => <NoThreadAccessErrorScreen threadId={params.id as string} onActionClick={onActionClick} />,
      notFound: () => <NotFoundPage onActionClick={onActionClick} />,
    }
    const errorPage = getErrorScreenMapping[userThreadAccessError]?.()
    return <PageLayout leftSidebarOption="threads">{errorPage}</PageLayout>
  }

  if (!loggedIn || !thread) {
    return <AppLoadingPage />
  }

  if (!currentUser) return <AppLoadingPage />

  return (
    <ThreadPageContextProvider>
      <SharingGroupContextProvider>
        {/* The div that replicates header and content layout. Used in thread onboarding to highlight both. */}
        <div id={OnboardingTourTargetComponent.threadPage} className={classes.fakeThreadRoot} />
        <PageLayout
          leftSidebarOption="threads"
          headerContent={<ThreadHeader currentUser={currentUser} currentThread={thread} />}
          leftSidebarWidth={theme.dimensions.leftSidebar.threadWidth}
        >
          <div className={classes.threadRoot}>
            <ThreadContent thread={thread} currentUser={currentUser} />
          </div>
        </PageLayout>
        <SharingGroupModal />
      </SharingGroupContextProvider>
      <AttachmentContentModal />
    </ThreadPageContextProvider>
  )
}

export default ThreadPage
