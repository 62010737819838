import axios, { AxiosResponse } from 'axios'
import { PaginatedResponse } from 'api/pagedResponse'

export const getNextPageNumber = (lastPage: PaginatedResponse<any>) => {
  if (lastPage.page === lastPage.totalPages || lastPage.totalPages === 0) return

  const nextPageNumber = (lastPage.page ?? 0) + 1
  return nextPageNumber
}

export const downloadFile = async (downloadLink: string, fileName: string) => {
  // Used to download files primarily by pre-signed GET URL from S3.
  // Unfortunately files downloaded via `fileDownload from 'js-file-download'` are corrupted and can't be opened.
  // Hence we use this abomination of a function
  await axios({ url: downloadLink, method: 'GET', responseType: 'blob' }).then((response: AxiosResponse) => {
    const url: string = window.URL.createObjectURL(new Blob([response.data]))
    const link: HTMLAnchorElement = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  })
}
