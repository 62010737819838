import React from 'react'
import { makeStyles, Theme, useTheme } from '@material-ui/core'
import DialogAnchoredOnComponent from 'component/dialogAnchoredOnComponent'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import { EditButton } from './editButton'
import classNames from 'classnames'

const useDeleteSharingGroupButtonStyles = makeStyles((theme: Theme) => ({
  deleteSharingGroupButtonRoot: {
    color: theme.palette.error.main,
    fontSize: 16,
    fontWeight: 500,
    height: 40,
    paddingInline: 16,
  },
  deleteSharingGroupConfirmationPopupContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    width: 432,
  },
  confirmationMessage: {
    paddingTop: 4,
    textAlign: 'left',
  },
}))

export interface IDeleteSharingGroupButtonProps {
  sharingGroupName: string
  anchorElementRefForConfirmationPopup?: React.RefObject<HTMLElement>
  onClick?(): void
  className?: string
}

export const DeleteSharingGroupButton = ({
  sharingGroupName,
  anchorElementRefForConfirmationPopup,
  onClick,
  className,
}: IDeleteSharingGroupButtonProps) => {
  const theme = useTheme()
  const classes = useDeleteSharingGroupButtonStyles()

  const {
    isOpen: isDeleteSharingGroupConfirmationPopupOpen,
    dialogAnchorElement,
    openDialog: openDeleteSharingGroupConfirmationPopup,
    handleCloseDialog: closeDeleteSharingGroupConfirmationPopup,
    setDialogAnchorRef: setDeleteSharingGroupConfirmationPopupAnchorElement,
  } = useDialog()

  React.useEffect(() => {
    if (!anchorElementRefForConfirmationPopup?.current) return
    setDeleteSharingGroupConfirmationPopupAnchorElement(anchorElementRefForConfirmationPopup.current)
  }, [anchorElementRefForConfirmationPopup])

  const deleteGroupWarningActions = [
    {
      label: 'Cancel',
      action: closeDeleteSharingGroupConfirmationPopup,
    },
    {
      label: 'Delete group',
      labelColor: theme.palette.error.contrastText,
      action: onClick,
    },
  ]

  return (
    <DialogAnchoredOnComponent
      open={isDeleteSharingGroupConfirmationPopupOpen}
      anchoredElementRef={dialogAnchorElement}
      onClose={closeDeleteSharingGroupConfirmationPopup}
      message={
        <div className={classes.confirmationMessage}>
          Are you sure you want to delete <strong>{sharingGroupName}</strong> share group?
          <p>All group members will no longer have access to anything shared with this group.</p>
          <p>This action can't be undone</p>
        </div>
      }
      textAlign="center"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      actions={deleteGroupWarningActions}
      rootClassName={classes.deleteSharingGroupConfirmationPopupContainer}
    >
      <EditButton
        label="Delete group"
        className={classNames(classes.deleteSharingGroupButtonRoot, className)}
        onClick={openDeleteSharingGroupConfirmationPopup}
      />
    </DialogAnchoredOnComponent>
  )
}
