import { AnyAction } from 'redux'

import { AUTH_ACTIONS } from '../constants/actions/auth'
import { GOOGLE_OAUTH_ACTIONS } from '../constants/actions/googleOAuth'
import { OUTLOOK365_OAUTH_ACTIONS } from '../constants/actions/outlook365OAuth'

const DEFAULT_STATE = {
  checked: false,
  googleOAuthLoggingIn: false,
  googleOAuthHandlingCallback: false,
  outlook365OAuthLoggingIn: false,
  outlook365OAuthHandlingCallback: false,
  user: null,
}

export const authReducer = (state: IAuthState = DEFAULT_STATE, action: AnyAction): IAuthState => {
  switch (action.type) {
    case AUTH_ACTIONS.SET_CHECKED:
      return {
        ...state,
        checked: action.checked,
      }

    case GOOGLE_OAUTH_ACTIONS.LOGIN:
      return {
        ...state,
        googleOAuthLoggingIn: true,
      }

    case GOOGLE_OAUTH_ACTIONS.HANDLE_CALLBACK:
      return {
        ...state,
        googleOAuthHandlingCallback: true,
      }

    case OUTLOOK365_OAUTH_ACTIONS.LOGIN:
      return {
        ...state,
        outlook365OAuthLoggingIn: true,
      }

    case OUTLOOK365_OAUTH_ACTIONS.HANDLE_CALLBACK:
      return {
        ...state,
        outlook365OAuthHandlingCallback: true,
      }

    case AUTH_ACTIONS.SET_USER:
      return {
        ...state,
        user: action.user,
      }

    case AUTH_ACTIONS.SIGNOUT:
      return {
        ...DEFAULT_STATE,
      }

    default:
      return state
  }
}
