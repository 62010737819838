import { useMutation } from '@tanstack/react-query'
import { createDraftMessage as createDraftApiCall } from 'api/message'
import { useThreadMessagesCacheActions } from 'hooks/useThreadMessages/useThreadMessagesCacheActions'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'actions/snackbar'
import { useDraftCacheActions } from 'hooks/useDraft/useDraftCacheActions'

export type ICreateDraftUseCaseProps = Normalize<
  Parameters<typeof createDraftApiCall>[0] & Partial<Omit<IDraftMessage, 'id'>>
>

export const useCreateDraftUseCase = () => {
  const dispatch = useDispatch()

  const threadMessagesCacheActions = useThreadMessagesCacheActions()
  const draftCacheActions = useDraftCacheActions()

  const addNewDraftForRepliedOrForwardedMessage = ({
    threadId,
    repliedOrForwardedMessageId,
    draftId,
  }: ICreateDraftUseCaseProps) => {
    if (!repliedOrForwardedMessageId) return

    const addDraftIdToMessage = (message: IMessage) => {
      const draftsIDs = message.draftsIds ?? []
      const draftsIDsWithNewID = draftsIDs.includes(draftId) ? draftsIDs : [...draftsIDs, draftId]
      return { ...message, draftsIds: draftsIDsWithNewID }
    }

    return threadMessagesCacheActions.updateMessage({
      threadId,
      messageId: repliedOrForwardedMessageId,
      messageModifier: addDraftIdToMessage,
    })
  }

  const createOptimisticDraftMessage = (newDraftData: ICreateDraftUseCaseProps) => {
    const rollbackMessageOptimisticUpdate = addNewDraftForRepliedOrForwardedMessage(newDraftData)

    const currentTimestampInMilliseconds = Date.now()
    const currentTimestampInSeconds = Math.floor(currentTimestampInMilliseconds / 1000)

    const rollbackDraftOptimisticCreation = draftCacheActions.setData({
      ...newDraftData,
      id: newDraftData.draftId,
      isSyncingWithProvider: true,
      isSyncedWithProvider: false,
      draftVersion: currentTimestampInSeconds,
    })

    return () => {
      rollbackMessageOptimisticUpdate?.()
      rollbackDraftOptimisticCreation?.()
    }
  }

  const {
    mutateAsync,
    reset: resetCreateDraftMutation,
    isLoading,
  } = useMutation(
    ({ threadId, draftType, repliedOrForwardedMessageId, draftId }: ICreateDraftUseCaseProps) =>
      createDraftApiCall({
        threadId,
        draftType,
        repliedOrForwardedMessageId,
        draftId,
      }),
    {
      onMutate: newDraftData => {
        threadMessagesCacheActions.cancelDataFetching(newDraftData.threadId)
        return createOptimisticDraftMessage(newDraftData)
      },
      onSuccess: ({ previousMessageContent }, { draftId }) => {
        const updateDraftPreviousMessageContent = (draft: IDraftMessage) => {
          return { ...draft, previousMessageContent, isSyncingWithProvider: false }
        }
        draftCacheActions.updateData({
          draftId,
          draftModifier: updateDraftPreviousMessageContent,
        })
      },
      onSettled: () => {
        resetCreateDraftMutation()
      },
      onError: (_, { threadId }, rollbackOptimisticUpdate) => {
        rollbackOptimisticUpdate?.()
        threadMessagesCacheActions.refreshData(threadId)
        dispatch(showSnackbar({ message: 'Failed to create draft message' }))
      },
    }
  )

  const createDraftUseCase: (props: ICreateDraftUseCaseProps) => Promise<IAPIDraft> = mutateAsync

  return {
    createDraftUseCase,
    isLoading,
  }
}
