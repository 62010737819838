import * as React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import { Theme } from '@material-ui/core'
import UserAvatar from '../../userAvatar'
import { IActivityItem } from '../../../model/messageActivity'
import DateTimeFormatter from '../../formatters/dateTime'
import { DateTime } from 'luxon'
import moment from 'moment'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: 50,
    },

    content: {
      marginLeft: 15,
    },

    timestamp: {
      color: theme.threadPalette.lightGray,
      fontSize: 13,
      marginLeft: 20,
      marginRight: 10,
    },

    action: {
      marginLeft: 3,
      marginRight: 20,
      textOverflow: 'ellipsis',
      fontSize: 13,
      fontWeight: 'lighter',
      color: theme.threadPalette.menuItem.current,
    },

    username: {
      fontSize: 13,
      color: theme.threadPalette.menuItem.current,
      fontWeight: 'bold',
    },

    headerPart: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },

    actionTarget: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      fontSize: 13,
      whiteSpace: 'nowrap',
      maxWidth: '80%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
)

export const getActivityInterval = (activity: IActivityItem, className: string) => {
  // if more than two activities were performed in a one minute we don't want to show that as an interval
  const TIME_FORMAT = 'hh:mm'

  if (
    activity.endPeriod &&
    !(
      moment(activity.startPeriod.toISO()).format(TIME_FORMAT) ===
      moment(activity.endPeriod.toISO()).format(TIME_FORMAT)
    )
  ) {
    return (
      <div>
        <DateTimeFormatter format={DateTime.TIME_SIMPLE} value={activity.endPeriod} className={className} />
        <br></br>
        <DateTimeFormatter format={DateTime.TIME_SIMPLE} value={activity.startPeriod} className={className} />
      </div>
    )
  } else {
    return <DateTimeFormatter format={DateTime.TIME_SIMPLE} value={activity.startPeriod} className={className} />
  }
}

interface IActivityItemProps {
  activity: IActivityItem
}

const ActivityItem = ({ activity }: IActivityItemProps) => {
  const classes = useStyles()
  const { currentThread } = useCurrentThreadContext()

  const renderActivityType = React.useCallback(() => {
    const timesSuffix = activity.count === 1 ? '' : ` ${activity.count} times`
    switch (activity.type) {
      case 'download': {
        return 'downloaded'
      }
      case 'join': {
        return 'joined'
      }
      case 'share': {
        return 'shared'
      }
      case 'view_thread': {
        return 'entered' + timesSuffix
      }
      case 'view_attachment': {
        return 'viewed' + timesSuffix
      }
      case 'add_follower': {
        return 'added'
      }

      case 'remove_follower': {
        return 'removed'
      }

      case 'name_change': {
        return 'changed name to'
      }

      case 'access_requested': {
        return 'requested access'
      }

      case 'access_granted': {
        return 'granted access'
      }
    }
  }, [activity.type, activity.target])

  const renderDescription = React.useCallback(() => {
    if (activity.description) {
      return activity.description
    } else {
      if (activity.type === 'join' || activity.type === 'view_thread') {
        return currentThread?.title
      }
    }
  }, [activity.description, activity.type, currentThread])

  return (
    <div className={classes.root} id={activity.id}>
      {getActivityInterval(activity, classes.timestamp)}
      <UserAvatar user={activity.user} size={30} />
      <div className={classes.content}>
        <div className={classes.headerPart}>
          <Typography className={classes.username}>{activity.user.name}</Typography>
          <Typography className={classes.action}> {renderActivityType()}:</Typography>
        </div>
        <Typography className={classes.actionTarget}>{renderDescription()}</Typography>
      </div>
    </div>
  )
}

export default ActivityItem
