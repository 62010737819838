import { TMessageNotificationType } from './../useMessageHandler'

type TMessageHandlerCallback = (messageId: string, actionType: TMessageNotificationType) => void
type TCallbacksIdentifierMapping = Map<string, TMessageHandlerCallback>

export default class MessageHandler {
  callbacks = new Map<string, TCallbacksIdentifierMapping>()

  public addCallback = (threadId: string, callback: TMessageHandlerCallback, identifier: string) => {
    const threadCallbacksMapping: TCallbacksIdentifierMapping = this.callbacks.get(threadId) ?? new Map()

    const updatedThreadCallbacks = threadCallbacksMapping.set(identifier, callback)
    this.callbacks.set(threadId, updatedThreadCallbacks)
  }

  public removeCallback = (threadId: string, identifier: string) => {
    const threadCallbacksMapping = this.callbacks.get(threadId)
    if (!threadCallbacksMapping) return

    threadCallbacksMapping.delete(identifier)
    if (threadCallbacksMapping.size === 0) this.callbacks.delete(threadId)
  }

  public handle = (threadId: string, messageId: string, actionType: TMessageNotificationType) => {
    const threadCallbacksMapping = this.callbacks.get(threadId)
    if (!threadCallbacksMapping) return

    const messageHandlerCallbacks = threadCallbacksMapping.values()
    for (const callback of messageHandlerCallbacks) {
      callback?.(messageId, actionType)
    }
  }
}
