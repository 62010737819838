import * as React from 'react'
import EmojiIcon from './emoji'
import EmojiPicker, { IEmojiPickerProps } from 'shared/components/emojiPicker'

interface IToolbarEmojiPicker extends IEmojiPickerProps {
  className?: string
}

const ToolbarEmojiPicker = ({ onEmojiClick, className, wrapperClassName }: IToolbarEmojiPicker) => {
  return (
    <EmojiPicker onEmojiClick={onEmojiClick} wrapperClassName={wrapperClassName}>
      <EmojiIcon className={className} />
    </EmojiPicker>
  )
}

export default ToolbarEmojiPicker
