import React from 'react'
import { OnboardingStepTextLines } from '../onboardingStepTextLines'
import { InlineEmoji } from 'shared/components/inlineEmoji'

export const ProjectsDashboardStep = () => {
  const textLines = [
    <>
      Access all projects
      <InlineEmoji emoji="📓" ariaLabel="notebook" withPadding />
      with a click
    </>,
    <>Organizing threads in projects with note spaces makes information easy to find. </>,
  ]

  return <OnboardingStepTextLines textLines={textLines} maxWidth={250} />
}
