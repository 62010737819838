import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography } from '@material-ui/core'
import { YodaTrainingLukeIcon } from 'icons/yodaTrainingLuke'

const useUseThreadLiveStepStyles = makeStyles(() => ({
  useThreadLiveStepRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    marginTop: 30,
    maxWidth: 480,
  },
  headerRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 30,
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 'inherit',
    fontWeight: 500,
  },
  infoText: {
    fontSize: 18,
    lineHeight: 2,
    textAlign: 'center',
  },
  buttonRoot: {
    paddingBlock: 6,
    paddingInline: 22,
  },
  buttonLabel: {
    fontSize: 16,
  },
  yodaTrainingLukeIcon: {
    height: 240,
  },
  raisedHandsIcon: {
    paddingInline: 8,
  },
}))

interface IUseThreadLiveStepProps {
  onSuccess(): void
}

export const UseThreadLiveStep = ({ onSuccess }: IUseThreadLiveStepProps) => {
  const classes = useUseThreadLiveStepStyles()

  const raisedHandsIcon = (
    <span role="img" aria-label="Raised Hands" className={classes.raisedHandsIcon}>
      🙌
    </span>
  )

  return (
    <div className={classes.useThreadLiveStepRoot}>
      <div className={classes.headerRoot}>
        {raisedHandsIcon}
        <Typography className={classes.headerText}>You created your first thread</Typography>
        {raisedHandsIcon}
      </div>
      <Typography className={classes.infoText}>
        All future messages sent / received in your email inbox or from <strong>Thread</strong>Live will be added to
        this thread.
      </Typography>
      <YodaTrainingLukeIcon className={classes.yodaTrainingLukeIcon} />
      <Typography className={classes.infoText}>We'll help you more along the way</Typography>
      <Button
        variant="contained"
        color="primary"
        classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
        onClick={onSuccess}
      >
        Open my thread
      </Button>
    </div>
  )
}
