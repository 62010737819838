import * as React from 'react'
import PageLayout from './pageLayout'
import { RouteComponentProps } from 'react-router-dom'

interface HomePageRouteComponentProps {
  follower?: string
  account?: string
  projectId?: string
  projectName?: string
  connectedThreadId?: string
}

interface IHomePagePageProps extends RouteComponentProps<HomePageRouteComponentProps> {}

const HomePage = (props: IHomePagePageProps) => {
  React.useEffect(() => {
    if (!('Notification' in window)) {
      console.debug('This browser does not support desktop notifications')
    } else if (Notification.permission === 'default') {
      Notification.requestPermission()
    }
  })

  return <PageLayout></PageLayout>
}

export default HomePage
