import React from 'react'
import classNames from 'classnames'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core'
import { useCopyContentToClipboard } from 'hooks/useCopyContentToClipboard'

const useCopyableContentStyles = makeStyles((theme: Theme) => ({
  copyableContent: {
    cursor: 'copy',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

type ICopyableTypographyProps = TypographyProps<'span'>

const CopyableTypography = ({ children, onClick, className, ...typographyProps }: ICopyableTypographyProps) => {
  if (React.Children.count(children) > 1)
    throw new Error('Copyable Typography can only accept one string elements as children')

  const classes = useCopyableContentStyles()

  const { copyContentToClipboard } = useCopyContentToClipboard()

  const customOnClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    copyContentToClipboard(children as string)
    onClick?.(event)
  }

  return (
    <Typography {...typographyProps} onClick={customOnClick} className={classNames(className, classes.copyableContent)}>
      {children}
    </Typography>
  )
}

export default CopyableTypography
