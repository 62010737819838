import * as React from 'react'
import { makeStyles, ClassNameMap } from '@material-ui/styles'
import { Theme, Typography, Divider } from '@material-ui/core'
import { IEmailThread } from 'model/emailThread'
import RadioButton from 'component/radioButton/radioButton'
import { simpleDateFormat } from 'dataProcessors/dateTime'
import Sent from 'icons/sent'
import { DateTime } from 'luxon'

interface IThreadListItemStylesProps {
  isDisabled?: boolean
  isSelected?: boolean
}

const useThreadListItemStyles = makeStyles<Theme, IThreadListItemStylesProps>((theme: Theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    cursor: ({ isDisabled }) => (isDisabled ? 'normal' : 'pointer'),
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  subject: {
    color: ({ isDisabled }) => (isDisabled ? theme.threadPalette.lightGray : theme.threadPalette.menuItem.current),
    fontSize: 14,
    fontWeight: ({ isSelected, isDisabled }) => {
      if (isDisabled || isSelected) return 'bold'
      return 500
    },
    marginLeft: 20,
    maxWidth: 'calc(100% - 340px)',
  },
  timestamp: {
    alignSelf: 'center',
    color: ({ isDisabled }) => (isDisabled ? theme.threadPalette.lightGray : theme.threadPalette.menuItem.current),
    fontSize: 13,
    width: '100%',
  },
  sentAtOrConnectedDetails: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'flex-end',
    marginRight: 15,
    position: 'relative',
  },
  checkMark: {
    fill: theme.threadPalette.green,
  },
  connected: {
    color: theme.threadPalette.green,
    fontFamily: 'Roboto',
    fontSize: 13,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 'normal',
    lineHeight: 'normal',
    marginLeft: 7,
    textAlign: 'right',
  },
  threadMessagesInfo: {
    width: 160,
    display: 'flex',
    flexDirection: 'row',
  },
  messageCount: {
    color: ({ isDisabled }) => (isDisabled ? theme.threadPalette.lightGray : theme.threadPalette.menuItem.current),
    fontSize: 14,
    marginLeft: 4,
  },
  senderNames: {
    color: ({ isDisabled }) => (isDisabled ? theme.threadPalette.lightGray : theme.threadPalette.menuItem.current),
    fontSize: 14,
    maxWidth: 130,
  },
}))

interface IThreadListItemProps {
  isChecked: boolean
  emailThread: IEmailThread
  onCheck: (emailThreadId: string) => void
  showDivider?: boolean
}

const ThreadListItem = ({ isChecked, emailThread, onCheck, showDivider = true }: IThreadListItemProps) => {
  const classes = useThreadListItemStyles({ isDisabled: emailThread.isConnected, isSelected: isChecked })

  const onEmailThreadSelected = React.useCallback(() => {
    if (emailThread.isConnected) return
    onCheck(emailThread.conversationId)
  }, [emailThread.isConnected, emailThread.conversationId, onCheck])

  const firstUserName = emailThread.lastSenderDisplayNames[0]

  const threadUsersDisplayNames = emailThread.lastSenderDisplayNames.reduce(
    (accumulatedDisplayName, senderName, index) => {
      if (index === 0) return ''

      const separator = index !== 0 ? ', ' : ''
      accumulatedDisplayName += `${separator}${senderName}`

      return accumulatedDisplayName
    },
    ''
  )

  return (
    <div>
      {showDivider && <Divider />}
      <div onClick={onEmailThreadSelected} className={classes.root}>
        <RadioButton checked={isChecked} disabled={emailThread.isConnected} />
        <div className={classes.threadMessagesInfo}>
          <Typography className={classes.senderNames} noWrap>
            <b>{firstUserName}</b>
            {threadUsersDisplayNames}
          </Typography>
          <Typography className={classes.messageCount}>{` | ${emailThread.messageCount}`}</Typography>
        </div>
        <Typography className={classes.subject} noWrap>
          {emailThread.subject}
        </Typography>
        <div className={classes.sentAtOrConnectedDetails}>
          <ThreadItemDateOrConnectedMark
            date={emailThread.updatedAt}
            isConnected={emailThread.isConnected}
            classes={classes}
          />
        </div>
      </div>
    </div>
  )
}

interface IThreadItemDateOrConnectedMarkProps {
  date: DateTime
  isConnected: boolean
  classes: ClassNameMap<string>
}

const ThreadItemDateOrConnectedMark = ({ date, isConnected, classes }: IThreadItemDateOrConnectedMarkProps) => {
  const connectedMark = (
    <>
      <Sent className={classes.checkMark} />
      <Typography className={classes.connected}>Connected</Typography>
    </>
  )
  const dateMark = (
    <Typography align="right" className={classes.timestamp}>
      {simpleDateFormat(date, 'MMM dd - t')}
    </Typography>
  )
  return <>{isConnected ? connectedMark : dateMark}</>
}

export default ThreadListItem
