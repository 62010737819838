import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

// the value in unreadCount to hide the count
const MANUAL_UNREAD = -1

interface IUseUnreadCircleStylesProps {
  size: number
  internalCircleSize: number
}

export const useStyles = makeStyles<Theme, IUseUnreadCircleStylesProps>((theme: Theme) => {
  return {
    orangeCircle: {
      alignItems: 'center',
      backgroundColor: 'orange',
      borderRadius: ({ size }) => size / 2,
      display: 'flex',
      height: ({ size }) => size,
      justifyContent: 'center',
      margin: '0 5px 1px 0',
      width: ({ size }) => size,
    },
    whiteCircle: {
      width: ({ internalCircleSize }) => internalCircleSize,
      height: ({ internalCircleSize }) => internalCircleSize,
      borderRadius: ({ internalCircleSize }) => internalCircleSize / 2,
      backgroundColor: theme.threadPalette.white,
    },
    unreadCount: {
      color: theme.threadPalette.white,
      fontSize: '12px',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
    },
  }
})

interface IUnreadOrangeCircleProps {
  unreadCount?: number
  size?: number
  internalCircleSize?: number
}

const UnreadOrangeCircle = ({ unreadCount = -1, size = 20, internalCircleSize = 4 }: IUnreadOrangeCircleProps) => {
  const classes = useStyles({ size, internalCircleSize })

  const internalCircleContent =
    unreadCount === MANUAL_UNREAD ? (
      <div className={classes.whiteCircle}></div>
    ) : (
      <div className={classes.unreadCount}>{unreadCount}</div>
    )

  return <div className={classes.orangeCircle}>{internalCircleContent}</div>
}

export default UnreadOrangeCircle
