import * as React from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import AttachmentDisplay from './attachmentDisplay'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { useDisplayAttachmentContext } from 'contexts/displayAttachmentContext'

const useAttachmentRightSidebarPanelStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachmentPreviewContentRoot: {
      position: 'absolute',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100vw',
      },
    },
  })
)

export const AttachmentRightSidebarPanel = () => {
  const classes = useAttachmentRightSidebarPanelStyles()

  const { currentThread } = useCurrentThreadContext()

  const { attachment, closeAttachment } = useDisplayAttachmentContext()

  if (!attachment || !currentThread) return null

  return (
    <AttachmentDisplay
      attachment={attachment}
      currentThread={currentThread}
      onClose={closeAttachment}
      contentClassName={classes.attachmentPreviewContentRoot}
    />
  )
}
