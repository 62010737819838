import React from 'react'
import HeadProvider from '.'
import useCurrentUrl from '../../hooks/useCurrentUrl'

const AppMainHead = () => {
  const { currentHost } = useCurrentUrl()

  return (
    <HeadProvider pageTitle="Threadlive" ogTitle="Login into Threadlive" ogTwitter={{ site: '@thread' }}>
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      <meta charSet="utf-8" />
      <meta name="theme-color" content="#000000" />
      <meta name="msapplication-TileImage" content={`${currentHost}/logo.png`} />
      <link rel="icon" href={`${currentHost}/favicon.ico`} />
      <link rel="apple-touch-icon" href={`${currentHost}/logo.png`} />
      <link rel="manifest" href={`${currentHost}/manifest.json`} />
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet" />
    </HeadProvider>
  )
}

export default AppMainHead
