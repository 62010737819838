import { AxiosResponse } from 'axios'
import { PaginatedResponse } from './../api/pagedResponse'
import camelcaseKeys from 'camelcase-keys'

export function PagedResponseDataProcessor<T>(
  response: AxiosResponse<PaginatedResponse<T>>,
  dataProcessor?: (items: T[]) => unknown[]
) {
  const orgPaginationHeader = response.headers?.['x-pagination']
  const paginationHeader = orgPaginationHeader && camelcaseKeys(JSON.parse(orgPaginationHeader))

  return {
    ...paginationHeader,
    data:
      Array.isArray(response.data) &&
      (dataProcessor ? dataProcessor(response.data.map((item: T) => item)) : response.data.map((item: T) => item)),
  }
}
