import React from 'react'
import { Dialog, Theme, Typography, fade, makeStyles } from '@material-ui/core'
import { IProject } from 'model/project'
import { useProjectsTreeContext } from './projectsTreeContext'
import { ProjectNode } from './projectNode'
import classNames from 'classnames'
import CloseIcon from '@material-ui/icons/Close'

const useUpdateParentProjectDialogStyles = makeStyles((theme: Theme) => ({
  updateParentProjectDialogRoot: {
    minWidth: 'min(800px, 100vw)',
    borderRadius: 18,
    overflow: 'unset',
  },
  projectsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingBlock: 18,
    paddingInline: 12,
    overflowY: 'auto',
  },
  header: {
    fontSize: 24,
    fontWeight: 500,
    paddingBlock: 14,
    backgroundColor: theme.threadPalette.lightishGray,
    color: theme.threadPalette.brownishGray,
    textAlign: 'center',
    borderTopRightRadius: 18,
    borderTopLeftRadius: 18,
  },
  rootProjectChip: {
    backgroundColor: theme.threadPalette.white,
    border: `1px solid ${theme.threadPalette.lightishGray}`,
    borderRadius: 12,
    color: theme.threadPalette.menuItem.current,
    paddingBlock: 10,
    paddingInline: 20,
    width: '100%',
    '&:hover': {
      boxShadow: `0 1px 3px 0 ${fade(theme.threadPalette.darkGray, 0.5)}`,
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  rootProjectChipLabel: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'center',
  },
  projectRootNode: {
    backgroundColor: theme.threadPalette.white,
    border: `1px solid ${theme.threadPalette.lightishGray}`,
    borderRadius: 12,
    color: theme.threadPalette.menuItem.current,
    '&:hover': {
      boxShadow: `0 1px 3px 0 ${fade(theme.threadPalette.darkGray, 0.5)}`,
    },
    paddingBlock: 4,
    paddingInline: 16,
  },
  closeIconWrapper: {
    color: theme.threadPalette.white,
    cursor: 'pointer',
    position: 'absolute',
    right: -20,
    top: -20,
    '&:hover': {
      color: theme.threadPalette.lightGray,
    },
  },
  closeIcon: {
    height: 26,
    width: 26,
  },
}))

interface IUpdateParentProjectDialogProps {
  projects: IProject[]
}

export const UpdateParentProjectDialog = ({ projects }: IUpdateParentProjectDialogProps) => {
  const classes = useUpdateParentProjectDialogStyles()

  const { isMovingProject, movingProject, setMovingProject, updateParentForSelectedProject } = useProjectsTreeContext()

  const isUpdatingParentForRootProject = !movingProject?.parentProjectId
  const showMoveProjectToRootChip = isMovingProject && !isUpdatingParentForRootProject

  const onClose = () => setMovingProject(undefined)
  const moveProjectToRoot = () => updateParentForSelectedProject(undefined)

  return (
    <Dialog open={isMovingProject} onClose={onClose} classes={{ paper: classes.updateParentProjectDialogRoot }}>
      <span className={classNames(classes.closeIconWrapper)} onClick={onClose}>
        <CloseIcon className={classes.closeIcon} />
      </span>
      <Typography className={classes.header}>Move project</Typography>
      <div className={classes.projectsList}>
        {showMoveProjectToRootChip && (
          <div className={classes.rootProjectChip} onClick={moveProjectToRoot}>
            <Typography className={classes.rootProjectChipLabel}>Move project to the root</Typography>
          </div>
        )}
        {projects.map(project => (
          <div key={project.id} className={classes.projectRootNode}>
            <ProjectNode key={project.id} project={project} showActionsButton={false} />
          </div>
        ))}
      </div>
    </Dialog>
  )
}
