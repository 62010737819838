import { handleError } from '@jetkit/react'
import { PaginatedResponse } from './../api/pagedResponse'
import React from 'react'
import { TabContentScrolledContext } from 'component/tabs/tabPanel'

interface IPaginationState<T> {
  data: T[]
  page: number
  totalPages: number
}

export function usePagination<T>(
  loadNextPage: (page: number, searchPhrase?: string, mode?: string) => Promise<PaginatedResponse<T[]>>,
  searchPhrase?: string,
  mode?: string,
  isReversed?: boolean
) {
  const [paginationState, setPaginationState] = React.useState<IPaginationState<T>>({
    data: [],
    page: 1,
    totalPages: 1,
  })
  const [isLoading, setIsLoading] = React.useState(false)
  const { isBottom } = React.useContext(TabContentScrolledContext)

  React.useEffect(() => {
    if (isBottom) {
      setPaginationState(prev => {
        return {
          ...prev,
          page: prev.page + 1,
        }
      })
    }
  }, [isBottom])

  const incrementPage = React.useCallback(() => {
    setPaginationState(prev => {
      return {
        ...prev,
        page: prev.page + 1,
      }
    })
  }, [])

  const loadMoreData = React.useCallback(
    async (page: number, searchPhrase?: string) => {
      try {
        setIsLoading(true)
        const result = await loadNextPage(page, searchPhrase, mode)
        setPaginationState(prev => {
          return {
            ...prev,
            totalPages: result.totalPages,
            data: page > 1 && prev.data.length > 0 ? [...prev.data, ...result?.data] : result.data,
          }
        })
      } catch (error) {
        handleError(error)
      } finally {
        setIsLoading(false)
      }
    },
    [loadNextPage, mode]
  )

  React.useEffect(() => {
    if (isLoading) return

    if (paginationState.page <= paginationState.totalPages) {
      loadMoreData(paginationState.page, searchPhrase)
    }
  }, [paginationState.page, paginationState.totalPages, searchPhrase])

  const reloadData = React.useCallback(async () => {
    setPaginationState({
      data: [],
      page: 1,
      totalPages: 1,
    })
    loadMoreData(1, searchPhrase)
  }, [loadMoreData, searchPhrase])

  React.useEffect(() => {
    reloadData()
  }, [reloadData, searchPhrase])

  const handleScrollEvent = React.useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const target = event.currentTarget
      // scrollHeight must be > 0 to detect actual user scroll
      const isTopPosition = target.scrollTop === 0 && target.scrollHeight > 0
      const isClientAtBottomScrollPosition = target.scrollHeight - target.scrollTop === target.clientHeight

      const condition = isReversed ? isTopPosition : isClientAtBottomScrollPosition
      if (condition) {
        incrementPage()
      }
    },
    [incrementPage, isReversed]
  )

  const setData = React.useCallback((data: T[]) => {
    setPaginationState(prev => {
      return {
        ...prev,
        data: data,
      }
    })
  }, [])

  return {
    handleScrollEvent,
    reloadData,
    data: paginationState.data,
    setData,
    isLoading,
    page: paginationState.page,
    triggerNextPageLoad: incrementPage,
  }
}
