import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import { SelectSharingGroupIcon } from 'icons/onboarding/selectSharingGroupIcon'
import { OnboardingStepWithImage } from '../onboardingStepWithImage'

const useCreateNewGroupStepStyles = makeStyles((theme: Theme) => ({
  selectSharingGroupInputIcon: {
    width: 450,
  },
  shareLabel: {
    color: theme.palette.primary.main,
    display: 'inline-flex',
    gap: 6,
    paddingInline: 4,
    verticalAlign: 'bottom',
  },
}))

export const CreateNewGroupStep = () => {
  const classes = useCreateNewGroupStepStyles()

  const textLines = [
    <>
      Click
      <span className={classes.shareLabel}>
        <GroupIcon />
        Share
      </span>
      and choose the group of people you want to share with.
    </>,
  ]

  return (
    <OnboardingStepWithImage
      headerText="How to share a thread"
      stepNumber={1}
      image={<SelectSharingGroupIcon className={classes.selectSharingGroupInputIcon} />}
      topTextLines={textLines}
      textLinesMaxWidth={290}
    />
  )
}
