import GenerateEmailContentPage from 'component/assistantPage/generateEmailContentPage'
import { BasePageHeader } from 'page/baseHeader'
import PageLayout from 'page/pageLayout'
import React from 'react'

const AssistantPage = () => {
  return (
    <PageLayout leftSidebarOption="assistant" headerContent={<BasePageHeader title="AI Assistant" />}>
      <GenerateEmailContentPage />
    </PageLayout>
  )
}

export default AssistantPage
