import { IActivityItem, IAPIActivityItem } from './../model/messageActivity'
import { DateTime } from 'luxon'

export const mapActivityItems = (items: IAPIActivityItem[]): IActivityItem[] => {
  return items.map((item: IAPIActivityItem) => {
    return {
      ...item,
      startPeriod: DateTime.fromISO(item.startPeriod),
      endPeriod: item.endPeriod ? DateTime.fromISO(item.endPeriod) : undefined,
    }
  })
}
