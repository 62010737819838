import * as React from 'react'

interface IDropdownIconProps {
  className?: string
}

const DropdownIcon = (props: IDropdownIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path
        fill="#2196F3"
        fillRule="evenodd"
        d="M9.314 14.715c.182.182.429.285.686.285.257 0 .505-.103.686-.285l7.03-7.053c.379-.38.379-.997 0-1.377-.38-.38-.994-.38-1.372 0L10 12.65 3.656 6.285c-.378-.38-.993-.38-1.372 0-.379.38-.379.996 0 1.377l7.03 7.053z"
      />
    </svg>
  )
}

export default DropdownIcon
