import React from 'react'

interface IUseMessageContentProps {
  content: string
  previousMessageContent?: string
}

export const useMessageContent = ({ content, previousMessageContent }: IUseMessageContentProps) => {
  const getIsTablePresentOnMessageContent = (content: string) => {
    // detects html (potentially excel) tables in the message content
    const tableTags = [/<table.*>/, /<td.*>/, /<tr.*>/, /<thead.*>/, /<tbody.*>/]

    return tableTags.some(tableTag => content.match(tableTag))
  }

  const isTablePresentOnMessageContent = React.useMemo(() => {
    return getIsTablePresentOnMessageContent(content)
  }, [content])

  const isTablePresentOnPreviousMessageContent = React.useMemo(() => {
    if (!previousMessageContent) return false
    return getIsTablePresentOnMessageContent(previousMessageContent)
  }, [previousMessageContent])

  const isTablePresentOnMessageContentOrPreviousMessage =
    isTablePresentOnMessageContent || isTablePresentOnPreviousMessageContent

  return {
    isTablePresentOnMessageContent,
    isTablePresentOnPreviousMessageContent,
    isTablePresentOnMessageContentOrPreviousMessage,
  }
}
