import * as React from 'react'
import { Typography, Divider } from '@material-ui/core'
import { IMenuItem } from 'component/menu/menu'
import { TAccountPage } from 'component/account/account'
import classNames from 'classnames'
import { useSettingsMenu } from './useSettingsMenu'
import { useStyles } from './settingsMenu.style'
import { UserAccessCheckWrapper } from 'lib/accessCheck'

interface ISettingsMenuProps {
  items: IMenuItem<TAccountPage>[]
  onItemSelected(item: TAccountPage): void
}

interface ISettingsMenuItemProps {
  item: IMenuItem<TAccountPage>
  onItemSelected(item: TAccountPage): void
}

const SettingsMenuItem = (props: ISettingsMenuItemProps) => {
  const classes = useStyles()

  const { selectedItem, handleOnItemClick } = useSettingsMenu(props.onItemSelected)

  const getItemClassNames = React.useCallback(
    (item: string) => {
      return classNames(classes.baseItem, { [classes.selectedItem]: item === selectedItem })
    },
    [classes, selectedItem]
  )

  return (
    <div key={props.item.value}>
      <Typography className={getItemClassNames(props.item.value)} onClick={() => handleOnItemClick(props.item.value)}>
        {props.item.label}
      </Typography>
      <Divider className={classes.divider} />
    </div>
  )
}

const SettingsMenu = ({ items, onItemSelected }: ISettingsMenuProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.menuContainer}>
        {items.map(item => {
          // if menu item has required permissions on it, want to wrap it in the access checker
          if (item.requiredPermissions)
            return (
              <UserAccessCheckWrapper
                key={item.value}
                requiredPermissions={item.requiredPermissions}
                notAllowedComponent={null}
              >
                <SettingsMenuItem item={item} onItemSelected={onItemSelected} />
              </UserAccessCheckWrapper>
            )
          else return <SettingsMenuItem key={item.value} item={item} onItemSelected={onItemSelected} />
        })}
      </div>
      <Divider className={classes.verticalDivider} orientation="vertical" />
    </div>
  )
}

export default SettingsMenu
