import { apiClient } from '@jetkit/react'
import { IOrganizationMember, IOrgSettings } from 'model/organization'

export const getOrgMembers = async (organizationId: string): Promise<IOrganizationMember[]> => {
  const result = await apiClient.get(`/organization/${organizationId}/members`)
  return result.data
}

// replace the endpoint with something more suited to this use-case TODO
export const checkEmailStatus = async (email: string): Promise<IUser> => {
  const result = await apiClient.get(`/user`, {
    params: {
      email: email,
    },
  })
  return result.data[0]
}

export const getOrgSettings = async (organizationId: string): Promise<IOrgSettings> => {
  const result = await apiClient.get(`/organization/${organizationId}/settings`)
  return result.data
}

export const addNewOrgMember = async (
  orgId: string,
  email: string,
  firstName?: string,
  lastName?: string
): Promise<IOrganizationMember> => {
  const result = await apiClient.post(`/organization/${orgId}/invite`, {
    email,
    name: `${firstName} ${lastName}`,
  })
  return result.data
}

export const removeMember = async (orgId: string, memberId: string): Promise<void> =>
  (await apiClient.delete(`/organization/${orgId}/members/${memberId}`)).data

export const acceptUserInvite = async (orgId: string, memberId: string): Promise<void> =>
  (await apiClient.post(`/organization/${orgId}/invite/accept`, { member_id: memberId })).data

export const denyUserInvite = async (orgId: string, memberId: string): Promise<void> =>
  (await apiClient.post(`/organization/${orgId}/invite/deny`, { member_id: memberId })).data

export const acceptInvite = async (orgId: string): Promise<void> =>
  (await apiClient.post(`/organization/${orgId}/invite/accept`)).data

export const denyInvite = async (orgId: string): Promise<void> =>
  (await apiClient.post(`/organization/${orgId}/invite/deny`)).data

export const switchOrganization = async (): Promise<void> => (await apiClient.patch(`/user/switch_org_tier`)).data

export const addAdmin = async (orgId: string, memberId: string): Promise<void> =>
  (await apiClient.patch(`/organization/${orgId}/members/${memberId}/change-owner`)).data

export const updateOrgSettings = async (orgId: string, settings: Partial<IOrgSettings>): Promise<IOrgSettings> => {
  const result = await apiClient.patch(`/organization/${orgId}/settings`, settings)
  return result.data
}
