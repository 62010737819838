import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { useDispatch } from 'react-redux'
import { downloadFile } from 'lib/api'
import IAttachment from 'model/attachment'
import { useSendAttachmentAnalyticsEventUseCase } from 'usecases/analytics/attachment'
import { getAttachmentDownloadUrl } from 'api/attachment'

type IAttachmentToDownload = Rename<
  Rename<Pick<IAttachment, 'id' | 'filename'>, 'id', 'attachmentId'>,
  'filename',
  'fileName'
> & { threadId: string }

export const useDownloadAttachmentUseCase = () => {
  const dispatch = useDispatch()

  const { sendAttachmentAnalyticsEventUseCase } = useSendAttachmentAnalyticsEventUseCase()

  const downloadAttachment = async ({ attachmentId, fileName }: IAttachmentToDownload) => {
    const downloadUrl = await getAttachmentDownloadUrl(attachmentId)
    await downloadFile(downloadUrl, fileName)
  }

  const { mutateAsync: runDownloadAttachmentMutation, isLoading } = useMutation(downloadAttachment, {
    onMutate: ({ attachmentId, threadId }) =>
      sendAttachmentAnalyticsEventUseCase({ attachmentId, action: 'downloaded', threadId }),
    onError: () => dispatch(showSnackbar({ message: 'Error on downloading attachment' })),
  })

  const downloadAttachmentUseCase: typeof downloadAttachment = runDownloadAttachmentMutation

  return { downloadAttachmentUseCase, isLoading }
}
