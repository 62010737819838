import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { getThreadMessagesQueryKey, TMessageListData } from './useThreadMessagesList'
import { BasePaginatedResponse, PaginatedResponse } from 'api/pagedResponse'
import { useUser } from 'hooks/auth'

export const useThreadMessagesCacheActions = () => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const refreshData = React.useCallback(
    (threadId: string) => {
      if (!currentUser) return

      queryClient.invalidateQueries({ queryKey: getThreadMessagesQueryKey({ userId: currentUser.id, threadId }) })
    },
    [queryClient, currentUser?.id]
  )

  const cancelDataFetching = React.useCallback(
    (threadId: string) => {
      if (!currentUser) return
      queryClient.cancelQueries({ queryKey: getThreadMessagesQueryKey({ userId: currentUser.id, threadId }) })
    },
    [queryClient]
  )

  const getData = React.useCallback(
    (threadId: string) => {
      if (!currentUser) return

      const queryKey = getThreadMessagesQueryKey({ userId: currentUser.id, threadId })
      return queryClient.getQueryData<TMessageListData>(queryKey)
    },
    [queryClient, currentUser?.id]
  )

  const createEmptyThreadMessagesData = () => {
    return {
      pageParams: [null],
      pages: [],
    }
  }

  const createEmptyThreadMessagesPage = (paginationParams: BasePaginatedResponse): PaginatedResponse<IMessage[]> => {
    return {
      ...paginationParams,
      data: [],
    }
  }

  const addMessage = React.useCallback(
    ({ threadId, newMessage }: { threadId: string; newMessage: IMessage }) => {
      if (!currentUser) return

      const messagesData = getData(threadId) ?? createEmptyThreadMessagesData()

      const pageParams = messagesData.pageParams
      const messagePages = messagesData.pages

      const firstPage =
        messagePages.shift() ??
        createEmptyThreadMessagesPage({ total: 1, firstPage: 1, lastPage: 1, page: 1, totalPages: 1 })

      const firstPageWithNewMessage = { ...firstPage, data: [newMessage, ...firstPage.data] }

      const queryKey = getThreadMessagesQueryKey({ userId: currentUser.id, threadId })
      queryClient.setQueryData(queryKey, { pageParams: pageParams, pages: [firstPageWithNewMessage, ...messagePages] })
    },
    [queryClient, currentUser?.id]
  )

  const updateMessage = React.useCallback(
    ({
      threadId,
      messageId,
      messageModifier,
    }: {
      threadId: string
      messageId: string
      messageModifier: (message: IMessage) => IMessage
    }) => {
      const messagesData = getData(threadId)

      if (!messagesData || !currentUser) return

      const pageParams = messagesData.pageParams
      const messagePages = messagesData.pages

      const messagePagesUpdated = messagePages.map(messagePage => {
        const messageList = messagePage.data

        const messageListUpdated = messageList.map(message =>
          message.id === messageId ? messageModifier(message) : message
        )

        return { ...messagePage, data: messageListUpdated }
      })

      const queryKey = getThreadMessagesQueryKey({ userId: currentUser.id, threadId })
      queryClient.setQueryData(queryKey, { pageParams: pageParams, pages: messagePagesUpdated })

      return () => {
        queryClient.setQueryData(queryKey, messagesData)
      }
    },
    [queryClient, currentUser?.id]
  )

  return {
    refreshData,
    addMessage,
    updateMessage,
    cancelDataFetching,
  }
}
