import React from 'react'
import { FormControlLabel, Link, Theme, Typography, makeStyles } from '@material-ui/core'
import { IAutoShareCondition } from 'model/sharingGroup'
import {
  getThreadSharingSettingsAutoShareConditionsTypes,
  isThreadSharingSettingsShareAllFutureMessages,
} from 'domain/sharingGroup'
import classNames from 'classnames'
import { CustomCheckbox } from 'shared/inputs/customCheckbox'

const useAutoShareConditionsPickerStyles = makeStyles((theme: Theme) => ({
  autoShareConditionsPickerRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  checkboxesSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginLeft: 6,
  },
  formControlLabel: {
    fontSize: 15,
    marginLeft: 6,
    maxWidth: 420,
  },
  formControlLabelRoot: {
    margin: 0,
    padding: 0,
  },
  checkboxContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
  },
  checkboxRoot: {
    paddingTop: 3,
  },
}))

interface IAutoShareConditionsPickerProps {
  rootClassName?: string
  selectedAutoShareConditions?: IAutoShareCondition[]
  setAutoShareConditions(autoShareCondition?: IAutoShareCondition[]): void
  openSharingFutureMessagesInfo?(): void
}

type AutoShareOption = 'allFutureMessages' | 'replyToSharedMessageWithSameAudience'

export const AutoShareConditionsPicker = ({
  rootClassName,
  selectedAutoShareConditions,
  setAutoShareConditions,
  openSharingFutureMessagesInfo,
}: IAutoShareConditionsPickerProps) => {
  const classes = useAutoShareConditionsPickerStyles()

  const selectedAutoShareConditionsTypes = getThreadSharingSettingsAutoShareConditionsTypes({
    autoShareConditions: selectedAutoShareConditions,
  })

  const isSharingAllFutureMessages = isThreadSharingSettingsShareAllFutureMessages({
    autoShareConditions: selectedAutoShareConditions,
  })
  const isSharingOnlyReplyToSharedMessageWithSameAudience = selectedAutoShareConditionsTypes.includes(
    'reply_to_shared_message_with_same_audience'
  )

  const autoShareOptionToConditionsMapping: Record<AutoShareOption, IAutoShareCondition[]> = {
    allFutureMessages: [],
    replyToSharedMessageWithSameAudience: [{ type: 'reply_to_shared_message_with_same_audience' }],
  }

  const handleCheckboxClick = (event: React.MouseEvent) => {
    const eventTarget = event.target as HTMLInputElement
    const isOptionChecked = eventTarget.checked

    if (!isOptionChecked) {
      setAutoShareConditions(undefined)
      return
    }

    const autoShareOption = eventTarget.value as AutoShareOption
    setAutoShareConditions(autoShareOptionToConditionsMapping[autoShareOption])
  }

  const onOpenSharingFutureMessagesInfoClick = (event: React.MouseEvent) => {
    if (!openSharingFutureMessagesInfo) return

    event.stopPropagation()
    event.preventDefault()

    openSharingFutureMessagesInfo()
  }

  const openSharingFutureMessagesInfoButton = (
    <>
      (
      <Link underline="none" target="_blank" rel="noopener" onClick={onOpenSharingFutureMessagesInfoClick}>
        more info
      </Link>
      )
    </>
  )

  const replyToSharedMessageWithSameAudienceLabel = (
    <Typography className={classes.formControlLabel}>
      Share messages that include the same list of people from the previous shared message, and any new recipients{' '}
      {openSharingFutureMessagesInfo && openSharingFutureMessagesInfoButton}
    </Typography>
  )

  return (
    <div className={classNames(classes.autoShareConditionsPickerRoot, rootClassName)}>
      <Typography className={classes.title}>Future messages</Typography>
      <section className={classes.checkboxesSection}>
        <FormControlLabel
          classes={{ root: classes.formControlLabelRoot }}
          className={classes.checkboxContainer}
          control={
            <CustomCheckbox
              value="replyToSharedMessageWithSameAudience"
              name="reply-to-shared-message-with-same-audience"
              checked={isSharingOnlyReplyToSharedMessageWithSameAudience}
              onClick={handleCheckboxClick}
              color="primary"
              className={classes.checkboxRoot}
            />
          }
          label={replyToSharedMessageWithSameAudienceLabel}
        />
        <FormControlLabel
          classes={{ label: classes.formControlLabel, root: classes.formControlLabelRoot }}
          className={classes.checkboxContainer}
          control={
            <CustomCheckbox
              value="allFutureMessages"
              name="all-future-messages"
              checked={isSharingAllFutureMessages}
              onClick={handleCheckboxClick}
              color="primary"
              className={classes.checkboxRoot}
            />
          }
          label="All future messages I receive"
        />
      </section>
    </div>
  )
}
