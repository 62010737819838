import { useMutation } from '@tanstack/react-query'
import { updateDraftMessage as updateDraftApiCall } from 'api/message'
import { getRecipientsEmails } from 'domain/messageRecipient'
import { insertInlineAttachments } from 'lib/message'
import IAttachment, { IInlineAttachment } from 'model/attachment'
import { ServerError } from 'api/errors/serverError'
import { TDraftProcessingErrorReason, getErrorReasonFromError } from './errors'
import { useDraftCacheActions } from 'hooks/useDraft/useDraftCacheActions'

interface IUseUpdateDraftUseCaseProps {
  onError?: (reason?: TDraftProcessingErrorReason) => void
}

type UpdateDraftApiCallReturnType = ReturnType<typeof updateDraftApiCall>

interface IUpdateDraftMessageProps {
  threadId: string
  draftId: string
  repliedOrForwardedMessageId: string
  content: string
  standardAttachments: IAttachment[]
  inlineAttachments: IInlineAttachment[]
  toRecipients: TDraftRecipient[]
  bccRecipients: TDraftRecipient[]
  isPreviousMessageContentEdited: boolean
  scheduleSyncingWithProvider: boolean
  draftVersion: number
}

export const useUpdateDraftUseCase = ({ onError }: IUseUpdateDraftUseCaseProps = {}) => {
  const draftCacheActions = useDraftCacheActions()

  const applyDraftUpdates = async ({
    draftId,
    content,
    standardAttachments,
    inlineAttachments,
    toRecipients,
    bccRecipients,
    isPreviousMessageContentEdited,
    scheduleSyncingWithProvider = true,
    draftVersion,
  }: IUpdateDraftMessageProps) => {
    const { content: contentWithInlineAttachments, inlineAttachments: referencedInlineAttachments } =
      insertInlineAttachments({
        messageContent: content,
        inlineAttachments: inlineAttachments,
      })

    const standardAttachmentIds = standardAttachments.map((item: IAttachment) => item.id)
    const inlineAttachmentIds = referencedInlineAttachments.map((item: IInlineAttachment) => item.contentId)

    const toRecipientEmails = getRecipientsEmails(toRecipients)
    const bccRecipientEmails = getRecipientsEmails(bccRecipients)

    return await updateDraftApiCall({
      draftId,
      content: contentWithInlineAttachments,
      standardAttachments: standardAttachmentIds,
      inlineAttachments: inlineAttachmentIds,
      toRecipients: toRecipientEmails,
      bccRecipients: bccRecipientEmails,
      isPreviousMessageContentEdited: isPreviousMessageContentEdited,
      scheduleSyncingWithProvider,
      draftVersion,
    })
  }

  const updateDraftCache = ({
    draftId,
    content,
    standardAttachments,
    inlineAttachments,
    toRecipients,
    bccRecipients,
    isPreviousMessageContentEdited,
    scheduleSyncingWithProvider,
    draftVersion,
  }: IUpdateDraftMessageProps) => {
    const draftModifier = (draft: IDraftMessage) => {
      return {
        ...draft,
        bccRecipients,
        recipients: toRecipients,
        content,
        inlineAttachments,
        attachments: standardAttachments,
        isPreviousMessageContentEdited,
        isSyncingWithProvider: !scheduleSyncingWithProvider,
        draftVersion,
      }
    }
    draftCacheActions.updateData({
      draftId,
      draftModifier,
    })
  }

  const { mutateAsync, isError, isLoading, error } = useMutation(applyDraftUpdates, {
    onMutate: updateDraftCache,
    onSuccess: ({ draftVersion, isSyncedWithProvider }, { draftId, draftVersion: syncingToVersion }) => {
      const updateDraftVersionAndSyncingStatus = (draft: IDraftMessage) => {
        if (syncingToVersion === draftVersion) return { ...draft, isSyncedWithProvider }
        return { ...draft, draftVersion, isSyncedWithProvider, isSyncingWithProvider: false }
      }
      draftCacheActions.updateData({ draftId, draftModifier: updateDraftVersionAndSyncingStatus })
    },
    onError: (error: ServerError, { draftId }: IUpdateDraftMessageProps) => {
      draftCacheActions.refreshData(draftId)
      onError?.(getErrorReasonFromError(error))
    },
  })

  const updateDraftUseCase: (props: IUpdateDraftMessageProps) => UpdateDraftApiCallReturnType = mutateAsync

  return {
    updateDraftUseCase,
    isUpdatingDraft: isLoading,
    hasErrorOnSaving: isError,
    errorReason: getErrorReasonFromError(error),
  }
}
