import React from 'react'
import { Theme, Typography, makeStyles } from '@material-ui/core'
import { THeldMessageReason } from 'model/sharingGroup'

const useHeldReasonLabelStyles = makeStyles((theme: Theme) => ({
  heldReasonLabelRoot: {
    color: theme.threadPalette.orange,
    fontSize: 14,
    paddingInline: 8,
  },
  warningIcon: {
    marginRight: 6,
  },
}))

interface IHeldReasonLabelProps {
  heldReason?: THeldMessageReason
}

export const HeldReasonLabel = ({ heldReason }: IHeldReasonLabelProps) => {
  const classes = useHeldReasonLabelStyles()

  if (!heldReason) return null

  const headReasonToLabelTextMapping: Record<THeldMessageReason, string> = {
    reply_to_held_message: 'Reply to a held message',
    people_removed_from_reply: 'Missing people from previous message',
  }

  return (
    <Typography className={classes.heldReasonLabelRoot}>
      <span className={classes.warningIcon} role="img" aria-label="warning">
        ⚠️
      </span>
      {headReasonToLabelTextMapping[heldReason]}
    </Typography>
  )
}
