import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, Typography } from '@material-ui/core'
import { INotification, TNotificationActionResource } from '../../../model/notification'
import BeatProgress from 'component/beatProgress'
import { groupBy } from 'lodash'
import { DateTimeDataProcessor } from 'dataProcessors/dateTime'
import UnreadDivider from '../../unreadDivider'
import NotificationItem from './notificationItem'
import { DateDivider } from 'component/rightSidebar/threadActivity/dateDivider'
import { useOpenThreadUseCase } from 'usecases/openThread'

const useStyles = makeStyles((theme: Theme) => ({
  notificationsRoot: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 50,
  },
  divider: {
    marginBottom: 12,
  },
  notificationItem: {
    borderTop: '1px solid #e5e5e5',
    height: 'fit-content',
  },
  empty: {
    color: theme.threadPalette.lightGray,
  },
}))

interface INotificationsProps {
  collection: INotification[]
  isLoading: boolean
}

const NotificationsList = ({ collection, isLoading }: INotificationsProps) => {
  const classes = useStyles()
  const { openThreadByIdUseCase } = useOpenThreadUseCase()

  const actionsMapping: Record<TNotificationActionResource, Function> = {
    thread: openThreadByIdUseCase,
  }

  const seenGroupedNotifications =
    groupBy(
      collection.sort((one, two) => (one.date > two.date ? -1 : 1)),
      notification => notification.seen
    ) || []
  const dateGroupedNotifications =
    groupBy(seenGroupedNotifications['true'], notification => {
      const createdAt = DateTimeDataProcessor(notification.date)
      return createdAt.toISODate()
    }) || []

  const notificationDates = Object.keys(dateGroupedNotifications).sort((one, two) => (one > two ? -1 : 1))
  const unreadNotificationsCount = seenGroupedNotifications['false']?.length

  if (!collection) {
    return <BeatProgress />
  }

  const onNotificationClicked = async (notification: INotification) => {
    if (!notification.actionResource || !notification.actionResourceId) return

    const notificationAction = actionsMapping[notification.actionResource]
    if (!notificationAction) {
      console.error(`There's no action registered for action resource ${notification.actionResource}`)
      return
    }

    notificationAction({ threadId: notification.actionResourceId })
  }

  return (
    <div className={classes.notificationsRoot}>
      {collection.length === 0 && !isLoading && (
        <Typography align="center" className={classes.empty}>
          Empty
        </Typography>
      )}
      {unreadNotificationsCount && <UnreadDivider unreadCounter={unreadNotificationsCount} />}
      {seenGroupedNotifications['false']?.map((notification: INotification) => {
        return (
          <NotificationItem
            isUnread={true}
            key={notification.id}
            notification={notification}
            className={classes.notificationItem}
            onClick={() => onNotificationClicked(notification)}
            isClickable={Boolean(notification.actionResource)}
          />
        )
      })}
      {notificationDates?.map((date: string) => {
        const result = [
          <DateDivider key={date} date={date} />,
          ...dateGroupedNotifications[date]?.map((notification: INotification) => {
            return (
              <NotificationItem
                key={notification.id}
                notification={notification}
                className={classes.notificationItem}
                onClick={() => onNotificationClicked(notification)}
                isClickable={Boolean(notification.actionResource)}
              />
            )
          }),
        ]
        return result
      })}
    </div>
  )
}

export default NotificationsList
