import React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles, createStyles } from '@material-ui/styles'
import { MessageRecipientsBlock } from '../messageComponents/messageRecipientsBlock'
import { AttachmentCardSmall } from 'component/messageAttachment/attachmentCardSmall'
import { MessageDeliveryStatusBar } from '../messageComponents/messageDeliveryStatusBar'
import { BaseMessage } from '../baseMessage'
import MessageContentWithPreviousMessage from '../messageContent/messageContentWithPreviousMessage'
import IAttachment from 'model/attachment'
import { useDisplayAttachmentContext } from 'contexts/displayAttachmentContext'

const useMessageToShareEntireContentStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageToShareEntireContentRoot: {
      width: '100%',
    },
    attachmentsListRoot: {
      display: 'flex',
      flexDirection: 'row',
      gap: 18,
    },
    messageContentWrapper: {
      paddingBlock: 10,
    },
  })
)

interface IMessageToShareEntireContentProps {
  message: IMessage
  currentUser: IUser
  isLoading?: boolean
}

export const MessageFullContent = ({ message, currentUser, isLoading }: IMessageToShareEntireContentProps) => {
  const classes = useMessageToShareEntireContentStyles()

  const { sender, attachments, deliveryStatus, createdAt } = message

  const { displayAttachmentOnModal } = useDisplayAttachmentContext()

  const onOpenAttachment = (attachment: IAttachment) => {
    displayAttachmentOnModal({ attachment, threadId: message.threadId })
  }

  const isMessageWithError = deliveryStatus === 'error'
  const isCurrentUserMessageSender = sender.id === currentUser.id

  const recipientsBlock = (
    <MessageRecipientsBlock
      currentUser={currentUser}
      message={message}
      isMessageWithError={isMessageWithError}
      isLoading={isLoading}
    />
  )

  const messageContentBlock = (
    <div className={classes.messageContentWrapper}>
      <MessageContentWithPreviousMessage
        message={message}
        isPreviousMessageContentExpanded={true}
        isInlineAttachmentPreviewEnabled={false}
      />
    </div>
  )

  const attachmentsBlock = (
    <div className={classes.attachmentsListRoot}>
      {attachments.map(attachment => (
        <AttachmentCardSmall
          attachment={attachment}
          key={attachment.id}
          onOpenAttachment={() => onOpenAttachment(attachment)}
        />
      ))}
    </div>
  )

  const footerBlock = (
    <MessageDeliveryStatusBar
      deliveryStatus={deliveryStatus}
      createdAt={createdAt}
      isCurrentUserMessageSender={isCurrentUserMessageSender}
    />
  )

  return (
    <div className={classes.messageToShareEntireContentRoot}>
      <BaseMessage
        recipientsBlock={recipientsBlock}
        messageContentBlock={messageContentBlock}
        attachmentsBlock={attachmentsBlock}
        footerBlock={footerBlock}
        isMessageWithError={isMessageWithError}
        isLoading={isLoading}
      />
    </div>
  )
}
