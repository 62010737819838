import React from 'react'
import { Dialog } from '@material-ui/core'
import { ConnectEmailAccount } from './connectEmailAccount'

interface ConnectEmailAccountDialogContextArgs {
  setIsOpen: SetStateCallback<boolean> | undefined
  isOpen: boolean
}

export const ConnectEmailAccountDialogContext = React.createContext<ConnectEmailAccountDialogContextArgs>({
  isOpen: false,
  setIsOpen: undefined,
})

// dialog popup to connect user outlook/google account. used in the access check wrapper
const ConnectEmailAccountDialog = () => {
  const { isOpen } = React.useContext(ConnectEmailAccountDialogContext)

  return (
    <Dialog open={isOpen}>
      <ConnectEmailAccount />
    </Dialog>
  )
}

export default ConnectEmailAccountDialog
