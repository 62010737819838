import { SNACKBAR_ACTIONS } from './../constants/actions/snackbar'

export const showSnackbar = ({
  message,
  durationInMilliseconds,
  severity,
  closeOnClickaway = true,
}: {
  message: string
  durationInMilliseconds?: number
  severity?: TSnackbarSeverity
  closeOnClickaway?: boolean
}): TThunk => {
  return (dispatch, getState) => {
    dispatch({
      type: SNACKBAR_ACTIONS.SHOW_WITH_MESSAGE,
      message: message,
      duration: durationInMilliseconds,
      severity: severity,
      closeOnClickaway: closeOnClickaway,
    })
  }
}

export const dismissSnackbar = (): TThunk => {
  return (dispatch, getState) => {
    dispatch({
      type: SNACKBAR_ACTIONS.DISMISS,
    })
  }
}
