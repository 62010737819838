import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import AttachmentDisplay from 'component/attachmentDisplay/attachmentDisplay'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { useDisplayAttachmentContext } from 'contexts/displayAttachmentContext'

const useAttachmentContentModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachmentPreviewContentRoot: {
      width: '100vw',
    },
  })
)

export const AttachmentContentModal = () => {
  const classes = useAttachmentContentModalStyles()

  const { currentThread } = useCurrentThreadContext()
  const { isAttachmentModalOpen, attachment, closeAttachment } = useDisplayAttachmentContext()

  if (!attachment || !currentThread) return null

  return (
    <Dialog open={isAttachmentModalOpen} fullScreen={true}>
      <AttachmentDisplay
        attachment={attachment}
        currentThread={currentThread}
        onClose={closeAttachment}
        contentClassName={classes.attachmentPreviewContentRoot}
      />
    </Dialog>
  )
}
