import * as React from 'react'

function FolderIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M22.54 1.542H9.72C9.72.73 9.07 0 8.26 0H2.58C1.85 0 1.2.65 1.2 1.542v19.717c0 .811.65 1.541 1.46 1.541h19.88c.81 0 1.46-.649 1.46-1.541V3.002c-.081-.811-.73-1.46-1.46-1.46zm-18.5 1.46h17.04c.81 0 1.46.65 1.46 1.542H2.66c0-.812.569-1.542 1.38-1.542zm11.116 10.386c-.325.325-.73.325-1.055 0l-.893-.974v4.22c0 .405-.324.73-.73.73a.728.728 0 01-.73-.73v-4.139l-.893.893c-.324.324-.73.324-1.054 0a.784.784 0 010-1.136l2.11-2.19.08-.082c.082-.081.244-.162.325-.162h.081c.162 0 .325.08.487.243l2.11 2.19c.486.325.486.812.162 1.137z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default FolderIcon
