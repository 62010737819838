import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: 40,
    marginTop: 10,
    overflowY: 'auto',
    width: '100%',
  },
  header: {
    fontSize: 27,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    marginBottom: 20,
    letterSpacing: 'normal',
  },
  divider: {
    color: theme.threadPalette.lighterGray,
  },

  profilePictureSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },

  label: {
    fontSize: 17,
    color: theme.threadPalette.menuItem.normal,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginTop: 12,
    marginBottom: 8,
  },
  buttonStyle: {
    height: 40,
    width: 180,
    marginLeft: 30,
    borderRadius: 4,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    border: `solid 1px ${theme.palette.primary.main}`,
  },

  fieldWrapper: {
    width: 330,
    marginBottom: 24,
  },

  field: {
    width: '100%',
    borderRadius: 3,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 16,
    alignItems: 'center',
    '& .MuiInputBase-input': {
      width: '100%',
      padding: 0,
      flex: 1,
      fontSize: 17,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.18,
      letterSpacing: 'normal',
      color: theme.threadPalette.brownishGray,
    },
  },

  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.threadPalette.white,
    },
  },

  fieldEnabled: {
    border: `solid 1px ${theme.threadPalette.lightishGray}`,
    backgroundColor: theme.threadPalette.white,
  },

  fieldDisabled: {
    border: `solid 1px ${theme.threadPalette.lighterGray}`,
    backgroundColor: theme.threadPalette.lighterGray,
    cursor: 'not-allowed',
    '& .MuiInputBase-input': {
      cursor: 'not-allowed',
      color: theme.threadPalette.lightGray,
    },
  },

  autoComplete: {
    paddingLeft: 0,
  },

  autoCompleteTextField: {
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'contents',
    },
  },

  providerBadge: {
    marginTop: 5,
  },

  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },

  deleteAccount: {
    display: 'inline-block',
    fontSize: 17,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.threadPalette.menuItem.normal,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))
