import React from 'react'
import { OnboardingStepTextLines } from '../onboardingStepTextLines'
import { InlineEmoji } from 'shared/components/inlineEmoji'

export const CopyThreadLinkStep = () => {
  const textLines = [
    <>
      <strong>Copy / Paste</strong> the thread link
      <InlineEmoji emoji="🔗" ariaLabel="chain" withPadding />
      anywhere you need it so you can easily get back to it.
    </>,
  ]

  return <OnboardingStepTextLines textLines={textLines} maxWidth={180} />
}
