import * as React from 'react'

import { useUserAuthOnboardingContext } from 'contexts/userAuthOnboardingContext'
import { useUser } from 'hooks'
import { UserAuthOnboarding } from 'component/userAuthOnboarding/userAuthOnboarding'

export const CheckUserIsOnboarded: React.FC<{}> = ({ children }) => {
  const currentUser = useUser()

  const { openAuthOnboarding, isAuthOnboardingOpen, isAuthOnboardingPaused } = useUserAuthOnboardingContext()

  const isUserActivated = !!currentUser?.activatedDateTime
  const canOpenAuthOnboarding = !isAuthOnboardingOpen && !isAuthOnboardingPaused

  if (currentUser && !isUserActivated && canOpenAuthOnboarding) {
    openAuthOnboarding()
  }

  return (
    <>
      {isAuthOnboardingOpen && <UserAuthOnboarding />}
      {children}
    </>
  )
}
