import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AppLoadingPage from 'page/appLoading'
import { getThreadTitle } from 'api/threads'
import ErrorScreen from 'component/errorScreen'
import { useDispatch } from 'react-redux'
import { push } from 'actions/navigation'

const useErrorNoThreadAccessStyles = makeStyles((theme: Theme) => ({
  errorNoThreadAccessContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    justifySelf: 'center',
    margin: 'auto',
  },
}))

interface IErrorNoThreadAccessProps {
  threadId: string
  onActionClick?(): void
}

export const NoThreadAccessErrorScreen = ({ threadId, onActionClick }: IErrorNoThreadAccessProps) => {
  const classes = useErrorNoThreadAccessStyles()
  const dispatch = useDispatch()

  const [threadTitle, setThreadTitle] = React.useState('')

  React.useEffect(() => {
    const loadThreadTitle = async () => {
      const { title = '' } = await getThreadTitle(threadId)
      setThreadTitle(title)
    }
    loadThreadTitle()
  }, [threadId])

  const goToHomePage = () => dispatch(push('/'))

  if (!threadTitle) return <AppLoadingPage />

  return (
    <div className={classes.errorNoThreadAccessContainer}>
      <ErrorScreen
        title={threadTitle}
        subtitle="Your email address is not associated with this thread"
        iconMessage="Access denied"
        onActionClick={onActionClick ?? goToHomePage}
      />
    </div>
  )
}
