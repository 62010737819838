import React from 'react'
import ErrorScreen from 'component/errorScreen'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useOauthScopeErrorPageStyles = makeStyles((theme: Theme) => ({
  oAuthScopeErrorPageContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
  },
  subtitleRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
}))

interface IOAuthScopeErrorPageProps {
  emailProvider: TEmailProvider
}

export const OAuthScopeErrorPage = ({ emailProvider }: IOAuthScopeErrorPageProps) => {
  const classes = useOauthScopeErrorPageStyles()

  const onButtonClick = ({
    isProviderSupportingFormResubmission = false,
  }: {
    isProviderSupportingFormResubmission?: boolean
  } = {}) => {
    // In some providers, when trying to return to the previous page, they render a page with an error message like `Confirm Form Resubmission`, while others, like Gmail, don't.
    // For the ones that don't, it makes easier to reuse the exact same boxes marked on the first try
    isProviderSupportingFormResubmission ? window.history.back() : window.history.go(-2)
  }

  return (
    <div className={classes.oAuthScopeErrorPageContainer}>
      {emailProvider === 'gmail' && (
        <ErrorScreen
          title={
            <span>
              You must <u>check all boxes</u>
            </span>
          }
          iconMessage="Check all boxes"
          isIconMessageHighlighted={true}
          overlaidBackgroundComponent={
            <img src="/gmail-mandatory-permissions.png" alt="Gmail mandatory boxes not checked example" />
          }
          buttonTitle="Back"
          onActionClick={() => onButtonClick({ isProviderSupportingFormResubmission: true })}
        />
      )}
      {emailProvider !== 'gmail' && (
        <ErrorScreen
          title="Missing permissions"
          subtitle="You need to grant ThreadLive access to all requested scopes"
          iconMessage="Ooops"
          onActionClick={onButtonClick}
          buttonTitle="Back"
        />
      )}
    </div>
  )
}
