import React from 'react'
import { makeStyles, Theme, Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import Spacer from 'component/layoutUtils/spacer'
import { EditButton } from '../common/editButton'
import { TMessagesSelectorOption } from 'component/sharingGroup/screens/createOrEditThreadSharingSettingsScreen/useThreadSharingSettingsFormFieldsContext'
import { IAutoShareCondition } from 'model/sharingGroup'
import { AutoShareConditionsPicker } from './autoShareConditionsPicker'

const useSelectThreadMessagesBlockStyles = makeStyles((theme: Theme) => ({
  selectThreadMessagesBlockRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  visibilitySettingsHeaderText: {
    fontSize: 18,
    fontWeight: 500,
  },
  optionLabel: {
    fontSize: 15,
    marginLeft: 6,
  },
  optionRadio: {
    color: theme.threadPalette.lightGray,
    padding: 0,
  },
  optionLabelRoot: {
    margin: 0,
    padding: 0,
  },
  radioGroup: {
    alignItems: 'center',
  },
  autoShareConditionsPickerRoot: {
    marginLeft: 10,
    marginTop: 6,
  },
}))

type ISelectThreadMessagesBlockProps = {
  selectedMessagesCount: number
  onClickEditSelectedMessages?(): void
  activeMessagesSelectorOption: TMessagesSelectorOption | null
  onChangeActiveMessagesSelectorOption(newOption: TMessagesSelectorOption | null): void
  selectedAutoShareConditions?: IAutoShareCondition[]
  setAutoShareConditions(autoShareCondition?: IAutoShareCondition[]): void
  openSharingFutureMessagesInfo?(): void
}

export const SelectThreadMessagesBlock = ({
  selectedMessagesCount,
  onClickEditSelectedMessages,
  activeMessagesSelectorOption,
  onChangeActiveMessagesSelectorOption,
  selectedAutoShareConditions,
  setAutoShareConditions,
  openSharingFutureMessagesInfo,
}: ISelectThreadMessagesBlockProps) => {
  const classes = useSelectThreadMessagesBlockStyles()

  const hasMultipleMessagesSelected = selectedMessagesCount > 1

  const selectMessagesToShareLabel =
    selectedMessagesCount === 0 || activeMessagesSelectorOption !== 'selectedMessages'
      ? 'Select messages'
      : `${selectedMessagesCount} message${hasMultipleMessagesSelected ? 's' : ''} selected`

  const onSelectActiveMessagesSelectorOption = (_: React.ChangeEvent<HTMLInputElement>, selectedOption: string) => {
    const selectedOptionNormalized = !!selectedOption ? (selectedOption as TMessagesSelectorOption) : null
    selectedOptionNormalized === 'selectedMessages' && onClickEditSelectedMessages?.()

    onChangeActiveMessagesSelectorOption(selectedOptionNormalized)
  }

  React.useEffect(() => {
    const isSelectedMessagesIdsEmpty =
      selectedMessagesCount === 0 && activeMessagesSelectorOption === 'selectedMessages'

    if (isSelectedMessagesIdsEmpty) {
      onChangeActiveMessagesSelectorOption(null)
    }
  }, [selectedMessagesCount])

  return (
    <div className={classes.selectThreadMessagesBlockRoot}>
      <Typography className={classes.visibilitySettingsHeaderText}>This group can see</Typography>
      <RadioGroup
        name="messages-sharing-options-radios"
        value={activeMessagesSelectorOption ?? ''}
        onChange={onSelectActiveMessagesSelectorOption}
        row
        className={classes.radioGroup}
      >
        <FormControlLabel
          classes={{ label: classes.optionLabel, root: classes.optionLabelRoot }}
          control={<Radio name="all-messages" color="secondary" classes={{ root: classes.optionRadio }} />}
          value="allMessages"
          label="All messages"
        />
        <Spacer width={24} />
        <FormControlLabel
          classes={{ label: classes.optionLabel, root: classes.optionLabelRoot }}
          control={<Radio name="selected-messages" color="secondary" classes={{ root: classes.optionRadio }} />}
          value="selectedMessages"
          label={selectMessagesToShareLabel}
        />
        {activeMessagesSelectorOption === 'selectedMessages' && selectedMessagesCount > 0 && (
          <React.Fragment>
            <Spacer width={12} />
            <EditButton onClick={() => onClickEditSelectedMessages?.()} />
          </React.Fragment>
        )}
      </RadioGroup>
      <AutoShareConditionsPicker
        rootClassName={classes.autoShareConditionsPickerRoot}
        selectedAutoShareConditions={selectedAutoShareConditions}
        setAutoShareConditions={setAutoShareConditions}
        openSharingFutureMessagesInfo={openSharingFutureMessagesInfo}
      />
    </div>
  )
}
