import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import * as React from 'react'
import { DateTime } from 'luxon'
import classnames from 'classnames'

import { DateTimeDataProcessor } from '../../../dataProcessors/dateTime'

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '18px 18px 12px 0',
      borderTop: '1px solid #e5e5e5',
    },
    value: {
      color: theme.threadPalette.menuItem.current,
      display: 'inline-block',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '20px',
      position: 'relative',
    },
  }
})

interface IDateDividerProps {
  className?: string
  date: string
}

const calculateDiff = (diff: number): string => {
  const diffAsNatural = Number(Math.floor(Math.abs(diff)).toPrecision(1))
  if (diffAsNatural === 0) {
    return 'Today'
  }

  if (diffAsNatural === 1) {
    return 'Yesterday'
  }

  return diffAsNatural + ' days ago'
}

export const DateDivider = (props: IDateDividerProps) => {
  const classes = useStyles()

  const dateTime = DateTimeDataProcessor(props.date)
  const diff = dateTime.diff(DateTime.local(), ['day']).as('day')

  return (
    <div className={classnames(classes.container, props.className)}>
      <span className={classes.value}>{calculateDiff(diff)}</span>
    </div>
  )
}
