import React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles, createStyles } from '@material-ui/styles'
import MessageContentWithPreviousMessage, {
  IMessageContentWithPreviousMessageProps,
} from './messageContentWithPreviousMessage'
import UncollapseIcon from '@material-ui/icons/ChevronRight'
import CollapseIcon from '@material-ui/icons/ExpandMore'
import { Typography } from '@material-ui/core'
import { getMessageContentAsPreview } from 'domain/message'

const useCollapsibleMessageContentStyles = makeStyles((theme: Theme) =>
  createStyles({
    collapsedContentContainer: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
    },
    labelIcon: {
      color: theme.threadPalette.lightSoftGray,
      fontSize: 20,
    },
    collapsedContent: {
      color: theme.threadPalette.lightGray,
      fontSize: 15,
    },
  })
)

interface ICollapsibleMessageContentProps extends IMessageContentWithPreviousMessageProps {
  isCollapsed: boolean
  toggleMessageCollapsed: () => void
}

export const CollapsibleMessageContent = ({
  isCollapsed,
  toggleMessageCollapsed,
  ...messageContentProps
}: ICollapsibleMessageContentProps) => {
  const classes = useCollapsibleMessageContentStyles()

  const message = messageContentProps.message

  const canCollapse = message.content.length > 0
  const IconComponent = isCollapsed ? UncollapseIcon : CollapseIcon

  return (
    <React.Fragment>
      {canCollapse && (
        <div className={classes.collapsedContentContainer} onClick={toggleMessageCollapsed}>
          <IconComponent className={classes.labelIcon} />
          {isCollapsed && (
            <Typography className={classes.collapsedContent} noWrap>
              {getMessageContentAsPreview(message)}
            </Typography>
          )}
        </div>
      )}
      {!isCollapsed && <MessageContentWithPreviousMessage {...messageContentProps} />}
    </React.Fragment>
  )
}
