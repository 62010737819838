import * as React from 'react'
import { Theme, Divider, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import { useUser } from 'hooks/auth'
import UserAvatar from 'component/userAvatar'
import { getUserDisplayName } from 'dataProcessors/userDisplayName'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userSelectItemRoot: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minWidth: 0,
    },

    content: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    avatarSection: {
      display: 'inline-block',
      position: 'relative',
    },

    section: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    username: {
      color: theme.threadPalette.menuItem.current,
      fontSize: 15,
      fontWeight: 500,
      lineHeight: 'normal',
    },
    userEmail: {
      color: theme.threadPalette.menuItem.current,
      fontSize: 12,
      lineHeight: 'normal',
      marginTop: 1,
    },

    divider: {
      width: '100%',
      color: theme.threadPalette.lighterGray,
    },
    userInfoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 12,
    },
  })
)

interface IUserSelectItemProps {
  user: IUser | TDraftRecipient
  addDivider?: boolean
}

const UserSelectItem = ({ user, addDivider = false }: IUserSelectItemProps) => {
  const classes = useStyles()
  const currentUser = useUser()

  const isMe = currentUser?.id === user.id
  const userDisplayName = isMe ? 'You' : getUserDisplayName(user)

  return (
    <div className={classes.userSelectItemRoot}>
      {addDivider && <Divider className={classes.divider} />}
      <div className={classes.content}>
        <div className={classes.section}>
          <div className={classes.avatarSection}>
            <UserAvatar user={user} />
          </div>
          <div className={classes.userInfoWrapper}>
            <Typography className={classes.username}>{userDisplayName}</Typography>
            {userDisplayName !== user.email && <Typography className={classes.userEmail}>{user.email}</Typography>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserSelectItem
