import * as React from 'react'
import { Typography, MenuItem } from '@material-ui/core'
import FormikTextField from 'component/formikTextField'
import DropdownSelect from 'component/dropdownSelect'
import TriangleIcon from 'icons/darkTriangleIcon'
import classNames from 'classnames'
import FieldControls from 'component/fieldControls'
import AppLoading from 'component/appLoading'
import { useStyles } from './orgSettings.style'
import { useOrgSettings } from './useOrgSettings'

interface IOrgSettingsProps {
  organizationId: string
}

const OrgSettings = ({ organizationId }: IOrgSettingsProps) => {
  const classes = useStyles()

  const {
    orgName,
    orgSettings,
    handleOrgNameChange,
    nameEditDisabled,
    handleSaveName,
    handleCancelNameEdit,
    subscriptionPlans,
    subPlan,
    planEditDisabled,
    handleSubPlanChange,
    getPlanLabel,
    handleSaveSubPlan,
    handleCancelSubscriptionPlanEdit,
  } = useOrgSettings(organizationId)

  if (!orgSettings) {
    return (
      <div className={classes.loadingContainer}>
        <AppLoading visible />
      </div>
    )
  }

  return (
    <div>
      <div className={classes.toggleEnabledContainer}>
        <FormikTextField
          labelClasses={classes.fieldLabel}
          className={classes.field}
          title="Organization name"
          value={orgName}
          onChange={handleOrgNameChange}
          disabled={nameEditDisabled}
        />
        <FieldControls
          handleSave={handleSaveName}
          handleToggle={handleCancelNameEdit}
          editDisabled={nameEditDisabled}
        />
      </div>

      <div className={classes.toggleEnabledContainer}>
        <DropdownSelect
          className={classNames(classes.field, classes.dropdown)}
          icon={TriangleIcon}
          label="Subscription plan"
          value={subPlan}
          disabled={planEditDisabled}
          onChange={handleSubPlanChange}
          renderValue={planValue => <Typography>{getPlanLabel(planValue)}</Typography>}
        >
          {subscriptionPlans.map(plan => (
            <MenuItem key={plan.value} value={plan.value}>
              <Typography>{plan.label}</Typography>
            </MenuItem>
          ))}
        </DropdownSelect>
        <FieldControls
          handleSave={handleSaveSubPlan}
          handleToggle={handleCancelSubscriptionPlanEdit}
          editDisabled={planEditDisabled}
        />
      </div>

      <Typography className={classes.fieldLabel}>Billing status</Typography>
      <Typography className={classes.field}>{orgSettings.billingStatus}</Typography>

      <Typography className={classes.fieldLabel}>Next billing date</Typography>
      <Typography className={classes.field}>{orgSettings.billingDate}</Typography>

      <Typography className={classes.fieldLabel}>Amount to be billed</Typography>
      <Typography className={classes.field}>${orgSettings.totalCost}</Typography>

      <Typography className={classes.fieldLabel}>Total members</Typography>
      <Typography className={classes.field}>{orgSettings.totalMembers}</Typography>
    </div>
  )
}

export default OrgSettings
