import React from 'react'
import differenceBy from 'lodash/differenceBy'
import uniqBy from 'lodash/uniqBy'

import { ISharingGroup } from 'model/sharingGroup'
import { useUser } from 'hooks/auth'
import { useUserSharingGroups } from './useUserSharingGroups'

type SharingGroupNameAndMembers = Pick<ISharingGroup, 'id' | 'name' | 'members'>

type IUseCreateOrEditSharingGroupProps = Rename<
  Rename<Partial<SharingGroupNameAndMembers>, 'name', 'initialName'>,
  'members',
  'initialMembers'
>

export const useCreateOrEditSharingGroup = ({
  id: sharingGroupId,
  initialName: initialSharingGroupName = '',
  initialMembers: initialSharingGroupMembers = [],
}: IUseCreateOrEditSharingGroupProps = {}) => {
  const currentUser = useUser()
  const [sharingGroupMembers, setSharingGroupMembers] = React.useState<TPerson[]>(initialSharingGroupMembers)
  const [sharingGroupName, setSharingGroupName] = React.useState(initialSharingGroupName)

  const { userSharingGroups } = useUserSharingGroups()

  const updateSharingGroupMembers = (updatedSharingGroupMembers: TPerson[]) => {
    const uniqueUpdatedSharingGroupMembers = uniqBy(updatedSharingGroupMembers, 'email')
    setSharingGroupMembers(uniqueUpdatedSharingGroupMembers)
  }

  const sharingGroupMembersAdded = differenceBy(sharingGroupMembers, initialSharingGroupMembers, 'email')
  const sharingGroupMembersRemoved = differenceBy(initialSharingGroupMembers, sharingGroupMembers, 'email')

  const filterCurrentUserFromMembersOptions = (people: TPerson[]) =>
    people.filter(person => person.email !== currentUser?.email)

  const updateSharingGroupName = (newName?: string) => setSharingGroupName(newName ?? '')

  const isSharingGroupNameEmpty = sharingGroupName.trim().length === 0
  const userHasOtherSharingGroupsWithSameName = userSharingGroups.some(
    sharingGroup => sharingGroup.name === sharingGroupName.trim() && sharingGroup.id !== sharingGroupId
  )
  const isValidSharingGroup = !isSharingGroupNameEmpty && !userHasOtherSharingGroupsWithSameName

  return {
    sharingGroupMembers,
    updateSharingGroupMembers,
    sharingGroupMembersAdded,
    sharingGroupMembersRemoved,
    filterCurrentUserFromMembersOptions,
    sharingGroupName,
    updateSharingGroupName,
    userHasOtherSharingGroupsWithSameName,
    isValidSharingGroup,
  }
}
