import * as React from 'react'
import { SvgIcon } from '@material-ui/core'

interface IRedoIconProps extends JSX.IntrinsicAttributes {
  className?: string
  transform?: string
}

const RedoIcon = ({ className, transform = '' }: IRedoIconProps) => {
  return (
    <SvgIcon transform={transform} viewBox="0 -2 14 14" className={className}>
      <path
        fillRule="evenodd"
        fill="#606060"
        d="M13.755 8.526c.067-.066.1-.159.093-.253l-.458-5.792c-.01-.125-.092-.234-.21-.277-.117-.043-.25-.014-.338.075l-1.335 1.335C8.723 1.127 4.84.944 2.548 3.238.64 5.146.426 8.332 2.028 10.985c.089.148.28.2.43.116.152-.083.21-.27.134-.426-.901-1.82-.639-3.911.653-5.203 2.012-2.012 4.463-.3 5.637.767L7.509 7.612c-.09.089-.118.221-.075.339.043.118.151.2.276.21l5.792.458c.094.008.187-.026.253-.093z"
      />
    </SvgIcon>
  )
}

export default RedoIcon
