import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    padding: '36px 36px 50px 36px',
    borderRadius: 4,
    boxShadow:
      '0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
    backgroundColor: theme.threadPalette.backgroundColor,
  },

  dialogHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  dialogTitle: {
    fontSize: 23,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  dialogSubtitle: {
    fontSize: 17,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.threadPalette.lightGray,
    marginRight: 80,
    marginTop: 20,
  },

  closeIcon: {
    width: 20,
    height: 20,
  },

  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
    '& svg': {
      fill: theme.threadPalette.lightGray,
    },
    '&:hover': {
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
  },

  form: {
    marginTop: 36,
    marginRight: 80,
  },

  emailStatusAvailable: {
    fontSize: 15,
    marginTop: 10,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.threadPalette.green,
  },

  emailStatusInOrg: {
    fontSize: 15,
    marginTop: 12,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.palette.error.main,
  },

  cancelButton: {
    backgroundColor: theme.threadPalette.white,
    width: 110,
    height: 40,
    marginRight: 10,
  },
  addButton: {
    width: 140,
    height: 40,
    color: theme.threadPalette.white,
  },
  buttonsStyle: {
    marginTop: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    alignContent: 'flex-start',
  },
  betweenButtons: {
    marginLeft: 15,
  },

  fieldLabel: {
    fontSize: 17,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.threadPalette.menuItem.normal,
  },
}))
