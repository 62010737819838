import React from 'react'
import { Dialog, makeStyles } from '@material-ui/core'
import { useFullScreen } from 'hooks/useFullScreen'
import { NewProjectModalContent } from './newProjectModalContent'
import { useNewProjectModalContext } from './newProjectModalContext'

const useNewProjectModalStyles = makeStyles(() => ({
  root: {
    borderRadius: 12,
    minWidth: 'min(345px, 95vw)',
  },
  backdrop: {
    backgroundColor: 'rgba(86,76,76, 0.38)',
  },
}))

export const NewProjectModal = () => {
  const classes = useNewProjectModalStyles()
  const { isOpen, closeModal } = useNewProjectModalContext()
  const { isFullScreen } = useFullScreen()

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: classes.root }}
      onClose={closeModal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
      maxWidth="sm"
      fullScreen={isFullScreen}
    >
      <NewProjectModalContent />
    </Dialog>
  )
}
