import { makeStyles } from '@material-ui/styles'

import { primaryFont } from '.'
import { fade } from '@material-ui/core'

const globalFontFamily = `${primaryFont}, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`

/**
 * A hook that installs global CSS overrides.
 */
const useGlobalCSS = makeStyles({
  '@global': {
    body: {
      backgroundColor: '#ffffff',
      fontFamily: globalFontFamily,
      fontSize: '100%',
      lineHeight: '1.2',
      margin: '0px',
      overflow: 'hidden',
      padding: '0px',
      '-webkit-tap-highlight-color': 'transparent',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    input: {
      fontFamily: globalFontFamily,
      '&::placeholder': {
        fontFamily: globalFontFamily,
      },
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
      margin: '0px',
      height: '100vh',
      padding: '0px',
    },
    div: {
      whiteSpace: 'pre-line',
    },
    '@keyframes blink': {
      '50%': {
        backgroundColor: fade('#2196f3', 0.25),
      },
    },
  },
})

export default useGlobalCSS
