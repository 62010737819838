import React from 'react'
import { Theme, Tooltip, makeStyles } from '@material-ui/core'
import { IProject } from 'model/project'
import { ProjectCard } from 'component/projectsTree/projectCard'

import { useProjectsTreeContext } from './projectsTreeContext'
import { ARCHIVED_PROJECT_OPACITY } from 'features/project/utils'
import { ProjectActionsButtonWithModal } from 'page/projects/projectDetailsPage/projectActionsButtonWithModal'
import NestedProjectsList from './nestedProjectsList'
import { useCollapsableIcon } from 'shared/hooks/useCollapsableIcon'

const useProjectNodeStyles = makeStyles<Theme, { isArchived: boolean; isMovingProject?: boolean }>(theme => ({
  projectNodeRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'inherit',
  },
  nestedProjectsListRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'inherit',
    marginLeft: 20,
  },
  projectIcon: {
    color: theme.threadPalette.onFocusGray,
    cursor: 'pointer',
    opacity: ({ isArchived }) => (isArchived ? ARCHIVED_PROJECT_OPACITY : 1),
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
  noNestedProjectsLabel: {
    color: theme.threadPalette.brownishGray,
    fontSize: 16,
    fontWeight: 400,
  },
  nestedProjectsLoadingRoot: {
    maxWidth: 720,
  },
}))

interface IProjectNodeProps {
  project: IProject
  showActionsButton?: boolean
  isMovingParentProject?: boolean
  allowEditingEmoji?: boolean
}

export const ProjectNode = ({
  project,
  showActionsButton = true,
  isMovingParentProject = false,
  allowEditingEmoji = false,
}: IProjectNodeProps) => {
  const { isMovingProject, movingProject, openProjectDetails, updateParentForSelectedProject } =
    useProjectsTreeContext()

  const [isCreatingNewProject, setIsCreatingNewProject] = React.useState(false)

  const isMovingCurrentProject = movingProject?.id === project.id || isMovingParentProject

  const classes = useProjectNodeStyles({ isArchived: project.isArchived, isMovingProject: isMovingCurrentProject })

  const {
    isExpanded: isNestedListExpanded,
    component: expandNestedProjectsButton,
    uncollapse: showNestedProjects,
  } = useCollapsableIcon({
    className: classes.projectIcon,
  })

  const onCreateNewProject = () => {
    !isNestedListExpanded && showNestedProjects()
    setIsCreatingNewProject(true)
  }
  const actionsButton = (
    <ProjectActionsButtonWithModal
      project={project}
      actionsToHide={['share']}
      iconClassName={classes.projectIcon}
      onNewNestedProject={onCreateNewProject}
    />
  )

  const onProjectClick = () => {
    if (isMovingCurrentProject) return
    if (isMovingProject) {
      updateParentForSelectedProject(project)
      return
    }
    openProjectDetails(project.id)
  }

  return (
    <Tooltip
      title={
        isMovingCurrentProject && !isMovingParentProject
          ? "You can't move a project to any of his nested projects, please pick another one"
          : ''
      }
    >
      <div key={project.id} className={classes.projectNodeRoot}>
        <ProjectCard
          project={project}
          prefixComponent={expandNestedProjectsButton}
          suffixComponent={actionsButton}
          onClick={onProjectClick}
          showActionsButton={showActionsButton}
          isDisabled={isMovingCurrentProject}
          allowEditingEmoji={allowEditingEmoji}
        />
        {isNestedListExpanded && (
          <div className={classes.nestedProjectsListRoot}>
            <NestedProjectsList
              project={project}
              showActionsButton={showActionsButton}
              isMovingCurrentProject={isMovingCurrentProject}
              isCreatingNewProject={isCreatingNewProject}
              onProjectCreationFinished={() => setIsCreatingNewProject(false)}
              allowEditingEmoji={allowEditingEmoji}
            />
          </div>
        )}
      </div>
    </Tooltip>
  )
}
