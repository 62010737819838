import * as React from 'react'
import { ThreadsViewHeader } from './threadsViewHeader'
import { ThreadsList } from './threadsList/threadsList'
import Spacer from 'component/layoutUtils/spacer'
import { useThreadsList } from 'hooks/useThreads/useThreadsList'
import { useNewThreadModalContext } from 'component/newThreadModal/newThreadModal'

export const ThreadsView = () => {
  const {
    threadsList,
    handleScrollEvent,
    isLoading,
    isFetchingNextPage,
    changeFilterBy: changeThreadsFilterBy,
  } = useThreadsList({
    pageSize: 20,
  })

  const { openModal: openNewThreadModal } = useNewThreadModalContext()

  return (
    <>
      <ThreadsViewHeader onNewThread={openNewThreadModal} changeThreadsFilterBy={changeThreadsFilterBy} />
      <Spacer height={16} />
      <ThreadsList
        threadsList={threadsList}
        handleScrollEvent={handleScrollEvent}
        isFirstPageLoading={isLoading}
        isLoadingNextPage={isFetchingNextPage}
      />
    </>
  )
}
