import { USER_AUTH_ONBOARDING_ACTIONS } from '../constants/actions/userAuthOnboarding'
import { AnyAction } from 'redux'

const DEFAULT_STATE = {
  currentOnboardingStep: 'confirmName',
}

export const userAuthOnboardingReducer = (state = DEFAULT_STATE, action: AnyAction) => {
  switch (action.type) {
    case USER_AUTH_ONBOARDING_ACTIONS.SET_CURRENT_ONBOARDING_STEP: {
      return {
        ...state,
        currentOnboardingStep: action.step,
      }
    }

    case USER_AUTH_ONBOARDING_ACTIONS.RESET: {
      return {
        ...DEFAULT_STATE,
      }
    }

    default: {
      return state
    }
  }
}
