import React from 'react'
import { makeStyles, Popover, Theme } from '@material-ui/core'
import { PopoverProps } from 'material-ui'
import { PeopleListBlock } from 'component/peopleListBlock/peopleListBlock'

interface RecipientsListPopoverProps extends Omit<PopoverProps, 'anchorEl'> {
  isOpen: boolean
  anchorEl: HTMLElement | (() => HTMLElement) | null
  onClose?(): void
  sender: TPerson
  recipients?: TPerson[]
  bccRecipients?: TPerson[]
}

const useRecipientsListPopoverStyles = makeStyles((theme: Theme) => {
  return {
    recipientsListModal: {
      borderRadius: 11,
      width: 376,
    },
    senderHeader: {
      backgroundColor: theme.threadPalette.darkBlue,
      color: theme.threadPalette.white,
    },
  }
})

const RecipientsListPopover = ({ sender, recipients, bccRecipients, isOpen, ...props }: RecipientsListPopoverProps) => {
  const classes = useRecipientsListPopoverStyles()

  const recipientsCount = recipients?.length ?? 0
  const bccRecipientsCount = bccRecipients?.length ?? 0

  return (
    <Popover
      open={isOpen}
      classes={{ paper: classes.recipientsListModal }}
      {...props}
      elevation={8}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <PeopleListBlock headerClassName={classes.senderHeader} people={[sender]} headerTitle="From:" addDivider />
      {recipients && recipientsCount > 0 && <PeopleListBlock headerTitle="To:" people={recipients} addDivider />}
      {bccRecipients && bccRecipientsCount > 0 && (
        <PeopleListBlock headerTitle="Bcc:" people={bccRecipients} addDivider />
      )}
    </Popover>
  )
}

export default RecipientsListPopover
