import * as React from 'react'
import { Button, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TViewState } from '../quickViewList'
import classnames from 'classnames'

const useViewSwitcherStyles = makeStyles((theme: Theme) => {
  return {
    viewSwitcherRoot: {
      flexDirection: 'row',
      height: '26px',
      justifyContent: 'space-between',
      display: 'flex',
      cursor: 'pointer',
    },
    switchOption: {
      color: theme.threadPalette.lightGray,
      fontSize: '14px',
      textAlign: 'center',
      width: '106px',
      borderRadius: '5px',
    },
    switchOptionActive: {
      backgroundColor: theme.threadPalette.white,
      color: theme.threadPalette.darkerBlue,
      '&:hover': {
        backgroundColor: theme.threadPalette.white,
      },
    },
    buttonLabel: {
      fontWeight: 'normal',
    },
  }
})

interface IViewSwitcherState {
  viewState: TViewState
  setViewState: SetStateCallback<TViewState>
}

export const ViewSwitcher = ({ viewState, setViewState }: IViewSwitcherState) => {
  const classes = useViewSwitcherStyles()

  const threadsOptionClassName = classnames(classes.switchOption, {
    [classes.switchOptionActive]: viewState === 'threads',
  })

  const projectsOptionClassName = classnames(classes.switchOption, {
    [classes.switchOptionActive]: viewState === 'projects',
  })

  const toggleViewState = React.useCallback(() => {
    let newViewState: TViewState | undefined
    if (viewState === 'threads') newViewState = 'projects'
    if (viewState === 'projects') newViewState = 'threads'

    if (!newViewState) return

    setViewState(newViewState)
  }, [viewState])

  return (
    <div className={classes.viewSwitcherRoot} onClick={toggleViewState}>
      <Button classes={{ root: threadsOptionClassName, label: classes.buttonLabel }}>Threads</Button>
      <Button classes={{ root: projectsOptionClassName, label: classes.buttonLabel }}>Projects</Button>
    </div>
  )
}
