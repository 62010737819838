import React from 'react'
import classnames from 'classnames'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import WrapIntoSkeletonLoading from 'component/wrapIntoSkeletonLoading'
import UserAvatar from 'component/userAvatar'
import Spacer from 'component/layoutUtils/spacer'

const useMessageBlockWithAvatarStyles = makeStyles<Theme>((theme: Theme) => {
  return {
    messageBlockWithAvatarRoot: {
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'row',
      paddingInline: 24,
    },
    messageBlockWithAvatarForSender: {
      display: 'flex',
      flexDirection: 'row-reverse',
      marginLeft: 'auto',
    },
    userAvatar: {
      flexShrink: 0,
    },
  }
})

interface IMessageBlockWithAvatarProps {
  messageSender: IUser
  isCurrentUserMessageSender: boolean
  rootClassName?: string
  avatarClassName?: string
  isLoading?: boolean
}

export const MessageBlockWithAvatar: React.FC<IMessageBlockWithAvatarProps> = ({
  isCurrentUserMessageSender,
  messageSender,
  rootClassName,
  avatarClassName,
  isLoading,
  children,
}) => {
  const classes = useMessageBlockWithAvatarStyles()

  const messageBlockWithAvatarRootClassName = classnames(classes.messageBlockWithAvatarRoot, rootClassName, {
    [classes.messageBlockWithAvatarForSender]: isCurrentUserMessageSender,
  })

  const avatarSizeInPixels = 40

  return (
    <div className={messageBlockWithAvatarRootClassName}>
      <WrapIntoSkeletonLoading
        isLoading={isLoading}
        className={classes.userAvatar}
        animation="wave"
        variant="rect"
        height={avatarSizeInPixels}
      >
        <UserAvatar
          className={classnames(classes.userAvatar, avatarClassName)}
          user={messageSender}
          fontSize={18}
          size={avatarSizeInPixels}
        />
      </WrapIntoSkeletonLoading>
      <Spacer width={16} />
      {children}
    </div>
  )
}
