import * as React from 'react'
import NotificationsList from './notificationsList'
import BeatProgress from 'component/beatProgress'
import { makeStyles } from '@material-ui/styles'
import { INotification } from 'model/notification'

const useStyles = makeStyles({
  headerWrapper: {
    padding: 16,
    fontFamily: 'Roboto',
    fontSize: 19,
    fontWeight: 500,
  },
})

interface INotificationPageProps {
  isLoading: boolean
  collection?: INotification[]
}

const NotificationsPage = ({ collection, isLoading }: INotificationPageProps) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.headerWrapper}>
        <span id={'unread-count'}>Notifications</span>
      </div>
      {collection ? <NotificationsList collection={collection || []} isLoading={isLoading} /> : <BeatProgress />}
    </>
  )
}

export default NotificationsPage
