import * as React from 'react'
import { SvgIcon } from '@material-ui/core'

interface IAttachmentIconProps {
  className?: string
  fill?: string
  width?: number
  height?: number
}

const AttachmentIcon = ({ className, fill, width, height }: IAttachmentIconProps) => {
  return (
    <SvgIcon className={className} viewBox="0 0 30 27" width={width} height={height}>
      <path
        fill={fill ? fill : '#606060'}
        fillRule="evenodd"
        d="M29.99 7.249a7.201 7.201 0 01-2.117 5.11l-13.513 13.5a.602.602 0 01-.88.035.602.602 0 01.034-.881L27.02 11.517a5.995 5.995 0 000-8.528 6.046 6.046 0 00-8.534 0L2.999 18.464c-1.199 1.2-1.8 2.4-1.8 3.563a3.762 3.762 0 001.199 2.657c1.69 1.691 4.468 1.475 6.454-.51L21.31 11.716a2.1 2.1 0 000-2.999 2.105 2.105 0 00-2.999 0L7.971 19.105a.601.601 0 11-.846-.846l10.358-10.35a3.304 3.304 0 114.672 4.672L9.705 25.018a6.474 6.474 0 01-4.534 1.979 5.045 5.045 0 01-3.612-1.486A4.933 4.933 0 010 22.026c0-1.488.744-2.999 2.147-4.415L17.64 2.12A7.235 7.235 0 0129.99 7.248z"
      />
    </SvgIcon>
  )
}

export default AttachmentIcon
