import { getComponentDimensions } from 'lib/elementTracking'
import * as React from 'react'

export function useTrackComponentDimensions() {
  const ref = React.useRef<HTMLDivElement | null>(null)
  const [componentHeight, setComponentHeight] = React.useState(0)
  const [componentWidth, setComponentWidth] = React.useState(0)

  const updateDimensions = () => {
    const { height, width } = getComponentDimensions(ref)
    setComponentHeight(height)
    setComponentWidth(width)
  }

  // Effect to update the tracked height and width on each re-render
  React.useEffect(() => {
    updateDimensions()
  })

  // Effect to force a re-render on window resizing when ref dimensions change
  React.useEffect(() => {
    window.addEventListener('resize', updateDimensions)

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  return { ref, componentHeight, componentWidth }
}
