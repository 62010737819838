import React from 'react'
import { Typography } from '@material-ui/core'
import { ISharingGroup } from 'model/sharingGroup'
import GroupIcon from '@material-ui/icons/Group'
import { SharingGroupMemberChip } from 'component/sharingGroup/common/sharingGroupMemberChip'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles, createStyles } from '@material-ui/styles'
import { useStopSharingMessageWithSharingGroupUseCase } from 'usecases/sharingGroup/stopSharingMessageWithSharingGroup'
import { StopSharingMessageButton } from 'component/sharingGroup/common/stopSharingMessageButton'

const useMessageSharingGroupBlockStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageSharingGroupBlockRoot: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      maxWidth: 440,
    },
    groupIcon: {
      color: theme.threadPalette.menuItem.current,
      height: 22,
      width: 22,
    },
    groupName: {
      fontSize: 18,
    },
    membersListRoot: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 6,
    },
    groupNameRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      gap: 12,
    },
    noMembersLabel: {
      color: theme.threadPalette.brownishGray,
      fontSize: 16,
    },
  })
)

interface IMessageSharingGroupBlockProps {
  message: IMessage
  sharingGroup: ISharingGroup
  canRemoveAccess?: boolean
  onStopSharingMessage?(): void
}

export const MessageSharingGroupBlock = ({
  message,
  sharingGroup,
  onStopSharingMessage,
  canRemoveAccess = false,
}: IMessageSharingGroupBlockProps) => {
  const classes = useMessageSharingGroupBlockStyles()

  const isSharingGroupsMembersListEmpty = sharingGroup.members.length === 0
  const groupNameRef = React.useRef<HTMLDivElement>(null)

  const { stopSharingMessageWithSharingGroupUseCase, isLoading: isStoppingToShareMessage } =
    useStopSharingMessageWithSharingGroupUseCase({
      onSuccess: onStopSharingMessage,
    })

  const onStopSharingMessageWithSharingGroup = () =>
    stopSharingMessageWithSharingGroupUseCase({
      sharingGroupId: sharingGroup.id,
      messageId: message.id,
      threadId: message.threadId,
    })

  return (
    <div className={classes.messageSharingGroupBlockRoot}>
      <section>
        <div ref={groupNameRef} className={classes.groupNameRoot}>
          <GroupIcon className={classes.groupIcon} />
          <Typography className={classes.groupName}>{sharingGroup.name}</Typography>
        </div>
        {canRemoveAccess && (
          <StopSharingMessageButton
            sharingGroupName={sharingGroup.name}
            anchorElementRefForConfirmationPopup={groupNameRef}
            onClick={onStopSharingMessageWithSharingGroup}
            isLoading={isStoppingToShareMessage}
          />
        )}
      </section>
      <div className={classes.membersListRoot}>
        {!isSharingGroupsMembersListEmpty &&
          sharingGroup.members.map(member => <SharingGroupMemberChip person={member} key={member.id} />)}
        {isSharingGroupsMembersListEmpty && <Typography className={classes.noMembersLabel}>No members</Typography>}
      </div>
    </div>
  )
}
