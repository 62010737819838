import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { IUserOnboarding } from 'model/onboarding'
import { useUser } from 'hooks/auth'

export const getUserOnboardingsQueryKey = ({ userId }: { userId: string }) => [userId, 'onboarding']

export const useUserOnboardingsCacheActions = () => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const getData = useCallback(() => {
    if (!currentUser) return
    const queryKey = getUserOnboardingsQueryKey({ userId: currentUser.id })

    return queryClient.getQueryData<IUserOnboarding[]>(queryKey)
  }, [queryClient, currentUser?.id])

  const updateUserOnboarding = useCallback(
    ({
      onboardingId,
      onboardingModifier,
    }: {
      onboardingId: string
      onboardingModifier: (onboarding: IUserOnboarding) => IUserOnboarding
    }) => {
      if (!currentUser) return
      const queryKey = getUserOnboardingsQueryKey({ userId: currentUser.id })

      const userOnboardings = getData()
      if (!userOnboardings) return

      const updatedUserOnboardings = userOnboardings.map(onboarding => {
        if (onboarding.id !== onboardingId) return onboarding
        return onboardingModifier(onboarding)
      })

      queryClient.setQueryData(queryKey, updatedUserOnboardings)

      const rollbackUpdate = () => queryClient.setQueryData(queryKey, userOnboardings)
      return rollbackUpdate
    },
    [queryClient, getData, currentUser?.id]
  )

  const refreshData = useCallback(() => {
    if (!currentUser) return
    const queryKey = getUserOnboardingsQueryKey({ userId: currentUser.id })

    queryClient.invalidateQueries(queryKey)
  }, [queryClient, currentUser?.id])

  return {
    getData,
    updateUserOnboarding,
    refreshData,
  }
}
