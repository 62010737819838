import { DateTime } from 'luxon'

export const MessagesDataProcessor = (messages: Array<IAPIMessage>): TMessages => {
  return messages.map(message => MessageDataProcessor(message))
}

export const MessageDataProcessor = (message: IAPIMessage): IMessage => ({
  ...message,
  createdAt: DateTime.fromISO(message.createdAt),
  updatedAt: DateTime.fromISO(message.updatedAt),
  mentionedUsers: message.mentionedUsers || [],
})
