import { push } from 'connected-react-router'
import { Dispatch } from 'redux'

import { handleError } from '../actions/error'
import * as AuthAPI from '../api/auth'
import { setChecked, setUser, signout } from './auth'
import * as Sentry from '@sentry/browser'

export const checkAuth = () => {
  return async (dispatch: Dispatch, getState: IState) => {
    let authUser = null
    try {
      authUser = await AuthAPI.getCurrentUser()
    } catch (error) {
      if (error.isAxiosError && error.response.status === 403) {
        dispatch(signout())
        return
      }

      dispatch(handleError(error))
      return
    }

    if (authUser === null) {
      dispatch(setChecked(false))
      dispatch(push('/signin'))
      return
    }

    dispatch(setChecked(true))
    dispatch(setUser(authUser))
    Sentry.setUser({ id: authUser.id })
  }
}
