import * as React from 'react'

interface IBellIconFilledProps {
  className?: string
}

const BellIconFilled = (props: IBellIconFilledProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path
        fill="#ffffff"
        fillRule="evenodd"
        d="M12.766 16.385C12.686 17.843 11.478 19 10 19s-2.685-1.157-2.766-2.615H3.85c-1.022 0-1.85-.826-1.85-1.847 0-1.019.827-1.846 1.847-1.846V9c0-2.601 1.625-4.881 4-5.767v-.08C7.846 1.963 8.81 1 10 1c1.19 0 2.154.964 2.154 2.153v.08c2.374.886 4 3.166 4 5.767v3.692c1.017 0 1.846.827 1.846 1.846 0 1.02-.828 1.847-1.85 1.847h-3.384zm-4.297 0c.078.777.733 1.384 1.531 1.384.798 0 1.453-.607 1.531-1.384H8.47z"
      />
    </svg>
  )
}

export default BellIconFilled
