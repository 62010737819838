import { setUser } from './../../actions/auth'
import { getCurrentUser } from './../../api/auth'
import * as React from 'react'
import useFileUpload from 'hooks/useFileUpload'
import { getNameParts } from 'dataProcessors/utils'
import { updateCurrentUser } from 'actions/user'
import { useDispatch } from 'react-redux'
import { useBoolean } from 'hooks/useBoolean'
import { showSnackbar } from 'actions/snackbar'
import { IUserPatch } from 'model/userPatch'
import { PrepareUploadResponse } from '@jetkit/react'

interface PreUploadResponse extends PrepareUploadResponse {
  download_url: string
}

export function useProfile(user: TUser) {
  const dispatch = useDispatch()
  const { uploadFile } = useFileUpload<PreUploadResponse>('/user/me/avatar')
  const { state: openDialog, toggle: toggleDialog } = useBoolean(false)
  const nameParts = getNameParts(user?.name || '')

  const updateUser = React.useCallback(
    (userPatch: IUserPatch) => {
      dispatch(updateCurrentUser(userPatch))
    },
    [dispatch]
  )

  const handleFirstNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newFirstName = event.target.value
      updateUser({ name: newFirstName + ' ' + nameParts.lastName })
    },
    [nameParts.lastName, updateUser]
  )

  const handleLastNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newLastName = event.target.value
      updateUser({ name: nameParts.firstName + ' ' + newLastName })
    },
    [nameParts.firstName, updateUser]
  )

  const handleEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newEmail = event.target.value
      updateUser({ email: newEmail })
    },
    [updateUser]
  )

  const handleNotificationOptionChange = React.useCallback(
    (
      event: React.ChangeEvent<{
        name?: string
        value: unknown
      }>,
      child: React.ReactNode
    ) => {
      const newValue = event.target.value
      updateUser({ notifications_enabled: newValue === 'Enabled' })
      if (newValue === 'Enabled' && Notification.permission !== 'granted') {
        Notification.requestPermission()
      }
    },
    [updateUser]
  )

  const handleTimeZoneChange = React.useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      updateUser({ timezone: value })
    },
    [updateUser]
  )

  const handleAvatarUpload = React.useCallback(
    async (files: File[]) => {
      dispatch(showSnackbar({ message: 'Avatar upload in progress...' }))
      await uploadFile(files[0])

      const result = await getCurrentUser()
      if (result) {
        dispatch(setUser(result))
      }
    },
    [dispatch, uploadFile, updateUser]
  )

  const handleOnDeleteAccountClick = React.useCallback(() => {
    toggleDialog()
  }, [toggleDialog])

  return {
    openDialog,
    toggleDialog,
    handleAvatarUpload,
    handleFirstNameChange,
    nameParts,
    handleLastNameChange,
    handleNotificationOptionChange,
    handleOnDeleteAccountClick,
    handleTimeZoneChange,
    handleEmailChange,
  }
}
