import React from 'react'
import { OnboardingStepTextLines } from '../onboardingStepTextLines'
import { InlineEmoji } from 'shared/components/inlineEmoji'

export const CreateNewThreadStep = () => {
  const textLines = [
    <>
      Click here to <strong>Create a thread</strong> you can share.
    </>,
    <>
      Enjoy productivity
      <InlineEmoji emoji="👍" ariaLabel="thumb-up" withPadding />
    </>,
  ]

  return <OnboardingStepTextLines textLines={textLines} maxWidth={210} />
}
