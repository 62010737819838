import React from 'react'
import { loadThread } from 'api/threads'
import { useEffectAsync } from 'hooks/useEffectAsync'
import { useSelector } from 'react-redux'
import { selectUsersOnlineStatus } from 'selectors/users'
import { DateTime } from 'luxon'

export function useThreadHeaderUserBadge(userId: string, threadId?: string) {
  const usersOnlineStatus: TUsersOnlineStatus = useSelector(selectUsersOnlineStatus)
  const [onlineStatus, setOnlineStatus] = React.useState<IOnlineStatus | undefined>()

  const getUserOnlineStatus = React.useCallback(
    (userId: string): IOnlineStatus | undefined => {
      return usersOnlineStatus ? usersOnlineStatus[userId] : undefined
    },
    [usersOnlineStatus]
  )

  React.useEffect(() => {
    setOnlineStatus(getUserOnlineStatus(userId))
  }, [getUserOnlineStatus, userId])

  const isUserInCurrentThread = threadId === onlineStatus?.location && onlineStatus?.status === 'online'

  const fetchThread = React.useCallback(async () => {
    if (!onlineStatus?.location) return undefined
    try {
      const threadId = onlineStatus?.location
      const thread = await loadThread(threadId)
      return thread?.title
    } catch (error) {
      console.error(error)
      return undefined
    }
  }, [onlineStatus])

  const getTooltipLabel = (
    isSubscribed: boolean,
    onlineStatus?: IOnlineStatus,
    isActiveInThread?: boolean,
    threadTitle?: string
  ): string => {
    if (isActiveInThread) {
      return 'Active in this thread'
    }

    // cases for paid users only
    if (isSubscribed) {
      if (threadTitle && onlineStatus?.status === 'online') {
        return `Active in ${threadTitle}`
      }
    }

    if (!onlineStatus) {
      return 'Offline'
    } else if (!onlineStatus.timestamp) {
      return onlineStatus.status
    } else {
      // if status and timestamp are provided, display detailed info about status
      const date = DateTime.fromISO(onlineStatus.timestamp)
      const relativeDate = date.toRelative()

      switch (onlineStatus.status) {
        case 'online': {
          if (relativeDate) {
            return `Connected ${relativeDate}`
          } else {
            return onlineStatus.status
          }
        }

        case 'offline': {
          if (relativeDate) {
            return `Last seen ${relativeDate}`
          } else {
            return onlineStatus.status
          }
        }

        case 'away': {
          return `Idle for ${date.diffNow()}`
        }
      }
    }
  }

  const { data: title } = useEffectAsync(fetchThread)

  return {
    onlineStatus,
    threadTitle: title,
    isUserInCurrentThread,
    getTooltipLabel,
  }
}
