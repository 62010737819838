import React, { ChangeEvent } from 'react'
import { ChipProps, TextField, Theme, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/styles'
import { useIsMount } from 'hooks/useIsMount'
import { isEmail } from 'dataProcessors/utils'
import classNames from 'classnames'
import { getUserDisplayName } from 'dataProcessors/userDisplayName'
import { getContacts } from 'api/contacts'
import UserSelectItem from 'shared/components/userSelectItem'
import AppLoading from 'component/appLoading'
import { debounce } from 'lodash'

const INPUT_DEBOUNCE_IN_MILLIS = 300

const usePeopleSelectStyles = makeStyles((theme: Theme) => ({
  inputWrapper: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  label: {
    color: theme.threadPalette.brownishGray,
    fontSize: 16,
    marginRight: 6,
    width: 31,
  },
  inputField: {
    backgroundColor: theme.threadPalette.white,
    borderColor: 'transparent',
    borderRadius: 8,
    paddingInline: 8,
    '&:hover': {
      backgroundColor: theme.threadPalette.backgroundColor,
    },
  },
  inputFieldFocused: {
    backgroundColor: theme.threadPalette.backgroundColor,
  },
  peopleList: {
    color: theme.threadPalette.brownishGray,
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 4,
  },
  personInvalid: {
    color: theme.palette.error.contrastText,
  },
  inputRoot: {
    padding: '6px 30px 6px 0px !important',
  },
  input: {
    padding: '6px 0px 6px 4px !important',
  },
}))

export interface IPersonChipProps extends ChipProps {
  person: TPerson
  className?: string
  backgroundColor?: string
}

interface IPeopleSelectProps {
  label?: string
  selectedPeople: TPerson[]
  hideField?: () => void
  onPeopleChange: (people: TPerson[]) => void
  peopleOptionsFilter?: (peopleOptions: TPerson[]) => TPerson[]
  isFocusedOnRendering?: boolean
  isEditable?: boolean
  setRef?: React.Dispatch<React.SetStateAction<HTMLInputElement | null>>
  personChip?: React.FC<IPersonChipProps>
  placeholder?: string
  rootClassName?: string
}
export const PeopleSelect = ({
  label,
  selectedPeople,
  hideField,
  onPeopleChange,
  peopleOptionsFilter,
  setRef,
  isFocusedOnRendering = false,
  isEditable = true,
  personChip: PersonChip,
  rootClassName,
  placeholder,
}: IPeopleSelectProps) => {
  const classes = usePeopleSelectStyles()

  const isFirstRender = useIsMount()

  const [isFocused, setIsFocused] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  const [peopleOptions, setPeopleOptions] = React.useState<TPerson[]>([])
  const [isLoadingPeopleOptions, setIsLoadingPeopleOptions] = React.useState(false)

  const displaySelectedPeopleChipsInlineWithInput = !!PersonChip

  const loadPeopleOptions = React.useCallback(
    async (searchTerm?: string) => {
      const { data: people } = await getContacts(1, searchTerm)
      setPeopleOptions(peopleOptionsFilter ? peopleOptionsFilter(people) : people)
    },
    [peopleOptionsFilter]
  )

  const debouncedLoadPeopleForSearchTerm = React.useCallback(debounce(loadPeopleOptions, INPUT_DEBOUNCE_IN_MILLIS), [])

  const handleAutocompleteInputChange = async (_: ChangeEvent<{}>, value: string, reason: string) => {
    // Without this, when editing a tag, its value is not re-added to the input for editing
    if (reason !== 'reset') setInputValue(value)
    setIsLoadingPeopleOptions(true)
    await debouncedLoadPeopleForSearchTerm(value)
    setIsLoadingPeopleOptions(false)
  }

  const checkEmptySelectedPeopleAndHideField = () => {
    if (selectedPeople.length === 0) hideField?.()
  }

  const parsePersonToDisplayText = (person: TPerson) => {
    if (!person.name) return person.email

    const displayText = `"${person.name}"<${person.email}>`
    return displayText
  }

  const parseDisplayTextToPerson = (text: string): TPerson => {
    const person: TPerson = { email: text, avatarUrl: null, name: null }
    if (isEmail(text)) return person

    // Regex to match the format "USER NAME"<USER EMAIL> and save them in named groups
    const personDisplayNameMatcher = /("(?<name>[a-zA-Z0-9 ].*)")?(<(?<email>.*)>)/gi
    const matches = personDisplayNameMatcher.exec(text)

    const { name, email } = matches?.groups ?? {}

    if (name) person.name = name
    if (email) person.email = email

    return person
  }

  const handleChange = async (value: (TPerson | string)[], reason: string) => {
    // When we're not displaying the SelectedPeople inside the input, it looks like a bug that pressing backspace would erase the selected people from the list, in the order they were added
    if (!displaySelectedPeopleChipsInlineWithInput && reason === 'remove-option') return

    const peopleList = value.map(personOrDisplayText => {
      const isDisplayText = typeof personOrDisplayText === 'string'
      return isDisplayText ? parseDisplayTextToPerson(personOrDisplayText) : personOrDisplayText
    })
    onPeopleChange(peopleList)

    // This allows the user to keep adding more tags, and reseting the input on each new tag added
    if (['select-option', 'create-option'].includes(reason)) setInputValue('')
  }

  React.useEffect(() => {
    // This avoids the field to be hidden on first loading, or when the user removes the last person but the field is still focused
    if (isFirstRender || isFocused) return
    checkEmptySelectedPeopleAndHideField()
  }, [selectedPeople])

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
    // This solves the issue created by by-passing the input value update on `reset` event
    setInputValue('')
    checkEmptySelectedPeopleAndHideField()

    const inputValue = event.target.value
    if (inputValue) handleChange([...selectedPeople, inputValue], 'create-option')
  }

  const getUnfocusedPersonText = (person: TPerson, index: number) => {
    const isValidEmail = isEmail(person.email)

    const personDisplayName = getUserDisplayName(person)
    const invalidPerson = <span className={classes.personInvalid}>{personDisplayName}</span>

    return (
      <React.Fragment key={index}>
        {index !== 0 && ', '}
        {isValidEmail ? personDisplayName : invalidPerson}
      </React.Fragment>
    )
  }

  const peopleOptionsLoadingComponent = isLoadingPeopleOptions && isFocused && (
    <div style={{ width: 28 }}>
      <AppLoading visible small padding={0} legWidth={6} legHeight={12} />
    </div>
  )

  const displayInputPlaceholder = !displaySelectedPeopleChipsInlineWithInput || selectedPeople.length === 0

  const renderTags = (people: TPerson[], getTagProps: any) => {
    if (!displaySelectedPeopleChipsInlineWithInput) return null
    if (!isFocused) return <Typography className={classes.peopleList}>{people.map(getUnfocusedPersonText)}</Typography>

    const peopleChips = people.map((person, index) => {
      const tagProps: any = getTagProps({ index })
      return (
        <PersonChip
          key={person.email}
          person={person}
          onDoubleClick={() => {
            const personDisplayText = parsePersonToDisplayText(person)
            tagProps?.onDelete()
            setInputValue(personDisplayText)
          }}
          {...tagProps}
        />
      )
    })

    return peopleChips
  }
  return (
    <div className={classes.inputWrapper}>
      {label && (
        <Typography align="right" className={classes.label}>
          {label}
        </Typography>
      )}
      <Autocomplete
        id="tags-outlined"
        size="small"
        className={rootClassName}
        classes={{ inputRoot: classes.inputRoot, input: classes.input }}
        value={selectedPeople}
        onChange={(_, value, reason) => handleChange(value, reason)}
        options={peopleOptions}
        autoSelect={false}
        autoComplete
        autoHighlight={false}
        clearOnBlur
        disableClearable={!isFocused && displaySelectedPeopleChipsInlineWithInput}
        disabled={!isEditable}
        filterSelectedOptions
        freeSolo
        fullWidth
        includeInputInList
        inputValue={inputValue}
        multiple
        openOnFocus
        selectOnFocus
        onInputChange={handleAutocompleteInputChange}
        getOptionLabel={personOption => getUserDisplayName(personOption)}
        // This aims to override Mui native filters, that causes the options to don't show correctly when using objects as values, instead of simple strings.
        filterOptions={person => person}
        getOptionSelected={(person, value) => person.email === value.email}
        renderOption={(person: TPerson) => <UserSelectItem user={person} key={person.id} />}
        noOptionsText={'No people found with this term'}
        limitTags={5}
        renderTags={renderTags}
        renderInput={({ InputProps, ...params }) => {
          return (
            <TextField
              {...params}
              classes={{ root: classNames(classes.inputField, { [classes.inputFieldFocused]: isFocused }) }}
              onFocus={() => setIsFocused(true)}
              onBlur={onBlur}
              variant="standard"
              InputProps={{
                ...InputProps,
                disableUnderline: true,
                startAdornment: (
                  <>
                    {InputProps.startAdornment}
                    {peopleOptionsLoadingComponent}
                  </>
                ),
              }}
              fullWidth
              autoFocus={isFocusedOnRendering}
              inputRef={input => setRef?.(input)}
              placeholder={displayInputPlaceholder ? placeholder : undefined}
            />
          )
        }}
      />
    </div>
  )
}
