import React from 'react'
import classnames from 'classnames'
import { makeStyles, Theme, createStyles, FormControlLabel, Checkbox } from '@material-ui/core'

const useNotifyPeopleByEmailCheckboxStyles = makeStyles((theme: Theme) =>
  createStyles({
    notifyPeopleByEmailLabel: {
      color: theme.threadPalette.brownishGray,
      fontSize: 15,
      marginLeft: 6,
    },
    notifyPeopleByEmailLabelRoot: {
      margin: 0,
      padding: 0,
    },
    notifyPeopleByEmailCheckbox: {
      color: theme.threadPalette.lightGray,
      padding: 0,
    },
    notifyPeopleByEmailCheckboxChecked: {
      color: theme.threadPalette.green,
    },
    notifyPeopleByEmailCheckboxRoot: {
      width: 'fit-content',
    },
  })
)

interface INotifyPeopleByEmailCheckboxProps {
  isChecked: boolean
  toggleIsChecked(): void
}

export const NotifyPeopleByEmailCheckbox = ({ isChecked, toggleIsChecked }: INotifyPeopleByEmailCheckboxProps) => {
  const classes = useNotifyPeopleByEmailCheckboxStyles()

  return (
    <FormControlLabel
      name="notify-by-email-checkbox"
      classes={{ label: classes.notifyPeopleByEmailLabel, root: classes.notifyPeopleByEmailLabelRoot }}
      className={classes.notifyPeopleByEmailCheckboxRoot}
      control={
        <Checkbox
          checked={isChecked}
          onChange={toggleIsChecked}
          classes={{
            root: classnames(classes.notifyPeopleByEmailCheckbox, {
              [classes.notifyPeopleByEmailCheckboxChecked]: isChecked,
            }),
          }}
          size="small"
        />
      }
      label="Notify people by email"
    />
  )
}
