import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import CustomChip from 'shared/components/chip'
import FolderIcon from '@material-ui/icons/Folder'

const useProjectChipStyles = makeStyles((theme: Theme) => ({
  chipRoot: {
    backgroundColor: theme.threadPalette.darkBlue,
    paddingLeft: 4,
  },
  folderIcon: {
    height: 10,
    width: 10,
    fill: theme.threadPalette.white,
  },
}))

interface IProjectChipProps {
  name: string
  onRemove?(): void
}

const ProjectChip = ({ name, onRemove }: IProjectChipProps) => {
  const classes = useProjectChipStyles()
  return (
    <CustomChip
      className={classes.chipRoot}
      avatar={<FolderIcon classes={{ root: classes.folderIcon }} />}
      label={name}
      onDelete={onRemove}
    />
  )
}

export default ProjectChip
