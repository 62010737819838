import { useState, useRef, useEffect } from 'react'

/*
 *    Helper hook to check if text element is overflowing and with added Ellipsis to shorten it
 */

export const useTextInEllipsis = () => {
  const textRef = useRef<HTMLDivElement | null>(null)
  const [hasEllipsis, setHasEllipsis] = useState(false)
  const [isOverflowingVertically, setIsOverflowingVertically] = useState(false)

  useEffect(() => {
    const listItemElement = textRef?.current
    if (!listItemElement) {
      setHasEllipsis(false)
      return
    }

    const isTitleOverflowingHorizontally = listItemElement.offsetWidth < listItemElement.scrollWidth
    const isTitleOverflowingVertically = listItemElement.offsetHeight < listItemElement.scrollHeight

    setHasEllipsis(isTitleOverflowingHorizontally)
    setIsOverflowingVertically(isTitleOverflowingVertically)
  }, [textRef])

  return { textRef, hasEllipsis, isOverflowingVertically }
}
