import { useQuery } from '@tanstack/react-query'
import { getUserOnboardings } from 'api/v2/onboarding'
import { getUserOnboardingsQueryKey } from './useUserOnboardingsCacheActions'
import { useUser } from 'hooks/auth'
import { getTimeInMilliSeconds } from 'dataProcessors/utils'

const ONE_HOUR_IN_MILLISECONDS = getTimeInMilliSeconds({ hours: 1 })

export const useUserOnboardings = () => {
  const currentUser = useUser()

  const { data: userOnboardings = [], isLoading } = useQuery({
    queryKey: getUserOnboardingsQueryKey({ userId: currentUser?.id ?? '' }),
    queryFn: () => getUserOnboardings(),
    staleTime: ONE_HOUR_IN_MILLISECONDS,
    enabled: !!currentUser?.id,
  })

  return { userOnboardings, isLoading }
}
