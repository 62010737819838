import React, { useContext } from 'react'

interface INewProjectModalContext {
  openModal: () => void
  closeModal: () => void
  isOpen: boolean
}

export const NewProjectModalContext = React.createContext<INewProjectModalContext | undefined>(undefined)

export const NewProjectModalProvider: React.FC<{}> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return (
    <NewProjectModalContext.Provider
      value={{
        isOpen,
        openModal,
        closeModal,
      }}
    >
      {children}
    </NewProjectModalContext.Provider>
  )
}

export const useNewProjectModalContext = () => {
  const context = useContext(NewProjectModalContext)

  if (context === undefined)
    throw new Error('useNewProjectModalContext should be used within a NewProjectModalProvider ')

  return context
}
