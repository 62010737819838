import Skeleton from '@material-ui/lab/Skeleton'
import classNames from 'classnames'
import React from 'react'
import { useStyles } from './threadItem.styles'

const ThreadSkeletonItem = () => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.threadItem)}>
      <div className={classes.flexContainer}>
        <Skeleton variant="circle" width={40} height={40} className={classes.loadingItem} />
        <div className={classes.threadInfoWrapper}>
          <Skeleton
            variant="text"
            className={classNames(classes.threadInfoItem, classes.threadName, classes.loadingItem)}
          />
          <Skeleton
            variant="text"
            className={classNames(classes.threadInfoItem, classes.lastSenderName, classes.loadingItem)}
          />
        </div>
      </div>
    </div>
  )
}

export default ThreadSkeletonItem
