import React from 'react'
import { Button, fade, useTheme } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import ImgSigninButtonGoogle from 'assets/signin_button_google.png'
import ImgSigninButtonMicrosoft from 'assets/signin_button_microsoft.png'

interface ISigninButtonProps {
  className?: string
  icon?: string
  text: string
  onClick?: (event: React.SyntheticEvent) => void
  dataCy?: string
  backgroundColor?: string
  startIconBackgroundColor?: string
}

const useStyles = makeStyles<Theme, Pick<ISigninButtonProps, 'backgroundColor' | 'startIconBackgroundColor'>>(theme => {
  return {
    root: {
      backgroundColor: ({ backgroundColor }) => backgroundColor,
      borderRadius: 4,
      boxShadow: `0 1px 1px 0 ${fade(theme.threadPalette.darkestBlue, 0.4)}, 0 0 1px 0 ${fade(
        theme.threadPalette.darkestBlue,
        0.2
      )}`,
      fontSize: 19,
      fontWeight: 500,
      height: 56,
      padding: 1,
      width: 280,

      '&:hover': {
        backgroundColor: ({ backgroundColor }) => backgroundColor && fade(backgroundColor, 0.9),
      },
    },
    label: {
      display: 'inline-flex',
      justifyContent: 'flex-start',
    },
    startIconContainer: {
      backgroundColor: ({ startIconBackgroundColor }) => startIconBackgroundColor,
      borderRadius: 2,
      display: 'grid',
      height: 54,
      minWidth: 54,
      placeItems: 'center',
      width: 54,
    },
    startIcon: {
      height: 28,
      width: 28,
    },
    labelTextContainer: {
      display: 'inline-flex',
      justifyContent: 'center',
      paddingInline: 8,
      width: '100%',
    },
  }
})

const SigninButton = (props: ISigninButtonProps) => {
  const classes = useStyles(props)

  let startIcon = null
  if (props.icon) {
    startIcon = <img className={classes.startIcon} src={props.icon} alt="" />
  }

  return (
    <Button
      data-cy={props.dataCy}
      className={props.className}
      classes={{
        label: classes.label,
        root: classes.root,
      }}
      color="primary"
      fullWidth
      variant="contained"
      onClick={props.onClick}
      disableElevation
    >
      <div className={classes.startIconContainer}>{startIcon}</div>
      <div className={classes.labelTextContainer}>{props.text}</div>
    </Button>
  )
}

export default SigninButton

interface ThirdPartySigninButtonProps {
  onClick?(event: React.SyntheticEvent): void
}
export const GoogleSigninButton = ({ onClick }: ThirdPartySigninButtonProps) => {
  const theme = useTheme()
  return (
    <SigninButton
      dataCy={'google-signin'}
      icon={ImgSigninButtonGoogle}
      text="Sign in with Google"
      onClick={onClick}
      backgroundColor="#4285f4"
      startIconBackgroundColor={theme.threadPalette.white}
    />
  )
}

export const OutlookSigninButton = ({ onClick }: ThirdPartySigninButtonProps) => {
  return (
    <SigninButton
      dataCy={'outlook-signin'}
      icon={ImgSigninButtonMicrosoft}
      text="Sign in with Microsoft"
      backgroundColor="#2f2f2f"
      onClick={onClick}
    />
  )
}
