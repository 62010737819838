import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, Typography } from '@material-ui/core'
import ConnectExistingEmailThread from 'component/newThreadModal/connectExistingEmailThread'
import Spacer from 'component/layoutUtils/spacer'

export const useConnectAnEmailStepStyles = makeStyles((theme: Theme) => ({
  header: {
    fontSize: 23,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 'normal',
    lineHeight: 'normal',
    marginBottom: 30,
    marginTop: 20,
  },
}))

interface IConnectAnEmailStepProps {
  onSuccess: () => void
}
const ConnectAnEmailStep = ({ onSuccess }: IConnectAnEmailStepProps) => {
  const classes = useConnectAnEmailStepStyles()

  return (
    <>
      <Typography align="center" className={classes.header}>
        Choose an email to follow and create your first thread.
      </Typography>
      <ConnectExistingEmailThread onSuccess={onSuccess} hideSearch={true} buttonText="Create thread" />
      <Spacer height={26} />
    </>
  )
}

export default ConnectAnEmailStep
