import { useThreadMessagesCacheActions } from 'hooks/useThreadMessages/useThreadMessagesCacheActions'
import { useThreadActions } from './useThreadActions'
import { useThreadFollowersActions } from 'hooks/useThreadFollowers/useThreadFollowersActions'

export const useRefreshThreadData = () => {
  const threadCacheActions = useThreadActions()
  const threadMessagesCacheActions = useThreadMessagesCacheActions()
  const threadFollowersCacheActions = useThreadFollowersActions()

  const refreshThreadData = (threadId: string) => {
    threadCacheActions.refreshThread(threadId)
    threadMessagesCacheActions.refreshData(threadId)
    threadFollowersCacheActions.refreshThreadFollowers(threadId)
    threadCacheActions.refreshThreadsList()
  }

  return { refreshThreadData }
}
