import React from 'react'
import { useSelector } from 'react-redux'

import { handleCallback } from '../actions/googleOAuth'
import { OAuthCallbackPage } from '../lib/oauth/pages'
import { selectGoogleOAuthHandlingCallback } from '../selectors/auth'

interface IGoogleOAuthCallbackPageProps {}

const GoogleOAuthCallbackPage = (props: IGoogleOAuthCallbackPageProps) => {
  const handlingCallback = useSelector(selectGoogleOAuthHandlingCallback)

  return <OAuthCallbackPage handleCallback={handleCallback} working={handlingCallback} />
}

export default GoogleOAuthCallbackPage
