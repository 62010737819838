import React from 'react'
import { ISharingGroup, IThreadSharingSettings } from 'model/sharingGroup'
import { useSharingGroupContext } from 'contexts/sharingGroupContext'
import { useThreadSharingSettingsFormFieldsContext } from './useThreadSharingSettingsFormFieldsContext'
import { EditThreadSharingSettingsScreen } from './editThreadSharingSettingsScreen/editThreadSharingSettingsScreen'
import { SelectMessagesToShareScreen } from './selectMessagesToShareScreen/selectMessagesToShareScreen'
import { useCreateOrEditThreadSharingSettingsUseCase } from 'usecases/sharingGroup/createOrEditThreadSharingSettings'
import { useStopSharingThreadWithSharingGroupUseCase } from 'usecases/sharingGroup/stopSharingThreadWithSharingGroup'
import { getUserDisplayName } from 'dataProcessors/userDisplayName'
import splitLines from 'split-lines'

interface ICreateOrEditThreadSharingSettingsScreenProps {
  threadSharingSettings?: IThreadSharingSettings
  sharingGroup: ISharingGroup
  onUpdateThreadSharingSettings?(): void
  onEditSharingGroup?(): void
  onStopSharing?(): void
  onReturn?(): void
}

const getDefaultNotificationEmailContentMessage = ({ sharingGroupOwnerName }: { sharingGroupOwnerName: string }) => {
  return `<div>Hi,</div>
  <div>I've shared this thread with you because I thought you might find it helpful.</div>
  <div>Please take a look and let me know what you think.</div>
  <br>
  <div>Thanks,</div>
  <div>${sharingGroupOwnerName}</div>`
}

export const CreateOrEditThreadSharingSettingsScreen = ({
  threadSharingSettings,
  sharingGroup,
  onUpdateThreadSharingSettings,
  onStopSharing,
  onEditSharingGroup,
  onReturn,
}: ICreateOrEditThreadSharingSettingsScreenProps) => {
  const isCreatingThreadSharingSettings = !threadSharingSettings

  const { thread } = useSharingGroupContext()

  const {
    selectedMessagesIds,
    setSelectedMessagesIds,
    activeMessagesSelectorOption,
    autoShareConditions,
    notifyMembersByEmail,
    notificationEmailMessage,
  } = useThreadSharingSettingsFormFieldsContext()

  const [isSelectingMessages, setIsSelectingMessages] = React.useState(false)

  const { stopSharingThreadWithSharingGroupUseCase } = useStopSharingThreadWithSharingGroupUseCase({ onStopSharing })
  const onStopSharingThread = () =>
    stopSharingThreadWithSharingGroupUseCase({ sharingGroupId: sharingGroup.id, threadId: thread.id })

  const { createOrEditThreadSharingSettingsUseCase, isLoading: isSubmittingThreadSharingSettings } =
    useCreateOrEditThreadSharingSettingsUseCase({
      onSuccess: onUpdateThreadSharingSettings,
    })
  const onSubmit = () => {
    const isAllMessagesOptionsSelected = activeMessagesSelectorOption === 'allMessages'
    const finalSelectMessagesIds = isAllMessagesOptionsSelected ? [] : selectedMessagesIds
    let notificationEmailContentMessage

    if (notifyMembersByEmail && !!!notificationEmailMessage) {
      const sharingGroupOwnerDisplayName = getUserDisplayName(sharingGroup.owner)
      notificationEmailContentMessage = getDefaultNotificationEmailContentMessage({
        sharingGroupOwnerName: sharingGroupOwnerDisplayName,
      })
    }
    if (notifyMembersByEmail && !!notificationEmailMessage) {
      const notificationEmailMessageAsHTML = splitLines(notificationEmailMessage).join('<br>')
      notificationEmailContentMessage = notificationEmailMessageAsHTML
    }

    createOrEditThreadSharingSettingsUseCase({
      sharingGroupId: sharingGroup.id,
      threadId: thread.id,
      selectAllMessages: isAllMessagesOptionsSelected,
      selectedMessagesIds: finalSelectMessagesIds,
      autoShareConditions,
      notificationEmailContentMessage,
    })
  }

  const visibleScreen = isSelectingMessages ? (
    <SelectMessagesToShareScreen
      thread={thread}
      initialSelectedMessagesIds={selectedMessagesIds}
      onConfirmSelectedMessages={setSelectedMessagesIds}
      onReturnToPreviousScreenClick={() => setIsSelectingMessages(false)}
    />
  ) : (
    <EditThreadSharingSettingsScreen
      isCreatingThreadSharingSettings={isCreatingThreadSharingSettings}
      thread={thread}
      sharingGroup={sharingGroup}
      onEditSharingGroup={onEditSharingGroup}
      onSubmit={onSubmit}
      isSubmitting={isSubmittingThreadSharingSettings}
      onStopSharing={onStopSharingThread}
      setIsSelectingMessages={setIsSelectingMessages}
      onReturn={onReturn}
    />
  )

  return visibleScreen
}
