export const WS_ACTIONS = {
  ACTIVITY_CREATED: 'activity_created',
  CLOUD_CONVERT_EVENT: 'cloud_convert_event',
  DELETE_MESSAGE: 'delete_message',
  MESSAGE_EDITED: 'edited_message',
  NEW_MESSAGE: 'new_message',
  NEW_NOTIFICATION: 'new_notification',
  NEW_THREAD: 'new_thread',
  ONLINE_STATUS_CHANGE: 'online_status_change',
  SEEN_BY_CHANGE: 'seen_by_change',
  TYPING: 'typing',
  THREAD_FOLLOWERS_UPDATED: 'followers_list_update',
  MAILBOX_SYNC: 'mailbox_sync',
  THREAD_MERGED: 'thread_merged',
  THREAD_HAS_IMPORT_ERRORS: 'thread_has_import_errors',
  THREAD_FOLLOWED: 'thread_followed',
  THREAD_UNFOLLOWED: 'thread_unfollowed',
  ATTACHMENT_UPLOADED_TO_FILE_STORAGE: 'attachment_uploaded_to_file_storage',
  DRAFT_SYNCING_STATUS_UPDATED: 'draft_syncing_status_updated',
  THREAD_IMPORTED: 'thread_imported',
  MESSAGES_BATCH_IMPORTED: 'messages_batch_imported',
  IMPORTING_THREAD: 'importing_thread',
}
