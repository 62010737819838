import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { editProject } from 'api/v2/project'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { useProjectActions } from 'hooks/useProjects/useProjectActions'
import { useThreadProjectActions } from 'hooks/useThreadProjects/useThreadProjectActions'
import { useDispatch } from 'react-redux'

export const useUpdateProjectUseCase = () => {
  const dispatch = useDispatch()
  const { refreshProjectsList, refreshProject } = useProjectActions()
  const { refreshThreadProjects } = useThreadProjectActions()
  const { currentThread } = useCurrentThreadContext()

  const { mutateAsync: runEditProjectMutation } = useMutation(editProject, {
    onError: () => {
      dispatch(showSnackbar({ message: 'Error trying to update project' }))
    },
    onSettled: (_, __, variables) => {
      const { projectId } = variables
      refreshProjectsList()
      refreshProject(projectId)
      if (currentThread) refreshThreadProjects(currentThread.id)
    },
  })
  const updateProjectUseCase: typeof editProject = runEditProjectMutation

  return { updateProjectUseCase }
}
