import React from 'react'
import classNames from 'classnames'

import { Hidden, IconButton, Theme, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ReplyIcon from '@material-ui/icons/Reply'
import CloseIcon from '@material-ui/icons/Close'

import { ExpandInputIconButton } from './expandInputIconButton'
import { TDraftType } from 'component/message/draftMessage/draftMessage'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import DialogAnchoredOnComponent from 'component/dialogAnchoredOnComponent'
import { TDraftSyncState, getMessageContentAsPreview } from 'domain/message'
import { SyncDraftButton } from './syncDraftButton'
import Spacer from 'component/layoutUtils/spacer'

const useMessageEditorHeaderStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    color: theme.threadPalette.lightGray,
    display: 'flex',
    width: '100%',
  },
  expandIconWrapper: {
    marginRight: 8,
  },
  previewText: {
    fontSize: 12,
    fontStyle: 'italic',
  },
  previewContent: {
    flex: 1,
  },
  previewSender: {
    fontWeight: 900,
    marginRight: 5,
  },
  previewIcon: {
    fontSize: 16,
    marginRight: 5,
  },
  closeIconWrapper: {
    marginLeft: 4,
  },
  closeIcon: {
    color: theme.threadPalette.lightGray,
    fontSize: 20,
  },
  forwardIcon: {
    transform: 'rotateY(180deg)',
  },
}))

interface IMessageEditorHeader {
  draftType?: TDraftType
  repliedOrForwardedMessage?: IMessage
  className?: string
  onDeleteMessage?(): void
  isInFocusMode: boolean
  toggleExpanded(): void
  showDeleteDraftButton?: boolean
  draftSyncState: TDraftSyncState
  emailProvider: TEmailProvider
  onForcedUpdate(): void
  hasUploadsInProgress: boolean
}

const MessageEditorHeader = ({
  draftType,
  repliedOrForwardedMessage,
  className = '',
  onDeleteMessage,
  isInFocusMode,
  toggleExpanded,
  showDeleteDraftButton = true,
  draftSyncState,
  emailProvider,
  onForcedUpdate,
  hasUploadsInProgress,
}: IMessageEditorHeader) => {
  const classes = useMessageEditorHeaderStyles()
  const { isOpen: isErrorDialogOpen, dialogAnchorElement, handleClickToOpenDialog, handleCloseDialog } = useDialog()

  const theme = useTheme()

  const deleteConfirmationActions = [
    {
      label: 'Cancel',
      action: handleCloseDialog,
    },
    {
      label: 'Delete draft',
      labelColor: theme.palette.error.contrastText,
      action: onDeleteMessage,
    },
  ]

  return (
    <div className={classNames(classes.root, className)}>
      <Hidden xsDown implementation="css">
        <ExpandInputIconButton
          className={classes.expandIconWrapper}
          isExpanded={isInFocusMode}
          toggleExpanded={toggleExpanded}
        />
      </Hidden>
      <SyncDraftButton
        draftSyncState={draftSyncState}
        providerName={emailProvider}
        onForcedUpdate={onForcedUpdate}
        hasUploadsInProgress={hasUploadsInProgress}
      />
      <Spacer width={8} />
      {repliedOrForwardedMessage && (
        <>
          <ReplyIcon
            classes={{ root: classNames(classes.previewIcon, { [classes.forwardIcon]: draftType === 'forward' }) }}
          />
          <Typography className={classNames(classes.previewText, classes.previewSender)}>
            {repliedOrForwardedMessage?.sender?.email}
          </Typography>
          <Typography className={classNames(classes.previewText, classes.previewContent)} noWrap>
            {getMessageContentAsPreview(repliedOrForwardedMessage)}
          </Typography>
        </>
      )}
      {showDeleteDraftButton && onDeleteMessage && (
        <DialogAnchoredOnComponent
          open={isErrorDialogOpen}
          anchoredElementRef={dialogAnchorElement}
          onClose={handleCloseDialog}
          message="Confirm you would like to delete this draft"
          textAlign="center"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          actions={deleteConfirmationActions}
        >
          <IconButton size="small" onClick={handleClickToOpenDialog} className={classes.closeIconWrapper}>
            <CloseIcon classes={{ root: classes.closeIcon }} />
          </IconButton>
        </DialogAnchoredOnComponent>
      )}
    </div>
  )
}

export default MessageEditorHeader
