import { IUserPatch } from './../model/userPatch'
import { USER_ACTIONS } from './../constants/actions/user'
import * as UsersApi from 'api/users'
import { debounce } from 'lodash'
import { Dispatch } from 'redux'
import { checkAuth } from 'actions/checkAuth'

export const changeOnlineStatus = (userId: string, status: IOnlineStatus): TThunk => {
  return (dispatch, getState) => {
    dispatch({
      type: USER_ACTIONS.CHANGE_ONLINE_STATUS,
      status: status,
      userId: userId,
    })
  }
}

export const loadUsers = (): TAsyncThunk => {
  return async (dispatch, getState) => {
    dispatch({ type: USER_ACTIONS.LOAD_USERS_START })

    let users
    try {
      users = await UsersApi.loadUsers()
    } catch (error) {
      dispatch({
        type: USER_ACTIONS.LOAD_USERS_FAILURE,
        error: error,
      })
      return
    }

    dispatch({
      type: USER_ACTIONS.LOAD_USERS_SUCCESS,
      users: users,
    })
  }
}

const updateUser = debounce(async (dispatch: Dispatch<any>, userDetails: IUserPatch) => {
  await UsersApi.patchCurrentUser(userDetails)
  dispatch(checkAuth())
}, 300)

export const updateCurrentUser = (userDetails: IUserPatch): TThunk => {
  return async (dispatch, _) => {
    await updateUser(dispatch, userDetails)
  }
}
