import classNames from 'classnames'
import { Checkbox, CheckboxProps, Theme, makeStyles } from '@material-ui/core'
import React from 'react'

const useCustomCheckboxStyles = makeStyles((theme: Theme) => ({
  checkboxRoot: {
    color: theme.threadPalette.lightGray,
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },
}))

interface ICustomCheckboxProps extends Omit<CheckboxProps, 'classes'> {
  className?: string
}

export const CustomCheckbox = ({ className, ...props }: ICustomCheckboxProps) => {
  const classes = useCustomCheckboxStyles()
  return <Checkbox classes={{ root: classNames(classes.checkboxRoot, className) }} {...props} />
}
