import React from 'react'
import { makeStyles, Theme, useTheme } from '@material-ui/core'
import DialogAnchoredOnComponent from 'component/dialogAnchoredOnComponent'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import { EditButton } from './editButton'
import StopSharingIcon from '@material-ui/icons/VisibilityOff'
import classNames from 'classnames'
import AppLoading from 'component/appLoading'

const useStopSharingMessageButtonStyles = makeStyles((theme: Theme) => ({
  stopSharingMessageButtonRoot: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },
  buttonLabel: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-flex',
    gap: 4,
  },
  stopSharingMessageConfirmationPopupContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    width: 432,
  },
  icon: {
    fontSize: 12,
    marginBottom: 2,
  },
}))

export interface IStopSharingMessageButtonProps {
  sharingGroupName: string
  anchorElementRefForConfirmationPopup?: React.RefObject<HTMLElement>
  isLoading?: boolean
  onClick?(): void
  className?: string
}

export const StopSharingMessageButton = ({
  sharingGroupName,
  anchorElementRefForConfirmationPopup,
  onClick,
  isLoading = false,
  className = '',
}: IStopSharingMessageButtonProps) => {
  const theme = useTheme()
  const classes = useStopSharingMessageButtonStyles()

  const {
    isOpen: isStopSharingMessageConfirmationPopupOpen,
    dialogAnchorElement,
    openDialog: openStopSharingMessageConfirmationPopup,
    handleCloseDialog: closeStopSharingMessageConfirmationPopup,
    setDialogAnchorRef: setStopSharingMessageConfirmationPopupAnchorElement,
  } = useDialog()

  const onStopSharing = () => {
    onClick?.()
    closeStopSharingMessageConfirmationPopup()
  }

  React.useEffect(() => {
    if (!anchorElementRefForConfirmationPopup?.current) return
    setStopSharingMessageConfirmationPopupAnchorElement(anchorElementRefForConfirmationPopup.current)
  }, [anchorElementRefForConfirmationPopup])

  const stopSharingMessageWarningActions = [
    {
      label: 'Cancel',
      action: closeStopSharingMessageConfirmationPopup,
    },
    {
      label: 'Stop sharing',
      labelColor: theme.palette.error.contrastText,
      action: onStopSharing,
    },
  ]

  const icon = isLoading ? (
    <AppLoading visible small legWidth={6} legHeight={16} fill={theme.palette.error.main} padding={4} />
  ) : (
    <StopSharingIcon className={classes.icon} />
  )

  return (
    <DialogAnchoredOnComponent
      open={isStopSharingMessageConfirmationPopupOpen}
      anchoredElementRef={dialogAnchorElement}
      onClose={closeStopSharingMessageConfirmationPopup}
      message={
        <>
          Are you sure you want to stop sharing this message with <strong>{sharingGroupName}</strong>?
        </>
      }
      textAlign="center"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      actions={stopSharingMessageWarningActions}
      rootClassName={classes.stopSharingMessageConfirmationPopupContainer}
    >
      <EditButton
        label={
          <>
            {icon}
            Remove access to this message
          </>
        }
        className={classNames(classes.stopSharingMessageButtonRoot, className)}
        labelClassName={classes.buttonLabel}
        onClick={openStopSharingMessageConfirmationPopup}
      />
    </DialogAnchoredOnComponent>
  )
}
