import { apiClient } from '@jetkit/react'
import { AxiosResponse } from 'axios'
import { IAutoShareCondition, ISharingGroup, IThreadSharingSettings } from 'model/sharingGroup'

const BASE_URI = '/v2/sharing-group'

interface IThreadSettings {
  threadId: string
  autoShareConditions?: IAutoShareCondition[]
  selectAllMessages: boolean
  selectedMessagesIds: string[]
}

export const getUserSharingGroups = async (): Promise<ISharingGroup[]> => {
  const result = await apiClient.get<ISharingGroup[]>(BASE_URI)
  return result.data
}

type ICreateSharingGroupProps = {
  name: string
  membersEmails: string[]
}
type ICreateSharingGroupAPIParams = Rename<ICreateSharingGroupProps, 'membersEmails', 'members'>

export const createSharingGroup = async ({
  name,
  membersEmails: members,
}: ICreateSharingGroupProps): Promise<ISharingGroup> => {
  const result = await apiClient.post<ICreateSharingGroupAPIParams, AxiosResponse<ISharingGroup>>(`${BASE_URI}`, {
    name,
    members,
  })
  return result.data
}

export const getSharingGroup = async (sharingGroupId: string): Promise<ISharingGroup> => {
  const result = await apiClient.get(`${BASE_URI}/${sharingGroupId}`)
  return result.data
}

export const addSharingGroupMembers = async ({
  sharingGroupId,
  membersEmailToAdd,
  notificationEmailContentMessage,
}: {
  sharingGroupId: string
  membersEmailToAdd: string[]
  notificationEmailContentMessage: string
}): Promise<void> =>
  apiClient.patch(`${BASE_URI}/${sharingGroupId}/add-members`, {
    members: membersEmailToAdd,
    notificationEmailContentMessage,
  })

type IEditSharingGroupProps = Partial<ICreateSharingGroupProps> & {
  sharingGroupId: string
}
export const editSharingGroup = async ({
  sharingGroupId,
  name,
  membersEmails,
}: IEditSharingGroupProps): Promise<void> =>
  apiClient.patch(`${BASE_URI}/${sharingGroupId}`, { name, members: membersEmails })

type ICreateOrOverwriteThreadSharingSettingsProps = IThreadSettings & {
  sharingGroupId: string
  notificationEmailContentMessage?: string
}
export const createOrOverwriteThreadSharingSettings = async ({
  sharingGroupId,
  threadId,
  ...props
}: ICreateOrOverwriteThreadSharingSettingsProps): Promise<IThreadSharingSettings> => {
  const response = await apiClient.put<
    Omit<ICreateOrOverwriteThreadSharingSettingsProps, 'sharingGroupId' | 'threadId'>,
    AxiosResponse<IThreadSharingSettings>
  >(`${BASE_URI}/${sharingGroupId}/threads/${threadId}`, props)
  return response.data
}
export const stopSharingThreadWithSharingGroup = async ({
  sharingGroupId,
  threadId,
}: {
  sharingGroupId: string
  threadId: string
}): Promise<void> => apiClient.delete(`${BASE_URI}/${sharingGroupId}/thread/${threadId}`)

export const deleteSharingGroup = async (sharingGroupId: string): Promise<void> =>
  apiClient.delete(`${BASE_URI}/${sharingGroupId}`)

export const stopSharingMessageWithSharingGroup = async ({
  sharingGroupId,
  messageId,
}: {
  sharingGroupId: string
  messageId: string
}): Promise<void> => apiClient.delete(`${BASE_URI}/${sharingGroupId}/messages/${messageId}`)

export const shareMessageWithSharingGroup = async ({
  sharingGroupId,
  messageId,
}: {
  sharingGroupId: string
  messageId: string
}): Promise<void> => {
  await apiClient.post(`${BASE_URI}/${sharingGroupId}/messages/${messageId}`)
}

export const rejectHeldMessageForSharingGroup = async ({
  sharingGroupId,
  messageId,
}: {
  sharingGroupId: string
  messageId: string
}): Promise<void> => {
  await apiClient.post(`${BASE_URI}/${sharingGroupId}/messages/${messageId}/reject`)
}
