import * as React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { push } from '../actions/navigation'
import { GoogleSigninButton, OutlookSigninButton } from './signinButton/signinButton'
import Spacer from './layoutUtils/spacer'

const useStyles = makeStyles((theme: Theme) => ({
  signinButtonsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const SignInButtons = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onGoogleSigninButtonClick = React.useCallback(
    (_: React.SyntheticEvent) => {
      dispatch(push('/oauth/google/login'))
    },
    [dispatch, push]
  )

  const onOutlook365SigninButtonClick = React.useCallback(
    (_: React.SyntheticEvent) => {
      dispatch(push('/oauth/outlook365/login'))
    },
    [dispatch, push]
  )

  return (
    <div className={classes.signinButtonsContainer}>
      <GoogleSigninButton onClick={onGoogleSigninButtonClick} />
      <Spacer height={35} />
      <OutlookSigninButton onClick={onOutlook365SigninButtonClick} />
    </div>
  )
}
