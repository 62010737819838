import * as React from 'react'
import { makeStyles, Theme, Tooltip, Typography } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import InlineEditingTextField from 'shared/inputs/inlineEditingTextField'
import Spacer from 'component/layoutUtils/spacer'
import { getThreadDisplayName } from 'domain/thread'
import classNames from 'classnames'

const useThreadTitleStyles = makeStyles((theme: Theme) => ({
  threadTitleContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  editInput: {
    maxWidth: 350,
  },
  editIcon: {
    color: theme.threadPalette.lightGray,
    fontSize: 16,
  },
  tooltip: {
    backgroundColor: theme.threadPalette.menuItem.current,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.4)',
    color: theme.threadPalette.white,
    paddingBlock: 6,
    paddingInline: 10,
  },
  tooltipArrow: {
    '&::before': {
      backgroundColor: theme.threadPalette.menuItem.current,
    },
  },
  emailIcon: {
    fontSize: 16,
    color: theme.threadPalette.lightGray,
  },
  title: {
    flex: 1,
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '30px',
    margin: '0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
}))

interface IThreadTitleProps {
  id?: string
  title: string
  alias?: string
  onThreadAliasChange?(newThreadAlias?: string): Promise<void>
  titleClassName?: string
}

const ThreadTitle = ({ id, title, alias, onThreadAliasChange, titleClassName }: IThreadTitleProps) => {
  const classes = useThreadTitleStyles()

  const iconWithThreadTitleTooltip = (
    <Tooltip
      arrow
      placement="bottom-start"
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      title={
        <Typography>
          Email subject: <strong>{title}</strong>
        </Typography>
      }
    >
      <EmailIcon className={classes.emailIcon} />
    </Tooltip>
  )

  const threadDisplayName = getThreadDisplayName({ title, titleAlias: alias })

  return (
    <div id={id}>
      <InlineEditingTextField
        onConfirmEdit={onThreadAliasChange}
        inputProps={{ placeholder: title, defaultValue: threadDisplayName }}
        inputContainerClassName={classes.editInput}
        editIconClassName={classes.editIcon}
        disableEditing={!onThreadAliasChange}
      >
        <div className={classes.threadTitleContainer}>
          {alias && iconWithThreadTitleTooltip}
          <Spacer width={8} />
          <Typography
            data-cy="thread-title-header-thread-name"
            className={classNames(titleClassName, { [classes.title]: !titleClassName })}
          >
            {threadDisplayName}
          </Typography>
        </div>
      </InlineEditingTextField>
    </div>
  )
}

export default ThreadTitle
