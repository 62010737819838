import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import { ThreadPageContentStep } from './threadContentStep'

const useSharedThreadContentStepStyles = makeStyles((theme: Theme) => ({
  urlLabel: {
    color: theme.palette.primary.main,
  },
}))

interface ISharedThreadContentStepProps {
  isFullScreen: boolean
}

export const SharedThreadContentStep = ({ isFullScreen }: ISharedThreadContentStepProps) => {
  const classes = useSharedThreadContentStepStyles()

  const textLines = [
    <>
      A <strong>thread</strong> is a collection of messages exchanged between people in the same email chain.
    </>,
    <>
      The <span className={classes.urlLabel}>URL</span> for a thread never changes.
    </>,
    <>Messages within gray message bubbles were shared with you.</>,
    <>New messages are added automatically when available.</>,
  ]

  return <ThreadPageContentStep textLines={textLines} isFullScreen={isFullScreen} />
}
