import React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import MessageEditor from 'component/messageEditor'
import { RichTextActionsContextProvider } from 'component/messageEditor/useRichTextActionsContext'
import { scrollElementIntoView } from 'dataProcessors/utils'
import DraftAutoSaveStatusLabel from './draftAutoSaveStatusLabel'
import { useBoolean } from 'hooks/useBoolean'
import { DraftMessageContextProvider } from './useDraftMessageContext'
import classNames from 'classnames'
import { getDraftFullContent, getDraftSyncState } from 'domain/message'

const useDraftMessageStyles = makeStyles((theme: Theme) => ({
  draftMessageRoot: {
    minWidth: 0,
    width: '100%',
  },
  animate: {
    animation: 'blink 1s',
  },
  draftBackdrop: {
    backgroundColor: '#564c4c',
    height: '100vh',
    left: 0,
    opacity: 0.38,
    position: 'fixed',
    top: 0,
    width: '100vw',
    zIndex: theme.zIndex.drawer,
  },
  messageEditorContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 0,
  },
  userAvatar: {
    flexShrink: 0,
    marginLeft: '9px',
    marginRight: 24,
  },
  hideAvatar: {
    display: 'none',
  },
}))

export type TDraftType = 'reply' | 'reply_all' | 'forward' | 'first_message'

export interface IDraftMessageProps {
  draft: IDraftMessage
  displayId?: string
  repliedOrForwardedMessage?: IMessage
  initialToRecipients?: TDraftRecipient[]
  initialBccRecipients?: TDraftRecipient[]
  previousMessagesPreview?: string
  onDelete?(): void
  draftContainerRef: React.MutableRefObject<HTMLDivElement | null>
  isLoading?: boolean
}

const DraftMessage = ({
  draft,
  displayId = '',
  repliedOrForwardedMessage,
  onDelete,
  initialToRecipients = [],
  initialBccRecipients = [],
  draftContainerRef,
  isLoading,
}: IDraftMessageProps) => {
  const classes = useDraftMessageStyles()
  const [isInFocusMode, setIsInFocusMode] = React.useState<boolean>(false)
  const [isShowFocusAnimation, setIsShowFocusAnimation] = React.useState<boolean>(false)
  const { state: isCollapsed, toggle: toggleIsCollapsed } = useBoolean(false)
  const draftRef = React.createRef<HTMLDivElement>()

  const [hasErrorOnSaving, setHasErrorOnSaving] = React.useState<boolean>(false)

  React.useEffect(() => {
    scrollElementIntoView(draftRef.current)
    setIsShowFocusAnimation(!isInFocusMode)
  }, [isInFocusMode])

  const savingErrorMessage = hasErrorOnSaving ? "Sync error with email provider, we'll try again soon" : ''

  const draftFullContent = getDraftFullContent(draft)

  return (
    <>
      {isInFocusMode && <div className={classes.draftBackdrop} onClick={() => setIsInFocusMode(false)} />}
      <div
        ref={draftRef}
        id={draft.id}
        className={classNames(classes.draftMessageRoot, { [classes.animate]: isShowFocusAnimation && !isLoading })}
      >
        <RichTextActionsContextProvider
          initialContent={draftFullContent}
          shouldFocusOnRender={draft.draftType !== 'forward'}
        >
          <DraftMessageContextProvider>
            <div className={classes.messageEditorContainer}>
              <MessageEditor
                draftIndex={draft.id}
                draftType={draft.draftType}
                draftId={draft.id}
                repliedOrForwardedMessage={repliedOrForwardedMessage}
                previousMessageContent={draft.previousMessageContent}
                initialIsPreviousMessageContentEdited={draft.isPreviousMessageContentEdited}
                onDeleteMessage={onDelete}
                threadId={draft.threadId}
                initialToRecipients={initialToRecipients}
                initialBccRecipients={initialBccRecipients}
                initialAttachments={draft.attachments}
                isInFocusMode={isInFocusMode}
                setIsInFocusMode={setIsInFocusMode}
                setHasErrorOnSavingDraft={setHasErrorOnSaving}
                draftSyncState={getDraftSyncState(draft)}
                topBlockComponent={
                  <DraftAutoSaveStatusLabel
                    displayId={displayId}
                    showCollapseButton={!isInFocusMode}
                    isCollapsed={isCollapsed}
                    onToggleCollapse={toggleIsCollapsed}
                    errorMessage={savingErrorMessage}
                    isLoading={isLoading}
                  />
                }
                isCollapsed={isCollapsed}
                containerRef={draftContainerRef}
                isLoading={isLoading}
              />
            </div>
          </DraftMessageContextProvider>
        </RichTextActionsContextProvider>
      </div>
    </>
  )
}

export default DraftMessage
