import * as React from 'react'
import { MOCK_MESSAGES } from 'tests/stubs/messageApi'
import { ThreadMessageBlockWithAvatar } from 'component/message/threadMessage/threadMessageBlockWithAvatar'

export const ThreadMessageSkeleton = ({ isOwnMessage, currentUser }: { isOwnMessage: boolean; currentUser: IUser }) => {
  const fakeMessage = { ...MOCK_MESSAGES[0] }
  fakeMessage.content = Array(2).fill('random big line of text to simulate long content on message\n').join('')
  if (isOwnMessage) {
    fakeMessage.sender = {
      ...fakeMessage.sender,
      id: currentUser.id,
    }
  }
  return (
    <ThreadMessageBlockWithAvatar
      message={fakeMessage}
      currentUser={currentUser}
      threadId={fakeMessage.threadId}
      isLoading={true}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onNewDraft={() => {}}
    />
  )
}
