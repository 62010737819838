import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface ICleanIconProps extends JSX.IntrinsicAttributes {
  className?: string
}

const CleanIcon = ({ className }: ICleanIconProps) => {
  return (
    <SvgIcon className={className} viewBox="0 -2 20 20">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#606060"
          d="M19.683 12.812c-.355-.355-.93-.355-1.285 0l-1.176 1.175-1.175-1.175c-.355-.355-.93-.355-1.286 0-.355.355-.355.93 0 1.285l1.176 1.176-1.176 1.175c-.355.355-.355.93 0 1.285.355.356.93.356 1.286 0l1.175-1.175 1.176 1.175c.355.356.93.356 1.285 0 .355-.355.355-.93 0-1.285l-1.175-1.175 1.175-1.176c.355-.355.355-.93 0-1.285z"
        />
        <path
          fill="#606060"
          d="M3.867 13.382H2.529L7.2 1.5c.262-.667.687-.666.948 0l4.671 11.882h-1.337l-.901-2.29H4.767l-.9 2.29zm1.415-3.6h4.784L7.674 3.698 5.282 9.783zM1.5 15.346c0-.361.276-.654.617-.654h11.114c.341 0 .618.29.618.654 0 .362-.276.655-.618.655H2.117c-.34 0-.617-.29-.617-.655z"
        />
      </g>
    </SvgIcon>
  )
}

export default CleanIcon
