import React from 'react'
import IAttachment from 'model/attachment'
import { useSendAttachmentAnalyticsEventUseCase } from 'usecases/analytics/attachment'

type TAttachmentDisplayOption = 'rightSidebar' | 'modal'

interface IDisplayAttachmentProps {
  attachment: IAttachment
  threadId: string
}

interface IDisplayAttachmentContext {
  attachment?: IAttachment
  displayAttachmentOnRightSideBar({ attachment, threadId }: IDisplayAttachmentProps): void
  displayAttachmentOnModal({ attachment, threadId }: IDisplayAttachmentProps): void
  closeAttachment(): void
  isAttachmentModalOpen: boolean
  isAttachmentSidebarOpen: boolean
}

export const DisplayAttachmentContext = React.createContext<IDisplayAttachmentContext | undefined>(undefined)

export const DisplayAttachmentContextProvider: React.FC<{}> = ({ children }) => {
  const [currentDisplayOption, setCurrentDisplayOption] = React.useState<TAttachmentDisplayOption>()
  const [attachment, setAttachment] = React.useState<IAttachment>()

  const { sendAttachmentAnalyticsEventUseCase } = useSendAttachmentAnalyticsEventUseCase()

  const displayAttachment = ({
    attachment,
    threadId,
    displayOption,
  }: IDisplayAttachmentProps & { displayOption: TAttachmentDisplayOption }) => {
    setAttachment(attachment)
    setCurrentDisplayOption(displayOption)

    sendAttachmentAnalyticsEventUseCase({ action: 'opened', attachmentId: attachment.id, threadId })
  }

  const closeAttachment = () => {
    setCurrentDisplayOption(undefined)
    setAttachment(undefined)
  }

  const displayAttachmentOnRightSideBar = ({ attachment, threadId }: IDisplayAttachmentProps) =>
    displayAttachment({ attachment, threadId, displayOption: 'rightSidebar' })
  const displayAttachmentOnModal = ({ attachment, threadId }: IDisplayAttachmentProps) =>
    displayAttachment({ attachment, threadId, displayOption: 'modal' })

  return (
    <DisplayAttachmentContext.Provider
      value={{
        attachment,
        isAttachmentModalOpen: currentDisplayOption === 'modal',
        isAttachmentSidebarOpen: currentDisplayOption === 'rightSidebar',
        displayAttachmentOnRightSideBar,
        displayAttachmentOnModal,
        closeAttachment,
      }}
    >
      {children}
    </DisplayAttachmentContext.Provider>
  )
}

export const useDisplayAttachmentContext = () => {
  const context = React.useContext(DisplayAttachmentContext)

  if (context === undefined)
    throw new Error('useAttachmentDisplayContext should be used within a AttachmentDisplayContextProvider ')

  return context
}
