import React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles, createStyles } from '@material-ui/styles'
import WrapIntoSkeletonLoading from 'component/wrapIntoSkeletonLoading'
import classnames from 'classnames'
import { Typography } from '@material-ui/core'
import { getUserDisplayName } from 'dataProcessors/userDisplayName'
import { ArrowDropDown } from '@material-ui/icons'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import Spacer from 'component/layoutUtils/spacer'
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined'
import FilterButton from 'component/buttons/filterButton'
import RecipientsListPopover from 'component/message/messageComponents/recipientsListPopover'

const useMessageRecipientsBlockStyles = makeStyles<Theme, { fromRecipientBlockColor?: string }>((theme: Theme) =>
  createStyles({
    recipientsBlock: {
      alignItems: 'center',
      border: `solid 2px ${theme.threadPalette.lightishGray}`,
      borderTopRightRadius: 6,
      color: theme.threadPalette.white,
      cursor: 'pointer',
      display: 'inline-flex',
      maxWidth: '100%',
      minHeight: 20,
      paddingLeft: 10,
    },
    fromRecipientBlock: {
      backgroundColor: ({ fromRecipientBlockColor }) => fromRecipientBlockColor ?? theme.threadPalette.darkBlue,
      borderColor: ({ fromRecipientBlockColor }) => fromRecipientBlockColor ?? theme.threadPalette.darkBlue,
      borderTopLeftRadius: 6,
      color: theme.threadPalette.white,
      justifyContent: 'flex-start',
      marginBottom: -2,
      minWidth: 136,
      paddingRight: 20,
      position: 'relative',
      width: 'fit-content',
    },
    fromRecipientBlockWithError: {
      backgroundColor: ({ fromRecipientBlockColor }) => fromRecipientBlockColor ?? theme.threadPalette.salmonPink,
      borderColor: ({ fromRecipientBlockColor }) => fromRecipientBlockColor ?? theme.threadPalette.vermilion,
      borderWidth: '0.5px',
    },
    fromRecipientBlockViewAccessMessage: {
      backgroundColor: ({ fromRecipientBlockColor }) => fromRecipientBlockColor ?? theme.threadPalette.lighterGray,
      borderColor: ({ fromRecipientBlockColor }) => fromRecipientBlockColor ?? theme.threadPalette.lightishGray,
      color: theme.threadPalette.brownishGray,
    },
    messageSenderWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    toRecipientsBlock: {
      backgroundColor: theme.threadPalette.lighterGray,
      borderBottom: 'none',
      color: theme.threadPalette.brownishGray,
      justifyContent: 'space-between',
      width: '100%',
    },
    toRecipientsBlockWithError: {
      backgroundColor: theme.threadPalette.palePink,
      borderColor: theme.threadPalette.vermilion,
      borderWidth: '0.5px',
    },
    toRecipientsBlockViewAccessMessage: {
      backgroundColor: theme.threadPalette.lighterGray,
      borderColor: theme.threadPalette.lightishGray,
      color: theme.threadPalette.brownishGray,
    },
    recipientNameLabel: {
      fontSize: 10,
      fontWeight: 'bold',
      marginRight: 7,
    },
    recipientName: {
      fontSize: 10,
      whiteSpace: 'pre',
    },
    seeRecipientsIcon: {
      fontSize: 20,
      marginLeft: 2,
    },
    importError: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      height: '16px',
      marginTop: '2px',
    },
    importErrorText: {
      fontSize: '12px',
      color: theme.threadPalette.vermilion,
    },
    importErrorIcon: {
      color: theme.threadPalette.vermilion,
      height: '14px',
      width: '14px',
    },
    recipientsWrapper: {
      alignItems: 'center',
      display: 'inline-flex',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '& :last-child': {
        marginRight: 0,
      },
      '& .MuiSvgIcon-root': {
        color: theme.threadPalette.lightBlue,
      },
    },
    recipientsWrapperViewAccessMessage: {
      '& .MuiSvgIcon-root': {
        color: theme.threadPalette.brownishGray,
      },
    },
    recipientNameSelected: {
      color: theme.palette.primary.main,
    },
    recipientsFilterButton: {
      borderRadius: 0,
      borderTopRightRadius: 6,
      margin: -2,
      marginLeft: 24,
      padding: '4px 5px',
      width: 32,
    },
  })
)

interface IMessageRecipientsFilter {
  onFilter: () => void
  isActive: boolean
}

interface IMessageRecipientsBlockProps {
  isLoading?: boolean
  isViewAccess?: boolean
  message: IMessage
  currentUser: IUser
  isMessageWithError?: boolean
  recipientsFilter?: IMessageRecipientsFilter
  messageStatusComponent?: JSX.Element
  fromRecipientBlockColor?: string
}

export const MessageRecipientsBlock = ({
  isLoading,
  isViewAccess,
  currentUser,
  message,
  isMessageWithError,
  recipientsFilter,
  messageStatusComponent,
  fromRecipientBlockColor,
}: IMessageRecipientsBlockProps) => {
  const classes = useMessageRecipientsBlockStyles({ fromRecipientBlockColor })

  const messageRecipients = [...(message.recipients ?? []), ...(message.bccRecipients ?? [])]

  const {
    isOpen: isRecipientsListOpen,
    handleClickToOpenDialog: openRecipientsListDialog,
    handleCloseDialog: closeRecipientsListDialog,
    dialogAnchorElement: recipientsBlockAnchorElement,
  } = useDialog()

  const getRecipientsPresentationNames = (recipients?: TPerson[]) => {
    if (!recipients) return ''

    const recipientsNames = recipients.map(recipient =>
      recipient.email === currentUser?.email ? 'You' : getUserDisplayName(recipient)
    )
    return recipientsNames.join('  ')
  }

  const onFilterClick = (event: React.MouseEvent) => {
    // prevent showing recipients dialog
    event.stopPropagation()
    recipientsFilter?.onFilter()
  }

  const fromRecipientBlockClassName = classnames(classes.fromRecipientBlock, {
    [classes.fromRecipientBlockWithError]: isMessageWithError,
    [classes.fromRecipientBlockViewAccessMessage]: isViewAccess,
  })
  const toRecipientsBlockClassName = classnames(classes.toRecipientsBlock, {
    [classes.toRecipientsBlockWithError]: isMessageWithError,
    [classes.toRecipientsBlockViewAccessMessage]: isViewAccess,
  })
  const toRecipientsListClassName = classnames(classes.recipientName, {
    [classes.recipientNameSelected]: recipientsFilter?.isActive,
  })

  const recipientsWrapperClassName = classnames(classes.recipientsWrapper, {
    [classes.recipientsWrapperViewAccessMessage]: isViewAccess,
  })

  return (
    <div>
      <WrapIntoSkeletonLoading
        isLoading={isLoading}
        variant="rect"
        className={classnames(classes.recipientsBlock, classes.fromRecipientBlock)}
        animation="wave"
        width={'35%'}
      >
        <div className={classes.messageSenderWrapper}>
          <div
            onClick={openRecipientsListDialog}
            className={classnames(classes.recipientsBlock, fromRecipientBlockClassName)}
          >
            <Typography className={classes.recipientNameLabel}>From:</Typography>
            <Typography className={classes.recipientName} noWrap>
              {getRecipientsPresentationNames([message.sender])}
            </Typography>
            <ArrowDropDown className={classes.seeRecipientsIcon} />
          </div>
          {isMessageWithError && (
            <div className={classes.importError}>
              <Spacer width={8} />
              <ErrorIcon className={classes.importErrorIcon} />
              <Spacer width={4} />
              <Typography className={classes.importErrorText}>Import error</Typography>
            </div>
          )}
          {!isMessageWithError && messageStatusComponent}
        </div>
      </WrapIntoSkeletonLoading>
      <WrapIntoSkeletonLoading
        isLoading={isLoading}
        variant="rect"
        className={classnames(classes.recipientsBlock, classes.toRecipientsBlock)}
        animation="wave"
        width={'100%'}
      >
        <div
          onClick={openRecipientsListDialog}
          className={classnames(classes.recipientsBlock, toRecipientsBlockClassName)}
        >
          <div className={recipientsWrapperClassName}>
            <Typography classes={{ root: classes.recipientNameLabel }}>To:</Typography>
            <Typography className={toRecipientsListClassName} noWrap>
              {getRecipientsPresentationNames(messageRecipients)}
            </Typography>
            <ArrowDropDown className={classes.seeRecipientsIcon} />
          </div>
          {recipientsFilter && (
            <FilterButton
              isSelected={recipientsFilter.isActive}
              onClick={onFilterClick}
              classes={{ button: classes.recipientsFilterButton }}
            />
          )}
        </div>
      </WrapIntoSkeletonLoading>
      <RecipientsListPopover
        isOpen={isRecipientsListOpen}
        anchorEl={recipientsBlockAnchorElement}
        onClose={closeRecipientsListDialog}
        sender={message.sender}
        recipients={message.recipients}
        bccRecipients={message.bccRecipients}
      />
    </div>
  )
}
