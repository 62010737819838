import { Theme, makeStyles } from '@material-ui/core'
import { SharingGroupScreenHeader } from 'component/sharingGroup/common/sharingGroupScreenHeader'
import React from 'react'
import { SelectOrCreateSharingGroupInput } from '../connectSharingGroupScreen/selectOrCreateSharingGroupInput'
import { useThreadSharingSettings } from 'hooks/useSharingGroup/useThreadSharingSettings'
import { getThreadSharingSettingsThatShareMessage } from 'domain/sharingGroup'
import { ISharingGroup, IThreadSharingSettings } from 'model/sharingGroup'
import { getThreadDisplayName } from 'domain/thread'

const useIndividualMessageSharingScreenStyles = makeStyles((theme: Theme) => ({
  individualMessageSharingScreenRoot: {
    paddingInline: 20,
    paddingBottom: 20,
  },
}))

interface IIndividualMessageSharingScreenProps {
  message: IMessage
  thread: IThread
  onShareMessageToSharingGroup({
    sharingGroup,
    threadSharingSettings,
    sharedMessagesIds,
  }: {
    sharingGroup: ISharingGroup
    threadSharingSettings?: IThreadSharingSettings
    sharedMessagesIds?: string[]
  }): void
  onCreateNewSharingGroupByName({
    name,
    initialSharedMessagesIds,
  }: {
    name: string
    initialSharedMessagesIds?: string[]
  }): void
  onEditExistingSharingGroup(sharingGroup: ISharingGroup): void
}

export const IndividualMessageSharingScreen = ({
  message,
  thread,
  onShareMessageToSharingGroup,
  onCreateNewSharingGroupByName,
  onEditExistingSharingGroup,
}: IIndividualMessageSharingScreenProps) => {
  const classes = useIndividualMessageSharingScreenStyles()

  const { threadSharingSettingsList } = useThreadSharingSettings(thread.id)

  const threadSharingSettingsThatShareMessage = getThreadSharingSettingsThatShareMessage(
    message,
    threadSharingSettingsList
  )
  const sharingGroupsThatShareMessage = threadSharingSettingsThatShareMessage.map(
    threadSharingSettings => threadSharingSettings.sharingGroup
  )

  const title = `Share message from "${getThreadDisplayName(thread)}"`

  const onSelectSharingGroup = (sharingGroup: ISharingGroup) => {
    const existingThreadSharingSettings = threadSharingSettingsList.find(
      threadSharingSettings => threadSharingSettings.sharingGroup.id === sharingGroup.id
    )
    const existingSharedMessagesIds = existingThreadSharingSettings
      ? [...existingThreadSharingSettings.sharedMessagesIds]
      : []
    onShareMessageToSharingGroup({
      sharingGroup,
      threadSharingSettings: existingThreadSharingSettings,
      sharedMessagesIds: [...existingSharedMessagesIds, message.id],
    })
  }

  const onCreateNewSharingGroup = (sharingGroupName: string) =>
    onCreateNewSharingGroupByName({ name: sharingGroupName, initialSharedMessagesIds: [message.id] })

  return (
    <div className={classes.individualMessageSharingScreenRoot}>
      <SharingGroupScreenHeader text={title} />
      <SelectOrCreateSharingGroupInput
        autoFocus={true}
        sharingGroupsToRemoveFromOptions={sharingGroupsThatShareMessage}
        onSelectSharingGroup={onSelectSharingGroup}
        onCreateNewSharingGroup={onCreateNewSharingGroup}
        onEditSharingGroup={onEditExistingSharingGroup}
      />
    </div>
  )
}
