/* eslint-disable @typescript-eslint/no-explicit-any */
import { refreshTokenIfNeeded } from '@jetkit/react'

export class WSEvent {
  message: any

  constructor(msg: any) {
    this.message = msg
  }
}

export type WSEventHandler = (ev: WSEvent) => void

export default class WSClient {
  ws: WebSocket | undefined
  public isConnected = false
  reconnectTime = 1 // time in seconds before reconnect

  protected callbacks: Map<WSEventHandler, boolean> = new Map()

  public open = async () => {
    if (this.ws) {
      if (this.ws.readyState === WebSocket.OPEN) {
        return
      }

      if (this.ws.readyState === WebSocket.CONNECTING) {
        console.debug('ws is still connecting...')
        return
      }

      this.ws.close()
    }

    if (!process.env.REACT_APP_WS_URL) throw new Error('REACT_APP_WS_URL missing')
    const host = new URL(process.env.REACT_APP_WS_URL)

    // make sure auth token is fresh
    const accessToken = await refreshTokenIfNeeded() // from axios-jwt
    // add auth token
    if (accessToken) host.searchParams.set('token', accessToken)

    // create new websocket client
    if (!this.ws) {
      this.ws = new WebSocket(String(host))
      this.ws.onopen = this.handleOpen
      this.ws.onclose = this.handleClose
      this.ws.onmessage = this.handleMessage
    }
  }

  public send = (event: Record<string, unknown>) => {
    if (this.ws && this.isConnected) {
      this.ws.send(JSON.stringify(event))
    }
  }

  public close = () => {
    if (this.ws) this.ws.close()
  }

  public reconnect() {
    if (this.ws) this.ws.close()
    this.open()
  }

  protected handleOpen = (ev: Event) => {
    this.isConnected = true
    this.reconnectTime = 1 // reset reconnect timer

    const ws = this.ws
    if (!ws) return

    console.debug('ws connected')
  }

  protected handleClose = (ev: Event) => {
    console.debug('handling ws close...')
    this.isConnected = false
    this.ws = undefined // clear ws reference

    // do reconnect
    setTimeout(() => {
      this.reconnectTime *= 2 // exponential backoff

      this.open()
    }, this.reconnectTime * 1000)

    // reconnect?
    this.open()
  }

  protected handleMessage = (ev: MessageEvent) => {
    // handle message received on WS
    const data = ev.data
    if (!data) return

    // try to parse as JSON
    const msg = JSON.parse(data)

    // create new websocket event and dispatch it to listeners
    const msgEvt = new WSEvent(msg)
    this.dispatchEvent(msgEvt)
  }

  public addCallback(cb: WSEventHandler) {
    this.callbacks.set(cb, true)
  }

  public removeCallback(cb: WSEventHandler) {
    this.callbacks.delete(cb)
  }

  public dispatchEvent(event: WSEvent) {
    for (const cb of this.callbacks.keys()) {
      cb(event)
    }
  }
}
