import React from 'react'
import { Button, Tooltip, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { useBoolean } from 'hooks/useBoolean'
import VisibilityIcon from '@material-ui/icons/Visibility'
import classnames from 'classnames'

const useThreadFollowBadgeStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'row',
      height: '20px',
      justifyContent: 'space-around',
      padding: '3px 5px',
      width: '66px',
    },
    buttonInFollowingState: {
      border: `solid 0.5px ${theme.threadPalette.lightSoftGray}`,
      '&:hover': {
        backgroundColor: '#ffc2c2',
        border: `solid 0.5px ${theme.threadPalette.vermilion}`,
      },
    },
    buttonInUnfollowedState: {
      backgroundColor: theme.threadPalette.lightishGray,
      '&:hover': {
        backgroundColor: theme.threadPalette.green,
      },
    },
    buttonInViewedState: {
      backgroundColor: theme.threadPalette.lightBlue,
      '&:hover': {
        backgroundColor: theme.threadPalette.green,
      },
    },
    buttonText: {
      fontSize: '12px',
    },
    buttonTextFollowing: {
      color: '#6b6b6b',
    },
    buttonTextInViewedState: {
      color: theme.threadPalette.white,
    },
    buttonTextUnfollow: {
      color: theme.threadPalette.vermilion,
    },
    buttonTextUnfollowed: {
      color: theme.threadPalette.lightGray,
    },
    buttonTextFollow: {
      color: theme.threadPalette.white,
    },
    iconWeapper: {
      alignItems: 'center',
      display: 'flex',
    },
    iconCommon: {
      height: '14px',
      width: '14px',
    },
    iconUnfollowed: {
      color: theme.threadPalette.lightGray,
    },
    iconFollow: {
      color: theme.threadPalette.white,
    },
    tooltip: {
      backgroundColor: '#000000',
      borderRadius: '8px',
      color: theme.threadPalette.white,
      fontSize: '10px',
      lineHeight: 1.5,
      padding: '6px',
      width: '220px',
    },
  })
)

interface IThreadFollowBadge {
  id?: string
  stateForUser: TThreadStateForUser
  onClick: () => void
}

export const ThreadFollowBadge = ({ id, stateForUser, onClick }: IThreadFollowBadge) => {
  const classes = useThreadFollowBadgeStyles()

  const { state: isHovering, setTrue: setStartedHovering, setFalse: setStoppedHovering } = useBoolean(false)

  const isViewed = stateForUser === 'has_view_access' || stateForUser === 'viewed'
  const isFollowing = stateForUser === 'following'
  const isUnfollowed = stateForUser === 'unfollowed'

  const iconClassName = classnames(classes.iconCommon, {
    [classes.iconUnfollowed]: !isHovering,
    [classes.iconFollow]: isHovering,
  })

  const buttonText = React.useMemo(() => {
    if (isViewed && !isHovering) return 'Viewed'
    if (isViewed && isHovering) return 'Follow'
    if (isFollowing && !isHovering) return 'Following'
    if (isFollowing && isHovering) return 'Unfollow'
    if (isUnfollowed) return 'Follow'
  }, [isHovering, isViewed, isFollowing, isUnfollowed])

  const buttonIcon = React.useMemo(() => {
    if (isViewed && isHovering) return <VisibilityIcon classes={{ root: iconClassName }} />
    if (isUnfollowed) return <VisibilityIcon classes={{ root: iconClassName }} />
  }, [isUnfollowed, isViewed, isHovering])

  const buttonTextStyle = classnames(classes.buttonText, {
    [classes.buttonTextInViewedState]: isViewed && !isHovering,
    [classes.buttonTextFollow]: (isUnfollowed || isViewed) && isHovering,
    [classes.buttonTextFollowing]: isFollowing && !isHovering,
    [classes.buttonTextUnfollow]: isFollowing && isHovering,
    [classes.buttonTextUnfollowed]: isUnfollowed && !isHovering,
  })

  const buttonStyle = classnames(classes.buttonRoot, {
    [classes.buttonInViewedState]: isViewed,
    [classes.buttonInFollowingState]: isFollowing,
    [classes.buttonInUnfollowedState]: isUnfollowed,
  })

  const tooltipText = React.useMemo(() => {
    if (isFollowing) return 'Unfollowing a thread stops message updates and removes it from your following list.'
    return ''
  }, [isFollowing])

  return (
    <Tooltip title={tooltipText} placement="bottom-start" classes={{ tooltip: classes.tooltip }}>
      <Button
        id={id}
        classes={{ root: buttonStyle }}
        onMouseEnter={setStartedHovering}
        onMouseLeave={setStoppedHovering}
        onClick={onClick}
      >
        {buttonIcon && <div className={classes.iconWeapper}>{buttonIcon}</div>}
        <Typography className={buttonTextStyle}>{buttonText}</Typography>
      </Button>
    </Tooltip>
  )
}
