import React from 'react'
import { SvgIcon, Theme, Tooltip, Typography, makeStyles, useTheme } from '@material-ui/core'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import {
  getThreadSharingSettingsForUserIsSharingGroupMember,
  getThreadSharingSettingsTheUserIsSharingGroupOwner,
} from 'domain/sharingGroup'
import { IThreadSharingSettings } from 'model/sharingGroup'
import GroupIcon from '@material-ui/icons/Group'
import { MessageSharingGroupsListPopover } from 'component/messageSharingGroupsListPopover/messageSharingGroupsListPopover'
import classNames from 'classnames'
import GroupOffIcon from 'icons/groupOff'
import { useSharingGroupContext } from 'contexts/sharingGroupContext'

const useMessageShareActionsStyles = makeStyles((theme: Theme) => ({
  iconRoot: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    marginLeft: 6,
  },
  shareIcon: {
    height: 16,
    width: 16,
  },
  viewHeldIcon: {
    height: 14,
    width: 14,
  },
  iconWithLabelContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    height: 16,
  },
  iconLabel: {
    fontSize: 10,
  },
}))

interface IMessageShareActionsProps {
  threadSharingSettingsThatShareMessage: IThreadSharingSettings[]
  threadSharingSettingsThatHoldMessage: IThreadSharingSettings[]
  messageAudience: TPerson[]
  currentUser: IUser
  message: IMessage
  isLoadingThreadSharingSettings: boolean
}

type TShareIcon = 'viewShare' | 'viewHeld' | 'shareThisMessage'

interface IShareIconOptions {
  shouldRender: boolean
  component: typeof SvgIcon
  labelText: string
  onClick?(event: React.MouseEvent): void
  color: string
  idleColor?: string
  iconClassName?: string
}

export const MessageShareActions = ({
  threadSharingSettingsThatShareMessage,
  threadSharingSettingsThatHoldMessage,
  messageAudience,
  currentUser,
  message,
  isLoadingThreadSharingSettings,
}: IMessageShareActionsProps) => {
  const classes = useMessageShareActionsStyles()
  const theme = useTheme()

  const [hoveredIcon, setHoveredIcon] = React.useState<TShareIcon>()

  const {
    isOpen: isSharingGroupsListOpen,
    handleClickToOpenDialog: openSharingGroupsListDialog,
    handleCloseDialog: closeSharingGroupsListDialog,
    dialogAnchorElement: groupIconAnchorElement,
  } = useDialog()

  const { openThreadHeldMessages, openIndividualMessageSharing } = useSharingGroupContext()

  const isMessageSharedInSharingGroups = threadSharingSettingsThatShareMessage.length > 0
  const isCurrentUserMessageDirectRecipient =
    messageAudience.find(recipient => recipient.email === currentUser.email) !== undefined

  const threadSharingSettingsTheUserIsGroupOwner = getThreadSharingSettingsTheUserIsSharingGroupOwner(
    currentUser.id,
    threadSharingSettingsThatShareMessage
  )
  const threadSharingSettingsTheUserIsAGroupMember = getThreadSharingSettingsForUserIsSharingGroupMember(
    currentUser.id,
    threadSharingSettingsThatShareMessage
  )

  const threadSharingSettingsToDisplay = isCurrentUserMessageDirectRecipient
    ? threadSharingSettingsTheUserIsGroupOwner
    : threadSharingSettingsTheUserIsAGroupMember

  const sharingGroupsToDisplay = threadSharingSettingsToDisplay.map(sharingGroup => sharingGroup.sharingGroup)

  const isUserSharingCurrentMessage = threadSharingSettingsTheUserIsGroupOwner.length > 0

  const showViewShareIcon =
    (isCurrentUserMessageDirectRecipient && isUserSharingCurrentMessage) ||
    (!isCurrentUserMessageDirectRecipient && isMessageSharedInSharingGroups)

  const showViewHeldIcon = threadSharingSettingsThatHoldMessage.length > 0
  const showShareThisMessageIcon = !showViewShareIcon && !showViewHeldIcon && !isLoadingThreadSharingSettings

  const shareIcons: Record<TShareIcon, IShareIconOptions> = {
    viewShare: {
      shouldRender: showViewShareIcon,
      component: GroupIcon,
      labelText: 'View share',
      onClick: openSharingGroupsListDialog,
      color: theme.palette.primary.main,
    },
    viewHeld: {
      shouldRender: showViewHeldIcon,
      component: GroupOffIcon,
      labelText: 'Review held messages',
      color: theme.threadPalette.brightOrange,
      iconClassName: classes.viewHeldIcon,
      onClick: () => openThreadHeldMessages(message),
    },
    shareThisMessage: {
      shouldRender: showShareThisMessageIcon,
      component: GroupIcon,
      labelText: 'Share this message',
      color: theme.palette.primary.main,
      idleColor: theme.threadPalette.lightGray,
      onClick: () => openIndividualMessageSharing(message),
    },
  }

  return (
    <React.Fragment>
      {Object.entries(shareIcons).map(([iconName, iconOptions]) => {
        const {
          shouldRender,
          component: IconComponent,
          labelText,
          onClick,
          color,
          iconClassName,
          idleColor,
        } = iconOptions

        if (!shouldRender) return null

        const isCurrentIconHovered = hoveredIcon === iconName

        const iconLabelText = isCurrentIconHovered ? labelText : ''
        const onMouseEnter = () => setHoveredIcon(iconName as TShareIcon)
        const onMouseLeave = () => setHoveredIcon(undefined)
        const tooltipText = onClick ? '' : 'Coming soon! This feature is currently under development'
        const iconRootClassName = classNames(classes.shareIcon, iconClassName)
        const currentColor = idleColor && !isCurrentIconHovered ? idleColor : color

        return (
          <div
            key={iconName}
            className={classes.iconRoot}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
          >
            <Tooltip title={tooltipText}>
              <span className={classes.iconWithLabelContainer}>
                <IconComponent className={iconRootClassName} style={{ fill: currentColor }} />
                {iconLabelText && (
                  <Typography className={classes.iconLabel} style={{ color: currentColor }}>
                    {iconLabelText}
                  </Typography>
                )}
              </span>
            </Tooltip>
          </div>
        )
      })}
      <MessageSharingGroupsListPopover
        isOpen={isSharingGroupsListOpen}
        anchorEl={groupIconAnchorElement}
        onClose={closeSharingGroupsListDialog}
        sharingGroups={sharingGroupsToDisplay}
        currentUser={currentUser}
        message={message}
      />
    </React.Fragment>
  )
}
