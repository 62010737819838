import React from 'react'
import { Button, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'

const useFilterButtonStyles = makeStyles((theme: Theme) => {
  return {
    buttonRoot: {
      alignItems: 'center',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'row',
      height: '22px',
      justifyContent: 'center',
      minWidth: '22px',
    },
    filterButtonActive: {
      backgroundColor: theme.threadPalette.slateGrey,
      color: theme.threadPalette.white,
      '&:hover': {
        backgroundColor: theme.threadPalette.slateGrey,
      },
    },
    filterButtonInactive: {
      backgroundColor: theme.threadPalette.darkerBlue,
      border: `solid 0.3px ${theme.threadPalette.lightSoftGray}`,
      color: theme.threadPalette.lightSoftGray,
      '&:hover': {
        backgroundColor: theme.threadPalette.darkerBlue,
      },
    },
  }
})

interface IFilterButtonCommonProps {
  children: React.FC<any>
  isActive: boolean
  onClick: () => void
  onActiveClassName?: string
  onInactiveClassName?: string
  rootClassName?: string
}

export const FilterButton = ({
  children: ChildrenComponent,
  isActive,
  onClick,
  onActiveClassName: activeClassName,
  onInactiveClassName: inactiveClassName,
  rootClassName,
}: IFilterButtonCommonProps) => {
  const classes = useFilterButtonStyles()

  const buttonRootClassName = classnames(classes.buttonRoot, rootClassName, {
    [classes.filterButtonActive]: isActive,
    [classes.filterButtonInactive]: !isActive,
  })

  return (
    <Button classes={{ root: buttonRootClassName }} onClick={onClick}>
      <ChildrenComponent className={isActive ? activeClassName : inactiveClassName} />
    </Button>
  )
}
