import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import { OnboardingStepWithImage } from '../onboardingStepWithImage'
import { FollowBadgeIconWithText } from 'icons/onboarding/followBadgeIconWithText'
import { EmailDigestIcon } from 'icons/onboarding/emailDigestIcon'
import { InlineEmoji } from 'shared/components/inlineEmoji'

const useEmailDigestStepStyles = makeStyles((theme: Theme) => ({
  followBadgeIconWithText: {
    width: 450,
  },
  emailDigestIcon: {
    height: 360,
  },
  combinedImageRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 50,
  },
}))

export const EmailDigestStep = () => {
  const classes = useEmailDigestStepStyles()

  const combinedImage = (
    <div className={classes.combinedImageRoot}>
      <FollowBadgeIconWithText className={classes.followBadgeIconWithText} />
      <EmailDigestIcon className={classes.emailDigestIcon} />
    </div>
  )

  const headerText = (
    <>
      <InlineEmoji emoji="📣" ariaLabel="mega" /> Let them know this!
    </>
  )

  return <OnboardingStepWithImage headerText={headerText} image={combinedImage} />
}
