import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import { push } from 'actions/navigation'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import * as AuthActions from '../actions/auth'
import LeftSidebar from 'component/leftSidebar'
import classnames from 'classnames'
import { AttachmentRightSidebarPanel } from 'component/attachmentDisplay/attachmentRightSidebarPanel'
import ThreadSnackbar from 'component/threadSnackbar'
import { useTrackComponentDimensions } from 'hooks/useTrackComponentDimensions'
import { NewThreadModalProvider } from 'component/newThreadModal/newThreadModal'
import { useUser } from 'hooks'
import { SyncingInboxSnackbar } from 'component/syncingInboxSnackbar/syncingInboxSnackbar'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { useDisplayAttachmentContext } from 'contexts/displayAttachmentContext'

const VARIANT_FILE_PREVIEW_CLASSNAME = 'File__preview'
const VARIANT_FILE_PREVIEW_SELECTOR = `&.${VARIANT_FILE_PREVIEW_CLASSNAME}`

interface PageLayoutStylesProps {
  appBarHeight?: number
  leftSidebarWidth: number
}

const useStyles = makeStyles<Theme, PageLayoutStylesProps>((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      height: ({ appBarHeight }) => `calc(100% - ${appBarHeight}px`,
      width: '100vw',
    },
    sidebar: {
      overflow: 'auto',
      [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: ({ leftSidebarWidth }) => `${leftSidebarWidth}px`,
      },
      [VARIANT_FILE_PREVIEW_SELECTOR]: {
        transition: 'width 0.3s ease-out',
        width: 0,
      },
    },
    appBar: {
      background: theme.threadPalette.white,
      color: theme.threadPalette.menuItem.current,
      boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.2)',
      transition: 'all 0.3s ease-in',
      maxWidth: '100vw',
      minHeight: theme.threadMixins.toolbarHeight.mobile,
      width: '100%',
      marginLeft: 0,
      [theme.breakpoints.up('md')]: {
        boxShadow: 'none',
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: ({ leftSidebarWidth }) => `${leftSidebarWidth}px`,
        width: ({ leftSidebarWidth }) => `calc(100% - ${leftSidebarWidth}px)`,
      },
      [VARIANT_FILE_PREVIEW_SELECTOR]: {
        boxShadow: 'none',
        marginLeft: 0,
        marginRight: '60%',
        width: '40%',
      },
    },

    menuButton: {
      [VARIANT_FILE_PREVIEW_SELECTOR]: {
        display: 'none',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
      padding: '10px',
    },
    menuIcon: {
      color: theme.threadPalette.menuItem.current,
    },
    sidebarPaper: {
      overflowY: 'auto',
      overflowX: 'hidden',
      background: theme.threadPalette.darkerBlue,
      borderRight: '0px none',
      width: ({ leftSidebarWidth }) => `${leftSidebarWidth}px`,
      transition: 'width 0.3s ease-in',
      [VARIANT_FILE_PREVIEW_SELECTOR]: {
        transition: 'width 0.4s ease-out',
        width: 0,
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    content: {
      flex: 1,
      display: 'flex',
      minWidth: 0,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
      [VARIANT_FILE_PREVIEW_SELECTOR]: {
        marginLeft: 0,
        transition: 'width 0.4s ease-out',
        width: '100%',
      },
    },
    previewContainerHidden: {
      width: 0,
      backgroundColor: theme.threadPalette.menuItem.current,
      marginTop: theme.threadMixins.toolbarHeight.desktop,
      [theme.breakpoints.up('lg')]: {
        position: 'relative',
        marginTop: 0,
      },
    },
    previewContainerVisible: {
      transition: 'width 0.4s ease-in',
      right: 0,
      top: 0,
      backgroundColor: theme.threadPalette.menuItem.normal,
      width: '60vw',
      height: '100vh',
      position: 'absolute',
      zIndex: theme.zIndex.appBar,
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
        width: '100vw',
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: ({ appBarHeight }) => `-${appBarHeight}px`,
        position: 'relative',
      },
    },
    headerRoot: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('lg')]: {
        padding: '8px 12px',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '15px 24px',
      },
    },
  }
})

interface IPageLayoutProps {
  headerContent?: React.ReactNode
  leftSidebarOption?: TLeftSidebarOption
  leftSidebarWidth?: number
  className?: string
}

const PageLayout: React.FC<IPageLayoutProps> = ({
  headerContent,
  leftSidebarOption,
  className,
  children,
  leftSidebarWidth = 60,
}) => {
  const theme = useTheme()
  const { ref: appBarRef, componentHeight: appBarHeight } = useTrackComponentDimensions()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  const classes = useStyles({ appBarHeight, leftSidebarWidth })
  const dispatch = useDispatch()
  const user = useUser()

  const { isAttachmentSidebarOpen } = useDisplayAttachmentContext()

  const [sidebarOpen, setSidebarOpen] = React.useState(isLargeScreen)

  const leftSidebarVariant = React.useMemo(() => {
    return isLargeScreen ? 'permanent' : 'temporary'
  }, [isLargeScreen])

  React.useEffect(() => {
    setSidebarOpen(isLargeScreen)
  }, [isLargeScreen])

  const contentClass = classnames(classes.content, { [VARIANT_FILE_PREVIEW_CLASSNAME]: isAttachmentSidebarOpen })
  const appBarClass = classnames(
    classes.appBar,
    { [VARIANT_FILE_PREVIEW_CLASSNAME]: isAttachmentSidebarOpen },
    className
  )
  const sidebarPaperClass = classnames(classes.sidebarPaper, {
    [VARIANT_FILE_PREVIEW_CLASSNAME]: isAttachmentSidebarOpen,
  })
  const sidebarClass = classnames(classes.sidebar, { [VARIANT_FILE_PREVIEW_CLASSNAME]: isAttachmentSidebarOpen })
  const previewContainerClass = isAttachmentSidebarOpen
    ? classes.previewContainerVisible
    : classes.previewContainerHidden

  const onToggleSidebarOpen = React.useCallback(() => {
    setSidebarOpen(!sidebarOpen)
  }, [sidebarOpen])

  const onLeftSidebarSignoutButtonClick = React.useCallback(() => {
    dispatch(AuthActions.signout())
  }, [dispatch])

  const onLeftSidebarProfileButtonClick = React.useCallback(() => {
    dispatch(push('/profile'))
  }, [dispatch])

  const leftSidebar = (
    <LeftSidebar
      user={user}
      leftSidebarOption={leftSidebarOption}
      onSignoutButtonClick={onLeftSidebarSignoutButtonClick}
      onProfileButtonClick={onLeftSidebarProfileButtonClick}
    />
  )

  return (
    <NewThreadModalProvider>
      <ThreadSnackbar />
      <SyncingInboxSnackbar isSyncingInbox={user?.isSyncingInbox} />
      <AppBar classes={{ root: appBarClass }} position="sticky" ref={appBarRef}>
        <Toolbar disableGutters className={classes.headerRoot}>
          <IconButton data-cy="left-sidebar-open-icon" className={classes.menuButton} onClick={onToggleSidebarOpen}>
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
          {headerContent}
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <nav className={sidebarClass}>
          <Drawer
            ModalProps={{
              keepMounted: true,
            }}
            anchor="left"
            classes={{
              paper: sidebarPaperClass,
            }}
            open={sidebarOpen}
            variant={leftSidebarVariant}
            onClose={onToggleSidebarOpen}
          >
            {leftSidebar}
          </Drawer>
        </nav>
        <main className={contentClass}>{children}</main>
        {isAttachmentSidebarOpen && (
          <aside className={previewContainerClass}>
            <AttachmentRightSidebarPanel />
          </aside>
        )}
      </div>
    </NewThreadModalProvider>
  )
}

export default PageLayout
