import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  field: {
    width: 320,
    fontSize: 17,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'capitalize',
  },

  loadingContainer: {
    width: '100%',
    height: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  fieldLabel: {
    fontSize: 17,
    marginTop: 24,
    marginBottom: 10,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.threadPalette.menuItem.normal,
  },

  toggleEnabledContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },

  dropdown: {
    height: 40,
  },
}))
