import React from 'react'
import { getThreadActivities } from '../../../api/threads'
import { IActivityItem } from '../../../model/messageActivity'
import { mapActivityItems } from 'dataProcessors/activityItem'
import moment from 'moment'

// the interval in which `view_thread` and `view_attachment` activities will be merged
const ACTIVITY_GROUPING_INTERVAL_MINUTES = 15

export interface IActivitiesState {
  activities: IActivityItem[]
  prevLast?: string
}

const mergeActivityPages = (loadedActivities: IActivityItem[], newActivities: IActivityItem[]) => {
  // when we are doing pagination on activities we may have the situation when we split one activities group into two
  // pages. this the function to handle this situation and merge such activities from the frontend side

  // the last already loaded activity (from the previous load), and the first new loaded activity
  // only this two activities have a chance to be merged
  const lastLoadedActivity = loadedActivities[loadedActivities.length - 1]
  const firstNewActivity = newActivities[0]

  // the end period might be none if only one activity went to the first page
  const lastLoadedEndPeriod = lastLoadedActivity.endPeriod
    ? lastLoadedActivity.endPeriod
    : lastLoadedActivity.startPeriod
  const newStartPeriod = firstNewActivity.startPeriod

  if (
    lastLoadedActivity.type === firstNewActivity.type &&
    (lastLoadedActivity.type === 'view_thread' || lastLoadedActivity.type === 'view_attachment') &&
    lastLoadedActivity.description === firstNewActivity.description &&
    lastLoadedActivity.user === firstNewActivity.user &&
    lastLoadedActivity.target === firstNewActivity.target &&
    moment(lastLoadedEndPeriod).diff(moment(newStartPeriod), 'minutes') <= ACTIVITY_GROUPING_INTERVAL_MINUTES
  ) {
    // since we will merge activities will will update the existing one and remove the new one
    newActivities.shift()

    // list of start/end periods to select the start and the end
    const periods = [lastLoadedActivity.startPeriod, firstNewActivity.startPeriod]
    if (lastLoadedActivity.endPeriod) {
      periods.push(lastLoadedActivity.endPeriod)
    }
    if (firstNewActivity.endPeriod) {
      periods.push(firstNewActivity.endPeriod)
    }

    // updated start and end periods and the count of the last loaded activity
    lastLoadedActivity.count += firstNewActivity.count
    lastLoadedActivity.startPeriod = periods.reduce(function (prev, current) {
      return prev > current ? prev : current
    })
    lastLoadedActivity.endPeriod = periods.reduce(function (prev, current) {
      return prev < current ? prev : current
    })
  }

  return [...loadedActivities, ...newActivities]
}

function useActivities(thread?: IThread) {
  // Marking if the last page was loaded so we don't need to load more.
  const [isLastPageLoaded, setIsLastPageLoaded] = React.useState(false)
  // Showing the current loaded page number.
  const [page, setPage] = React.useState(1)
  // The flag to prevent loading all pages at once. Basically when you are at the top of the list you can
  // trigger loading next page multiple times which is not ok. This flag is preventing this behaviour.
  const [isLoading, setIsLoading] = React.useState(false)
  // Activities state. Storing loaded activities and previous last activity - last activity of the previous load. Will be used
  // to scroll on him when new page loads.
  const [activitiesState, setActivitiesState] = React.useState<IActivitiesState>({
    activities: [],
  })
  // Reference used for the activity list.
  const elementRef = React.useRef<HTMLDivElement>(null)

  const loadActivities = React.useCallback(async () => {
    // Updating activity state with a new API call.
    if (!thread?.id) return

    const response = await getThreadActivities(thread.id, page)

    if (response.paginationData.page >= response.paginationData.last_page) {
      setIsLastPageLoaded(true)
    }

    setActivitiesState(activitiesState => {
      const activitiesMapped = mapActivityItems(response.activities)
      let mergedActivities = []

      // if not the first page loaded, checking if need to merge
      if (activitiesState.activities.length > 0) {
        mergedActivities = mergeActivityPages(activitiesState.activities, activitiesMapped)
      } else {
        mergedActivities = [...activitiesMapped, ...activitiesState.activities]
      }

      return {
        activities: mergedActivities,
        prevLast: activitiesState.activities?.[0]?.id,
      }
    })
  }, [page, thread])

  const handleLoadOnScroll = React.useCallback(
    // Callback for on scroll event. If we are near the list end, not loaded last page and not loading new activity will update existing.
    (e: Event) => {
      if (elementRef.current) {
        if (
          // 1.3 jusn an empirical number to set the offset for loading the next page
          elementRef.current.offsetHeight + elementRef.current.scrollTop * 1.3 > elementRef.current.scrollHeight &&
          !isLastPageLoaded &&
          !isLoading
        ) {
          setIsLoading(true) // preventing loading all pages at once
          setPage(page => page + 1)
        }
      }
    },
    [isLastPageLoaded, setPage, isLoading, setIsLoading]
  )

  React.useLayoutEffect(() => {
    // Scrolling back to the last activity of the previous load. Setting that we are finished loading here.
    if (activitiesState.prevLast) {
      document.getElementById(activitiesState.prevLast)?.scrollIntoView()
      setIsLoading(false)
    }
  }, [activitiesState.prevLast, setIsLoading])

  React.useEffect(() => {
    if (!isLastPageLoaded) {
      loadActivities()
    }
  }, [loadActivities, page, isLastPageLoaded])

  React.useEffect(() => {
    // clearing states for on a new thread, so new activities can be loaded
    setActivitiesState({ activities: [] })
    setPage(1)
    setIsLastPageLoaded(false)
  }, [thread])

  React.useEffect(() => {
    if (!elementRef.current) return
    const elementReference = elementRef.current
    elementRef.current.addEventListener('scroll', handleLoadOnScroll)
    return () => {
      elementReference?.removeEventListener('scroll', handleLoadOnScroll)
    }
  }, [handleLoadOnScroll])

  return { activitiesState, elementRef }
}

export { useActivities }
