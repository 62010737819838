import * as React from 'react'
import { Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useSearchKeyHintStyles = makeStyles((theme: Theme) => {
  return {
    searchKeyHintRoot: {
      alignItems: 'center',
      backgroundColor: theme.threadPalette.lightGray,
      borderRadius: '5px',
      color: theme.threadPalette.lightBlueGrey,
      display: 'flex',
      fontSize: '10px',
      height: '16px',
      justifyContent: 'center',
      minWidth: '16px',
      paddingLeft: '1px',
      paddingRight: '1px',
      textAlign: 'center',
      width: 'fit-content',
    },
  }
})

interface ISearchKeyHintProps {
  keyName: string
}

export const SearchKeyHint = ({ keyName }: ISearchKeyHintProps) => {
  const classes = useSearchKeyHintStyles()

  return <Typography classes={{ root: classes.searchKeyHintRoot }}>{keyName}</Typography>
}
