import IAttachment from './attachment'

export enum OpportunityStatus {
  open = 'open',
  purchased = 'purchased',
  cancelled = 'cancelled',
}

export type TOpportunityTypes = 'basic_salesforce_opportunity'

export interface IOppCount {
  openCount: number
  cancelledCount: number
  purchasedCount: number
}

export interface IOppAttribute {
  type: string
  value?: string | number
  name: string
  enumType?: string
}

export interface IOpportunity {
  id: string
  number: string
  name: string
  thread?: IThread
  purchaseDate: string
  probability: number
  owner?: IUser
  attributes: IOppAttribute[]
  attachments?: IAttachment[]
  status?: OpportunityStatus
}

export interface IOpportunityNote {
  content: string
  updatedAt: string
  opportunityId: string
}

export type IOpportunityActivityType =
  | 'OpportunityActivityType.attribute_update'
  | 'OpportunityActivityType.document_update'
  | 'OpportunityActivityType.task_update'
  | 'OpportunityActivityType.task_deletion'
  | 'OpportunityActivityType.task_creation'
  | 'OpportunityActivityType.task_update'
  | 'OpportunityActivityType.opportunity_creation'
  | 'OpportunityActivityType.file_attach'
  | 'OpportunityActivityType.file_detach'

export interface IOpportunityActivity {
  id: string
  attributeOldValue: string
  user: IUser
  createdAt: string
  attributeName: string
  attributeNewValue: string
  taskName: string
  type: IOpportunityActivityType
  attachment?: IAttachment
}

export type TTaskStatus = 'not_started' | 'in_progress' | 'completed'

export interface IOpportunityTask {
  id: string
  comments: string
  attachments: IAttachment[]
  dueDate: string
  assignedTo: IUser
  createdBy: IUser
  subject: string
  status: TTaskStatus
}

export type TSharedLocationType = 'thread' | 'opportunity'

export interface ISharedLocation {
  name: string
  type: TSharedLocationType
  id: string
}

export const EMPTY_OPPORTUNITY: IOpportunity = {
  id: '',
  number: '',
  name: '',
  purchaseDate: '',
  probability: 50,
  attributes: [],
}
