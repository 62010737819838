import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface IColorIconProps extends JSX.IntrinsicAttributes {
  className?: string
}

const ColorIcon = (props: IColorIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ height: 'inherit', width: 'inherit', transform: 'scale(1.15) translateY(0.5px)' }}
      viewBox="0 0 21 14"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#606060"
          d="M0 8.937C0 6.14 5.056 0 5.056 0s5.055 6.14 5.055 8.937c0 1.81-.964 3.48-2.528 4.385-1.564.904-3.491.904-5.055 0C.964 12.417 0 10.746 0 8.937z"
          transform="translate(.4)"
        />
        <path fill="#606060" d="M1.75 7L5.25 3.5 1.75 0z" transform="translate(.4) rotate(90 7 12.6)" />
      </g>
    </SvgIcon>
  )
}

export default ColorIcon
