import { clearAuthTokens } from '@jetkit/react'
import { Dispatch } from 'redux'

import { AUTH_ACTIONS } from '../constants/actions/auth'
import { push } from 'connected-react-router'
import { resetUserAuthOnboarding } from './userAuthOnboarding'

export const setChecked = (checked: boolean) => {
  return {
    type: AUTH_ACTIONS.SET_CHECKED,
    checked: checked,
  }
}

export const setUser = (user: ICurrentUser) => {
  return {
    type: AUTH_ACTIONS.SET_USER,
    user: user,
  }
}

export const signout = () => {
  return (dispatch: Dispatch, getState: IState) => {
    clearAuthTokens()
    dispatch({ type: AUTH_ACTIONS.SIGNOUT })
    dispatch(resetUserAuthOnboarding())
    dispatch(push('/signin'))
  }
}
