import * as React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { Theme, TextField, Typography, Link } from '@material-ui/core'
import ThreadListItem from './emailThreadItem'
import ProgressBarSmall from 'component/progressBarSmall'
import { ISelectedEmailThread, useEmailThreadSearch } from './useEmailThreadSearch'

const useEmailThreadSearchStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    border: `solid 1px ${theme.threadPalette.lightishGray}`,
    borderRadius: 8,
    maxWidth: 650,
    padding: '24px 16px 8px 10px',
    width: '100%',

    '&.searchHidden': {
      paddingTop: 2,
    },
  },

  search: {
    marginBottom: 14,
    width: '100%',
  },
  searchLabel: {
    fontStyle: 'italic',
    fontWeigth: 'normal',
  },
  itemsList: {
    height: `calc(100vh - 540px)`,
    overflowY: 'auto',
    width: '100%',
  },

  loading: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 8,
  },

  invisible: {
    visibility: 'hidden',
  },

  visible: {
    visibility: 'visible',
  },

  progressBar: {
    height: 15,
    width: 15,
  },

  progressLabel: {
    color: theme.palette.error.main,
    letterSpacing: 'normal',
    lineHeight: 'normal',
    marginLeft: 5,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },

  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  loadMoreContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  loadMoreText: {
    color: theme.threadPalette.brownishGray,
    fontSize: 14,
    marginLeft: 10,
    marginTop: 2,
  },
}))

interface IEmailThreadSearchProps {
  selectedEmailThreadId: string
  onEmailThreadSelected: (emailThread?: ISelectedEmailThread) => void
  hideSearch?: boolean
}

const EmailThreadSearch = ({
  selectedEmailThreadId,
  onEmailThreadSelected,
  hideSearch = false,
}: IEmailThreadSearchProps) => {
  const classes = useEmailThreadSearchStyles()

  const {
    searchQuery,
    isLoading,
    emailThreadsList,
    handleQueryChange,
    handleOnListItemClick,
    handleScrollEvent,
    resetSearch,
  } = useEmailThreadSearch(selectedEmailThreadId, onEmailThreadSelected)

  const isHideDividerforItem = (index: number) => index === 0 && hideSearch

  return (
    <div className={classNames(classes.root, { searchHidden: hideSearch })}>
      {!hideSearch && (
        <TextField
          label="Search for an email"
          className={classes.search}
          id="outlined-margin-none"
          variant="outlined"
          value={searchQuery}
          onChange={handleQueryChange}
          InputLabelProps={{ classes: { root: classes.searchLabel } }}
        />
      )}

      <div className={classes.itemsList} onScroll={handleScrollEvent}>
        {emailThreadsList.length === 0 && searchQuery.length > 0 ? (
          <div>
            <Typography>No results found</Typography>
            <Link onClick={resetSearch} className={classes.link}>
              Start a new search query?
            </Link>
          </div>
        ) : (
          emailThreadsList.map((item, index) => (
            <ThreadListItem
              key={item.conversationId}
              emailThread={item}
              isChecked={selectedEmailThreadId === item.conversationId}
              onCheck={handleOnListItemClick}
              showDivider={!isHideDividerforItem(index)}
            />
          ))
        )}
      </div>

      {isLoading && (
        <div className={classes.loading}>
          <ProgressBarSmall />
          <Typography align="center" className={classes.progressLabel}>
            Searching your email inbox...
          </Typography>
        </div>
      )}
    </div>
  )
}

export default EmailThreadSearch
