import * as React from 'react'
import { useIsSubscribed } from 'hooks/useIsSubscribed'
import UserConnectionStatus from '../userConnectionStatus'
import { getShortenedName } from 'dataProcessors/utils'
import { Tooltip, Typography } from '@material-ui/core'
import { useStyles } from 'component/threadHeaderBar/threadHeaderBar.style'
import classNames from 'classnames'
import { useThreadHeaderUserBadge } from './useThreadHeaderUserBadge'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'

interface IUserBadgeProps {
  name: string | null
  userId: string
  className?: string
}

const UserBadge = ({ name, userId, className }: IUserBadgeProps) => {
  const classes = useStyles()
  const isSubscribed = useIsSubscribed()
  const { currentThread } = useCurrentThreadContext()

  const { onlineStatus, threadTitle, isUserInCurrentThread, getTooltipLabel } = useThreadHeaderUserBadge(
    userId,
    currentThread?.id
  )

  return (
    <div className={classNames(classes.userBadge, className)}>
      <Tooltip
        title={
          process.env.USER_CONNECTIVITY_STATUS_ENABLED
            ? getTooltipLabel(isSubscribed, onlineStatus, isUserInCurrentThread, threadTitle)
            : ''
        }
        classes={{ tooltip: classes.tooltip }}
        placement="bottom"
      >
        <div className={classes.tooltipContent}>
          <UserConnectionStatus onlineStatus={onlineStatus} isActiveInThread={isUserInCurrentThread} />
          <Typography align="center">{getShortenedName(name)}</Typography>
        </div>
      </Tooltip>
    </div>
  )
}
export default UserBadge
