import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ButtonWithIcon from 'component/buttonWithIcon/buttonWithIcon'

const useShowRepliedOrForwardedMessageContentButtonStyles = makeStyles((theme: Theme) => ({
  buttonRoot: {
    color: theme.threadPalette.lightBlue,
    fontSize: 15,
    marginLeft: 4,
    padding: '3px 10px',
    '&:disabled': {
      color: theme.threadPalette.lightBlue,
    },
  },
}))

interface IShowRepliedOrForwardedMessageContentButtonProps {
  onClick(): void
  isLoading: boolean
}

const ShowRepliedOrForwardedMessageContentButton = ({
  onClick,
  isLoading,
}: IShowRepliedOrForwardedMessageContentButtonProps) => {
  const classes = useShowRepliedOrForwardedMessageContentButtonStyles()
  const buttonText = isLoading ? 'Loading previous messages' : 'Edit previous message content'
  return (
    <ButtonWithIcon
      size="small"
      buttonClassName={classes.buttonRoot}
      icon={EditIcon}
      onClick={onClick}
      text={buttonText}
      isLoading={isLoading}
    />
  )
}

export default ShowRepliedOrForwardedMessageContentButton
