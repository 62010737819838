import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useUser } from 'hooks/auth'

export const getThreadMessagesScrollPositionQueryKey = (userId: string, threadId: string) => [
  userId,
  'thread',
  threadId,
  'messages-scroll-position',
]

export const useThreadMessagesScrollPosition = (threadId: string) => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const threadScrollPositionQueryKey = getThreadMessagesScrollPositionQueryKey(currentUser?.id ?? '', threadId)

  const initialScrollPosition = 0

  const { data: scrollPosition } = useQuery<number>({
    enabled: !!currentUser?.id && !!threadId,
    queryKey: threadScrollPositionQueryKey,
    queryFn: () => queryClient.getQueryData(threadScrollPositionQueryKey) ?? initialScrollPosition,
    initialData: initialScrollPosition,
  })

  const setScrollPosition = ({ threadId, scrollPosition }: { threadId: string; scrollPosition: number }) => {
    if (!currentUser?.id) return

    const threadScrollPositionQueryKey = getThreadMessagesScrollPositionQueryKey(currentUser.id, threadId)
    queryClient.setQueryData(threadScrollPositionQueryKey, scrollPosition)
  }

  return {
    scrollPosition,
    setScrollPosition,
  }
}
