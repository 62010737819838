import React from 'react'
import { OnboardingStepTextLines } from '../onboardingStepTextLines'

export const ThreadAliasStep = () => {
  const textLines = [
    <>The name of your thread is the subject of the email.</>,
    <>You can rename it in ThreadLive to something more meaningful to you.</>,
    <>
      This change is only seen by you and <strong>won't alter the email subject</strong> or disrupt the conversation
      thread in your inbox.
    </>,
  ]

  return <OnboardingStepTextLines textLines={textLines} maxWidth={260} />
}
