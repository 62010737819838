import { IMention } from 'model/mention'
import React, { useContext } from 'react'
import ReactQuillEditor, { Quill } from 'react-quill'
import { RangeStatic, DeltaStatic, Delta } from 'quill'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import { parse, HTMLElement } from 'node-html-parser'

const IMAGE_SIZE_ON_SELECTION = 1
const NESTED_LIST_STYLES = ['', 'list-style-type: lower-alpha;', 'list-style-type: lower-roman;']

type QuillSelectionRange = RangeStatic

interface IInsertTextOptions {
  focusOnInsert?: boolean
  startingPosition?: number
  keepFormatting?: boolean
}
interface IRichTextActionsContext {
  richTextEditorRef: ReactQuillEditor | undefined
  richTextContent: string
  DEFAULT_EMPTY_INPUT_VALUE: string
  setRichTextEditorRef(ref: ReactQuillEditor | undefined): void
  setRichTextContent: React.Dispatch<React.SetStateAction<string>>
  insertTextAtTheEnd(text: string, options?: Omit<IInsertTextOptions, 'startingPosition'>): void
  insertDividerAtTheEnd(dividerText?: string): void
  insertTextAtSelection(text: string, options?: IInsertTextOptions): void
  insertImageAtSelection(imageSource: string): void
  undo(): void
  redo(): void
  getExistingMentionsFromContent(): IMention[]
  saveSelectionRange(previousRange: QuillSelectionRange): void
  convertFormattedTextToHtmlForEditor(text?: string): string
  insertExcelTable: (htmlTable: string) => void
  standardizeNestedLists: (messageContent: string, getListItemIndent: (listItem: HTMLElement) => number) => string
}

export const RichTextActionsContext = React.createContext<IRichTextActionsContext | undefined>(undefined)

export const RichTextActionsContextProvider: React.FC<{
  initialContent?: string
  footerToInsertOnLoading?: string
  shouldFocusOnRender?: boolean
}> = ({ children, initialContent = '', footerToInsertOnLoading, shouldFocusOnRender = true }) => {
  const [richTextContent, setRichTextContent] = React.useState(initialContent)
  const [richTextEditorRef, setRichTextEditorRef] = React.useState<ReactQuillEditor | undefined>()
  const [lastSelectionRange, setLastSelectionRange] = React.useState<QuillSelectionRange | undefined>()

  const setRichTextEditorReference = (ref: ReactQuillEditor | undefined) => {
    setRichTextEditorRef(previousRef => {
      // Force focus only when is first rendering
      if (!previousRef && shouldFocusOnRender) {
        ref?.focus()
      }
      return ref
    })
  }
  const [isEditorMounted, setIsEditorMounted] = React.useState(false)

  const DEFAULT_EMPTY_INPUT_VALUE = '<div><br></div>'

  const getEditor = React.useCallback((): Quill | undefined => {
    return richTextEditorRef?.getEditor()
  }, [richTextEditorRef])

  const createDeltaFromText = (text?: string): DeltaStatic => {
    const editor = getEditor()
    if (!editor || !text) return new Delta()

    const delta = editor.clipboard.convert(text)
    return delta
  }

  const getCurrentSelectionPosition = () => {
    const editor = getEditor()
    if (!editor) return

    const selectionOnFocusedEditor = editor.getSelection()?.index
    const lastSavedSelection = lastSelectionRange?.index

    return selectionOnFocusedEditor ?? lastSavedSelection
  }

  const getInputEndCursorPosition = () => {
    const isInputEmpty = richTextContent.length === 0 || richTextContent === DEFAULT_EMPTY_INPUT_VALUE
    if (isInputEmpty) return 0
    return richTextContent.length
  }

  const insertTextAtTheEnd = (
    text: string,
    { focusOnInsert = false, keepFormatting = false }: Omit<IInsertTextOptions, 'startingPosition'> = {}
  ) => {
    const cursorEndPosition = getInputEndCursorPosition()

    insertTextAtSelection(text, {
      startingPosition: cursorEndPosition,
      focusOnInsert,
      keepFormatting,
    })
  }

  const insertTextAtSelection = React.useCallback(
    (text: string, { startingPosition, focusOnInsert = true, keepFormatting = false }: IInsertTextOptions = {}) => {
      const editor = getEditor()
      if (!editor) return

      let cursorPosition = startingPosition ?? getCurrentSelectionPosition()
      if (!cursorPosition) cursorPosition = getInputEndCursorPosition()

      // Adding new text with Quill editor, doesn't render HTML, keeping the tags as text instead or keeping the formatting
      if (keepFormatting) {
        const deltaToInsert = createDeltaFromText(text)
        const currentEditorContentDeltas = editor.getContents()
        const combinedContent = currentEditorContentDeltas.concat(deltaToInsert)

        editor.setContents(combinedContent, 'user')
      }

      !keepFormatting && editor.insertText(cursorPosition, text, 'user')
      focusOnInsert && editor.setSelection(cursorPosition + text.length, 0, 'user')
    },
    [getEditor, getCurrentSelectionPosition, getInputEndCursorPosition, createDeltaFromText]
  )

  const insertDividerAtTheEnd = React.useCallback(
    (dividerText = '---') => {
      const editor = getEditor()
      if (!editor) return

      const cursorEndPosition = getInputEndCursorPosition()

      // inserting empty line before previousMessageContent
      const deltaToInsert = createDeltaFromText('\n')
      const currentEditorContentDeltas = editor.getContents()
      const combinedContent = currentEditorContentDeltas.concat(deltaToInsert)
      editor.setContents(combinedContent)

      editor.insertEmbed(cursorEndPosition + 1, 'emailPreviousMessageContentDivider', dividerText, 'user')
      editor.setSelection(cursorEndPosition + dividerText.length + 1, 0, 'user')
    },
    [getEditor, getInputEndCursorPosition]
  )

  const insertExcelTable = React.useCallback(
    (htmlTable: string) => {
      const editor = getEditor()
      if (!editor) return

      let cursorPosition = getCurrentSelectionPosition()
      if (!cursorPosition) cursorPosition = getInputEndCursorPosition()

      editor.insertEmbed(cursorPosition, 'excelTable', htmlTable, 'user')

      insertTextAtSelection('\n', { keepFormatting: true })
    },
    [getEditor, getInputEndCursorPosition]
  )

  const insertImageAtSelection = React.useCallback(
    (imageSource: string, imageCustomData?: string) => {
      const editor = getEditor()
      if (!editor) return

      let cursorPosition = getCurrentSelectionPosition()
      if (!cursorPosition && cursorPosition !== 0) cursorPosition = getInputEndCursorPosition()

      const imageParams: React.HTMLProps<HTMLImageElement> & { dataCustom?: string } = { src: imageSource }
      if (imageCustomData) {
        imageParams.dataCustom = imageCustomData
      }
      editor.insertEmbed(cursorPosition, 'image', imageParams, 'user')
      editor.setSelection(cursorPosition + IMAGE_SIZE_ON_SELECTION, 0, 'user')
    },
    [getEditor, getCurrentSelectionPosition, getInputEndCursorPosition]
  )

  const convertFormattedTextToHtmlForEditor = (text?: string): string => {
    const delta = createDeltaFromText(text)
    if (!delta?.ops) return ''

    const converter = new QuillDeltaToHtmlConverter(delta.ops)
    const html = converter.convert()
    return html
  }

  const undo = React.useCallback(() => {
    const editor: any = getEditor()
    if (!editor) return

    editor.history.undo()
  }, [getEditor])

  const redo = React.useCallback(() => {
    const editor: any = getEditor()
    if (!editor) return

    editor.history.redo()
  }, [getEditor])

  const getExistingMentionsFromContent = (): IMention[] => {
    const editor = getEditor()
    if (!editor) return []

    const changeBlocks = editor.getContents()
    const existingMentions = changeBlocks.reduce<IMention[]>((mentions, changeBlock) => {
      const { insert: { mention } = {} } = changeBlock
      if (mention)
        mentions.push({
          id: mention.id,
          name: mention.name,
        })

      return mentions
    }, [])

    return existingMentions
  }

  const saveSelectionRange = (previousRange: QuillSelectionRange) => setLastSelectionRange(previousRange)

  React.useEffect(() => {
    if (isEditorMounted || !richTextEditorRef) return

    setIsEditorMounted(true)
    if (footerToInsertOnLoading) {
      insertDividerAtTheEnd()
      insertTextAtTheEnd(footerToInsertOnLoading, { keepFormatting: true })
    }
  }, [richTextEditorRef])

  const standardizeNestedLists = (
    messageContent: string,
    getListItemIndent: (listItem: HTMLElement) => number
  ): string => {
    const root = parse(messageContent)

    const initialOrderedLists = root.querySelectorAll('ol')
    const initialBulletLists = root.querySelectorAll('ul')

    if (initialOrderedLists.length === 0 && initialBulletLists.length === 0) return messageContent

    initialOrderedLists.forEach(initialList => {
      const nestedListStandardized = standardizeNestedList({
        initialList,
        getListItemIndent,
        listTagName: 'ol',
        applyListStyles: true,
      })
      initialList.replaceWith(nestedListStandardized)
    })

    initialBulletLists.forEach(initialList => {
      const nestedListStandardized = standardizeNestedList({
        initialList,
        getListItemIndent,
        listTagName: 'ul',
        applyListStyles: false,
      })
      initialList.replaceWith(nestedListStandardized)
    })

    return root.toString()
  }

  const standardizeNestedList = ({
    initialList,
    listTagName,
    applyListStyles,
    getListItemIndent,
  }: {
    initialList: HTMLElement
    listTagName: string
    applyListStyles: boolean
    getListItemIndent: (listItem: HTMLElement) => number
  }) => {
    const nestedList = parse(`<${listTagName}></${listTagName}>`).querySelector(listTagName) as HTMLElement

    let currentIndent = -1
    let currentList = nestedList

    const listItems = initialList.querySelectorAll('li')

    listItems.forEach(li => {
      const liIndent = getListItemIndent(li)

      if (liIndent > currentIndent) {
        for (let _ = 0; _ < liIndent - currentIndent; _++) {
          const newList = parse(`<${listTagName}></${listTagName}>`).querySelector(listTagName) as HTMLElement

          const newListStyle = NESTED_LIST_STYLES[liIndent % NESTED_LIST_STYLES.length]
          applyListStyles && newList.setAttribute('style', newListStyle)

          currentList.appendChild(newList)
          currentList = newList
        }
      } else if (liIndent < currentIndent) {
        for (let _ = 0; _ < currentIndent - liIndent; _++) {
          currentList = currentList.parentNode as HTMLElement
        }
      }

      li.remove()
      currentList.appendChild(li)

      currentIndent = liIndent
    })

    return nestedList.querySelector(listTagName) as HTMLElement
  }

  return (
    <RichTextActionsContext.Provider
      value={{
        richTextEditorRef,
        richTextContent,
        DEFAULT_EMPTY_INPUT_VALUE,
        setRichTextEditorRef: setRichTextEditorReference,
        setRichTextContent,
        insertTextAtTheEnd,
        insertTextAtSelection,
        insertDividerAtTheEnd,
        insertImageAtSelection,
        undo,
        redo,
        getExistingMentionsFromContent,
        saveSelectionRange,
        convertFormattedTextToHtmlForEditor,
        insertExcelTable,
        standardizeNestedLists,
      }}
    >
      {children}
    </RichTextActionsContext.Provider>
  )
}

export const useRichTextActionsContext = () => {
  const context = useContext(RichTextActionsContext)
  if (context === undefined)
    throw new Error('useRichTextActionsContext should be used within a RichTextActionsContextProvider ')

  return context
}
