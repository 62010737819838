import React from 'react'
import { createStyles, makeStyles } from '@material-ui/styles'
import { Theme, Typography } from '@material-ui/core'
import { simpleDateFormat } from 'dataProcessors/dateTime'
import IAttachment from 'model/attachment'
import { humanizeAttachmentSize } from 'domain/attachment'
import { AttachmentContentTypeIcon } from 'component/attachmentContentTypeIcon/attachmentContentTypeIcon'

interface IUseAttachmentInfoCardStylesProps {
  fillColor: string
}

const useAttachmentInfoCardStyles = makeStyles<Theme, IUseAttachmentInfoCardStylesProps>((theme: Theme) =>
  createStyles({
    attachmentInfoCardRoot: {
      alignItems: 'center',
      display: 'flex',
    },
    attachmentExtensionIcon: {
      marginRight: 8,
    },
    attachmentTextWrapper: {
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
    },
    fileNameText: {
      color: ({ fillColor }) => fillColor,
      display: 'inline-block',
      fontSize: 16,
      fontWeight: 500,
      height: 21,
      marginBottom: 2,
      maxWidth: 250,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    dateTextWrapper: {
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
    textStyle: {
      color: ({ fillColor }) => fillColor,
      fontSize: 12,
      fontWeight: 300,
      marginRight: 2,
    },
  })
)

interface IAttachmentInfoCardProps {
  attachment: IAttachment
  fillColor: string
}

export const AttachmentInfoCard = ({ attachment, fillColor }: IAttachmentInfoCardProps) => {
  const classes = useAttachmentInfoCardStyles({ fillColor })

  const { owner, createdAt, size } = attachment

  const ownerDisplayName = !!owner.name ? owner.name : owner.email

  return (
    <div className={classes.attachmentInfoCardRoot}>
      <div className={classes.attachmentExtensionIcon}>
        <AttachmentContentTypeIcon attachment={attachment} fillColor={fillColor} />
      </div>
      <div className={classes.attachmentTextWrapper}>
        <Typography className={classes.fileNameText}>{attachment.filename}</Typography>
        <div className={classes.dateTextWrapper}>
          <Typography className={classes.textStyle}>{ownerDisplayName},</Typography>
          <Typography className={classes.textStyle}>{createdAt && simpleDateFormat(createdAt)},</Typography>
          {size && <Typography className={classes.textStyle}>{humanizeAttachmentSize({ size })}</Typography>}
        </div>
      </div>
    </div>
  )
}
