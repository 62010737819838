import React from 'react'
import { SelectOrCreateSharingGroupInput } from './selectOrCreateSharingGroupInput'
import { SharingGroupScreenHeader } from 'component/sharingGroup/common/sharingGroupScreenHeader'
import { useSharingGroupContext } from 'contexts/sharingGroupContext'
import { Theme, Typography, makeStyles } from '@material-ui/core'
import { ISharingGroup, IThreadSharingSettings } from 'model/sharingGroup'
import { useThreadSharingSettings } from 'hooks/useSharingGroup/useThreadSharingSettings'
import { ConnectedThreadSharingSettingsCard } from 'component/sharingGroup/common/connectedThreadSharingSettingsCard'
import { useStopSharingThreadWithSharingGroupUseCase } from 'usecases/sharingGroup/stopSharingThreadWithSharingGroup'
import {
  getHeldMessageCountForThreadSharingSettings,
  getThreadSharingSettingsTheUserIsSharingGroupOwner,
} from 'domain/sharingGroup'
import { useUser } from 'hooks'
import { ReviewHeldMessagesButton } from 'component/sharingGroup/common/reviewHeldMessagesButton'
import { CopyThreadLinkButton } from 'component/threadHeader/copyThreadLinkButton'
import { getThreadDisplayName } from 'domain/thread'

const useConnectSharingGroupScreenStyles = makeStyles((theme: Theme) => ({
  connectSharingGroupScreenRoot: {
    paddingInline: 20,
    paddingBottom: 20,
  },
  sharedWithContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    paddingTop: 24,
  },
  sharedWithTitle: {
    color: theme.threadPalette.menuItem.current,
    fontSize: 24,
    fontWeight: 500,
  },
  footerRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  reviewHeldMessagesButton: {
    alignSelf: 'end',
  },
}))

interface IConnectSharingGroupScreenProps {
  onCreateNewSharingGroupByName?(name?: string): void
  onEditExistingSharingGroup?(sharingGroup: ISharingGroup): void
  onShareThreadToSharingGroup?(sharingGroup: ISharingGroup): void
  onEditThreadSharingSettings?(threadSharingSettings: IThreadSharingSettings): void
  onReviewHeldMessages?(): void
  isInputAutoFocused?: boolean
}
export const ConnectSharingGroupScreen = ({
  onShareThreadToSharingGroup,
  onEditThreadSharingSettings,
  onCreateNewSharingGroupByName,
  onEditExistingSharingGroup,
  onReviewHeldMessages,
  isInputAutoFocused,
}: IConnectSharingGroupScreenProps) => {
  const classes = useConnectSharingGroupScreenStyles()
  const { thread } = useSharingGroupContext()
  const currentUser = useUser()

  const { threadSharingSettingsList, isLoading: isThreadSharingListLoading } = useThreadSharingSettings(thread.id)

  const title = `Share "${getThreadDisplayName(thread)}"`

  const { stopSharingThreadWithSharingGroupUseCase } = useStopSharingThreadWithSharingGroupUseCase()

  if (!currentUser) return null

  const threadSharingSettingsUserOwns = getThreadSharingSettingsTheUserIsSharingGroupOwner(
    currentUser.id,
    threadSharingSettingsList
  )

  const hasThreadSharingSettings = !isThreadSharingListLoading && threadSharingSettingsUserOwns.length > 0

  const alreadyConnectedSharingGroups = threadSharingSettingsUserOwns.map(
    threadSharingSettings => threadSharingSettings.sharingGroup
  )

  const heldMessageCount = getHeldMessageCountForThreadSharingSettings({
    threadSharingSettingsList: threadSharingSettingsUserOwns,
    user: currentUser,
  })
  const hasHeldMessages = hasThreadSharingSettings && heldMessageCount > 0

  return (
    <div className={classes.connectSharingGroupScreenRoot}>
      <SharingGroupScreenHeader text={title} />
      <SelectOrCreateSharingGroupInput
        autoFocus={isInputAutoFocused && !hasThreadSharingSettings}
        sharingGroupsToRemoveFromOptions={alreadyConnectedSharingGroups}
        onSelectSharingGroup={onShareThreadToSharingGroup}
        onCreateNewSharingGroup={onCreateNewSharingGroupByName}
        onEditSharingGroup={onEditExistingSharingGroup}
      />
      {hasThreadSharingSettings && (
        <>
          <section className={classes.sharedWithContainer}>
            <Typography component="h2" className={classes.sharedWithTitle}>
              Shared With
            </Typography>
            {threadSharingSettingsUserOwns.map(threadSharingSettings => (
              <ConnectedThreadSharingSettingsCard
                key={`${threadSharingSettings.thread.id}-${threadSharingSettings.sharingGroup.id}`}
                threadSharingSettings={threadSharingSettings}
                onEditGroup={() => onEditExistingSharingGroup?.(threadSharingSettings.sharingGroup)}
                onEditSharingSettings={() => onEditThreadSharingSettings?.(threadSharingSettings)}
                onStopSharing={() =>
                  stopSharingThreadWithSharingGroupUseCase({
                    sharingGroupId: threadSharingSettings.sharingGroup.id,
                    threadId: threadSharingSettings.thread.id,
                  })
                }
                manuallySharedMessageCount={threadSharingSettings.manuallySharedMessageCount}
              />
            ))}
          </section>
          <div className={classes.footerRoot}>
            <CopyThreadLinkButton threadId={thread.id} outlined />
            {hasHeldMessages && onReviewHeldMessages && (
              <ReviewHeldMessagesButton onClick={onReviewHeldMessages} className={classes.reviewHeldMessagesButton} />
            )}
          </div>
        </>
      )}
    </div>
  )
}
