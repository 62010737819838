import { useCallback } from 'react'
import { useUser } from 'hooks/auth'
import { useOnboardingTour } from './useOnboardingTour'

export const useThreadOnboardingTour = () => {
  const currentUser = useUser()

  const { startOnboardingTour } = useOnboardingTour()

  const startThreadOnboardingTour = useCallback(
    ({ thread, skipActivationCheck = false }: { thread: IThread; skipActivationCheck?: boolean }) => {
      const isUserActivated = skipActivationCheck || currentUser?.activatedDateTime
      if (!currentUser || !isUserActivated || !thread.isInitiated || thread.isImporting) return

      const onboardingTourName = thread.isUserAThreadDirectRecipient ? 'thread' : 'shared_thread'

      startOnboardingTour(onboardingTourName)
    },
    [currentUser, startOnboardingTour]
  )

  return { startThreadOnboardingTour }
}
