import React from 'react'
import { SubscriptionCreationErrorPage } from 'component/signInErrors/subscriptionCreationError'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useSubscriptionCreationForbiddenPageStyles = makeStyles((theme: Theme) => ({
  subtitleRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  connectWithUsSubtitle: {
    fontSize: '20px',
    width: '350px',
    marginTop: '12px',
  },
}))

export const SubscriptionCreationForbiddenPage = () => {
  const classes = useSubscriptionCreationForbiddenPageStyles()

  const onButtonClick = () => {
    // adding home page to the history, so user can open the previous page without any issues
    window.history.pushState(null, '', '/')
    window.history.pushState(null, '', '/book-support-call')
    window.location.reload()
  }

  const subtitle = (
    <div className={classes.subtitleRoot}>
      <div>It looks like you're not authorized to provide us access to subscribe to your inbox updates</div>
      <div className={classes.connectWithUsSubtitle}>
        <div>Pick a time so we can quickly help you with your issue</div>
      </div>
    </div>
  )

  return <SubscriptionCreationErrorPage subtitle={subtitle} buttonTitle={'Book a call'} onButtonClick={onButtonClick} />
}
