import { useThreadActions } from 'hooks/useThreads/useThreadActions'

export const useInsertThreadUseCase = () => {
  const { getThreadsListActiveData, insertThreadToThreadsList } = useThreadActions()

  const insertThreadUseCase = (thread: IThread) => {
    const threadsListActiveData = getThreadsListActiveData()

    if (!threadsListActiveData) return

    const { data: activeThreadsList, activeFilters } = threadsListActiveData

    if ('following' in activeFilters && thread.stateForUser === 'following') {
      insertThreadToThreadsList(thread, activeThreadsList)
    }
    if ('unfollowed' in activeFilters && thread.stateForUser === 'unfollowed') {
      insertThreadToThreadsList(thread, activeThreadsList)
    }
    if ('viewed' in activeFilters && thread.stateForUser === 'viewed') {
      insertThreadToThreadsList(thread, activeThreadsList)
    }
  }
  return {
    insertThreadUseCase,
  }
}
