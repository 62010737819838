import React from 'react'
import { IconButton, makeStyles, Theme } from '@material-ui/core'
import ArrowExpand from 'icons/arrowExpand'
import ArrowCompress from 'icons/arrowCompress'
import classNames from 'classnames'

const useExpandInputIconButtonStyles = makeStyles((theme: Theme) => ({
  expandButton: {
    '& path': {
      fill: theme.threadPalette.lightGray,
    },
  },
}))

interface IExpandInputIconButton {
  isExpanded: boolean
  toggleExpanded(): void
  className?: string
}

export const ExpandInputIconButton = ({ isExpanded, toggleExpanded, className = '' }: IExpandInputIconButton) => {
  const classes = useExpandInputIconButtonStyles()
  return (
    <IconButton className={classNames(classes.expandButton, className)} onClick={toggleExpanded} size="small">
      {isExpanded ? <ArrowCompress /> : <ArrowExpand />}
    </IconButton>
  )
}
