import Button from '@material-ui/core/Button'
import { Theme, List, ListItemIcon, ListItem, fade } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import AssistantIcon from '@material-ui/icons/Face'
import { makeStyles } from '@material-ui/styles'
import * as React from 'react'
import LogoOnlyIcon from 'icons/logoOnly'
import UserAvatar from '../userAvatar'
import OptionsMenu, { IMenuItem } from '../menu/menu'
import { useDispatch } from 'react-redux'
import { push } from 'actions/navigation'
import MessagesIcon from 'icons/messages'
import ListIcon from '@material-ui/icons/List'
import classNames from 'classnames'
import NotificationsPopover from 'component/notificationsPopover'
import { useMenu } from 'hooks/useMenu'
import NotificationsBadge from 'component/rightSidebar/notificationCenter/notifcationsBadge'
import { setOption } from 'actions/leftSidebar'
import { UserAccessCheckWrapper } from 'lib/accessCheck'
import { QuickViewList } from './quickViewList'
import { LeftSidebarMenuItem } from './leftSidebarMenuItem'
import ProjectIcon from 'icons/project'
import { OnboardingTourTargetComponent } from 'domain/onboardingTourStep'

const USER_MENU_ITEMS: Array<IMenuItem<string>> = [
  {
    value: 'account',
    label: 'Account',
    IconComponent: SettingsIcon,
  },
  {
    value: 'signout',
    label: 'Sign out',
    IconComponent: ExitToAppIcon,
  },
]

const useLeftSidebarStyles = makeStyles((theme: Theme) => {
  return {
    leftSidebar: {
      display: 'flex',
      height: '100vh',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    notificationWrapper: {
      backgroundColor: '#141e2e',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: 60,
    },
    LogoButton: {
      alignSelf: 'center',
      margin: '24px auto',
    },
    leftSidebarOptionsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    userMenu: {
      textAlign: 'center',
    },
    userButton: {
      color: theme.threadPalette.white,
      padding: '0px',
    },
    userButtonEndIcon: {
      marginLeft: '0px',
    },
    userButtonText: {
      flex: 1,
      textAlign: 'left',
    },
    option: {
      backgroundColor: 'transparent',
      borderRadius: 8,
      height: 50,
      padding: 4,
      width: '100%',
      minWidth: 0,

      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.5),
      },
    },
    listItemIconRoot: {
      alignItems: 'center',
      borderRadius: 8,
      color: theme.threadPalette.white,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      minWidth: 0,
      width: '100%',
    },
    leftSidebarSection: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginInline: 4,
    },
    bottomSection: {
      height: 100,
      justifyContent: 'space-between',
    },
    logo: {
      display: 'flex',
    },
  }
})

interface ILeftSidebarProps {
  leftSidebarOption?: TLeftSidebarOption
  onSignoutButtonClick: () => void
  onProfileButtonClick: () => void
  user: TCurrentUser
}

const LeftSidebar: React.FC<ILeftSidebarProps> = props => {
  const classes = useLeftSidebarStyles()
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  const [unreadNotificationsCount, setUnreadNotificationsCount] = React.useState(0)

  const user = props.user

  const setLeftSidebarOption = React.useCallback(
    (option: TLeftSidebarOption) => {
      dispatch(setOption(option))
    },
    [dispatch]
  )

  const onUserButtonClick = React.useCallback((event: React.SyntheticEvent<any, MouseEvent>) => {
    setUserMenuAnchorEl(event.currentTarget)
  }, [])

  const onUserMenuClose = React.useCallback(() => {
    setUserMenuAnchorEl(null)
  }, [])

  const onUserMenuSelect = React.useCallback(
    (value: string) => {
      onUserMenuClose()
      switch (value) {
        case 'signout': {
          props.onSignoutButtonClick()
          break
        }
        case 'account': {
          props.onProfileButtonClick()
          break
        }
      }
    },
    [onUserMenuClose, props]
  )

  const onHomeClick = React.useCallback(() => {
    dispatch(push('/'))
  }, [dispatch])

  const { menuAnchorEl, onMenuIconClick, onMenuClose } = useMenu()

  const onSidebarThreadsOptionSelected = React.useCallback(() => {
    setLeftSidebarOption('threads')
    dispatch(push('/'))
  }, [dispatch, setLeftSidebarOption])

  const onSidebarProjectsOptionSelected = React.useCallback(() => {
    setLeftSidebarOption('projects')
    dispatch(push('/projects'))
  }, [dispatch, setLeftSidebarOption])

  const onSidebarAssistantOptionSelected = React.useCallback(() => {
    setLeftSidebarOption('assistant')
    dispatch(push('/assistant'))
  }, [dispatch, setLeftSidebarOption])

  const onSidebarManagementOptionSelected = React.useCallback(() => {
    setLeftSidebarOption('management')
    dispatch(push('/management'))
  }, [dispatch, setLeftSidebarOption])

  const onCloseNotificationCenter = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    onMenuClose(event)
    setUnreadNotificationsCount(0)
  }

  return (
    <>
      <div className={classes.leftSidebar}>
        <NotificationsPopover
          anchorEl={menuAnchorEl}
          open={menuAnchorEl !== null}
          onClose={onCloseNotificationCenter}
        />
        <div className={classes.notificationWrapper}>
          <UserAccessCheckWrapper requiredPermissions={['user_registered']}>
            <div className={classes.logo}>
              <LogoOnlyIcon onClick={onHomeClick} className={classes.LogoButton} height={30} width={34} />
            </div>
          </UserAccessCheckWrapper>
          <List className={classes.leftSidebarOptionsWrapper}>
            <div className={classNames(classes.leftSidebarSection)}>
              <LeftSidebarMenuItem
                onClick={onSidebarThreadsOptionSelected}
                isSelected={props.leftSidebarOption === 'threads'}
                icon={<MessagesIcon width={22} height={22} />}
                tooltip="Threads"
              />
              <LeftSidebarMenuItem
                id={OnboardingTourTargetComponent.projectsDashboardLeftSidebarMenu}
                onClick={onSidebarProjectsOptionSelected}
                isSelected={props.leftSidebarOption === 'projects'}
                icon={<ProjectIcon />}
                tooltip="Projects"
              />
              <LeftSidebarMenuItem
                onClick={onSidebarAssistantOptionSelected}
                isSelected={props.leftSidebarOption === 'assistant'}
                icon={<AssistantIcon />}
                tooltip="AI Assistant"
              />
              <UserAccessCheckWrapper requiredPermissions={['crm_full_access']} notAllowedComponent={null}>
                <LeftSidebarMenuItem
                  onClick={onSidebarManagementOptionSelected}
                  isSelected={props.leftSidebarOption === 'management'}
                  icon={<ListIcon width={30} height={30} />}
                />
              </UserAccessCheckWrapper>
            </div>
            <div className={classNames(classes.leftSidebarSection, classes.bottomSection)}>
              {/* TODO refactor it to use new left sidebar menu item */}
              <ListItem button className={classes.option}>
                <ListItemIcon classes={{ root: classes.listItemIconRoot }} onClick={onMenuIconClick}>
                  <NotificationsBadge
                    showUnread={menuAnchorEl === null}
                    unreadCount={unreadNotificationsCount}
                    updateUnreadCount={setUnreadNotificationsCount}
                  />
                </ListItemIcon>
              </ListItem>

              {user !== null && (
                <div className={classes.userMenu}>
                  <Button
                    className={classes.userButton}
                    classes={{
                      endIcon: classes.userButtonEndIcon,
                    }}
                    fullWidth
                    size="large"
                    onClick={onUserButtonClick}
                  >
                    <UserAvatar user={user} />
                  </Button>
                  <OptionsMenu
                    anchorEl={userMenuAnchorEl}
                    items={USER_MENU_ITEMS}
                    open={userMenuAnchorEl !== null}
                    onClose={onUserMenuClose}
                    onSelect={onUserMenuSelect}
                  />
                </div>
              )}
            </div>
          </List>
        </div>
        <QuickViewList />
      </div>
    </>
  )
}

export default LeftSidebar
