import * as React from 'react'
import LogoOnlyIcon from 'icons/logoOnly'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles, createStyles } from '@material-ui/styles'
import Spacer from 'component/layoutUtils/spacer'
import ImgMessageBubbleTriangle from 'assets/message_bubble_triangle.svg'
import classNames from 'classnames'
import { Typography } from '@material-ui/core'
import AppLoading from 'component/appLoading'

const useSystemMessageStyles = makeStyles((theme: Theme) =>
  createStyles({
    systemMessageRoot: {
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'row',
      marginLeft: '24px',
      [theme.breakpoints.down('md')]: {
        marginLeft: '14px',
      },
      maxWidth: '780px',
    },
    logoIcon: {
      fill: theme.threadPalette.lightGray,
    },
    messageBubbleRoot: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      '&::before': {
        backgroundImage: `url(${ImgMessageBubbleTriangle})`,
        backgroundSize: '10px 10px',
        bottom: '15px',
        content: '""',
        display: 'block',
        height: '10px',
        left: '-6px',
        position: 'absolute',
        transform: 'rotate(180deg)',
        width: '10px',
      },
    },
    messageBubbleRootWithAction: {
      '&::before': {
        bottom: '47px',
      },
    },
    messageBubble: {
      border: `solid 2px ${theme.threadPalette.lightishGray}`,
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      borderTopRightRadius: '10px',
      fontSize: '15px',
      minWidth: '400px',
      padding: '16px',
    },
    messageBubbleHeader: {
      alignItems: 'center',
      backgroundColor: theme.threadPalette.lightGray,
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      color: theme.threadPalette.white,
      display: 'inline-flex',
      marginBottom: '-2px',
      minHeight: '20px',
      minWidth: '140px',
      paddingInline: '10px',
      position: 'relative',
      width: 'fit-content',
    },
    messageBubbleHeaderText: {
      fontSize: '10px',
    },
    logoIconWrapper: {
      height: '32px',
      width: '40px',
    },
    headerIcon: {
      fill: theme.threadPalette.white,
      height: '12px',
      marginRight: '6px',
      width: '12px',
    },
    actionsWrapper: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      height: '30px',
      marginLeft: 'auto',
      marginRight: '16px',
      marginTop: '2px',
    },
    actionIcon: {
      fill: theme.palette.primary.main,
      height: '18px',
      width: '18px',
    },
    messageActionText: {
      color: theme.palette.primary.main,
      fontSize: '15px',
      marginLeft: '4px',
      '&:hover': {
        fontWeight: 'bold',
      },
    },
    logoWrapper: {
      marginBottom: '32px',
    },
  })
)

interface ISystemMessageAction {
  text: string
  IconComponent?: React.FC<any>
  onClick(): Promise<void>
}

interface ISystemMessageHeader {
  text: string
  IconComponent?: React.FC<any>
  iconClassName?: string
}

export interface ISystemMessageProps {
  children: JSX.Element
  header: ISystemMessageHeader
  action?: ISystemMessageAction
}

export const SystemMessage = React.forwardRef<HTMLDivElement, ISystemMessageProps>(
  ({ children, header, action }: ISystemMessageProps, ref) => {
    const classes = useSystemMessageStyles()

    const [isActionLoading, setIsActionLoading] = React.useState<boolean>(false)

    const { text: headerText, IconComponent: HeaderIconComponent, iconClassName: headerIconClass } = header
    const ActionIconComponent = action?.IconComponent

    const hasAction = !!action

    const headerIconClassName = classNames(classes.headerIcon, headerIconClass)
    const logoIconWrapperClassName = classNames(classes.logoIconWrapper, { [classes.logoWrapper]: hasAction })
    const messageBubbleRootClassName = classNames(classes.messageBubbleRoot, {
      [classes.messageBubbleRootWithAction]: hasAction,
    })

    const onActionClick = React.useCallback(async () => {
      if (!action || isActionLoading) return

      setIsActionLoading(true)
      await action.onClick()
      setIsActionLoading(false)
    }, [action, isActionLoading])

    return (
      <div className={classes.systemMessageRoot} ref={ref}>
        <div className={logoIconWrapperClassName}>
          <LogoOnlyIcon fill="#aaaaaa" height={32} width={38} />
        </div>
        <Spacer width={17} />
        <div className={messageBubbleRootClassName}>
          <div className={classes.messageBubbleHeader}>
            {HeaderIconComponent && <HeaderIconComponent className={headerIconClassName} />}
            <Typography className={classes.messageBubbleHeaderText}>{headerText}</Typography>
          </div>
          <div className={classes.messageBubble}>{children}</div>
          {action && (
            <div className={classes.actionsWrapper} onClick={onActionClick}>
              {!isActionLoading && ActionIconComponent && <ActionIconComponent className={classes.actionIcon} />}
              {<AppLoading visible={isActionLoading} small legWidth={8} legHeight={16} />}
              {isActionLoading && <Spacer width={8} />}
              <Typography className={classes.messageActionText}>{action.text}</Typography>
            </div>
          )}
        </div>
      </div>
    )
  }
)
