import React from 'react'
import { makeStyles, Theme, useTheme } from '@material-ui/core'
import DialogAnchoredOnComponent from 'component/dialogAnchoredOnComponent'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import { EditButton } from './editButton'
import classNames from 'classnames'

const useStopSharingThreadButtonStyles = makeStyles((theme: Theme) => ({
  stopSharingThreadButtonRoot: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },
  stopSharingThreadConfirmationPopupContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    width: 432,
  },
}))

export interface IStopSharingThreadButtonProps {
  sharingGroupName: string
  anchorElementRefForConfirmationPopup?: React.RefObject<HTMLElement>
  onClick?(): void
  className?: string
}

export const StopSharingThreadButton = ({
  sharingGroupName,
  anchorElementRefForConfirmationPopup,
  onClick,
  className,
}: IStopSharingThreadButtonProps) => {
  const theme = useTheme()
  const classes = useStopSharingThreadButtonStyles()

  const {
    isOpen: isStopSharingThreadConfirmationPopupOpen,
    dialogAnchorElement,
    openDialog: openStopSharingThreadConfirmationPopup,
    handleCloseDialog: closeStopSharingThreadConfirmationPopup,
    setDialogAnchorRef: setStopSharingThreadConfirmationPopupAnchorElement,
  } = useDialog()

  React.useEffect(() => {
    if (!anchorElementRefForConfirmationPopup?.current) return
    setStopSharingThreadConfirmationPopupAnchorElement(anchorElementRefForConfirmationPopup.current)
  }, [anchorElementRefForConfirmationPopup])

  const stopSharingThreadWarningActions = [
    {
      label: 'Cancel',
      action: closeStopSharingThreadConfirmationPopup,
    },
    {
      label: 'Stop sharing',
      labelColor: theme.palette.error.contrastText,
      action: onClick,
    },
  ]

  return (
    <DialogAnchoredOnComponent
      open={isStopSharingThreadConfirmationPopupOpen}
      anchoredElementRef={dialogAnchorElement}
      onClose={closeStopSharingThreadConfirmationPopup}
      title={`Are you sure you want to stop sharing this thread with ${sharingGroupName}?`}
      textAlign="center"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      actions={stopSharingThreadWarningActions}
      rootClassName={classes.stopSharingThreadConfirmationPopupContainer}
    >
      <EditButton
        label="Stop sharing"
        className={classNames(classes.stopSharingThreadButtonRoot, className)}
        onClick={openStopSharingThreadConfirmationPopup}
      />
    </DialogAnchoredOnComponent>
  )
}
