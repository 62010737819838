import * as React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Snackbar, Button, Theme, SnackbarContent } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { selectSnackbar } from 'selectors/threadSnackbar'
import { dismissSnackbar } from 'actions/snackbar'
import Grow from '@material-ui/core/Grow'
import { TransitionProps } from '@material-ui/core/transitions'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.primary.main,
    },
  })
)

function GrowTransition(props: TransitionProps) {
  return <Grow {...props} />
}

interface IThreadSnackbarProps {
  className?: string
}

const ThreadSnackbar = (_: IThreadSnackbarProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const snackbarState = useSelector(selectSnackbar)

  const handleDismissSnackbar = React.useCallback(() => {
    dispatch(dismissSnackbar())
  }, [dispatch])

  const onClose = React.useCallback(
    (event, reason) => {
      if (reason === 'clickaway' && !snackbarState.closeOnClickaway) return

      handleDismissSnackbar()
    },
    [snackbarState.closeOnClickaway]
  )

  const snackbarAction = (
    <Button onClick={handleDismissSnackbar} className={classes.button}>
      CLOSE
    </Button>
  )

  let snackbarContent = <SnackbarContent message={snackbarState.message} action={snackbarAction} />

  if (snackbarState.severity) {
    snackbarContent = (
      <Alert action={snackbarAction} severity={snackbarState.severity}>
        {snackbarState.message}
      </Alert>
    )
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={snackbarState.duration}
      open={snackbarState.show}
      onClose={onClose}
      TransitionComponent={GrowTransition}
    >
      {snackbarContent}
    </Snackbar>
  )
}

export default ThreadSnackbar
