import * as React from 'react'

interface IBackArrowProps {
  className?: string
}

const BackArrow = (props: IBackArrowProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" {...props}>
      <path
        fill="#2196F3"
        fillRule="evenodd"
        d="M6.285 9.314C6.103 9.496 6 9.743 6 10c0 .257.103.505.285.686l7.053 7.03c.38.379.997.379 1.377 0 .38-.38.38-.994 0-1.372L8.35 10l6.365-6.344c.38-.378.38-.993 0-1.372-.38-.379-.996-.379-1.377 0l-7.053 7.03z"
      />
    </svg>
  )
}

export default BackArrow
