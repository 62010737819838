import { apiClient } from '@jetkit/react'

export const getCurrentUser = async (): Promise<IAuthCheckResponse | null> => {
  let result = null
  try {
    result = await apiClient.get('/auth/me')
  } catch (error) {
    if (error.isAxiosError && error.response.status === 401) {
      result = error.response
    } else {
      throw error
    }
  }

  if (result !== null && result.status === 200) {
    return result.data
  }

  return null
}
