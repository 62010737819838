import React from 'react'
import { Step, StepLabel, Stepper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useUserOnboardingStepperStyles = makeStyles((theme: Theme) => ({
  stepper: {
    maxWidth: 500,
    padding: 0,
    width: '100%',
    '& .MuiStepIcon-root': {
      height: 15,
      width: 15,
    },
    '& .MuiStepIcon-completed': {
      color: theme.threadPalette.green,
    },
  },
  step: {
    '& .MuiStepConnector-alternativeLabel': {
      top: 7,
    },
  },
  stepperLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    '& .MuiStepLabel-label': {
      marginTop: 8,
    },
  },
}))

interface IUserOnboardingStepperProps {
  activeStep: number
  steps: string[]
}

export const UserOnboardingStepper = ({ activeStep, steps }: IUserOnboardingStepperProps) => {
  const classes = useUserOnboardingStepperStyles()
  return (
    <Stepper activeStep={activeStep} alternativeLabel classes={{ root: classes.stepper }}>
      {steps.map((label, index) => (
        <Step completed={activeStep > index} key={label} classes={{ root: classes.step }}>
          <StepLabel classes={{ root: classes.stepperLabel }}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
