import React from 'react'
import { makeStyles, Popover, Theme, Typography } from '@material-ui/core'
import { PopoverProps } from 'material-ui'
import { ISharingGroup } from 'model/sharingGroup'
import { MessageSharingGroupBlock } from './messageSharingGroupBlock'
import { getUserDisplayName } from 'dataProcessors/userDisplayName'
import { ShareIndividualMessageButton } from 'component/sharingGroup/common/shareIndividualMessageButton'
import { canMessageBeSharedByUser } from 'domain/sharingGroup'

const useMessageSharingGroupsListPopoverStyles = makeStyles((theme: Theme) => {
  return {
    paperRoot: {
      backgroundColor: theme.threadPalette.whiteSmoke,
      borderRadius: 16,
      display: 'flex',
      maxHeight: 'clamp(20vh, 60vh, 80vh)',
      overflow: 'hidden',
    },
    listRoot: {
      display: 'flex',
      flexDirection: 'column',
      gap: 26,
      overflowY: 'auto',
      padding: 16,
      width: '100%',
    },
    headerRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 6,
      minWidth: 420,
    },
    headerText: {
      color: theme.threadPalette.brownishGray,
      fontSize: 18,
      fontWeight: 500,
    },
    groupsListRoot: {
      display: 'flex',
      flexDirection: 'column',
      gap: 18,
      marginTop: 20,
    },
  }
})

interface IMessageSharingGroupsListPopoverProps extends Omit<PopoverProps, 'anchorEl'> {
  isOpen: boolean
  anchorEl: HTMLElement | (() => HTMLElement) | null
  onClose?(): void
  currentUser: IUser
  message: IMessage
  sharingGroups: ISharingGroup[]
}

export const MessageSharingGroupsListPopover = ({
  sharingGroups,
  currentUser,
  message,
  isOpen,
  ...props
}: IMessageSharingGroupsListPopoverProps) => {
  const classes = useMessageSharingGroupsListPopoverStyles()

  const sharingGroupsGroupedByOwner = React.useMemo(() => {
    const groupOwnerIdToSharingGroupMapping: Record<string, ISharingGroup[]> = {}

    sharingGroups.forEach(sharingGroup => {
      if (sharingGroup.owner.id in groupOwnerIdToSharingGroupMapping) {
        const existingSharingGroups = groupOwnerIdToSharingGroupMapping[sharingGroup.owner.id]
        groupOwnerIdToSharingGroupMapping[sharingGroup.owner.id] = [...existingSharingGroups, sharingGroup]
      } else {
        groupOwnerIdToSharingGroupMapping[sharingGroup.owner.id] = [sharingGroup]
      }
    })

    return groupOwnerIdToSharingGroupMapping
  }, [sharingGroups])

  if (sharingGroups.length === 0) return null

  const canUserShareMessage = canMessageBeSharedByUser({ user: currentUser, message })

  return (
    <Popover
      open={isOpen}
      classes={{ paper: classes.paperRoot }}
      {...props}
      elevation={8}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div className={classes.listRoot}>
        {Object.keys(sharingGroupsGroupedByOwner).map(groupOwnerId => {
          const sharingGroupsList = sharingGroupsGroupedByOwner[groupOwnerId]

          const isCurrentUserTheGroupOwner = groupOwnerId === currentUser.id
          const groupsOwnerDisplayName = isCurrentUserTheGroupOwner
            ? 'You'
            : getUserDisplayName(sharingGroupsList[0].owner)

          return (
            <div key={groupOwnerId}>
              <div className={classes.headerRoot}>
                <Typography className={classes.headerText}>
                  {groupsOwnerDisplayName} shared this message with
                </Typography>
                {canUserShareMessage && <ShareIndividualMessageButton message={message} onClick={props.onClose} />}
              </div>
              <div className={classes.groupsListRoot}>
                {sharingGroupsList.map(sharingGroup => (
                  <MessageSharingGroupBlock
                    key={sharingGroup.id}
                    message={message}
                    sharingGroup={sharingGroup}
                    canRemoveAccess={isCurrentUserTheGroupOwner}
                    onStopSharingMessage={props.onClose}
                  />
                ))}
              </div>
            </div>
          )
        })}
      </div>
    </Popover>
  )
}
