import React from 'react'
import ErrorScreen from 'component/errorScreen'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useNotFoundPageStyles = makeStyles((theme: Theme) => ({
  notFoundPageContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    justifySelf: 'center',
    margin: 'auto',
  },
}))

interface INotFoundPageProps {
  onActionClick?(): void
}

const NotFoundPage = ({ onActionClick }: INotFoundPageProps) => {
  const classes = useNotFoundPageStyles()
  return (
    <div className={classes.notFoundPageContainer}>
      <ErrorScreen
        title="You lost your way"
        subtitle={`The page you requested
        could not be found`}
        iconMessage="404 Error"
        onActionClick={onActionClick}
      />
    </div>
  )
}

export default NotFoundPage
