import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, useTheme } from '@material-ui/core'
import { css } from '@emotion/core'
import BeatLoader from 'react-spinners/BeatLoader'

const useStyles = makeStyles((theme: Theme) => ({
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 3,
    marginBottom: 3,
  },
}))

const override = css`
  margin: 0 0 0 0;
  border-color: red;
`

interface IBeatProgressProps {}

const BeatProgress = (props: IBeatProgressProps) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.progressContainer}>
      <BeatLoader size={4} color={theme.threadPalette.lightGray} css={override} />
    </div>
  )
}

export default BeatProgress
