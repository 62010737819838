import React from 'react'
import PageLayout from '../../pageLayout'
import { ProjectsHomePageContent } from '../projectsHomePage/projectsHomePageContent'
import { BasePageHeader } from 'page/baseHeader'

export const ProjectsHomePage = () => {
  return (
    <PageLayout leftSidebarOption="projects" headerContent={<BasePageHeader title="My Projects" />}>
      <ProjectsHomePageContent />
    </PageLayout>
  )
}
