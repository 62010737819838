import React from 'react'
import { Theme, Typography, makeStyles } from '@material-ui/core'

const useOnboardingStepTextLinesStyles = makeStyles<Theme, { maxWidth?: number; lineHeight?: number }>(
  (theme: Theme) => ({
    onboardingStepTextLinesRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: 14,
      maxWidth: ({ maxWidth }) => maxWidth ?? 'unset',
    },
    textLine: {
      color: theme.threadPalette.menuItem.current,
      fontSize: 16,
      lineHeight: ({ lineHeight }) => lineHeight ?? 1.5,
      textAlign: 'center',
    },
  })
)

interface IOnboardingStepTextLinesProps {
  textLines: JSX.Element[]
  maxWidth?: number
  lineHeight?: number
}

export const OnboardingStepTextLines = ({ textLines, maxWidth, lineHeight }: IOnboardingStepTextLinesProps) => {
  const classes = useOnboardingStepTextLinesStyles({ maxWidth, lineHeight })

  return (
    <div className={classes.onboardingStepTextLinesRoot}>
      {textLines.map((textLine, index) => (
        <Typography key={index} className={classes.textLine}>
          {textLine}
        </Typography>
      ))}
    </div>
  )
}
