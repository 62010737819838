import * as React from 'react'
import { useInfiniteQuery, useQueryClient, InfiniteData } from '@tanstack/react-query'
import { loadThreads } from 'api/threads'
import { PaginatedResponse } from 'api/pagedResponse'
import { useUser } from 'hooks/auth'
import { getNextPageNumber } from 'lib/api'

interface IUseThreadListProps {
  pageSize: number
}

interface ThreadsListFilters {
  filterBy?: TThreadsFilterBy[]
  pageSize?: number
}

export type TThreadsListData = InfiniteData<PaginatedResponse<IThread[]>>

export const getThreadsListQueryKey = (userId: string, filters: ThreadsListFilters = {}) => [
  userId,
  'threads',
  { type: 'list', ...filters },
]

interface IGetThreadsListQueryFunctionProps {
  filterBy: TThreadsFilterBy[]
  pageNumber: number
  pageSize: number
  signal?: AbortSignal
}

export function useThreadsList({ pageSize = 20 }: IUseThreadListProps) {
  const [filterBy, setFilterBy] = React.useState<TThreadsFilterBy[]>([])
  const currentUser = useUser()

  const {
    data: threadsPages,
    fetchNextPage,
    isInitialLoading,
    isFetchingNextPage,
  } = useInfiniteQuery({
    enabled: !!currentUser?.id,
    queryKey: getThreadsListQueryKey(currentUser?.id ?? '', { pageSize, filterBy }),
    queryFn: ({ pageParam, signal }) => loadThreads({ filterBy, pageNumber: pageParam ?? 1, pageSize, signal }),
    getNextPageParam: lastPage => getNextPageNumber(lastPage),
  })

  const changeFilterBy = (newFilterBy: TThreadsFilterBy[]) => {
    setFilterBy(newFilterBy)
  }

  const handleScrollEvent = React.useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      if (isFetchingNextPage) return
      const target = event.currentTarget
      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        fetchNextPage()
      }
    },
    [fetchNextPage, isFetchingNextPage]
  )

  const threadsList: IThread[] = threadsPages?.pages?.flatMap(page => page.data) ?? []

  return {
    threadsList,
    changeFilterBy,
    handleScrollEvent,
    isLoading: isInitialLoading,
    isFetchingNextPage,
  }
}

export const usePrefetchThreadsList = () => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const prefetchThreadsList = React.useCallback(
    ({ filterBy, pageSize }: Pick<IGetThreadsListQueryFunctionProps, 'filterBy' | 'pageSize'>) => {
      if (!currentUser?.id) return

      queryClient.prefetchInfiniteQuery({
        queryKey: getThreadsListQueryKey(currentUser.id, { pageSize, filterBy }),
        queryFn: () => loadThreads({ filterBy, pageNumber: 1, pageSize }),
      })
    },
    [queryClient, currentUser?.id]
  )

  return { prefetchThreadsList }
}
