import { getUserSharingGroups } from 'api/v2/sharingGroup'
import { useQuery } from '@tanstack/react-query'
import { useUser } from 'hooks/auth'

const getUserSharingGroupsQueryKey = (userId: string) => [userId, 'sharingGroups']
const TEN_MINUTE_IN_MILISECONDS = 10 * 60 * 1000

export function useUserSharingGroups() {
  const currentUser = useUser()

  const { data: userSharingGroups = [], isLoading } = useQuery({
    enabled: !!currentUser?.id,
    queryKey: getUserSharingGroupsQueryKey(currentUser?.id ?? ''),
    queryFn: getUserSharingGroups,
    staleTime: TEN_MINUTE_IN_MILISECONDS,
  })

  return {
    userSharingGroups,
    isLoading,
  }
}
