import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { useBoolean } from 'hooks/useBoolean'
import classNames from 'classnames'

const useProjectArchivedStateBadgeStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.threadPalette.whiteSmoke,
      border: `solid 0.5px ${theme.threadPalette.lightSoftGray}`,
      borderRadius: 4,
      color: theme.threadPalette.brownishGray,
      display: 'flex',
      fontSize: 12,
      fontWeight: 500,
      paddingBlock: 0,
      paddingInline: 3,
      width: 68,
      '&:hover': {
        backgroundColor: theme.threadPalette.pink100,
        borderColor: theme.threadPalette.vermilion,
        color: theme.threadPalette.vermilion,
      },
    },
    archived: {
      backgroundColor: theme.threadPalette.lightishGray,
      border: 'none',
      color: theme.threadPalette.lightGray,
      '&:hover': {
        backgroundColor: theme.threadPalette.green,
        color: theme.threadPalette.white,
      },
    },
  })
)

interface IProjectArchivedStateBadge {
  isArchived: boolean
  onClick?(): void
}

export const ProjectArchivedStateBadge = ({ isArchived, onClick }: IProjectArchivedStateBadge) => {
  const classes = useProjectArchivedStateBadgeStyles()

  const { state: isHovering, setTrue: setStartedHovering, setFalse: setStoppedHovering } = useBoolean(false)

  const buttonLabel = React.useMemo(() => {
    if (isArchived) return isHovering ? 'Activate' : 'Archived'

    return isHovering ? 'Archive' : 'Active'
  }, [isArchived, isHovering])

  const onButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    onClick?.()
  }

  return (
    <Button
      classes={{ root: classNames(classes.buttonRoot, { [classes.archived]: isArchived }) }}
      onMouseEnter={setStartedHovering}
      onMouseLeave={setStoppedHovering}
      onClick={onButtonClick}
    >
      {buttonLabel}
    </Button>
  )
}
