import * as React from 'react'

interface IErrorBearsIconProps {
  text: string
  isTextHighlighted?: boolean
}

const ErrorBearsIcon = ({ text, isTextHighlighted = false }: IErrorBearsIconProps) => {
  const fontSize = isTextHighlighted ? 17 : 18
  const textColor = isTextHighlighted ? '#ff0000' : '#231F20'
  const textWeight = isTextHighlighted ? 'bold' : 'normal'
  return (
    <svg width="268" height="238" viewBox="0 0 268 238" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <ellipse
          cx="132.556"
          cy="17.813"
          rx="132.556"
          ry="17.813"
          transform="translate(2.888 202.374)"
          fill="#E5E5E5"
          fillRule="nonzero"
        />
        <path
          d="m158.1 221.628-7.043.588-21.524-219.86a1.216 1.216 0 0 1 .592-1.146c.408-.253.932-.28 1.367-.068l4.298 2.107c.614.302 1.023.87 1.09 1.512l21.22 216.867z"
          fill="#F47823"
          fillRule="nonzero"
        />
        <path
          d="m146.151 88.46 13.063 133.403-7.05.596L130.647 2.606c-.044-.451.182-.89.588-1.14.407-.252.93-.277 1.362-.066l4.298 2.107c.615.3 1.023.87 1.081 1.512l7.16 73.188M145.636 83.181l.304 3.117M135.604 9.437l.127 1.23M134.937 2.544l.38 3.948"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m63.329 23.673 144.052 37.663a1.47 1.47 0 0 1 1.035 1.85 1.59 1.59 0 0 1-1.923 1.075L62.44 26.598a1.47 1.47 0 0 1-1.034-1.85 1.59 1.59 0 0 1 1.923-1.075z"
          fill="#F47823"
          fillRule="nonzero"
        />
        <path
          d="M205.22 20.194 65.766 70.528a1.616 1.616 0 0 1-2.039-.894 1.46 1.46 0 0 1 .851-1.938l139.454-50.334a1.616 1.616 0 0 1 2.038.895 1.46 1.46 0 0 1-.85 1.937z"
          fill="#F47823"
          fillRule="nonzero"
        />
        <path
          d="m64.43 23.954 144.052 37.663a1.47 1.47 0 0 1 1.035 1.85 1.59 1.59 0 0 1-1.924 1.076L63.541 26.879a1.47 1.47 0 0 1-1.035-1.849 1.59 1.59 0 0 1 1.924-1.076z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M206.316 20.473 66.862 70.807a1.616 1.616 0 0 1-2.039-.895 1.46 1.46 0 0 1 .851-1.937L205.128 17.64a1.616 1.616 0 0 1 2.038.895 1.46 1.46 0 0 1-.85 1.938z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.46 67.506c3.95-13.275 3.36-27.36-1.689-40.31a.84.84 0 0 1 .137-.848.995.995 0 0 1 .843-.351 588.851 588.851 0 0 0 134.743-6.032 1.01 1.01 0 0 1 .876.269c.223.221.31.533.23.828-3.482 13.335-3.032 27.294 1.3 40.412.096.287.03.6-.176.831a1 1 0 0 1-.845.32 499.237 499.237 0 0 0-134.313 6.001 1.017 1.017 0 0 1-.884-.278.854.854 0 0 1-.222-.842z"
          fill="#E5E5E5"
          fillRule="nonzero"
        />
        <path
          d="M68.414 67.443c3.953-13.274 3.363-27.36-1.688-40.31a.84.84 0 0 1 .136-.848.995.995 0 0 1 .843-.35 588.951 588.951 0 0 0 134.752-6.032c.32-.052.647.05.869.272a.848.848 0 0 1 .228.824c-3.481 13.335-3.032 27.294 1.3 40.413a.85.85 0 0 1-.174.829 1.007 1.007 0 0 1-.839.33A498.9 498.9 0 0 0 69.58 68.564a1.022 1.022 0 0 1-.93-.25.856.856 0 0 1-.236-.87z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m67.805 66.636 3.183 2.681a.651.651 0 0 1 .046.954.756.756 0 0 1-1.028.05l-3.183-2.682a.651.651 0 0 1-.046-.954.756.756 0 0 1 1.028-.05zM69.302 65.835l3.183 2.681a.651.651 0 0 1 .046.954.756.756 0 0 1-1.028.05l-3.183-2.682a.651.651 0 0 1-.046-.954.756.756 0 0 1 1.028-.05zM69.494 25.485l-3.191 2.674a.756.756 0 0 1-1.028-.052.651.651 0 0 1 .048-.953l3.191-2.675a.756.756 0 0 1 1.028.052.651.651 0 0 1-.048.954zM71.13 26.175l-3.19 2.674a.756.756 0 0 1-1.028-.051.651.651 0 0 1 .048-.954l3.19-2.675a.756.756 0 0 1 1.028.052.651.651 0 0 1-.048.954zM207.77 61.336l-2.876 2.938a.762.762 0 0 1-1.036.05.656.656 0 0 1-.046-.96l2.877-2.94a.762.762 0 0 1 1.035-.05.656.656 0 0 1 .046.962zM206.2 60.665l-2.877 2.94a.762.762 0 0 1-1.036.049.656.656 0 0 1-.046-.96l2.877-2.94a.762.762 0 0 1 1.035-.05.656.656 0 0 1 .046.961zM201.605 18.522l3.47 2.389a.648.648 0 0 1 .139.939.749.749 0 0 1-1.01.149l-3.47-2.389a.648.648 0 0 1-.138-.94.749.749 0 0 1 1.01-.148zM200.052 19.356l3.47 2.389a.648.648 0 0 1 .139.939.749.749 0 0 1-1.01.149l-3.47-2.389a.648.648 0 0 1-.138-.94.749.749 0 0 1 1.01-.148z"
          stroke="#231F20"
          fill="#4B5A69"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <text
          x="132"
          y="57"
          fill={textColor}
          textAnchor="middle"
          fontSize={fontSize}
          fontWeight={textWeight}
          transform="rotate(-3)"
        >
          {text}
        </text>
        <g fillRule="nonzero">
          <path
            d="M196.723 110.739s-4.281-4.332.287-8.523 11.771 3.055 11.771 3.055l-12.058 5.468zM228.025 110.065s8.749-3.486 10.437 1.097c2.762 7.575-5.75 11.366-5.75 11.366l-4.687-12.463zM236.453 148.284s3.732 48.066-15.335 47c-11.12-.61 2.027-25.286 0-45.433"
            fill="#F47823"
          />
          <path
            d="M229.588 188.156c-1.74 14.672-4.704 19.826-6.198 24.378 7.414 1.425 15.25 7.872 9.381 10.277-5.869 2.405-27.866 2.013-30.998-.266-3.133-2.28-3.125-14.335-3.125-25.498-3.825 11.17-11.205 22.45-11.205 22.45l-18.054.518s-14.7-43.758-9.33-62.378c5.37-18.62 23.559-29.32 23.559-29.32l44.154 11.076 1.816 48.763z"
            fill="#F47823"
          />
          <path
            d="M169.955 135.9s5.067.11 8.774 2.694a57.753 57.753 0 0 0-2.652 8.868s4.349-5.405 14.043-4.661c9.694.744 18.12 11.89 27.764 11.452 9.643-.439 17.344-3.447 20.08 0 1.925-6.392-2.069-10.896-2.702-18.612-.633-7.716 2.922-20.986-3.774-26.109-4.4-3.36-3.59-4.151-5.422-5.945-1.832-1.794-5.91-2.295-10.318-1.747a36.511 36.511 0 0 0-13.41 4.105c-20.215 10.872-32.383 29.955-32.383 29.955z"
            fill="#9BC53E"
          />
          <path
            d="M214.97 104.683c-6.704.416-14.059 5.116-17.681 10.474-5.413 7.982-1.233 18.212 9.339 19.426 6.477.745 7.16 1.794 10.049 2.35 6.409 1.191 13.94-6.368 14.532-14.28.355-4.7-.515-10.058-4.307-12.572a30.29 30.29 0 0 1-4.889-4.066c-.946-.9-2.87-1.582-7.042-1.332z"
            fill="#F47823"
          />
        </g>
        <g transform="translate(157.73 100.91)">
          <path
            d="M62.366 57.699c0-.462.05-.917.067-1.379M57.882 91.21c-1.022-6.18 3.065-18.306 4.264-30.55M77.852 54.824c.178 9.964-.447 28.85-7.295 36.864M64.992 111.303a77.342 77.342 0 0 0 4.906-17.32M42.446 121.063c.735 4.7 25.577 4.27 31.446 1.865 5.869-2.405-2.238-10.623-14.355-10.623M9.328 42.322c-3.722 4.635-6.485 9.87-8.148 15.44-5.37 18.635 9.339 62.377 9.339 62.377l18.053-.525s7.372-11.28 11.206-22.443M11.313 35.906s5.066.102 8.781 2.687a56.748 56.748 0 0 0-2.651 8.868s4.34-5.405 14.034-4.661c9.694.744 18.121 11.89 27.764 11.452 9.644-.438 17.353-3.447 20.08 0 1.926-6.384-2.06-10.896-2.693-18.604-.633-7.708 2.922-20.994-3.783-26.109-4.4-3.368-3.58-4.16-5.421-5.953-1.841-1.794-5.911-2.295-10.319-1.747a36.457 36.457 0 0 0-13.4 4.105C23.48 16.816 11.312 35.906 11.312 35.906zM37.709 7.886c-.752-1.653-1.09-4.136 1.57-6.58 2.855-2.616 6.756-.783 9.247.987"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M72.76 8.388c2.72-.674 6.866-1.144 7.963 1.864 1.57 4.308-.506 7.395-2.592 9.251M52.545 4.698c-5.32 1.81-9.873 5.157-12.987 9.549-5.413 7.982-1.233 18.213 9.34 19.427 6.476.744 7.16 1.794 10.048 2.35 6.409 1.19 13.941-6.369 14.532-14.28.254-3.337-.11-6.964-1.689-9.698M30.067 18.43a23.88 23.88 0 0 1 1.74-2.17M20.778 36.572a104.569 104.569 0 0 1 7.6-15.8"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M54.606 24.932c3.023 1.026 4.889 1.245 5.598.266 1.689-2.35-.583-4.144-3.63-5.256-2.855-1.042-5.194-.165-5.802 1.935-.44 1.535 3.834 3.055 3.834 3.055z"
            stroke="#231F20"
            fill="#4B5A69"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <ellipse
            stroke="#231F20"
            fill="#1E2D44"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="50.654"
            cy="16.362"
            rx="2.103"
            ry="1.951"
          />
          <ellipse
            stroke="#231F20"
            fill="#1E2D44"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="63.784"
            cy="20.772"
            rx="2.086"
            ry="1.935"
          />
          <path
            d="M52.908 24.188s-.743-1.959.625-2.609c1.368-.65.515 1.387.515 1.387M57.257 25.676s1.689-.877 1.419-1.904c-.338-1.284-1.31.243-1.31.243"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.667 16.785c.162-.53-.094-1.093-.616-1.355M64.78 21.234s.44-.924-.362-1.331"
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M61.53 16.856c2.82 0 5.961-.98 8.165.932M58.49 15.077c.175.9.957 1.59 1.934 1.708M48.543 10.918s4.77.697 8.005 3.54"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M59.36 29.193c-3.724-1.52-7.203-2.655-9.289-1.23-2.702 1.825 5.328.22 9.289 1.23z"
            stroke="#231F20"
            fill="#1E2D44"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M73.242 9.868s3.2-1.065 5.016-.039M77.354 10.918a2.956 2.956 0 0 1 1.934.69M47.09 4.361s-2.533-3.337-6.147-2.28M41.357 3.077a4.471 4.471 0 0 0-2.533.642M38.41 5.693c.114-.568.545-1.036 1.131-1.23"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.025 121.071c.938 2.836 29.26 2.797 29.26-.188 0-3.533-3.42-9.604-12.718-9.604-8.292 0-17.952 5.523-16.542 9.792z"
            fill="#F47823"
            fillRule="nonzero"
          />
          <path
            d="M2.885 122.105c.93 2.836 29.25 2.797 29.25-.188 0-3.533-3.42-9.612-12.716-9.612-8.292 0-17.944 5.53-16.534 9.8zM69.383 90.873a13.275 13.275 0 0 1-2.33 4.943M66.588 93.983c-.431 1.003-1.242 1.824-2.28 2.311M63.176 95.714a1.971 1.971 0 0 1-1.03.557M59.005 90.677a5.685 5.685 0 0 0 0 3.306M60.289 93.012s-.152 1.802.405 2.538M53.508 5.45c.893-1.152 2.24-1.932 3.75-2.17M57.088 4.463a6.056 6.056 0 0 1 3.47-.713c2.103.165 2.754 1.52 4.78 1.167M63.404 6.155a5.996 5.996 0 0 0 2.635 0M70.295 11.96a7.805 7.805 0 0 0-1.056-3.33M67.677 8.881c-.102-.701-.527-1.327-1.165-1.715M50.654 36.83a8.612 8.612 0 0 1 5.108.635c2.534 1.207 4.172 2.28 8.335.415M49.227 39.847c-.228.11-.456.227-.684.36M54.04 39.314a8.456 8.456 0 0 0-3.606.14M40.614 99.185a11.523 11.523 0 0 0-1.461 3.133M40.588 102.764a6.785 6.785 0 0 0-1.19 3.549c0 1.269.532 2.984-.228 4.457M40.504 110.739s-.346 2.765-1.25 3.964M40.352 115.368c-.09.437-.273.853-.54 1.222M41.154 116.966a7.888 7.888 0 0 0-.33 4.7M38.646 111.945a2.31 2.31 0 0 1-.515.854"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M155.811 117.71s-11.079 63.506 60.266 92.67c.724-.59 1.11-1.459 1.047-2.35-63.871-34.906-61.313-90.32-61.313-90.32z"
            fill="#E5E5E5"
            fillRule="nonzero"
          />
          <path
            d="M156.706 117.24s-11.13 63.506 60.266 92.7c.724-.589 1.11-1.458 1.047-2.35-63.871-34.96-61.313-90.35-61.313-90.35z"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M215.199 208.311c.334-.244.578-.577.7-.955M205.277 190.882l-42-53.675M207.211 193.357l-.693-.877M213.13 200.916l-5.227-6.666M216.23 204.888l-1.03-1.324"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g transform="translate(3.521 101.221)">
          <path
            d="M74.604 3.376s4.813-6.353 10.901-1.63c6.089 4.724-2.195 11.696-2.195 11.696L74.604 3.376zM54.237 4.418c.54.783-5.683-3.408-10.015.14-4.484 3.69-1.241 8.688 1.554 11.21 2.795 2.523 8.46-11.35 8.46-11.35zM35.398 60.419S50.352 43.585 58.39 50.024C66.43 56.463 45.294 86.277 33.827 75.6c-8.799-8.186-2.533-36.182 4.602-39.167a18.883 18.883 0 0 0 3.809-2.092l8.292 22.341"
            fill="#95B1C9"
            fillRule="nonzero"
          />
          <path
            d="M35.66 74.738s-.212 20.32 8.09 34.945c-9.965 2.178-16.965 8.703-12.44 10.544 4.527 1.84 29.277 1.151 31.531 0 2.255-1.152 6.4-20.007 6.933-27.072.971 8.397 4.577 19.035 6.865 22.92 8.157-.658 18.45-.658 18.45-.658s7.034-20.273 7.28-35.681c.244-15.408 1.485-34.232-7.482-42.081-8.968-7.849-32.637-8.766-32.637-8.766l-22.242 8.852-4.348 36.997z"
            fill="#95B1C9"
            fillRule="nonzero"
          />
          <path
            d="M67.021.305c-5.977-.31-11.771 1.966-15.69 6.165-4.964 5.17-7.97 14.978-9.617 18.276-2.322 4.653-5.311 11.609-11.24 19.332 8.963 1.37 17.198 5.42 23.45 11.531 2.855-6.24 7.146-11.825 12.574-16.364 4.648 4.703 8.58 9.974 11.686 15.667 5.561-6.176 13.706-9.849 22.377-10.09-2.575-7.535-7.33-13.825-9.423-17.836-2.534-4.786-2.753-10.12-7.685-17.782C79.835 3.962 73.721.651 67.021.305z"
            fill="#F47823"
            fillRule="nonzero"
          />
          <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.545 25.983 1.58-.556M0 29.007l6.646-2.35M1.85 16.333l.886 2.318-1.562 1.865-.05 1.919M.346 25.646l.549-1.504M20.849 2.358l-2.06 3.117 1.283.235M20.73 7.167l.279 1.121M20.637 9.948l-2.423 5.742"
          />
          <path
            d="M65.079 3.87a61.275 61.275 0 0 1-5.826 1.7 13.089 13.089 0 0 0-5.388 2.114c-6.89 5.225-10.884 16.678-5.353 23.085 8.106 9.4 24.226 9.745 32.417-.25 2.356-2.875 2.153-7.207 1.35-11.492-.945-5.045-1.182-9.4-5.665-10.92-6.57-2.232-6.165-5.702-11.535-4.237z"
            fill="#95B1C9"
            fillRule="nonzero"
          />
          <path
            d="M65.383 23.516c3.209-.243 4.56-.635 4.687-1.81.008-1.218-.552-2.381-1.536-3.189-.984-.807-2.296-1.181-3.599-1.025-3.377.29-4.914 3.133-4.424 4.7.49 1.567 4.872 1.324 4.872 1.324z"
            fill="#E5E5E5"
            fillRule="nonzero"
          />
          <path
            d="M63.306 23.375s-1.478-1.081-.532-2.21c.945-1.127 1.097 1.058 1.097 1.058"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M59.658 15.682c-.055-.55-.516-.99-1.106-1.057M72.738 15.502s0-1.01-.845-1.12"
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <ellipse
            stroke="#231F20"
            fill="#1E2D44"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="58.552"
            cy="15.635"
            rx="2.103"
            ry="1.951"
          />
          <ellipse
            stroke="#231F20"
            fill="#1E2D44"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="71.623"
            cy="15.377"
            rx="2.086"
            ry="1.935"
          />
          <path
            d="M53.114 13.293a9.828 9.828 0 0 1 4.39-2.499c3.226-.634 4.763 1.066 4.763 1.066M67.747 11.382s1.132-.83 3.885-.4c1.641.256 3.151.995 4.306 2.107M81.74 4.37a3.962 3.962 0 0 1 2.532-.234M45.362 7.238a2.91 2.91 0 0 0-1.52.697"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M67.266 38.619c.058.862-.649 1.606-1.58 1.66-.911 0-2.431-.211-2.431-1.057.096-.973.887-1.764 1.925-1.927.878 0 2.086.478 2.086 1.324z"
            fill="#F47823"
            fillRule="nonzero"
          />
          <path
            d="M63.34 38.783a4.022 4.022 0 0 0-2.736 0c-1.444.548-1.69 1.966-3.767 2.17M63.12 39.48s-4.78 2.789-4.644 7.293c.135 4.504 2.955-2.969 3.63-4.857a4.917 4.917 0 0 1 1.42-2.13M67.266 38.619c2.217.55 4.136 1.842 5.387 3.626 2.018 2.961-1.283 7.27-2.465 5.398-1.183-1.873-.955-5.076-1.58-6.69a3.908 3.908 0 0 0-1.384-1.95M42.643 26.469a52.344 52.344 0 0 1-3.428 8.695M43.825 22.004s-.177.846-.54 2.185M51.079 31.749c.279 1.947 1.374 3.715 3.048 4.919M54.127 33.253s.65 3.188 3.335 4.66M59.033 36.363c.3.631.781 1.174 1.393 1.566M70.196 36.582a4.594 4.594 0 0 1-1.3 1.426M80.253 29.532s-1.131 4.042-2.575 5.82M77.39 32.164s-.97 3.25-5.91 5.679"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M72.24 195.37c.962 8.398 4.576 19.036 6.865 22.921 8.157-.658 18.442-.658 18.442-.658s7.076-20.273 7.304-35.681c.169-11.515.912-24.926-2.457-34.255M51.594 211.79c-13.182-.635-22.344 8.82-17.817 10.653 4.526 1.833 29.275 1.151 31.53 0M38.277 181.27c.389 6.338 1.799 18.503 6.874 28.64"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M106.058 220.704c-.937 2.836-29.25 2.796-29.25-.188 0-3.533 3.42-9.612 12.717-9.612 8.292 0 17.944 5.53 16.533 9.8z"
            fill="#95B1C9"
            fillRule="nonzero"
          />
          <path
            d="M105.045 221.69c-.937 2.837-29.25 2.797-29.25-.187 0-3.533 3.377-9.604 12.716-9.604 8.284.008 17.936 5.53 16.534 9.792zM84.678 132.727c2.069-3.4 1.925-8.194 1.123-12.479-.946-5.044-1.182-9.4-5.666-10.92-6.57-2.232-6.164-5.702-11.535-4.237a61.275 61.275 0 0 1-5.826 1.7 13.089 13.089 0 0 0-5.388 2.115c-6.89 5.224-10.31 16.144-6.307 23.5"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M68.904 124.737c3.209-.243 4.56-.635 4.687-1.81.008-1.218-.552-2.38-1.536-3.189-.983-.807-2.295-1.181-3.598-1.025-3.378.29-4.915 3.133-4.425 4.7.49 1.567 4.872 1.324 4.872 1.324z"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M66.827 124.596s-1.478-1.081-.532-2.21c.946-1.127 1.098 1.058 1.098 1.058M71.142 124.4s1.005-1.19.295-2.013c-.844-1.042-1.072.72-1.072.72"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M63.179 116.904c-.055-.55-.515-.99-1.106-1.058M76.259 116.723s0-1.01-.844-1.12"
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M56.635 114.514a9.828 9.828 0 0 1 4.39-2.499c3.226-.634 4.763 1.066 4.763 1.066M71.269 112.603s1.131-.83 3.884-.4c1.642.257 3.151.995 4.306 2.108M64.437 128.097c2.297.917 6.604-1.621 9.002-1.19"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M76.867 150.125a67.995 67.995 0 0 1 3.665 5.961c5.557-6.18 13.705-9.853 22.377-10.09-2.584-7.535-7.338-13.825-9.432-17.836-2.491-4.786-2.753-10.12-7.676-17.782-3.624-5.242-9.738-8.555-16.44-8.906-5.975-.311-11.767 1.966-15.682 6.165-4.965 5.17-7.98 14.977-9.617 18.275-2.331 4.653-5.312 11.609-11.248 19.333 8.964 1.365 17.2 5.415 23.45 11.53"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M79.426 103.266c1.832-1.567 5.42-3.549 9.6-.298 4.028 3.133 1.757 7.246-.236 9.666M54.93 104.77c-2.061-.572-4.882-.862-7.17 1.01-3.454 2.844-2.322 6.455-.346 9.118"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M80.802 105.224c.511-.92 1.385-1.622 2.44-1.958M82.06 106.11c.44-.596 1.832-2.218 3.994-2.116M85.26 105.592a3.962 3.962 0 0 1 2.534-.235M54.507 106.657a5.648 5.648 0 0 0-2.829-.712M53.41 107.707a5.977 5.977 0 0 0-5.067-.25M48.883 108.46a2.91 2.91 0 0 0-1.52.696"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M70.787 139.84c.058.863-.649 1.606-1.579 1.66-.912 0-2.432-.211-2.432-1.057.097-.973.887-1.764 1.925-1.927.879 0 2.086.478 2.086 1.324z"
            stroke="#231F20"
            fill="#F47823"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M66.86 140.004a4.022 4.022 0 0 0-2.735 0c-1.444.549-1.69 1.966-3.766 2.17M66.641 140.701s-4.78 2.79-4.644 7.293c.135 4.504 2.955-2.969 3.63-4.856a4.917 4.917 0 0 1 1.42-2.131M70.787 139.84c2.217.55 4.136 1.842 5.388 3.627 2.018 2.96-1.284 7.269-2.466 5.397-1.182-1.872-.954-5.076-1.58-6.69a3.908 3.908 0 0 0-1.384-1.95M69.833 150.156a2.77 2.77 0 0 0 .6 2.021"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M70.585 140.63c1.197.977 1.589 2.554.97 3.918a40.211 40.211 0 0 0-1.51 4.183M93.054 139.135c0 .423-.05.861-.084 1.308M87.718 131.967a9.185 9.185 0 0 0 1.148-2.217c.524-1.566.042-3.971 1.03-3.462.988.51 3.133 4.7 3.184 11.296"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.143 124.673a2.965 2.965 0 0 1 4.031-.123l105.629 92.179a2.552 2.552 0 0 1 .114 3.74 2.965 2.965 0 0 1-4.031.123l-105.629-92.18a2.552 2.552 0 0 1-.114-3.739z"
            fill="#F47823"
            fillRule="nonzero"
          />
          <path
            d="M22.915 124.178a.84.84 0 0 1 .038 1.232l-4.102 4.046a.977.977 0 0 1-1.328.04.84.84 0 0 1-.038-1.232l4.102-4.045a.977.977 0 0 1 1.328-.04z"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path
            d="M21.735 122.843a.84.84 0 0 1 .037 1.232l-4.102 4.045a.977.977 0 0 1-1.328.04.84.84 0 0 1-.037-1.231l4.102-4.046a.977.977 0 0 1 1.328-.04zM22.637 126.615a.898.898 0 0 1 .6 1.149l-1.947 5.782a.978.978 0 0 1-1.2.627.898.898 0 0 1-.6-1.149l1.948-5.782a.978.978 0 0 1 1.199-.627z"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path
            d="M30.542 130.816a.873.873 0 0 1-.896.846l-8.087-.219a.866.866 0 0 1-.841-.893.873.873 0 0 1 .896-.845l8.086.219a.866.866 0 0 1 .842.892z"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path
            d="M17.768 124.733a2.965 2.965 0 0 1 4.03-.123l105.63 92.179a2.552 2.552 0 0 1 .113 3.74 2.965 2.965 0 0 1-4.03.122L17.88 128.473a2.552 2.552 0 0 1-.113-3.74z"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fill="#E5E5E5"
            fillRule="nonzero"
            d="m0 131.035 2.888.47 15.402-5.734 7.17-16.677-.828-8.5-21.795 15.377z"
          />
          <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m1.267 131.513 2.896.478 15.394-5.734 7.177-16.678-.836-8.507-21.786 15.385zM12.067 127.204l1.579-.556M3.521 130.228l6.646-2.35"
          />
          <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m5.37 117.554.887 2.318-1.562 1.865-.05 1.919M3.867 126.867l.549-1.504M24.37 103.579l-2.06 3.118 1.283.235M24.252 108.388l.278 1.12M24.159 111.17l-2.424 5.741"
          />
          <path
            d="M1.944 121.594a.884.884 0 0 1 .965-.78l18.345 1.8a.857.857 0 0 1 .768.95.884.884 0 0 1-.965.78l-18.346-1.8a.857.857 0 0 1-.767-.95z"
            stroke="#231F20"
            fill="#4B5A69"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.867 109.777a.971.971 0 0 1 1.185.635l5.463 16.838a.894.894 0 0 1-.61 1.137.971.971 0 0 1-1.185-.636l-5.463-16.838a.894.894 0 0 1 .61-1.136z"
            stroke="#231F20"
            fill="#4B5A69"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.735 122.843a.84.84 0 0 1 .037 1.232l-4.102 4.045a.977.977 0 0 1-1.328.04.84.84 0 0 1-.037-1.231l4.102-4.046a.977.977 0 0 1 1.328-.04zM20.739 130.573h0a.843.843 0 0 1 .292-.608.98.98 0 0 1 .67-.238l1.69.047 6.29.172c.25.004.487.101.658.27a.827.827 0 0 1 .254.623h0c-.018.477-.448.852-.963.838l-6.552-.172-1.393-.04a.976.976 0 0 1-.678-.26.837.837 0 0 1-.268-.632z"
            stroke="#231F20"
            fill="#4B5A69"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.637 126.615a.898.898 0 0 1 .6 1.149l-1.947 5.782a.978.978 0 0 1-1.2.627.898.898 0 0 1-.6-1.149l1.948-5.782a.978.978 0 0 1 1.199-.627z"
            stroke="#231F20"
            strokeWidth="1.006"
            fill="#4B5A69"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.915 124.178a.84.84 0 0 1 .038 1.232l-4.102 4.046a.977.977 0 0 1-1.328.04.84.84 0 0 1-.038-1.232l4.102-4.045a.977.977 0 0 1 1.328-.04z"
            stroke="#231F20"
            fill="#4B5A69"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M38.919 161.64s14.955-16.834 22.993-10.395c8.04 6.44-13.096 36.253-24.564 25.576-8.798-8.186 1.571-15.181 1.571-15.181"
            fill="#95B1C9"
            fillRule="nonzero"
          />
          <path
            d="M62.495 158.593c-1.824 10.606-17.049 27.714-26.177 19.23-6.046-5.631-4.973-20.624-1.401-30.416"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.747 160.708c3.377-3.33 10.64-9.815 16.668-9.933M37.897 162.635l.439-.478"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M102.03 154.95s-34.984 18.542-25.028 29.14c7.853 8.39 29.614-13.794 29.614-13.794l-4.585-15.346z"
            fill="#95B1C9"
            fillRule="nonzero"
          />
          <path
            d="M81.63 186.942c9.6-.995 23.964-15.667 23.964-15.667l-4.585-15.353s-24.81 13.152-26.692 23.64M51.982 130.44s0 3.07 1.689 5.068M71.083 198.081c.689 2.162.862 4.436.506 6.666M70.053 204.348c.407 2.364.571 4.759.49 7.152M68.735 211.084c.219.886.357 1.788.414 2.695M67.89 214.202a13.88 13.88 0 0 1-.396 6.322M66.1 220.524c.225.432.36.9.398 1.378"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M75.634 179.226a9.222 9.222 0 0 0-1.435 3.917M75.288 182.994a3.607 3.607 0 0 0-.372 2.632M81.292 185.994c-.82.5-1.743.839-2.71.995M77.55 185.994s-.244.392-1.477.501M61.786 157.05s.582-.94.312-3.275M60.984 153.948a5.846 5.846 0 0 0-.338-2.35M59.447 152.757c-.051-.922-.528-1.778-1.31-2.35"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g transform="translate(97.544 68.235)">
          <path
            d="M17.803 59.925C11.394 64.555 6.419 70.673 3.38 77.66c-3.825 8.923-7.6 44.69 7.93 51.575 8.637 3.839 4.863-28.2 5.302-45.057"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path
            d="M67.547 144.934c11.898-.611 18.18 3.211 20.215 8.796 2.179 5.962-29.276 5.398-32.45 2.914-3.175-2.483-8.124-16.348-9.61-25.388-1.393 9.15-6.629 22.85-6.629 23.18 0 .328-14.878-2.304-21.27-2.304-4.923-10.081-5.599-25.591-5.599-25.591l1.79-60.317 16.044-5.844 38.117 1.982s7.448 5.185 11.281 10.426c3.429 4.7 5.38 19.388 5.38 19.388l-9.56 8.796.051 3.62c2.888 19.105-1.14 43.867-1.14 43.867"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path
            d="M80.965 93.311s-14.077-10.7-17.682 0c-3.606 10.7 14.904 24.895 26.126 16.278 8.157-6.266-1.689-25.607-12.76-37.827"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path fill="#95B1C9" fillRule="nonzero" d="m71.254 27.12 6.088-8.868L71.887 5.89 71.254 0l-6.722 17.32z" />
          <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M71.727 4.865v2.31l1.292 2.79M74.125 12.173l-.245 1.747 1.393 1.026M67.471 16.364l1.013-1.16v-2.146l.684-1.183M68.324 17.743l1.191 1.261"
          />
          <path
            d="M55.168 67.508c-3.648 1.418-10.133 1.48-9.576 4.802.718 4.324 5.911 8.617 5.616 16.67-.186 4.394-1.132 6.204-.068 9.736.769 2.538 3.192 4.928 4.45 6.158a9.277 9.277 0 0 1-.7-6.4c1.072-3.635 3.951-6.126 5.167-9.205 3.682-9.368-1.233-23.179-4.889-21.76z"
            fill="#2196F3"
            fillRule="nonzero"
          />
          <path
            d="M59.854 70.783c-.557.603-2.989-.376 3.116 1.864 4.037 1.488 2.078 6.07 5.396 7.912-.177-3.917 2.82-4.599 1.647-8.374-.934-3.124-3.757-5.448-7.212-5.938l-2.947 4.536z"
            fill="#2196F3"
            fillRule="nonzero"
          />
          <path
            d="M62.033 60.536c-1.908 2.836-5.168 6.479-10.268 8.304-9.88 3.54-22.993-1.058-31.725-11.962-4.28 1.96-8.16 4.595-11.467 7.787a83.61 83.61 0 0 0 6.756 5.28c8.14 5.741 22.528 15.893 33.303 11.75 2.12-.823 5.86-2.875 8.883-8.852a18.195 18.195 0 0 0 5.717-2.107c3.144-1.832 5.603-4.518 7.043-7.693a25.885 25.885 0 0 1-3.75-5.436c-2.052-4.003-2.17-6.933-3.158-11.17-1.36-5.844-2.17-8.735-3.901-11.077-3.378-4.56-5.506-2.985-10.234-5.977-8.706-5.483-21.702 3.737-22.318 4.222-.05.784 1.148 2.154 1.689 2.006 4.619-1.285 7.414-3.69 9.82-1.567 1.925 1.708 2.39 3.76 3.994 4.003 2.71.423 3.69-1.113 6.52-.846 3.191.297 7.253 3.235 8.528 11.805.498 3.345.304 4.856 1.021 9.274a27.5 27.5 0 0 0 1.444 5.374"
            fill="#2196F3"
            fillRule="nonzero"
          />
          <path
            d="M53.26 35.172c.118-1.92 6.535-6.987 11.061-2.977 4.003 3.549-1.604 11.649-3.377 11.265-1.774-.384-1.926-2.39-3.75-4.12-2.406-2.28-3.994-3.189-3.934-4.168zM21.73 44.572s-2.534 2.757-2.534 8.288c0 0-1.588 7.206 2.533 12.839 3.074 4.206 9.559 7.285 20.9 7.214 13.704-.086 18.577-9.4 18.577-14.225 0-2.593-1.048-4.826-2.45-5.938 0-3.33-.497-4.426-1.165-7.465-.667-3.04-3.58-8.092-7.177-9.18-3.378-1.035-4.028.853-7.22 1.033-3.192.18-3.462-3.478-7.127-3.674-2.972-.156-10.074 1.348-11.973 5.123a47.544 47.544 0 0 0-2.365 5.985z"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path
            d="M28.611 51.708s-7.6-.532-9.381 4.951M20.674 56.659c-1.68 2.579-1.961 5.739-.76 8.538"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.733 46.076s-6.756-1.872-3.817-9.283c2.939-7.41 10.293-.634 10.293-.634"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path
            d="M40.838 56.659c3.226-.07 4.797-.376 4.797-1.567 0-1.801-1.596-4.927-4.864-4.927-3.066 0-4.712 3.306-4.712 4.927 0 1.622 4.78 1.567 4.78 1.567z"
            fill="#F47823"
            fillRule="nonzero"
          />
          <ellipse
            stroke="#231F20"
            fill="#1E2D44"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="33.264"
            cy="49.225"
            rx="2.103"
            ry="1.951"
          />
          <ellipse
            stroke="#231F20"
            fill="#1E2D44"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="48.32"
            cy="49.17"
            rx="2.086"
            ry="1.935"
          />
          <path
            d="M46.648 59.236c-.22 2.46-8.267 2.28-11.628.783 1.139.268 2.315.37 3.488.306 3.428-.47 8.199-1.739 8.14-1.089z"
            stroke="#231F20"
            fill="#1E2D44"
            fillRule="nonzero"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.586 65.197c.002-.972.566-1.869 1.478-2.35M39.707 62.87c-.949 1.106-1.22 2.584-.718 3.918M42.122 62.87a2.561 2.561 0 0 0 0 1.716M44.655 62.487c1.087.51 1.828 1.488 1.976 2.609M47.087 62.056c.695-.108 1.399.141 1.84.65M46.031 45.52s2.002-2.898 3.496-2.021c1.355.949 2.6 2.025 3.716 3.212M27.7 46.405s4.399-3.72 6.256-3.65c1.858.07 2.365 2.765 2.365 2.765M38.744 56.4s-1.41-1.159-.388-2.224c1.021-1.066 1.03 1.112 1.03 1.112M43.616 56.471s1.073-1.128.423-1.998c-.845-1.08-1.115.666-1.115.666"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.37 49.272c-.059-.547-.518-.983-1.106-1.05M49.434 49.272s0-1.003-.844-1.112"
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g transform="translate(96.657 68.979)">
          <path
            d="M16.723 121.723c.928-9.98-.38-25.999-.11-36.339M9.748 68.62A41.069 41.069 0 0 0 3.38 78.875c-3.825 8.915-7.6 44.65 7.92 51.568.645.343 1.42.406 2.12.172M55.312 157.85c3.175 2.492 34.621 3.048 32.451-2.913-2.035-5.578-8.317-9.4-20.215-8.797M12.61 132.635c.6 5.397 2.01 14.186 5.193 20.712 6.393 0 21.271 2.624 21.271 2.295 0-.33 5.236-14.03 6.629-23.18M74.936 144.017c.845-7.12 1.985-19.85 1.09-31.757M73.248 27.127l6.08-8.867L73.88 5.89 73.248 0l-6.722 17.327z"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M72.614 4.904v2.31l1.292 2.79M75.012 12.212l-.244 1.747 1.393 1.026M68.358 16.403l1.014-1.16v-2.146l.684-1.182M69.211 17.782l1.191 1.261M53.733 32.266a17.125 17.125 0 0 1-3.63-1.74c-8.707-5.483-21.702 3.737-22.319 4.223.262.078.71.462 1.25.305 4.619-1.276 8.242-2.608 10.648-.485 1.925 1.707 2.533 2.264 4.13 2.514 2.71.423 3.723-1.457 6.552-1.19 3.192.305 7.726 5.483 9.001 14.1.06.415.093.783.11 1.183M61.071 73.25a17.475 17.475 0 0 0 3.032-1.37c3.143-1.833 5.603-4.519 7.042-7.693a25.885 25.885 0 0 1-3.75-5.437c-2.051-4.002-2.17-6.932-3.157-11.17-.27-1.175-.524-2.225-.76-3.172M19.23 58.829a42.316 42.316 0 0 0-9.77 6.98 83.61 83.61 0 0 0 6.756 5.28c7.904 5.576 21.693 15.298 32.35 12.047M55.152 97.972c1.224-3.22 3.774-5.561 4.897-8.42 2.77-7.05.693-16.537-2.052-20.266M54.637 101.85a8.087 8.087 0 0 1 .059-2.248"
          />
          <path
            d="M46.725 75.702c1.739 3.454 4.737 7.403 4.483 13.567-.185 4.395-1.131 6.204-.067 9.737.768 2.538 3.192 4.928 4.45 6.157M63.697 73.524c3.116 1.731 1.596 5.867 4.704 7.59-.178-3.916 2.82-4.59 1.646-8.365a8.353 8.353 0 0 0-1.545-2.836M54.147 35.211c.118-1.92 6.536-6.987 11.062-2.977 4.002 3.549-1.605 11.649-3.378 11.265-1.773-.384-1.925-2.39-3.75-4.12-2.406-2.28-3.993-3.189-3.934-4.168z"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M56.579 35.344s1.9-2.044 3.8-2.632c1.9-.587 5.362.118 4.416 4.371M22.617 44.611s-2.534 2.758-2.534 8.288M29.499 51.747s-7.6-.532-9.382 4.951M21.561 56.698c-1.68 2.58-1.961 5.739-.76 8.539M22.253 61.555s-.667 3.862.845 6.141M24.33 66.051s-.49 2.758 3.378 5.131M28.587 68.174c.141 1.733 1.24 3.275 2.904 4.073M32.032 70.461s.05 1.865 2.82 2.617c2.39.642 3.85-1.489 5.472.36M41.008 72.247s1.773.588 1.858 1.982M44.554 72.232c.133.58.115 1.181-.05 1.754M46.235 74.652c1.292-.826 2.216-2.059 2.6-3.47M49.368 73.79s3.428-2.263 4.095-5.616M54.13 71.77c2.647-1.984 3.99-5.103 3.547-8.233M59.636 66.811s2.533-4.144.717-8.57M62.084 58.711c.35-1.747-.117-3.549-1.283-4.958-2.491-2.906-8.529-2.006-8.529-2.006"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.62 46.115s-6.755-1.872-3.817-9.282c2.939-7.41 10.294-.635 10.294-.635"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.19 43.71s-2.06-.368-2.137-3.368c-.076-3 .963-4.144.963-4.144M41.726 56.698c3.225-.07 4.796-.376 4.796-1.567 0-1.801-1.596-4.927-4.864-4.927-3.065 0-4.712 3.306-4.712 4.927 0 1.622 4.78 1.567 4.78 1.567z"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <ellipse
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="34.151"
            cy="49.264"
            rx="2.103"
            ry="1.951"
          />
          <ellipse
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="49.207"
            cy="49.209"
            rx="2.086"
            ry="1.935"
          />
          <path
            d="M47.535 59.275c-.22 2.46-8.267 2.28-11.627.784 1.138.267 2.315.37 3.487.305 3.428-.47 8.2-1.739 8.14-1.089zM36.473 65.237c.003-.973.567-1.87 1.478-2.35M40.594 62.91c-.948 1.105-1.22 2.583-.718 3.917M43.01 62.91a2.561 2.561 0 0 0 0 1.716M45.542 62.526c1.087.51 1.828 1.488 1.976 2.609M47.974 62.095c.695-.107 1.4.141 1.841.65M46.919 45.559s2.001-2.898 3.496-2.021c1.354.949 2.6 2.025 3.715 3.212M28.587 46.444s4.399-3.72 6.257-3.65c1.857.07 2.364 2.765 2.364 2.765M39.631 56.44s-1.41-1.16-.388-2.225c1.022-1.065 1.03 1.112 1.03 1.112M44.504 56.51s1.072-1.128.422-1.997c-.844-1.081-1.115.665-1.115.665"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect fill="#F47823" fillRule="nonzero" x="70.917" y="24.096" width="2.939" height="139.81" rx="1.469" />
          <rect
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            x="71.821"
            y="24.143"
            width="2.939"
            height="139.81"
            rx="1.469"
          />
          <path
            d="M82.773 92.183s-14.077-10.7-17.682 0c-3.606 10.7 14.87 24.871 26.075 16.247 8.157-6.267-1.689-25.607-12.76-37.835"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path
            d="M65.023 101.317c4.053 8.24 16.753 14.829 25.256 8.28 8.504-6.549-2.406-27.033-13.983-39.167"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M81.852 93.35s-7.684-5.835-13.147-4.574"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.475 156.135c0 3.134 30.956 2.961 30.956-.196 0-3.736-5.767-10.371-15.604-10.308-9.973.031-15.352 5.671-15.352 10.504z"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path
            d="M11.588 157.31c0 3.134 30.957 2.953 30.957-.203 0-3.737-5.768-10.372-15.605-10.31-9.973.064-15.352 5.672-15.352 10.513z"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m70.946 24.484 4.976-1.44a.642.642 0 0 1 .788.413.59.59 0 0 1-.395.754l-4.977 1.44a.642.642 0 0 1-.787-.412.59.59 0 0 1 .395-.755zM71.105 25.752l4.308-1.246a.642.642 0 0 1 .787.412.59.59 0 0 1-.395.755l-4.308 1.246a.642.642 0 0 1-.787-.412.59.59 0 0 1 .395-.755zM71.284 27l3.654-1.057a.642.642 0 0 1 .788.413.59.59 0 0 1-.396.754l-3.654 1.057a.642.642 0 0 1-.787-.412.59.59 0 0 1 .395-.754zM71.306 28.297l3.565-1.031a.642.642 0 0 1 .788.412.59.59 0 0 1-.395.754l-3.566 1.032a.642.642 0 0 1-.787-.412.59.59 0 0 1 .395-.755zM71.293 29.606l3.606-1.043a.642.642 0 0 1 .787.412.59.59 0 0 1-.395.754l-3.606 1.044a.642.642 0 0 1-.787-.413.59.59 0 0 1 .395-.754z"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path
            d="m70.178 29.05 3.628-1.063a.64.64 0 0 1 .786.408.587.587 0 0 1-.39.753l-3.629 1.063a.64.64 0 0 1-.786-.408.587.587 0 0 1 .391-.753zM71.286 32.224l3.638-1.053a.642.642 0 0 1 .788.413.59.59 0 0 1-.395.754l-3.639 1.052a.642.642 0 0 1-.787-.412.59.59 0 0 1 .395-.754z"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path
            d="m70.223 31.634 3.572-1.046a.64.64 0 0 1 .786.408.587.587 0 0 1-.39.753l-3.573 1.046a.64.64 0 0 1-.786-.408.587.587 0 0 1 .391-.753zM71.314 34.822l3.557-1.03a.642.642 0 0 1 .788.413.59.59 0 0 1-.395.754l-3.558 1.03a.642.642 0 0 1-.787-.413.59.59 0 0 1 .395-.754z"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path
            d="M71.373 35.994a4.135 4.135 0 0 0-.507 2.742c.24.963.868 1.805 1.757 2.35a.537.537 0 0 0 .709-.102.461.461 0 0 0-.076-.65 3.331 3.331 0 0 1-1.351-1.856 5.045 5.045 0 0 1 .464-2.53"
            fill="#4B5A69"
            fillRule="nonzero"
          />
          <path
            d="m70.946 24.484 4.976-1.44a.642.642 0 0 1 .788.413.59.59 0 0 1-.395.754l-4.977 1.44a.642.642 0 0 1-.787-.412.59.59 0 0 1 .395-.755zM71.105 25.752l4.308-1.246a.642.642 0 0 1 .787.412.59.59 0 0 1-.395.755l-4.308 1.246a.642.642 0 0 1-.787-.412.59.59 0 0 1 .395-.755zM71.284 27l3.654-1.057a.642.642 0 0 1 .788.413.59.59 0 0 1-.396.754l-3.654 1.057a.642.642 0 0 1-.787-.412.59.59 0 0 1 .395-.754zM71.306 28.297l3.565-1.031a.642.642 0 0 1 .788.412.59.59 0 0 1-.395.754l-3.566 1.032a.642.642 0 0 1-.787-.412.59.59 0 0 1 .395-.755zM71.293 29.606l3.606-1.043a.642.642 0 0 1 .787.412.59.59 0 0 1-.395.754l-3.606 1.044a.642.642 0 0 1-.787-.413.59.59 0 0 1 .395-.754z"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m70.178 29.05 3.628-1.063a.64.64 0 0 1 .786.408.587.587 0 0 1-.39.753l-3.629 1.063a.64.64 0 0 1-.786-.408.587.587 0 0 1 .391-.753z"
            stroke="#231F20"
            strokeWidth=".996"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m71.286 32.224 3.638-1.053a.642.642 0 0 1 .788.413.59.59 0 0 1-.395.754l-3.639 1.052a.642.642 0 0 1-.787-.412.59.59 0 0 1 .395-.754z"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m70.223 31.634 3.572-1.046a.64.64 0 0 1 .786.408.587.587 0 0 1-.39.753l-3.573 1.046a.64.64 0 0 1-.786-.408.587.587 0 0 1 .391-.753z"
            stroke="#231F20"
            strokeWidth=".996"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m71.314 34.822 3.557-1.03a.642.642 0 0 1 .788.413.59.59 0 0 1-.395.754l-3.558 1.03a.642.642 0 0 1-.787-.413.59.59 0 0 1 .395-.754z"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M71.373 35.994a4.135 4.135 0 0 0-.507 2.742c.24.963.868 1.805 1.757 2.35a.537.537 0 0 0 .709-.102h0a.461.461 0 0 0-.076-.65 3.331 3.331 0 0 1-1.351-1.856 5.045 5.045 0 0 1 .464-2.53M51.158 76.07s5.75 3.995 3.428 15.29M56.385 76.556a10.166 10.166 0 0 0-.44-1.042M56.579 86.426c.639-2.63.745-5.348.312-8.014M69.684 62.409a16.365 16.365 0 0 1-3.96 5.287M25.775 75.013A35.2 35.2 0 0 0 29 76.579M13.708 66.16a60.03 60.03 0 0 0 10.901 8.218M26.12 37.561s-1.012-1.12-1.865-1.16M24.972 38.595a2.404 2.404 0 0 0-2.018.243M24.787 39.739a1.004 1.004 0 0 0-.845.235M57.617 36.629a2.917 2.917 0 0 1 1.69-.98M58.665 37.397c.216-.217.526-.332.844-.314M47.096 133.912c-.854 2.872-.91 5.9-.16 8.797M49.444 150.965c.008.346-.032.69-.119 1.026M49.672 138.745s-2.17 3.564-.684 9.048c.185.685.32 1.381.405 2.083M51.082 149.234s.422 2.836 0 4.199M52.796 152.853a5.114 5.114 0 0 1 0 3.917M54.307 156.778c-.002.438-.13.868-.371 1.245M12.61 129.298c.632.188 1.31.188 1.942 0M13.944 128.115s1.613.173 3.116-1.88M15.498 125.616a4.948 4.948 0 0 0 2.035-2.404M67.007 101.012s-2.423-1.01-3.31-4.348M65.352 96.688s-2.322-1.888-.65-6.526M65.876 91.36s.304-2.35 1.562-3.195"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  )
}

export default ErrorBearsIcon
