import React from 'react'

import { useParams } from 'react-router-dom'
import { ProjectsHomePage } from './projectsHomePage/projectsHomePage'
import { ProjectDetailsPage } from './projectDetailsPage/projectDetailsPage'

interface IProjectsPageRouteParams {
  id?: string
}

const ProjectsPage = () => {
  const { id: projectId }: IProjectsPageRouteParams = useParams()

  if (!projectId) {
    return <ProjectsHomePage />
  }

  return <ProjectDetailsPage projectId={projectId} />
}

export default ProjectsPage
