import * as React from 'react'
import IAttachment from 'model/attachment'
import BigCloseIcon from 'icons/bigCloseIcon'
import { createStyles, makeStyles } from '@material-ui/styles'
import { Theme, useTheme } from '@material-ui/core'
import useBackListener from 'hooks/useBackListener'
import { AttachmentInfoCard } from 'component/messageAttachment/attachmentInfoCard'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerSection: {
      display: 'flex',
      height: 70,
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: theme.threadPalette.menuItem.current,
      alignItems: 'center',
      alignContent: 'center',
    },
    closeButton: {
      cursor: 'pointer',
      marginRight: 30,
      width: 20,
      height: 20,
    },
    attachmentCardInfoWrapper: {
      paddingInline: 14,
    },
  })
)

interface IAttachmentPreviewHeaderProps {
  attachment: IAttachment
  onClose: () => void
}

const AttachmentPreviewHeader = ({ attachment, onClose }: IAttachmentPreviewHeaderProps) => {
  const classes = useStyles()
  const theme = useTheme()

  const closeAttachmentPreview = React.useCallback(() => {
    onClose()
  }, [onClose])

  useBackListener(closeAttachmentPreview)

  return (
    <div className={classes.headerSection}>
      <div className={classes.attachmentCardInfoWrapper}>
        <AttachmentInfoCard attachment={attachment} fillColor={theme.threadPalette.white} />
      </div>
      <div className={classes.closeButton} onClick={closeAttachmentPreview}>
        <BigCloseIcon />
      </div>
    </div>
  )
}

export default AttachmentPreviewHeader
