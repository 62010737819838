import { AnyAction } from 'redux'

import { RIGHT_SIDEBAR_ACTIONS } from '../constants/actions/rightSidebar'

const DEFAULT_STATE: IRightSidebarState = {
  search: '',
  optionSelected: 'none',
  backOption: 'none',
}

export const rightSidebarReducer = (state = DEFAULT_STATE, action: AnyAction) => {
  switch (action.type) {
    case RIGHT_SIDEBAR_ACTIONS.SET_OPTION:
      return {
        ...state,
        optionSelected: action.optionSelected,
      }

    case RIGHT_SIDEBAR_ACTIONS.SET_BACK_OPTION: {
      return {
        ...state,
        backOption: action.backOption,
      }
    }

    case RIGHT_SIDEBAR_ACTIONS.SET_SEARCH:
      return {
        ...state,
        search: action.search,
      }

    default:
      return state
  }
}
