import * as React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { setBackOption, setOption } from 'actions/rightSidebar'
import DetailsPageHeader from 'component/contactDetailsHeader'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: theme.threadPalette.lighterGray,
      height: theme.dimensions.rightSidebar.headerHeight,
    },
    header: {
      paddingLeft: 20,
      fontSize: 19,
      fontWeight: 500,
      color: theme.threadPalette.menuItem.current,
      textTransform: 'capitalize',
    },
    closeButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 10,
      height: 10,
      marginRight: 20,
      '& svg': {
        fill: theme.threadPalette.menuItem.current,
      },
      '&:hover': {
        '& svg': {
          fill: theme.threadPalette.lightGray,
        },
      },
    },
  })
)

type TRightSidebarOptionsMap = Record<TRightSidebarOption, string | React.ReactNode | undefined>
const rightSidebarMap: TRightSidebarOptionsMap = {
  none: undefined,
  search: 'Search',
  activity: 'Thread Activity',
  files: 'Files',
  followers: 'Followers',
  accountDetails: <DetailsPageHeader defaultBackOption="followers" title="Account" />,
  teamSpace: 'Team space',
  settings: 'Settings',
  emailViews: 'Email Views',
  contactDetails: <DetailsPageHeader defaultBackOption="followers" title="Contact" />,
}

interface IRightSidebarHeaderProps {
  title: TRightSidebarOption
}

const RightSidebarHeader = ({ title }: IRightSidebarHeaderProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onCloseClick = React.useCallback(() => {
    // clean the backOption state so the default one will be used
    dispatch(setBackOption('none'))
    dispatch(setOption('none'))
  }, [dispatch])

  return (
    <div className={classes.root}>
      <Typography component="div" className={classes.header}>
        {title !== 'none' && rightSidebarMap[title]}
      </Typography>
      <div className={classes.closeButton} onClick={onCloseClick}>
        <CloseIcon />
      </div>
    </div>
  )
}

export default RightSidebarHeader
