export const safelyParseURL = (url: string) => {
  try {
    const urlParsed = new URL(url)
    return urlParsed
  } catch (error) {
    return
  }
}

export const getRelativeURL = (url: URL) => {
  return `${url.pathname}${url.search}${url.hash}`
}
