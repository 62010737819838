import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { updateParentProject as updateParentProjectApiCal } from 'api/v2/project'
import { useProjectActions } from 'hooks/useProjects/useProjectActions'
import { useDispatch } from 'react-redux'

export const useUpdateParentProjectUseCase = () => {
  const dispatch = useDispatch()
  const projectCacheActions = useProjectActions()

  const { mutateAsync: runUpdateParentProjectMutation } = useMutation(updateParentProjectApiCal, {
    onSettled: (_, __, { projectId, parentProjectId }) => {
      projectCacheActions.refreshProjectsList()
      projectCacheActions.refreshProject(projectId)
      if (parentProjectId) projectCacheActions.refreshProject(parentProjectId)
    },
    onError: () => dispatch(showSnackbar({ message: 'Failed to move project' })),
  })

  const updateParentProjectUseCase: typeof updateParentProjectApiCal = runUpdateParentProjectMutation

  return { updateParentProjectUseCase }
}
