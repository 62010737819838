import * as React from 'react'
import { setUser } from 'actions/auth'
import { getCurrentUser } from 'api/auth'
import useFileUpload from 'hooks/useFileUpload'
import { getNameParts } from 'dataProcessors/utils'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'actions/snackbar'
import { PrepareUploadResponse } from '@jetkit/react'
import * as UsersApi from 'api/users'
import { useBoolean } from 'hooks/useBoolean'

interface PreUploadResponse extends PrepareUploadResponse {
  download_url: string
}

export function useUserInfoConfirmation(user: TUser, onSuccess: () => void) {
  const dispatch = useDispatch()
  const {
    state: isUpdatingUserInfo,
    setTrue: setIsUpdatingUserInfoTrue,
    setFalse: setIsUpdatingUserInfoFalse,
  } = useBoolean(false)

  const nameParts = getNameParts(user?.name || '')

  const { uploadFile } = useFileUpload<PreUploadResponse>('/user/me/avatar')

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newFirstName = event.target.value
    nameParts.firstName = newFirstName
  }

  const handleLastNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newLastName = event.target.value
    nameParts.lastName = newLastName
  }

  const handleAvatarUpload = React.useCallback(
    async (files: File[]) => {
      dispatch(showSnackbar({ message: 'Avatar upload in progress...' }))
      await uploadFile(files[0])

      const result = await getCurrentUser()
      if (result) {
        dispatch(setUser(result))
      }
    },
    [dispatch, uploadFile, getCurrentUser]
  )

  const handleFormSubmit = React.useCallback(async () => {
    setIsUpdatingUserInfoTrue()
    const name = `${nameParts.firstName} ${nameParts.lastName}`
    const email = user?.email

    const updatedUser = await UsersApi.updateUserInfoOnActivation({
      name,
      email,
    })

    if (!updatedUser) return

    setIsUpdatingUserInfoFalse()
    onSuccess()
    dispatch(setUser(updatedUser))
  }, [nameParts])

  return {
    user,
    nameParts,
    handleAvatarUpload,
    handleFirstNameChange,
    handleLastNameChange,
    handleFormSubmit,
    isUpdatingUserInfo,
  }
}
