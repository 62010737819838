import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { disconnectThreadFromProject } from 'api/v2/project'
import { useProjectActions } from 'hooks/useProjects/useProjectActions'
import { useThreadProjectActions } from 'hooks/useThreadProjects/useThreadProjectActions'
import { useDispatch } from 'react-redux'

interface IDisconnectThreadFromProjectUseCaseProps {
  threadId: string
  projectId: string
}

export const useDisconnectThreadFromProjectUseCase = () => {
  const dispatch = useDispatch()

  const { refreshProjectsList, refreshProject } = useProjectActions()
  const { refreshThreadProjects } = useThreadProjectActions()

  const disconnectThreadFromProjectHandler = async ({
    threadId,
    projectId,
  }: IDisconnectThreadFromProjectUseCaseProps) => {
    const project = await disconnectThreadFromProject({ projectId, threadId })
    return project
  }

  const disconnectThreadFromProjectMutation = useMutation(disconnectThreadFromProjectHandler, {
    onError: () => {
      dispatch(showSnackbar({ message: 'Error trying to disconnect project' }))
    },
    onSettled: (data, error, variables) => {
      const { projectId, threadId } = variables

      disconnectThreadFromProjectMutation.reset()

      refreshThreadProjects(threadId)
      refreshProject(projectId)
      refreshProjectsList()
    },
  })

  const disconnectThreadFromProjectUseCase = async (useCaseProps: IDisconnectThreadFromProjectUseCaseProps) => {
    return await disconnectThreadFromProjectMutation.mutateAsync(useCaseProps)
  }

  return {
    disconnectThreadFromProjectUseCase,
  }
}
