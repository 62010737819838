import * as React from 'react'
import { makeStyles, Theme, createStyles, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { setBackOption, setOption } from 'actions/rightSidebar'
import BackArrow from 'icons/backArrow'
import { selectRightSidebar } from 'selectors/rightSidebar'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
    },
    text: {
      fontSize: 16,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: theme.palette.primary.main,
      marginRight: 12,
    },
  })
)

interface IDetailsPageHeaderProps {
  defaultBackOption: TRightSidebarOption
  title: string
}

const DetailsPageHeader = ({ defaultBackOption, title }: IDetailsPageHeaderProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const backOption = useSelector(selectRightSidebar)?.backOption

  const onBackClick = React.useCallback(() => {
    // if backOption is set and it is not default one, it should override the default one
    if (backOption && backOption !== 'none') {
      dispatch(setOption(backOption))
      // clean the backOption state so the default defaultBackOption will be used next time
      dispatch(setBackOption('none'))
      return
    }
    dispatch(setOption(defaultBackOption))
  }, [dispatch, defaultBackOption])

  return (
    <div className={classes.root} onClick={onBackClick}>
      <BackArrow /> <Typography className={classes.text}>Back</Typography> {title}
    </div>
  )
}

export default DetailsPageHeader
