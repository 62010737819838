import * as React from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'actions/snackbar'

interface IAttachmentPickerProps {
  uploadAttachments: (files: File[]) => Promise<void>
  fileTypes?: string[]
  errorMsg?: string
  className?: string
}

const AttachmentPicker: React.FC<IAttachmentPickerProps> = ({
  uploadAttachments,
  fileTypes,
  errorMsg,
  className,
  children,
}) => {
  const inputFile: React.RefObject<HTMLInputElement> = React.createRef()
  const dispatch = useDispatch()

  const handleOpenFilePicker = React.useCallback(() => {
    if (inputFile.current) {
      inputFile.current.click()
    }
  }, [inputFile])

  const checkFileTypesValid = React.useCallback(
    (files: File[]) => {
      let valid = true
      // check if selected files have proper type
      if (fileTypes) {
        files.forEach(file => {
          const validFileType = fileTypes.includes(file.type)
          if (!validFileType) {
            valid = valid && false
          }
        })
      }
      return valid
    },
    [fileTypes]
  )

  const handleFilesSelected = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFiles: FileList | null = event.target.files
      if (selectedFiles) {
        const files = Array.from(selectedFiles)

        if (checkFileTypesValid(files)) {
          await uploadAttachments(files)
        } else if (errorMsg) {
          dispatch(showSnackbar({ message: errorMsg }))
        } else {
          // if fileTypes are limited, the error message is mandatory
          throw new Error('Error Message cannot be empty if fileTypes are limited')
        }
      }
    },
    [checkFileTypesValid, dispatch, errorMsg, uploadAttachments]
  )

  return (
    <div className={className} onClick={handleOpenFilePicker}>
      <input
        type="file"
        ref={inputFile}
        style={{ display: 'none' }}
        value="" // this ensures that onChange will be called even if user selects the same file over and over
        onChange={handleFilesSelected}
      />
      {children}
    </div>
  )
}

export default AttachmentPicker
