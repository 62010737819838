import { IProject } from 'model/project'
import * as React from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ProjectSkeletonItem from './projectItem/projectSkeletonItem'
import { ProjectItem } from './projectItem/projectItem'
import { useNewThreadModalContext } from 'component/newThreadModal/newThreadModal'
import BeatProgress from 'component/beatProgress'

const useProjectsListStyles = makeStyles((theme: Theme) => {
  return {
    projectsListRoot: {
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }
})

interface IProjectListProps {
  handleScrollEvent: (event: React.UIEvent<HTMLDivElement>) => void
  projectsList: IProject[]
  isInitialLoading: boolean
  isLoadingNextPage: boolean
}

export const ProjectsList = ({
  projectsList,
  isInitialLoading,
  handleScrollEvent,
  isLoadingNextPage,
}: IProjectListProps) => {
  const classes = useProjectsListStyles()

  const { openModal: openNewThreadModal, setProject: setNewThreadProject } = useNewThreadModalContext()

  const onNewThreadInProject = (project: IProject) => {
    setNewThreadProject(project)
    openNewThreadModal()
  }

  const getProjectsList = (projects: IProject[]) => {
    return projects.map(project => (
      <ProjectItem project={project} key={project.id} onNewThreadInProject={onNewThreadInProject} />
    ))
  }

  const getProjectsSeletonList = (numberOfProjects: number) => {
    return [...Array(numberOfProjects)].map((_, index) => <ProjectSkeletonItem key={index} />)
  }

  return (
    <div className={classes.projectsListRoot} onScroll={handleScrollEvent}>
      {isInitialLoading ? getProjectsSeletonList(30) : getProjectsList(projectsList)}
      {isLoadingNextPage && <BeatProgress />}
    </div>
  )
}
