import classnames from 'classnames'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

interface IAppLoadingProps {
  visible: boolean
  small?: boolean
  padding?: number | { inline?: number; block?: number }
  fill?: string
  legWidth?: number
  legHeight?: number
}

const useStyles = makeStyles<Theme, IAppLoadingProps>((theme: Theme) => {
  return {
    appLoading: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
    },
    '@keyframes appLoadingSpinnerDiv': {
      '0%': { opacity: 1 },
      '100%': { opacity: 0 },
    },
    appLoadingSpinner: {
      height: '105px',
      position: 'relative',
      transform: 'translate(-100px, -100px) scale(1) translate(100px, 100px)',
      width: '105px',
    },
    appLoadingSpinnerSmall: {
      transform: 'scale(0.5)',
      paddingInline: ({ padding = 0 }) => (typeof padding === 'number' ? padding : padding.inline ?? 0),
      paddingBlock: ({ padding = 0 }) => (typeof padding === 'number' ? padding : padding.block ?? 0),
    },
    appLoadingSpinnerDiv: {
      animation: '$appLoadingSpinnerDiv linear 1s infinite',
      background: ({ fill }) => fill ?? theme.palette.primary.main,
      borderRadius: '40%',
      height: ({ legHeight = 24 }) => legHeight,
      left: ({ small }) => (small ? 0 : '46px'),
      position: 'absolute',
      transformOrigin: ({ small }) => (small ? '0 0' : '6px 52px'),
      width: ({ legWidth = 12 }) => legWidth,
    },
    appLoadingSpinnerDiv1: {
      animationDelay: '-0.916666666666667s',
      transform: 'rotate(0deg)',
    },
    appLoadingSpinnerDiv2: {
      animationDelay: '-0.833333333333333s',
      transform: 'rotate(30deg)',
    },
    appLoadingSpinnerDiv3: {
      animationDelay: '-0.75s',
      transform: 'rotate(60deg)',
    },
    appLoadingSpinnerDiv4: {
      animationDelay: '-0.666666666666667s',
      transform: 'rotate(90deg)',
    },
    appLoadingSpinnerDiv5: {
      animationDelay: '-0.583333333333333s',
      transform: 'rotate(120deg)',
    },
    appLoadingSpinnerDiv6: {
      animationDelay: '-0.5s',
      transform: 'rotate(150deg)',
    },
    appLoadingSpinnerDiv7: {
      animationDelay: '-0.416666666666667s',
      transform: 'rotate(180deg)',
    },
    appLoadingSpinnerDiv8: {
      animationDelay: '-0.333333333333333s',
      transform: 'rotate(210deg)',
    },
    appLoadingSpinnerDiv9: {
      animationDelay: '-0.25s',
      transform: 'rotate(240deg)',
    },
    appLoadingSpinnerDiv10: {
      animationDelay: '-0.166666666666667s',
      transform: 'rotate(270deg)',
    },
    appLoadingSpinnerDiv11: {
      animationDelay: '-0.083333333333333s',
      transform: 'rotate(300deg)',
    },
    appLoadingSpinnerDiv12: {
      animationDelay: '-0s',
      transform: 'rotate(330deg)',
    },
  }
})

const AppLoading = (props: IAppLoadingProps) => {
  const classes = useStyles(props)

  const { visible = false, small = false } = props

  if (!visible) {
    return null
  }

  const divsClassesSuffixForSpinner = Array.from(Array(12), (_, index) => index + 1)
  return (
    <div className={classes.appLoading}>
      <div className={small ? classes.appLoadingSpinnerSmall : classes.appLoadingSpinner}>
        {divsClassesSuffixForSpinner.map(classSuffix => {
          return (
            <div
              key={classSuffix}
              className={classnames(classes.appLoadingSpinnerDiv, classes[`appLoadingSpinnerDiv${classSuffix}`])}
            />
          )
        })}
      </div>
    </div>
  )
}

export default AppLoading
