import { useCallback } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getThreadProjects } from 'api/v2/thread'
import { getTimeInMilliSeconds } from 'dataProcessors/utils'
import { useUser } from 'hooks/auth'

interface IUseThreadThreadProjectsProps {
  threadId?: string
  isFetchingEnabled?: boolean
}

export const getThreadProjectsQueryKey = ({ userId, threadId }: { userId: string; threadId: string }) => [
  userId,
  'threads',
  threadId,
  'projects',
]

const getThreadProjectsQueryFunction = async (threadId?: string) => {
  if (!threadId) return undefined

  const projects = getThreadProjects(threadId)

  return projects
}

export const useThreadProjects = ({ threadId, isFetchingEnabled = true }: IUseThreadThreadProjectsProps) => {
  const currentUser = useUser()

  const { data: projects, isLoading } = useQuery({
    enabled: !!threadId && isFetchingEnabled && !!currentUser?.id,
    queryKey: getThreadProjectsQueryKey({ userId: currentUser?.id ?? '', threadId: threadId ?? '' }),
    queryFn: () => getThreadProjectsQueryFunction(threadId),
    staleTime: getTimeInMilliSeconds({ minutes: 1 }),
  })

  return {
    projects: projects ?? [],
    isLoading,
  }
}

export const usePrefetchThreadProjects = () => {
  const queryClient = useQueryClient()
  const currentUser = useUser()

  const prefetchThreadProjects = useCallback(
    ({ threadId }: Required<Pick<IUseThreadThreadProjectsProps, 'threadId'>>) => {
      if (!currentUser?.id) return

      queryClient.prefetchQuery({
        queryKey: getThreadProjectsQueryKey({ userId: currentUser?.id ?? '', threadId }),
        queryFn: () => getThreadProjectsQueryFunction(threadId),
      })
    },
    [queryClient, currentUser?.id]
  )

  return { prefetchThreadProjects }
}
