import React from 'react'
import parse, { HTMLElement } from 'node-html-parser'
import ReactDOMServer from 'react-dom/server'
import { ImageWithContent } from './inlineAttachmentImage/imageWithContent'

interface IModifyInlineAttachmentProps {
  content: string
  contentId: string
}

interface ISetInlineAttachmentContentProps extends IModifyInlineAttachmentProps {
  url: string
}

interface ISetInlineAttachmentPlaceholderProps extends IModifyInlineAttachmentProps {
  placeholder: JSX.Element
}

export const useInlineAttachmentContentActions = () => {
  const modifyInlineAttachment = (content: string, attachmentContentId: string, action: (img: HTMLElement) => void) => {
    const root = parse(content)

    const imageTags = root.getElementsByTagName('img')
    const inlineAttachmentImageTags = imageTags.filter(
      imageTag => imageTag?.attributes?.['data-custom'] === attachmentContentId
    )

    inlineAttachmentImageTags.forEach(imageTag => action(imageTag))

    return root.toString()
  }

  const setInlineAttachmentPlaceholder = ({
    content,
    contentId,
    placeholder,
  }: ISetInlineAttachmentPlaceholderProps) => {
    const imagePlaceholderAsString = ReactDOMServer.renderToString(placeholder)

    return modifyInlineAttachment(content, contentId, imageTag => imageTag.replaceWith(imagePlaceholderAsString))
  }

  const setInlineAttachmentContent = ({ content, contentId, url }: ISetInlineAttachmentContentProps) => {
    const image = <ImageWithContent contentId={contentId} url={url} />
    const imageAsString = ReactDOMServer.renderToString(image)

    return modifyInlineAttachment(content, contentId, imageTag => imageTag.replaceWith(imageAsString))
  }

  return {
    setInlineAttachmentContent,
    setInlineAttachmentPlaceholder,
  }
}
