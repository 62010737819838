import * as React from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

interface IImageViewerProps {
  fileUrl?: string
  zoom: number
}

const ImageViewer = ({ fileUrl: filePath, zoom }: IImageViewerProps) => {
  return (
    <TransformWrapper
      scale={zoom}
      wheel={{
        wheelEnabled: false,
      }}
      options={{
        minScale: 0.2,
        centerContent: false,
        limitToBounds: false,
        limitToWrapper: false,
      }}
    >
      <TransformComponent>
        <img src={filePath} alt="img preview" />
      </TransformComponent>
    </TransformWrapper>
  )
}

export default ImageViewer
