import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'

const useInlineEmojiStyles = makeStyles<Theme, { withPadding: boolean }>((theme: Theme) => ({
  inlineEmojiRoot: {
    paddingInline: ({ withPadding }) => (withPadding ? 4 : 0),
  },
}))

interface IInlineEmojiProps {
  emoji: string
  ariaLabel: string
  withPadding?: boolean
}

export const InlineEmoji = ({ emoji, ariaLabel, withPadding = false }: IInlineEmojiProps) => {
  const classes = useInlineEmojiStyles({ withPadding })

  return (
    <span className={classes.inlineEmojiRoot} role="img" aria-label={ariaLabel}>
      {emoji}
    </span>
  )
}
