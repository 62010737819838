import { useMutation } from '@tanstack/react-query'
import { sendDraftMessage as sendMessageApiCall } from 'api/message'
import { getRecipientsEmails } from 'domain/messageRecipient'
import { useThreadMessagesCacheActions } from 'hooks/useThreadMessages/useThreadMessagesCacheActions'
import { insertInlineAttachments, setInlineAttachmentsMaxWidth } from 'lib/message'
import IAttachment, { IInlineAttachment } from 'model/attachment'
import { IMention } from 'model/mention'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'actions/snackbar'
import { useProjectActions } from 'hooks/useProjects/useProjectActions'
import { useUpdateThreadUseCase } from 'usecases/updateThread'
import { useThreadProjectActions } from 'hooks/useThreadProjects/useThreadProjectActions'
import { ServerError } from 'api/errors/serverError'
import { getErrorReasonFromError } from './errors'
import { useDraftCacheActions } from 'hooks/useDraft/useDraftCacheActions'

export interface ISendDraftMessageProps {
  threadId: string
  draftId: string
  repliedOrForwardedMessageId?: string
  content: string
  standardAttachments: IAttachment[]
  inlineAttachments: IInlineAttachment[]
  toRecipients: TDraftRecipient[]
  bccRecipients: TDraftRecipient[]
  isPreviousMessageContentEdited: boolean
  mentionedUsers: IMention[]
  previousMessageContent: string
  sender: IUser
}

export const useSendDraftUseCase = () => {
  const dispatch = useDispatch()

  const { getThreadProjects } = useThreadProjectActions()
  const { refreshProject } = useProjectActions()
  const { updateThreadUseCase } = useUpdateThreadUseCase()
  const draftCacheActions = useDraftCacheActions()
  const threadMessagesCacheActions = useThreadMessagesCacheActions()

  const parseDraftContentAndSendMessage = async ({
    draftId,
    content,
    standardAttachments,
    inlineAttachments,
    toRecipients,
    bccRecipients,
    isPreviousMessageContentEdited,
    mentionedUsers,
  }: ISendDraftMessageProps) => {
    const { content: contentWithInlineAttachments, inlineAttachments: referencedInlineAttachments } =
      insertInlineAttachments({
        messageContent: content,
        inlineAttachments: inlineAttachments,
      })

    const standardAttachmentIds = standardAttachments.map((item: IAttachment) => item.id)
    const inlineAttachmentIds = referencedInlineAttachments.map((item: IInlineAttachment) => item.contentId)

    const toRecipientEmails = getRecipientsEmails(toRecipients)
    const bccRecipientEmails = getRecipientsEmails(bccRecipients)

    await sendMessageApiCall({
      draftId,
      content: contentWithInlineAttachments,
      bccRecipients: bccRecipientEmails,
      toRecipients: toRecipientEmails,
      isPreviousMessageContentEdited,
      standardAttachments: standardAttachmentIds,
      inlineAttachments: inlineAttachmentIds,
      mentions: mentionedUsers,
    })
  }

  const convertDraftToThreadMessage = ({
    content,
    bccRecipients,
    toRecipients,
    standardAttachments,
    mentionedUsers,
    draftId,
    previousMessageContent,
    isPreviousMessageContentEdited,
    sender,
    threadId,
    repliedOrForwardedMessageId,
  }: ISendDraftMessageProps): IMessage => {
    const contentWithUpdatedInlineAttachments = setInlineAttachmentsMaxWidth(content)

    const convertDraftRecipientsToRecipients = (recipients: TDraftRecipient[]) =>
      recipients.map(recipient => {
        return { name: recipient.name ?? recipient.email, email: recipient.email, avatarUrl: null }
      })

    return {
      createdAt: DateTime.local(),
      updatedAt: DateTime.local(),
      id: draftId,
      threadId: threadId,
      repliedOrForwardedMessageId,
      attachments: standardAttachments,
      mentionedUsers,
      previousMessageContent: isPreviousMessageContentEdited ? '' : previousMessageContent,
      content: contentWithUpdatedInlineAttachments,
      messageSubtype: 'user',
      deliveryStatus: 'pending',
      type: 'email',
      sender,
      recipients: convertDraftRecipientsToRecipients(toRecipients),
      bccRecipients: convertDraftRecipientsToRecipients(bccRecipients),
      isEditable: false,
      isCollapsed: false,
      isDraft: false,
      isEdited: false,
    }
  }

  const removeDraftFromRepliedOrForwardedMessage = ({
    threadId,
    repliedOrForwardedMessageId,
    draftId: draftIdToRemove,
  }: Pick<ISendDraftMessageProps, 'threadId' | 'repliedOrForwardedMessageId' | 'draftId'>) => {
    if (!repliedOrForwardedMessageId) return

    const removeDraftIdFromMessage = (message: IMessage) => {
      const draftsIDs = message.draftsIds ?? []
      const filteredDraftsIDs = draftsIDs.filter(draftId => draftId !== draftIdToRemove)
      return { ...message, draftsIds: filteredDraftsIDs }
    }

    return threadMessagesCacheActions.updateMessage({
      threadId,
      messageId: repliedOrForwardedMessageId,
      messageModifier: removeDraftIdFromMessage,
    })
  }

  const updateDraftMessageOptimistically = ({
    draftId,
    bccRecipients,
    toRecipients,
    content,
    inlineAttachments,
    standardAttachments,
    isPreviousMessageContentEdited,
  }: ISendDraftMessageProps) => {
    const draftModifier = (draft: IDraftMessage) => {
      return {
        ...draft,
        bccRecipients,
        recipients: toRecipients,
        content,
        inlineAttachments,
        attachments: standardAttachments,
        isPreviousMessageContentEdited,
      }
    }
    draftCacheActions.updateData({
      draftId,
      draftModifier,
    })
  }

  const updateThreadOptimistically = ({ threadId }: { threadId: string }) => {
    const { rollbackOptimisticUpdates: rollbackThreadOptimisticUpdate } = updateThreadUseCase({
      threadId,
      isInitiated: true,
      clearFirstDraftId: true,
    })
    return rollbackThreadOptimisticUpdate
  }

  const removeDraftMessageOptimistically = ({
    draftId,
    repliedOrForwardedMessageId,
    threadId,
  }: ISendDraftMessageProps) => {
    const rollbackMessageOptimisticUpdate = removeDraftFromRepliedOrForwardedMessage({
      repliedOrForwardedMessageId,
      draftId,
      threadId,
    })
    const rollbackDraftOptimisticDeletion = draftCacheActions.deleteData(draft => draft.id === draftId)
    return () => {
      rollbackMessageOptimisticUpdate?.()
      rollbackDraftOptimisticDeletion?.()
    }
  }

  const createPendingMessageOptimistically = (sendDraftMessageProps: ISendDraftMessageProps) => {
    threadMessagesCacheActions.addMessage({
      threadId: sendDraftMessageProps.threadId,
      newMessage: convertDraftToThreadMessage(sendDraftMessageProps),
    })
  }

  const sendDraftOptimistically = (sendDraftMessageProps: ISendDraftMessageProps) => {
    // We are updating the draft message in the cache to restore data if an error happens correctly.
    updateDraftMessageOptimistically(sendDraftMessageProps)

    const rollbackThreadOptimisticUpdate = updateThreadOptimistically(sendDraftMessageProps)
    const rollbackDraftOptimisticRemoval = removeDraftMessageOptimistically(sendDraftMessageProps)

    // We don't need to undo pending message creation because undoing draft removal removes the pending message.
    createPendingMessageOptimistically(sendDraftMessageProps)

    return () => {
      rollbackThreadOptimisticUpdate?.()
      rollbackDraftOptimisticRemoval?.()
    }
  }

  const { mutateAsync, isLoading } = useMutation<void, ServerError, ISendDraftMessageProps, () => void>(
    parseDraftContentAndSendMessage,
    {
      onMutate: sendDraftOptimistically,
      onSettled: (_, __, { threadId }) => {
        threadMessagesCacheActions.refreshData(threadId)
      },
      onError: (error, _, rollbackOptimisticUpdate) => {
        rollbackOptimisticUpdate?.()

        const errorReason = getErrorReasonFromError(error)
        const defaultErrorMessage = 'Failed to send message'

        const errorMessage =
          errorReason === 'message_not_a_draft' ? "Can't send the draft since it's already sent." : defaultErrorMessage
        dispatch(showSnackbar({ message: errorMessage }))
      },
      onSuccess: (_, { threadId }) => {
        // refreshing thread project's to keep threads order
        const projects = getThreadProjects(threadId)
        projects.map(project => refreshProject(project.id))
      },
    }
  )

  const sendDraftUseCase: (props: ISendDraftMessageProps) => Promise<void> = mutateAsync

  return {
    sendDraftUseCase,
    isSending: isLoading,
  }
}
