import * as React from 'react'
import { Typography, Divider, Button, TextField, Select, MenuItem } from '@material-ui/core'
import { useUser } from 'hooks/auth'
import UserAvatar from 'component/userAvatar'
import classNames from 'classnames'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TIME_ZONES } from '../../constants/timeZones'
import AttachmentPicker from '../attachmentPicker'
import EmailProviderBadge from 'component/emailProviderBadge'
import AppLoading from 'component/appLoading'
import DeleteAccountDialog from 'component/deleteAccount'
import { AVATAR_FILE_TYPES, notificationOptions } from './profile.model'
import { useStyles } from './profile.styles'
import { useProfile } from './useProfile'
import { UserAccessCheckWrapper, userHasAccess } from 'lib/accessCheck'

const Profile: React.FC = () => {
  const classes = useStyles()
  const user = useUser()

  const {
    openDialog,
    toggleDialog,
    handleAvatarUpload,
    handleFirstNameChange,
    nameParts,
    handleLastNameChange,
    handleNotificationOptionChange,
    handleOnDeleteAccountClick,
    handleTimeZoneChange,
    handleEmailChange,
  } = useProfile(user)

  const emailClasses = classNames(
    classes.field,
    { [classes.fieldDisabled]: userHasAccess(user, ['user_registered']) },
    { [classes.fieldEnabled]: !userHasAccess(user, ['user_registered']) }
  )

  if (!user) {
    return (
      <div className={classes.loadingContainer}>
        <AppLoading visible />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <DeleteAccountDialog open={openDialog} onClose={toggleDialog} />
      <Typography align="left" className={classes.header}>
        Profile
      </Typography>

      <Divider className={classes.divider} />

      <Typography className={classes.label}>Profile Picture</Typography>
      <div className={classes.profilePictureSection}>
        <UserAvatar user={user} size={160} />
        <AttachmentPicker
          uploadAttachments={handleAvatarUpload}
          fileTypes={AVATAR_FILE_TYPES}
          errorMsg={'Please select .jpg or .png file'}
        >
          <Button variant="outlined" color="primary" className={classes.buttonStyle}>
            Change Photo
          </Button>
        </AttachmentPicker>
      </div>

      <div className={classes.fieldWrapper}>
        <Typography className={classes.label}>First Name</Typography>
        <TextField
          className={classNames(classes.field, classes.fieldEnabled)}
          defaultValue={nameParts.firstName}
          fullWidth
          onChange={handleFirstNameChange}
          InputProps={{ disableUnderline: true }}
        />
      </div>

      <div className={classes.fieldWrapper}>
        <Typography className={classes.label}>Last Name</Typography>
        <TextField
          className={classNames(classes.field, classes.fieldEnabled)}
          defaultValue={nameParts.lastName}
          fullWidth
          onChange={handleLastNameChange}
          InputProps={{ disableUnderline: true }}
        />
      </div>

      <div className={classes.fieldWrapper}>
        <Typography className={classes.label}>Email</Typography>
        <TextField
          className={emailClasses}
          defaultValue={user.email}
          disabled
          onChange={handleEmailChange}
          fullWidth
          InputProps={{ disableUnderline: true }}
        />
        <UserAccessCheckWrapper requiredPermissions={['user_registered']} notAllowedComponent={null}>
          <EmailProviderBadge provider={user?.emailProvider} className={classes.providerBadge} />
        </UserAccessCheckWrapper>
      </div>

      <div className={classes.fieldWrapper}>
        <Typography className={classes.label}>Time Zone</Typography>
        <Autocomplete
          options={TIME_ZONES}
          defaultValue={user.timezone}
          onChange={handleTimeZoneChange}
          getOptionLabel={(option: string) => option}
          className={classNames(classes.field, classes.fieldEnabled, classes.select, classes.autoComplete)}
          renderInput={params => <TextField {...params} className={classes.autoCompleteTextField} variant="outlined" />}
        />
      </div>

      <div className={classes.fieldWrapper}>
        <Typography className={classes.label}>Browser Notifications</Typography>
        <Select
          className={classNames(classes.field, classes.fieldEnabled, classes.select)}
          disableUnderline
          defaultValue={user.notificationsEnabled ? 'Enabled' : 'Disabled'}
          onChange={handleNotificationOptionChange}
        >
          {notificationOptions.map(item => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </div>

      <p className={classes.deleteAccount} onClick={handleOnDeleteAccountClick}>
        Delete account?
      </p>
    </div>
  )
}

export default Profile
