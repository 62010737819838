import { useMutation } from '@tanstack/react-query'
import { showSnackbar } from 'actions/snackbar'
import { unfollowThread } from 'api/threads'
import { useThreadActions } from 'hooks/useThreads/useThreadActions'
import { useDispatch } from 'react-redux'
import { useUpdateThreadUseCase } from './updateThread'

interface IUnfollowThreadUseCaseProps {
  threadId: string
  isUserAThreadDirectRecipient?: boolean
}

export const useUnfollowThreadUseCase = () => {
  const dispatch = useDispatch()

  const { updateThreadUseCase } = useUpdateThreadUseCase()
  const { refreshThread, refreshThreadsList } = useThreadActions()

  const unfollowThreadApiCall = async ({ threadId }: IUnfollowThreadUseCaseProps) => {
    await unfollowThread(threadId)
  }

  const unfollowThreadMutation = useMutation(unfollowThreadApiCall, {
    onMutate: unfollowThreadParams => {
      const { threadId, isUserAThreadDirectRecipient } = unfollowThreadParams

      const newStateForUser: TThreadStateForUser = isUserAThreadDirectRecipient ? 'unfollowed' : 'viewed'
      const { rollbackOptimisticUpdates } = updateThreadUseCase({ threadId: threadId, stateForUser: newStateForUser })

      return rollbackOptimisticUpdates
    },
    onError: (error, unfollowThreadParams, restoreData) => {
      restoreData?.()
      dispatch(showSnackbar({ message: 'Error on unfollowing thread' }))
      unfollowThreadMutation.reset()
    },
    onSettled: (data, error, unfollowThreadParams) => {
      const { threadId } = unfollowThreadParams
      refreshThread(threadId)
      refreshThreadsList()
    },
  })

  const unfollowThreadUseCase = async ({ threadId, isUserAThreadDirectRecipient }: IUnfollowThreadUseCaseProps) => {
    await unfollowThreadMutation.mutateAsync({ threadId, isUserAThreadDirectRecipient })
  }

  return { unfollowThreadUseCase, isLoading: unfollowThreadMutation.isLoading }
}
