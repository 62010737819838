import classnames from 'classnames'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => {
  return {
    appLoading: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    '@keyframes appLoadingSpinnerDiv': {
      '0%': { opacity: 1 },
      '50%': { opacity: 0.9 },
      '100%': { opacity: 0 },
    },
    spinnerCommon: {
      animation: '$appLoadingSpinnerDiv linear 1s infinite',
      borderRadius: '40%',
      left: '10px',
      position: 'absolute',
      transformOrigin: '1px 8.5px',
      width: '1.5px',
      background: theme.palette.error.main,
    },
    appLoadingSpinner: {
      height: 20,
      position: 'relative',
      transform: 'translate(-100px, -100px) scale(1) translate(100px, 100px)',
      width: 20,
    },
    appLoadingSpinnerPart: {
      height: '4px',
    },
    appLoadingSpinnerPart1: {
      height: '3.5px',
    },
    appLoadingSpinnerPart2: {
      height: '3px',
    },
    appLoadingSpinnerPart3: {
      height: '2.5px',
    },
    appLoadingSpinnerPart4: {
      height: '2px',
    },
    appLoadingSpinnerPart5: {
      height: '1px',
    },
    appLoadingSpinnerDiv1: {
      animationDelay: '-0.99999999999s',
      transform: 'rotate(150deg)',
    },
    appLoadingSpinnerDiv2: {
      animationDelay: '-0.90909090909s',
      transform: 'rotate(180deg)',
    },
    appLoadingSpinnerDiv3: {
      animationDelay: '-0.81818181818s',
      transform: 'rotate(210deg)',
    },
    appLoadingSpinnerDiv4: {
      animationDelay: '-0.72727272727s',
      transform: 'rotate(240deg)',
    },
    appLoadingSpinnerDiv5: {
      animationDelay: '-0.63636363636s',
      transform: 'rotate(270deg)',
    },
    appLoadingSpinnerDiv6: {
      animationDelay: '-0.54545454545s',
      transform: 'rotate(300deg)',
    },
    appLoadingSpinnerDiv7: {
      animationDelay: '-0.45454545454s',
      transform: 'rotate(330deg)',
    },
    appLoadingSpinnerDiv8: {
      animationDelay: '-0.36363636363s',
      transform: 'rotate(0deg)',
    },
    appLoadingSpinnerDiv9: {
      animationDelay: '-0.27272727272s',
      transform: 'rotate(30deg)',
    },
    appLoadingSpinnerDiv10: {
      animationDelay: '-0.18181818181s',
      transform: 'rotate(60deg)',
    },
    appLoadingSpinnerDiv11: {
      animationDelay: '-0.09090909090s',
      transform: 'rotate(90deg)',
    },
  }
})

interface IProgressBarProps {
  className?: string
}

const ProgressBarSmall = ({ className }: IProgressBarProps) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.appLoading, className)}>
      <div className={classes.appLoadingSpinner}>
        <div
          className={classnames(classes.appLoadingSpinnerPart5, classes.appLoadingSpinnerDiv1, classes.spinnerCommon)}
        />
        <div
          className={classnames(classes.appLoadingSpinnerPart4, classes.appLoadingSpinnerDiv2, classes.spinnerCommon)}
        />
        <div
          className={classnames(classes.appLoadingSpinnerPart3, classes.appLoadingSpinnerDiv3, classes.spinnerCommon)}
        />
        <div
          className={classnames(classes.appLoadingSpinnerPart2, classes.appLoadingSpinnerDiv4, classes.spinnerCommon)}
        />
        <div
          className={classnames(classes.appLoadingSpinnerPart1, classes.appLoadingSpinnerDiv5, classes.spinnerCommon)}
        />
        <div
          className={classnames(classes.appLoadingSpinnerPart, classes.appLoadingSpinnerDiv6, classes.spinnerCommon)}
        />
        <div
          className={classnames(classes.appLoadingSpinnerPart, classes.appLoadingSpinnerDiv7, classes.spinnerCommon)}
        />
        <div
          className={classnames(classes.appLoadingSpinnerPart, classes.appLoadingSpinnerDiv8, classes.spinnerCommon)}
        />
        <div
          className={classnames(classes.appLoadingSpinnerPart, classes.appLoadingSpinnerDiv9, classes.spinnerCommon)}
        />
        <div
          className={classnames(classes.appLoadingSpinnerPart, classes.appLoadingSpinnerDiv10, classes.spinnerCommon)}
        />
        <div
          className={classnames(classes.appLoadingSpinnerPart, classes.appLoadingSpinnerDiv11, classes.spinnerCommon)}
        />
      </div>
    </div>
  )
}

export default ProgressBarSmall
