import React from 'react'
import { getThreadURL } from 'domain/thread'
import { CopyLinkButton } from 'component/copyLinkButton'

interface ICopyThreadLinkButtonProps {
  id?: string
  threadId: string
  outlined?: boolean
}

export const CopyThreadLinkButton = ({ id, threadId, outlined = false }: ICopyThreadLinkButtonProps) => {
  const threadUrl = getThreadURL({ id: threadId })

  return (
    <CopyLinkButton
      id={id}
      link={threadUrl.toString()}
      successSnackbarMessage="Thread link copied to clipboard"
      outlined={outlined}
    />
  )
}
