import * as React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import UnreadCounter from 'component/unreadCounter'
import { getUnreadNotificationsCount } from 'api/notifications'
import { useNotificationHandler } from 'api/websocket/actionHandlers/useNotificationHandler'
import throttle from 'lodash/throttle'
import BellIconFilled from 'icons/bellFilled'
import { getTimeInMilliSeconds } from 'dataProcessors/utils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  counter: {
    position: 'absolute',
    left: 12,
    top: 10,
  },
  icon: {
    fill: theme.threadPalette.white,
    width: 22,
    height: 22,
  },
}))

const HANDLER_KEY = 'notifications_badge'

// time between 'get unread notifications counter' calls
const WAIT_TIME = getTimeInMilliSeconds({ seconds: 20 })

interface INotificationBadgeProps {
  showUnread: boolean
  unreadCount: number
  updateUnreadCount(newValue: number): void
}

const NotificationsBadge: React.FC<INotificationBadgeProps> = ({ showUnread, unreadCount, updateUnreadCount }) => {
  const classes = useStyles()

  const fetchData = React.useCallback(async () => {
    const unread = await getUnreadNotificationsCount()
    updateUnreadCount(unread.unreadNotificationsCount)
  }, [])

  const notificationRead = React.useMemo(() => throttle(fetchData, WAIT_TIME), [fetchData])

  React.useEffect(() => {
    notificationRead()
  }, [notificationRead])

  const notificationHandlerCallback = React.useCallback((unreadQuantity: number) => {
    updateUnreadCount(unreadQuantity)
  }, [])

  useNotificationHandler({ key: HANDLER_KEY, callback: notificationHandlerCallback })

  return (
    <div className={classes.root}>
      <BellIconFilled className={classes.icon} />
      {showUnread && <UnreadCounter counter={unreadCount} className={classes.counter} />}
    </div>
  )
}

export default NotificationsBadge
