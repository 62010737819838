import React from 'react'
import { GlobalHotKeys } from 'react-hotkeys'

type ACTION_NAME = 'OPEN_SEARCH_MODAL_HOTKEYS'

export const HotKeysHandler = () => {
  const openSearchModalHandler = (event: KeyboardEvent | undefined) => {
    console.log('opening search modal.')
  }

  const hotKeysToActionMap: Record<ACTION_NAME, { hotkeys: string; action(event: KeyboardEvent | undefined): void }> = {
    OPEN_SEARCH_MODAL_HOTKEYS: {
      hotkeys: 'command+k',
      action: openSearchModalHandler,
    },
  }

  const keyMap = Object.entries(hotKeysToActionMap).reduce<Record<string, string>>(
    (accumulatedKeyMap, [actionName, { hotkeys }]) => {
      accumulatedKeyMap[actionName] = hotkeys
      return accumulatedKeyMap
    },
    {}
  )

  const handlers = Object.entries(hotKeysToActionMap).reduce<
    Record<string, (event: KeyboardEvent | undefined) => void>
  >((accumulatedHandlers, [actionName, { action }]) => {
    accumulatedHandlers[actionName] = action
    return accumulatedHandlers
  }, {})

  return <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
}
