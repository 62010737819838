import { Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%',
      backgroundColor: theme.threadPalette.white,
      '& .Mui-disabled': {
        backgroundColor: theme.threadPalette.lighterGray,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
      '& .Mui-error': {
        color: theme.palette.error.contrastText,
      },
      '& .MuiFormHelperText-root': {
        color: theme.palette.error.contrastText,
      },
    },

    fieldWrapper: {
      marginTop: 30,
    },

    fieldTitle: {
      color: theme.threadPalette.menuItem.normal,
      height: 20,
      fontSize: 17,
      lineHeight: 1.18,
      letterSpacing: 'normal',
      marginBottom: 10,
      textTransform: 'capitalize',
    },

    formFieldInputStyle: {
      padding: '10px 17px 10px 17px',
      height: 20,
    },
  })
)
