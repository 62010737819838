export default class TypingHandler {
  callbacks = new Map<string, (userId: string) => void>()

  public addCallback = (threadId: string, callback: (userId: string) => void) => {
    this.callbacks.set(threadId, callback)
  }

  public removeCallback = (threadId: string) => {
    this.callbacks.delete(threadId)
  }

  public handle = (threadId: string, userId: string) => {
    this.callbacks.get(threadId)?.(userId)
  }
}
