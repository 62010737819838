import React from 'react'
import { IOnboardingTourStep } from 'contexts/useOnboardingTourContext'
import {
  createUntargetedOnboardingStep,
  createTargetedOnboardingStep,
  OnboardingTourTargetComponent,
} from 'domain/onboardingTourStep'
import { CopyThreadLinkStep } from 'component/onboarding/threadOnboarding/copyThreadLinkStep'
import { WelcomeToSharedThreadStep } from 'component/onboarding/threadOnboarding/welcomeToSharedThreadStep'
import { SharedThreadContentStep } from 'component/onboarding/threadOnboarding/sharedThreadContentStep'
import { ViewAccessBadgeStep } from 'component/onboarding/threadOnboarding/viewAccessBadgeStep'
import { CreateNewThreadStep } from 'component/onboarding/threadOnboarding/createNewThreadStep'
import { useFullScreen } from 'hooks/useFullScreen'

export const useSharedThreadOnboardingSteps = () => {
  const { isFullScreen } = useFullScreen('md')

  const createThreadContentStep = ({ isFullScreen }: { isFullScreen: boolean }) => {
    const commonStepProps = {
      content: <SharedThreadContentStep isFullScreen={isFullScreen} />,
      showProgress: true,
    }

    if (isFullScreen) return createUntargetedOnboardingStep(commonStepProps)

    return createTargetedOnboardingStep({
      ...commonStepProps,
      target: OnboardingTourTargetComponent.threadPage,
      placement: 'left',
      spotlightPadding: -10,
    })
  }

  const getSteps = React.useCallback(
    (props = {}): IOnboardingTourStep[] => {
      return [
        createUntargetedOnboardingStep({
          content: <WelcomeToSharedThreadStep />,
        }),
        createThreadContentStep({ isFullScreen }),
        createTargetedOnboardingStep({
          content: <CopyThreadLinkStep />,
          target: OnboardingTourTargetComponent.copyThreadLinkButton,
          placement: 'bottom',
          spotlightPadding: 4,
          showProgress: true,
        }),
        createTargetedOnboardingStep({
          content: <ViewAccessBadgeStep />,
          target: OnboardingTourTargetComponent.threadFollowBadge,
          placement: 'bottom',
          spotlightPadding: 4,
          showProgress: true,
        }),
        createTargetedOnboardingStep({
          content: <CreateNewThreadStep />,
          target: OnboardingTourTargetComponent.createNewThreadButton,
          placement: 'right',
          spotlightPadding: 0,
        }),
      ]
    },
    [isFullScreen]
  )

  return { getSteps }
}
