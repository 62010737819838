import React from 'react'
import { Button, Theme, Typography, fade, makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useReviewHeldMessagesButtonStyles = makeStyles((theme: Theme) => ({
  reviewHeldMessagesButtonRoot: {
    borderColor: theme.threadPalette.brightOrange,
    paddingBlock: 6,
    paddingInline: 10,
    '&:hover': {
      backgroundColor: fade(theme.threadPalette.brightOrange, 0.15),
    },
  },
  reviewHeldMessagesButtonLabel: {
    color: theme.threadPalette.brightOrange,
    fontSize: 16,
    fontWeight: 500,
  },
}))

interface IReviewHeldMessagesButtonProps {
  onClick(): void
  className?: string
}

export const ReviewHeldMessagesButton = ({ onClick, className }: IReviewHeldMessagesButtonProps) => {
  const classes = useReviewHeldMessagesButtonStyles()

  return (
    <Button
      classes={{ root: classNames(classes.reviewHeldMessagesButtonRoot, className) }}
      onClick={onClick}
      variant="outlined"
    >
      <Typography className={classes.reviewHeldMessagesButtonLabel}>
        <span role="img" aria-label="warning">
          ⚠️
        </span>{' '}
        Review held messages
      </Typography>
    </Button>
  )
}
