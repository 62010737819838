import * as React from 'react'
import uniqBy from 'lodash/uniqBy'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import useMessageAttachments from '../../hooks/useMessageAttachments/attachments'
import DragDropArea from 'component/dragDrop/dragDropArea'
import DragDropOverlay from 'component/dragDrop/dragDropOverlay'
import RecipientsBlock from './recipientsBlock'
import classNames from 'classnames'
import MessageEditorHeader from './messageEditorHeader'
import { TDraftType } from 'component/message/draftMessage/draftMessage'
import { useUser } from 'hooks'
import ShowRepliedOrForwardedMessageContentButton from './showRepliedOrForwardedMessageContentButton'
import MessageEditorFooter from './messageEditorFooter'
import RichTextEditor from './richTextEditor'
import IAttachment from 'model/attachment'
import AttachmentList from 'component/attachmentList'
import { useRichTextActionsContext } from './useRichTextActionsContext'
import { IShortcut } from 'model/shortcut'
import Spacer from 'component/layoutUtils/spacer'
import { useDraftMessageContext } from 'component/message/draftMessage/useDraftMessageContext'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import MessageSendButton from './messageSendButton'
import ErrorDialogAnchoredOnComponent from 'component/dialogAnchoredOnComponent'
import { useCurrentThreadContext } from 'contexts/currentThreadContext'
import { useThreadFollowers } from 'hooks/useThreadFollowers/useThreadFollowers'
import { useUpdateDraft } from 'hooks/useUpdateDraft'
import { useSendDraftUseCase } from 'usecases/draft/sendDraft'
import { getComponentPosition } from 'lib/elementTracking'
import { HTMLElement as NodeHTMLElement } from 'node-html-parser'
import { usePeopleEmailCheck } from 'hooks/usePeopleEmailCheck'
import { useTheme } from '@material-ui/core'
import { TDraftProcessingErrorReason } from 'usecases/draft/errors'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'actions/snackbar'
import { IDialogAnchoredOnComponentProps } from 'component/dialogAnchoredOnComponent/dialogAnchoredOnComponent'
import WrapIntoSkeletonLoading from 'component/wrapIntoSkeletonLoading'
import { TDraftSyncState } from 'domain/message'

const EDITOR_BOTTOM_OFFSET_IN_PX = 20

const useMessageEditorStyles = makeStyles((theme: Theme) => ({
  messageEditorRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
  },
  messageEditorRootExpanded: {
    height: 'calc(100vh - 60px)',
    left: 0,
    margin: 35,
    marginTop: 24,
    position: 'fixed',
    top: 0,
    width: 'calc(100vw - 70px)',
    zIndex: theme.zIndex.drawer,
  },
  messageEditorContentContainer: {
    backgroundColor: theme.threadPalette.white,
    border: `solid 2px ${theme.threadPalette.lightBlue}`,
    borderRadius: 8,
    borderTopLeftRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxHeight: '70vh',
    minHeight: 0,
    minWidth: 0,
    padding: 14,
    paddingBottom: 12,
  },
  messageEditorContentContainerSkeleton: {
    padding: 0,
  },
  messageEditorContentContainerExpanded: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  dragDropOverlay: {
    borderRadius: 6,
  },
  header: {
    marginBottom: 12,
  },
}))

type IErrorDialogMessageOption = Pick<IDialogAnchoredOnComponentProps, 'message' | 'actions'> | undefined

interface IMessageEditorProps {
  draftIndex?: string
  draftType: TDraftType
  draftId: string
  repliedOrForwardedMessage?: IMessage
  onDeleteMessage?(): void
  initialToRecipients?: TDraftRecipient[]
  initialBccRecipients?: TDraftRecipient[]
  initialAttachments?: IAttachment[]
  previousMessageContent?: string
  initialIsPreviousMessageContentEdited?: boolean
  isInFocusMode: boolean
  setIsInFocusMode(newIsInFocusMode: boolean): void
  threadId: string
  isCollapsed: boolean
  topBlockComponent?: JSX.Element
  setHasErrorOnSavingDraft: (hasError: boolean) => void
  containerRef: React.MutableRefObject<HTMLDivElement | null>
  isLoading?: boolean
  draftSyncState: TDraftSyncState
}

const MessageEditor = ({
  draftId,
  draftIndex = '',
  draftType,
  repliedOrForwardedMessage,
  onDeleteMessage,
  initialToRecipients = [],
  initialBccRecipients = [],
  initialAttachments = [],
  isInFocusMode,
  setIsInFocusMode,
  threadId,
  topBlockComponent,
  previousMessageContent,
  initialIsPreviousMessageContentEdited = false,
  isCollapsed,
  setHasErrorOnSavingDraft,
  containerRef,
  isLoading,
  draftSyncState,
}: IMessageEditorProps) => {
  const theme = useTheme()
  const classes = useMessageEditorStyles()

  const dispatch = useDispatch()

  const { currentThread } = useCurrentThreadContext()
  const currentUser = useUser()
  const { followers: threadFollowers } = useThreadFollowers(currentThread?.id)
  const editorRef = React.useRef<HTMLDivElement | null>(null)

  const [toRecipients, setToRecipients] = React.useState<TDraftRecipient[]>(initialToRecipients)
  const onUpdateToRecipients = (recipients: TDraftRecipient[]): void => {
    const uniqueRecipients = uniqBy(recipients, 'email')
    setToRecipients(uniqueRecipients)
  }

  const [bccRecipients, setBccRecipients] = React.useState<TDraftRecipient[]>(initialBccRecipients)
  const onUpdateBccRecipients = (bccRecipients: TDraftRecipient[]): void => {
    const uniqueBccRecipients = uniqBy(bccRecipients, 'email')
    setBccRecipients(uniqueBccRecipients)
  }

  const [isPreviousMessageContentEdited, setIsPreviousMessageContentEdited] = React.useState(
    initialIsPreviousMessageContentEdited
  )

  const { focusToRecipients } = useDraftMessageContext()

  const isVisibleShowPreviousMessagesContent =
    repliedOrForwardedMessage !== undefined && !isPreviousMessageContentEdited

  const {
    richTextContent: messageContent,
    setRichTextContent: setMessageContent,
    DEFAULT_EMPTY_INPUT_VALUE,
    insertTextAtSelection,
    insertTextAtTheEnd,
    insertDividerAtTheEnd,
    insertImageAtSelection,
    undo,
    redo,
    getExistingMentionsFromContent,
    standardizeNestedLists,
  } = useRichTextActionsContext()

  const onAttachmentUploadError = (attachment?: IAttachment) => {
    const message = attachment ? `Failed to upload ${attachment.filename} file.` : 'Failed to update the file.'
    dispatch(showSnackbar({ message }))
  }

  const {
    attachments,
    uploadStatuses,
    uploadAttachments,
    deleteAttachment,
    onFilePaste,
    uploadsInProgress,
    attachmentsSize,
  } = useMessageAttachments({ initialAttachments, onAttachmentUploadError })

  const onPasteInlineAttachment = (event: React.ClipboardEvent<HTMLElement>) => {
    onFilePaste(event, insertImageAtSelection)
  }

  const {
    isOpen: isErrorDialogOpen,
    dialogAnchorElement: errorDialogAnchorElement,
    openDialog: openErrorDialog,
    handleCloseDialog: closeErrorDialog,
    setDialogAnchorRef: sendButtonRef,
  } = useDialog()

  const {
    isOpen: isAttachmentErrorDialogOpen,
    dialogAnchorElement: attachmentErrorDialogAnchorElement,
    openDialog: openAttachmentErrorDialog,
    handleCloseDialog: closeAttachmentErrorDialog,
    setDialogAnchorRef: draftModalRef,
  } = useDialog()

  const onDraftUpdateError = (reason?: TDraftProcessingErrorReason) => {
    if (reason === 'message_not_a_draft') {
      openErrorDialog()
    }
  }

  const { sendDraftUseCase, isSending } = useSendDraftUseCase()

  const {
    hasErrorOnSaving,
    errorReason: draftUpdateErrorReason,
    onForcedUpdate,
  } = useUpdateDraft({
    toRecipients,
    bccRecipients,
    content: messageContent,
    draftId,
    threadId,
    inlineAttachments: attachments.inline,
    standardAttachments: attachments.standard,
    isPreviousMessageContentEdited,
    repliedOrForwardedMessageId: repliedOrForwardedMessage?.id ?? '',
    isAttachmentsUploadInProgress: uploadsInProgress > 0,
    isSendingDraft: isSending,
    onUpdateError: onDraftUpdateError,
  })

  React.useEffect(() => {
    setHasErrorOnSavingDraft(hasErrorOnSaving)
  }, [hasErrorOnSaving])

  const addRepliedOrForwardedMessageContentToEndOfMessage = () => {
    if (!previousMessageContent) return

    insertDividerAtTheEnd()
    insertTextAtTheEnd(previousMessageContent, { keepFormatting: true })

    setIsPreviousMessageContentEdited(true)
  }

  const { errorMessage: peopleEmailErrorMessage } = usePeopleEmailCheck({
    people: [...toRecipients, ...bccRecipients].map(recipient => {
      return { ...recipient, name: recipient?.name ?? null, avatarUrl: recipient?.avatarUrl ?? null }
    }),
    errorMessageOnEmptyList:
      'There are no recipients added to this message. Please add at least one recipient and try again',
  })

  const errorDialogMessageOptions: IErrorDialogMessageOption = React.useMemo(() => {
    const deleteDraftActions = [
      {
        label: 'Cancel',
        action: closeErrorDialog,
      },
      {
        label: 'Delete',
        labelColor: theme.palette.error.contrastText,
        action: onDeleteMessage,
      },
    ]

    if (draftUpdateErrorReason === 'message_not_a_draft')
      return {
        actions: deleteDraftActions,
        message: "Can't update / send message to the email provider as it's already sent. Do you want to delete it?",
      }
    if (peopleEmailErrorMessage) return { actions: undefined, message: peopleEmailErrorMessage }
  }, [draftUpdateErrorReason, peopleEmailErrorMessage])

  const onSendMessage = React.useCallback(async () => {
    if (errorDialogMessageOptions) return openErrorDialog()

    const isEmptyMessage = messageContent === DEFAULT_EMPTY_INPUT_VALUE || messageContent.length === 0
    if (isSending || isEmptyMessage || !currentThread || !currentUser) return

    const mentionedUsers = getExistingMentionsFromContent()
    const uniqueMentionedUsers = uniqBy(mentionedUsers, 'id')

    const messageContentWithStandardizedLists = standardizeNestedLists(messageContent, (listItem: NodeHTMLElement) => {
      const liClass = listItem.getAttribute('class') ?? null
      return liClass === null ? 0 : parseInt(liClass.split('-').pop() as string)
    })

    sendDraftUseCase({
      content: messageContentWithStandardizedLists,
      toRecipients,
      bccRecipients,
      draftId,
      inlineAttachments: attachments.inline,
      standardAttachments: attachments.standard,
      isPreviousMessageContentEdited,
      mentionedUsers: uniqueMentionedUsers,
      previousMessageContent: previousMessageContent ?? '',
      repliedOrForwardedMessageId: repliedOrForwardedMessage?.id,
      sender: currentUser,
      threadId: threadId,
    })
  }, [
    currentThread,
    messageContent,
    toRecipients,
    bccRecipients,
    attachments,
    isPreviousMessageContentEdited,
    previousMessageContent,
    draftId,
    repliedOrForwardedMessage,
    sendDraftUseCase,
    getExistingMentionsFromContent,
    isSending,
    errorDialogMessageOptions,
  ])

  const attachmentUploadErrorMessage = React.useMemo(() => {
    if (!currentUser) return ''

    const attachmentSizeLimitations = currentUser.attachmentSizeLimitations

    const OneMBInBytes = Math.pow(2, 20)

    if (attachmentSizeLimitations?.maxSingleFileSize) {
      return `Can't upload attachment. Its size is more than ${
        attachmentSizeLimitations.maxSingleFileSize / OneMBInBytes
      } MB.`
    }

    if (attachmentSizeLimitations?.maxTotalFilesSize) {
      return `Can't upload attachment. Total attachments size is more than ${
        attachmentSizeLimitations.maxTotalFilesSize / OneMBInBytes
      } MB.`
    }

    return ''
  }, [currentUser])

  const getFollowersByNameOrEmail = React.useCallback(
    (searchTerm?: string): IUser[] => {
      const followersWithoutCurrentUser = threadFollowers.filter(follower => follower.email !== currentUser?.email)
      if (!searchTerm) return followersWithoutCurrentUser

      const lowerCaseSearchTerm = searchTerm.toLowerCase()
      const followersMatchingNameOrEmail = followersWithoutCurrentUser.filter(follower => {
        const followerLowerCaseName = follower.name?.toLowerCase() ?? ''
        const followerLowerCaseEmail = follower.email.toLowerCase()
        return (
          followerLowerCaseName.includes(lowerCaseSearchTerm) || followerLowerCaseEmail.includes(lowerCaseSearchTerm)
        )
      })

      return followersMatchingNameOrEmail
    },
    [currentThread?.id, threadFollowers]
  )

  const onMention = React.useCallback(
    (mentionedRecipient: TDraftRecipient): void => {
      const isEmailInToRecipients = toRecipients.find(recipient => recipient.email === mentionedRecipient.email)
      if (isEmailInToRecipients) return

      setToRecipients(previousRecipients => {
        const updatedRecipients = [...previousRecipients, mentionedRecipient]
        return updatedRecipients
      })
    },
    [toRecipients, setToRecipients]
  )

  const isSendMessageEnabled = (): boolean => {
    const hasNoUploadsInProgress = uploadsInProgress === 0
    const isDraftSavedOnExternalSource = previousMessageContent !== undefined
    const isFirstMessage = draftType === 'first_message'

    // For first message, we won't be creating the draft until the user starts typing something, where we'll create / sync the draft to avoid creating multiple drafts on every page load
    return hasNoUploadsInProgress && (isDraftSavedOnExternalSource || isFirstMessage)
  }

  const onMentionsButtonClick = () => {
    insertTextAtSelection(' @', { focusOnInsert: true })
  }

  const shortcuts: IShortcut[] = React.useMemo(
    () => [
      {
        isShortcutKeys: (event: React.KeyboardEvent) => event.key === 'Enter' && (event.ctrlKey || event.metaKey),
        runAction: () => isSendMessageEnabled() && onSendMessage(),
      },
      {
        // Easter egg shortcut :)
        isShortcutKeys: (event: React.KeyboardEvent) => event.key === '0' && (event.ctrlKey || event.metaKey),
        runAction: () => insertTextAtSelection("Hi, it's great that you're using Threadlive!", { focusOnInsert: true }),
      },
    ],
    [isSendMessageEnabled, onSendMessage, insertTextAtTheEnd]
  )

  const toolbarId = `toolbar-${draftIndex}`

  // When receiving the previous messages content, and the draft type is a forward, it should automatically add it to the content
  React.useEffect(() => {
    if (draftType === 'forward' && isVisibleShowPreviousMessagesContent) {
      addRepliedOrForwardedMessageContentToEndOfMessage()

      // Workaround to avoid losing focus on ToRecipients field
      focusToRecipients()
    }
  }, [previousMessageContent])

  const onUploadAttachments = React.useCallback(
    async (files: File[]) => {
      if (!currentUser) return

      const maxAttachmentSize = Math.max(...files.map(file => file.size))
      const totalAttachmentsSize = attachmentsSize + files.reduce((sum, current) => sum + current.size, 0)

      const attachmentLimits = currentUser.attachmentSizeLimitations

      if (attachmentLimits?.maxSingleFileSize && maxAttachmentSize > attachmentLimits.maxSingleFileSize) {
        return openAttachmentErrorDialog()
      }
      if (attachmentLimits?.maxTotalFilesSize && totalAttachmentsSize > attachmentLimits.maxTotalFilesSize) {
        return openAttachmentErrorDialog()
      }

      uploadAttachments(files)
    },
    [uploadAttachments, attachmentsSize, currentUser, openAttachmentErrorDialog]
  )

  const sendButton = (
    <MessageSendButton
      ref={sendButtonRef}
      onSend={onSendMessage}
      isSending={isSending}
      isDisabled={!isSendMessageEnabled()}
      isErrorDialogOpen={isErrorDialogOpen}
      errorMessage={errorDialogMessageOptions?.message}
      errorDialogActions={errorDialogMessageOptions?.actions}
      errorDialogAnchorElement={errorDialogAnchorElement}
      onCloseErrorDialog={closeErrorDialog}
    />
  )

  const isFirstSavingDraft = previousMessageContent === undefined
  const isShowAddPreviousMessagesButton = () => {
    const isButtonVisibleWithExistingPreviousMessageContent =
      isVisibleShowPreviousMessagesContent && previousMessageContent
    const isFirstMessage = draftType === 'first_message'

    return (isFirstSavingDraft && !isFirstMessage) || isButtonVisibleWithExistingPreviousMessageContent
  }

  const scrollEditorToBottom = React.useCallback(() => {
    if (!editorRef.current) return
    if (!containerRef?.current) return

    const { bottom } = getComponentPosition(editorRef)
    const editorBottomDistanceToWindowEnd = bottom - window.innerHeight

    const isCutBelowTheFold = editorBottomDistanceToWindowEnd > 0
    if (!isCutBelowTheFold) return

    containerRef.current.scrollTop += editorBottomDistanceToWindowEnd + EDITOR_BOTTOM_OFFSET_IN_PX
  }, [editorRef, containerRef])

  const onContentChange = React.useCallback(
    (content: string) => {
      setMessageContent(content)
      scrollEditorToBottom()
    },
    [setMessageContent, scrollEditorToBottom]
  )

  if (!currentUser) return null

  return (
    <DragDropArea
      className={classNames(classes.messageEditorRoot, { [classes.messageEditorRootExpanded]: isInFocusMode })}
      onFilesDrop={onUploadAttachments}
      overlay={<DragDropOverlay className={classes.dragDropOverlay} />}
      ref={editorRef}
    >
      <ErrorDialogAnchoredOnComponent
        open={isAttachmentErrorDialogOpen}
        anchoredElementRef={attachmentErrorDialogAnchorElement}
        title="Error"
        message={attachmentUploadErrorMessage}
        onClose={closeAttachmentErrorDialog}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {topBlockComponent}
        <WrapIntoSkeletonLoading
          isLoading={isLoading}
          variant="rect"
          className={classNames(classes.messageEditorContentContainer, classes.messageEditorContentContainerSkeleton)}
          animation="wave"
          width={'100%'}
        >
          <div
            className={classNames(classes.messageEditorContentContainer, {
              [classes.messageEditorContentContainerExpanded]: isInFocusMode,
            })}
            ref={draftModalRef}
          >
            {!isCollapsed && (
              <MessageEditorHeader
                draftType={draftType}
                className={classes.header}
                repliedOrForwardedMessage={repliedOrForwardedMessage}
                showDeleteDraftButton={!!repliedOrForwardedMessage}
                onDeleteMessage={() => onDeleteMessage?.()}
                isInFocusMode={isInFocusMode}
                toggleExpanded={() => setIsInFocusMode(!isInFocusMode)}
                draftSyncState={draftSyncState}
                emailProvider={currentUser.emailProvider}
                onForcedUpdate={onForcedUpdate}
                hasUploadsInProgress={uploadsInProgress !== 0}
              />
            )}
            <RecipientsBlock
              toRecipients={toRecipients}
              bccRecipients={bccRecipients}
              setToRecipients={onUpdateToRecipients}
              setBccRecipients={onUpdateBccRecipients}
              isEditable={!isCollapsed}
              shouldAutoFocus={draftType === 'forward'}
            />
            {!isCollapsed && (
              <>
                <Spacer height={12} />
                <RichTextEditor
                  textContent={messageContent}
                  onChange={onContentChange}
                  onFilePaste={onPasteInlineAttachment}
                  placeholder="Type your message here"
                  getMentionOptionsFromSearchTerm={getFollowersByNameOrEmail}
                  toolbarId={toolbarId}
                  shortcuts={shortcuts}
                  onMention={onMention}
                />
                {isShowAddPreviousMessagesButton() && (
                  <div>
                    <ShowRepliedOrForwardedMessageContentButton
                      onClick={() => addRepliedOrForwardedMessageContentToEndOfMessage()}
                      isLoading={isFirstSavingDraft}
                    />
                  </div>
                )}
                <AttachmentList
                  attachments={attachments.standard}
                  onDelete={deleteAttachment}
                  attachmentUploadStatuses={uploadStatuses}
                />
                <MessageEditorFooter
                  sendButton={sendButton}
                  toolbarId={toolbarId}
                  onUploadAttachments={onUploadAttachments}
                  onEmojiSelected={insertTextAtSelection}
                  onMentionsClick={onMentionsButtonClick}
                  onUndo={undo}
                  onRedo={redo}
                />
              </>
            )}
          </div>
        </WrapIntoSkeletonLoading>
      </ErrorDialogAnchoredOnComponent>
    </DragDropArea>
  )
}

export default MessageEditor
