import * as React from 'react'

export function useTabs(preselectedTab?: number) {
  const [selectedTab, selectTab] = React.useState(preselectedTab || 0)

  const handleSelectTab = React.useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    selectTab(newValue)
  }, [])

  return { selectedTab, handleSelectTab }
}
