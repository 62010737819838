import React from 'react'
import { IconButton, InputBase, InputBaseProps, Theme } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import ConfirmIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import AppLoading from 'component/appLoading'
import Spacer from 'component/layoutUtils/spacer'
import { useBoolean } from 'hooks/useBoolean'
import classNames from 'classnames'

const useInlineEditingTextFieldStyles = makeStyles((theme: Theme) => ({
  labelWithEditContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  labelWithError: {
    color: theme.palette.error.main,
    textDecoration: 'underline',
  },
  editIconContainer: {
    color: 'inherit',
    height: 'fit-content',
    marginLeft: 4,
  },
  editIcon: {
    fontSize: 13,
  },
  editForm: {
    alignItems: 'center',
    display: 'inline-flex',
    width: '100%',
  },
  editInputContainer: {
    backgroundColor: theme.threadPalette.white,
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: 4,
    color: theme.threadPalette.brownishGray,
    fontSize: 12,
    height: 25,
    paddingInline: 6,
    marginBlock: 6,
  },
  editInput: {
    '&::placeholder': {
      fontStyle: 'italic',
    },
  },
  confirmEditingIcon: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    fontSize: 16,
  },
  cancelEditingIcon: {
    color: theme.palette.error.contrastText,
    cursor: 'pointer',
    fontSize: 16,
  },
}))

interface IInlineEditingTextFieldProps {
  children?: React.ReactNode | ((editingButton: JSX.Element) => React.ReactNode)
  onConfirmEdit?(newValue?: string): Promise<void> | void
  onCancelEdit?(): void
  onStopEditing?(): void
  isEditing?: boolean
  inputProps?: Omit<InputBaseProps, 'className'>
  labelClassName?: string
  editIconClassName?: string
  inputContainerClassName?: string
  inputClassName?: string
  autoFocus?: boolean
  hideCancelEditingButton?: boolean
  hasError?: boolean
  disableEditing?: boolean
}

const InlineEditingTextField = ({
  children,
  onConfirmEdit,
  onCancelEdit,
  onStopEditing,
  inputProps = {},
  labelClassName,
  editIconClassName,
  inputContainerClassName,
  inputClassName,
  autoFocus = false,
  hideCancelEditingButton = false,
  hasError = false,
  disableEditing = false,
}: IInlineEditingTextFieldProps) => {
  const classes = useInlineEditingTextFieldStyles()

  const [isUpdating, setIsUpdating] = React.useState<boolean>(false)
  const { state: isEditing, setTrue: startEditing, setFalse: stopEditing } = useBoolean(autoFocus || !!!children)
  const editInputRef = React.useRef<HTMLInputElement>()

  const onCloseEditing = () => {
    onStopEditing?.()
    stopEditing()
  }
  const onUpdate = async () => {
    const updatedValue = editInputRef.current?.value
    setIsUpdating(true)

    onConfirmEdit && (await onConfirmEdit(updatedValue))

    setIsUpdating(false)
    onCloseEditing()
  }

  const onCancel = () => {
    onCancelEdit?.()
    onCloseEditing()
  }

  const onSubmitInput = (event: React.FormEvent) => {
    event.preventDefault()
    onUpdate()
  }
  const editActions = (
    <>
      <Spacer width={4} />
      <IconButton size="small" onClick={onUpdate}>
        <ConfirmIcon className={classes.confirmEditingIcon} />
      </IconButton>
      {!hideCancelEditingButton && (
        <IconButton size="small" onClick={onCancel}>
          <CancelIcon className={classes.cancelEditingIcon} />
        </IconButton>
      )}
    </>
  )

  const updatingIcon = (
    <>
      <Spacer width={8} />
      <AppLoading visible small padding={4} legHeight={16} legWidth={6} />
    </>
  )

  const processShortcuts = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape' && !hideCancelEditingButton) onCancel()
  }

  const editingButton = (
    <IconButton size="small" classes={{ root: classes.editIconContainer }} onClick={startEditing}>
      <EditIcon className={editIconClassName ?? classes.editIcon} />
    </IconButton>
  )

  const isChildrenDirectlyUsingEditingButton = typeof children === 'function'
  const parsedChildren = isChildrenDirectlyUsingEditingButton ? (
    children(editingButton)
  ) : (
    <>
      {children}
      {!disableEditing && editingButton}
    </>
  )
  return (
    <>
      {!isEditing && (
        <div
          className={classNames(classes.labelWithEditContainer, { [classes.labelWithError]: hasError }, labelClassName)}
        >
          {parsedChildren}
        </div>
      )}
      {isEditing && (
        <form onSubmit={onSubmitInput} className={classNames(classes.editForm, inputContainerClassName)}>
          <InputBase
            classes={{ root: classNames(classes.editInputContainer, inputClassName), input: classes.editInput }}
            autoFocus
            fullWidth
            {...inputProps}
            inputRef={editInputRef}
            endAdornment={isUpdating ? updatingIcon : editActions}
            onKeyUp={processShortcuts}
          />
        </form>
      )}
    </>
  )
}

export default InlineEditingTextField
