import * as React from 'react'
import { Typography, TextField } from '@material-ui/core'
import { useStyles } from './formikTextField.style'
import classNames from 'classnames'
import { TextFieldProps } from 'material-ui'

interface IFormikTextFieldProps extends TextFieldProps {
  title?: string
  id?: string
  name?: string
  type?: string
  onChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
  value?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  labelClasses?: string
  fieldWrapperClasses?: string
  inputProps?: any
  InputProps?: any
  multiLine?: boolean
  error?: boolean
  errorText?: string
  dataCy?: string
}
const FormikTextField = ({
  title,
  onChange,
  value,
  id,
  name,
  type,
  placeholder,
  disabled,
  className,
  labelClasses,
  fieldWrapperClasses,
  InputProps,
  inputProps,
  multiLine,
  error,
  errorText,
  dataCy,
  ...props
}: IFormikTextFieldProps) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.fieldWrapper, fieldWrapperClasses)}>
      {title && <Typography className={classNames(classes.fieldTitle, labelClasses)}>{title}</Typography>}
      <TextField
        classes={{
          root: classes.input,
        }}
        id={id}
        data-cy={dataCy}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        variant="outlined"
        className={className}
        multiline={multiLine}
        placeholder={placeholder}
        error={error}
        helperText={errorText}
        disabled={disabled}
        InputProps={InputProps}
        inputProps={{
          className: classes.formFieldInputStyle,
          ...inputProps,
        }}
        {...props}
      />
    </div>
  )
}

export default FormikTextField
