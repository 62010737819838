import { createHash } from 'crypto'
import { DateTime } from 'luxon'
import IAttachment, { IInlineAttachment, TAttachmentContentType } from 'model/attachment'

interface ICreateAttachmentFromFileProps {
  file: File
  attachmentId: string
  owner: Pick<IUser, 'id' | 'name' | 'email'>
}

export const createStandardAttachmentFromFile = ({
  file,
  attachmentId,
  owner,
}: ICreateAttachmentFromFileProps): IAttachment => {
  const now = DateTime.fromMillis(Date.now())

  return {
    id: attachmentId,
    filename: file.name,
    createdAt: now.toISO(),
    owner: owner,
    size: file.size,
    fileType: guessFileTypeFromFileName(file.name),
  }
}

export const createInlineAttachmentFromFile = async ({
  file,
  attachmentId,
  contentId,
  owner,
}: ICreateAttachmentFromFileProps & { contentId: string }): Promise<IInlineAttachment> => {
  const attachment = createStandardAttachmentFromFile({ file, attachmentId, owner })

  const attachmentContent = await getAttachmentContent(file)
  const contentHash = createHash('sha256').update(attachmentContent).digest('hex')

  return { ...attachment, contentId, contentHash }
}

export const guessFileTypeFromFileName = (fileName: string): string | undefined => {
  const fileNameParts = fileName.split('.')

  if (fileNameParts.length < 2) return

  const fileType = fileNameParts.pop()

  // checking if the file type is valid by guessing the content type
  // if content type is unknown, the last part of the file name is probably not a file type
  const contentType = getAttachmentContentType({ fileType })
  return contentType === 'unknown' ? undefined : fileType
}

export const getAttachmentContent = async (file: File | Blob) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event: any) => {
      const data = event.target.result
      resolve(data)
    }
    reader.readAsDataURL(file)
  })
}

export const humanizeAttachmentSize = ({ size }: Pick<IAttachment, 'size'>) => {
  const BASE = 1024
  const UNITS = ['B', 'kB', 'MB', 'GB', 'TB']

  const unitIndex = Math.floor(Math.log(size) / Math.log(BASE))
  const fileSize = (size / Math.pow(BASE, unitIndex)).toFixed(2)

  return `${fileSize} ${UNITS[unitIndex]}`
}

export const getAttachmentContentType = ({ fileType }: Pick<IAttachment, 'fileType'>): TAttachmentContentType => {
  if (!fileType) return 'unknown'

  const normalizedFileType = fileType.toLowerCase()

  const contentTypeToFileTypesMapping: Record<TAttachmentContentType, string[]> = {
    text: ['doc', 'docx', 'dot', 'docm', 'dotx', 'dotm', 'docb'],
    image: ['jpeg', 'jpg', 'tiff', 'tif', 'gif', 'png', 'webp'],
    spreadsheet: ['xls', 'xlsb', 'xlsm', 'xlsx'],
    video: ['mp4', '3gp', 'ogg', 'wmv', 'avi', 'webm', 'mov', 'flv'],
    audio: ['mp3', 'wav', 'aac', 'wma', 'flac'],
    pdf: ['pdf'],
    presentation: ['pptx', 'ppsx', 'odp'],
    unknown: [],
  }

  const contentTypes = Object.keys(contentTypeToFileTypesMapping) as TAttachmentContentType[]
  const contentType = contentTypes.find(contentType =>
    contentTypeToFileTypesMapping[contentType].includes(normalizedFileType)
  )

  return contentType || 'unknown'
}

export const isAttachmentConvertible = ({ fileType }: Pick<IAttachment, 'fileType'>) =>
  fileType && ['docx', 'doc', 'csv', 'xlsx'].includes(fileType)
