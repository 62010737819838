import * as React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { AuthenticatedRoute, UnauthenticatedRouteWithUserAuthOnboarding } from './middlewares'
import GoogleOAuthCallbackPage from '../page/googleOAuthCallbackPage'
import GoogleOAuthLoginPage from '../page/googleOAuthLoginPage'
import Outlook365OAuthCallbackPage from '../page/outlook365OAuthCallbackPage'
import Outlook365OAuthLoginPage from '../page/outlook365OAuthLoginPage'
import SigninPage from '../page/signinPage'
import ThreadPage from '../page/thread/thread'
import HomePage from '../page/homePage'
import LiveLinkPage from '../page/liveLinkPage'
import AccountPage from '../page/accountPage'
import ManagementPage from '../page/managementPage'
import { useDispatch } from 'react-redux'
import { push } from 'actions/navigation'
import { AccessCheckRoute } from 'lib/accessCheck'
import { useUser } from 'hooks'
import NotFoundPage from 'page/notFound'
import CalendarSupportCallPage from 'page/bookSupportCall'
import ProjectsPage from 'page/projects/projectsPage'
import AssistantPage from 'page/assistant/assistantHomePage'

const Routes = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const user = useUser()

  const lastOpenedThreadId = user?.lastOpenedThreadId

  const isUserWithLastOpenedThread = pathname === '/' && lastOpenedThreadId
  if (isUserWithLastOpenedThread) {
    dispatch(push(`/threads/${lastOpenedThreadId}`))
    return null
  }

  return (
    <Switch>
      <Route path="/signin" component={SigninPage} />
      <Route path="/oauth/google/login" component={GoogleOAuthLoginPage} />
      <Route path="/oauth/google/callback" component={GoogleOAuthCallbackPage} />
      <Route path="/oauth/outlook365/login" component={Outlook365OAuthLoginPage} />
      <Route path="/oauth/microsoft/callback" component={Outlook365OAuthCallbackPage} />
      <Route path="/live-link/:id" component={LiveLinkPage} />
      <Route path="/book-support-call" component={CalendarSupportCallPage} />
      <AuthenticatedRoute exact path="/" component={HomePage} />
      <UnauthenticatedRouteWithUserAuthOnboarding path="/threads/:id" component={ThreadPage} />
      <AuthenticatedRoute exact path="/projects/:id?" component={ProjectsPage} />
      <AuthenticatedRoute exact path="/assistant" component={AssistantPage} />
      <Redirect from="/chats/:id" to="/threads/:id" />
      <AccessCheckRoute requiredPermissions={['user_registered']} exact path="/new-thread" component={HomePage} />
      <AuthenticatedRoute exact path="/profile" component={AccountPage} />
      <AccessCheckRoute requiredPermissions={['user_registered']} exact path="/management" component={ManagementPage} />
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  )
}

export default Routes
