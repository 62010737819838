import React from 'react'
import ThreadHeaderBar from '../threadHeaderBar'
import { ShareThreadButton } from './shareThreadButton'
import { CopyThreadLinkButton } from './copyThreadLinkButton'
import { BaseHeaderWithActions } from 'component/baseHeaderWithActions'
import { OnboardingTourTargetComponent } from 'domain/onboardingTourStep'
import { LabelWithLoadingIndicator } from 'component/labelWithLoadingIndicator/labelWithLoadingIndicator'
import { Theme, makeStyles, useTheme } from '@material-ui/core'

const useThreadHeaderStyles = makeStyles((theme: Theme) => ({
  importingLabel: {
    backgroundColor: theme.threadPalette.skyBlue,
    borderRadius: 4,
    color: theme.threadPalette.white,
    paddingBlock: 4,
    paddingInline: 8,
  },
}))

interface IThreadHeaderProps {
  currentUser: IUser
  currentThread: IThread
}

const ThreadHeader = ({ currentThread, currentUser }: IThreadHeaderProps) => {
  const theme = useTheme()
  const classes = useThreadHeaderStyles()

  const threadActions = [
    <ShareThreadButton
      id={OnboardingTourTargetComponent.shareThreadButton}
      currentThread={currentThread}
      currentUser={currentUser}
    />,
    <CopyThreadLinkButton id={OnboardingTourTargetComponent.copyThreadLinkButton} threadId={currentThread.id} />,
  ]

  const importingLabel = currentThread.isImporting ? (
    <LabelWithLoadingIndicator
      text="Importing"
      className={classes.importingLabel}
      spinnerColor={theme.threadPalette.white}
    />
  ) : null

  return (
    <BaseHeaderWithActions actions={threadActions} footer={importingLabel}>
      <ThreadHeaderBar currentThread={currentThread} />
    </BaseHeaderWithActions>
  )
}

export default ThreadHeader
